import { FormLabel, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { ReferenceField, TextField } from "react-admin";
import { useForm } from "react-final-form";
import { Constants } from "../../constants";

const useStyles = makeStyles(() => ({
  label: {
    marginTop: 16,
  },
}));

export const ClientCompanyReadonlyInput: React.FC<any> = ({ record }) => {
  const form = useForm();

  useEffect(() => {
    form.change("clientCompanyId", record?.id);
  }, [record]);

  return <TextField source="name" record={record} />;
};

export const ClientCompanyInput: React.FC<any> = (props) => {
  const classes = useStyles(props);
  return (
    <>
      <FormLabel component="legend" className={classes.label}>
        Client company
      </FormLabel>
      <ReferenceField
        {...props}
        source="taskId"
        basePath={Constants.TASK_PATH}
        reference={Constants.TASK_RESOURCE}
        link={false}
      >
        <ReferenceField
          source="clientCompanyId"
          reference={Constants.CLIENT_COMPANY_RESOURCE}
          label="Client company"
          link={false}
        >
          <ClientCompanyReadonlyInput />
        </ReferenceField>
      </ReferenceField>
    </>
  );
};
