import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { Constants } from "../../constants";
import { IInvoice } from "../../model";
import { priceFormatter } from "../../utils/money";

interface ITotalAmountProps {
  invoice: IInvoice;
}

const useStyles = makeStyles({
  tableCell: {
    border: "none",
    padding: "5px",
  },
});

export const TotalAmount: React.FC<ITotalAmountProps> = ({ invoice }) => {
  const classes = useStyles({});
  const query = encodeURIComponent(JSON.stringify({ invoiceId: invoice.id }));
  const {
    amtToBill,
    expenseToBill,
    wipPayment,
    wipCreditNote,
    expensePayment,
    expenseCreditNote,
    expenseBalance,
    wipBalance,
  } = invoice;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableCell} align="right" />
          <TableCell className={classes.tableCell} align="right">
            WIP
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            Expense
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell className={classes.tableCell} align="left">
            Invoice Total
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            {priceFormatter.format(Number(amtToBill))}
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            {priceFormatter.format(Number(expenseToBill))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.tableCell} align="left">
            <Link to={`${Constants.CREDIT_NOTE_PATH}?filter=${query}`}>
              Credit Note Total
            </Link>
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            {priceFormatter.format(Number(wipCreditNote))}
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            {priceFormatter.format(Number(expenseCreditNote))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.tableCell} align="left">
            <Link to={`${Constants.PAYMENT_PATH}?filter=${query}`}>
              Payment Total
            </Link>
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            {priceFormatter.format(Number(wipPayment))}
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            {priceFormatter.format(Number(expensePayment))}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.tableCell} align="left">
            Balance
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            {priceFormatter.format(Number(wipBalance))}
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            {priceFormatter.format(Number(expenseBalance))}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
