import _ from "lodash";
import { useGetList } from "ra-core";
import React, { useRef } from "react";
import { ClientGroupForm } from "../../components/ClientGroupFrom/ClientGroupForm";
import { Constants } from "../../constants";
import { IClientGroup } from "../../model";
import { IClientUser } from "../../model/IClientUserAuth";
import { IFormGroup } from "../../model/IFormGroup";
import { FormGroupResponseReferenceList } from "./FormGroupResponseReferenceList";
import { FormResponseReferenceList } from "./FormResponseReferenceList";

interface IClientGroupClientUserListProps {
  clientGroup: IClientGroup;
}

export const ClientGroupFormTab: React.FC<IClientGroupClientUserListProps> = ({
  clientGroup,
}) => {
  const htmlElRef = useRef(null);
  const executeScroll = () => htmlElRef.current.scrollIntoView(false);

  const { data: formGroups } = useGetList(
    Constants.FORM_GROUPS_RESOURCE,
    { page: 1, perPage: 30 },
    { field: "", order: "" },
    {}
  );

  const { data: clientUsers } = useGetList(
    Constants.CLIENT_USER_RESOURCE,
    { page: 1, perPage: 30 },
    { field: "", order: "" },
    { clientGroupId: clientGroup.id }
  );

  return (
    <>
      <div className="client-group-form">
        <h3 className="client-group-entry-list-title">Send Form</h3>
        <ClientGroupForm
          clientUsers={_.values(clientUsers) as IClientUser[]}
          formGroup={_.values(formGroups) as IFormGroup[]}
        />
      </div>
      <div className="client-group-form-flow">
        <h3 className="client-group-entry-list-title">Current Form Progress</h3>
        <FormGroupResponseReferenceList
          record={clientGroup}
          hasCreate={false}
          hasEdit={false}
          hasShow={false}
          hasList={true}
          resource={Constants.FORM_GROUP_RESPONSE_RESOURCE}
          basePath={`${Constants.APP_PATH}/form_groups/`}
          scrollItoView={executeScroll}
        />
      </div>
      <div className="client-group-formresponse" ref={htmlElRef}>
        <h3 className="client-group-entry-list-title">See Responses</h3>
        <FormResponseReferenceList
          record={clientGroup}
          hasCreate={false}
          hasEdit={false}
          hasShow={false}
          hasList={true}
          resource={Constants.FORMS_RESPONSE_RESOURCE}
          basePath={Constants.FORM_RESPONSES_PATH}
        />
      </div>
    </>
  );
};
