import { getOr, intersection } from "lodash/fp";

export const features = {
  timebill: {
    timesheet: ["timebill_core", "timebill_admin"],
    invoice: [
      "timebill_core",
      "timebill_payment",
      "timebill_admin",
      "timebill_feenote_department",
    ],
    writeOff: ["timebill_core", "timebill_admin"],
    creditNote: ["timebill_core", "timebill_admin"],
    expense: ["timebill_expense", "timebill_admin"],
    payment: ["timebill_payment", "timebill_admin"],
    reports: ["timebill_reports", "timebill_admin"],
    timeEntry: ["timebill_reports", "timebill_admin"],
    editSubtask: ["timebill_edit_subtask", "timebill_admin", "admin"],
    adjustment: ["timebill_adjustment", "timebill_admin"],
    estimatedFee: ["timebill_admin", "admin"],
    estimatedTimeEntry: ["timebill_admin", "admin"],
    customReport: ["custom_report", "timebill_admin", "admin"],
  },
  switchUser: {
    internal: ["internal"],
  },
  bankStatmentParser: ["bank_statement_parser"],
  doctomate: ["doctomate"],
};

export function enabled(feature: string, roles: string[]): boolean {
  const requiredRoles = getOr([], feature, features);
  const intersected = intersection(requiredRoles, roles);

  return intersected.length > 0;
}

export function reportAccess(roles: string[], reportType: string) {
  const rex = new RegExp(`${reportType}|timebill_reports|timebill_admin`);

  return roles.find((role) => role.match(rex));
}

export function reportRole(roles: string[]) {
  return roles.find((role) => role.match(/report/));
}

export const reportDefaultFilter = (
  roles: string[],
  reportType: string,
  departmentId: string
) => {
  const rex = new RegExp(`${reportType}`);

  const hasRole = roles.find((role) => role.match(rex));

  if (hasRole) {
    return { departmentId };
  }
};
