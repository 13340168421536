import { IPermissions } from "../model";

const ALL_KEY = "all";
const MANAGE_KEY = "manage";

export interface ICanParams {
  action: string;
  modelName: string;
  fieldName?: string;
  permissions: IPermissions;
  objectPermissions?: IPermissions;
}

export function can(params: ICanParams): boolean {
  const {
    action,
    modelName,
    fieldName,
    permissions: globalPermissions,
    objectPermissions,
  } = params;

  // If object permissions are supplied, merge them with the global permissions and evaluate all
  // permissions questions within the context of the merged rules.
  const permissions = mergePermissions(globalPermissions, objectPermissions);

  // First check if the specified model/field is listed in the cannot object.
  // If it is, then the answer is no, since cannot takes precedence over can.
  const unpermittedAttributes = getAttributes(
    "cannot",
    action,
    modelName,
    permissions
  );

  if (checkAttributes(unpermittedAttributes, fieldName)) {
    return false;
  }

  // Next check if the specified model/field is listed in the can object OR an all key is present.
  // If not, then the answer is no, since permissions must be explicitly given.
  const permittedAttributes = getAttributes(
    "can",
    action,
    modelName,
    permissions
  );

  if (checkAttributes(permittedAttributes, fieldName)) {
    return true;
  }

  return false;
}

export function cannot(params: ICanParams): boolean {
  return !can(params);
}

function mergePermissions(
  globalPermissions: IPermissions,
  objectPermissions?: IPermissions
): IPermissions {
  if (objectPermissions == null) {
    return globalPermissions;
  }

  return {
    ...globalPermissions,
    ...objectPermissions,
  };
}

function actionExists(ability: "can" | "cannot", action, permissions): boolean {
  return (
    permissions[ability] != null &&
    (permissions[ability][action] != null ||
      permissions[ability][MANAGE_KEY] != null)
  );
}

function getAttributes(
  ability: "can" | "cannot",
  action,
  modelName,
  permissions
): string[] {
  if (permissions != null && actionExists(ability, action, permissions)) {
    const actionPermissions =
      permissions[ability][action] || permissions[ability][MANAGE_KEY];
    if (actionPermissions[modelName] != null) {
      return actionPermissions[modelName];
    }

    if (actionPermissions[ALL_KEY] != null) {
      return actionPermissions[ALL_KEY];
    }
  }

  return null;
}

function checkAttributes(attributes, fieldName): boolean {
  if (attributes != null) {
    // An empty list is shorthand for all attributes being unpermitted, so return false.
    if (attributes.length === 0) {
      return true;
    }

    if (fieldName != null && attributes.includes(fieldName)) {
      return true;
    }
  }

  return false;
}
