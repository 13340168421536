import { makeStyles } from "@material-ui/core";
import { writeToString } from "fast-csv";
import {
  drop,
  flow,
  groupBy,
  map,
  mapValues,
  sum,
  uniq,
  unzip,
  values,
} from "lodash/fp";
import moment from "moment";
import { downloadCSV } from "ra-core";
import React from "react";
import { List } from "react-admin";
import { ReportRole } from "../../constants/reportRoles";
import { useReportParams } from "../../hooks/useReportParams";
import { DateFilter } from "../DepartmentAccountBalance/DateFilter";
import { ApprovalStatusSelectArrayInput } from "../inputs";
import { AmountToBillBody } from "./AmountToBillList/AmountToBillBody";

const filterDefaultValues = {
  startDate: moment().startOf("M").toDate(),
  endDate: moment().endOf("D").toDate(),
  approvalStatus: [
    "draft",
    "need_eic_approval",
    "need_pic_approval",
    "approved",
  ],
};

const useStyle = makeStyles({
  container: { marginTop: "30px" },
});

interface IData {
  id?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  total?: number;
}

interface IType {
  pic?: IData[];
  eic?: IData[];
  department?: IData[];
  entity?: IData[];
}

const exporter = async (data) => {
  const toDateString = (record) =>
    `${record.year}-${`${record.month}`.padStart(2, "0")}`;

  const getMonths = (headers) => {
    const dates = headers.slice().sort((a, b) => {
      if (moment(a) > moment(b)) {
        return 1;
      } else if (moment(a) < moment(b)) {
        return -1;
      } else if (moment(a) === moment(b)) {
        return 0;
      }
    });

    const res: string[] = [];
    const month = moment(dates[0]);

    while (month <= moment(dates[dates.length - 1])) {
      res.push(month.format("YYYY-MM"));
      month.add(1, "month");
    }

    return res;
  };

  const months = flow(map(toDateString), uniq, getMonths)(data);

  const groupByTypeId = flow(
    groupBy("typeId"),
    mapValues((rows: IData[]) => [
      rows[0].firstName || rows[0].name,
      rows[0].lastName || "",
      ...months.map((month) => {
        const row: IData = rows.find((r) => toDateString(r) === month);
        return Number(row?.total ?? 0);
      }),
      rows.reduce((rowSum, r) => rowSum + Number(r.total), 0),
    ])
  );

  const toTotal = (name) =>
    flow(values, unzip, map(sum), drop(2), (arr) => [name, "", ...arr]);

  const sections = flow(groupBy("type"), mapValues(groupByTypeId))(data);

  const sectionToRows = flow(values);

  const picSection = sectionToRows(sections.pic);
  const eicSection = sectionToRows(sections.eic);
  const departmentSection = sectionToRows(sections.department);
  const entitySection = sectionToRows(sections.entity);

  const csv = await writeToString([
    ["", "", ...months, "Total"],
    ["By PICs"],
    ...picSection,
    [],
    toTotal("PICs Total by Month")(picSection),
    [],
    ["By EICs"],
    ...eicSection,
    [],
    toTotal("EICs Total by Month")(eicSection),
    [],
    ["By Departments"],
    ...departmentSection,
    [],
    toTotal("Departments Total by Month")(departmentSection),
    [],
    ["By Billing Entities"],
    ...entitySection,
    [],
    toTotal("Billing Entities Total by Month")(entitySection),
  ]);

  downloadCSV(csv, "amount_to_bills");
};

export const AmountToBillList: React.FC<any> = (props) => {
  const classes = useStyle();

  const filterParams = useReportParams(
    ReportRole.AMOUNT_TO_BILL_DEPARTMENT,
    filterDefaultValues
  );

  return (
    <List
      {...props}
      pagination={false}
      exporter={exporter}
      perPage={100000}
      filters={
        <DateFilter
          reportType={ReportRole.AMOUNT_TO_BILL_TENANT}
          approvalStatusSelectArrayInput={
            <ApprovalStatusSelectArrayInput
              label="Approval statuses"
              source="approvalStatus"
            />
          }
        />
      }
      filterDefaultValues={filterParams}
      className={classes.container}
    >
      <AmountToBillBody />
    </List>
  );
};
