import _ from "lodash";
import qs from "qs";
import { useAuthProvider, useRedirect } from "ra-core";
import React, { useEffect } from "react";
import { Redirect } from "react-router";
import { Constants } from "../constants";
import { getClientRedirectPath, setClientRedirectPath } from "../core";
import { history } from "../store";

export const ClientUserLoginPage: React.FC = () => {
  const qsString = history.location.search.slice(1);
  const params: any = qs.parse(decodeURIComponent(qsString));

  if (params.hasOwnProperty("redirectUrl")) {
    setClientRedirectPath(params.redirectUrl);
  } else {
    setClientRedirectPath("/companies");
  }
  const redirect = useRedirect();

  const authDataProvider = useAuthProvider();
  useEffect(() => {
    const login = async () => {
      if (!_.isEmpty(params)) {
        await authDataProvider.login(params);
        redirect(`${Constants.CLIENT_APP_PATH}${getClientRedirectPath()}`);
      } else {
        redirect(Constants.CLIENT_APP_LOGIN_GENERATOR_PATH);
      }
    };

    login();
  }, []);

  return <Redirect to={Constants.CLIENT_APP_LOGIN_GENERATOR_PATH} />;
};
