import * as React from "react";
import { Droppable } from "react-beautiful-dnd";
import { IKanbanColumn } from "../../../model";
import { TaskCard } from "../TaskCard/TaskCard";

export class Column extends React.Component<IKanbanColumn> {
  public render(): JSX.Element {
    const taskCards = this.props.taskCardList.map((taskCardObj, index) => (
      <TaskCard
        id={taskCardObj.id}
        key={taskCardObj.draggableId}
        draggableId={taskCardObj.draggableId}
        draggableIndex={index}
        isDragDisabled={taskCardObj.isDragDisabled}
        taskCardStartDate={taskCardObj.taskCardStartDate}
        taskCardDueDate={taskCardObj.taskCardDueDate}
        taskCardLabelColor={taskCardObj.taskCardLabelColor}
        taskCardLabelContent={taskCardObj.taskCardLabelContent}
        taskCardUserSpanContent={taskCardObj.taskCardUserSpanContent}
        taskCardUserSpanColor={taskCardObj.taskCardUserSpanColor}
        taskCardLink={taskCardObj.taskCardLink}
        taskCardTitle={taskCardObj.taskCardTitle}
      />
    ));

    const container = (provided, snapshot) => (
      <div
        className="kanban-cards-container"
        ref={provided.innerRef}
        {...provided.droppableProps}
      >
        {taskCards}
        {provided.placeholder}
      </div>
    );

    return (
      <div className="kanban-column-container">
        <div className="kanban-column-body">
          <h3 className="kanban-column-title">
            {this.props.name} ({this.props.taskCardList.length})
          </h3>
          <Droppable droppableId={this.props.id}>{container}</Droppable>
        </div>
      </div>
    );
  }
}
