import { Datagrid, List, TextField } from "ra-ui-materialui";
import React from "react";
import { PriceField } from "../../fields";
import { FeeNoteNumberField } from "../../Invoice/FeeNoteNumberField";
import { InvoiceStatusField } from "./InvoiceStatusField";
import { PdfLink } from "./PdfLink";
import { ShowButton } from "./ShowButton";

export const ClientInvoiceList: React.FC<any> = (props) => {
  const clientCompanyId = props.match.params.client_company_id;

  return (
    <List
      {...props}
      sort={{ field: "date", order: "DESC" }}
      filter={{ clientCompanyId, approvalStatus: "approved" }}
      exporter={false}
      bulkActionButtons={false}
    >
      <Datagrid>
        <TextField source="date" />
        <FeeNoteNumberField source="feeNoteNumber" sortable={false} />
        <TextField source="title" emptyText="Fee Note" sortable={false} />
        <PriceField source="amount" sortable={false} textAlign="right" />
        <InvoiceStatusField source="status" sortable={false} />
        <PdfLink source="pdf" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
