import { Button, Classes, Intent, Spinner } from "@blueprintjs/core";
import classNames from "classnames";
import {
  CRUD_UPDATE,
  useDataProvider,
  useGetManyReference,
  useNotify,
  useRefresh,
} from "ra-core";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Icons } from "react-keyed-file-browser";
import { useSelector } from "react-redux";
import { Constants } from "../../constants";
import { IAttachmentEntry, IEmailAttachment, IEtsState } from "../../model";
import {
  checkDuplicate,
  findFileByType,
  getFileByKey,
  getFileDataByKey,
  getFilesByFolder,
  getFilesByKeys,
  getFolders,
  getTransformedFiles,
} from "../../utils/fileManagement";
import { AttachmentSaveDialog } from "../dialog/AttachmentSaveDialog";
import AttachmentActions from "./AttachmentActions";
import CustomFileBrowser from "./CustomFileBrowser";
import CustomFilter from "./Filter";
import CustomHeader from "./Header";

interface IAttachmentFileBrowserProps {
  canDirectUpload: boolean;
  canDownload: boolean;
  canModify: boolean;
  canSave: boolean;

  attachment?: IAttachmentEntry | IEmailAttachment;
  attachmentType?: string;
  clientCompanyId?: string;
  handleUpload?: (files) => void;
  handleCopy?: (newClientAttachmentKey: string) => void;
  handleReplace?: (
    oldClientAttachmentId: string,
    newClientAttachmentKey: string
  ) => void;
  setUploadLocation?: (loc: string) => void;
  isLoading?: boolean;
  setIsLoading?: (loading: boolean) => void;
}

const detailRender = () => {
  return false;
};

const stopPropagation = (event) => {
  event.stopPropagation();
};

export const AttachmentFileBrowser: React.FC<IAttachmentFileBrowserProps> = ({
  attachment,
  canDirectUpload,
  canDownload,
  canModify,
  canSave,
  clientCompanyId,
  handleUpload,
  handleCopy,
  handleReplace,
  setUploadLocation,
  isLoading,
  setIsLoading,
  attachmentType,
}) => {
  /*
    Component is responsible for storing the state of the folder location at all times.
    We derive the location from FileBrowser handlers and user global clicks.
    When user clicks save, we will pass in the current location to the Save dialog.
    This is necessary to make sure that the current file structure display
    (e.g., UI change when user selects a folder) is consistent with our location state.
  */
  const [location, setLocation] = useState("");
  /*
    Temporary directories (folders) are needed to maintain folders which are created,
    but not yet filled with files within a single Data Safe open (as they are not permanently stored yet)
    These folders, if not filled, will disappear whenever users re-open the Data Safe.
  */
  const [tempDir, setTempDir] = useState([]);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const deleteEnabled = useSelector((state: IEtsState) =>
    state.auth.roles.includes("admin")
  );

  const intl = useIntl();

  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const isRoot = location === "";

  const { data: rawFiles, loading: dataLoading, loaded } = useGetManyReference(
    Constants.CLIENT_PORTAL_ATTACHMENT_RESOURCE,
    Constants.CLIENT_COMPANY_RESOURCE,
    clientCompanyId,
    { page: 1, perPage: 100 },
    { field: "", order: "" },
    {},
    Constants.CLIENT_COMPANY_RESOURCE
  );

  const handleGlobalClick = () => {
    setLocation("");
  };

  const transformedFiles = useMemo(
    () => getTransformedFiles(rawFiles, tempDir),
    [rawFiles, tempDir]
  );

  const onEnterSave = (event) => {
    event.stopPropagation();
    const path = location + attachment.fileName;
    const duplicate = checkDuplicate(path, transformedFiles);
    setIsDuplicate(duplicate);
    setIsSubmitting(true);
  };

  const finishSubmitting = () => {
    setIsSubmitting(false);
  };

  useEffect(() => {
    const header = document.querySelector(
      ".rendered-react-keyed-file-browser thead"
    );

    // Prevent current location state to change when user clicks header
    if (header) {
      header.addEventListener("click", stopPropagation);
    }

    // Change current location to root whenever user clicks outside on empty area
    window.addEventListener("click", handleGlobalClick);
  }, [loaded]);

  const handleCreateFolder = (key: string) => {
    const folders = getFolders(transformedFiles);
    if (folders.has(key)) {
      notify("Folder name taken. Please choose a different name.", "warning");
      return;
    }
    setTempDir((prevTempDir) => {
      const newTempDir = prevTempDir.concat([
        {
          key,
        },
      ]);
      return newTempDir;
    });
    setLocation(key);
  };

  const renameTempDir = (oldKey: string, newKey: string) => {
    setTempDir((prevTempDir) => {
      const arr = [];
      prevTempDir.forEach((dir) => {
        if (oldKey !== dir.key) {
          arr.push(dir);
        }
      });
      arr.push({
        key: newKey,
      });
      return arr;
    });
  };

  // On direct upload, prevent files with duplicate keys to be created (NO replace/rename option)
  const handleCreateFiles = (incomingFiles: File[], prefix: string) => {
    for (const incomingFile of incomingFiles) {
      for (const existingFile of transformedFiles) {
        const incomingFileKey = prefix + incomingFile.name;
        const existingFileKey = existingFile.key;
        if (incomingFileKey === existingFileKey) {
          notify(
            "File(s) with duplicate name exists. Please rename.",
            "warning"
          );
          return;
        }
      }
    }
    setIsLoading(true);
    setUploadLocation(prefix);
    handleUpload(incomingFiles);
  };

  const onChangeKey = (file, newClientAttachmentKey: string) => {
    return dataProvider.update(
      Constants.CLIENT_PORTAL_ATTACHMENT_RESOURCE,
      {
        id: file.id,
        data: {
          ...file,
          newClientAttachmentKey,
        },
        previousData: file,
      },
      {
        action: CRUD_UPDATE,
      }
    );
  };

  const handleRenameFile = async (oldKey: string, newKey: string) => {
    const duplicate = checkDuplicate(newKey, transformedFiles);
    if (duplicate) {
      notify("File with duplicate name detected. Please rename.", "warning");
      return;
    }
    setIsLoading(true);
    const file = getFileByKey(oldKey, rawFiles);
    try {
      await onChangeKey(file, newKey);
      notify("File successfully updated !", "info");
    } catch (error) {
      notify("File update failed.", "warning");
    }
    setIsLoading(false);
  };

  // Function essentially changes the keys of each file in the folder.
  const handleRenameFolder = async (oldKey: string, newKey: string) => {
    const folders = getFolders(transformedFiles);
    if (folders.has(newKey)) {
      notify("Folder name taken. Please choose a different name.", "warning");
      return;
    }
    setIsLoading(true);
    const files = getFilesByFolder(oldKey, rawFiles);
    try {
      await Promise.all(
        files.map((file) => {
          const newFileKey = newKey + file.fileName;
          return onChangeKey(file, newFileKey);
        })
      );
      notify("Folder successfully renamed !", "info");
      renameTempDir(oldKey, newKey);
    } catch (error) {
      notify("Folder renaming failed.", "warning");
    }
    setIsLoading(false);
  };

  const handleSelectFile = (file) => {
    const key = file.key;
    const { folder } = getFileDataByKey(key);
    setLocation(folder);
  };

  const handleSelectFolder = (folder) => {
    if (folder) {
      const key = folder.key;
      setLocation(key);
    }
  };

  const handleSave = (fileName: string) => {
    setIsLoading(true);
    setIsSubmitting(false);
    const newClientAttachmentKey = location + fileName;
    handleCopy(newClientAttachmentKey);
  };

  const handleOverride = () => {
    setIsLoading(true);
    setIsSubmitting(false);
    const clientAttachmentKey = location + attachment.fileName;
    const file = getFileByKey(clientAttachmentKey, rawFiles);
    handleReplace(file.id, clientAttachmentKey);
  };

  const handleDownloadFile = () => {
    return true;
  };

  const onDeleteFile = (file) => {
    return dataProvider.delete(Constants.CLIENT_PORTAL_ATTACHMENT_RESOURCE, {
      id: file.id,
      previousData: file,
    });
  };

  // Delete folder currently not enabled
  const handleDeleteFolder = async (folderKey: string) => {
    setIsLoading(true);
    const files = getFilesByFolder(folderKey, rawFiles);
    try {
      const data = await Promise.all(
        files.map((file) => {
          return onDeleteFile(file);
        })
      );
      notify("Folder successfully deleted !", "info");
    } catch (error) {
      notify("Folder deletion failed.", "warning");
    }
    refresh();
    setIsLoading(false);
  };

  // Delete ability only available to users with admin roles
  const handleDeleteFile = async (fileKeys: string[]) => {
    setIsLoading(true);
    const files = getFilesByKeys(fileKeys, rawFiles);
    try {
      const data = await Promise.all(
        files.map((file) => {
          return onDeleteFile(file);
        })
      );
      notify("File(s) successfully deleted !", "info");
    } catch (error) {
      notify("File(s) deletion failed.", "warning");
    }
    refresh();
    setIsLoading(false);
  };

  const renderHeader = () => {
    if (attachment.isShared) {
      const file = findFileByType(
        attachmentType,
        attachment.id,
        transformedFiles
      );
      if (!file) {
        return false;
      }
      const { folder, fileName } = getFileDataByKey(file.key);
      return (
        <div className="attachment-dialog-header-text">
          <span> Your file </span>
          <b>{attachment.fileName}</b>
          <span> has been saved under </span>
          <b>
            {folder !== "" ? folder.substring(0, folder.length - 1) : "Root"}
          </b>
          {attachment.fileName !== fileName && (
            <>
              <span> as </span>
              <b>{fileName}</b>
            </>
          )}
        </div>
      );
    } else {
      return (
        <div className="attachment-dialog-header-text">
          <span>
            Choose a folder to save <b>{attachment.fileName}</b> into
          </span>
          <br />
        </div>
      );
    }
  };

  return (
    <>
      {attachment && renderHeader()}
      <CustomFileBrowser
        files={transformedFiles ?? []}
        icons={Icons.FontAwesome(4)}
        onCreateFolder={isRoot && (canSave || canModify) && handleCreateFolder}
        onCreateFiles={canDirectUpload && handleCreateFiles}
        onMoveFolder={false}
        onMoveFile={canModify && handleRenameFile}
        onRenameFolder={canModify && handleRenameFolder}
        onRenameFile={canModify && handleRenameFile}
        onDeleteFolder={false}
        onDeleteFile={canModify && deleteEnabled && handleDeleteFile}
        canFilter={true}
        showFoldersOnFilter={true}
        showActionBar={true}
        onSelectFile={handleSelectFile}
        onSelectFolder={handleSelectFolder}
        actionRenderer={AttachmentActions}
        detailRenderer={detailRender}
        onDownloadFile={canDownload && handleDownloadFile}
        noFilesMessage={intl.formatMessage({
          defaultMessage: "No Files",
          description: "DataSafe no files message",
        })}
        filterRenderer={CustomFilter}
        headerRenderer={CustomHeader}
      />
      <div
        className={classNames(
          Classes.DIALOG_FOOTER,
          "cp-attachment-dialog-footer"
        )}
      >
        {(isLoading || dataLoading) && (
          <Spinner className="attachment-loading-spinner" size={20} />
        )}
        {canSave && !attachment?.isShared && (
          <>
            <AttachmentSaveDialog
              isOpen={isSubmitting}
              canOutsideClickClose={true}
              onClose={finishSubmitting}
              attachment={attachment}
              location={location}
              isDuplicate={isDuplicate}
              onSave={handleSave}
              onOverride={handleOverride}
            />
            <Button
              intent={Intent.PRIMARY}
              text="Save"
              type="submit"
              className="attachment-dialog-save-button"
              onClick={onEnterSave}
              disabled={isLoading || dataLoading}
            />
          </>
        )}
      </div>
    </>
  );
};
