import { IActivityFeedNotificationListProps } from "@app/model";
import { ReferenceManyList } from "@app/ReferenceManyList";
import { AnchorButton, Icon } from "@blueprintjs/core";
import React from "react";
import {
  Datagrid,
  Pagination,
  ReferenceManyField,
  TextField,
} from "react-admin";
import { Constants } from "../../constants";
import { ErrorBoundary } from "../ErrorBoundary";
import {
  useArchiveNotificationsByGroupOnClick,
  useArchiveNotificationsOnClick,
} from "../Notification/ArchiveNotifications";
import { Notification } from "../Notification/Notification";
import { isNotificationValid } from "../Notification/NotificationRender";
import { useNotificationOnClick } from "../Notification/OpenNotification";

const NotificationsListByTaskId: React.FC<any> = (props) => {
  const notifications = props.data || [];
  const notificationIds = props.ids || [];
  const validNotifications = notificationIds
    .map((id) => notifications[id])
    .filter(isNotificationValid);

  const handleNotificationClick = useNotificationOnClick();
  const handleArchiveNotificationClick = useArchiveNotificationsOnClick();

  const allNotifications = validNotifications.map((n) => (
    <Notification
      key={n.id}
      notification={n}
      displayArchiveButton={true}
      notificationOnClick={handleNotificationClick}
      archiveNotificationOnClick={handleArchiveNotificationClick}
    />
  ));

  return allNotifications;
};

export const NotificationTaskList: React.FC<any> = (props) => {
  const filter = { groupId: props.record?.id, groupType: "Task" };
  return (
    <ReferenceManyField
      reference={Constants.ACTIVITY_FEED_NOTIFICATIONS_BY_GROUP_RESOURCE}
      target="groupId"
      record={props.record}
      basePath={props.basePath}
      sort={{ field: "createdAt", order: "DESC" }}
      filter={filter}
      perPage={10}
      pagination={<Pagination />}
    >
      <NotificationsListByTaskId />
    </ReferenceManyField>
  );
};

const ArchiveTaskNotificationsButton: React.FC<any> = (props) => {
  const taskId = props.record?.id;
  const handleArchiveNotificationClick = useArchiveNotificationsByGroupOnClick();
  const handleArchiveNotificationOnClick = () => {
    handleArchiveNotificationClick(taskId);
  };

  return (
    <AnchorButton
      key={taskId}
      className="archive-notification-button"
      onClick={handleArchiveNotificationOnClick}
      minimal={true}
    >
      <Icon icon="cross" />
    </AnchorButton>
  );
};

const NotificationsToDisplay: React.FC = () => {
  return (
    <Datagrid expand={<NotificationTaskList />}>
      <TextField source="description" label="Task Description" />
      <ArchiveTaskNotificationsButton />
    </Datagrid>
  );
};

const NotificationsList: React.FC<IActivityFeedNotificationListProps> = (
  props
) => {
  return (
    <ReferenceManyList
      {...props}
      reference={Constants.ACTIVITY_FEED_NOTIFICATIONS_RESOURCE}
      target="notification_group"
      perPage={5}
      pagination={<Pagination />}
      sort={{ field: "createdAt", order: "desc" }}
      bulkActionButtons={false}
      actions={false}
      exporter={false}
    >
      <NotificationsToDisplay />
    </ReferenceManyList>
  );
};

export const ActivityFeedShow: React.FC<any> = () => {
  return (
    <ErrorBoundary>
      <div className="notify-container">
        <div className="header">
          <div className="title">Your Notification Activity Feed</div>
        </div>
        <hr />
        <NotificationsList
          resource={Constants.ACTIVITY_FEED_NOTIFICATIONS_RESOURCE}
          basePath={Constants.NOTIFICATIONS_PATH}
        />
      </div>
    </ErrorBoundary>
  );
};
