import moment from "moment";

export const endDateValidation = (value, allValues) => {
  if (moment(value).isBefore(moment(allValues.startDate)) && value) {
    return "End date must be in after start date";
  }
};

export const startDateValidation = (value, allValues) => {
  if (moment(value).isAfter(moment(allValues.endDate)) && value) {
    return "start date must be in before end date";
  }
};
