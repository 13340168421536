import { Tab, Tabs } from "@blueprintjs/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IEtsState } from "../../../model";
import { TimeEntryDurationTab } from "./TimeEntryDurationTab";
import { TimeEntryTimeRangeTab } from "./TimeEntryTimeRangeTab";

type IAddTimeEntryTab = "dr" | "ti";

export const TimeEntryTabs = () => {
  const [tab, setTab] = useState<IAddTimeEntryTab>("dr");
  const handleChange = (newTabId: React.ReactText) => {
    setTab(newTabId as IAddTimeEntryTab);
  };
  const isDaily = useSelector(
    (state: IEtsState) => state.departmentSetting.timebillDaily
  );

  return (
    <Tabs className="add-time-entry-tabs" large={false} onChange={handleChange}>
      <Tab
        id="dr"
        title="Duration"
        panel={<TimeEntryDurationTab selected={tab === "dr"} />}
      />
      {!isDaily && (
        <Tab id="ti" title="Start/End Time" panel={<TimeEntryTimeRangeTab />} />
      )}
    </Tabs>
  );
};
