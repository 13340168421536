import { IClientGroup, IEtsState } from "../../model";
import { selectForModelById } from "./ModelSelect/ModelSelect";
import { alphabeticalSort } from "./ModelSelect/Sort";

export const ClientGroupSelect = selectForModelById(
  "clientGroup",
  (group: IClientGroup) => {
    return {};
  },
  (group: IClientGroup) => group.name,
  (group: IClientGroup) => null,
  (state: IEtsState) => state.clientGroupsById,
  undefined,
  undefined,
  alphabeticalSort,
  undefined,
  { popoverProps: { popoverClassName: "client-group-select-popover" } }
);
