import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { ConnectedTaskTable } from "../../components";
import { IEtsState, ITask } from "../../model";
import * as Utils from "../../utils/sort";
import * as TaskUtil from "../../utils/task";

interface ITodayProps {
  tasks: ITask[];
}

export class TodayImpl extends React.Component<ITodayProps> {
  public render(): JSX.Element {
    return (
      <div className="today">
        <ConnectedTaskTable
          completedTasksFilter={TaskUtil.filterTasksThisMonthDueDate}
          tasks={this.props.tasks}
        />
      </div>
    );
  }
}

function mapStateToProps(state: IEtsState): ITodayProps {
  const tasks = state.tasks.filter(
    (task) =>
      task.assigneeId === state.auth.user.id &&
      moment(task.dueDate).toDate() <= moment().toDate()
  );

  tasks.sort(Utils.sortByDueAtAsc);
  return {
    tasks,
  };
}

export const Today = connect(mapStateToProps)(TodayImpl);
