import { Icon } from "@blueprintjs/core";
import { Divider, IconButton, MenuItem } from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { Constants } from "../../../constants";
import { IClientCompany } from "../../../model";
import { ClientPortalCompanySelect } from "./ClientPortalCompanySelect";

interface IClientPortalSidebarProps {
  user: any;
  companies: IClientCompany[];
  selectedCompany: IClientCompany;
  handleDrawerOpen: (open) => void;
  action: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20,
      paddingTop: 0,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: 5,
      [theme.breakpoints.down("xs")]: {
        padding: 10,
      },
    },
    menuItem: {
      padding: 20,
      paddingLeft: 25,
    },
  })
);

export const ClientPortalSidebar: React.FC<IClientPortalSidebarProps> = ({
  user,
  companies,
  selectedCompany,
  handleDrawerOpen,
  action,
}) => {
  const classes = useStyles({});
  const theme = useTheme();
  const getUserName = () => {
    return user.firstName + " " + user.lastName;
  };

  return (
    <div>
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerOpen.bind(this, false)}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      <div className="client-portal-sidebar-company-select">
        <ClientPortalCompanySelect
          companies={companies}
          selectedCompany={selectedCompany}
        />
      </div>
      <div className="client-portal-sidebar-field">
        <Icon className="client-portal-company-field-icon" icon="person" />
        {user && getUserName()}
      </div>
      {selectedCompany && (
        <>
          <Divider />
          <MenuItem
            className={classes.menuItem}
            selected={action === "manage"}
            component={NavLink}
            to={`${Constants.CLIENT_APP_PATH}/${selectedCompany.id}/manage`}
          >
            <FormattedMessage
              defaultMessage="Manage"
              description="Side Bar Manage Label"
            />
          </MenuItem>
          <Divider />
          <MenuItem
            className={classes.menuItem}
            selected={action === "data_safe"}
            component={NavLink}
            to={`${Constants.CLIENT_APP_PATH}/${selectedCompany.id}/data_safe`}
          >
            <FormattedMessage
              defaultMessage="Data Safe"
              description="Side Bar DataSafe Label"
            />
          </MenuItem>
          <Divider />
          <MenuItem
            className={classes.menuItem}
            selected={action === "invoices"}
            component={NavLink}
            to={`${Constants.CLIENT_APP_PATH}/${selectedCompany.id}/invoices`}
          >
            <FormattedMessage
              defaultMessage="Fee Notes"
              description="Side Bar Fee Notes Label"
            />
          </MenuItem>
        </>
      )}
    </div>
  );
};
