import {
  AnchorButton,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Popover,
  PopoverPosition,
} from "@blueprintjs/core";
import { keyBy } from "lodash";
import { useGetList } from "ra-core";
import { Datagrid, DateField, TextField } from "ra-ui-materialui";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Constants } from "../../constants";
import { IEtsState, ITask } from "../../model";
import { UserField } from "../fields";
import { Pagination } from "../Pagination";

interface ActionsFieldProps {
  source: string;
  record?: { [key: string]: string };
  label: string;
}

const ActionsField: React.FC<ActionsFieldProps> = ({ source, record = {} }) => {
  const user = useSelector((state: IEtsState) => state.auth.user);
  const form = useRef<HTMLFormElement>(null);

  return (
    <Popover position={PopoverPosition.BOTTOM}>
      <Button text="Actions" icon="add-to-artifact" rightIcon="caret-down" />
      <Menu>
        <MenuItem
          text="Edit"
          icon="edit"
          href={`/interviews/${record[source]}`}
          target="_blank"
        />
        {record.docxUrl && (
          <form action="/interviews" target="_blank" method="POST" ref={form}>
            <MenuItem
              onClick={() => form.current.submit()}
              text="Create (Use as previous year data)"
              icon="fork"
            />
            <input type="hidden" name="user_id" value={user.id} />
            <input type="hidden" name="state_id" value={record[source]} />
            <input type="hidden" name="state_id_timeshift" value="true" />
          </form>
        )}
      </Menu>
    </Popover>
  );
};

interface DownloadsFieldProps {
  record?: { [key: string]: string };
  label: string;
}

const DownloadsField: React.FC<DownloadsFieldProps> = ({ record = {} }) => {
  const docxUrl = record.docxUrl;
  const pdfUrl = record.pdfUrl;

  return (
    <ButtonGroup>
      <AnchorButton
        text="DOCX"
        href={docxUrl}
        target="_blank"
        icon="document-open"
        disabled={!docxUrl}
      />
      <AnchorButton
        text="PDF"
        href={pdfUrl}
        target="_blank"
        icon="document-open"
        disabled={!docxUrl}
      />
    </ButtonGroup>
  );
};

interface IInterviewsListProps {
  task: ITask;
}

export const InterviewsList: React.FC<IInterviewsListProps> = ({ task }) => {
  const [page, setPage] = useState(1);
  const perPage = 5;

  const { data, ids, total, loaded, loading } = useGetList(
    Constants.COMPLETED_INTERVIEWS_RESOURCE,
    { page, perPage },
    { field: "createdAt", order: "DESC" },
    { entryId: task.id }
  );

  if (total === 0) {
    return <p>No documents generated yet...</p>;
  }

  return (
    <>
      <Datagrid
        loaded={loaded}
        loading={loading}
        data={keyBy(data, "id")}
        ids={ids}
        currentSort={{ field: "createdAt", order: "DESC" }}
        selectedIds={[]}
        className="interviews-table"
      >
        <DateField label="Created On" source="createdAt" />
        <UserField label="Created By" source="createdById" />
        <TextField
          label="Template"
          source="title"
          headerClassName="template-column"
          cellClassName="template-column"
        />
        <TextField
          label="Excel template"
          source="excelName"
          headerClassName="template-column"
          cellClassName="template-column"
        />
        <ActionsField label="" source="id" />
        <DownloadsField label="Download" />
      </Datagrid>
      <Pagination page={page} perPage={perPage} setPage={setPage} ids={ids} />
    </>
  );
};
