// tsline:enable no-submodule-imports
import * as React from "react";
import { Constants } from "../../constants";
import { AccountBalanceTable } from "./AccountBalanceTable";
import { ExpenseRow } from "./ExpenseRow";

export interface IExpenseTableProps {
  range: [Date, Date];
  taskId: string;
  showProvisional: boolean;
}

const renderRow = (entry, total) => (
  <ExpenseRow key={entry.id} entry={entry} total={total} />
);

export const ExpenseTable: React.FC<IExpenseTableProps> = (props) => (
  <AccountBalanceTable
    {...props}
    resource={Constants.EXPENSE_ENTRY_RESOURCE}
    rowRenderer={renderRow}
  />
);
