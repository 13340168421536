import {
  CRUD_UPDATE,
  Record,
  useDataProvider,
  useNotify,
  useRedirect,
} from "ra-core";
import { useCallback } from "react";
import { useFormState } from "react-final-form";
import { Constants } from "../../../constants";
import { IInvoice } from "../../../model";

const toInvoicePayload = (values) => {
  const { wipWriteOff, expenseWriteOff, contact, ...data } = values;

  return {
    data: {
      ...data,
      wipWriteOffDescription: wipWriteOff?.description,
      expenseWriteOffDescription: expenseWriteOff?.description,
      contact: contact ?? "",
    },
  };
};

export const useCreateInvoice = ({ basePath }) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const formState = useFormState<Partial<IInvoice>>();
  return useCallback(async () => {
    if (!formState.valid) {
      return;
    }

    const payload = toInvoicePayload(formState.values);

    const { lineItems } = formState.values;

    try {
      const { data: newInvoice } = await dataProvider.create(
        Constants.INVOICE_RESOURCE,
        payload
      );

      notify("Invoice created");
      redirectTo(`${basePath}/${newInvoice.id}`);
    } catch (error) {
      notify("Error: invoice not created", "warning");
    }
  }, [
    basePath,
    dataProvider,
    formState.valid,
    formState.values,
    notify,
    redirectTo,
  ]);
};

interface IUpdateInvoiceOption {
  basePath: string;
  record: IInvoice;
  formData?: IInvoice;
}

export const useUpdateInvoice = ({
  basePath,
  record,
  formData,
}: IUpdateInvoiceOption) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const formState = useFormState<Partial<IInvoice>>();
  return useCallback(async () => {
    if (!formState.valid) {
      return;
    }

    const { data } = toInvoicePayload(formData ?? formState.values);

    try {
      const { data: newInvoice } = await dataProvider.update(
        Constants.INVOICE_RESOURCE,
        {
          id: record.id,
          data,
          previousData: record as Record,
        },
        {
          action: CRUD_UPDATE,
        }
      );

      notify("Invoice updated");
      redirectTo(`${basePath}/${newInvoice.id}`);
    } catch (error) {
      notify("Error: invoice not updated", "warning");
    }
  }, [
    basePath,
    dataProvider,
    formState.valid,
    formState.values,
    notify,
    redirectTo,
  ]);
};
