import { Icon } from "@blueprintjs/core";
import moment from "moment";
import React, { useMemo } from "react";

interface IClientCompanyDateSpanProps {
  firstDate: string;
  lastDate: string;
}

const getYearEntries = (firstDate: string, lastDate: string) => {
  const firstYear = moment(firstDate).year();
  const lastYear = moment(lastDate).year();
  const currYear = moment().year();

  const entries = [];
  for (let year = currYear; year >= firstYear; year--) {
    let completed = false;
    if (year <= lastYear) {
      completed = true;
    }
    entries.push({
      year,
      completed,
    });
  }
  return entries;
};

export const ClientCompanyDateSpan: React.FC<IClientCompanyDateSpanProps> = ({
  firstDate,
  lastDate,
}) => {
  const yearEntries = useMemo(() => getYearEntries(firstDate, lastDate), [
    firstDate,
    lastDate,
  ]);

  return (
    <div className="client-company-date-span-entries">
      {yearEntries.map((entry, key) => (
        <span key={key} className="client-company-date-span">
          {entry.year}
          <Icon icon={entry.completed ? "small-tick" : "small-cross"} />
        </span>
      ))}
    </div>
  );
};
