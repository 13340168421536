import { makeStyles } from "@material-ui/styles";
import { SelectArrayInput } from "ra-ui-materialui";
import React, { FC } from "react";
import { statusString } from "../../utils/approvalStatus";

const defaultChoices = (
  status: string[] = [
    "draft",
    "need_eic_approval",
    "need_pic_approval",
    "approved",
  ]
) => status.map((id) => ({ id, name: statusString(id) }));

const useStyles = makeStyles({
  root: {
    minWidth: "160px",
  },
  chip: {
    backgroundColor: "aliceblue",
  },
});

export const ApprovalStatusSelectArrayInput: FC<any> = (props) => {
  const classes = useStyles(props);
  const { choices } = props;

  return (
    <SelectArrayInput
      {...props}
      classes={classes}
      choices={choices ? defaultChoices(choices) : defaultChoices()}
    />
  );
};
