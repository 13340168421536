import { Icon, Tab, Tabs } from "@blueprintjs/core";
import * as React from "react";

interface ITableKanbanSwitcherProps {
  table: JSX.Element;
  board: JSX.Element;
}

export class TableKanbanSwitcher extends React.Component<
  ITableKanbanSwitcherProps
> {
  public render(): JSX.Element {
    const { table, board } = this.props;

    return (
      <div className="tabs-div">
        <Tabs
          id="header-tabs"
          className="header-tabs"
          defaultSelectedTabId="table"
        >
          <Tab
            id="table"
            title={
              <>
                <Icon icon="th" /> Table
              </>
            }
            panel={table}
          />
          <Tab
            id="kanban"
            title={
              <>
                <Icon icon="dashboard" /> Board
              </>
            }
            panel={board}
          />
          <Tabs.Expander />
        </Tabs>
      </div>
    );
  }
}
