import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Api } from "../../../actions";
import { ITimesheet } from "../../../model";

interface ITimesheetInternalRemarks {
  timesheet: ITimesheet;
}

const useStyles = makeStyles({
  text: {
    width: 500,
  },
  internalRemarks: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 30,
    marginTop: 8,
  },
  button: {
    width: 100,
    margin: 10,
  },
});

export const TimesheetInternalRemarks: React.FC<ITimesheetInternalRemarks> = ({
  timesheet,
}) => {
  const [internalRemarks, setInternalRemarks] = React.useState("");
  const classes = useStyles({});
  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInternalRemarks(event.currentTarget.value);
  };

  useEffect(() => {
    setInternalRemarks(timesheet.internalRemarks || "");
  }, [timesheet]);

  const dispatch = useDispatch();
  const handleOnClick = () => {
    dispatch(
      Api.Timesheet.update(timesheet, { ...timesheet, internalRemarks })
    );
  };

  return (
    <Grid className={classes.internalRemarks}>
      <TextField
        className={classes.text}
        multiline={true}
        rows="4"
        rowsMax="4"
        value={internalRemarks}
        onChange={handleOnChange}
        label="Internal Remarks"
        variant="outlined"
      />
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        size="small"
        startIcon={<Save />}
        onClick={handleOnClick}
      >
        Save
      </Button>
    </Grid>
  );
};
