import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { required } from "ra-core";
import { Create, SimpleForm, TextInput } from "ra-ui-materialui";
import * as React from "react";
import { ReferenceInput, SelectInput } from "react-admin";
import { Constants } from "../../constants";
import { PostCreateToolbar } from "../CreateToolBar";

const useStyles = makeStyles({
  naturalPersons: { marginLeft: 10 },
  title: { marginLeft: 10, display: "block" },
  notes: {
    marginLeft: 10,
    display: "block",
  },
  card: { backgroundColor: "inherit", boxShadow: "0px 0px 0px 0px" },
});

export const ImportantPersonCreate: React.FC<any> = (props) => {
  const classes = useStyles(props);
  const { clientCompanyId, clientGroupId, onSuccess } = props;
  return (
    <Create {...props} classes={classes} location={{}} onSuccess={onSuccess}>
      <SimpleForm
        toolbar={<PostCreateToolbar />}
        initialValues={{ clientCompanyId }}
      >
        <ReferenceInput
          source="natural_person_id"
          reference={Constants.NATURAL_PERSON_RESOURCE}
          filter={{ clientGroupId }}
          className={classes.naturalPersons}
        >
          <SelectInput optionText="name" emptyText="LOL" />
        </ReferenceInput>
        <TextInput
          source="title"
          validate={[required()]}
          className={classes.title}
        />
        <TextInput
          source="notes"
          className={classNames(classes.notes, "person-notes-create")}
          multiline={true}
        />
      </SimpleForm>
    </Create>
  );
};
