import { Button } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { push } from "connected-react-router";
import React from "react";
import { useDispatch } from "react-redux";
import { Constants } from "../../constants";

interface IBackButton {
  type: "credit_notes" | "write_offs";
}

export const BackButton: React.FC<IBackButton> = ({ type }) => {
  const dispatch = useDispatch();
  const handleOnClick = () => {
    if (type === "write_offs") {
      dispatch(push(Constants.WRITE_OFF_PATH));
    }
    if (type === "credit_notes") {
      dispatch(push(Constants.CREDIT_NOTE_PATH));
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<ArrowBackIcon />}
      onClick={handleOnClick}
      style={{ margin: 20 }}
    >
      Back
    </Button>
  );
};
