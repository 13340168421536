import * as React from "react";
import { NumberField } from "react-admin";

export const AmountField: React.FC<any> = ({ record, source }) => {
  const amount = parseFloat(record[source] ?? "0");
  return (
    <NumberField
      source={source}
      label="WIP"
      record={{ ...record, [source]: amount }}
      options={{ style: "currency", currency: "HKD" }}
    />
  );
};
