import { Button, MenuItem } from "@blueprintjs/core";
import { ItemPredicate, ItemRenderer, Select } from "@blueprintjs/select";
import React from "react";

interface ITimesheetCreateSelectInputProps<T> {
  options: T[];
  handleSelectOption: (options: T) => void;
  filter: boolean;
  itemPredicate?: ItemPredicate<T>;
  text: string;
}

export function TimesheetCreateSelectInput<T>(
  props: ITimesheetCreateSelectInputProps<T>
) {
  const { options, handleSelectOption, filter, itemPredicate, text } = props;
  const OptionSelect = Select.ofType<T>();

  const renderOptions: ItemRenderer<any> = (option, { handleClick }) => {
    return (
      <MenuItem
        label={option.chineseName}
        key={option.id}
        onClick={handleClick}
        text={option.description ?? option.name}
      />
    );
  };

  const handleOnItemSelect = (select: T) => {
    handleSelectOption(select);
  };

  return (
    <OptionSelect
      filterable={filter}
      items={options}
      itemRenderer={renderOptions}
      onItemSelect={handleOnItemSelect}
      itemPredicate={itemPredicate ?? null}
      noResults={<MenuItem disabled={true} text="No results." />}
    >
      <Button rightIcon="caret-down" text={text} />
    </OptionSelect>
  );
}
