import { makeStyles } from "@material-ui/core";
import * as jsonExport from "jsonexport/dist";
import { mapValues } from "lodash/fp";
import moment from "moment";
import React from "react";
import { downloadCSV, List } from "react-admin";
import { ReportRole } from "../../constants/reportRoles";
import { useReportParams } from "../../hooks/useReportParams";
import { ApprovalStatusSelectArrayInput } from "../inputs";
import { DateFilter } from "./DateFilter";
import { ReportLayout } from "./ReportLayout";

const filterDefaultValues = {
  startDate: moment().startOf("M").toDate(),
  endDate: moment().endOf("D").toDate(),
  approvalStatus: ["need_eic_approval", "need_pic_approval", "approved"],
};

const useStyle = makeStyles({
  container: { marginTop: "30px" },
});

const translate = {
  "Department": "name",
  "WIP B/f": "wipBf",
  "WIP Entries": "wipEntries",
  "WIP Invoices": "wipInvoices",
  "WIP Credit Notes": "wipCreditNotes",
  "WIP Write-offs": "wipWriteOffs",
  "WIP Transfers": "wipAdjustments",
  "WIP Balance": "wipTotal",
  "WIP C/f": "wipCf",
  "BLANK": "",
  "Expense B/f": "expenseBf",
  "Expense Entries": "expenseEntries",
  "Expense Invoices": "expenseInvoices",
  "Expense Credit Notes": "expenseCreditNotes",
  "Expense Write-offs": "expenseWriteOffs",
  "Expense Transfers": "expenseAdjustments",
  "Expense Balance": "expenseTotal",
  "Expense C/f": "expenseCf",
};

export const DepartmentsAccountBalancesList: React.FC<any> = (props) => {
  const filterParams = useReportParams(
    ReportRole.DEPARTMENT_ACCOUNT_BALANCE_DEPARTMENT,
    filterDefaultValues
  );
  const classes = useStyle();

  return (
    <List
      {...props}
      pagination={<></>}
      filters={
        <DateFilter
          reportType={ReportRole.DEPARTMENT_ACCOUNT_BALANCE_TENANT}
          approvalStatusSelectArrayInput={
            <ApprovalStatusSelectArrayInput
              label="Approval statuses"
              source="approvalStatus"
              choices={["need_eic_approval", "need_pic_approval", "approved"]}
            />
          }
        />
      }
      exporter={exporter}
      filterDefaultValues={filterParams}
      className={classes.container}
    >
      <ReportLayout headerName="Departments" />
    </List>
  );
};

const exporter = async (records) => {
  const data = records.map((record) =>
    mapValues((value) => record?.[value] ?? "", translate)
  );

  jsonExport(
    data,
    {
      mapHeaders: (header) => (header.includes("BLANK") ? "" : header),
    },
    (err, csv) => {
      downloadCSV(csv, "department account balances");
    }
  );
};
