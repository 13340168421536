import { IModelById } from "@chiubaka/core";
import * as React from "react";
import { connect } from "react-redux";
import { ConnectedTaskTable } from "../../components";
import { IEtsState, IStatus, ITask } from "../../model";
import * as Utils from "../../utils/sort";

interface IReviewProps {
  tasks: ITask[];
}

export class ReviewImpl extends React.Component<IReviewProps> {
  public render(): JSX.Element {
    return (
      <div className="review">
        <ConnectedTaskTable completedTasksLimit={10} tasks={this.props.tasks} />
      </div>
    );
  }
}

function mapStateToProps(state: IEtsState): IReviewProps {
  const tasks = state.tasks.filter(
    (task) => task.reviewerId === state.auth.user.id
  );
  // Check whether or not the statusId is one of the whitelisted status Ids
  // TODO: A hack to get the first element until we implement a new endpoint to GET an endpoint
  const whitelistedIds: string[] = state.departmentSetting.reviewStatusId;
  const whitelistedTasks = tasks.filter(
    (task) => whitelistedIds.indexOf(task.statusId) > -1
  );

  whitelistedTasks.sort(Utils.sortByCreatedAtAsc);
  return {
    tasks: whitelistedTasks,
  };
}

function findStatusByName(statuses: IModelById<IStatus>, statusName: string) {
  // Some Javascript trickery, when using "of" operator, requires the object to be array
  // So you use Object.keys to return to you the keys in array format
  for (const key of Object.keys(statuses)) {
    const value = statuses[key];
    if (value.name === statusName) {
      return key;
    }
  }
}

export const Review = connect(mapStateToProps)(ReviewImpl);
