import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { push } from "connected-react-router";
import _ from "lodash";
import * as React from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Constants } from "../../../constants";
import { IClientCompany } from "../../../model";

interface IClientPortalCompanySelectProps {
  companies: IClientCompany[];
  selectedCompany: IClientCompany;
}

export const ClientPortalCompanySelect: React.FC<IClientPortalCompanySelectProps> = ({
  companies,
  selectedCompany,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const handleCompanyChange = (event: any, clientCompany: IClientCompany) => {
    dispatch(push(`${Constants.CLIENT_APP_PATH}/${clientCompany.id}/manage`));
  };

  const getCompanyLabel = ({ name, chineseName }: IClientCompany) => {
    return `${name} (${chineseName})`;
  };

  const renderCompanyInput = (params) => {
    return (
      <TextField
        {...params}
        label={intl.formatMessage({
          description: "Client Company Select label",
          defaultMessage: "Client company",
        })}
        margin="normal"
        placeholder={intl.formatMessage({
          description: "Client Company Select placeholder",
          defaultMessage: "Please choose a client company",
        })}
      />
    );
  };

  return (
    <Autocomplete
      options={_.orderBy(companies, ["name"], ["asc"])}
      getOptionLabel={getCompanyLabel}
      id="client-portal-company-select"
      value={selectedCompany}
      onChange={handleCompanyChange}
      renderInput={renderCompanyInput}
      disableClearable={true}
    />
  );
};
