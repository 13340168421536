import { ReactAdminComponentPropsWithId, useGetOne } from "ra-core";
import {
  DateInput,
  Edit,
  NumberInput,
  ReferenceField,
  SimpleForm,
  TextInput,
} from "ra-ui-materialui";
import React from "react";
import { Constants } from "../../constants";
import { ITask } from "../../model";
import { PostCreateToolbar } from "../CreateToolBar";
import { ClientCompanyField, TaskField } from "../fields";

export const ExpenseEdit: React.FC<ReactAdminComponentPropsWithId> = (
  props
) => {
  const { loaded: expenseLoaded, data: expense } = useGetOne(
    Constants.EXPENSE_RESOURCE,
    props.id
  );
  const { loaded, data } = useGetOne(
    Constants.TASK_RESOURCE,
    expense && expense.taskId
  );
  const task = data as ITask;
  if (!loaded || !expenseLoaded || !expense || !task) {
    return null;
  }

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<PostCreateToolbar />}>
        <ReferenceField
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          addLabel={false}
          link={false}
        >
          <TaskField />
        </ReferenceField>
        <ReferenceField
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          addLabel={false}
          link={false}
        >
          <ClientCompanyField />
        </ReferenceField>
        <DateInput source="date" />
        <TextInput multiline={true} source="description" rows="4" />
        <NumberInput source="amount" />
      </SimpleForm>
    </Edit>
  );
};
