import { makeStyles } from "@material-ui/styles";
import React, { FC } from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";
import { Constants } from "../../../constants";
import { ILineItem, ILineItemTemplate } from "../../../model";

interface ILineItemTemplateInputProps {
  record?: ILineItem;
  templateId?: string;
  source?: string;
  fullWidth?: boolean;
  disabled?: boolean;
}
interface LineItemTemplateAutocompleteInputDetailProps {
  record?: ILineItemTemplate;
}

const templateToChoices = (record: ILineItemTemplate) => {
  return !record?.id ? `Clear input` : `[${record?.code}] ${record?.category}`;
};

const filterToQuery = (searchText: string) => ({ searchQuery: searchText });

const useStyles = makeStyles(() => ({
  wrap: {
    width: "220px",
    whiteSpace: "pre-wrap",
    color: "#828282",
    fontSize: "12px",
  },
}));

export const LineItemTemplateAutocompleteInputDetail: FC<LineItemTemplateAutocompleteInputDetailProps> = ({
  record,
}) => {
  const classes = useStyles({});
  return (
    <div>
      <div>{templateToChoices(record)}</div>
      <div className={classes.wrap}>{record.description}</div>
    </div>
  );
};

const matchSuggestion = (filterValue: string, choice) => {
  return (
    templateToChoices(choice)
      .toLowerCase()
      .includes(filterValue.toLowerCase()) ||
    choice.description.toLowerCase().includes(filterValue.toLowerCase())
  );
};

export const LineItemTemplateInput: React.FC<ILineItemTemplateInputProps> = (
  props
) => {
  return (
    <ReferenceInput
      {...props}
      label="Template"
      reference={Constants.LINE_ITEM_TEMPLATE_RESOURCE}
      perPage={100}
      sort={{ field: "code_and_category", order: "ASC" }}
      allowEmpty={true}
      emptyText="Clear Input"
      filterToQuery={filterToQuery}
    >
      <AutocompleteInput
        optionText={<LineItemTemplateAutocompleteInputDetail />}
        options={{ disabled: props.disabled }}
        inputText={templateToChoices}
        matchSuggestion={matchSuggestion}
      />
    </ReferenceInput>
  );
};
