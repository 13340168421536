import * as jsonExport from "jsonexport/dist";
import { pick } from "lodash";
import { downloadCSV } from "react-admin";
import { ITaskAccountBalance } from "../../model";
import * as Utils from "../../utils/date";
import { priceFormatter } from "../../utils/money";
import { calculatePercentage } from "../../utils/numberFormat";

export const taskAccountBalanceExporter = (isShowAll: boolean) => (
  records: ITaskAccountBalance[]
) => {
  const data = records.map((record) => {
    const json = {
      "Client Company": record.clientCompanyName,
      "Job Code": record.taskCode,
      "Job": record.taskDescription,
      "Date": `${Utils.shortFormDate(record.startDate)} - ${Utils.shortFormDate(
        record.dueDate
      )}`,
      "PIC Name": record.pic,
      "EIC Name": record.eic,
      "Assignee Name": record.assignee,
      "Budgeted Fee": priceFormatter.format(Number(record.estimatedFee)),
      "WIP Entries": priceFormatter.format(Number(record.wipEntries)),
      "Actual Recoverability Rate": calculatePercentage(
        record.estimatedFee,
        record.wipEntries
      ),
      "Fee Notes Amount": priceFormatter.format(Number(record.feeNoteAmount)),
      "Fee Notes Balance": priceFormatter.format(Number(record.feeNoteAmount)),
      "WIP To Bill": priceFormatter.format(Number(record.wipToBill)),
      "WIP Settled": priceFormatter.format(Number(record.wipSettled)),
      "WIP Credit Notes": priceFormatter.format(Number(record.wipCreditNotes)),
      "WIP Write Offs": priceFormatter.format(Number(record.wipWriteOffs)),
      "WIP Transfers": priceFormatter.format(Number(record.wipAdjustments)),
      "WIP Balance": priceFormatter.format(Number(record.wipTotal)),
      "Expense Entries": priceFormatter.format(Number(record.expenseEntries)),
      "Expense To Bill": priceFormatter.format(Number(record.expenseToBill)),
      "Expense Settled": priceFormatter.format(Number(record.expenseSettled)),
      "Expense Credit Notes": priceFormatter.format(
        Number(record.expenseCreditNotes)
      ),
      "Expense Write Offs": priceFormatter.format(
        Number(record.expenseWriteOffs)
      ),
      "Expense Transfers": priceFormatter.format(
        Number(record.expenseAdjustments)
      ),
      "Expense Balance": priceFormatter.format(Number(record.expenseTotal)),
    };

    return isShowAll
      ? json
      : pick(json, [
          "Client Company",
          "Job Code",
          "Job",
          "Date",
          "PIC Name",
          "EIC Name",
          "Assignee Name",
          "Budgeted Fee",
          "WIP Entries",
          "Actual Recoverability Rate",
          "Fee Notes Amount",
          "Fee Notes Balance",
          "WIP To Bill",
        ]);
  });

  jsonExport(data, {}, (err, csv) => {
    downloadCSV(csv, "tasks_account_balances");
  });
};
