import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";
import { IClientInvoice } from "../../../model";

const useStyle = makeStyles({
  title: {
    fontWeight: "bold",
    fontSize: 15,
    color: "#000000DE",
  },
  content: {
    paddingTop: 8,
    fontSize: 14,
  },
});

interface IPdfLinkProps {
  record?: IClientInvoice;
  basePath?: string;
  label?: string;
  source?: string;
}

export const PdfLink: React.FC<IPdfLinkProps> = ({
  record,
  label,
  basePath,
  source,
}) => {
  const isShow = basePath.includes("show");
  const classes = useStyle({});

  const renderUrl = () => (
    <a href={record.pdfUrl} target="_blank" rel="noreferrer">
      {record.pdfUrl ? (
        <FormattedMessage
          defaultMessage="Open"
          description="Client Invoice pdf link open"
        />
      ) : (
        <FormattedMessage
          defaultMessage="No PDF"
          description="Client Invoice pdf link no pdf"
        />
      )}
    </a>
  );

  return (
    <>
      {isShow ? (
        <div className="MuiFormControl-root MuiFormControl-marginDense">
          <label className={classes.title}>
            <FormattedMessage
              description="invoice field pdf"
              defaultMessage="PDF"
            />
          </label>
          <Typography
            component="span"
            variant="body2"
            className={classes.content}
          >
            {renderUrl()}
          </Typography>
        </div>
      ) : (
        renderUrl()
      )}
    </>
  );
};
