import React from "react";
import { ITaskAccountBalance } from "../../../model";
import { calculatePercentage } from "../../../utils/numberFormat";

interface IActualRecoverRateFieldProps {
  record?: ITaskAccountBalance;
  label?: string;
}

export const ActualRecoverRateField: React.FC<IActualRecoverRateFieldProps> = ({
  record,
}) => {
  const percentage = () =>
    calculatePercentage(record.estimatedFee, record.wipEntries);
  return <span>{percentage()}</span>;
};
