import React from "react";
import { IHourByJob } from "../../../model";
import { durationToStringFormat, sumOfDuration } from "../../../utils/date";

interface IBudgetDurationProps {
  textAlign?: "left" | "right";
  record?: IHourByJob;
  label?: string;
  cellClassName: any;
  headerClassName: any;
  isDaily?: boolean;
}

export const BudgetDuration: React.FC<IBudgetDurationProps> = ({
  record,
  isDaily,
}) => {
  const estimatedReport = record.records;

  const budgetDuration = estimatedReport.reduce(
    (accumulator, currentRecord) => {
      return sumOfDuration(accumulator, currentRecord.estTime);
    },
    "PT0H"
  );

  return <>{durationToStringFormat(budgetDuration, isDaily)}</>;
};
