import { Dialog, IDialogProps } from "@blueprintjs/core";
import * as React from "react";
import { Constants } from "../../constants";
import { NaturalPersonCreate } from "../NaturalPerson/NaturalPersonCreate";

interface INaturalPersonCreateDialogProps extends IDialogProps {
  clientGroupId: string;
  onSuccess: () => void;
}

export const NaturalPersonCreateDialog: React.FC<INaturalPersonCreateDialogProps> = (
  props
) => {
  const { clientGroupId, onSuccess } = props;

  return (
    <div className="save-dialog-wrapper">
      <Dialog {...props} icon="person" title="Create Natural Person">
        <NaturalPersonCreate
          resource={Constants.NATURAL_PERSON_RESOURCE}
          basePath="client_companies"
          clientGroupId={clientGroupId}
          onSuccess={onSuccess}
        />
      </Dialog>
    </div>
  );
};
