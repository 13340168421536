import { Spinner } from "@blueprintjs/core";
import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { CommentForm } from "../CommentForm/CommentForm";

interface IGeneralUploadProps {
  entryType: "Task" | "ClientGroup" | "ClientCompany";
  entryId: string;
  afterSubmit: () => void;
  handleUpload: (files) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const GeneralUpload = ({
  entryId,
  entryType,
  afterSubmit,
  handleUpload,
  loading,
  setLoading,
}: IGeneralUploadProps) => {
  const onDrop = useCallback((newFiles: File[]) => {
    setLoading(true);
    handleUpload(newFiles);
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragReject,
    isDragActive,
  } = useDropzone({ onDrop });

  const styleClass = useMemo(() => {
    if (isDragActive || isDragAccept) {
      return "accept-drag";
    } else if (isDragReject) {
      return "reject-drag";
    }
    return "";
  }, [isDragReject, isDragAccept, isDragActive]);

  return (
    <div {...getRootProps({ className: "timeline-drag-drop" })}>
      <input {...getInputProps()} />
      <div className={`timeline-form ${styleClass}`}>
        <div className="timeline-form-comment">
          <CommentForm
            entryId={entryId}
            entryType={entryType}
            afterSubmit={afterSubmit}
          />
          <p className="dropzone-text">
            {loading
              ? "Your attachment(s) are being uploaded .."
              : "Click / Drag & Drop your attachment(s) here .."}
          </p>
          {loading && <Spinner size={35} />}
        </div>
      </div>
    </div>
  );
};
