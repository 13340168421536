import React from "react";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { FormattedMessage } from "react-intl";
import { RawFileBrowser, Utils } from "react-keyed-file-browser";

class CustomFileBrowser extends RawFileBrowser {
  constructor(props) {
    super(props);
  }

  render() {
    const { selection } = this.state;
    const browserProps = this.getBrowserProps();
    const headerProps = {
      browserProps,
      fileKey: "",
      fileCount: this.props.files.length,
    };
    let renderedFiles;

    let files = this.props.files.concat([]);
    if (this.state.activeAction === "createFolder") {
      files.push({
        key: this.state.actionTargets[0],
        size: 0,
        draft: true,
      });
    }
    if (this.state.nameFilter) {
      const filteredFiles = [];
      const terms = this.state.nameFilter.toLowerCase().split(" ");
      files.map((file) => {
        let skip = false;
        terms.map((term) => {
          if (file.key.toLowerCase().trim().indexOf(term) === -1) {
            skip = true;
          }
        });
        if (skip) {
          return;
        }
        filteredFiles.push(file);
      });
      files = filteredFiles;
    }
    if (typeof this.props.group === "function") {
      files = this.props.group(files, "");
    } else {
      const newFiles = [];
      files.map((file) => {
        if (!Utils.isFolder(file)) {
          newFiles.push(file);
        }
      });
      files = newFiles;
    }
    const selectedItems = [];
    const findSelected = (item) => {
      if (selection.includes(item.key)) {
        selectedItems.push(item);
      }
      if (item.children) {
        item.children.map(findSelected);
      }
    };
    files.map(findSelected);
    if (typeof this.props.sort === "function") {
      files = this.props.sort(files);
    }

    let header;
    /** @type any */
    let contents = this.renderFiles(files, 0);
    switch (this.props.renderStyle) {
      case "table":
        if (!contents.length) {
          if (this.state.nameFilter) {
            contents = (
              <tr>
                <td colSpan={100}>
                  <FormattedMessage
                    defaultMessage={`No files matching {nameFilter}`}
                    description="key file browser no search result"
                    values={{ nameFilter: this.state.nameFilter }}
                  />
                </td>
              </tr>
            );
          } else {
            contents = (
              <tr>
                <td colSpan={100}>{this.props.noFilesMessage}</td>
              </tr>
            );
          }
        } else {
          if (this.state.nameFilter) {
            const numFiles = contents.length;
            contents = contents.slice(0, this.state.searchResultsShown);
            if (numFiles > contents.length) {
              contents.push(
                <tr key="show-more">
                  <td colSpan={100}>
                    <a onClick={this.handleShowMoreClick} href="#">
                      <FormattedMessage
                        defaultMessage={`Show more results`}
                        description="key file browser show more result"
                      />
                    </a>
                  </td>
                </tr>
              );
            }
          }
        }

        if (this.props.headerRenderer) {
          header = (
            <thead>
              <this.props.headerRenderer
                {...headerProps}
                {...this.props.headerRendererProps}
              />
            </thead>
          );
        }

        renderedFiles = (
          <table cellSpacing="0" cellPadding="0">
            {header}
            <tbody>{contents}</tbody>
          </table>
        );
        break;

      case "list":
        if (!contents.length) {
          if (this.state.nameFilter) {
            contents = (
              <p className="empty">
                <FormattedMessage
                  defaultMessage={`No files matching {nameFilter}`}
                  description="key file browser no search result"
                  values={{ nameFilter: this.state.nameFilter }}
                />
              </p>
            );
          } else {
            contents = <p className="empty">No files.</p>;
          }
        } else {
          let more;
          if (this.state.nameFilter) {
            const numFiles = contents.length;
            contents = contents.slice(0, this.state.searchResultsShown);
            if (numFiles > contents.length) {
              more = (
                <a onClick={this.handleShowMoreClick} href="#">
                  <FormattedMessage
                    defaultMessage={`Show more results`}
                    description="key file browser show more result"
                  />
                </a>
              );
            }
          }
          contents = (
            <div>
              <ul>{contents}</ul>
              {more}
            </div>
          );
        }

        if (this.props.headerRenderer) {
          header = (
            <this.props.headerRenderer
              {...headerProps}
              {...this.props.headerRendererProps}
            />
          );
        }

        renderedFiles = (
          <div>
            {header}
            {contents}
          </div>
        );
        break;
    }

    const ConfirmMultipleDeletionRenderer = this.props
      .confirmMultipleDeletionRenderer;

    return (
      <DndProvider backend={HTML5Backend}>
        <div className="rendered-react-keyed-file-browser">
          {this.props.actions}
          <div
            className="rendered-file-browser"
            ref={(el) => {
              this.browserRef = el;
            }}
          >
            {this.props.showActionBar && this.renderActionBar(selectedItems)}
            {this.state.activeAction === "delete" &&
              this.state.selection.length > 1 && (
                <ConfirmMultipleDeletionRenderer
                  handleDeleteSubmit={this.handleMultipleDeleteSubmit}
                />
              )}
            <div className="files">{renderedFiles}</div>
          </div>
          {this.state.previewFile !== null && (
            <this.props.detailRenderer
              file={this.state.previewFile}
              close={this.closeDetail}
              {...this.props.detailRendererProps}
            />
          )}
        </div>
      </DndProvider>
    );
  }
}

export default CustomFileBrowser;
