import { FormLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { ReferenceField } from "react-admin";
import { Constants } from "../../constants";
import { IInvoice, ITask } from "../../model";
import { priceFormatter } from "../../utils/money";

interface IInvoiceBudgetedFieldProps {
  record?: IInvoice;
}

export const InvoiceBudgetedField: React.FC<IInvoiceBudgetedFieldProps> = ({
  record,
}) => {
  return (
    <ReferenceField
      resource={Constants.INVOICE_RESOURCE}
      reference={Constants.TASK_RESOURCE}
      source="taskId"
      basePath={Constants.TASK_PATH}
      record={record}
      link={false}
      addLabel={false}
    >
      <BudgetField />
    </ReferenceField>
  );
};

interface IBudgetFieldProps {
  record?: ITask;
}

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 16,
  },
  label: {
    marginTop: 16,
  },
  number: {
    fontSize: 16,
  },
}));

const BudgetField: React.FC<IBudgetFieldProps> = ({ record }) => {
  const classes = useStyles({});
  return (
    <div className={classes.root}>
      <FormLabel component="legend" className={classes.label}>
        Budgeted Fee
      </FormLabel>
      <span className={classes.number}>
        {priceFormatter.format(Number(record.estimatedFee))}
      </span>
    </div>
  );
};
