import { Card } from "@blueprintjs/core";
import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { IClientCompany } from "../../model";
import * as DateUtils from "../../utils/date";
import { ClientCompanyDateFields } from "./ClientCompanyDateFields";

interface IClientCompanyDatesSectionProps {
  clientCompany: IClientCompany;
  onChange: (dates) => void;
}

interface ICascadingKeys {
  lastDateKey: string;
  nextDateKey: string;
}

export const ClientCompanyDatesSection = ({
  clientCompany,
  onChange,
}: IClientCompanyDatesSectionProps) => {
  const dispatch = useDispatch();
  const {
    firstAnnualGeneralMeetingDate,
    lastAnnualGeneralMeetingDate,
    nextAnnualGeneralMeetingDate,
    firstAnnualReturnDate,
    lastAnnualReturnDate,
    nextAnnualReturnDate,
    firstAuditDate,
    lastAuditDate,
    nextAuditDate,
    firstBusinessRegistrationRenewalDate,
    lastBusinessRegistrationRenewalDate,
    nextBusinessRegistrationRenewalDate,
    firstFinancialYearEnd,
    lastFinancialYearEnd,
    nextFinancialYearEnd,
    firstTaxFilingDate,
    lastTaxFilingDate,
    nextTaxFilingDate,
  } = clientCompany;

  const updateDate = (
    key: string,
    cascadingKeys: ICascadingKeys = { lastDateKey: null, nextDateKey: null }
  ) => (date: Date, isUserChange: boolean) => {
    if (isUserChange) {
      if (date) {
        onChange({
          ...clientCompany,
          [key]: DateUtils.formatDate(date),
        });
      } else {
        const { lastDateKey, nextDateKey } = cascadingKeys;
        onChange({
          ...clientCompany,
          [key]: null,
          [lastDateKey]: null,
          [nextDateKey]: null,
        });
      }
    }
  };

  const completeNextYear = (
    lastDateKey: string,
    newDateKey: string,
    dateString: string
  ) => () => {
    onChange({
      ...clientCompany,
      [lastDateKey]: DateUtils.formatDate(moment(dateString).toDate()),
      [newDateKey]: DateUtils.formatDate(
        moment(dateString).add(1, "year").toDate()
      ),
    });
  };

  return (
    <Card className="details">
      <div className="content">
        <Grid container={true} spacing={1}>
          <ClientCompanyDateFields
            dateName="Annual General Meeting Date"
            dateKey="annualGeneralMeetingDate"
            firstDate={firstAnnualGeneralMeetingDate}
            lastDate={lastAnnualGeneralMeetingDate}
            nextDate={nextAnnualGeneralMeetingDate}
            updateDate={updateDate}
            completeNextYear={completeNextYear}
          />
          <br />
          <ClientCompanyDateFields
            dateName="Annual Return Date"
            dateKey="annualReturnDate"
            firstDate={firstAnnualReturnDate}
            lastDate={lastAnnualReturnDate}
            nextDate={nextAnnualReturnDate}
            updateDate={updateDate}
            completeNextYear={completeNextYear}
          />
          <ClientCompanyDateFields
            dateName="Audit Date"
            dateKey="auditDate"
            firstDate={firstAuditDate}
            lastDate={lastAuditDate}
            nextDate={nextAuditDate}
            updateDate={updateDate}
            completeNextYear={completeNextYear}
          />
          <ClientCompanyDateFields
            dateName="Business Registration Renewal Date"
            dateKey="businessRegistrationRenewalDate"
            firstDate={firstBusinessRegistrationRenewalDate}
            lastDate={lastBusinessRegistrationRenewalDate}
            nextDate={nextBusinessRegistrationRenewalDate}
            updateDate={updateDate}
            completeNextYear={completeNextYear}
          />
          <ClientCompanyDateFields
            dateName="Financial Year-End"
            dateKey="financialYearEnd"
            firstDate={firstFinancialYearEnd}
            lastDate={lastFinancialYearEnd}
            nextDate={nextFinancialYearEnd}
            updateDate={updateDate}
            completeNextYear={completeNextYear}
          />
          <ClientCompanyDateFields
            dateName="Tax Filing Date"
            dateKey="taxFilingDate"
            firstDate={firstTaxFilingDate}
            lastDate={lastTaxFilingDate}
            nextDate={nextTaxFilingDate}
            updateDate={updateDate}
            completeNextYear={completeNextYear}
          />
        </Grid>
      </div>
    </Card>
  );
};
