import { UserUtil } from "@chiubaka/core";
import { pickBy } from "lodash";
import { IEtsState, IEtsUser } from "../../model";
import { getStyleFromUser } from "../../utils/user";
import { selectForModelById } from "./ModelSelect/ModelSelect";
import { alphabeticalSort } from "./ModelSelect/Sort";

const pickByUserDepartment = (state: IEtsState) =>
  pickBy(state.usersById, (user) => {
    const currentUser = state.usersById[state.auth.user.id];
    return user.department.id === currentUser.department.id;
  });

export const filteredUserSelect = (
  filter: Parameters<typeof selectForModelById>[6]
) =>
  selectForModelById(
    "user",
    (user: IEtsUser) => {
      return getStyleFromUser(user);
    },
    (user: IEtsUser) => UserUtil.displayValue(user, true),
    (user: IEtsUser) => null,
    pickByUserDepartment,
    undefined,
    filter,
    alphabeticalSort
  );

export const UserSelect = filteredUserSelect(
  (state: IEtsState, user: IEtsUser) => user.status === "active"
);

// For places in the UI where we want to conserve space.
export const UserCompactSelect = selectForModelById(
  "user-compact",
  (user: IEtsUser) => {
    return getStyleFromUser(user);
  },
  (user: IEtsUser) => UserUtil.displayValue(user, true),
  (user: IEtsUser) => null,
  pickByUserDepartment,
  (user) => user.abbreviation,
  (state: IEtsState, user: IEtsUser) => user.status === "active",
  alphabeticalSort
);
