import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { useSelector } from "react-redux";
import {
  IEtsState,
  INotification,
  INotificationProps,
  NotificationMode,
} from "../../model";
import { getDaysfromNow } from "../../utils/date";
import {
  PolymorphicFullNotification,
  PolymorphicShortNotification,
} from "./NotificationRender";

const NOTIFICATION_RENDER_MODE = {
  Full: PolymorphicFullNotification,
  Short: PolymorphicShortNotification,
};

export const updateTaskNotificationLine = (
  notification: INotification
): INotification => {
  const userId = useSelector((state: IEtsState) => state.auth.user.id);
  if (
    notification.parameters?.newAssigneeId &&
    notification.parameters?.newAssigneeId !== userId
  ) {
    if (notification.parameters?.notificationLine) {
      notification.parameters.notificationLine = "updated assignee of Job";
    }
  }
  if (
    notification.parameters?.newReviewerId &&
    notification.parameters?.newReviewerId != userId
  ) {
    if (notification.parameters?.notificationLine) {
      notification.parameters.notificationLine = "updated reviewer of Job";
    }
  }
  return notification;
};

export const TaskNotificationComponent: React.FC<INotificationProps> = ({
  mode = NotificationMode.Full,
  notification,
}) => {
  const notificationModeString = NotificationMode[mode];
  const Handler = NOTIFICATION_RENDER_MODE[notificationModeString];
  const taskNotification = updateTaskNotificationLine(notification);

  return (
    <div className="notify-container-right">
      <div className="notify-container-three">
        <Handler notification={taskNotification} mode={mode} />
      </div>
      <small>
        <Icon icon={IconNames.EDIT} iconSize={12} />
        <span className="bp3-button-text">
          {getDaysfromNow(notification.createdAt)}
        </span>
      </small>
    </div>
  );
};
