import { makeStyles } from "@material-ui/core";
import qs from "qs";
import { useDataProvider, useNotify } from "ra-core";
import React, { useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router";
import { ReactTypeformEmbed } from "react-typeform-embed";
import { Constants } from "../../constants";
import {
  getClientUser,
  getFormGroupResponse,
  setFormGroupResponse,
} from "../../core";
import { IFormGroupResponse } from "../../model";
import { history } from "../../store";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
});

export const ClientAppForm: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const formGroupResponse = getFormGroupResponse();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    async function updateCurrentForm() {
      const { data } = await dataProvider.update(
        Constants.FORM_GROUP_RESPONSE_RESOURCE,
        {
          id: formGroupResponse.id,
          data: { currentFormNo: match.params.id },
          previousData: formGroupResponse,
        }
      );
      if (!data) {
        return notify("update fail", "error");
      }
      return setFormGroupResponse(data as IFormGroupResponse);
    }
    updateCurrentForm();
  });

  const typeformEmbed = useRef(null);

  const clientUserEmail = encodeURIComponent(getClientUser().email);

  const classes = useStyles({});
  const qsString = history.location.search.slice(1);
  const params: any = qs.parse(decodeURIComponent(qsString));
  if (!clientUserEmail && !params.group_code) {
    return null;
  }

  return (
    <div className={classes.root}>
      <ReactTypeformEmbed
        popup={false}
        autoOpen={false}
        url={`${process.env.TYPEFORM_URL}/to/${match.params.id}?email=${clientUserEmail}&group_code=${params.group_code}`}
        hideHeaders={false}
        hideFooter={true}
        buttonText="Go!"
        style={{ top: 100 }}
        ref={typeformEmbed}
      />
    </div>
  );
};
