import React from "react";
import { priceFormatter } from "../../../utils/money";

export const WipExpenseField: React.FC<any> = ({ record, source }) => {
  const amount = priceFormatter.format(parseFloat(record[source]));
  const isSettle = source === "expenseSettled" || source === "wipSettled";
  return (
    <span>
      {isSettle && "-"}
      {amount}
    </span>
  );
};
