export function noSort(a, b) {
  return 0;
}

export function alphabeticalSort(a, b) {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  return 0;
}
