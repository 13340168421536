import { makeStyles } from "@material-ui/styles";
import { NumberInput } from "ra-ui-materialui";
import React, { FC } from "react";

const useStyles = makeStyles({
  root: {
    minWidth: "160px",
  },
  chip: {
    backgroundColor: "aliceblue",
  },
});

export const BalanceFilterInput: FC<any> = (props) => {
  const classes = useStyles(props);

  return <NumberInput {...props} classes={classes} />;
};
