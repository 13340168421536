import { Field } from "formik";
import * as React from "react";
import * as Yup from "yup";
import { Errors } from "../../constants";
import { DSFModel, TSFModel } from "../../model";
import { withDSFCreateForm } from "../hoc";
import { ClientGroupSelect, UserSelect } from "../select";
import { DSFForm, IFormProps } from "./DSFForm/DSFForm";

const CompanyDSFForm = withDSFCreateForm(
  DSFForm,
  DSFModel.Company,
  TSFModel.TenantCompany
) as any;

const CompanySchema = Yup.object().shape({
  clientId: Yup.string().required(Errors.REQUIRED),
  clientGroupId: Yup.string().required(Errors.REQUIRED),
  name: Yup.string().required(Errors.REQUIRED),
  chineseName: Yup.string().required(Errors.REQUIRED),
  jurisdiction: Yup.string().required(Errors.REQUIRED),
  contactName: Yup.string().required(Errors.REQUIRED),
  assigneeId: Yup.string().required(Errors.REQUIRED),
});

export class CompanyForm extends React.Component<IFormProps> {
  public render() {
    return (
      <CompanyDSFForm {...this.props} validationSchema={CompanySchema}>
        <div className="row">
          <div className="col-md">
            <Field
              name="model.clientId"
              render={DSFForm.renderInputGroup.bind(this, "Client ID")}
            />
          </div>
          <div className="col-md">
            <Field
              name="model.clientGroupId"
              render={DSFForm.renderModelSelect.bind(
                this,
                "Client Group",
                ClientGroupSelect
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <Field
              name="model.name"
              render={DSFForm.renderInputGroup.bind(this, "Name")}
            />
          </div>
          <div className="col-md-4">
            <Field
              name="model.chineseName"
              render={DSFForm.renderInputGroup.bind(this, "Chinese Name")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <Field
              name="model.jurisdiction"
              render={DSFForm.renderInputGroup.bind(this, "Jurisdiction")}
            />
          </div>
          <div className="col-md">
            <Field
              name="model.contactName"
              render={DSFForm.renderInputGroup.bind(this, "Contact Name")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <Field
              name="model.assigneeId"
              render={DSFForm.renderModelSelect.bind(
                this,
                "Assignee",
                UserSelect
              )}
            />
          </div>
        </div>
      </CompanyDSFForm>
    );
  }
}
