import { IEtsState, IRate } from "../../model";
import { selectForModelById } from "./ModelSelect/ModelSelect";
import { alphabeticalSort } from "./ModelSelect/Sort";

export const RateSelect = selectForModelById(
  "rate",
  (rate: IRate) => {
    return {};
  },
  (rate: IRate) => rate.name,
  (rate: IRate) => rate.name,
  (state: IEtsState) => state.ratesById,
  undefined,
  undefined,
  alphabeticalSort
);
