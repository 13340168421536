import { push } from "connected-react-router";
import { compact, flatten } from "lodash";
import * as qs from "qs";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Filter, SortingRule, TableProps } from "react-table";
import { Dispatch } from "../../../types";

interface IUrlQueryableTableStateProps {
  defaultSorted: SortingRule[];
  defaultFiltered: Filter[];
}

interface IUrlQueryableTableDispatchProps {
  onFilterOrSortTasks: (sorted: SortingRule[], filtered: Filter[]) => void;
}

interface IUrlQueryableTableProps
  extends TableProps,
    IUrlQueryableTableStateProps,
    IUrlQueryableTableDispatchProps {}

export function withUrlQueryableTable(
  WrappedComponent: React.ComponentClass<Partial<TableProps>>
) {
  class UrlQueryableTable extends React.Component<IUrlQueryableTableProps> {
    private wrappedInstance;
    constructor(props: IUrlQueryableTableProps) {
      super(props);
      this.handleFilteredChanged = this.handleFilteredChanged.bind(this);
      this.handleSortedChanged = this.handleSortedChanged.bind(this);
    }

    public render() {
      return (
        <WrappedComponent
          {...this.props}
          ref={(r) => (this.wrappedInstance = r)}
          onSortedChange={this.handleSortedChanged}
          onFilteredChange={this.handleFilteredChanged}
        />
      );
    }

    public getWrappedInstance() {
      if (!this.wrappedInstance) {
        console.warn("UrlQueryableTable - No wrapped instance");
      }
      if (this.wrappedInstance.getWrappedInstance) {
        return this.wrappedInstance.getWrappedInstance();
      }
      return this.wrappedInstance;
    }

    private handleSortedChanged(
      newSorted: SortingRule[],
      column: any,
      additive: boolean
    ) {
      const { onSortedChange } = this.props;
      if (onSortedChange) {
        onSortedChange(newSorted, column, additive);
      }
      const { sorted, filtered } = this.getWrappedInstance().state;
      this.props.onFilterOrSortTasks(sorted, filtered);
    }

    private handleFilteredChanged(
      newFiltering: Filter[],
      column: any,
      value: any
    ) {
      const { onFilteredChange } = this.props;
      if (onFilteredChange) {
        onFilteredChange(newFiltering, column, value);
      }
      const { sorted, filtered } = this.getWrappedInstance().state;
      this.props.onFilterOrSortTasks(sorted, filtered);
    }
  }

  function mapStateToProps(
    _,
    ownProps: RouteComponentProps
  ): IUrlQueryableTableStateProps {
    const parsed = qs.parse(ownProps.location.search?.slice(1) || "", {
      comma: true,
    });
    const defaultFiltered: Filter[] = Object.keys(parsed)
      .filter((key) => key !== "sort")
      .map((key) => ({ id: key, value: parsed[key] }));
    const defaultSorted: SortingRule[] = compact(flatten([parsed.sort])).map(
      (s) => {
        const [id, value] = (s as string).split(".");
        return {
          id,
          desc: value === "desc",
        };
      }
    );

    return {
      defaultFiltered,
      defaultSorted,
    };
  }

  function mapDispatchToProps(
    dispatch: Dispatch,
    ownProps: RouteComponentProps
  ) {
    return {
      onFilterOrSortTasks: (sorted: SortingRule[], filtered: Filter[]) => {
        const queryString = qs.stringify(
          {
            ...filtered.reduce(
              (obj, { id, value }) => ({ ...obj, [id]: value }),
              {}
            ),
            sort: sorted.map(
              ({ id, desc }) => `${id}.${desc ? "desc" : "asc"}`
            ),
          },
          { arrayFormat: "comma" }
        );
        const action = push(`${ownProps.location.pathname}?${queryString}`);
        dispatch(action);
      },
    };
  }

  const UrlQueryTableNoRouter = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
  )(UrlQueryableTable);
  const UrlQueryTableWithRouter = withRouter<any, typeof UrlQueryTableNoRouter>(
    UrlQueryTableNoRouter
  );
  // eslint-disable-next-line react/display-name
  return React.forwardRef<any, any>((props, ref) => (
    <UrlQueryTableWithRouter {...props} wrappedComponentRef={ref} />
  ));
}
