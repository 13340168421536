import { Button, Card } from "@blueprintjs/core";
import _ from "lodash";
import { useNotify, useRefresh } from "ra-core";
import React, { useState } from "react";
import { ImportantPersonCreateDialog } from "../../components/dialog/ImportantPersonCreateDialog";
import { IClientCompany } from "../../model";
import { ClientCompanyImportantPerson } from "./ClientCompanyImportantPerson";

interface IClientCompanyImportantPeopleProps {
  company: IClientCompany;
  data?: any;
}

export const ClientCompanyImportantPeople: React.FC<IClientCompanyImportantPeopleProps> = ({
  company,
  data: importantPeople = {},
}) => {
  const [isCreatingImportantPerson, setIsCreatingImportantPerson] = useState(
    false
  );
  const refresh = useRefresh();
  const notify = useNotify();

  const startCreatingImportantPerson = () => {
    setIsCreatingImportantPerson(true);
  };

  const finishCreatingImportantPerson = () => {
    setIsCreatingImportantPerson(false);
  };

  const handleCreateImportantPersonSuccess = () => {
    notify("ra.notification.created", "info", { smart_count: 1 });
    refresh();
    finishCreatingImportantPerson();
  };

  return (
    <>
      <Card>
        <div>
          <Button
            icon="add"
            text="Add Important Person"
            onClick={startCreatingImportantPerson}
            className="create-client-user-button"
          />
          {_.values(importantPeople).map((person, index) => (
            <ClientCompanyImportantPerson
              key={index}
              importantPerson={person}
            />
          ))}
        </div>
      </Card>
      <ImportantPersonCreateDialog
        isOpen={isCreatingImportantPerson}
        onClose={finishCreatingImportantPerson}
        canOutsideClickClose={true}
        clientGroupId={company.clientGroupId}
        clientCompanyId={company.id}
        onSuccess={handleCreateImportantPersonSuccess}
      />
    </>
  );
};
