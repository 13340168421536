import { Button, Classes, Intent, Tag } from "@blueprintjs/core";
import React from "react";
import { IAttachmentEntry, IEmailAttachment } from "../../model";

interface IAttachmentSaveProps {
  attachment: IAttachmentEntry | IEmailAttachment;
  location: string;

  onSave: (fileName: string) => void;
}

export const AttachmentSave: React.FC<IAttachmentSaveProps> = ({
  attachment,
  location,
  onSave,
}) => {
  const modifiedLocation =
    location === "" ? "Root" : location.substring(0, location.length - 1);

  const handleSaveClick = () => {
    onSave(attachment.fileName);
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <span>
          Do you want to save <Tag>{attachment.fileName}</Tag> into{" "}
          <Tag>{modifiedLocation}</Tag> ?
        </span>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <Button
          intent={Intent.PRIMARY}
          text="Confirm"
          type="submit"
          className="attachment-share-dialog-footer"
          onClick={handleSaveClick}
        />
      </div>
    </>
  );
};
