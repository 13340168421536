import { Button, Card, EditableText, Icon, Intent } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { IModelById } from "@chiubaka/core";
import * as React from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Action } from "redux";
import { Api, clearTaskSelection } from "../../actions";
import {
  AddTimeEntry,
  CompanySelect,
  HistoryTimeline,
  IDSFEditControllerComponentProps,
  InterviewLauncher,
  InterviewsList,
  RateSelect,
  StatusSelect,
  SubtaskCreator,
  SubtaskList,
  TimeEntryList,
  UserSelect,
  Warning,
  withDSFEditController,
} from "../../components";
import { Follow } from "../../components/Follow/Follow";
import { enabled } from "../../config/features";
import { Constants } from "../../constants";
import {
  DSFModel,
  IChatThread,
  IClientCompany,
  IClientGroup,
  IDepartmentSpecificFieldConfig,
  IEtsState,
  IEtsUser,
  IRate,
  IStatus,
  ISubtask,
  ISubtaskType,
  ITask,
  ITaskAdditionalData,
  ITaskType,
  ITimeEntry,
} from "../../model";
import { IEmail } from "../../model/IEmail";
import { canCreateSubtasks, permissionsForTask } from "../../selectors";
import { history } from "../../store";
import { Dispatch } from "../../types";
import * as DateUtils from "../../utils/date";
import { copyEmailAddress } from "../../utils/email";
import { CopyEmail } from "../CompanyDetail/CopyEmail";

interface ITaskDetailStateProps {
  assignee: IEtsUser;
  company: IClientCompany;
  enableSubtaskCreation: boolean;
  pic?: IEtsUser;
  permissions: { [property in keyof ITask]?: boolean };
  reviewer: IEtsUser;
  status: IStatus;
  subtaskTypesById: IModelById<ISubtaskType>;
  taskType: ITaskType;
  usersById: IModelById<IEtsUser>;
  clientGroupsById: IModelById<IClientGroup>;
  emails: IEmail[];
  chatThreads: IChatThread[];
  timeEntries: ITimeEntry[];
  rates: IRate[];
  rate: IRate;
  balanceEnabled: boolean;
  feeNoteEnabled: boolean;
  doctomateEnabled: boolean;
  userId: string;
}

interface ITaskDetailDispatchProps {
  onGetEmails: () => Promise<IEmail[]>;
  onGetTask: (id: string) => Promise<ITask>;
  onUpdateTask: (original: ITask, updated: Partial<ITask>) => Promise<ITask>;
  onUpdateSubtask: (original: ISubtask, updated: ISubtask) => Promise<ISubtask>;
  onDeleteSubtask: (subtask: ISubtask) => Promise<void>;
  onGetChatThreads: () => Promise<IChatThread[]>;
  onGetTimeEntries: () => Promise<ITimeEntry[]>;
  onClearSelectTasks: () => Action;
}

interface ITaskDetailProps
  extends ITaskDetailStateProps,
    ITaskDetailDispatchProps,
    IDSFEditControllerComponentProps<ITaskAdditionalData>,
    RouteComponentProps<any> {
  task: ITask;
}

interface ITaskDetailState {
  description?: string;
  editingCompany: boolean;
  notes?: string;
  departmentSpecificData: ITaskAdditionalData;
  timeEntrySubtask: ISubtask | null;
  code?: string;
  userId: string;
  userIsFollowing?: boolean;
}

export class TaskDetailImpl extends React.Component<
  ITaskDetailProps,
  ITaskDetailState
> {
  constructor(props: ITaskDetailProps) {
    super(props);
    this.state = {
      description: props.task.description,
      code: props.task.code,
      editingCompany: false,
      notes: props.task.notes,
      departmentSpecificData: {
        ...props.departmentSpecificData,
        taskId: props.task.id,
      },
      timeEntrySubtask: null,
      userId: props.userId,
      userIsFollowing: props.task.userIsFollowingTask,
    };
  }

  public componentDidMount() {
    this.props.onGetEmails();
    this.props.onGetChatThreads();
    this.props.onGetTimeEntries();
  }

  public componentDidUpdate(prevProps) {
    if (
      this.props.departmentSpecificData !== prevProps.departmentSpecificData
    ) {
      this.setState({
        ...this.state,
        departmentSpecificData: {
          ...this.state.departmentSpecificData,
          ...this.props.departmentSpecificData,
          taskId: this.props.task.id,
        },
      });
    }
    if (this.props.task !== prevProps.task) {
      this.props.onGetEmails();
      this.props.onGetChatThreads();
      this.props.onGetTimeEntries();
      this.setState({
        ...this.state,
        code: this.props.task.code,
        description: this.props.task.description,
        notes: this.props.task.notes,
        userIsFollowing: this.props.task.userIsFollowingTask,
      });
    }
  }

  public componentWillUnmount() {
    this.props.onClearSelectTasks();
  }

  public render = (): JSX.Element => {
    const {
      assignee,
      balanceEnabled,
      permissions,
      reviewer,
      pic,
      status,
      taskType,
      chatThreads,
      emails,
      rate,
      feeNoteEnabled,
      doctomateEnabled,
    } = this.props;

    if (!this.props.task) {
      return null;
    }

    const {
      startDate,
      dueDate,
      isComplete,
      reminderDate,
      id,
      emailAlias,
      createdAt,
      createdById,
    } = this.props.task;
    const {
      id: clientCompanyId,
      jurisdiction,
      clientGroupId,
      clientId,
    } = this.props.company;

    const {
      departmentSpecificData,
      description,
      notes,
      code,
      userIsFollowing,
    } = this.state;
    const { attachments, comments, versions } = this.props.task;
    const { group, name } = taskType;

    return (
      <div className="task-detail">
        <EditableText
          className="description"
          value={code}
          onChange={this.handleCodeChange}
          onConfirm={this.handleCodeConfirm}
          disabled={!permissions.code}
          placeholder="Job Code"
        />
        <div className="top-row">
          <div className="top-left">
            <Button
              className="is-complete"
              large={true}
              icon={isComplete ? "tick-circle" : "circle"}
              intent={isComplete ? Intent.SUCCESS : null}
              minimal={true}
              onClick={this.handleCompletionToggle}
              disabled={!permissions.isComplete}
            />
            <EditableText
              className="description"
              value={description}
              onChange={this.handleDescriptionChange}
              onConfirm={this.handleDescriptionConfirm}
              disabled={!permissions.description}
            />
          </div>
          <div className="status">
            <span className="field-label">Status: </span>
            <StatusSelect
              selectedId={status.id}
              onSelect={this.handleStatusSelect}
              disabled={!permissions.statusId}
            />
          </div>
        </div>
        <div className="name-and-clipboard-container">
          <div className="details">
            <div className="company">
              {this.renderCompanyName()}
              <span className="secondary-info">
                {clientId}, {this.displayClientGroup(clientGroupId)},{" "}
                {jurisdiction}
              </span>
            </div>
          </div>
          <div className="account-balance-copy-task-button">
            {balanceEnabled && this.renderAccountBalance()}
            {feeNoteEnabled && this.renderFeeNoteButton()}
            <CopyEmail
              emailAddress={copyEmailAddress("task-id", emailAlias)}
              type="Job"
            />
            <Follow
              followableId={id}
              type="Task"
              userIsFollowing={userIsFollowing}
              onClick={this.handleUpdateFollowingStatus}
            />
          </div>
        </div>
        <Card className="details">
          <table className="details-table">
            <tbody>
              <tr className="row-fields">
                <td className="bp3-running-text row-first-col">
                  <Icon icon="label" /> Job Type:
                </td>
                <td className="row-last-col bp3-running-text">
                  {name ? `${group}/${name}` : group}
                </td>
              </tr>
              <tr className="row-fields">
                <td className="bp3-running-text row-first-col">
                  <Icon icon="user" /> Assignee:
                </td>
                <td className="row-last-col">
                  <div className="row-flex">
                    <UserSelect
                      selectedId={assignee.id}
                      onSelect={this.handleAssigneeSelect}
                      disabled={!permissions.assigneeId}
                    />
                    {!!rate &&
                      !this.props.task.assigneeRate &&
                      this.renderWarningAssigneeRate()}
                  </div>
                </td>
              </tr>
              <tr className="row-fields">
                <td className="bp3-running-text row-first-col">
                  <Icon icon="zoom-in" /> Reviewer:
                </td>
                <td className="row-last-col">
                  <UserSelect
                    selectedId={reviewer.id}
                    onSelect={this.handleReviewerSelect}
                    disabled={!permissions.reviewerId}
                  />
                </td>
              </tr>
              <tr className="row-fields">
                <td className="bp3-running-text row-first-col">
                  <Icon icon="zoom-in" /> Partner:
                </td>
                <td className="row-last-col">
                  <UserSelect
                    selectedId={pic ? pic.id : ""}
                    onSelect={this.handlePicSelect}
                    disabled={!permissions.picId}
                  />
                </td>
              </tr>
              <tr className="row-fields">
                <td className="bp3-running-text row-first-col">
                  <Icon icon="dollar" /> Rate:
                </td>
                <td className="row-last-col">
                  <div className="row-flex">
                    <RateSelect
                      selectedId={rate ? rate.id : ""}
                      onSelect={this.handleRateSelect}
                      disabled={!permissions.rateId}
                    />
                    {!rate && this.renderWarningWithRate()}
                  </div>
                </td>
              </tr>
              <tr className="row-fields">
                <td className="bp3-running-text row-first-col">
                  <Icon icon="arrow-right" /> Start Date:
                </td>
                <td className="row-last-col">
                  <DateInput
                    className="start-date"
                    value={DateUtils.parseDate(startDate)}
                    formatDate={DateUtils.formatFriendlyDate}
                    parseDate={DateUtils.parseDateStrict}
                    maxDate={Constants.MAX_DATE}
                    onChange={this.handleStartDateChange}
                    disabled={!permissions.startDate}
                  />
                </td>
              </tr>
              <tr className="row-fields">
                <td className="bp3-running-text row-first-col">
                  <Icon icon="error" /> Due Date:
                </td>
                <td className="row-last-col">
                  <DateInput
                    className="due-date"
                    value={DateUtils.parseDate(dueDate)}
                    formatDate={DateUtils.formatFriendlyDate}
                    parseDate={DateUtils.parseDateStrict}
                    maxDate={Constants.MAX_DATE}
                    onChange={this.handleDueDateChange}
                    disabled={!permissions.dueDate}
                  />
                </td>
              </tr>
              <tr className="row-fields">
                <td className="bp3-running-text row-first-col">
                  <Icon icon="notifications" /> Reminder Date:
                </td>
                <td className="row-last-col">
                  <DateInput
                    className="reminder-date"
                    value={DateUtils.parseDate(reminderDate)}
                    formatDate={DateUtils.formatFriendlyDate}
                    parseDate={DateUtils.parseDateStrict}
                    maxDate={Constants.MAX_DATE}
                    onChange={this.handleReminderDateChange}
                    disabled={!permissions.reminderDate}
                  />
                </td>
              </tr>
              {this.renderDepartmentSpecificFields()}
            </tbody>
          </table>
        </Card>
        {doctomateEnabled && (
          <Card className="interviews">
            <h6>
              <Icon icon="build" /> EzAudit
            </h6>
            <InterviewLauncher task={this.props.task} />
            <InterviewsList task={this.props.task} />
          </Card>
        )}
        <Card className="notes">
          <h6>
            <Icon icon="manually-entered-data" /> Notes
          </h6>
          <EditableText
            value={notes}
            minLines={3}
            multiline={true}
            onChange={this.handleNotesChange}
            onConfirm={this.handleNotesConfirm}
            disabled={!permissions.notes}
          />
        </Card>
        <Card className="time-entry">
          <h6>
            <Icon icon="time" /> Time Entry
          </h6>
          <AddTimeEntry
            subtask={this.state.timeEntrySubtask}
            taskId={this.props.task.id}
            history={this.props.history}
            onTimeSubtask={this.handleTimeSubtask}
          />
          <TimeEntryList timeEntries={this.props.timeEntries} />
        </Card>
        <Card>{this.renderSubtasks()}</Card>
        <br />
        <Card>
          <HistoryTimeline
            resource={Constants.TASK_RESOURCE}
            basePath={Constants.TASK_PATH}
            attachments={attachments}
            comments={comments}
            departmentSpecificFieldsVersions={departmentSpecificData.versions}
            versions={versions}
            chatThreads={chatThreads}
            emails={emails}
            entryType="Task"
            entryId={id}
            createdAt={createdAt}
            createdById={createdById}
            clientCompanyId={clientCompanyId}
          />
        </Card>
      </div>
    );
  };
  private displayClientGroup = (clientGroupId: string) => {
    return this.props.clientGroupsById[clientGroupId].name;
  };
  private renderCompanyName = () => {
    const canEditCompany = this.props.permissions.clientCompanyId;
    const { id, name, chineseName } = this.props.company;

    if (this.state.editingCompany) {
      return (
        <div className="name">
          <CompanySelect selectedId={id} onSelect={this.handleCompanyChange} />
          <Button
            icon="cross"
            minimal={true}
            onClick={this.finishEditingCompany}
          />
        </div>
      );
    }

    const editButton = canEditCompany ? (
      <Button icon="edit" minimal={true} onClick={this.startEditingCompany} />
    ) : null;

    return (
      <div className="name">
        <Link to={`${Constants.COMPANY_DETAIL_PATH}/${id}`}>
          {name} ({chineseName})
        </Link>
        {editButton}
      </div>
    );
  };

  private renderAccountBalance = () => {
    const to = () =>
      history.push(`${Constants.ACCOUNT_BALANCE_PATH}/${this.props.task.id}`);
    return (
      <Button icon="dollar" alignText="right" onClick={to}>
        Account Balance
      </Button>
    );
  };

  private renderFeeNoteButton = () => {
    const { id: taskId } = this.props.task;
    const to = () =>
      history.push(
        `${Constants.INVOICE_PATH}/create?source=${JSON.stringify({ taskId })}`
      );
    return (
      <Button icon="th" alignText="right" onClick={to}>
        Create Fee Note
      </Button>
    );
  };

  private renderDepartmentSpecificFields = () => {
    const fieldConfigs = this.props.departmentSpecificFieldConfigs;

    if (!fieldConfigs || fieldConfigs.length === 0) {
      return;
    }

    return fieldConfigs.map(this.renderDepartmentSpecificField);
  };

  private renderDepartmentSpecificField = (
    fieldConfig: IDepartmentSpecificFieldConfig
  ) => {
    const { name, field, icon } = fieldConfig;

    const params = {
      fieldConfig,
      data: this.state.departmentSpecificData,
    };

    return (
      <tr className="row-fields" key={field}>
        <td className="bp3-running-text row-first-col">
          <Icon icon={icon} /> {name}:
        </td>
        <td className="row-last-col">{this.props.renderDSFEditor(params)}</td>
      </tr>
    );
  };

  private renderSubtasks = () => {
    if (!this.props.task.subtasks) {
      return;
    }
    return (
      <div>
        <SubtaskList
          onDeleteSubtask={this.props.onDeleteSubtask}
          onUpdateSubtask={this.props.onUpdateSubtask}
          subtasks={this.props.task.subtasks}
          onTimeSubtask={this.handleTimeSubtask}
        />
        {this.renderSubtaskCreation()}
      </div>
    );
  };

  private renderSubtaskCreation = () => {
    const { enableSubtaskCreation, task } = this.props;

    if (!enableSubtaskCreation) {
      return null;
    }

    return (
      <SubtaskCreator
        taskId={task.id}
        newSubtaskOrder={this.getNewSubtaskOrder()}
      />
    );
  };

  private handleCompletionToggle = () => {
    this.handleUpdateTask({ isComplete: !this.props.task.isComplete });
  };

  private handleDescriptionChange = (description: string) => {
    this.setState({ ...this.state, description });
  };

  private handleCodeChange = (code: string) => {
    this.setState({ ...this.state, code });
  };

  private startEditingCompany = () => {
    this.setState({ ...this.state, editingCompany: true });
  };

  private finishEditingCompany = () => {
    this.setState({ ...this.state, editingCompany: false });
  };

  private handleCompanyChange = async (company: IClientCompany) => {
    await this.handleUpdateTask({ clientCompanyId: company.id });
    this.setState({ ...this.state, editingCompany: false });
  };

  private handleRateSelect = (rate: IRate) => {
    return this.handleUpdateTask({ rateId: rate.id });
  };

  private handleCodeConfirm = (code: string) => {
    this.handleUpdateTask({ code });
  };

  private handleDescriptionConfirm = (description: string) => {
    this.handleUpdateTask({ description });
  };

  private handleAssigneeSelect = (assignee: IEtsUser) => {
    return this.handleUpdateTask({ assigneeId: assignee.id });
  };

  private handleReviewerSelect = (reviewer: IEtsUser) => {
    return this.handleUpdateTask({ reviewerId: reviewer.id });
  };

  private handlePicSelect = (pic: IEtsUser) => {
    return this.handleUpdateTask({ picId: pic.id });
  };

  private handleStatusSelect = (status: IStatus) => {
    return this.handleUpdateTask({ statusId: status.id });
  };

  private handleStartDateChange = (selectedDate: Date) => {
    this.handleUpdateTask({ startDate: DateUtils.formatDate(selectedDate) });
  };

  private handleDueDateChange = (selectedDate: Date) => {
    this.handleUpdateTask({ dueDate: DateUtils.formatDate(selectedDate) });
  };

  private handleReminderDateChange = (selectedDate: Date) => {
    this.handleUpdateTask({ reminderDate: DateUtils.formatDate(selectedDate) });
  };

  private handleNotesChange = (notes: string) => {
    this.setState({ ...this.state, notes });
  };

  private handleNotesConfirm = (notes: string) => {
    this.handleUpdateTask({ notes });
  };

  private handleTimeSubtask = (subtask: ISubtask) => {
    this.setState({
      timeEntrySubtask: subtask,
    });
  };

  private handleUpdateTask = (update: Partial<ITask>) => {
    const original = this.props.task;
    return this.props.onUpdateTask(original, { id: original.id, ...update });
  };

  private handleUpdateFollowingStatus = (userIsFollowing) => {
    this.setState({
      ...this.state,
      userIsFollowing,
    });
    return this.props.onGetTask(this.props.task.id);
  };

  private getNewSubtaskOrder = () => {
    const subtasks = this.props.task.subtasks || [];
    // Last subtask in list has the highest order. Add 1000 to it for good measure.
    return (subtasks.length ? subtasks[subtasks.length - 1].order : 0) + 1000;
  };

  private renderWarningWithRate = () => (
    <Warning text="Please select the task rate" />
  );

  private renderWarningAssigneeRate = () => (
    <Warning text="This user has no user rate" />
  );
}

function mapStateToProps(state: IEtsState, ownProps): ITaskDetailStateProps {
  const task = ownProps.task;

  const usersById = state.usersById;
  const assignee = state.usersById[task.assigneeId];
  const reviewer = state.usersById[task.reviewerId];
  const pic = state.usersById[task.picId];
  const company = state.clientCompaniesById[task.clientCompanyId];
  const status = state.statusesById[task.statusId];
  const subtaskTypesById = {};
  const taskType = state.taskTypesById[task.taskTypeId];
  const emails = state.emails;
  const chatThreads = state.chatThreads;
  const timeEntries = state.timeEntries;
  const rates = state.rates;
  const rate = state.ratesById[task.rateId];
  const roles = state.auth.roles;
  const userId = state.auth.user.id;
  const balanceEnabled =
    enabled("timebill.reports", roles) ||
    userId === task.picId ||
    userId === task.reviewerId ||
    userId === company.assigneeId ||
    userId === company.eicId;
  const feeNoteEnabled = enabled("timebill.invoice", roles);
  const doctomateEnabled = enabled("doctomate", roles);

  return {
    assignee,
    enableSubtaskCreation: canCreateSubtasks(state),
    reviewer,
    pic,
    company,
    permissions: permissionsForTask(task)(state),
    status,
    subtaskTypesById,
    taskType,
    usersById,
    clientGroupsById: state.clientGroupsById,
    emails,
    chatThreads,
    timeEntries,
    rates,
    rate,
    balanceEnabled,
    feeNoteEnabled,
    doctomateEnabled,
    userId,
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  { task }: ITaskDetailProps
): ITaskDetailDispatchProps {
  return {
    onGetEmails: () => {
      return dispatch(Api.Email.getAllCustomSuffix(task.id));
    },
    onGetTask: (id: string) => {
      return dispatch(Api.Task.get(id));
    },
    onUpdateTask: (original: ITask, updated: Partial<ITask>) => {
      return dispatch(Api.Task.update(original, updated));
    },
    onUpdateSubtask: (original: ISubtask, updated: Partial<ISubtask>) => {
      return dispatch(Api.Subtask.update(original, updated)).then(
        (result: ISubtask) => {
          dispatch(Api.Task.get(updated.taskId));
          return result;
        }
      );
    },
    onDeleteSubtask: (subtask: ISubtask) => {
      return dispatch(Api.Subtask.delete(subtask)).then(() => {
        dispatch(Api.Task.get(subtask.taskId));
        return;
      });
    },
    onGetChatThreads: () => {
      return dispatch(
        Api.ChatThread.getAllCustomPath(`/api/tasks/${task.id}/chat_threads`)
      );
    },
    onGetTimeEntries: () => {
      return dispatch(
        Api.TimeEntry.getAllCustomPath(`/api/tasks/${task.id}/time_entries`)
      );
    },
    onClearSelectTasks: () => {
      return dispatch(clearTaskSelection());
    },
  };
}

export const TaskDetailNoRouter = connect(
  mapStateToProps,
  mapDispatchToProps
)(withDSFEditController(TaskDetailImpl, DSFModel.Task));

export const TaskDetail = withRouter(TaskDetailNoRouter);
