import * as React from "react";
import { IModel } from "../../core";
import { ITask } from "../../model";
import { IModelSelectOwnProps } from "../select";
import { ICellInfo, IEditableCellProps } from "./types";

export function editableCellForLinkedModel<T extends IModel>(
  ModelSelect: React.ComponentType<IModelSelectOwnProps<T>>,
  linkedModelIdFromCellInfo: (cellInfo: ICellInfo<ITask, string>) => string
) {
  class EditableLinkedModelCell extends React.Component<
    IEditableCellProps<ITask, string>
  > {
    constructor(props: IEditableCellProps<ITask, string>) {
      super(props);

      this.handleSelect = this.handleSelect.bind(this);
    }

    public shouldComponentUpdate(nextProps, _nextState) {
      return (
        this.props.cellInfo.value !== nextProps.cellInfo.value ||
        this.props.loading !== nextProps.loading
      );
    }

    public render(): JSX.Element {
      const { cellInfo, disabled, loading, query } = this.props;

      return (
        <ModelSelect
          selectedId={linkedModelIdFromCellInfo(cellInfo)}
          onSelect={this.handleSelect}
          query={query}
          disabled={disabled || loading || cellInfo.original.isComplete}
        />
      );
    }

    private handleSelect(item: T): Promise<any> {
      const id = this.props.cellInfo.original.id;
      const newLinkedModelId = item.id;
      return this.props.onFinishEditing(id, newLinkedModelId);
    }
  }

  return EditableLinkedModelCell;
}
