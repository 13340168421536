import { InputGroup } from "@blueprintjs/core";
import { makeStyles } from "@material-ui/core";
import React, { ChangeEvent } from "react";

interface IDescriptionInput {
  description: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const useStyle = makeStyles({
  leftTitle: {
    width: "110px",
  },
  input: {
    width: "500px",
  },
});

export const DescriptionInput: React.FC<IDescriptionInput> = ({
  description,
  onChange,
}) => {
  const classes = useStyle({});
  return (
    <>
      <span className={classes.leftTitle}>Description:</span>
      <InputGroup
        className={classes.input}
        onChange={onChange}
        value={description}
      />
    </>
  );
};
