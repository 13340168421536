import * as React from "react";
import { EditableText } from "../../components/EditableText";
import { ITask } from "../../model";
import { IEditableCellProps } from "./types";

interface IEditableTextCellState {
  value: string;
}

export class EditableTextCell extends React.Component<
  IEditableCellProps<ITask, string>,
  IEditableTextCellState
> {
  constructor(props: IEditableCellProps<ITask, string>) {
    super(props);

    this.state = {
      value: props.cellInfo.value,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  public shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.cellInfo.value !== nextProps.cellInfo.value ||
      this.props.loading !== nextProps.loading ||
      this.state.value !== nextState.value
    );
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.cellInfo.value !== this.props.cellInfo.value) {
      this.setState({
        ...this.state,
        value: this.props.cellInfo.value,
      });
    }
  }

  public render(): JSX.Element {
    const { cellInfo, disabled, loading, query } = this.props;
    const value = this.state.value;

    return (
      <EditableText
        className={loading ? "loading" : null}
        onChange={this.handleChange}
        onConfirm={this.handleConfirm}
        value={value}
        // There is a bug with Blueprint where having multi-line text content
        // without having multiline and maxLines set results in divs with height
        // set with ridiculously large values
        multiline={true}
        maxLines={1}
        query={query}
        disabled={disabled || loading || cellInfo.original.isComplete}
        placeholder={disabled ? "" : undefined}
      />
    );
  }

  private handleChange(value: string) {
    this.setState({ ...this.state, value });
  }

  private handleConfirm(value: string) {
    const id = this.props.cellInfo.original.id;
    this.props.onFinishEditing(id, value);
  }
}
