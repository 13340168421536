import { Grid } from "@material-ui/core";
import { Toolbar } from "ra-ui-materialui";
import React from "react";
import { BackButton, EditToolBar } from "../../EditToolbar";

export const WriteOffEditToolBar = (props) => {
  const { record, resource } = props;

  if (record.invoiceId) {
    return (
      <Toolbar {...props}>
        <Grid item={true} xs={6}>
          <BackButton type={resource} />
        </Grid>
      </Toolbar>
    );
  }

  return <EditToolBar {...props} />;
};
