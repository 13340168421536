import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import _ from "lodash";
import { useGetManyReference } from "ra-core";
import React from "react";
import { Constants } from "../../constants";
import { IClientUser } from "../../model/IClientUserAuth";
import { ClientUserListItem } from "./ClientUserListItem";

interface IClientUserListProps {
  clientGroupId: string;
}

export const ClientUserList: React.FC<IClientUserListProps> = ({
  clientGroupId,
}) => {
  const { data: rawData, loading: dataLoading, loaded } = useGetManyReference(
    Constants.CLIENT_USER_RESOURCE,
    Constants.CLIENT_GROUP_RESOURCE,
    clientGroupId,
    { page: 1, perPage: 100 },
    { field: "", order: "" },
    {},
    Constants.CLIENT_GROUP_RESOURCE
  );

  const clientUsers = _.values(rawData) as IClientUser[];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Status</TableCell>
            <TableCell align="left">Created At</TableCell>
            <TableCell align="right">Login Link</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientUsers.map((user) => (
            <ClientUserListItem clientUser={user} key={user.id} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
