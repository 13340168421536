import { Spinner } from "@blueprintjs/core";
import CheckIcon from "@material-ui/icons/Check";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useDataProvider } from "ra-core";
import React, { useState } from "react";
import CardSection from "./CardSection";
import { ISubscriptionCheckoutDialogProps } from "./SubscriptionCheckoutDialog";

interface IError {
  children?: string;
  message?: string;
}

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="stripeCheckoutFormRow">
    <label htmlFor={id} className="stripeCheckoutFormRowLabel">
      {label}
    </label>
    <input
      className="stripeCheckoutFormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

export const PaymentSuccess: React.FC<ISubscriptionCheckoutDialogProps> = (
  props
) => {
  const { monthlyPaymentAmount } = props.subscriptionDetails;

  return (
    <div className="stripeCheckoutResult">
      <div className="stripeCheckoutResultTitle" role="alert">
        Payment successful
      </div>
      <div className="stripeCheckoutResultMessage">
        Thank you for your payment of ${monthlyPaymentAmount} USD. A recurring
        payment of ${monthlyPaymentAmount} USD will be billed on a monthly
        cycle. Please login again for the change in your account status to take
        effect. You will be redirected to the login page once you exit this
        popup.
      </div>
    </div>
  );
};

const ErrorMessage: React.FC<IError> = (error) => {
  return (
    <div className="stripeCheckoutErrorMessage" role="alert">
      {error?.children || error?.message}
    </div>
  );
};

export const CheckoutForm: React.FC<ISubscriptionCheckoutDialogProps> = (
  props
) => {
  const stripe = useStripe();
  const elements = useElements();
  const dataProvider = useDataProvider();
  const { monthlyPaymentAmount, tenantNumUsers } = props.subscriptionDetails;
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const unionPayLogo = require("./unionPay.svg") as string;
  const mastercardLogo = require("./mastercard.svg") as string;
  const amexLogo = require("./amex.svg") as string;
  const visaLogo = require("./visa.svg") as string;
  const checkIconStyle = {
    display: "block",
    margin: "auto",
  };

  const [billingDetails, setBillingDetails] = useState({
    email: "",
    name: "",
  });

  const handleSubscribe = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (
      !stripe ||
      !elements ||
      !billingDetails.name ||
      !billingDetails.email ||
      !monthlyPaymentAmount ||
      !tenantNumUsers
    ) {
      setLoading(false);
      return;
    }

    try {
      const customerData = await dataProvider.createStripeCustomer(
        billingDetails
      );
      const paymentMethod = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      });
      if (paymentMethod.error) {
        setLoading(false);
        setError(paymentMethod.error.message);
        return;
      }
      await dataProvider.createStripeSubscription({
        customerId: customerData.data.id,
        paymentMethodId: paymentMethod.paymentMethod.id,
      });
      props.enableEscapeKeyDown();
      props.enableBackdropClick();
      props.renderPaymentSuccess();
    } catch (result) {
      setError(result.data.error);
    }
    setLoading(false);
  };

  return (
    <form className="stripeCheckoutForm" onSubmit={handleSubscribe}>
      <fieldset className="stripeCheckoutFormGroup">
        <div className="stripeCheckoutFormTitle">Your Trial Has Ended</div>
        <div className="stripeCheckoutFormRow">
          Please subscribe to Easytrack for the {tenantNumUsers} number of users
          in your tenant. The monthly cost for each user is $50 USD for a total
          of ${monthlyPaymentAmount} USD per month.
        </div>
        <div className="stripeCheckoutFormRow">
          Below are the full features you will be able to enjoy after
          subscribing to Easytrack.ai!
        </div>
        <table className="stripeCheckoutPricingTable">
          <tr className="tableHeader">
            <th></th>
            <th>Features</th>
          </tr>
          <tr>
            <td>Job and subtasks management</td>
            <td>
              <CheckIcon style={checkIconStyle} />
            </td>
          </tr>
          <tr>
            <td>Staff allocation and assignment</td>
            <td>
              <CheckIcon style={checkIconStyle} />
            </td>
          </tr>
          <tr>
            <td>Automatic job-type specific checklist</td>
            <td>
              <CheckIcon style={checkIconStyle} />
            </td>
          </tr>
          <tr>
            <td>Delay detection & prediction</td>
            <td>
              <CheckIcon style={checkIconStyle} />
            </td>
          </tr>
          <tr>
            <td>Correspondence and documentation storage management</td>
            <td>
              <CheckIcon style={checkIconStyle} />
            </td>
          </tr>
          <tr>
            <td>Email, chat messages and attachment upload</td>
            <td>
              <CheckIcon style={checkIconStyle} />
            </td>
          </tr>
          <tr>
            <td>
              On-the-go retrieval and preview through smart search functionality
            </td>
            <td>
              <CheckIcon style={checkIconStyle} />
            </td>
          </tr>
          <tr>
            <td>Full record retention of all project history</td>
            <td>
              <CheckIcon style={checkIconStyle} />
            </td>
          </tr>
          <tr>
            <td>Interactive business metrics dashboard</td>
            <td>
              <CheckIcon style={checkIconStyle} />
            </td>
          </tr>
          <tr>
            <td>Firms and teams performance indicators</td>
            <td>
              <CheckIcon style={checkIconStyle} />
            </td>
          </tr>
          <tr>
            <td>Profitability tracking breakdown by service line and user</td>
            <td>
              <CheckIcon style={checkIconStyle} />
            </td>
          </tr>
          <tr>
            <td>Chatbot &quot;Whatsapp Forwarding&quot;</td>
            <td>
              <CheckIcon style={checkIconStyle} />
            </td>
          </tr>
        </table>
        <div className="stripeCheckoutFormRow">
          <Field
            label="Name"
            id="name"
            type="text"
            placeholder="Jane Doe"
            required
            autoComplete="name"
            value={billingDetails.name}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, name: e.target.value });
            }}
          />
        </div>
        <div className="stripeCheckoutFormRow">
          <Field
            label="Email"
            id="email"
            type="email"
            placeholder="janedoe@gmail.com"
            required
            autoComplete="email"
            value={billingDetails.email}
            onChange={(e) => {
              setBillingDetails({ ...billingDetails, email: e.target.value });
            }}
          />
        </div>
        <CardSection />
        <div className="stripeCheckoutFormRow">
          We support the following credit cards:
        </div>
        <div className="stripeCheckoutCreditCardContainer">
          <div className="stripeCheckoutCreditCardLogo">
            <img src={unionPayLogo} />
          </div>
          <div className="stripeCheckoutCreditCardLogo">
            <img src={mastercardLogo} />
          </div>
          <div className="stripeCheckoutCreditCardLogo">
            <img src={visaLogo} />
          </div>
          <div className="stripeCheckoutCreditCardLogo">
            <img src={amexLogo} />
          </div>
        </div>
        <div className="stripeCheckoutPlanSummaryContainer">
          <span className="stripeCheckoutPlanSummaryTitle">Plan Summary</span>
          <div className="stripeCheckoutLicenseRow">
            <div className="stripeCheckoutLicenseLineItem">
              {tenantNumUsers} EasyTrack User License
            </div>
            <div className="stripeCheckoutPaymentLineItem">
              $ {monthlyPaymentAmount} USD per month
            </div>
          </div>
          <div className="stripeCheckoutTotalRow">
            <div className="stripeCheckoutTotalLineItem">Total</div>
            <div className="stripeCheckoutPaymentLineItem">
              $ {monthlyPaymentAmount} USD per month
            </div>
          </div>
        </div>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <button
          className="stripeCheckoutSubmitButton"
          disabled={!stripe || isLoading}
        >
          {isLoading ? (
            <Spinner size={20} />
          ) : (
            <span>Subscribe for ${monthlyPaymentAmount} USD per month</span>
          )}
        </button>
      </fieldset>
    </form>
  );
};
