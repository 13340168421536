import { Create, ReferenceField, SimpleForm } from "ra-ui-materialui";
import React from "react";
import { Constants } from "../../constants";
import { PostCreateToolbar } from "../CreateToolBar";
import { ClientCompanyField, TaskField } from "../fields";
import { WriteOffSelectInput } from "./CustomField/WriteOffSelectInput";

const initialValues = { date: new Date() };

export const WriteOffCreate: React.FC<any> = (props) => {
  return (
    <Create {...props} location={{}}>
      <SimpleForm initialValues={initialValues} toolbar={<PostCreateToolbar />}>
        <ReferenceField
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          addLabel={false}
          link={false}
        >
          <TaskField />
        </ReferenceField>
        <ReferenceField
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          addLabel={false}
          link={false}
        >
          <ClientCompanyField />
        </ReferenceField>
        <WriteOffSelectInput />
      </SimpleForm>
    </Create>
  );
};
