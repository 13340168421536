import { Button } from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
import { makeStyles } from "@material-ui/styles";
import * as jsonExport from "jsonexport/dist";
import qs from "qs";
import { fetchEnd, fetchStart } from "ra-core";
import React, { useCallback } from "react";
import { downloadCSV } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../../constants";
import { Api } from "../../core/api/actions";
import { IEtsState } from "../../model";
import { formatSlashDate, parseDate } from "../../utils/date";
import { priceFormatter } from "../../utils/money";

const paymentCsv = (payments) => {
  const postsForExport = payments.map((payment) => {
    return {
      "Date": formatSlashDate(parseDate(payment.date)),
      "Client Company": payment.clientCompany,
      "Job": payment.job,
      "Created By": payment.createdBy,
      "Fee Note": payment.feeNoteNumber,
      "Payment Type": payment.paymentType,
      "Wip Amount": priceFormatter.format(payment.wipAmount),
      "Expense Amount": priceFormatter.format(payment.expenseAmount),
      "Internal Remarks": payment.internalRemarks,
      "EIC": payment.eic,
      "PIC": payment.pic,
    };
  });

  jsonExport(postsForExport, {}, (err, csv) => {
    downloadCSV(csv, "payment_report");
  });
};

interface IPaymentExportButtonProps {
  filterValues: any;
  disabled: boolean;
}

const useStyles = makeStyles({
  button: {
    display: "inline-flex",
    alignItems: "center",
  },
});

export const PaymentExportButton: React.FC<IPaymentExportButtonProps> = ({
  filterValues,
  disabled,
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state: IEtsState) => state.auth.token);
  const classes = useStyles({});

  const filters = qs.stringify({ filter: filterValues });
  const query = `?export=true&${filters}`;
  const handleClick = useCallback(async () => {
    dispatch(fetchStart());
    const result = await fetch(`${Constants.PAYMENTS_API_PATH}${query}`, {
      method: "GET",
      headers: Api.apiHeaders(token),
    });
    const data = await result.json();
    paymentCsv(data);
    dispatch(fetchEnd());
  }, [filterValues, token]);

  return (
    <Button
      color="primary"
      size="small"
      className={classes.button}
      startIcon={<DownloadIcon />}
      onClick={handleClick}
      disabled={disabled}
    >
      <span>EXPORT</span>
    </Button>
  );
};
