import { createStyles, makeStyles, Theme } from "@material-ui/core";
import qs from "qs";
import { useDataProvider, useNotify, useQuery, useRedirect } from "ra-core";
import React, { useEffect } from "react";
import { Constants } from "../../constants";
import { getClientUser, setFormGroupResponse } from "../../core";
import { IFormGroupResponse } from "../../model";
import { history } from "../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    content: {
      display: "flex",
      flexDirection: "column",
    },
    title: {
      fontFamily: "Arial",
      textAlign: "center",
      fontSize: "85px",
    },
    pictureBlock: {
      display: "flex",
      justifyContent: "center",
      width: " 100%",
      height: " 100%",
    },
    picture: {
      width: "200px",
      height: "200px",
    },
    footer: {
      fontFamily: "Arial",
    },
  })
);

export const ClientAppForms: React.FC = () => {
  const dataProvider = useDataProvider();
  const qsString = history.location.search.slice(1);
  const params: any = qs.parse(decodeURIComponent(qsString));
  const clientUser = getClientUser();
  const redirect = useRedirect();
  const notify = useNotify();

  const classes = useStyles({});

  const { data: formGroupResponses, loading, error } = useQuery({
    type: "getList",
    resource: Constants.FORM_GROUP_RESPONSE_RESOURCE,
    payload: {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "", order: "" },
      filter: { clientUserId: clientUser.id, groupCode: params.group_code },
    },
  });

  useEffect(() => {
    async function updateDone() {
      const { data } = await dataProvider.update(
        Constants.FORM_GROUP_RESPONSE_RESOURCE,
        {
          id: formGroupResponses[0].id,
          data: { status: "finished" },
          previousData: formGroupResponses,
        }
      );
      if (!data) {
        return notify("update fail", "error");
      }
    }

    const redirectToForm = () => {
      const record = formGroupResponses[0];
      if (record?.status == "unfinished") {
        setFormGroupResponse(record as IFormGroupResponse);
        return redirect(
          `${Constants.CLIENT_APP_PATH}/forms/${record.currentForm.formNo}?group_code=${params.group_code}`
        );
      } else {
        redirect(Constants.CLIENT_APP_PATH);
        return notify("The group code is failed", "error");
      }
    };

    if (params.hasOwnProperty("group_code") && !loading) {
      if (params.hasOwnProperty("done")) {
        updateDone();
      } else {
        redirectToForm();
      }
    }
  });

  return (
    <>
      {params.hasOwnProperty("done") ? (
        <div className={classes.root}>
          <div className={classes.content}>
            <span className={classes.title}>Thank You!</span>
            <div className={classes.pictureBlock}>
              <img
                className={classes.picture}
                src="https://easytrack.sgp1.cdn.digitaloceanspaces.com/correct_icon.jpg"
              />
            </div>
            <h3 className={classes.footer}>
              Thank you for filling out the form, we will contact you later. You
              can close this window
            </h3>
          </div>
        </div>
      ) : null}
    </>
  );
};
