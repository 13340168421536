import { Theme, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import React from "react";
import {
  FormDataConsumer,
  number,
  NumberInput,
  required,
  SelectInput,
  SimpleFormIterator,
} from "react-admin";
import { LineItemCategoryInput } from "./LineItemCategoryInput";
import { LineItemCodeInput } from "./LineItemCodeInput";
import { LineItemDescriptionInput } from "./LineItemDescriptionInput";
import { LineItemTemplateInput } from "./LineItemTemplateInput";

const choices = [
  { id: "wip", name: "WIP" },
  { id: "expense", name: "Expense" },
];

const useStyles = makeStyles(
  () => ({
    container: {
      display: "flex",
    },
    item: {
      padding: "5px",
      flex: 1,
    },
    description: {
      flex: 3,
    },
    invisible: {
      display: "none",
    },
    type: {
      minWidth: "initial!important",
    },
  }),
  {
    name: "LineItemIterator",
  }
);

const toString = (v) => `${v}`;

interface ILineItemIteratorProps {
  disabled?: boolean;
}

export const LineItemIterator: React.FC<ILineItemIteratorProps> = (props) => {
  const classes = useStyles(props);
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up("md"));
  return (
    <SimpleFormIterator
      {...props}
      classes={{ form: isDesktop && classes.container }}
      disableAdd={props.disabled}
      disableRemove={props.disabled}
    >
      <FormDataConsumer formClassName={classNames(classes.item)}>
        {({ formData, scopedFormData, getSource, ...rest }) => (
          <LineItemTemplateInput
            {...rest}
            {...props}
            templateId={scopedFormData?.templateId}
            source={getSource("templateId")}
            fullWidth={true}
          />
        )}
      </FormDataConsumer>
      <SelectInput
        {...props}
        formClassName={classNames(classes.item)}
        className={classes.type}
        source="entryType"
        choices={choices}
        label="Type"
        validate={[required()]}
        fullWidth={true}
      />
      <FormDataConsumer
        formClassName={classNames(classes.item, classes.description)}
      >
        {({ formData, scopedFormData, getSource, ...rest }) => (
          <LineItemDescriptionInput
            {...rest}
            {...props}
            templateId={scopedFormData?.templateId}
            source={getSource("description")}
            fullWidth={true}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer formClassName={classNames(classes.invisible)}>
        {({ formData, scopedFormData, getSource, ...rest }) => (
          <LineItemCategoryInput
            {...rest}
            {...props}
            templateId={scopedFormData?.templateId}
            source={getSource("category")}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer formClassName={classNames(classes.invisible)}>
        {({ formData, scopedFormData, getSource, ...rest }) => (
          <LineItemCodeInput
            {...rest}
            {...props}
            templateId={scopedFormData?.templateId}
            source={getSource("code")}
          />
        )}
      </FormDataConsumer>
      <NumberInput
        {...props}
        source="amount"
        label="Amount"
        formClassName={classes.item}
        validate={[required(), number()]}
        fullWidth={true}
        format={parseFloat}
        parse={toString}
      />
    </SimpleFormIterator>
  );
};
