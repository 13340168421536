import { Dialog, IDialogProps } from "@blueprintjs/core";
import * as React from "react";
import { Constants } from "../../constants";
import { ClientUserCreate } from "../ClientUser/ClientUserCreate";

interface IClientUserCreateDialogProps extends IDialogProps {
  clientGroupId: string;
  handleSuccess: () => void;
}

export const ClientUserCreateDialog: React.FC<IClientUserCreateDialogProps> = (
  props
) => {
  const { clientGroupId, handleSuccess } = props;

  return (
    <div className="save-dialog-wrapper">
      <Dialog {...props} icon="person" title="Create Client User">
        <ClientUserCreate
          resource={Constants.CLIENT_USER_RESOURCE}
          basePath={Constants.CLIENT_USER_PATH}
          clientGroupId={clientGroupId}
          handleSuccess={handleSuccess}
        />
      </Dialog>
    </div>
  );
};
