import { Button, makeStyles } from "@material-ui/core";
import ImageEye from "@material-ui/icons/RemoveRedEye";
import { useExpanded } from "ra-core";
import React from "react";
import { IFormGroupResponse } from "../../model";

interface IFormGroupResponseExpandButtonProps {
  record?: IFormGroupResponse;
  resource?: string;
  scrollItoView: () => void;
}

const useStyle = makeStyles({
  button: {
    color: "#3f51b5",
  },
});

export const FormGroupResponseExpandButton: React.FC<IFormGroupResponseExpandButtonProps> = ({
  resource,
  record,
  scrollItoView,
}) => {
  const [expanded, toggleExpanded] = useExpanded(resource, record?.id);
  const handleOnclick = () => {
    toggleExpanded();
    scrollItoView();
  };
  const classes = useStyle({});
  return (
    <Button
      size="small"
      className={classes.button}
      onClick={handleOnclick}
      startIcon={<ImageEye />}
    >
      Detail
    </Button>
  );
};
