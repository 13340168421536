import has from "lodash/has";
import { ReduxState } from "ra-core";
import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import {
  AuthClientUserComponent,
  ClientAppForm,
  ClientAppForms,
  ClientPortalPage,
  ClientUserLoginPage,
  LoginLinkGenerator,
} from "../components";
import { Constants } from "../constants";

export const ClientAppRouter = () => {
  const isRegistered = useSelector(({ admin: { resources } }: ReduxState) =>
    Constants.CLIENT_RESOURCES.reduce(
      (result, resource) => result && has(resources, resource),
      true
    )
  );

  if (!isRegistered) {
    return null;
  }

  return (
    <Switch>
      <Route
        path={"/client_app/login"}
        component={ClientUserLoginPage}
        exact={true}
      />
      <Route
        path={"/client_app/login_generator"}
        component={LoginLinkGenerator}
        exact={true}
      />
      <AuthClientUserComponent
        component={ClientAppForms}
        path={Constants.CLIENT_APP_FORM_PATH}
        exact={true}
      />
      <AuthClientUserComponent
        component={ClientAppForm}
        path={`${Constants.CLIENT_APP_FORM_PATH}/:id`}
        exact={true}
      />
      <AuthClientUserComponent
        component={ClientPortalPage}
        path={`${Constants.CLIENT_APP_PATH}/:id?`}
      />
    </Switch>
  );
};
