import { Icon } from "@blueprintjs/core";
import { Drawer, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import _ from "lodash";
import { useGetList } from "ra-core";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { Constants } from "../../../constants";
import { getClientUser } from "../../../core";
import { IClientCompany } from "../../../model";
import { ClientInvoiceList, ClientInvoiceShow } from "../ClientInvoice";
import { ClientPortalDataSafe } from "./ClientPortalDataSafe";
import { ClientPortalIndex } from "./ClientPortalIndex";
import { ClientPortalManage } from "./ClientPortalManage";
import { ClientPortalNavbar } from "./ClientPortalNavbar";
import { ClientPortalSidebar } from "./ClientPortalSidebar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: 240,
      flexShrink: 0,
    },
    drawerPaper: {
      width: 240,
    },
    content: {
      maxWidth: "95%",
      flexGrow: 1,
      padding: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up("md")]: {
        paddingLeft: 240,
      },
    },
  })
);

export const ClientPortalPage: React.FC<RouteComponentProps<{
  id?: string;
  url?: string;
}>> = (props) => {
  const id = props.match.params.id;
  const [action] = window.location.href.split("/").slice(-1);
  const user = getClientUser();
  const [company, setCompany] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const classes = useStyles({});

  const { data: companyEntries } = useGetList(
    Constants.CLIENT_COMPANY_RESOURCE,
    { page: 1, perPage: 30 },
    { field: "", order: "" },
    {}
  );

  useEffect(() => {
    if (companyEntries && id) {
      setCompany(companyEntries[id]);
    }
  });

  const transformedData = (): IClientCompany[] => {
    if (companyEntries) {
      return _.values(companyEntries) as IClientCompany[];
    }
    return [];
  };

  const handleDrawerOpen = (open: boolean) => {
    setIsDrawerOpen(open);
  };

  const renderClientPortalIndex = () => {
    return (
      <ClientPortalIndex
        companies={transformedData()}
        selectedCompany={company}
      />
    );
  };

  return (
    <>
      <ClientPortalNavbar
        user={user}
        drawerOpen={isDrawerOpen}
        handleDrawerOpen={handleDrawerOpen}
      />
      <Grid
        container={true}
        xs={10}
        className={classNames("client-portal-container", classes.content, {
          [classes.contentShift]: isDrawerOpen,
        })}
      >
        <Grid item={true} xs={12} className="client-portal-header">
          <Grid item={true} xs={12} className="client-portal-title">
            <h3 className="client-portal-title-content">
              <Icon
                className="client-portal-company-title-icon"
                icon="office"
                iconSize={30}
              />
              {company && company.name}
            </h3>
          </Grid>
        </Grid>
        <Grid item={true} xs={12} className="client-portal-main">
          <Switch>
            <Route
              exact={true}
              path={`${Constants.CLIENT_APP_PATH}/index`}
              component={renderClientPortalIndex}
            />
            <Route
              exact={true}
              path={`${Constants.CLIENT_APP_PATH}/:id/manage`}
              component={ClientPortalManage}
            />
            <Route
              exact={true}
              path={`${Constants.CLIENT_APP_PATH}/:id/data_safe`}
              component={ClientPortalDataSafe}
            />
            <Route
              exact={true}
              path={`${Constants.CLIENT_APP_PATH}/:client_company_id/invoices`}
              render={(p) => (
                <ClientInvoiceList
                  resource={Constants.INVOICE_RESOURCE}
                  {...p}
                  hasCreate={false}
                  hasEdit={false}
                  hasShow={true}
                  hasList={true}
                  basePath={`${Constants.CLIENT_APP_PATH}/invoices`}
                />
              )}
            />
            <Route
              exact={true}
              path={`${Constants.CLIENT_APP_PATH}/:client_company_id/invoices/:id/show`}
              render={(p) => (
                <ClientInvoiceShow
                  {...p}
                  resource={Constants.INVOICE_RESOURCE}
                  id={p.match.params.id}
                  basePath={`${Constants.CLIENT_APP_PATH}/invoices/show`}
                />
              )}
            />
            <Redirect
              from={Constants.CLIENT_APP_PATH}
              to={`${Constants.CLIENT_APP_PATH}/index`}
            />
          </Switch>
        </Grid>
      </Grid>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={isDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <ClientPortalSidebar
          user={user}
          companies={transformedData()}
          selectedCompany={company}
          handleDrawerOpen={handleDrawerOpen}
          action={action}
        />
      </Drawer>
    </>
  );
};
