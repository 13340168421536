import { Constants } from "@app/constants";
import { Dialog, DialogContent } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useDataProvider } from "ra-core";
import React, { useEffect, useState } from "react";
import { history } from "../../store";
import { CheckoutForm, PaymentSuccess } from "./SubscriptionCheckoutForm";

let stripePublishableKey = "";

if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
  stripePublishableKey = process.env.STRIPE_PRODUCTION_PUBLISHABLE_KEY;
} else {
  stripePublishableKey = process.env.STRIPE_TEST_PUBLISHABLE_KEY;
}

const stripePromise = loadStripe(stripePublishableKey);

export interface ISubscriptionDetails {
  monthlyPaymentAmount: string;
  tenantNumUsers: string;
}

export interface ISubscriptionCheckoutDialogProps {
  subscriptionDetails: ISubscriptionDetails;
  enableEscapeKeyDown: () => void;
  enableBackdropClick: () => void;
  renderPaymentSuccess: () => void;
}

export const SubscriptionCheckoutDialog: React.FC<any> = () => {
  const [subscriptionDetails, setSubscriptionDetails] = useState<
    ISubscriptionDetails
  >({ monthlyPaymentAmount: "", tenantNumUsers: "" });
  const [open, setOpen] = useState(true);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [disableEscapeKeyDown, setDisableEscapeKeyDown] = useState(true);
  const [disableBackdropClick, setDisableBackdropClick] = useState(true);
  const dataProvider = useDataProvider();

  const handleOnExited = () => {
    history.push(`${Constants.Logout}`);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const enableEscapeKeyDown = () => {
    setDisableEscapeKeyDown(false);
  };

  const enableBackdropClick = () => {
    setDisableBackdropClick(false);
  };

  const renderPaymentSuccess = () => {
    setPaymentSuccess(true);
  };

  useEffect(() => {
    (async () => {
      const response = await dataProvider.getTenantSubscriptionDetails();
      const subscriptionDetails = response.data;
      setSubscriptionDetails(subscriptionDetails);
    })();
  }, []);

  const props = {
    subscriptionDetails,
    enableEscapeKeyDown,
    enableBackdropClick,
    renderPaymentSuccess,
  };

  return (
    <Dialog
      disableEscapeKeyDown={disableEscapeKeyDown}
      disableBackdropClick={disableBackdropClick}
      onClose={handleCloseModal}
      fullWidth={true}
      aria-labelledby="simple-dialog-title"
      open={open}
      onExited={handleOnExited}
    >
      <DialogContent>
        <Elements stripe={stripePromise}>
          {paymentSuccess ? (
            <PaymentSuccess {...props} />
          ) : (
            <CheckoutForm {...props} />
          )}
        </Elements>
      </DialogContent>
    </Dialog>
  );
};
