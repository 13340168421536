import { Icon } from "@blueprintjs/core";
import React from "react";
import { connect } from "react-redux";
import { Api } from "../actions";
import { ISubtask, ISubtaskType } from "../model";
import { Dispatch } from "../types";
import { CurrentUserSubtaskTypeSelect } from "./select";

interface ISubtaskCreatorOwnProps {
  newSubtaskOrder: number;
  taskId: string;
}

interface ISubtaskCreatorDispatchProps {
  onCreateSubtask: (subtaskType: Partial<ISubtaskType>) => Promise<any>;
}

interface ISubtaskCreatorProps
  extends ISubtaskCreatorOwnProps,
    ISubtaskCreatorDispatchProps {}

export class SubtaskCreatorImpl extends React.Component<ISubtaskCreatorProps> {
  public render = () => {
    return (
      <div className="subtask-creator">
        <Icon icon="edit" />
        <CurrentUserSubtaskTypeSelect
          creatable={true}
          suggest={true}
          defaultButtonLabel="Add subtask..."
          onCreate={this.handleCreate}
          onSelect={this.handleSelect}
        />
      </div>
    );
  };

  private handleCreate = (description: string): Promise<ISubtask> => {
    const subtaskType: Partial<ISubtaskType> = {
      description,
    };
    return this.props.onCreateSubtask(subtaskType);
  };

  private handleSelect = (subtaskType: ISubtaskType) => {
    return this.props.onCreateSubtask(subtaskType);
  };
}

function mapDispatchToProps(
  dispatch: Dispatch,
  ownProps: ISubtaskCreatorOwnProps
) {
  const { newSubtaskOrder, taskId } = ownProps;

  return {
    onCreateSubtask: (subtaskType: Partial<ISubtaskType>) => {
      const subtask: Partial<ISubtask> = {
        taskId,
        typeId: subtaskType.id,
        order: newSubtaskOrder,
      };

      let promise;

      if (subtaskType.id == null) {
        promise = dispatch(Api.SubtaskType.create(subtaskType)).then(
          (createdSubtaskType) => {
            subtask.typeId = createdSubtaskType.id;
            return dispatch(Api.Subtask.create(subtask));
          }
        );
      } else {
        promise = dispatch(Api.Subtask.create(subtask));
      }

      return promise.then(() => {
        return dispatch(Api.Task.get(taskId));
      });
    },
  };
}

export const SubtaskCreator = connect(
  null,
  mapDispatchToProps
)(SubtaskCreatorImpl);
