import { CRUD_UPDATE } from "ra-core";
import { eventChannel } from "redux-saga";
import { call, put, take, takeEvery } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { subscribeToInvoice, updateInvoice } from "../actions";
import { Constants } from "../constants";
import { IInvoice } from "../model";
import { getCable } from "./actionCable";

const createInvoiceChannel = (invoiceId: string) =>
  eventChannel<ActionType<typeof updateInvoice>>((emit) => {
    const chan = getCable().subscriptions.create(
      {
        channel: "InvoiceChannel",
        id: invoiceId,
      },
      {
        received: (invoice: IInvoice) => {
          emit(updateInvoice(invoice));
        },
      }
    );

    return chan.unsubscribe;
  });

export function* subscribeToInvoiceChannel(
  action: ActionType<typeof subscribeToInvoice>
) {
  const previousInvoice = action.payload;
  const chan: ReturnType<typeof createInvoiceChannel> = yield call(
    createInvoiceChannel,
    previousInvoice.id
  );

  while (true) {
    try {
      const {
        payload: newInvoice,
      }: ActionType<typeof updateInvoice> = yield take(chan);
      const requestPayload = {
        id: previousInvoice.id,
        data: newInvoice,
        previousData: previousInvoice,
      };
      yield put({
        type: `${CRUD_UPDATE}_LOADING`,
        payload: requestPayload,
        meta: {
          resource: Constants.INVOICE_RESOURCE,
        },
      });

      yield put({
        type: `${CRUD_UPDATE}_SUCCESS`,
        payload: {
          data: newInvoice,
        },
        requestPayload: {
          id: previousInvoice.id,
          data: newInvoice,
          previousData: previousInvoice,
        },
        meta: {
          fetchResponse: "UPDATE",
          fetchStatus: "RA/FETCH_END",
          resource: Constants.INVOICE_RESOURCE,
          refresh: true,
        },
      });
    } catch (error) {
      console.error("invoice channel error", error);
      chan.close();
    }
  }
}

export function* invoiceSaga() {
  yield takeEvery(getType(subscribeToInvoice), subscribeToInvoiceChannel);
}
