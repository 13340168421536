import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
} from "@blueprintjs/core";
import {
  IModelFormControllerComponentProps,
  ModelFormErrors,
} from "@chiubaka/core";
import * as React from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { connect } from "react-redux";
import { Api } from "../../actions";
import { withModelFormController } from "../../core";
import { IEtsState, IEtsUser } from "../../model";
import { canCreateComments } from "../../selectors";

interface ISettingsFormStateProps {
  enableCommentCreation: boolean;
}

interface ISettingsFormOwnProps {
  afterSubmit: (settings: IEtsUser) => void;
}

interface ISettingsFormProps
  extends ISettingsFormStateProps,
    ISettingsFormOwnProps,
    IModelFormControllerComponentProps<IEtsUser> {}

interface ISettingsFormState {
  errors: ModelFormErrors<IEtsUser>;
  showErrors: boolean;
  saveDisabled: boolean;
}

class SettingsFormImpl extends React.Component<
  ISettingsFormProps,
  ISettingsFormState
> {
  constructor(props: ISettingsFormProps) {
    super(props);

    this.state = {
      errors: this.validate(props.model),
      showErrors: false,
      saveDisabled: false,
    };

    this.handleContentChange = this.handleContentChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(props: ISettingsFormProps) {
    const errors = this.validate(props.model);

    const { showErrors } = this.state;
    if (showErrors) {
      this.setState({
        ...this.state,
        errors,
        saveDisabled: Object.keys(errors).length !== 0,
      });
    }
  }

  public render(): JSX.Element {
    const { enableCommentCreation } = this.props;
    const { errors, showErrors } = this.state;

    const {
      firstName,
      lastName,
      phoneNumber,
      phoneNumberVerified,
    } = this.props.model;

    return (
      <div>
        <div>
          <FormGroup label="First name">
            <InputGroup value={firstName} disabled={true} />
          </FormGroup>
        </div>
        <div>
          <FormGroup label="Last name">
            <InputGroup value={lastName} disabled={true} />
          </FormGroup>
        </div>
        <div>
          <FormGroup
            label={`Phone number (${
              phoneNumberVerified ? "Verified" : "Unverified"
            })`}
            helperText={this.showError(errors.content)}
            intent={this.showErrorIntent(errors.content)}
          >
            <PhoneInput
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={this.handleContentChange}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              intent={Intent.PRIMARY}
              text="Save"
              onClick={this.onSubmit}
              disabled={this.state.saveDisabled || !enableCommentCreation}
            />
          </div>
        </div>
      </div>
    );
  }

  private handleContentChange(phone) {
    // const update = {taskId: this.props.taskId, content};
    this.props.onModelDetailsUpdate({
      id: this.props.model.id,
      phoneNumber: phone,
    });
    this.validate(this.props.model);
  }

  private async onSubmit() {
    const errors = this.validate(this.props.model);
    if (Object.keys(errors).length > 0) {
      this.setState({
        ...this.state,
        errors,
        saveDisabled: true,
        showErrors: true,
      });
      return;
    }

    this.setState({ ...this.state, saveDisabled: true });
    await this.props.onSubmit().then(
      (submittedUser) => {
        this.props.afterSubmit(submittedUser);
        return this.setState({
          ...this.state,
          errors: {},
          showErrors: false,
          saveDisabled: false,
        });
      },
      () => {
        this.setState({ ...this.state, saveDisabled: false });
      }
    );
  }

  private validate(user: Partial<IEtsUser>) {
    const errors: ModelFormErrors<IEtsUser> = {};

    if (user.phoneNumber && !isValidPhoneNumber(user.phoneNumber)) {
      errors.content = "Phone number is invalid";
    }

    return errors;
  }

  private showError(error: string) {
    if (this.state.showErrors && error) {
      return error;
    }

    return null;
  }

  private showErrorIntent(error: string) {
    if (this.state.showErrors && error) {
      return Intent.DANGER;
    }

    return null;
  }
}

function mapStateToProps(state: IEtsState) {
  return {
    enableCommentCreation: canCreateComments(state),
  };
}

export const SettingsForm = withModelFormController<
  ISettingsFormProps,
  IEtsUser,
  ISettingsFormOwnProps
>(connect(mapStateToProps)(SettingsFormImpl), Api.User);
