import { push } from "connected-react-router";
import { useDataProvider, useRefresh } from "ra-core";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { INotification } from "../../model";

export const useNotificationOnClick = () => {
  const dispatch = useDispatch();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  return useCallback(
    (notification: INotification) => {
      const act = async () => {
        await dataProvider.openNotification({ id: notification.id });
        refresh();
      };
      dispatch(push(notification.parameters.notifiablePath));
      act();
    },
    [dataProvider]
  );
};
