import { Button, Popover, Position } from "@blueprintjs/core";
import { useGetMatching } from "ra-core";
import React from "react";
import { Link } from "react-router-dom";
import { ErrorBoundary } from "../../components";
import { Constants } from "../../constants";
import { INotification, NotificationMode } from "../../model";
import { Notification } from "../Notification/Notification";
import { isNotificationValid } from "../Notification/NotificationRender";
import { useNotificationOnClick } from "../Notification/OpenNotification";

export const NotificationButton: React.FC = () => {
  const { data, total: badgeCount, loaded } = useGetMatching(
    Constants.NOTIFICATIONS_RESOURCE,
    {
      page: 1,
      perPage: 5,
    },
    {
      field: "",
      order: "",
    },
    {
      filter: "unopened",
    },
    "notificationIds",
    "notifications"
  );
  const notifications = (data as INotification[]) ?? [];
  const handleClick = useNotificationOnClick();

  if (!loaded) {
    return null;
  }

  const notificationRows = notifications
    .filter(isNotificationValid)
    .map((n) => (
      <Notification
        key={n.id}
        notification={n}
        mode={NotificationMode.Short}
        notificationOnClick={handleClick}
      />
    ));

  return (
    <ErrorBoundary>
      <Popover position={Position.BOTTOM_RIGHT} boundary="viewport">
        <Button className="notifications" icon="notifications">
          <div className="badge">{badgeCount > 0 ? badgeCount : ""}</div>
        </Button>
        <div className="box">
          <div className="menu-bar">
            <p className="menu-notify">
              <b>Notifications</b>
            </p>
          </div>
          {notificationRows}
          <div className="bottom-bar">
            <small>
              <Link
                to={Constants.NOTIFICATIONS_PATH}
                className="see-all-button"
              >
                See All
              </Link>
            </small>
          </div>
        </div>
      </Popover>
    </ErrorBoundary>
  );
};
