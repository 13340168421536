import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
}));

export const Pagination: React.FC<any> = ({
  page,
  perPage,
  total,
  setPage,
  ids,
}) => {
  const classes = useStyles({});
  const hasNext = ids.length === perPage;
  const setPrev = () => setPage(page - 1);
  const setNext = () => setPage(page + 1);
  return (
    <Toolbar>
      <div className={classes.grow} />
      {page !== 1 && (
        <Button
          color="primary"
          key="prev"
          startIcon={<ChevronLeft />}
          onClick={setPrev}
        >
          Prev
        </Button>
      )}
      {hasNext && (
        <Button
          color="primary"
          key="next"
          endIcon={<ChevronRight />}
          onClick={setNext}
        >
          Next
        </Button>
      )}
    </Toolbar>
  );
};
