import { Button, Grid } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import { cond, eq, flow } from "lodash/fp";
import { FormDataConsumer } from "ra-core";
import React from "react";
import { SaveButton, Toolbar } from "react-admin";
import { useSelector } from "react-redux";
import { ApprovalAction } from "../../hooks";
import { IEtsState, IInvoice } from "../../model";
import { WorkFlowButton } from "../EditToolbar";
import { useUpdateInvoice } from "./hooks";

const InvoiceSaveButton: React.FC<any> = ({ handleSubmit, ...props }) => {
  const handleClick = useUpdateInvoice(props);

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  wrapper: {
    margin: 16,
    position: "relative",
  },
  buttonSuccess: {
    "backgroundColor": red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  invoiceDownload: {
    marginLeft: "10px",
  },
  buttonProgress: {
    color: red[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ApprovalButtonGroup: React.FC<any> = (props) => {
  const { type, onClick } = props;
  return (
    <Grid container={true} spacing={3} justify="flex-end">
      <Grid item={true}>
        <WorkFlowButton
          {...props}
          workFlow={
            type === "eic" ? ApprovalAction.EicAccept : ApprovalAction.PicAccept
          }
          onClick={onClick}
        />
      </Grid>
      <Grid item={true}>
        <WorkFlowButton
          {...props}
          workFlow={
            type === "eic" ? ApprovalAction.EicReject : ApprovalAction.PicReject
          }
          onClick={onClick}
        />
      </Grid>
    </Grid>
  );
};

const InvoiceDownloadButton: React.FC<any> = ({ record, saving }) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      href={record.pdfUrl}
      target="_blank"
      disabled={!record.pdfUrl}
    >
      {!!record.pdfUrl ? "Download" : "Generating PDF"}
    </Button>
  );
};

export const InvoiceEditToolbar = (props) => {
  const { record, basePath } = props;
  const userId = useSelector<IEtsState, string>((state) => state.auth.user.id);
  const approvalType = flow(
    cond([
      [eq("need_eic_approval"), () => "eic"],
      [eq("need_pic_approval"), () => "pic"],
      [() => true, () => null],
    ])
  )(props.record.approvalStatus);

  const canApprove =
    props.record.approvalStatus !== "approved" &&
    flow(
      cond([
        [
          (id) => approvalType === "eic" && eq(props.record.eicId)(id),
          () => true,
        ],
        [
          (id) => approvalType === "pic" && eq(props.record.picId)(id),
          () => true,
        ],
        [() => true, () => false],
      ])
    )(userId);

  const handleSubmit = (formData) =>
    useUpdateInvoice({ basePath, record: record as IInvoice, formData });

  const canSubmit =
    props.record.approvalStatus === "draft" &&
    userId === props.record.createdById;
  return (
    <Toolbar {...props} classes={useStyles(props)}>
      <InvoiceSaveButton label="Save" />
      <InvoiceDownloadButton />
      <FormDataConsumer>
        {({ formData, getSource, ...rest }) => (
          <>
            {canSubmit && (
              <WorkFlowButton
                {...props}
                workFlow={ApprovalAction.Submit}
                onClick={handleSubmit(formData)}
              />
            )}
            {canApprove && (
              <ApprovalButtonGroup
                {...props}
                type={approvalType}
                onClick={handleSubmit(formData)}
              />
            )}
          </>
        )}
      </FormDataConsumer>
    </Toolbar>
  );
};
