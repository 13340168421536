import { useDataProvider, useNotify, useRefresh } from "ra-core";
import React, { useCallback } from "react";
import { ListBase, Pagination, useListContext } from "react-admin";
import { ErrorBoundary } from "../../components";
import { Notification } from "../../components/Notification/Notification";
import { isNotificationValid } from "../../components/Notification/NotificationRender";
import { useNotificationOnClick } from "../../components/Notification/OpenNotification";
import { Constants } from "../../constants";

const useOpenAll = () => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();

  return useCallback(() => {
    const act = async () => {
      await dataProvider.openAllNotifications(
        Constants.NOTIFICATIONS_RESOURCE,
        {},
        {
          action: "openAllNotifications",
        }
      );
      refresh();
      notify("Marked all notifications as read", "info");
    };
    act();
  }, [dataProvider]);
};

const NotificationsToDisplay: React.FC = (props) => {
  const { data, ids } = useListContext(props);
  const validNotifications = ids
    .map((id) => data[id])
    .filter(isNotificationValid);
  const handleClick = useNotificationOnClick();
  return validNotifications.map((n) => (
    <Notification
      key={n.id}
      notification={n}
      notificationOnClick={handleClick}
    />
  ));
};

const NotificationsList: React.FC<any> = (props) => {
  return (
    <ListBase {...props}>
      <NotificationsToDisplay />
      <Pagination />
    </ListBase>
  );
};

export const Notifications: React.FC = () => {
  const handleReadAllOnClick = useOpenAll();

  return (
    <ErrorBoundary>
      <div className="notify-container">
        <div className="header">
          <div className="title">Your Notifications</div>
          <small className="all-read">
            <button className="all-read-button" onClick={handleReadAllOnClick}>
              Mark All as Read
            </button>
          </small>
        </div>
        <hr />
        <NotificationsList
          resource={Constants.NOTIFICATIONS_RESOURCE}
          basePath={Constants.NOTIFICATIONS_PATH}
        />
      </div>
    </ErrorBoundary>
  );
};
