import { makeStyles } from "@material-ui/core";
import React from "react";
import { ISubtask, ITask } from "../../../../model";
import { TimesheetCreateSelectInput } from "./TimesheetCreateSelect";

interface ITaskOrSubtaskSelect {
  options: ITask[] | ISubtask[];
  selectedItem: ITask | ISubtask;
  handleSelect: (task: ITask | ISubtask) => void;
  entryType: "Job" | "Subtask";
}

const useStyle = makeStyles({
  leftTitle: {
    width: "110px",
  },
});

export const TasksOrSubtaskSelect: React.FC<ITaskOrSubtaskSelect> = ({
  options,
  selectedItem,
  handleSelect,
  entryType,
}) => {
  const classes = useStyle({});

  return (
    <>
      <span className={classes.leftTitle}>{entryType}:</span>
      {options && (
        <TimesheetCreateSelectInput
          options={options}
          handleSelectOption={handleSelect}
          filter={false}
          text={
            selectedItem ? `${selectedItem.description}` : `Select ${entryType}`
          }
        />
      )}
    </>
  );
};
