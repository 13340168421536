import * as qs from "qs";
import {
  Create,
  DateInput,
  NumberInput,
  SimpleForm,
  TextInput,
} from "ra-ui-materialui";
import React from "react";
import { PostCreateToolbar } from "../CreateToolBar";
import { ExpenseCreateByTask } from "./CustomField/ExpenseCreateByTask";
import { ExpenseCreateSelect } from "./CustomField/ExpenseCreateSelect";

const initialValues = { date: new Date() };

export const ExpenseCreate: React.FC<any> = (props) => {
  const { source } = qs.parse(props.location.search.slice(1));
  const taskId = source && (source as qs.ParsedQs).taskId;

  return (
    <Create {...props} export={false}>
      <SimpleForm initialValues={initialValues} toolbar={<PostCreateToolbar />}>
        {!!taskId && <ExpenseCreateByTask />}
        {!taskId && <ExpenseCreateSelect />}
        <DateInput source="date" />
        <TextInput multiline={true} source="description" rows="4" />
        <NumberInput source="amount" />
      </SimpleForm>
    </Create>
  );
};
