import { Box, Divider, Grid, Hidden } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { IClientCompany } from "../../../model";

interface ICompanyDatesFieldsProps {
  company: IClientCompany;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    field: {
      [theme.breakpoints.up("md")]: {
        textAlign: "right",
      },
    },
  })
);

export const CompanyDatesFields: React.FC<ICompanyDatesFieldsProps> = ({
  company,
}) => {
  const {
    nextAnnualGeneralMeetingDate,
    nextAnnualReturnDate,
    nextBusinessRegistrationRenewalDate,
    nextFinancialYearEnd,
    nextTaxFilingDate,
  } = company;

  const classes = useStyles({});

  return (
    <div className="client-portal-company-fields">
      <Hidden smDown={true}>
        <Box
          className="client-portal-date-field"
          display="flex"
          justifyContent="flex-end"
        >
          <b>
            <FormattedMessage
              defaultMessage="Dates"
              description="client portal page importantDates.dates"
            />
          </b>
        </Box>
        <Divider />
      </Hidden>
      <Box
        className="client-portal-date-field"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item={true} xs={12} md={6}>
          <FormattedMessage
            defaultMessage="Annual General Meeting"
            description="client portal page important.Dates.annualGeneralMeeting"
          />{" "}
          :
        </Grid>
        <Grid item={true} xs={12} md={6} className={classes.field}>
          {nextAnnualGeneralMeetingDate ? (
            <FormattedDate
              value={nextAnnualGeneralMeetingDate}
              year="numeric"
              month="long"
              day="2-digit"
            />
          ) : (
            "-"
          )}
        </Grid>
      </Box>
      <Divider />
      <Box
        className="client-portal-date-field"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item={true} xs={12} md={6}>
          <FormattedMessage
            defaultMessage="Annual Return (Filing Deadline)"
            description="client portal page important.annualReturn"
          />{" "}
          :
        </Grid>
        <Grid item={true} xs={12} md={6} className={classes.field}>
          {nextAnnualReturnDate ? (
            <FormattedDate
              value={nextAnnualReturnDate}
              year="numeric"
              month="long"
              day="2-digit"
            />
          ) : (
            "-"
          )}
        </Grid>
      </Box>
      <Divider />
      <Box
        className="client-portal-date-field"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item={true} xs={12} md={6}>
          <FormattedMessage
            defaultMessage="Business Registration Renewal Date"
            description="client portal page important.businessRegistrationRenewalDate"
          />{" "}
          :
        </Grid>
        <Grid item={true} xs={12} md={6} className={classes.field}>
          {nextBusinessRegistrationRenewalDate ? (
            <FormattedDate
              value={nextBusinessRegistrationRenewalDate}
              year="numeric"
              month="long"
              day="2-digit"
            />
          ) : (
            "-"
          )}
        </Grid>
      </Box>
      <Divider />
      <Box
        className="client-portal-date-field"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item={true} xs={12} md={6}>
          <FormattedMessage
            defaultMessage="Financial Year-End"
            description="client portal page important.financial Year-End"
          />{" "}
          :
        </Grid>
        <Grid item={true} xs={12} md={6} className={classes.field}>
          {nextFinancialYearEnd ? (
            <FormattedDate
              value={nextFinancialYearEnd}
              year="numeric"
              month="long"
              day="2-digit"
            />
          ) : (
            "-"
          )}
        </Grid>
      </Box>
      <Divider />
      <Box
        className="client-portal-date-field"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item={true} xs={12} md={6}>
          <FormattedMessage
            defaultMessage="Tax Filing Date"
            description="client portal page important.taxFilingDate"
          />{" "}
          :
        </Grid>
        <Grid item={true} xs={12} md={6} className={classes.field}>
          {nextTaxFilingDate ? (
            <FormattedDate
              value={nextTaxFilingDate}
              year="numeric"
              month="long"
              day="2-digit"
            />
          ) : (
            "-"
          )}
        </Grid>
      </Box>
    </div>
  );
};
