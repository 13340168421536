import * as React from "react";
import { CompanyTable } from "../components";

export class CompanyIndex extends React.Component {
  public render(): JSX.Element {
    return (
      <div>
        <CompanyTable />
      </div>
    );
  }
}
