import { Button, InputGroup, Intent } from "@blueprintjs/core";
import Fuse from "fuse.js";
import * as jsonexport from "jsonexport";
import { downloadCSV, Sort } from "ra-core";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { IModelById } from "../../core";
import { IEtsState, IEtsUser, ITimeEntry } from "../../model";
import { convertTimeEntries } from "../../utils/csv";
import { sortTimeEntries } from "../../utils/date";
import { SortableHeader, SortableHeaderItem } from "../SortableHeader";
import { TimeEntryRecord } from "./TimeEntryRecord";
import { TimeEntryTotal } from "./TimeEntryTotal";

interface ITimeEntryListProps {
  timeEntries: ITimeEntry[];
}

const filterSortEntries = (
  timeEntries: ITimeEntry[],
  users: IModelById<IEtsUser>,
  filter: string,
  sort: Sort
) => {
  const timeEntriesWithCreatedBy = timeEntries.map((entry) => ({
    ...entry,
    createdBy: users[entry.createdById],
  }));

  const filteredEntries =
    filter.length > 0
      ? new Fuse(timeEntriesWithCreatedBy, {
          keys: [
            "createdBy.firstName",
            "createdBy.lastName",
            "subtaskDescription",
            "description",
          ],
        }).search(filter)
      : timeEntriesWithCreatedBy;

  return filteredEntries.sort(sortTimeEntries(sort));
};

export const TimeEntryList: React.FC<ITimeEntryListProps> = ({
  timeEntries,
}) => {
  const [toggle, setToggle] = useState(false);
  const [sort, setSort] = useState<Sort>({ field: "date", order: "ASC" });
  const [filter, setFilter] = useState("");
  const users = useSelector((state: IEtsState) => state.usersById);
  const sortedTimeEntries = filterSortEntries(timeEntries, users, filter, sort);
  const isDaily = useSelector(
    (state: IEtsState) => state.departmentSetting.timebillDaily
  );

  const handleToggle = useCallback(() => {
    setToggle((prevToggle) => !prevToggle);
  }, []);

  const handleDownloadCsv = useCallback(() => {
    const headers = [
      "Date",
      "Staff",
      "Code",
      "Category",
      "Subtask Description",
      "Description",
      "Is Chargeable",
      "Duration",
      "Timesheet",
    ];
    const convertDate = convertTimeEntries(
      sortedTimeEntries,
      users,
      isDaily,
      headers
    );
    jsonexport(
      convertDate,
      {
        headers,
      },
      (err, csv) => {
        downloadCSV(csv, "time entries");
      }
    );
  }, [users, sortedTimeEntries]);

  const handleSort = useCallback((option: Sort) => {
    setSort(option);
  }, []);

  const handleFilter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFilter(event.target.value);
    },
    []
  );

  return (
    <div className="time-entries-list">
      <div className="time-entries-list-title">
        <Button
          text={toggle ? "Hide" : "Show"}
          intent={Intent.PRIMARY}
          onClick={handleToggle}
        />
        <div className="time-entries-search">
          <Button
            text="Download CSV"
            intent={Intent.PRIMARY}
            onClick={handleDownloadCsv}
          />
          <InputGroup
            className="search-input"
            leftIcon="search"
            placeholder="Search"
            onChange={handleFilter}
            value={filter}
          />
        </div>
      </div>
      <SortableHeader onClick={handleSort} sort={sort}>
        <SortableHeaderItem
          label="Date"
          field="date"
          className="time-entries-list-start-date-time"
        />
        <SortableHeaderItem
          label="Staff"
          field="createdBy"
          className="time-entries-list-created-by"
        />
        <SortableHeaderItem
          label="Subtask"
          field="subtask"
          className="time-entries-list-subtask-description"
        />
        <SortableHeaderItem
          label="Description"
          field="description"
          className="time-entries-list-description"
          sortable={false}
        />
        <SortableHeaderItem
          label="Chargeable"
          field="chargeable"
          className="time-entries-list-chargeable"
          sortable={false}
        />
        <SortableHeaderItem
          label="Duration"
          field="duration"
          className="time-entries-list-duration"
        />
        <SortableHeaderItem
          label="Timesheet"
          field="timesheet"
          className="time-entries-list-timesheet-status"
          sortable={false}
        />
      </SortableHeader>
      {toggle && (
        <>
          <div className="time-entries-list-content">
            {sortedTimeEntries.map((TE: ITimeEntry) => (
              <TimeEntryRecord timeEntry={TE} key={TE.id} users={users} />
            ))}
          </div>
          {<TimeEntryTotal timeEntries={sortedTimeEntries} />}
        </>
      )}
    </div>
  );
};
