import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { IAmountToBill, IUserTotalType } from "../../../model/IAmountToBill";
import { priceFormatter } from "../../../utils/money";

interface IUserTotal {
  amountToBill: IAmountToBill[];
  months: string[];
  type: IUserTotalType;
}

export const UserTotal: React.FC<IUserTotal> = ({
  amountToBill,
  months,
  type,
}) => {
  const result = {};
  amountToBill.map((single) => {
    months.map((month) => {
      if (result[month]) {
        result[month] += single[month] ? Number(single[month]) : 0;
      } else {
        result[month] = single[month] ? Number(single[month]) : 0;
      }
    });
  });

  const total = Object.keys(result).reduce((acc, key) => {
    acc += result[key];
    return acc;
  }, 0);
  return (
    <TableRow key={`${type}-total`}>
      <TableCell align="left" style={{ height: 50, border: "none" }}>
        {type} Total
      </TableCell>
      {months.map((key) => (
        <TableCell
          align="right"
          style={{ border: "none" }}
          key={`${type}-total-${key}`}
        >
          {priceFormatter.format(result[key])}
        </TableCell>
      ))}
      <TableCell align="right" style={{ height: 50, border: "none" }}>
        {priceFormatter.format(total)}
      </TableCell>
    </TableRow>
  );
};
