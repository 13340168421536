import React from "react";

interface IActionProps {
  browserProps: any;
  selectedItems: any[];
  isFolder: boolean;
  icons: any;
  nameFilter: string;
  canCreateFolder: boolean;
  onCreateFolder: () => void;
  canRenameFile: boolean;
  onRenameFile: () => void;
  canRenameFolder: boolean;
  onRenameFolder: () => void;
  canDeleteFile: boolean;
  onDeleteFile: () => void;
  canDeleteFolder: boolean;
  onDeleteFolder: () => void;
  canDownloadFile: boolean;
  onDownloadFile: (event) => void;
}

const Actions: React.FC<IActionProps> = ({
  browserProps,
  selectedItems,
  isFolder,
  icons,
  nameFilter,
  canCreateFolder,
  onCreateFolder,
  canRenameFile,
  onRenameFile,
  canRenameFolder,
  onRenameFolder,
  canDeleteFile,
  onDeleteFile,
  canDeleteFolder,
  onDeleteFolder,
  canDownloadFile,
  onDownloadFile,
}) => {
  let actions: any = [];

  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  const handleDownload = (event) => {
    event.preventDefault();
    selectedItems.forEach((item) => {
      // TODO: handle multi-download

      if (item.fileUrl) {
        window.open(item.fileUrl, "_self");
      }
    });
    onDownloadFile(event);
  };

  // TODO (MAYBE) : SAVE implementationf from Actions
  // const onAttachmentSave = browserProps.fileRendererProps.onAttachmentSave;
  // const handleSave = (event) => {
  //   if (selectedItems.length && selectedItems.length !== 0) {
  //     event.stopPropagation();
  //     onAttachmentSave(selectedItems);
  //   }
  // };

  if (selectedItems.length) {
    // Something is selected. Build custom actions depending on what it is.
    const selectedItemsAction = selectedItems.filter((item) => item.action);
    if (
      selectedItemsAction.length === selectedItems.length &&
      [...new Set(selectedItemsAction)].length === 1
    ) {
      // Selected item has an active action against it. Disable all other actions.
      let actionText;
      switch (selectedItemsAction[0].action) {
        case "delete":
          actionText = "Deleting ...";
          break;

        case "rename":
          actionText = "Renaming ...";
          break;

        default:
          actionText = "Moving ...";
          break;
      }

      actions = (
        // TODO: Enable plugging in custom spinner.
        <div className="item-actions">
          {icons.Loading} {actionText}
        </div>
      );
    } else {
      const itemsWithoutKeyDerived = selectedItems.find(
        (item) => !item.keyDerived
      );
      if (
        !itemsWithoutKeyDerived &&
        !isFolder &&
        canRenameFile &&
        selectedItems.length === 1
      ) {
        actions.push(
          <li key="action-rename">
            <a onClick={onRenameFile} href="#" role="button">
              {icons.Rename}
              &nbsp;Rename
            </a>
          </li>
        );
      } else if (!itemsWithoutKeyDerived && isFolder && canRenameFolder) {
        actions.push(
          <li key="action-rename">
            <a onClick={onRenameFolder} href="#" role="button">
              {icons.Rename}
              &nbsp;Rename
            </a>
          </li>
        );
      }

      if (!itemsWithoutKeyDerived && !isFolder && canDeleteFile) {
        actions.push(
          <li key="action-delete">
            <a onClick={onDeleteFile} href="#" role="button">
              {icons.Delete}
              &nbsp;Delete
            </a>
          </li>
        );
      } else if (!itemsWithoutKeyDerived && isFolder && canDeleteFolder) {
        actions.push(
          <li key="action-delete">
            <a onClick={onDeleteFolder} href="#" role="button">
              {icons.Delete}
              &nbsp;Delete
            </a>
          </li>
        );
      }

      if (!isFolder && canDownloadFile && selectedItems.length === 1) {
        const item = selectedItems[0];
        actions.push(
          <li key="action-download">
            <a href={item.url} role="button">
              {icons.Download}
              &nbsp;Download
            </a>
          </li>
        );
      }

      actions = actions.length ? (
        <ul className="item-actions" onClick={stopPropagation}>
          {actions}
        </ul>
      ) : (
        <div className="item-actions" onClick={stopPropagation}>
          &nbsp;
        </div>
      );
    }
  } else {
    // Nothing selected: We"re in the "root" folder. Only allowed action is adding a folder.
    if (!isFolder && canCreateFolder && !nameFilter) {
      actions.push(
        <li key="action-add-folder">
          <a onClick={onCreateFolder} href="#" role="button">
            {icons.Folder}
            &nbsp;Add Folder
          </a>
        </li>
      );
    }

    actions = actions.length ? (
      <ul className="item-actions" onClick={stopPropagation}>
        {actions}
      </ul>
    ) : (
      <div className="item-actions" onClick={stopPropagation}>
        &nbsp;
      </div>
    );
  }

  return actions;
};

export default Actions;
