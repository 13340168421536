import { Grid, makeStyles } from "@material-ui/core";
import React from "react";

export const departmentAccountBalanceStyle = makeStyles({
  title: {
    boxSizing: "border-box",
    borderBottom: "solid 1px",
    marginTop: 10,
    marginBottom: 10,
    textAlign: "end",
    fontSize: 20,
    paddingBottom: 10,
    height: 45,
  },
  total: {
    boxSizing: "border-box",
    borderBottom: "solid 1px",
    marginTop: 10,
    textAlign: "end",
    fontSize: 15,
    paddingBottom: 10,
  },
  normal: {
    marginTop: 10,
    marginBottom: 10,
    textAlign: "end",
    fontSize: 15,
  },
  wip: {
    paddingBottom: 10,
  },
  expenses: {
    boxSizing: "border-box",
    borderTop: "solid 1px",
    marginTop: 35,
    marginBottom: 10,
    textAlign: "end",
    fontSize: 15,
    paddingTop: 5,
  },
  minWidth: {
    minWidth: 200,
  },
});

export const ReportLayoutLeft: React.FC<any> = (props) => {
  const classes = departmentAccountBalanceStyle({});
  return (
    <Grid
      container={true}
      item={true}
      xs={2}
      direction="column"
      className={classes.minWidth}
    >
      <Grid container={true} direction="column" className={classes.wip}>
        <Grid item={true} className={classes.title}>
          <span>{props.headerName}</span>
        </Grid>
        <Grid item={true} className={classes.normal}>
          <span>WIP B/f</span>
        </Grid>
        <Grid item={true} className={classes.normal}>
          <span>WIP</span>
        </Grid>
        <Grid item={true} className={classes.normal}>
          <span>WIP Settled</span>
        </Grid>
        <Grid item={true} className={classes.normal}>
          <span>Credit Notes</span>
        </Grid>
        <Grid item={true} className={classes.normal}>
          <span>Write-Offs</span>
        </Grid>
        <Grid item={true} className={classes.normal}>
          <span>Transfers</span>
        </Grid>
        <Grid item={true} className={classes.total}>
          <span>Balance</span>
        </Grid>
        <Grid item={true} className={classes.normal}>
          <span>C/f</span>
        </Grid>
        <Grid item={true} className={classes.expenses}>
          <span>Expense B/f</span>
        </Grid>
        <Grid item={true} className={classes.normal}>
          <span>Expense</span>
        </Grid>
        <Grid item={true} className={classes.normal}>
          <span>Expense Settled</span>
        </Grid>
        <Grid item={true} className={classes.normal}>
          <span>Credit Notes</span>
        </Grid>
        <Grid item={true} className={classes.normal}>
          <span>Write-Offs</span>
        </Grid>
        <Grid item={true} className={classes.normal}>
          <span>Transfers</span>
        </Grid>
        <Grid item={true} className={classes.total}>
          <span>Balance</span>
        </Grid>
        <Grid item={true} className={classes.normal}>
          <span>C/f</span>
        </Grid>
      </Grid>
    </Grid>
  );
};
