import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { ITimeEntry } from "../../../model";
import { countRowTotalDuration, countTaskDuration } from "./countDuration";
import { TimesheetDurationBox } from "./TimesheetDurationBox";

interface ITimesheetTotalRowProps {
  timeEntries: ITimeEntry[];
}
export const TimesheetTotalRow: React.FC<ITimesheetTotalRowProps> = ({
  timeEntries,
}) => {
  const taskDuration = countTaskDuration(timeEntries);
  return (
    <TableRow className="timesheet-create-table-total-row">
      <TableCell>Total</TableCell>
      {Object.keys(taskDuration).map((index) => (
        <TimesheetDurationBox duration={taskDuration[index]} key={index} />
      ))}
      <TimesheetDurationBox duration={countRowTotalDuration(taskDuration)} />
    </TableRow>
  );
};
