import { Button, Popover, Position } from "@blueprintjs/core";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

interface ICopyEmailProps {
  emailAddress: string;
  type: "Job" | "Client Group" | "Company";
}

export const CopyEmail: React.FC<ICopyEmailProps> = ({
  emailAddress,
  type,
}) => {
  return (
    <Popover position={Position.BOTTOM}>
      <CopyToClipboard text={emailAddress}>
        <Button icon="clipboard" alignText="right">
          Copy {type} Email
        </Button>
      </CopyToClipboard>
      {<div className="tooltip-message">Email Address Copied</div>}
    </Popover>
  );
};
