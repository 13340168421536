import React, { useEffect, useState } from "react";

interface IReadMoreHtmlProps {
  body: string;
  identifier: string;
  heightThreshold: number;
  truncated: boolean;
  updateTruncated(newTruncatedState: boolean): any;
}

export const ReadMoreHtml = ({
  body,
  identifier,
  heightThreshold,
  truncated,
  updateTruncated,
}: IReadMoreHtmlProps) => {
  const [overflow, setOverflow] = useState(false);
  const toggleTruncated = (event) => {
    event.preventDefault();
    updateTruncated(!truncated);
  };
  const elementId = `truncate-${identifier}` as string;

  useEffect(() => {
    const height = document.getElementById(elementId).clientHeight;
    if (height > heightThreshold) {
      setOverflow(true);
    }
  }, [elementId, heightThreshold]);

  const displayToggleLabel = () => {
    const text = truncated ? (
      <a href="#" onClick={toggleTruncated}>
        Read more
      </a>
    ) : (
      <a href="#" onClick={toggleTruncated}>
        Read less
      </a>
    );
    return <span>{text}</span>;
  };

  return (
    <div>
      <div
        id={elementId}
        className={`html-truncate ${
          overflow && truncated ? "html-truncate-active" : ""
        }`}
        dangerouslySetInnerHTML={{
          __html: body as string,
        }}
      />
      {overflow && displayToggleLabel()}
    </div>
  );
};
