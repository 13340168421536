import { required, useGetOne } from "ra-core";
import {
  DateInput,
  Edit,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextInput,
} from "ra-ui-materialui";
import React from "react";
import { Constants } from "../../constants";
import { IInvoice, IPayment, ITask } from "../../model";
import { PostCreateToolbar } from "../CreateToolBar";
import { ClientCompanyField, FeeNoteField, TaskField } from "../fields";
import { PaymentBalance } from "./PaymentBalance";
import { paymentTypes } from "./paymentTypes";

export const PaymentEdit: React.FC<any> = (props) => {
  const paymentId = props.id;
  const { loaded, data } = useGetOne(
    Constants.PAYMENT_RESOURCE,
    paymentId && (paymentId as string)
  );
  const payment = data as IPayment;
  const { data: invoiceData } = useGetOne(
    Constants.INVOICE_RESOURCE,
    payment && (payment.invoiceId as string)
  );
  const invoice = invoiceData as IInvoice;
  const { data: taskData } = useGetOne(
    Constants.TASK_RESOURCE,
    payment && (payment.taskId as string)
  );
  const task = taskData as ITask;

  if (!payment || !task || !invoice) {
    return null;
  }

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<PostCreateToolbar />}>
        <ReferenceField
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          addLabel={false}
          link={false}
        >
          <TaskField />
        </ReferenceField>
        <ReferenceField
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          addLabel={false}
          link={false}
        >
          <ClientCompanyField />
        </ReferenceField>
        <ReferenceField
          source="invoiceId"
          reference={Constants.INVOICE_RESOURCE}
          addLabel={false}
          link={false}
        >
          <FeeNoteField />
        </ReferenceField>
        <DateInput source="date" />
        <PaymentBalance
          wipBalance={invoice.wipBalance}
          expenseBalance={invoice.expenseBalance}
          originalWip={invoice.amtToBill}
          originalExpense={invoice.expenseToBill}
          withAutofillOption={false}
        />
        <SelectInput
          source="paymentType"
          label="Payment type"
          choices={paymentTypes}
          validate={[required()]}
        />
        <TextInput
          label="Internal Remarks"
          source="internalRemarks"
          multiline={true}
          rows="4"
        />
      </SimpleForm>
    </Edit>
  );
};
