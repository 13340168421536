import { makeStyles } from "@material-ui/core";
import * as jsonExport from "jsonexport/dist";
import moment from "moment";
import React from "react";
import { Datagrid, DateField, downloadCSV, List, TextField } from "react-admin";
import { ReportRole } from "../../constants/reportRoles";
import { useReportParams } from "../../hooks/useReportParams";
import { shortFormDate } from "../../utils/date";
import { priceFormatter } from "../../utils/money";
import { DateFilter } from "../DepartmentAccountBalance/DateFilter";
import { PriceField, UserField } from "../fields";
import { ApprovalStatusSelectArrayInput } from "../inputs";

const filterDefaultValues = {
  startDate: moment().startOf("M").toDate(),
  endDate: moment().endOf("D").toDate(),
  approvalStatus: [
    "draft",
    "need_eic_approval",
    "need_pic_approval",
    "approved",
  ],
};

const useStyle = makeStyles({
  container: { marginTop: "30px" },
});

const exporter = (records) => {
  const data = records.map((record) => ({
    "Date": shortFormDate(record.date),
    "Fee Note": record.feeNoteNumber,
    "Department Name": record.name,
    "Client Company Name": record.clientCompanyName,
    "Pic_name": record.eic,
    "Eic_name": record.pic,
    "Total": priceFormatter.format(record.totalBill),
    "Fee Billed": priceFormatter.format(record.amtToBill),
    "Expense Billed": priceFormatter.format(record.expenseToBill),
    "Fee Credited": priceFormatter.format(record.wipToCredit),
    "Expense Credited": priceFormatter.format(record.expenseToCredit),
  }));
  jsonExport(data, (err, csv) => {
    downloadCSV(csv, "credit_notes_lists");
  });
};

export const CreditNotesReportList: React.FC<any> = (props) => {
  const filterParams = useReportParams(
    ReportRole.CREDIT_NOTES_LISTS_DEPARTMENT,
    filterDefaultValues
  );

  const classes = useStyle({});

  return (
    <List
      {...props}
      filters={
        <DateFilter
          reportType={ReportRole.CREDIT_NOTES_LISTS_TENANT}
          approvalStatusSelectArrayInput={
            <ApprovalStatusSelectArrayInput
              label="Approval statuses"
              source="approvalStatus"
            />
          }
        />
      }
      bulkActionButtons={false}
      exporter={exporter}
      filterDefaultValues={filterParams}
      className={classes.container}
    >
      <Datagrid>
        <DateField source="date" label="Date" sortable={false} />
        <TextField source="feeNoteNumber" label="Fee Note #" sortable={false} />
        <TextField source="name" label="Department" sortable={false} />
        <TextField source="clientCompanyName" />
        <UserField label="PIC" source="picId" />
        <UserField label="EIC" source="eicId" />
        <PriceField source="totalBill" label="Total" sortable={false} />
        <PriceField source="amtToBill" label="Fee Billed" sortable={false} />
        <PriceField
          source="expenseToBill"
          label="Expense Billed"
          sortable={false}
        />
        <PriceField
          source="wipToCredit"
          label="Fee Credited"
          sortable={false}
        />
        <PriceField
          source="expenseToCredit"
          label="Expense Credited"
          sortable={false}
        />
      </Datagrid>
    </List>
  );
};
