import { TimePicker } from "@blueprintjs/datetime";
import React, { useContext } from "react";
import {
  AddTimeEntryDispatchContext,
  AddTimeEntryStateContext,
} from "../context";

export const TimeEntryTimeRangeTab: React.FC = (props) => {
  const {
    state: { startTime, endTime },
  } = useContext(AddTimeEntryStateContext);
  const { dispatch } = useContext(AddTimeEntryDispatchContext);
  const handleChangeStartTime = (payload: Date) =>
    dispatch({ type: "SET_START_TIME", payload });
  const handleChangeEndTime = (payload: Date) =>
    dispatch({ type: "SET_END_TIME", payload });

  return (
    <div className="add-time-entry-input-time">
      <div>
        <TimePicker value={startTime} onChange={handleChangeStartTime} />
      </div>
      -
      <div>
        <TimePicker value={endTime} onChange={handleChangeEndTime} />
      </div>
    </div>
  );
};
