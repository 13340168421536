import { returnEmailEnvPrefix } from "./environment";

type IAliasType = "client-group-id" | "task-id" | "company-id" | "sub-id";

export const copyEmailAddress = (type: IAliasType, emailAlias) => {
  return (
    type +
    "-" +
    emailAlias +
    "@noreply." +
    returnEmailEnvPrefix() +
    "mail.easytrack.ai"
  );
};
