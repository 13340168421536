import { Button, Icon } from "@blueprintjs/core";
import moment from "moment";
import React from "react";
import { ITimesheet } from "../../../model";

interface ITimesheetDateFilterProps {
  onClickLeft: () => void;
  onClickRight: () => void;
  startDayOfWeek: Date;
  endDayOfWeek: Date;
  timesheet?: ITimesheet;
}

export const TimesheetDateFilter: React.FC<ITimesheetDateFilterProps> = ({
  onClickLeft,
  onClickRight,
  startDayOfWeek,
  endDayOfWeek,
  timesheet,
}) => {
  const renderLeftButton = () => (
    <Button
      className="bp3-minimal bp3-intent-primary bp3-small"
      onClick={onClickLeft}
    >
      <Icon icon="chevron-left" />
    </Button>
  );

  const renderRightButton = () => (
    <Button
      className="bp3-minimal bp3-intent-primary bp3-small"
      onClick={onClickRight}
    >
      <Icon icon="chevron-right" />
    </Button>
  );
  return (
    <>
      {!timesheet && renderLeftButton()}
      <span>
        {moment(startDayOfWeek).format("YYYY/MM/DD")}
        {" - "}
        {moment(endDayOfWeek).format("YYYY/MM/DD")}
      </span>
      {!timesheet && renderRightButton()}
    </>
  );
};
