import { Button, Checkbox, Icon, InputGroup, Intent } from "@blueprintjs/core";
import { Tooltip } from "@material-ui/core";
import classnames from "classnames";
import { Field, Formik } from "formik";
import * as React from "react";
import { isNullOrUndefined } from "util";
import * as Yup from "yup";
import { Constants } from "../../../constants";
import { IModel } from "../../../core";
import { IDepartmentSpecificFieldConfig } from "../../../model";
import * as DateUtils from "../../../utils/date";
import { DateInput } from "../../DateInput";
import { FormGroup } from "../FormGroup";

export interface IFormProps {
  onCancel: () => void;
  onSubmit: (values, actions) => void;
  skipValidations?: boolean;
  className?: string;
  isClone?: boolean;
  initialValues?: any;
}

export interface IDSFFormProps extends IFormProps {
  departmentSpecificFieldConfigs: IDepartmentSpecificFieldConfig[];
  renderDSFEditor: (params) => JSX.Element;
  validationSchema: Yup.ObjectSchema<any>;
}

export class DSFForm extends React.Component<IDSFFormProps> {
  public static renderInputGroup(label, props) {
    const { field, form } = props;
    const { name, value } = field;

    return (
      <FormGroup {...props} label={label}>
        <InputGroup {...field} placeholder={label} value={value || ""} />
      </FormGroup>
    );
  }

  public static renderDateInput(label, props) {
    const { field, form } = props;
    const { name, value } = field;
    const onChange = (date) =>
      form.setFieldValue(name, DateUtils.formatDate(date));

    return (
      <FormGroup {...props} label={label}>
        <DateInput
          {...field}
          value={value ? DateUtils.parseDate(value) : null}
          onChange={onChange}
          formatDate={DateUtils.formatDate}
          parseDate={DateUtils.parseDateStrict}
          maxDate={Constants.MAX_DATE}
          placeholder={Constants.DATE_FORMAT}
        />
      </FormGroup>
    );
  }

  public static renderCheckBox(label, props) {
    const { field, form } = props;
    const { name, value } = field;
    const onChange = (input) =>
      form.setFieldValue(name, input.currentTarget.checked);
    return (
      <FormGroup {...props}>
        <Checkbox
          checked={value}
          onChange={onChange}
          label={label}
          defaultChecked={true}
        />
      </FormGroup>
    );
  }

  public static renderSubtaskWarning(props) {
    const { taskTypeId, cloneSubtasks } = props.form.values.model;
    const message =
      "When both ‘Clone Subtasks’ is selected and new job type is chosen, the subtasks of the original job will be cloned instead of default subtask of new job type";
    const renderWarningIcon = () => (
      <Tooltip title={message}>
        <Icon icon="warning-sign" iconSize={20} color={"#FFC400"} />
      </Tooltip>
    );
    return (
      <div className="warning">
        {taskTypeId && cloneSubtasks && renderWarningIcon()}
      </div>
    );
  }

  public static renderModelSelect(label, SelectComponent, props) {
    const { field, form } = props;
    const { name, value } = field;

    const onSelect = (model: IModel) => form.setFieldValue(name, model.id);

    return (
      <FormGroup {...props} label={label}>
        <SelectComponent selectedId={value} onSelect={onSelect} />
      </FormGroup>
    );
  }

  constructor(props) {
    super(props);

    this.renderForm = this.renderForm.bind(this);

    this.renderDepartmentSpecificFields = this.renderDepartmentSpecificFields.bind(
      this
    );
    this.renderDepartmentSpecificField = this.renderDepartmentSpecificField.bind(
      this
    );
  }

  public render() {
    return (
      <Formik
        initialValues={this.props.initialValues || {}}
        onSubmit={this.props.onSubmit}
        render={this.renderForm}
        validationSchema={this.buildValidationSchema()}
      />
    );
  }

  private renderForm(props) {
    const { errors, handleSubmit, submitCount } = props;
    const hasErrors = Object.keys(errors).length > 0;
    const submitted = submitCount > 0;

    return (
      <div className={classnames("dsf-form", this.props.className)}>
        {this.props.children}
        {this.renderDepartmentSpecificFields()}
        <div className="actions">
          <Button text="Cancel" onClick={this.props.onCancel} />
          <Button
            intent={Intent.PRIMARY}
            text="Save"
            type="submit"
            disabled={hasErrors && submitted}
            onClick={handleSubmit}
          />
        </div>
      </div>
    );
  }

  private renderDepartmentSpecificFields() {
    const fieldConfigs = this.props.departmentSpecificFieldConfigs;

    if (!fieldConfigs || fieldConfigs.length === 0) {
      return;
    }

    const fields = fieldConfigs.map(this.renderDepartmentSpecificField);
    return <div>{fields}</div>;
  }

  private renderDepartmentSpecificField(
    fieldConfig: IDepartmentSpecificFieldConfig
  ) {
    const field = fieldConfig.field;
    const name = `additionalData.${field}`;

    return (
      <Field
        key={name}
        name={name}
        render={this.renderDSFInner.bind(this, fieldConfig)}
      />
    );
  }

  private renderDSFInner(fieldConfig, props) {
    const params = {
      fieldConfig,
      fieldProps: props,
    };

    const label = fieldConfig.name;
    const name = fieldConfig.field;

    return (
      <FormGroup {...props} label={label}>
        {this.props.renderDSFEditor(params)}
      </FormGroup>
    );
  }

  private buildValidationSchema() {
    if (this.props.skipValidations) {
      return;
    }

    const additionalDataSchema = {};

    this.props.departmentSpecificFieldConfigs.forEach((fieldConfig) => {
      const { field, validations } = fieldConfig;
      if (!isNullOrUndefined(validations)) {
        additionalDataSchema[field] = validations;
      }
    });

    return Yup.object().shape({
      model: this.props.validationSchema,
      additionalData: Yup.object().shape(additionalDataSchema),
    });
  }
}
