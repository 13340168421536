import { Card, Icon, NumericInput } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Api } from "../../actions";
import { EstimatedTimeEntryList } from "../../components";
import { ITask } from "../../model";

interface IEstimatedFeePageProps {
  task: ITask;
}

export const EstimatedFeePage: React.FC<IEstimatedFeePageProps> = ({
  task,
}) => {
  const [estimatedFee, setEstimatedFee] = useState(task.estimatedFee);

  useEffect(() => {
    setEstimatedFee(task.estimatedFee);
  }, [task.estimatedFee]);
  const dispatch = useDispatch();

  const onUpdateTask = (original: ITask, updated: ITask) =>
    dispatch(Api.Task.update(original, updated));

  const onChangeEstimatedFeeInput = (
    valueAsNumber: number,
    valueAsString: string,
    inputElement: HTMLInputElement
  ) => {
    setEstimatedFee(valueAsString);
  };

  const handleUpdateTask = (update: Partial<ITask>) => {
    const updated = { ...task, ...update };
    return onUpdateTask(task, updated);
  };

  const handleOnblur = (input) => {
    const value = input.currentTarget.value;
    return handleUpdateTask({ estimatedFee: value });
  };

  if (!task) {
    return null;
  }

  return (
    <div className="estimated-fee-page-container">
      <Card className="estimated-fee">
        <div className="title">
          <h6>
            <Icon icon="dollar" />
            Budgeted Fee
          </h6>
        </div>
        <NumericInput
          leftIcon="dollar"
          onBlur={handleOnblur}
          value={estimatedFee}
          onValueChange={onChangeEstimatedFeeInput}
        />
      </Card>
      <Card className="estimated-time-entry">
        <EstimatedTimeEntryList task={task} />
      </Card>
    </div>
  );
};
