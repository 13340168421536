import _ from "lodash";
import { useGetManyReference } from "ra-core";
import React, { useMemo } from "react";
import { Constants } from "../../constants";
import {
  IAttachmentEntry,
  IChatThreadSortable,
  ICommentEntry,
  IEmailSortable,
  IVersionSortable,
} from "../../model";
import { typeToResource } from "../../utils/resource";
import { HistoryTimeline } from "./HistoryTimeline";

interface IRAHistoryTimelineProps {
  entryId: string;
  entryType: "Task" | "ClientGroup" | "ClientCompany";
  basePath: string;
  clientCompanyId?: string;
  versions?: IVersionSortable[];
}

export const RAHistoryTimeline: React.FC<IRAHistoryTimelineProps> = (props) => {
  const { entryId, entryType } = props;
  const resource = useMemo(() => typeToResource(entryType), [entryType]);

  const {
    data: commentsData = {},
    loaded: commentsLoaded,
  } = useGetManyReference(
    Constants.COMMENT_RESOURCE,
    resource,
    entryId,
    { page: 1, perPage: 1000 },
    { field: "", order: "" },
    { entryId, entryType },
    resource
  );

  const {
    data: attachmentsData = {},
    loaded: attachmentsLoaded,
  } = useGetManyReference(
    Constants.ATTACHMENT_RESOURCE,
    resource,
    entryId,
    { page: 1, perPage: 1000 },
    { field: "", order: "" },
    { entryId, entryType },
    resource
  );

  const { data: emailsData = {}, loaded: emailsLoaded } = useGetManyReference(
    Constants.EMAIL_RESOURCE,
    resource,
    entryId,
    { page: 1, perPage: 1000 },
    { field: "", order: "" },
    { entryId, entryType },
    resource
  );

  const {
    data: chatThreadsData = {},
    loaded: chatThreadsLoaded,
  } = useGetManyReference(
    Constants.CHAT_THREAD_RESOURCE,
    resource,
    entryId,
    { page: 1, perPage: 1000 },
    { field: "", order: "" },
    { entryId, entryType },
    resource
  );

  const comments = _.values(commentsData) as ICommentEntry[];
  const attachments = _.values(attachmentsData) as IAttachmentEntry[];
  const emails = _.values(emailsData) as IEmailSortable[];
  const chatThreads = _.values(chatThreadsData) as IChatThreadSortable[];

  return (
    <HistoryTimeline
      {...props}
      resource={resource}
      comments={comments}
      attachments={attachments}
      emails={emails}
      chatThreads={chatThreads}
    />
  );
};
