import { Icon, Tag } from "@blueprintjs/core";
import { sentenceCase } from "change-case";
import * as React from "react";
import { ReferenceField } from "react-admin";
import { Constants } from "../../constants";
import { IVersionSortable } from "../../model";
import * as DateUtils from "../../utils/date";
import { CreatedBy } from "./CreatedBy";

interface ITimelineChangeEntryProps {
  record: IVersionSortable;
}

export const ChangeEntry = ({ record }: ITimelineChangeEntryProps) => {
  if (!record) {
    return null;
  }

  const { createdAt, fieldName, valueFrom, valueTo } = record;
  const date = DateUtils.formatDate(DateUtils.parseDate(createdAt));
  const name = sentenceCase(fieldName);
  return (
    <div className="timeline-changelog-wrapper">
      <div className="timeline-commit-icon">
        <Icon icon="git-commit" />
      </div>
      <ReferenceField
        record={record}
        source="userId"
        basePath={Constants.VERSION_RESOURCE}
        reference={Constants.USER_RESOURCE}
      >
        <CreatedBy />
      </ReferenceField>
      <div className="timeline-changelog-content">
        changed <Tag>{name}</Tag>
        {valueFrom && (
          <span>
            {" "}
            from{" "}
            <span className="changelog-value"> &quot;{valueFrom}&quot; </span>
          </span>
        )}{" "}
        to <span className="changelog-value"> &quot;{valueTo}&quot; </span> on{" "}
        {date}
      </div>
    </div>
  );
};
