import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  SelectInput,
} from "ra-ui-materialui";
import React from "react";
import { Constants } from "../../constants";

export const FormResponseFilter = (props) => {
  const clientGroupId = location.pathname.split("/").pop();

  return (
    <Filter {...props}>
      <ReferenceInput
        label="Form"
        source="form_group_id"
        reference={Constants.FORM_GROUPS_RESOURCE}
        alwaysOn={true}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Client User"
        source="client_user_id"
        reference={Constants.CLIENT_USER_RESOURCE}
        filter={{ clientGroupId }}
        alwaysOn={true}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <SelectInput
        source="status"
        choices={[
          { id: "finished", name: "Finished" },
          { id: "unfinished", name: "In Process" },
        ]}
        alwaysOn={true}
      />
    </Filter>
  );
};
