export const paymentType = {
  bank_transfer: "Bank Transfer",
  cash: "Cash",
  check: "Check",
  credit_card: "Credit Card",
  promissory_note: "Promissory Note",
};

export const paymentTypes = Object.keys(paymentType).map((key) => ({
  id: key,
  name: paymentType[key],
}));
