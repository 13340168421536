import { makeStyles } from "@material-ui/core";
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from "ra-ui-materialui";
import React from "react";
import { useSelector } from "react-redux";
import { Constants } from "../../constants";
import { ReportRole } from "../../constants/reportRoles";
import { useReportParams } from "../../hooks/useReportParams";
import { IEtsState } from "../../model";
import { PriceField } from "../fields";
import { HourDetailShow } from "../HourByUser/HourByUserShow";
import { hourByJobExporter } from "./exporter";
import { BudgetDuration } from "./Fields/BudgetDuration";
import { HourReportFilter } from "./Fields/HourReportFilter";
import { RecoverRateField } from "./Fields/RecoverRateField";

const linkTo = (record) => `/app/companies/${record.clientCompanyId}`;

const useStyles = makeStyles({
  budgetDuration: { textAlign: "right" },
});

export const HourByJobList: React.FC<any> = (props) => {
  const isDaily = useSelector(
    (state: IEtsState) => state.departmentSetting.timebillDaily
  );
  const filterParams = useReportParams(ReportRole.HOURS_BY_JOB_DEPARTMENT, {});

  const classes = useStyles();

  return (
    <List
      {...props}
      filters={<HourReportFilter reportType={ReportRole.HOURS_BY_JOB_TENANT} />}
      bulkActionButtons={false}
      filterDefaultValues={filterParams}
      exporter={hourByJobExporter(isDaily)}
    >
      <Datagrid rowClick="expand" expand={<HourDetailShow type="Job" />}>
        <TaskField />
        <ReferenceField
          label="Client Company"
          source="clientCompanyId"
          reference="client_companies"
          link={linkTo}
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="taskStartDate" label="Start Date" />
        <DateField source="taskDueDate" label="Due Date" />
        <BudgetDuration
          label={`Budgeted ${isDaily ? "Days" : "Hours"}`}
          cellClassName={classes.budgetDuration}
          headerClassName={classes.budgetDuration}
          isDaily={isDaily}
          textAlign="right"
        />
        <PriceField
          source="estimatedFee"
          label="Budgeted Fee"
          textAlign="right"
          sortable={false}
        />
        <RecoverRateField
          textAlign="right"
          type="estimated"
          label="Bud. Recoverability Rate"
        />
        <RecoverRateField
          textAlign="right"
          type="actual"
          label="Actual Recoverability Rate"
        />
      </Datagrid>
    </List>
  );
};

const TaskField: React.FC<any> = (props) => {
  const auth = useSelector((state: IEtsState) => state.auth);
  const canLink = auth.departmentId === props.record.departmentId;
  return (
    <ReferenceField
      resource={Constants.HOURS_BY_JOB_RESOURCE}
      reference={Constants.TASK_RESOURCE}
      basePath={Constants.HOUR_BY_JOB_PATH}
      link={canLink}
      addLabel={false}
      record={props.record}
      label="Job"
      source="id"
    >
      <TextField source="description" />
    </ReferenceField>
  );
};
