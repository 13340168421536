/* eslint-disable promise/always-return */
import * as HttpStatus from "http-status-codes";
import { Api, IApiError } from "../../api/actions/Api";
import { ILoginUserResponse, IUser } from "../../app/types";
import { IAuthState } from "../model/AuthenticationState";
import {
  completeLogin,
  failLogin,
  startLogin,
  successfulGetUserDetails,
} from "./creators";
import { completeLogoutAndRedirect } from "./thunks";
import { AuthDispatch as Dispatch, IAuthToken } from "./types";

export class AuthApi extends Api {
  public static getInstance(): AuthApi {
    if (!AuthApi.singleton) {
      AuthApi.singleton = new AuthApi();
    }

    return AuthApi.singleton;
  }

  public static userFromJwtUserResponse(response: ILoginUserResponse): IUser {
    const user = response.data;
    return {
      id: user.id,
      username: user.username,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      userTrialOver: user.userTrialOver,
    };
  }

  private static singleton: AuthApi;

  public login(username: string, password: string) {
    return (dispatch: Dispatch, getState: () => IAuthState) => {
      dispatch(startLogin());
      const authState = getState().auth;
      const innerPayload = authState.useEmailAsUsername
        ? { email: username, password }
        : { username, password };

      // Some auth systems expect parameters in the form:
      // {
      //   user: {
      //     email: test@test.com
      //     password: testtest
      //   }
      // }
      // const payload = getState().auth.wrapParameters
      //   ? { user: innerPayload }
      //   : innerPayload;
      return this.postRequest(
        "/auth/sign_in",
        innerPayload,
        dispatch,
        null
      ).then((response: ILoginUserResponse) => {
        if (response.data) {
          // if (response.token) {
          //   dispatch(completeLogin(response.token));
          // }
          if (response.data.email) {
            const user = AuthApi.userFromJwtUserResponse(response);
            const { roles, department } = response.data;
            dispatch(successfulGetUserDetails(user, roles, department.id));
          } else {
            // TODO: Should really fix the Django server so that it returns the user details
            // with the tokens--that would allow elimination of this branching code path.
            // Have to grab new state because we've just updated through dispatch, in theory
            const token = getState().auth.token;
            return this.getRequest(
              "/api/users/me/",
              null,
              dispatch,
              token
            ).then((userDetailsResponse: IUser) => {
              const user = AuthApi.userFromJwtUserResponse(response);
              const { roles, department } = response.data;
              dispatch(successfulGetUserDetails(user, roles, department.id));
            });
          }
        }
      });
    };
  }

  public socialLogin(provider: string, code: string, redirectUri: string) {
    return (dispatch: Dispatch, getState: () => IAuthState) => {
      dispatch(startLogin());
      const payload = {
        provider,
        code,
        redirect_uri: redirectUri,
      };
      return this.postRequest(
        "/api/login/social/jwt_user/",
        payload,
        dispatch,
        getState().auth.token
      ).then((response: ILoginUserResponse) => {
        const user = AuthApi.userFromJwtUserResponse(response);
        const { roles, department } = response.data;
        // dispatch(completeLogin(response.token));
        dispatch(successfulGetUserDetails(user, roles, department.id));
      });
    };
  }

  public logout() {
    return (dispatch: Dispatch, getState: () => IAuthState) => {
      const token = getState().auth.token;
      return this.deleteRequest("/auth/sign_out", dispatch, token).then(() => {
        dispatch(completeLogoutAndRedirect());
      });
    };
  }

  protected errorTransformer(_url: string, _error: IApiError): Promise<string> {
    return Promise.reject("Invalid credentials.");
  }

  protected handleUnsuccessfulRequest(reason: string, dispatch: Dispatch) {
    super.handleUnsuccessfulRequest(reason, dispatch);
    dispatch(failLogin(reason));
  }

  protected handleApiResponse<T>(
    dispatch: Dispatch,
    response: Response
  ): Promise<T | string> {
    switch (response.status) {
      case HttpStatus.OK:
      case HttpStatus.CREATED:
        // Handle pulling the JWT token out of the headers if it appears there.
        const authorizationHeader = getAuthToken(response);
        if (authorizationHeader !== null) {
          dispatch(completeLogin(authorizationHeader));
        }
        return super.handleApiResponse(dispatch, response);
      case HttpStatus.NO_CONTENT:
        return Promise.resolve("Successfully logged out.");
      case HttpStatus.UNAUTHORIZED:
        return Promise.reject("Invalid credentials.");
      default:
        super.handleApiResponse(dispatch, response);
    }

    return super.handleApiResponse(dispatch, response);
  }
}

export const getAuthToken = (response: Response): IAuthToken => {
  return {
    accessToken: response.headers.get("access-token"),
    client: response.headers.get("client"),
    expiry: response.headers.get("expiry"),
    tokenType: response.headers.get("token-type"),
    uid: response.headers.get("uid"),
  };
};

export const getClientUserDetail = (response): IUser => {
  const user = response.data;
  return {
    firstName: user.firstName,
    lastName: user.lastName,
    username: `${user.firstName},${user.lastName}`,
    email: user.email,
    id: user.id,
  };
};
