import { Button } from "@material-ui/core";
import { refreshView } from "ra-core";
import React from "react";
import { useDispatch } from "react-redux";
import { ApprovalAction, useApproval } from "../../hooks";
import { ICreditNote, IInvoice, IWriteOff } from "../../model";
import { workFlowLabel } from "../../utils/approvalStatus";

interface IWorkFlowButtonProps {
  resource: string;
  workFlow: ApprovalAction;
  record: ICreditNote | IWriteOff | IInvoice;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const WorkFlowButton: React.FC<IWorkFlowButtonProps> = ({
  resource,
  workFlow,
  record,
  onClick,
}) => {
  const handleSubmit = useApproval(resource, workFlow, record.id);

  const dispatch = useDispatch();
  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    handleSubmit(
      {},
      {
        onSuccess: () => {
          if (onClick) {
            onClick(event);
          }
          dispatch(refreshView());
        },
      }
    );
  };
  const label = workFlowLabel(workFlow);
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleOnClick}
      style={{ margin: 20 }}
    >
      {label}
    </Button>
  );
};
