import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { ReferenceField, Show, SimpleShowLayout, TextField } from "react-admin";
import { FormattedMessage } from "react-intl";
import { Constants } from "../../../constants";
import { IClientInvoice } from "../../../model";
import { priceFormatter } from "../../../utils/money";
import { FpsQrCode } from "./FpsQrCode";
import { InvoiceStatusField } from "./InvoiceStatusField";
import { PdfLink } from "./PdfLink";

export const ClientInvoiceShow: React.FC<any> = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="date" className="client-invoice-admin-field" />
        <TextField
          source="feeNoteNumber"
          className="client-invoice-admin-field"
        />
        <ReferenceField
          source="clientCompanyId"
          reference={Constants.CLIENT_COMPANY_RESOURCE}
          link={false}
          className="client-invoice-admin-field"
        >
          <TextField source="name" />
        </ReferenceField>
        <TitleShow />
        <PriceShow source="amount" />
        <InvoiceStatusField />
        <PdfLink label="PDF" />
        <FpsQrCode />
      </SimpleShowLayout>
    </Show>
  );
};

export const invoiceShowStyle = makeStyles({
  show: {
    marginTop: 10,
    marginBottom: 10,
  },
  title: {
    fontWeight: "bold",
    fontSize: 15,
    color: "#000000DE",
  },
  content: {
    paddingTop: 8,
    fontSize: 14,
  },
});

interface IPriceShowProps {
  record?: IClientInvoice;
  source?: string;
  sortable?: boolean;
  textAlign?: string;
}

const PriceShow: React.FC<IPriceShowProps> = ({
  record,
  source,
  sortable = false,
}) => {
  const classes = invoiceShowStyle({});
  return (
    <div className="MuiFormControl-root MuiFormControl-marginDense">
      <label className={classes.title}>
        <FormattedMessage
          description="invoice amount"
          defaultMessage="Amount"
        />
      </label>
      <Typography component="span" variant="body2" className={classes.content}>
        {priceFormatter.format(Number(record[source]))}
      </Typography>
    </div>
  );
};

interface ITitleShowProps {
  record?: IClientInvoice;
  source?: string;
  sortable?: boolean;
}

const TitleShow: React.FC<ITitleShowProps> = ({
  record,
  source,
  sortable = false,
}) => {
  const classes = invoiceShowStyle({});
  const title = record.title || "Fee Note";
  return (
    <div className="MuiFormControl-root MuiFormControl-marginDense">
      <label className={classes.title}>
        <FormattedMessage description="invoice Title" defaultMessage="Title" />
      </label>
      <Typography component="span" variant="body2" className={classes.content}>
        {title}
      </Typography>
    </div>
  );
};
