import { Checkbox } from "@blueprintjs/core";
import * as React from "react";
import { ReactTableDefaults, RowInfo } from "react-table";
import { ITask } from "../../model";

interface ITaskTableRowProps {
  className?: string;
  handleExpanderClick: (rowInfo: RowInfo) => void;
  handleTaskSelectAllToggle: (selectedAll: boolean, taskIds: string[]) => void;
  rowInfo: RowInfo;
  selectedTaskIds: Set<ITask>;
  style: React.CSSProperties;
}

export class TaskTableRow extends React.Component<ITaskTableRowProps> {
  constructor(props) {
    super(props);

    this.handleExpanderClick = this.handleExpanderClick.bind(this);
  }

  public render() {
    const {
      handleExpanderClick,
      handleTaskSelectAllToggle,
      rowInfo,
      selectedTaskIds,

      ...otherProps
    } = this.props;

    if (rowInfo && rowInfo.groupedByPivot) {
      const cell = { ...this.props.children[rowInfo.level] };
      cell.props.style.flex = "unset";
      cell.props.style.width = "100%";
      cell.props.style.maxWidth = "unset";

      const taskIds = [];
      let selectedAll = true;

      rowInfo.subRows.forEach((subrow) => {
        const taskId = subrow._original.id;
        if (!selectedTaskIds.has(taskId)) {
          selectedAll = false;
        }

        taskIds.push(taskId);
      });

      return (
        <div
          className={`rt-tr ${this.props.className} ${
            selectedAll ? "selected" : ""
          }`}
          style={this.props.style}
          onClick={this.handleExpanderClick}
        >
          <Checkbox
            className={"select-all-toggle"}
            checked={selectedAll}
            onChange={this.handleTaskSelectAllToggle.bind(
              this,
              selectedAll,
              taskIds
            )}
          />
          {cell}
        </div>
      );
    }
    const selected = rowInfo && selectedTaskIds.has(rowInfo.original.id);

    return (
      <ReactTableDefaults.TrComponent
        {...otherProps}
        className={`rt-tr ${this.props.className} ${
          selected ? "selected" : ""
        }`}
      />
    );
  }

  private handleExpanderClick() {
    const { handleExpanderClick, rowInfo } = this.props;
    handleExpanderClick(rowInfo);
  }

  private handleTaskSelectAllToggle(selectedAll, taskIds) {
    this.props.handleTaskSelectAllToggle(selectedAll, taskIds);
  }
}
