import { makeStyles } from "@material-ui/styles";
import * as qs from "qs";
import { useGetOne } from "ra-core";
import {
  BooleanInput,
  Create,
  DateInput,
  ReferenceField,
  SimpleForm,
  TextInput,
} from "ra-ui-materialui";
import React from "react";
import { Constants } from "../../constants";
import { IInvoice } from "../../model";
import { PostCreateToolbar } from "../CreateToolBar";
import { ClientCompanyField, TaskField } from "../fields";
import { FeeNoteField } from "./CustomField/FeeNoteField";
import { WipAndExpenseField } from "./CustomField/WipAndExpenseField";

const useStyles = makeStyles({
  description: { width: 350 },
});

const initialValues = { date: new Date() };

export const CreditNoteCreate: React.FC<any> = (props) => {
  const classes = useStyles({});
  const { invoiceId } = qs.parse(props.location.search.slice(1));
  const { loaded, data } = useGetOne(
    Constants.INVOICE_RESOURCE,
    invoiceId as string
  );
  const invoice = data as IInvoice;
  if (!invoice) {
    return null;
  }
  const referenceRecord = { invoiceId, taskId: invoice.taskId };
  return (
    <Create {...props} location={{}}>
      <SimpleForm
        initialValues={{ ...initialValues, invoiceId: invoice.id }}
        toolbar={<PostCreateToolbar />}
      >
        <>
          <ReferenceField
            source="taskId"
            reference={Constants.TASK_RESOURCE}
            addLabel={false}
            link={false}
            record={referenceRecord}
            basePath={Constants.CREDIT_NOTE_PATH}
            resource={Constants.CREDIT_NOTE_RESOURCE}
          >
            <TaskField />
          </ReferenceField>
          <ReferenceField
            source="taskId"
            reference={Constants.TASK_RESOURCE}
            addLabel={false}
            link={false}
            record={referenceRecord}
            basePath={Constants.CREDIT_NOTE_PATH}
            resource={Constants.CREDIT_NOTE_RESOURCE}
          >
            <ClientCompanyField />
          </ReferenceField>
          <ReferenceField
            source="invoiceId"
            reference={Constants.INVOICE_RESOURCE}
            addLabel={false}
            link={false}
            record={referenceRecord}
            basePath={Constants.CREDIT_NOTE_PATH}
            resource={Constants.CREDIT_NOTE_RESOURCE}
          >
            <FeeNoteField />
          </ReferenceField>
        </>
        <DateInput source="date" />
        <TextInput
          source="description"
          multiline={true}
          rows="4"
          className={classes.description}
        />
        <WipAndExpenseField invoice={invoice} disableEdit={false} />
        <TextInput
          label="Internal Remarks"
          source="internalRemarks"
          multiline={true}
          rows="4"
          className={classes.description}
        />
        <BooleanInput label="Write-Off?" source="isWriteOff" />
      </SimpleForm>
    </Create>
  );
};
