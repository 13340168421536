import { Button, Icon, Intent } from "@blueprintjs/core";
import moment from "moment";
import React, { FC, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Api } from "../../actions";
import { IEtsUser } from "../../model";
import { DurationTimeInput } from "../inputs";
import { EstimatedUserSelect } from "./EstimatedUserSelect";

interface IEstimatedTimeEntryCreatorProps {
  taskId: string;
}

export const EstimatedTimeEntryCreator: FC<IEstimatedTimeEntryCreatorProps> = ({
  taskId,
}) => {
  const dispatch = useDispatch();
  const [duration, setDuration] = useState<string>(
    moment.duration(0, "h").toISOString()
  );
  const [user, setUser] = useState<IEtsUser>();

  const handleCreateEstimatedTimeEntry = useCallback(() => {
    dispatch(
      Api.EstimatedTimeEntry.create({
        taskId,
        duration,
        userId: user.id,
      })
    );
    setUser(null);
  }, [dispatch, user, duration]);
  const handleSelectUser = useCallback(
    async (newUser: IEtsUser) => {
      setUser(newUser);
    },
    [setUser]
  );

  return (
    <div className="estimated-time-entry-creator">
      <div className="estimated-time-entry-creator-first">
        <div className="item">
          <Icon icon="edit" />
        </div>
        <EstimatedUserSelect
          selectedId={user?.id}
          onSelect={handleSelectUser}
        />
      </div>
      <div className="estimated-time-entry-creator-duration">
        <DurationTimeInput
          duration={duration}
          onChange={setDuration}
          disabled={false}
        />
      </div>
      <div className="estimated-time-entry-creator-button">
        <Button
          text="Create"
          intent={Intent.PRIMARY}
          onClick={handleCreateEstimatedTimeEntry}
          disabled={!user || moment.duration(duration).asHours() === 0}
        />
      </div>
    </div>
  );
};
