import { FormLabel } from "@material-ui/core";
import { useGetManyReference } from "ra-core";
import * as React from "react";
import { FormDataConsumer, NumberField } from "react-admin";
import { Constants } from "../../constants";
import { priceFormatter } from "../../utils/money";

export const AmountFormField: React.FC<any> = ({
  type,
  record,
  label,
  amount,
}) => {
  const { loaded, data } = useGetManyReference(
    Constants.ACCOUNT_BALANCE_RESOURCE,
    Constants.TASK_RESOURCE,
    record.taskId,
    { page: 1, perPage: 1 },
    { field: "", order: "" },
    {},
    Constants.TASK_RESOURCE
  );

  if (!data) {
    return null;
  }

  const balance = data[record.taskId];

  const balanceAmount =
    type === "wip"
      ? balance.provisionalBalance
      : balance.expenseProvisionalBalance;

  const renderer = () => {
    const labelWithBalance = `${label} (${priceFormatter.format(
      parseFloat(balanceAmount)
    )} on balance)`;
    return (
      <>
        <FormLabel component="legend">{labelWithBalance}</FormLabel>
        <NumberField
          record={{ amount }}
          source="amount"
          options={{ style: "currency", currency: "HKD" }}
        />
      </>
    );
  };

  return <FormDataConsumer>{renderer}</FormDataConsumer>;
};
