import { Classes, MenuItem } from "@blueprintjs/core";
import {
  ItemListRenderer,
  ItemPredicate,
  Select,
  Suggest,
} from "@blueprintjs/select";
import { IModel, IModelById } from "@chiubaka/core";
import classnames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { List } from "react-virtualized";
import { createSelector } from "reselect";
import { isNullOrUndefined } from "util";
import { IEtsState } from "../../../model";
import * as Utils from "../../../utils/highlight";
import { noSort } from "./Sort";

const ROW_HEIGHT = 30;
const MAX_HEIGHT = 300;

export interface IModelSelectOwnProps<T extends IModel> {
  defaultButtonLabel?: string;
  disabled?: boolean;
  selectedId?: string;
  suggest?: boolean;
  onSelect: (item: T) => Promise<any>;
  creatable?: boolean;
  onCreate?: (query: string) => Promise<T>;
  query?: string;
}

export const itemRenderer = (minQueryLength, item, props) => {
  const { handleClick, modifiers, query } = props;

  if (shouldRenderItem(item, modifiers)) {
    return null;
  }

  const text = getItemText(item, query, minQueryLength);

  return (
    <MenuItem
      active={modifiers.active}
      disabled={item.disabled}
      key={item.createOnSelect ? "creatable" : item.model.id}
      label={item.label}
      onClick={handleClick}
      text={text}
    />
  );
};

function shouldRenderItem(item, modifiers) {
  return !modifiers.matchesPredicate && !item.createOnSelect;
}

function getItemText(item, query, minQueryLength) {
  // If rendering the creatable option, need to make that clear.
  if (item.createOnSelect) {
    return (
      <span>
        Create new option &quot;<strong>{item.text}</strong>&quot;
      </span>
    );
  }

  // If query is longer than minimum length for highlighting, highlight it.
  if (query.length >= minQueryLength) {
    return Utils.highlightText(item.text as string, query);
  }

  return item.text;
}

export function selectForModel<T extends IModel>(
  classUniqueIdentifier: string,
  optionButtonStyleFactory: (item: T) => {},
  optionTextFactory: (item: T) => string,
  optionLabelFactory: (item: T) => string,
  modelsFromState: (state: IEtsState) => T[],
  modelFromId: (id: string, state: IEtsState) => T,
  buttonLabelFactory?: (item: T) => string,
  modelFilter: (state: IEtsState, item: T) => boolean = () => true,
  itemSorter?: (a, b) => number,
  minQueryLength: number = 1,
  passedProps: any = {}
) {
  if (!buttonLabelFactory) {
    buttonLabelFactory = optionTextFactory;
  }

  interface IModelSelectItem {
    model: T;
    label: string;
    text: string;
    buttonStyle: {};
    createOnSelect?: boolean;
    disabled?: boolean;
  }

  interface IModelSelectStateProps {
    items: IModelSelectItem[];
    selectedItem: IModelSelectItem;
  }

  interface IModelSelectProps
    extends IModelSelectOwnProps<T>,
      IModelSelectStateProps {}

  interface IModelSelectState {
    createOnSelect: boolean;
    query: string;
    inputFocused: boolean;
    disabled: boolean;
  }

  const MSelect = Select.ofType<IModelSelectItem>();
  const MSuggest = Suggest.ofType<IModelSelectItem>();

  class ModelSelectImpl extends React.Component<
    IModelSelectProps,
    IModelSelectState
  > {
    public static modelToItem(model: T): IModelSelectItem {
      if (!model) {
        return null;
      }

      return {
        model,
        label: optionLabelFactory(model),
        text: optionTextFactory(model),
        buttonStyle: optionButtonStyleFactory(model),
        createOnSelect: false,
      };
    }

    private static itemPredicate: ItemPredicate<IModelSelectItem> = (
      query,
      item
    ) => {
      if (!query || item.createOnSelect) {
        return true;
      }

      const lowerCaseQuery = query.toLowerCase();
      const text = item.text;
      const label = item.label;
      return (
        (text as string).toLowerCase().indexOf(lowerCaseQuery) >= 0 ||
        (label && label.toLowerCase().indexOf(lowerCaseQuery) >= 0)
      );
    };

    private selectInput;

    constructor(props: IModelSelectProps) {
      super(props);

      this.state = {
        createOnSelect: false,
        query: null,
        inputFocused: false,
        disabled: false,
      };

      this.selectInput = React.createRef();

      this.creatableItem = this.creatableItem.bind(this);
      this.itemListRenderer = this.itemListRenderer.bind(this);
      this.renderCreatableHelperText = this.renderCreatableHelperText.bind(
        this
      );
      this.renderNonIdealState = this.renderNonIdealState.bind(this);

      this.handleSelect = this.handleSelect.bind(this);
      this.handleQueryChange = this.handleQueryChange.bind(this);

      this.clearSelection = this.clearSelection.bind(this);
      this.disable = this.disable.bind(this);
      this.enable = this.enable.bind(this);
    }

    public componentDidUpdate() {
      if (this.props.suggest && !this.state.inputFocused) {
        // This is a hack, but something down the lifecycle chain is setting the
        // placeholder and value. It's not clear what... but the setTimeout here
        // will be executed after this serial thread is done, so is guaranteed
        // to occur after the chain.
        setTimeout(this.clearSelection, 0);
      }
    }

    public render(): JSX.Element {
      const { defaultButtonLabel, selectedItem, suggest, query } = this.props;
      const disabled = this.state.disabled || this.props.disabled;
      let buttonLabel: React.ReactNode | string = selectedItem
        ? buttonLabelFactory(selectedItem.model)
        : defaultButtonLabel;

      if (isNullOrUndefined(buttonLabel)) {
        buttonLabel = "Select an option";
      } else if (query && !suggest) {
        buttonLabel = Utils.highlightText(buttonLabel as string, query);
      }

      const items = [...this.props.items];

      // Shim for creatable options is to add a special option to the beginning
      // of the items list. This allows us to maintain the built-in controlled
      // state functionality from the underlying components.
      const creatableItem = this.creatableItem();
      if (creatableItem) {
        items.unshift(this.creatableItem());
      }

      // properties passed to both MSelect + MSuggest properties
      const componentProps = {
        inputValueRenderer: (item) => item.text,
        items,
        itemListRenderer: this.itemListRenderer,
        itemRenderer: itemRenderer.bind(this, minQueryLength),
        itemPredicate: ModelSelectImpl.itemPredicate,
        onItemSelect: this.handleSelect,
        onQueryChange: this.handleQueryChange,
        resetOnSelect: true,
        resetOnClose: true,
        // We're relying on react-virtualized to handle the scroll, so disabling Blueprint's
        // internal scroll
        scrollToActiveItem: false,
        selectedItem: null,
        ...(passedProps || {}),
      };

      // style properties passed to MSelect > button

      // selectedItem is null in a form, e.g. 'Create Task'
      const buttonStyle = isNullOrUndefined(selectedItem)
        ? null
        : selectedItem.buttonStyle;
      const buttonStyleProps = {
        className: classnames(
          Classes.BUTTON,
          "model-select",
          classUniqueIdentifier + "-mselect-button"
        ),
        style: buttonStyle,
      };

      // This inputProps is only used for MSuggest, but inputProps is still passed in via passedProps
      const inputProps = {
        disabled,
        placeholder: buttonLabel as string,
        inputRef: (input) => (this.selectInput = input),
        onBlur: () => this.setState({ ...this.state, inputFocused: false }),
        onFocus: () => this.setState({ ...this.state, inputFocused: true }),
      };

      const popoverProps = {
        popoverClassName: "model-select-popover",
        onClose: this.clearSelection,
        onOpen: this.clearSelection,
        ...(passedProps.popoverProps || {}),
      };

      if (suggest) {
        return (
          <MSuggest
            {...componentProps}
            inputProps={inputProps}
            popoverProps={popoverProps}
            disabled={disabled}
            className={classnames(classUniqueIdentifier + "-mselect-suggest")}
          />
        );
      } else {
        return (
          <MSelect
            {...componentProps}
            popoverProps={popoverProps}
            disabled={disabled}
            className={classnames(
              "select-component",
              classUniqueIdentifier + "-mselect-button"
            )}
          >
            <button
              {...buttonStyleProps}
              disabled={disabled}
              ref={(button) => {
                this.selectInput = button;
              }}
            >
              <span
                className={classnames(
                  Classes.BUTTON_TEXT,
                  classUniqueIdentifier + "-mselect-text"
                )}
                tabIndex={-1}
              >
                {buttonLabel}
              </span>
            </button>
          </MSelect>
        );
      }
    }

    private itemListRenderer: ItemListRenderer<IModelSelectItem> = (props) => {
      const { activeItem, filteredItems, renderItem } = props;

      const activeIndex = activeItem
        ? filteredItems.indexOf(activeItem as IModelSelectItem)
        : 0;
      let numRows = filteredItems.length;
      // If there are now items, we will still show one item to alert the user.
      if (numRows === 0 || this.shouldRenderCreatableHelperText()) {
        numRows += 1;
      }

      const height = Math.min(numRows * ROW_HEIGHT, MAX_HEIGHT);

      return (
        <List
          width={300}
          height={height}
          noRowsRenderer={this.renderNonIdealState}
          rowCount={numRows}
          rowHeight={ROW_HEIGHT}
          rowRenderer={this.rowRenderer.bind(this, renderItem, filteredItems)}
          scrollToIndex={activeIndex}
        />
      );
    };

    private rowRenderer(renderItem, filteredItems, args) {
      const { key, style } = args;
      let index = args.index;

      if (this.shouldRenderCreatableHelperText()) {
        if (index === 0) {
          return this.renderCreatableHelperText();
        } else {
          // If we're rendering the helper text, then we need to offset the index by one
          // for rendering the items themselves
          index--;
        }
      }

      const item = filteredItems[index];
      if (item == null) {
        return this.renderNonIdealState();
      }

      return (
        <div key={key} style={style} className="model-select-item">
          {renderItem(item, index)}
        </div>
      );
    }

    private shouldRenderCreatableHelperText() {
      return this.props.creatable && !this.state.query;
    }

    private renderNonIdealState() {
      if (this.shouldRenderCreatableHelperText()) {
        return this.renderCreatableHelperText();
      }

      return (
        <div className="model-select-item" key="non-ideal-state">
          <MenuItem disabled={true} text="No results." />
        </div>
      );
    }

    private renderCreatableHelperText() {
      // If the user has typed anything, they'll see the "Create a new option..."
      // text, so no need to show them anything here.
      if (!this.shouldRenderCreatableHelperText()) {
        return null;
      }

      return (
        <div className="model-select-item" key="creatable-helper-text">
          <MenuItem
            text={<strong>Start typing to create a new option.</strong>}
            disabled={true}
          />
        </div>
      );
    }

    private creatableItem(): IModelSelectItem {
      const query = this.state.query;
      if (!this.props.creatable || !query) {
        return null;
      }

      const exactMatches = this.optionsWithExactMatch();
      if (exactMatches.length !== 0) {
        return null;
      }

      return {
        text: query,
        label: null,
        model: null,
        buttonStyle: {},
        createOnSelect: true,
        disabled: isNullOrUndefined(this.state.query),
      };
    }

    private handleSelect(item: IModelSelectItem) {
      const promise = item.createOnSelect
        ? this.props.onCreate(this.state.query)
        : this.props.onSelect(item.model);

      if (!promise) {
        return;
      }

      this.disable();
      return promise.finally(() => {
        this.enable();
        this.clearSelection();

        if (!this.props.suggest) {
          this.selectInput.focus();
        }
      });
    }

    private handleQueryChange(query: string) {
      this.setState({ ...this.state, query });
    }

    private clearSelection() {
      if (this.selectInput == null) {
        return;
      }

      this.selectInput.placeholder = this.props.defaultButtonLabel;
      this.selectInput.value = null;
    }

    private optionsWithExactMatch() {
      return this.props.items.filter((item) => item.text === this.state.query);
    }

    private disable() {
      this.setState({ ...this.state, disabled: true });
    }

    private enable() {
      this.setState({ ...this.state, disabled: false });
    }
  }

  const getSortedItems = createSelector(
    [
      (state: IEtsState) =>
        modelsFromState(state).filter(modelFilter.bind(this, state)),
    ],
    (models: T[]) => {
      const items = models.map(ModelSelectImpl.modelToItem);
      const sortFunc = itemSorter ? itemSorter : noSort;
      items.sort(sortFunc);
      return items;
    }
  );

  function mapStateToProps(
    state: IEtsState,
    ownProps: IModelSelectOwnProps<T>
  ): IModelSelectStateProps {
    const selectedId = ownProps.selectedId;
    const selectedModel = selectedId ? modelFromId(selectedId, state) : null;
    const selectedItem = ModelSelectImpl.modelToItem(selectedModel);

    return {
      items: getSortedItems(state),
      selectedItem,
    };
  }

  return connect(mapStateToProps)(ModelSelectImpl);
}

export function selectForModelById<T>(
  classUniqueIdentifier: string,
  optionButtonStyleFactory: (item: T) => {},
  optionTextFactory: (item: T) => string,
  optionLabelFactory: (item: T) => string,
  modelByIdFromState: (state: IEtsState) => IModelById<T>,
  buttonLabelFactory?: (item: T) => string,
  itemFilter?: (state: IEtsState, item: T) => boolean,
  itemSorter?: (a, b) => number,
  minQueryLength?: number,
  passedProps?: any
) {
  function itemsFromModelById(state: IEtsState) {
    const modelById = modelByIdFromState(state);
    const items = [];

    for (const id in modelById) {
      if (!modelById.hasOwnProperty(id)) {
        continue;
      }
      items.push(modelById[id]);
    }

    return items;
  }

  return selectForModel(
    classUniqueIdentifier,
    optionButtonStyleFactory,
    optionTextFactory,
    optionLabelFactory,
    itemsFromModelById,
    (id: string, state: IEtsState) => modelByIdFromState(state)[id],
    buttonLabelFactory,
    itemFilter,
    itemSorter,
    minQueryLength,
    passedProps
  );
}
