import { Datagrid, List, ShowButton, TextField } from "ra-ui-materialui";
import * as React from "react";
import { CompanyShow } from "./CompanyShow";

export const CompanyList: React.FC<any> = (props) => {
  const { clientGroupId } = props;
  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={false}
      filter={{ clientGroupId }}
      perPage={10}
    >
      <Datagrid rowClick="expand" expand={<CompanyShow />}>
        <TextField source="name" label="Name" />
        <TextField source="clientId" label="Client ID" />
        <TextField source="chineseName" label="Chinese Name" />
        <TextField source="jurisdiction" label="Jurisdiction" />
        <TextField source="contactName" label="Contact Name" />
        <ShowButton />
      </Datagrid>
    </List>
  );
};
