import React from "react";
import { useIntl } from "react-intl";

interface IFilterProps {
  value: string;
  updateFilter: (newValue: string) => any;
}

const Filter: React.FC<IFilterProps> = ({ value, updateFilter }) => {
  const intl = useIntl();
  const handleFilterChange = (event) => {
    const newValue = event.target.value;
    updateFilter(newValue);
  };

  return (
    <input
      type="search"
      placeholder={intl.formatMessage({
        description: "DataSafe no files filter",
        defaultMessage: "Filter files",
      })}
      value={value}
      onChange={handleFilterChange}
    />
  );
};

export default Filter;
