import { Classes, Dialog, IDialogProps } from "@blueprintjs/core";
import * as React from "react";
import { connect } from "react-redux";
import { Constants } from "../../constants/index";
import { IModel, ModelApi } from "../../core/api";
import { Dispatch } from "../../types";
import { stripKeySuffix } from "../../utils/fieldSpecific";

interface IBuildModelDialogParams<TModel, TData> {
  defaultDialogProps?: Partial<IDialogProps>;
  Form: React.ComponentClass<any>;
  modelApi: ModelApi<TModel>;
  dataApi: ModelApi<TData>;
  additionalDataApi?: ModelApi<TData>;
  foreignKeyProperty: string;
}

export function buildModelDialog<
  TModel extends IModel,
  TData extends IModel & { [key: string]: any }
>(params: IBuildModelDialogParams<TModel, TData>) {
  const {
    defaultDialogProps,
    Form,
    modelApi,
    dataApi,
    additionalDataApi,
    foreignKeyProperty,
  } = params;

  interface IModelDialogOwnProps extends IDialogProps {
    afterSubmit: (model: TModel) => void;
  }

  interface IModelDialogDispatchProps {
    onCreateModel: (model: TModel) => Promise<TModel>;
    onCreateDepartmentSpecificData: (
      data,
      foreignKey: string
    ) => Promise<TData>;
    onCreateTenantSpecificData: (data, foreignKey: string) => Promise<TData>;
  }

  interface IModelDialogProps
    extends IModelDialogOwnProps,
      IModelDialogDispatchProps {}

  class ModelDialogImpl extends React.Component<IModelDialogProps> {
    public render(): JSX.Element {
      return (
        <Dialog {...defaultDialogProps} {...this.props}>
          <div className={Classes.DIALOG_BODY}>
            <Form onCancel={this.props.onClose} onSubmit={this.handleSubmit} />
          </div>
        </Dialog>
      );
    }

    private handleSubmit = async (values, actions) => {
      const model = await this.props.onCreateModel(values.model);
      if (values.additionalData == null) {
        this.finish(actions, model);
        return;
      }

      const tenantValues = this.getSpecificFieldValues(
        values.additionalData,
        Constants.TENANT_KEY
      );
      const departmentValues = this.getSpecificFieldValues(
        values.additionalData,
        Constants.DEPARTMENT_KEY
      );

      await Promise.all([
        this.props.onCreateDepartmentSpecificData(departmentValues, model.id),
        this.props.onCreateTenantSpecificData(tenantValues, model.id),
      ]);
      this.finish(actions, model);
    };

    private getSpecificFieldValues(data: object, fieldSpecificKey: string) {
      return Object.keys(data).reduce((acc, additionalDataKey) => {
        const additionalDataValue = data[additionalDataKey];
        if (additionalDataKey.includes(fieldSpecificKey)) {
          acc[stripKeySuffix(additionalDataKey)] = additionalDataValue;
        } else {
          acc[additionalDataKey] = additionalDataValue;
        }
        return acc;
      }, {});
    }

    private finish = (actions, model) => {
      actions.resetForm();
      this.props.onClose();
      this.props.afterSubmit(model);
    };
  }

  function mapDispatchToProps(dispatch: Dispatch) {
    return {
      onCreateModel: (model: TModel) => {
        return dispatch(modelApi.create(model));
      },
      onCreateDepartmentSpecificData: (data, foreignKey: string) => {
        (data as any)[foreignKeyProperty] = foreignKey;
        return dispatch(dataApi.create(data));
      },
      onCreateTenantSpecificData: (data, foreignKey: string) => {
        (data as any)[foreignKeyProperty] = foreignKey;
        if (additionalDataApi) {
          return dispatch(additionalDataApi.create(data));
        }
      },
    };
  }

  return connect(null, mapDispatchToProps)(ModelDialogImpl);
}
