import { Grid } from "@material-ui/core";
import React from "react";
import { ReferenceField } from "react-admin";
import { Constants } from "../../constants";
import { IInvoice } from "../../model";
import { ClientCompanyField, TaskField } from "../fields";
import { ClientCompanyAddressField } from "./ClientCompanyAddressField";
import { TotalAmount } from "./TotalAmount";

interface IInvoiceHeaderProps {
  record?: IInvoice;
}

export const InvoiceHeader: React.FC<IInvoiceHeaderProps> = ({ record }) => {
  return (
    <Grid container={true}>
      <Grid xs={8} container={true} item={true}>
        <Grid item={true} xs={12}>
          <ReferenceField
            resource={Constants.INVOICE_RESOURCE}
            reference={Constants.TASK_RESOURCE}
            source="taskId"
            basePath={Constants.TASK_PATH}
            record={record}
            link={false}
            addLabel={false}
          >
            <TaskField />
          </ReferenceField>
        </Grid>
        <Grid item={true} xs={12}>
          <ReferenceField
            resource={Constants.INVOICE_RESOURCE}
            reference={Constants.TASK_RESOURCE}
            source="taskId"
            basePath={Constants.TASK_PATH}
            record={record}
            link={false}
            addLabel={false}
          >
            <ClientCompanyField />
          </ReferenceField>
        </Grid>
        <Grid item={true} xs={12}>
          <ReferenceField
            resource={Constants.INVOICE_RESOURCE}
            reference={Constants.TASK_RESOURCE}
            source="taskId"
            basePath={Constants.TASK_PATH}
            record={record}
            link={false}
            addLabel={false}
          >
            <ClientCompanyAddressField />
          </ReferenceField>
        </Grid>
      </Grid>
      <Grid xs={4} container={true} item={true}>
        <TotalAmount invoice={record} />
      </Grid>
    </Grid>
  );
};
