import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { INotificationProps, NotificationMode } from "../../model";
import { getDaysfromNow } from "../../utils/date";
import {
  FormGroupResponseFullNotification,
  PolymorphicShortNotification,
} from "./NotificationRender";

const NOTIFICATION_RENDER_MODE = {
  Full: FormGroupResponseFullNotification,
  Short: PolymorphicShortNotification,
};

export const FormGroupResponseNotificationComponent: React.FC<INotificationProps> = ({
  mode = NotificationMode.Full,
  notification,
}) => {
  const notificationModeString = NotificationMode[mode];
  const Handler = NOTIFICATION_RENDER_MODE[notificationModeString];

  return (
    <div className="notify-container-right">
      <div className="notify-container-three">
        <Handler notification={notification} mode={mode} />
      </div>
      <small className="icon-container">
        <Icon icon={IconNames.PAPERCLIP} iconSize={12} />
        <span className="bp3-button-text">
          {getDaysfromNow(notification.createdAt)}
        </span>
      </small>
    </div>
  );
};
