import { Button, Checkbox, Icon } from "@blueprintjs/core";
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import qs from "qs";
import { CRUD_CREATE, useCreate, useRefresh } from "ra-core";
import React, { ChangeEvent, useCallback, useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { getCurrentDateOfWeek, TimesheetTableHeaderRow } from "..";
import { Constants } from "../../../../constants";
import { Api } from "../../../../core/api/actions";
import { IClientCompany, IEtsState, ISubtask, ITask } from "../../../../model";
import { DescriptionInput } from "./TimesheetCreateDescription";
import { initialState, timesheetCreateReducer } from "./timesheetCreateReducer";
import { TimesheetCreateSelectInput } from "./TimesheetCreateSelect";
import { TasksOrSubtaskSelect } from "./TimesheetCreateSelectElement";
import { TimesheetTimeEntryTimeInput } from "./TimesheetTimeEntryTimeInput";

interface ITimesheetTimeEntryCreateProps {
  date: string;
}
const useStyle = makeStyles({
  options: {
    paddingTop: "5px",
    display: "flex",
    alignItems: "center",
    minHeight: "35px",
  },
  container: {
    margin: "20px 30px",
    border: "1px solid rgba(224, 224, 224, 1)",
    borderRadius: "5px",
  },
  center: {
    minHeight: "140px",
    paddingLeft: "10px",
    paddingTop: "10px",
  },
  top: {
    padding: "10px",
  },
  leftTitle: {
    width: "110px",
  },
});

const getOptions = async (query, token) => {
  const request = await fetch(`/api/${Constants.TASK_RESOURCE}${query}`, {
    method: "GET",
    headers: Api.apiHeaders(token),
  });
  return request.json();
};

const filterClientCompany = (query, cC, _index, exactMatch) => {
  const normalizedTitle = cC.name.toLowerCase();
  const normalizedQuery = query.toLowerCase();
  if (exactMatch) {
    return normalizedTitle === normalizedQuery;
  } else {
    return (
      ` ${normalizedTitle} ${cC.chineseName}`.indexOf(normalizedQuery) >= 0
    );
  }
};

export const TimesheetTimeEntryCreate: React.FC<ITimesheetTimeEntryCreateProps> = ({
  date,
}) => {
  const user = useSelector((state: IEtsState) => state.auth);
  const clientCompanies = useSelector(
    (state: IEtsState) => state.clientCompanies
  );
  const [create] = useCreate(Constants.TIME_ENTRY_RESOURCE);
  const classes = useStyle({});

  const [
    {
      currentDate,
      disabled,
      clientCompany,
      task,
      tasksOptions,
      subtask,
      subtasksOptions,
      durations,
      isChargeable,
      description,
    },
    dispatch,
  ] = useReducer(timesheetCreateReducer, initialState(date));

  const refresh = useRefresh();

  const dateOfWeek = getCurrentDateOfWeek(date);

  useEffect(() => {
    if (currentDate !== date) {
      handleResetDuration();
    }
  }, [date]);

  const handleTask = (t: ITask) => {
    dispatch({ type: "CHANGE_TASK", payload: t });
    handleDisabled(false);
    getSubtasks(t);
  };

  const handleSubtask = useCallback(
    (st: ISubtask) => {
      return dispatch({ type: "CHANGE_SUBTASK", payload: st });
    },
    [dispatch]
  );

  const handleTaskOption = useCallback(
    (tasks: ITask[]) => {
      return dispatch({ type: "CHANGE_TASK_OPTIONS", payload: tasks });
    },
    [dispatch]
  );

  const handleSubtaskOption = useCallback(
    (subtasks: ISubtask[]) => {
      return dispatch({ type: "CHANGE_SUBTASK_OPTIONS", payload: subtasks });
    },
    [dispatch]
  );

  const handleResetAll = useCallback(() => {
    return dispatch({ type: "RESET_ALL" });
  }, [dispatch]);

  const handleDisabled = (isDisabled: boolean) => {
    return dispatch({ type: "CHANGE_DISABLED", payload: isDisabled });
  };
  const handleResetDuration = () => {
    return dispatch({ type: "RESET_DURATION" });
  };

  const handleClientCompany = (cC: IClientCompany) => {
    dispatch({ type: "CHANGE_CLIENT_COMPANY", payload: cC });
    getTasks(cC);
  };

  const handleDescription = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "CHANGE_DESCRIPTION",
      payload: event.currentTarget.value,
    });
  };

  const handleDuration = (d: Date, duration: string) => {
    dispatch({ type: "CHANGE_DURATION", payload: { date: d, duration } });
  };

  const handleOnClickChargeable = useCallback(() => {
    dispatch({ type: "CHANGE_ISCHARGEABLE", payload: !isChargeable });
  }, [isChargeable, dispatch]);

  const getTasks = useCallback(
    async (cc: IClientCompany) => {
      const query = qs.stringify({
        filter: { clientCompanyId: cc.id, departmentId: user.departmentId },
      });
      const taskResult = await getOptions(`?${query}`, user.token);
      handleTaskOption(taskResult);
    },
    [handleTaskOption, user?.departmentId, user?.token]
  );

  const getSubtasks = useCallback(
    async (t: ITask) => {
      const taskResult = await getOptions(`/${t.id}`, user.token);
      handleSubtaskOption(taskResult.subtasks);
    },
    [handleSubtaskOption, user?.token]
  );

  const handleOnClickSave = () => {
    if (Object.keys(durations).length === 0) {
      return;
    }
    for (const [key, duration] of Object.entries(durations)) {
      if (moment.duration(duration).asMilliseconds() !== 0) {
        const timeEntry = {
          taskId: task.id,
          subtaskId: subtask?.id,
          startDatetime: key,
          endDatetime: moment(key)
            .add(moment.duration(duration).asMilliseconds())
            .toISOString(),
          description,
          isChargeable,
        };
        create(
          {
            resource: Constants.TIME_ENTRY_RESOURCE,
            payload: { data: timeEntry },
          },
          {
            action: CRUD_CREATE,
            onSuccess: () => {
              refresh();
            },
          }
        );
      }
    }
    handleResetAll();
  };

  return (
    <Grid className={classes.container}>
      <Grid item={true} className={classes.top}>
        <Icon icon="plus" /> &nbsp; Add Time Entry
      </Grid>

      <Grid className={classes.center}>
        <Grid item={true} className={classes.options}>
          <span className={classes.leftTitle}>Company:</span>
          <TimesheetCreateSelectInput
            options={clientCompanies}
            handleSelectOption={handleClientCompany}
            filter={true}
            itemPredicate={filterClientCompany}
            text={clientCompany?.name ?? "Select Company"}
          />
        </Grid>
        <Grid item={true} className={classes.options}>
          <TasksOrSubtaskSelect
            options={tasksOptions}
            selectedItem={task}
            handleSelect={handleTask}
            entryType="Job"
          />
        </Grid>
        <Grid item={true} className={classes.options}>
          <TasksOrSubtaskSelect
            options={subtasksOptions}
            selectedItem={subtask}
            handleSelect={handleSubtask}
            entryType="Subtask"
          />
        </Grid>
        <Grid item={true} className={classes.options}>
          <Checkbox
            checked={isChargeable}
            label="Chargeable"
            onClick={handleOnClickChargeable}
            inline={true}
            alignIndicator="right"
            disabled={!task || !!subtask}
          />
        </Grid>
        <Grid item={true} className={classes.options}>
          <DescriptionInput
            description={description}
            onChange={handleDescription}
          />
        </Grid>
      </Grid>
      <Grid>
        <Table>
          <TableHead className="timesheet-create-table-head">
            <TimesheetTableHeaderRow
              date={date}
              key={`timesheet-time-entry-bottom-create-header`}
            />
          </TableHead>
          <TableBody>
            <TableRow className="timesheet-create-table-total-row">
              <TableCell />
              {dateOfWeek.map((d, i) => (
                <TimesheetTimeEntryTimeInput
                  date={d}
                  duration={durations?.[d.toISOString()]}
                  disabled={disabled}
                  onChange={handleDuration}
                  key={`bottom-create-${d}`}
                />
              ))}
              <TableCell>
                <Button onClick={handleOnClickSave} disabled={disabled}>
                  <Icon icon={"floppy-disk"} />
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
};
