import { Icon } from "@blueprintjs/core";
import * as React from "react";
import { IEmailAttachment } from "../../model";
import { iconName } from "../../utils/attachment";

export const EmailAttachments = ({
  contentType,
  fileName,
  filePath,
  isShared,
  startSharingAttachment,
  parentEntryType,
}: IEmailAttachment) => {
  const icon = iconName(contentType);
  return (
    <div className="timeline-email-attachments" key={fileName}>
      <Icon
        icon={iconName(contentType)}
        iconSize={16}
        style={{ opacity: 0.5 }}
      />
      <a className="email-attachment-element" href={filePath} download={true}>
        {fileName}
      </a>
      {parentEntryType !== "ClientGroup" && (
        <Icon
          className="attachment-button"
          icon={isShared ? "tick-circle" : "share"}
          iconSize={12}
          onClick={startSharingAttachment}
        />
      )}
    </div>
  );
};
