import { required, useReference } from "ra-core";
import React from "react";
import { TextInput } from "react-admin";
import { Constants } from "../../../constants";
import { ILineItemTemplate } from "../../../model";

export const LineItemDescriptionInput = ({ templateId, ...props }) => {
  const { className, ...rest } = props;

  const { referenceRecord } = useReference({
    reference: Constants.LINE_ITEM_TEMPLATE_RESOURCE,
    id: templateId,
  });

  const template = referenceRecord as ILineItemTemplate;

  return (
    <TextInput
      {...rest}
      label="Description"
      validate={[required()]}
      multiline={true}
      initialValue={template?.description}
    />
  );
};
