import ClassNames from "classnames";
import React from "react";
import { DropTarget } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import { useIntl } from "react-intl";

interface ICustomHeaderProps {
  select: (key: string) => any;
  fileKey: string;
  connectDropTarget: (component: JSX.Element) => any;
  isOver: boolean;
  isSelected: () => any;
  browserProps: {
    createFiles: () => any;
    moveFolder: () => any;
    moveFile: () => any;
  };
}

const CustomHeader: React.FC<ICustomHeaderProps> = ({
  select,
  fileKey,
  connectDropTarget,
  isOver,
  isSelected,
  browserProps,
}) => {
  const intl = useIntl();

  const handleHeaderClick = () => {
    select(fileKey);
  };
  const header = (
    <tr
      className={ClassNames("folder", {
        dragover: isOver,
        selected: isSelected,
      })}
    >
      <th>
        {intl.formatMessage({
          defaultMessage: "File",
          description: "DataSafe header file",
        })}
      </th>
      <th className="size">
        {intl.formatMessage({
          defaultMessage: "Size",
          description: "DataSafe header size",
        })}
      </th>
      <th className="modified">
        {intl.formatMessage({
          defaultMessage: "Last Modified",
          description: "DataSafe header last modified",
        })}
      </th>
    </tr>
  );

  const render = () => {
    if (
      typeof browserProps.createFiles === "function" ||
      typeof browserProps.moveFile === "function" ||
      typeof browserProps.moveFolder === "function"
    ) {
      return connectDropTarget(header);
    }
    return header;
  };
  return render();
};

const targetSource = {
  drop(props, monitor) {
    if (monitor.didDrop()) {
      return;
    }
    const key = props.newKey || props.fileKey;
    const slashIndex = key.lastIndexOf("/");
    const path = slashIndex !== -1 ? key.substr(0, slashIndex + 1) : "";
    const item = monitor.getItem();
    if (item.files && props.browserProps.createFiles) {
      props.browserProps.createFiles(item.files, path);
    }
    return {
      path: path,
    };
  },
};

function targetCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
  };
}

export default DropTarget(
  ["file", "folder", NativeTypes.FILE],
  targetSource,
  targetCollect
)(CustomHeader);
