import moment from "moment";
import * as React from "react";
import { ITask } from "../../../model";
import { EditableDateCell } from "../EditableDateCell";
import { IEditableCellProps } from "../types";

interface IDateAwareEditableDateCellState {
  editing: boolean;
}

// Specialized component to define a new cell that is aware of the dates,
// used for due date cell in TaskTable
export class DateAwareEditableDateCell extends React.Component<
  IEditableCellProps<ITask, string>,
  IDateAwareEditableDateCellState
> {
  constructor(props: IEditableCellProps<ITask, string>) {
    super(props);

    this.formatDate = this.formatDate.bind(this);
  }

  public shouldComponentUpdate(nextProps, _nextState) {
    return (
      this.props.cellInfo.value !== nextProps.cellInfo.value ||
      this.props.loading !== nextProps.loading
    );
  }

  public render(): JSX.Element {
    return (
      <EditableDateCell
        {...this.props}
        inputProps={{ className: this.formatDate() }}
      />
    );
  }

  private formatDate(): string {
    const cellDate = moment(this.props.cellInfo.value);
    if (cellDate.isBefore(moment())) {
      return "date-past";
    } else if (cellDate.isBefore(moment().add(7, "days"))) {
      return "date-week";
    }
    return "";
  }
}
