import * as React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { Column } from "../../components";
import { IKanbanProps, IKanbanState } from "../../model";
import { Dispatch } from "../../types";
import * as Atils from "../../utils/array";

export class KanbanImpl extends React.Component<IKanbanProps, IKanbanState> {
  constructor(props) {
    super(props);
    this.state = {
      columnList: this.props.columnList,
    };
  }

  public render(): JSX.Element {
    const columns = this.state.columnList.map((column) => (
      <Column
        key={column.id}
        id={column.id}
        name={column.name}
        taskCardList={column.taskCardList}
        colorHex={column.colorHex}
      />
    ));

    return (
      <DragDropContext onDragEnd={this.onDragEnd} className="kanban-container">
        <div className="kanban-body">{columns}</div>
      </DragDropContext>
    );
  }

  private onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    const { columnList } = this.state;
    const { onUpdateTask } = this.props;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const startId = source.droppableId;
    const endId = destination.droppableId;
    const startColumn = columnList.find((column) => column.id === startId);
    const endColumn = columnList.find((column) => column.id === endId);

    if (startId === endId) {
      const taskCardList = [...startColumn.taskCardList];
      const draggableObject = taskCardList.find(
        (taskCard) => taskCard.id === draggableId
      );
      taskCardList.splice(source.index, 1);
      taskCardList.splice(destination.index, 0, draggableObject);
      columnList[
        Atils.findPositionByKey(columnList, endId, "id")
      ].taskCardList = taskCardList;
      this.setState({
        columnList,
      });
    } else {
      const { endTaskCard, updatedTask, updatedColumnList } = moveCardToColumn(
        result,
        columnList,
        startColumn,
        endColumn
      );
      const model = this.props.model;
      this.props.onUpdateTask(endTaskCard, updatedTask, model);
      this.setState({
        columnList: updatedColumnList,
      });
    }
    return;
  };
}

function moveCardToColumn(result, columnList, startColumn, endColumn) {
  const { destination, source, draggableId } = result;
  const startId = source.droppableId;
  const endId = destination.droppableId;

  const startColumnIndex = columnList.findIndex(
    (column) => column.id === source.droppableId
  );
  const endColumnIndex = columnList.findIndex(
    (column) => column.id === destination.droppableId
  );

  const startColumnClone = { ...startColumn };
  const endColumnClone = { ...endColumn };
  const updatedColumnList = [...columnList];

  // Remove the task card from the column the card was moved from
  const endTaskCard = startColumn.taskCardList[source.index];
  // Add the task card to the index in the column it was added to
  const endColumnTaskCardList = [
    ...endColumn.taskCardList.slice(0, destination.index),
    endTaskCard,
    ...endColumn.taskCardList.slice(destination.index),
  ];
  const startColumnTaskCardList = [
    ...startColumn.taskCardList.slice(0, source.index),
    ...startColumn.taskCardList.slice(source.index + 1),
  ];
  endColumnClone.taskCardList = endColumnTaskCardList;
  startColumnClone.taskCardList = startColumnTaskCardList;

  const updatedTask = {
    id: endTaskCard.id,
    statusId: endColumn.id,
  };

  updatedColumnList[startColumnIndex] = startColumnClone;
  updatedColumnList[endColumnIndex] = endColumnClone;
  return { endTaskCard, updatedTask, updatedColumnList };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onUpdateTask: (originalTask, updatedTask, model) => {
      return dispatch(model.update(originalTask, updatedTask));
    },
  };
}

export const Kanban = connect(null, mapDispatchToProps)(KanbanImpl);
