import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import {
  ArrayInput,
  Create,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { Constants } from "../../constants";
import { ClientCompanyField, TaskField } from "../fields";
import { TextAreaInput } from "../inputs";
import { BalanceInput } from "./BalanceInput";
import { ClientCompanyAddressField } from "./ClientCompanyAddressField";
import { ContactInput } from "./ContactInput";
import { ExpenseTable } from "./ExpenseTable";
import { InvoiceBudgetedField } from "./InvoiceBudgetedField";
import { InvoiceCreateToolbar } from "./InvoiceCreateToolbar";
import { InvoiceDateField } from "./InvoiceDateField";
import { InvoiceProForma } from "./InvoiceProForma";
import { LineItemIterator } from "./lineItem";

const useStyles = makeStyles(() => ({
  container: {
    margin: 30,
  },
}));

export const InvoiceCreate: React.FC<any> = (props) => {
  const classes = useStyles(props);

  return (
    <Create {...props} location={{}}>
      <SimpleForm
        className={classes.container}
        toolbar={<InvoiceCreateToolbar />}
      >
        <ReferenceField
          reference={Constants.TASK_RESOURCE}
          source="taskId"
          addLabel={false}
          link={false}
        >
          <TaskField />
        </ReferenceField>
        <ReferenceField
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          addLabel={false}
          link={false}
        >
          <ClientCompanyField />
        </ReferenceField>
        <ReferenceField
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          addLabel={false}
          link={false}
        >
          <ClientCompanyAddressField />
        </ReferenceField>
        <ContactInput />
        <ReferenceInput
          label="Template to use"
          source="templateId"
          reference={Constants.INVOICE_TEMPLATE_RESOURCE}
          validate={[required()]}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <InvoiceProForma isCreate={true} />
        <TextInput source="reference" validate={[required()]} />
        <InvoiceDateField source="date" />
        <InvoiceBudgetedField />
        <ExpenseTable />
        <ArrayInput source="lineItems">
          <LineItemIterator />
        </ArrayInput>
        <BalanceInput type="wip" label="WIP" />
        <BalanceInput type="expense" label="Expense" />
        <TextAreaInput source="internalRemarks" />
        <TextAreaInput source="customField" />
      </SimpleForm>
    </Create>
  );
};
