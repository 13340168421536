import { Button, FileInput, MenuItem } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import _ from "lodash";
import { useDataProvider, useGetList, useNotify, useRefresh } from "ra-core";
import React, { useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Constants } from "../../constants";
import { IAttachment, IEtsState, IInterviewTemplate, ITask } from "../../model";

const interviewRenderer = (
  interview: IInterviewTemplate,
  { handleClick, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }
  return (
    <MenuItem
      active={modifiers.active}
      key={interview.name}
      onClick={handleClick}
      text={interview.name}
    />
  );
};

const interviewPredicate = (query: string, interview: IInterviewTemplate) => {
  return interview?.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
};

const InterviewSelect = Select.ofType<IInterviewTemplate>();

interface IInterviewLauncherProps {
  task: ITask;
}

const ExcelUploader = ({ task, interview, title }) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const token = useSelector((state: IEtsState) => state.auth.token);

  const onSubmit = (attachment: string): Promise<Partial<IAttachment>> => {
    const attrs = {
      excel: attachment,
      entryType: "Task",
      entryId: task.id,
      filename: interview.docassembleName,
      title,
    };
    return dataProvider.create(Constants.INTERVIEWS_RESOURCE, {
      data: attrs,
    });
  };

  const handleAttachment = async (attachments: string[]) => {
    await Promise.all(attachments.map(onSubmit));
    refresh();
  };

  const render = (renderProps: any) => {
    const handleFileChange = (event: React.FormEvent<HTMLInputElement>) => {
      const nonXlsx =
        Array.from(event.currentTarget.files).filter(
          (f) => !f.name.match(/\.xlsx/)
        ).length > 0;
      if (nonXlsx) {
        return notify("Only excel file is allowed", "error");
      }
      renderProps.handleUpload(event.currentTarget.files);
    };

    return (
      <FileInput
        className="item"
        onInputChange={handleFileChange}
        text="Choose Excel file"
        disabled={!interview}
        buttonText="Upload"
      />
    );
  };

  return (
    <DirectUploadProvider
      headers={{ authorization: `Bearer ${token}` }}
      multiple={false}
      onSuccess={handleAttachment}
      render={render}
    ></DirectUploadProvider>
  );
};

export const InterviewLauncher: React.FC<IInterviewLauncherProps> = ({
  task,
}) => {
  const { data } = useGetList<IInterviewTemplate>(
    Constants.INTERVIEW_TEMPLATE_RESOURCE,
    { page: 1, perPage: 10000 },
    { field: "", order: "" },
    {}
  );

  const interviews = _.values(data);

  const user = useSelector((state: IEtsState) => state.auth.user);
  const [interview, setInterview] = useState<IInterviewTemplate>(null);
  const title = interview?.name || "Select a template...";

  return (
    <form
      action="/interviews"
      target="_blank"
      method="POST"
      className="interview-launcher"
    >
      <InterviewSelect
        className="item"
        items={interviews}
        itemRenderer={interviewRenderer}
        itemPredicate={interviewPredicate}
        onItemSelect={setInterview}
      >
        <Button text={title} rightIcon="caret-down" />
      </InterviewSelect>

      <input type="hidden" name="user_id" value={user.id} />
      <input type="hidden" name="entry_type" value="Task" />
      <input type="hidden" name="entry_id" value={task.id} />
      <input type="hidden" name="interview_template_id" value={interview?.id} />
      <input
        type="hidden"
        name="filename"
        value={interview?.docassembleName || ""}
      />
      <input type="hidden" name="title" value={title} />
      <Button
        className="item"
        text="Start New"
        type="submit"
        disabled={interview === null}
      />
      <Link
        to={`/api/interviews/excel?filename=${interview?.docassembleName}`}
        download={true}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <Button
          className="item"
          text="Download Excel template"
          disabled={interview === null}
        />
      </Link>
      <ExcelUploader task={task} interview={interview} title={title} />
    </form>
  );
};
