import { InputGroup } from "@blueprintjs/core";
import {
  EditableBooleanCell,
  EditableDateCell,
  EditableTextCell,
} from "../components/cells";
import { CustomCheckbox } from "../components/CustomCheckbox/CustomCheckbox";
import { DateInput } from "../components/DateInput";
import { Constants } from "../constants";
import * as DateUtils from "../utils/date";

export const DATE_INPUT_DEFAULT_PROPS = {
  formatDate: DateUtils.formatDate,
  parseDate: DateUtils.parseDateStrict,
  placeholder: Constants.DATE_FORMAT,
};

export const BOOLEAN_INPUT_DEFAULT_PROPS = {
  checked: false,
  componentType: "Checkbox",
};

export const DATE_INPUT_EDIT_DEFAULT_PROPS = {
  ...DATE_INPUT_DEFAULT_PROPS,
  formatDate: DateUtils.formatFriendlyDate,
};

export const DATE_INPUT_EDIT_CELL_DEFAULT_PROPS = {
  CreateComponent: DateInput,
  createComponentProps: DATE_INPUT_DEFAULT_PROPS,
  EditComponent: DateInput,
  editComponentProps: DATE_INPUT_EDIT_DEFAULT_PROPS,
  TableEditComponent: EditableDateCell,
  width: 130,
};

export const TEXT_INPUT_EDIT_CELL_DEFAULT_PROPS = {
  CreateComponent: InputGroup,
  EditComponent: InputGroup,
  editComponentOptions: {
    submitOnBlur: true,
  },
  TableEditComponent: EditableTextCell,
};

export const BOOLEAN_EDIT_CELL_DEFAULT_PROPS = {
  CreateComponent: CustomCheckbox,
  EditComponent: CustomCheckbox,
  createComponentProps: BOOLEAN_INPUT_DEFAULT_PROPS,
  editComponentOptions: {
    submitOnBlur: true,
  },
  TableEditComponent: EditableBooleanCell,
};
