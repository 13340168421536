export class ReportRole {
  public static readonly DEPARTMENT = "_department";
  public static readonly TENANT = "_tenant";

  public static readonly TASK_ACCOUNT_BALANCE_DEPARTMENT = `report_tasks_account_balance${ReportRole.DEPARTMENT}`;
  public static readonly PIC_ACCOUNT_BALANCE_DEPARTMENT = `report_pic_account_balance${ReportRole.DEPARTMENT}`;
  public static readonly HOURS_BY_JOB_DEPARTMENT = `report_hours_by_job${ReportRole.DEPARTMENT}`;
  public static readonly HOURS_BY_USER_DEPARTMENT = `report_hours_by_user${ReportRole.DEPARTMENT}`;
  public static readonly FEE_NOTES_LINE_ITEMS_DEPARTMENT = `report_fee_notes_line_items${ReportRole.DEPARTMENT}`;
  public static readonly FEE_NOTES_LIST_DEPARTMENT = `report_fee_notes_list${ReportRole.DEPARTMENT}`;
  public static readonly DEPARTMENT_ACCOUNT_BALANCE_DEPARTMENT = `report_departments_account_balance${ReportRole.DEPARTMENT}`;
  public static readonly CREDIT_NOTES_LISTS_DEPARTMENT = `report_credit_notes_lists${ReportRole.DEPARTMENT}`;
  public static readonly AMOUNT_TO_BILL_DEPARTMENT = `report_amount_to_bill${ReportRole.DEPARTMENT}`;

  public static readonly TASK_ACCOUNT_BALANCE_TENANT = `report_tasks_account_balance${ReportRole.TENANT}`;
  public static readonly PIC_ACCOUNT_BALANCE_TENANT = `report_pic_account_balance${ReportRole.TENANT}`;
  public static readonly HOURS_BY_JOB_TENANT = `report_hours_by_job${ReportRole.TENANT}`;
  public static readonly HOURS_BY_USER_TENANT = `report_hours_by_user${ReportRole.TENANT}`;
  public static readonly FEE_NOTES_LINE_ITEMS_TENANT = `report_fee_notes_line_items${ReportRole.TENANT}`;
  public static readonly FEE_NOTES_LIST_TENANT = `report_fee_notes_list${ReportRole.TENANT}`;
  public static readonly DEPARTMENT_ACCOUNT_BALANCE_TENANT = `report_departments_account_balance${ReportRole.TENANT}`;
  public static readonly CREDIT_NOTES_LISTS_TENANT = `report_credit_notes_lists${ReportRole.TENANT}`;
  public static readonly AMOUNT_TO_BILL_TENANT = `report_amount_to_bill${ReportRole.TENANT}`;
}
