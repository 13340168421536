import { Datagrid, List, TextField } from "ra-ui-materialui";
import React from "react";
import { useSelector } from "react-redux";
import { ReportRole } from "../../constants/reportRoles";
import { useReportParams } from "../../hooks/useReportParams";
import { IEtsState } from "../../model";
import { UserField } from "../fields";
import { HourReportFilter } from "../HourByJob/Fields/HourReportFilter";
import { hourByUserExporter } from "./exporter";
import { HourDetailShow } from "./HourByUserShow";

export const HourByUserList: React.FC<any> = (props) => {
  const isDaily = useSelector(
    (state: IEtsState) => state.departmentSetting.timebillDaily
  );

  const filterParams = useReportParams(ReportRole.HOURS_BY_USER_DEPARTMENT, {});

  return (
    <List
      {...props}
      filters={
        <HourReportFilter reportType={ReportRole.HOURS_BY_USER_TENANT} />
      }
      bulkActionButtons={false}
      filterDefaultValues={filterParams}
      exporter={hourByUserExporter(isDaily)}
    >
      <Datagrid rowClick="expand" expand={<HourDetailShow type="user" />}>
        <UserField source="id" label="Staff" sort={false} />
        <TextField source="userName" label="" />
      </Datagrid>
    </List>
  );
};
