import {
  DateInput as BPDateInput,
  IDateInputProps,
} from "@blueprintjs/datetime";
import * as React from "react";
import { Constants } from "../constants";
import * as Utils from "../utils/date";

export class DateInput extends React.Component<Partial<IDateInputProps>> {
  public render(): JSX.Element {
    return (
      <BPDateInput
        formatDate={Utils.formatDate}
        parseDate={Utils.parseDate}
        placeholder={Constants.DATE_FORMAT}
        {...this.props}
      />
    );
  }
}
