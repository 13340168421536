import { FormLabel, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { ReferenceField, TextField } from "react-admin";
import { Constants } from "../../../constants";
import { IExpense } from "../../../model";
import { ClientCompanyInput } from "../../inputs";

interface IExpenseCreateByTask {
  record?: IExpense;
  basePath?: string;
}

const useStyles = makeStyles(() => ({
  label: {
    marginTop: 16,
  },
}));

export const ExpenseCreateByTask: React.FC<IExpenseCreateByTask> = (props) => {
  const classes = useStyles({});
  return (
    <Grid container={true}>
      <Grid item={true} xs={12}>
        <ClientCompanyInput {...props} />
      </Grid>
      <Grid item={true} xs={12}>
        <FormLabel component="legend" className={classes.label}>
          Task
        </FormLabel>
        <ReferenceField
          {...props}
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          label="Task"
          link={false}
        >
          <TextField source="description" />
        </ReferenceField>
      </Grid>
    </Grid>
  );
};
