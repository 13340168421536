import * as React from "react";
import { connect } from "react-redux";
import { Api } from "../../actions/api";
import { ConnectedTaskTable, TableKanbanSwitcher } from "../../components";
import { Follow } from "../../components/Follow/Follow";
import {
  IDepartmentDispatchProps,
  IDepartmentProps,
  IDepartmentStateProps,
  IEtsState,
} from "../../model";
import { taskKanbanDepartment } from "../../selectors";
import { Dispatch } from "../../types";
import * as Utils from "../../utils/sort";
import * as TaskUtil from "../../utils/task";
import { Kanban } from "../Kanban/Kanban";

export class DepartmentImpl extends React.Component<IDepartmentProps> {
  public render(): JSX.Element {
    const {
      columnList,
      model,
      departmentId,
      followingOwnDepartment,
    } = this.props;

    const table = (
      <div>
        {departmentId && (
          <Follow
            followableId={departmentId}
            type="Department"
            userIsFollowing={followingOwnDepartment}
            onClick={this.handleUpdateFollowingStatus}
          />
        )}
        <ConnectedTaskTable
          completedTasksFilter={TaskUtil.filterTasksThisMonthDueDate}
          tasks={this.props.tasks}
        />
      </div>
    );

    const board = <Kanban columnList={columnList} model={model} />;

    return <TableKanbanSwitcher table={table} board={board} />;
  }

  private handleUpdateFollowingStatus = (followingOwnDepartment) => {
    this.setState({
      ...this.state,
      followingOwnDepartment,
    });
    return this.props.onGetUser(this.props.userId);
  };
}

function mapStateToProps() {
  const taskKanbanDepartmentList = taskKanbanDepartment();

  return (state: IEtsState): IDepartmentStateProps => {
    const user = state.usersById[state.auth.user.id];
    const tasks = state.tasks.filter(
      (task) => task.departmentId === user.department.id
    );
    tasks.sort(Utils.sortByCreatedAtDesc);
    return {
      departmentId: user.department.id,
      followingOwnDepartment: user.followingOwnDepartment,
      tasks,
      columnList: taskKanbanDepartmentList(state),
      model: Api.Task,
      userId: user.id,
    };
  };
}

function mapDispatchToProps(dispatch: Dispatch): IDepartmentDispatchProps {
  return {
    onGetUser: (id: string) => {
      return dispatch(Api.User.get(id));
    },
  };
}

export const Department = connect(
  mapStateToProps,
  mapDispatchToProps
)(DepartmentImpl);
