window.env = window.env || {};

export function returnSentryEnvironment(): string {
  return window.env.HEROKU_CURRENT_ENV;
}

export function returnEmailEnvPrefix(): string {
  switch (window.env.HEROKU_CURRENT_ENV) {
    case "ets-develop":
      return "develop.";
    case "ets-staging":
      return "staging.";
    case "ets-production":
      return "";
    default:
      return "localhost.";
  }
}
