import { CRUD_UPDATE, useMutation, useNotify } from "ra-core";
import { MutationOptions } from "ra-core/esm/dataProvider/useMutation";

export enum ApprovalAction {
  Submit = "submit",
  EicAccept = "eicAccept",
  EicReject = "eicReject",
  PicAccept = "picAccept",
  PicReject = "picReject",
}

export const useApproval = (
  resource: string,
  action: ApprovalAction,
  id: string
) => {
  const notify = useNotify();
  const options: MutationOptions = {
    action: CRUD_UPDATE,
    onFailure: (error) => {
      notify(error.message, "warning");
    },
  };
  const [handleOnClickSubmit] = useMutation(
    {
      resource,
      type: action,
      payload: { id },
    },
    options
  );
  return handleOnClickSubmit;
};
