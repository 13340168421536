import { ProgressBar } from "@blueprintjs/core";
import { useAuthState } from "ra-core";
import React from "react";
import { Redirect, Route } from "react-router";
import { Constants } from "../../constants";

interface IAuthClientUserProps {
  component: any;
  path: string;
  exact?: boolean;
}

export const AuthClientUserComponent: React.FC<IAuthClientUserProps> = ({
  component,
  path,
  exact,
}) => {
  const { loading, authenticated } = useAuthState();

  if (loading) {
    return (
      <div>
        <ProgressBar />
        <div className="loading-container">
          <div className="loading-icon-container">
            <img
              className="loading-icon-img"
              height="160"
              width="180"
              src={
                "https://easytrack.sgp1.cdn.digitaloceanspaces.com/loading-image.gif"
              }
            />
            <span className="loading-text">
              {" "}
              Preparing the EasyTrack experience...
            </span>
          </div>
        </div>
      </div>
    );
  }
  if (authenticated) {
    return <Route path={path} component={component} exact={exact} />;
  }
  return <Redirect to={Constants.CLIENT_APP_LOGIN_GENERATOR_PATH} />;
};
