import { isNullOrUndefined } from "util";
import { ActionTypes, IAuthActionType, ISetRedirect } from "../actions/index";
import {
  DEFAULT_AUTH_STATE,
  IAuthInnerState,
  LoginState,
} from "../model/AuthenticationState";

export function auth(
  state: IAuthInnerState | undefined = DEFAULT_AUTH_STATE,
  action: IAuthActionType
) {
  switch (action.type) {
    case ActionTypes.START_LOGIN:
      return { ...state, loginState: LoginState.LoggingIn };
    case ActionTypes.COMPLETE_LOGIN:
      const getAuthToken = action.authToken;
      return {
        ...state,
        loginState: !isNullOrUndefined(getAuthToken.accessToken)
          ? LoginState.LoggedIn
          : LoginState.NotLoggedIn,
        token: getAuthToken,
      };
    case ActionTypes.SUCCESSFUL_GET_USER_DETAILS:
      const { user, roles, departmentId } = action;
      return {
        ...state,
        user,
        roles,
        departmentId,
      };
    case ActionTypes.START_LOGOUT:
      return { ...state, loginState: LoginState.LoggingOut };
    case ActionTypes.FAIL_LOGIN:
    case ActionTypes.COMPLETE_LOGOUT:
      return {
        ...state,
        token: undefined,
        user: undefined,
        roles: [],
        loginState: LoginState.NotLoggedIn,
      };
    case ActionTypes.SET_REDIRECT:
      const redirectPath = (action as ISetRedirect).redirectPath;
      return { ...state, redirectPath };
    case ActionTypes.CLEAR_REDIRECT:
      return { ...state, redirectPath: undefined };
    default:
      return state;
  }
}
