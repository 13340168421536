export const priceColor = (amount: number) => {
  if (amount === 0) {
    return;
  } else if (amount > 0) {
    return { color: "#ff0000" };
  } else {
    return { color: "#3cb371" };
  }
};

export const companyColor = () => {
  return "#008080	";
};
