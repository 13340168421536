import { LinearProgress } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import {
  Datagrid,
  DateField,
  ReferenceField,
  ReferenceManyField,
  TextField,
} from "react-admin";
import { Constants } from "../../constants";
import { IFormGroupResponse } from "../../model";
import { TypeformShow } from "../TypeformShow/TypeformShow";

interface IFormResponseExpandProps {
  record?: IFormGroupResponse;
  basePath?: string;
}

export const FormResponseExpand: React.FC<IFormResponseExpandProps> = ({
  record,
  basePath,
}) => {
  return (
    <ReferenceManyField
      reference={Constants.FORMS_RESPONSE_RESOURCE}
      target="form_group_response"
      record={record}
      basePath={basePath}
      sort={{ field: "created_at", order: "asc" }}
    >
      <List />
    </ReferenceManyField>
  );
};

const List = (props) => {
  if (props.loading) {
    return <LinearProgress />;
  }
  return (
    <>
      {_.isEmpty(props?.data) ? (
        <span>No Result</span>
      ) : (
        <Datagrid rowClick="expand" expand={<TypeformShow />}>
          <DateField source="date" label="Completion Date" />
          <ReferenceField
            label="Client User"
            source="clientUserId"
            reference={Constants.CLIENT_USER_RESOURCE}
            link={false}
          >
            <TextField label="Client User" source="name" />
          </ReferenceField>
          <ReferenceField
            label="Form Name"
            source="formGroupId"
            reference={Constants.FORM_GROUPS_RESOURCE}
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Form Part"
            source="formId"
            reference={Constants.FORMS_RESOURCE}
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
      )}
    </>
  );
};
