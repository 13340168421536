import * as _ from "lodash";
import * as React from "react";
import {
  INotification,
  INotificationProps,
  NotificationMode,
} from "../../model";
import * as ColorUtils from "../../utils/color";
import * as UserUtils from "../../utils/user";

export const PolymorphicFullNotification: React.FC<INotificationProps> = ({
  mode = NotificationMode.Full,
  notification,
}) => {
  const username =
    notification.parameters?.username ?? UserUtils.getAbbreviation();
  const companyColor =
    notification.parameters?.entryDetails?.companyColor ??
    ColorUtils.companyColor();
  const notificationTypeName =
    notification.parameters?.entryDetails?.typeName ??
    "Unknown Notification Type";
  const notificationLine = notification.parameters?.notificationLine ?? "";
  const notifiableContent = notification.parameters?.notifiableContent ?? "";

  return (
    <>
      <b className="username">{username}</b>
      <>
        &nbsp;{notificationLine}
        <div className="notify-bar" style={{ backgroundColor: companyColor }}>
          {notificationTypeName}
        </div>
        <RenderClientGroupOrClientCompany
          notification={notification}
          mode={mode}
        />
        &nbsp;{notifiableContent ? `"${notifiableContent}"` : ""}
      </>
    </>
  );
};

export const PolymorphicShortNotification: React.FC<INotificationProps> = ({
  mode = NotificationMode.Full,
  notification,
}) => {
  const username =
    notification.parameters?.username?.substring(0, 20) ??
    UserUtils.getAbbreviation();
  const notificationLine = notification.parameters?.notificationLine ?? "";
  const notifiableContent = _.truncate(
    notification.parameters?.notifiableContent ?? "",
    { length: 30, separator: " " }
  );

  return (
    <>
      <b className="username">{username}</b>
      <>
        &nbsp;{notificationLine}&nbsp;
        <RenderClientGroupOrClientCompany
          notification={notification}
          mode={mode}
        />
        &nbsp;{notifiableContent ? `"${notifiableContent}"` : ""}
      </>
    </>
  );
};

export const RenderClientGroupOrClientCompany: React.FC<INotificationProps> = ({
  mode = NotificationMode.Full,
  notification,
}) => {
  const entryType = notification.parameters?.entryType?.toLowerCase() ?? "";
  switch (entryType) {
    case "task":
    case "subtask":
    case "attachment":
    case "comment":
    case "clientcompany":
      return <RenderCompany notification={notification} mode={mode} />;
    case "clientgroup":
      return <RenderClientGroup notification={notification} mode={mode} />;
    default:
      return null;
  }
};

export const ApprovalFullNotification: React.FC<INotificationProps> = ({
  mode = NotificationMode.Full,
  notification,
}) => {
  const username =
    notification.parameters?.username ?? UserUtils.getAbbreviation();

  return (
    <>
      <b className="username">{username}</b>
      <RenderApproval notification={notification} mode={mode} />
    </>
  );
};

export const ApprovalShortNotification: React.FC<INotificationProps> = ({
  mode = NotificationMode.Full,
  notification,
}) => {
  const username =
    notification.parameters?.username?.substring(0, 20) ??
    UserUtils.getAbbreviation();

  return (
    <>
      <b className="username">{username}</b>
      <RenderApproval notification={notification} mode={mode} />
    </>
  );
};

export const RenderApproval: React.FC<INotificationProps> = ({
  mode,
  notification,
}) => {
  const doesLineContainClientCompany =
    notification.parameters?.notificationLine?.includes("#client_company") ??
    false;

  return (
    <>
      &nbsp;
      {doesLineContainClientCompany ? (
        <ApprovalLineWithCompany notification={notification} mode={mode} />
      ) : (
        notification.parameters.notificationLine
      )}
    </>
  );
};

export const ApprovalLineWithCompany: React.FC<INotificationProps> = ({
  mode = NotificationMode.Full,
  notification,
}) => {
  const stringLine = notification.parameters.notificationLine.split(
    "#client_company"
  );
  return (
    <>
      {stringLine[0]}
      <RenderCompany notification={notification} mode={mode} />
      {stringLine[1]}
    </>
  );
};

export const RenderCompany: React.FC<INotificationProps> = ({
  mode = NotificationMode.Full,
  notification,
}) => {
  const clientCompanyFullName =
    notification.parameters?.entryDetails?.companyName ?? "Unknown Company";
  const clientCompanyShortName =
    notification.parameters?.entryDetails?.companyName?.substring(0, 20) ??
    "Unknown Company";

  return (
    <>
      <div
        className="notify-bar-client-company"
        style={{
          backgroundColor:
            notification.parameters?.entryDetails?.companyColor ??
            ColorUtils.companyColor(),
        }}
      >
        {mode === NotificationMode.Full
          ? clientCompanyFullName
          : clientCompanyShortName}
      </div>
    </>
  );
};

export const RenderClientGroup: React.FC<INotificationProps> = ({
  mode = NotificationMode.Full,
  notification,
}) => {
  const fullName =
    notification.parameters?.entryDetails?.clientGroupName ??
    "Unknown ClientGroup";
  const shortName =
    notification.parameters?.entryDetails?.clientGroupName?.substring(0, 20) ??
    "Unknown ClientGroup";

  return (
    <>
      for
      <div
        className="notify-bar-client-group"
        style={{
          backgroundColor:
            notification.parameters?.entryDetails?.clientGroupColor ??
            ColorUtils.companyColor(),
        }}
      >
        {mode === NotificationMode.Short ? fullName : shortName}
      </div>
    </>
  );
};

export const isNotificationValid = (notification: INotification): boolean => {
  try {
    if (!notification.id) {
      throw new Error("Missing Notification Id");
    } else if (!notification.parameters?.notificationLine) {
      throw new Error("Missing Notification body");
    } else if (!notification.createdAt) {
      throw new Error(
        "Cannot calculate date difference for notification created date"
      );
    }
  } catch (error) {
    return false;
  }

  return true;
};

export const FormGroupResponseFullNotification: React.FC<INotificationProps> = ({
  mode = NotificationMode.Full,
  notification,
}) => {
  const createdAt = notification.parameters.entryDetails.createdAt;
  return <>{`${notification.parameters.notificationLine} on ${createdAt}`}</>;
};
