import { EnhancedModelApi } from "../../api/actions/EnhancedModelApi";
import { ModelApi } from "../../core/api";
import {
  IAccountBalance,
  IActual,
  IActualTime,
  IAmountToBill,
  IAttachment,
  IBalanceEntry,
  IChatThread,
  IClientCompany,
  IClientGroup,
  IClientService,
  IComment,
  ICompanyAdditionalData,
  ICreditNote,
  ICreditNoteList,
  IDepartmentAccountBalance,
  IDepartmentSettings,
  IEmail,
  IEstimatedTimeEntry,
  IEtsUser,
  IExpense,
  IExpenseBalance,
  IFeeNoteList,
  IInvoice,
  IInvoiceTemplate,
  IPayment,
  IRate,
  IStatus,
  ISubtask,
  ISubtaskType,
  ITask,
  ITaskAccountBalance,
  ITaskAdditionalData,
  ITaskType,
  ITeam,
  ITenantAdditionalData,
  ITimeEntry,
  ITimesheet,
  IWriteOff,
} from "../../model";
import { INotification } from "../../model/INotification";

export class Api {
  public static Attachment = new EnhancedModelApi<IAttachment>("attachment");
  public static ClientCompany = new EnhancedModelApi<IClientCompany>(
    "client_company"
  );
  public static ClientGroup = new ModelApi<IClientGroup>("client_group");
  public static ClientService = new ModelApi<IClientService>("client_service");
  public static Comment = new EnhancedModelApi<IComment>("comment");
  public static CompanyAdditionalDataRecord = new ModelApi<
    ICompanyAdditionalData
  >("company_additional_data_record");
  public static Email = new EnhancedModelApi<IEmail>("email");
  public static Status = new ModelApi<IStatus>("status");
  public static Subtask = new ModelApi<ISubtask>("subtask");
  public static SubtaskType = new ModelApi<ISubtaskType>("subtask_type");
  public static Task = new EnhancedModelApi<ITask>("task");
  public static TaskAdditionalDataRecord = new EnhancedModelApi<
    ITaskAdditionalData
  >("task_additional_data_record");
  public static TaskType = new EnhancedModelApi<ITaskType>("task_type");
  public static Team = new ModelApi<ITeam>("team");
  public static TenantAdditionalDataRecord = new ModelApi<
    ITenantAdditionalData
  >("tenant_additional_data_records");
  public static User = new ModelApi<IEtsUser>("user");
  public static DepartmentSetting = new ModelApi<IDepartmentSettings>(
    "department_setting"
  );
  public static Notification = new EnhancedModelApi<INotification>(
    "notification"
  );
  public static ChatThread = new EnhancedModelApi<IChatThread>("chat_thread");
  public static Expense = new EnhancedModelApi<IExpense>("expense");
  public static TimeEntry = new EnhancedModelApi<ITimeEntry>("time_entry");
  public static AccountBalance = new EnhancedModelApi<IAccountBalance>(
    "account_balance"
  );
  public static ExpenseBalance = new EnhancedModelApi<IExpenseBalance>(
    "expense_balance"
  );
  public static BalanceEntry = new EnhancedModelApi<IBalanceEntry>(
    "balance_entry"
  );
  public static Timesheet = new EnhancedModelApi<ITimesheet>("timesheet");
  public static CreditNote = new EnhancedModelApi<ICreditNote>("credit_note");
  public static Payment = new EnhancedModelApi<IPayment>("payment");
  public static InvoiceTemplate = new EnhancedModelApi<IInvoiceTemplate>(
    "invoice_template"
  );
  public static Invoice = new EnhancedModelApi<IInvoice>("invoice");
  public static WriteOff = new EnhancedModelApi<IWriteOff>("write_off");
  public static TasksAccountBalance = new EnhancedModelApi<ITaskAccountBalance>(
    "tasks_account_balance"
  );
  public static DepartmentsAccountBalance = new EnhancedModelApi<
    IDepartmentAccountBalance
  >("departments_account_balance");
  public static AmountToBill = new EnhancedModelApi<IAmountToBill>(
    "amount_to_bill"
  );
  public static FeeNotesList = new EnhancedModelApi<IFeeNoteList>(
    "fee_notes_list"
  );
  public static CreditNotesList = new EnhancedModelApi<ICreditNoteList>(
    "credit_notes_list"
  );
  public static Rate = new EnhancedModelApi<IRate>("rate");
  public static CloneTask = new EnhancedModelApi("clone_tasks");
  public static EstimatedTimeEntry = new EnhancedModelApi<IEstimatedTimeEntry>(
    "estimated_time_entries"
  );
  public static ActualTime = new EnhancedModelApi<IActualTime>("actual_time");
  public static Acutal = new EnhancedModelApi<IActual>("actual");
}
