import { IClientCompany, IEtsState } from "../../model";
import { selectForModelById } from "./ModelSelect/ModelSelect";
import { alphabeticalSort } from "./ModelSelect/Sort";

export const CompanySelect = selectForModelById(
  "company",
  (company: IClientCompany) => {
    return {};
  },
  (company: IClientCompany) => company.name,
  (company: IClientCompany) => company.chineseName,
  (state: IEtsState) => state.clientCompaniesById,
  undefined,
  undefined,
  alphabeticalSort,
  undefined,
  { popoverProps: { popoverClassName: "company-select-popover" } }
);
