export function iconName(contentType: string) {
  switch (contentType) {
    case "document":
      return "document";
    case "media":
      return "media";
    case "zip file":
      return "compressed";
    case "join-table":
      return "join-table";
    case "slides":
      return "presentation";
  }
}
