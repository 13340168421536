import { IAmountToBill, IUserTotalType } from "@app/model";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { compact } from "lodash";
import React from "react";
import { AmountToBillContent } from "./AmountToBillContent";
import { dataCover, getMonth } from "./coverData";
import { UserTotal } from "./UserTotal";

const styles = makeStyles({
  monthHeader: {
    minWidth: 95,
  },
  userTotal: {
    height: 50,
    border: "none",
    fontWeight: "bold",
  },
});

const renderAmountToBillContent = (bills, type, betweenMonths) => {
  const keys = Object.keys(bills);
  return keys.map((id) => {
    if (id.includes(type)) {
      return (
        <AmountToBillContent
          amountToBill={bills[id]}
          months={betweenMonths}
          key={id}
        />
      );
    }
  });
};

const renderUserTotal = (bills, type: IUserTotalType, betweenMonths) => {
  const keys = Object.keys(bills);
  const result = [];
  keys.map((id) => {
    if (id.includes(type.toLocaleLowerCase())) {
      result.push(bills[id]);
    }
  });
  return <UserTotal months={betweenMonths} amountToBill={result} type={type} />;
};

export const AmountToBillBody: React.FC<any> = ({ data, ids, setCsvData }) => {
  const classes = styles({});
  const amountToBills = compact<IAmountToBill>(ids.map((id) => data[id]));
  const betweenMonths = amountToBills.length > 0 ? getMonth(amountToBills) : [];
  const coverDate = dataCover(amountToBills);

  if (!amountToBills) {
    return null;
  }

  return (
    <Table size="small" aria-label="a dense table">
      <TableHead>
        <TableRow>
          <TableCell />
          {betweenMonths.map((month, index) => (
            <TableCell
              align="right"
              key={"heard" + month}
              className={classes.monthHeader}
            >
              {month}
            </TableCell>
          ))}
          <TableCell align="right">
            <span>Total</span>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="left" className={classes.userTotal}>
            By PIC
          </TableCell>
        </TableRow>
        {renderAmountToBillContent(coverDate, "pic", betweenMonths)}
        {renderUserTotal(coverDate, "PIC", betweenMonths)}
      </TableBody>
      <TableBody>
        <TableRow>
          <TableCell align="left" className={classes.userTotal}>
            By EIC
          </TableCell>
        </TableRow>
        {renderAmountToBillContent(coverDate, "eic", betweenMonths)}
        {renderUserTotal(coverDate, "EIC", betweenMonths)}
      </TableBody>
      <TableBody>
        <TableRow>
          <TableCell align="left" className={classes.userTotal}>
            By Dept
          </TableCell>
        </TableRow>
        {renderAmountToBillContent(coverDate, "department", betweenMonths)}
        {renderUserTotal(coverDate, "Department", betweenMonths)}
      </TableBody>
      <TableBody>
        <TableRow>
          <TableCell align="left" className={classes.userTotal}>
            By Billing Entity
          </TableCell>
        </TableRow>
        {renderAmountToBillContent(coverDate, "entity", betweenMonths)}
        {renderUserTotal(coverDate, "Entity", betweenMonths)}
      </TableBody>
    </Table>
  );
};
