import { EditableText } from "@blueprintjs/core";
import { useNotify, useUpdate } from "ra-core";
import React, { useState } from "react";

interface IEditableResourceFieldProps {
  resourceId: string;
  resourceName: string;
  fieldValue: string;
  fieldName: string;
  multiline: boolean;
}

export const EditableResourceField: React.FC<IEditableResourceFieldProps> = ({
  resourceId,
  resourceName,
  fieldValue,
  fieldName,
  multiline,
}) => {
  const [value, setValue] = useState(fieldValue);
  const notify = useNotify();
  const capitalizedFieldName =
    fieldName.charAt(0).toUpperCase() + fieldName.slice(1);

  const [update] = useUpdate(
    resourceName,
    resourceId,
    {
      [fieldName]: value,
    },
    {},
    {
      onSuccess: () => {
        notify(`${capitalizedFieldName} successfully updated`, "info");
      },
      onFailure: () => {
        notify(`${capitalizedFieldName} update failed`, "warning");
      },
    }
  );

  const handleChange = (value: string) => {
    setValue(value);
  };

  const handleConfirm = () => {
    update();
  };

  return (
    <EditableText
      onChange={handleChange}
      onConfirm={handleConfirm}
      value={value}
      multiline={multiline}
      maxLines={10}
    />
  );
};
