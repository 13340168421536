import { Icon } from "@blueprintjs/core";
import * as React from "react";
import { isNullOrUndefined } from "util";
import { IEtsUser } from "../../model";
import * as DateUtils from "../../utils/date";
import * as UserUtils from "../../utils/user";

interface ITimelineCreatedAtEntry {
  createdAt: string;
  record?: IEtsUser;
  type: string;
}

export const CreatedAtEntry = ({
  createdAt,
  record: createdBy,
  type,
}: ITimelineCreatedAtEntry) => {
  const date = DateUtils.formatDate(DateUtils.parseDate(createdAt));
  const changedByInitials = UserUtils.getAbbreviation(createdBy);
  let createdByStyle = {};
  if (!isNullOrUndefined(createdBy)) {
    createdByStyle = UserUtils.getStyleFromUser(createdBy);
  }
  return (
    <div className="timeline-changelog-wrapper" key={createdAt + "Creation"}>
      <div className="timeline-commit-icon">
        <Icon icon="git-commit" />
      </div>
      <div className="timeline-changelog-avatar" style={createdByStyle}>
        {changedByInitials}
      </div>
      <div className="timeline-changelog-content">
        created {type} on {date}
      </div>
    </div>
  );
};
