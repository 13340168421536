import { pickBy } from "lodash";
import { IEtsState, IStatus } from "../../model";
import { selectForModelById } from "./ModelSelect/ModelSelect";

const statusOrderSort = (a, b) => {
  return a.model.order - b.model.order;
};

export const StatusSelect = selectForModelById(
  "status",
  (status: IStatus) => {
    return status.colorHex ? { backgroundColor: status.colorHex } : {};
  },
  (status: IStatus) => status.name,
  (_status: IStatus) => null,
  (state: IEtsState) =>
    pickBy(state.statusesById, (status) => {
      const currentUser = state.usersById[state.auth.user.id];
      return status.departmentId === currentUser.department.id;
    }),
  undefined,
  undefined,
  statusOrderSort
);
