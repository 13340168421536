import moment from "moment";
import { ITimeEntry } from "../../../model";

export interface IDurationOfWeek {
  Fri: string;
  Mon: string;
  Sat: string;
  Sun: string;
  Thu: string;
  Tue: string;
  Wed: string;
}

export interface IClientCompaniesDuration {
  [id: string]: IDurationOfWeek;
}

const initialDuration = moment.duration(0).toISOString();
const initialState: IDurationOfWeek = {
  Mon: initialDuration,
  Tue: initialDuration,
  Wed: initialDuration,
  Thu: initialDuration,
  Fri: initialDuration,
  Sat: initialDuration,
  Sun: initialDuration,
};

export const countTaskDuration = (ITimeEnties: ITimeEntry[]) =>
  ITimeEnties.reduce((acc: IDurationOfWeek, entry: ITimeEntry) => {
    const dayOfWeek = getDurationOfDay(entry);
    return {
      ...acc,
      [dayOfWeek]: moment
        .duration(acc[dayOfWeek])
        .add(entry.duration)
        .toISOString(),
    };
  }, initialState);

export const countRowTotalDuration = (duration: IDurationOfWeek) =>
  Object.keys(duration).reduce(
    (acc, day) => moment.duration(acc).add(duration[day]).toISOString(),
    initialDuration
  );

export const countClientCompanyDuration = (
  timeEntries: ITimeEntry[]
): IClientCompaniesDuration =>
  timeEntries.reduce((acc: object, entry: ITimeEntry) => {
    const day = getDurationOfDay(entry);
    return {
      ...acc,
      [entry.clientCompanyId]: {
        ...initialState,
        ...acc[entry.clientCompanyId],
        [day]: moment
          .duration(acc[entry.clientCompanyId] ?? 0)
          .add(entry.duration)
          .toISOString(),
      },
    };
  }, {});

const getDurationOfDay = (entry: ITimeEntry) =>
  moment(entry.startDatetime).format("ddd");
