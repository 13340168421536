import { FormLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { enabled } from "../../config/features";
import { Constants } from "../../constants";
import { IEtsState, IInvoice } from "../../model";
import { FeeNoteNumberField } from "../Invoice/FeeNoteNumberField";

export interface IFeeNoteFieldProps {
  record?: IInvoice;
}

const useStyles = makeStyles(() => ({
  label: {
    marginTop: 16,
  },
}));

export const FeeNoteField: React.FC<IFeeNoteFieldProps> = ({ record }) => {
  const roles = useSelector((state: IEtsState) => state.auth.roles);
  const classes = useStyles({});
  const canAccess = enabled("timebill.invoice", roles);
  return (
    <>
      <FormLabel component="legend" className={classes.label}>
        Fee Note Number
      </FormLabel>
      {canAccess ? (
        <Link to={`${Constants.INVOICE_PATH}/${record.id}`}>
          <FeeNoteNumberField record={record} source="feeNoteNumber" />
        </Link>
      ) : (
        <FeeNoteNumberField record={record} source="feeNoteNumber" />
      )}
    </>
  );
};
