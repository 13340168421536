import { Grid } from "@material-ui/core";
import React from "react";
import { DepartmentList } from "./DepartmentList";
import { ReportLayoutLeft } from "./ReportLayoutLeft";

export const ReportLayout: React.FC<any> = (props) => {
  const { data, ids } = props;

  return (
    <div style={{ margin: "1em" }}>
      <Grid container={true} spacing={2} wrap="nowrap">
        <Grid
          container={true}
          item={true}
          xs={12}
          justify="center"
          wrap="nowrap"
        >
          <ReportLayoutLeft headerName={props.headerName} />
          {ids.map((id) => (
            <DepartmentList
              department={data[id]}
              key={id}
              type={props.headerName}
            />
          ))}
        </Grid>
      </Grid>
    </div>
  );
};
