import { Button, makeStyles } from "@material-ui/core";
import ImageEye from "@material-ui/icons/RemoveRedEye";
import { push } from "connected-react-router";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { Constants } from "../../../constants";
import { IClientInvoice } from "../../../model";
const useStyle = makeStyles({
  button: {
    color: "#3f51b5",
  },
});

interface IShowButtonProps {
  record?: IClientInvoice;
}

export const ShowButton: React.FC<IShowButtonProps> = ({ record }) => {
  const classes = useStyle({});

  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(
      push(
        `${Constants.CLIENT_APP_PATH}/${record.clientCompanyId}/invoices/${record.id}/show`
      )
    );
  };

  return (
    <div className="client-invoice-show-button">
      <Button
        size="small"
        className={classes.button}
        onClick={onClick}
        startIcon={<ImageEye />}
      >
        <FormattedMessage
          defaultMessage="Show"
          description=" custom react table show button"
        />
      </Button>
    </div>
  );
};
