import * as jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import { IHourByJob } from "../../model/IHourByJob";
import { durationToStringFormat, sumOfDuration } from "../../utils/date";
import { priceFormatter } from "../../utils/money";
import { calculatePercentage } from "./Fields/RecoverRateField";

export const hourByJobExporter = (isDaily: boolean) => (
  records: IHourByJob[]
) => {
  const data = records.map((record) => {
    const budgetedKey = `Budgeted ${isDaily ? "Days" : "Hours"}`;
    const actualKey = `Actual ${isDaily ? "Days" : "Hours"}`;
    const newDetail = record.records.map((detail) => ({
      "Name": detail.userName,
      [budgetedKey]: durationToStringFormat(detail.estTime, isDaily),
      [actualKey]: durationToStringFormat(detail.actualTime, isDaily),
      "Rate": priceFormatter.format(Number(detail.rate)),
      "Budgeted WIP": priceFormatter.format(Number(detail.estAmount)),
      "Actual WIP": priceFormatter.format(Number(detail.actualAmount)),
      "Variance": priceFormatter.format(Number(detail.variance)),
    }));

    const budgetDuration = record.records.reduce(
      (accumulator, currentRecord) => {
        return sumOfDuration(accumulator, currentRecord.estTime);
      },
      "PT0H"
    );

    const estimatedWip = record.records.reduce((acc, current) => {
      return Number(current.estAmount) + acc;
    }, 0);

    const actualWip = record.records.reduce((acc, current) => {
      return Number(current.actualAmount) + acc;
    }, 0);
    const estimatedFee = Number(record.estimatedFee);
    return {
      "Description": record.description,
      "Start Date": record.taskStartDate,
      "Due Date": record.taskDueDate,
      [budgetedKey]: durationToStringFormat(budgetDuration, isDaily),
      "Budgeted Fee": priceFormatter.format(estimatedFee),
      "Actual Recoverability Rate": calculatePercentage(
        estimatedFee,
        actualWip
      ),
      "Bud. Recoverability Rate": calculatePercentage(
        estimatedFee,
        estimatedWip
      ),
      "Staff": newDetail,
    };
  });

  jsonExport(data, {}, (err, csv) => {
    downloadCSV(csv, "actual_vs_budget_by_job");
  });
};
