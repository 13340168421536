import { Icon } from "@blueprintjs/core";
import { Sort } from "ra-core";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../../actions";
import { enabled } from "../../config";
import { IEtsState, ITask } from "../../model";
import { SortableHeader, SortableHeaderItem } from "../SortableHeader";
import { Warning } from "../Warning";
import { EstimatedTimeEntryCreator } from "./EstimatedTimeEntryCreator";
import { EstimatedTimeEntryRecord } from "./EstimatedTimeEntryRecord";
import { EstimatedTimeEntryTotal } from "./EstimatedTimeEntryTotal";

interface IEstimatedTimeEntryListProps {
  task: ITask;
}

const estimatedTimeEntryEnabled = (task, auth) => {
  const { picId, reviewerId } = task;
  return (
    enabled("timebill.estimatedTimeEntry", auth.roles) ||
    picId === auth.user.id ||
    reviewerId === auth.user.id
  );
};

export const EstimatedTimeEntryList: FC<IEstimatedTimeEntryListProps> = ({
  task,
}) => {
  const dispatch = useDispatch();
  const [sort, setSort] = useState<Sort>({ field: "", order: "" });
  const auth = useSelector((state: IEtsState) => state.auth);
  const editable = estimatedTimeEntryEnabled(task, auth);
  const fetchEstimatedTimeEntries = useCallback(() => {
    dispatch(
      Api.EstimatedTimeEntry.getAll({
        sort,
        pagination: { page: 1, perPage: 10000 },
        filter: { taskId: task.id },
      })
    );
  }, [dispatch, sort, task.id]);
  const handleChangeSort = useCallback(
    (newSort: Sort) => {
      setSort(newSort);
    },
    [setSort, fetchEstimatedTimeEntries]
  );

  const estimatedTimeEntries = useSelector(
    (state: IEtsState) => state.estimatedTimeEntries
  );

  useEffect(() => {
    fetchEstimatedTimeEntries();
    dispatch(
      Api.ActualTime.getAllCustomPath(`/api/tasks/${task.id}/actual_time`)
    );
  }, [task.rateId, sort, task.id]);

  if (!estimatedTimeEntries) {
    return null;
  }

  return (
    <div className="estimated-time-entries-list">
      <div className="estimated-time-entries-list-title">
        <h6>
          <Icon icon="properties" /> Budgeted Time
        </h6>
        {!task.rateId && (
          <Warning text="Please select the task rate in details tab" />
        )}
      </div>
      {!!task.rateId && (
        <div className="estimated-time-entries">
          <SortableHeader onClick={handleChangeSort} sort={sort}>
            <SortableHeaderItem
              label="Staff"
              field="user"
              className="estimated-time-entry-user"
            />
            <SortableHeaderItem
              label="Rate"
              field="rate"
              className="estimated-time-entry-rate"
            />
            <SortableHeaderItem
              label="Duration"
              field="duration"
              className="estimated-time-entry-duration"
            />
            <SortableHeaderItem
              label="Actual"
              field=""
              sortable={false}
              className="estimated-time-entry-duration-actual"
            />
            <SortableHeaderItem
              label="WIP"
              field="amount"
              className="estimated-time-entry-amount"
            />
            <SortableHeaderItem
              label="Actual"
              field=""
              sortable={false}
              className="estimated-time-entry-amount-actual"
            />
            <SortableHeaderItem
              label="Variance"
              field="amount"
              sortable={false}
              className="estimated-time-entry-variance"
            />
            <div className="estimated-time-entry-delete" />
          </SortableHeader>
          <div className="estimated-time-entry-content">
            {estimatedTimeEntries.map((entry) => (
              <EstimatedTimeEntryRecord
                key={entry.id}
                entry={entry}
                editable={editable}
              />
            ))}
            {editable && <EstimatedTimeEntryCreator taskId={task.id} />}
            <EstimatedTimeEntryTotal task={task} />
          </div>
        </div>
      )}
    </div>
  );
};
