import { Sort } from "ra-core";
import React from "react";

interface ISortableHeaderProps {
  onClick: (sort: Sort) => void;
  sort: Sort;
}

export const SortableHeader: React.FC<ISortableHeaderProps> = ({
  children,
  onClick,
  sort,
}) => {
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child as React.ReactElement<any>, {
      sort,
      onClick,
    })
  );

  return <div className="sortable-header">{childrenWithProps}</div>;
};
