import { FormLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { TextField } from "react-admin";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { enabled } from "../../config/features";
import { Constants } from "../../constants";
import { IEtsState, ITask } from "../../model";

export interface ITaskFieldProps {
  record?: ITask;
}

const useStyles = makeStyles(() => ({
  label: {
    marginTop: 16,
  },
}));
export const TaskField: React.FC<ITaskFieldProps> = ({ record }) => {
  const classes = useStyles({});

  const balanceEnabled = useSelector((state: IEtsState) => {
    const roles = state.auth.roles;
    const currentUserId = state.auth.user.id;
    return (
      enabled("timebill.reports", roles) ||
      currentUserId === record.picId ||
      currentUserId === record.reviewerId
    );
  });

  if (!record) {
    return null;
  }

  const renderText = () => <TextField record={record} source="description" />;
  return (
    <>
      <FormLabel component="legend" className={classes.label}>
        Job
      </FormLabel>
      {balanceEnabled ? (
        <Link to={`${Constants.APP_PATH}/account_balance/${record.id}`}>
          {renderText()}
        </Link>
      ) : (
        renderText()
      )}
    </>
  );
};
