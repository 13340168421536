import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { pick } from "lodash";
import { DropzoneArea } from "material-ui-dropzone";
import { CRUD_GET_ONE, useCreate, useGetOne, useVersion } from "ra-core";
import React, { useCallback, useMemo, useReducer, useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { useDispatch, useSelector } from "react-redux";
import { subscribeToBankStatementConversion } from "../../actions";
import { Constants } from "../../constants";
import { IBankStatementConversion, IEtsState } from "../../model";

const useStyles = makeStyles({
  container: {
    margin: "50px 10px",
  },
  formControl: {
    minWidth: 120,
  },
  button: {
    textTransform: "none",
  },
});

type ActiveStorageFileUpload =
  | { state: "waiting"; id: string; file: File }
  | { state: "uploading"; id: string; file: File; progress: number }
  | { state: "error"; id: string; file: File; error: string }
  | { state: "finished"; id: string; file: File };

interface IBankType {
  description: string;
  value: string;
}

const bankTypes = [{ description: "Bank HSBC", value: "bankhs" }];

interface IBankStatementSwitchGroupProps {
  file: File;
  isScanned: boolean;
  onChangeScanned: (file: File, isScanned: boolean) => void;
}

const BankStatementSwitchGroup = ({
  file,
  isScanned,
  onChangeScanned,
}: IBankStatementSwitchGroupProps) => {
  const value = useMemo(() => (isScanned ? "scanned" : "electronic"), [
    isScanned,
  ]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeScanned(file, event.target.value === "scanned");
    },
    [isScanned, onChangeScanned]
  );

  return (
    <Grid item={true}>
      <FormControl component="fieldset">
        <FormLabel component="legend">{file.name}</FormLabel>
        <RadioGroup
          aria-label="file"
          name={file.name}
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="electronic"
            control={<Radio />}
            label="Electronic"
          />
          <FormControlLabel
            value="scanned"
            control={<Radio />}
            label="Scanned"
          />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

interface IBankStatementDropzoneProps {
  bankStatementConversionId: string;
  files: {
    [key: string]: IBankStatement;
  };
  onUpload: (files: FileList | File[]) => any;
  onChangeBankType: (bankType: IBankType) => any;
  ready: boolean;
  uploads: ActiveStorageFileUpload[];
  bankType?: IBankType;
  onChangeScanned: (file: File, isScanned: boolean) => void;
  onChangeFiles: (files: FileList | File[]) => any;
}

export const BankStatementDropzone = ({
  bankStatementConversionId,
  files,
  onUpload,
  onChangeBankType,
  uploads,
  ready,
  bankType,
  onChangeScanned,
  onChangeFiles,
}: IBankStatementDropzoneProps) => {
  const classes = useStyles();
  const [rawFiles, setFiles] = useState<File[]>([]);

  const handleSubmit = useCallback(() => {
    onUpload(rawFiles);
  }, [onUpload, rawFiles]);

  const handleChangeFiles = useCallback(
    (newFiles: File[]) => {
      onChangeFiles(newFiles);
      setFiles(newFiles);
    },
    [onChangeFiles, setFiles]
  );

  const handleChangeBankType = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: string }>) => {
      const selected = bankTypes.find(
        (type) => type.value === event.target.value
      );
      onChangeBankType(selected);
    },
    [onChangeBankType, bankTypes]
  );
  const { data } = useGetOne(
    Constants.BANK_STATEMENT_CONVERSION_RESOURCE,
    bankStatementConversionId,
    { action: CRUD_GET_ONE }
  );

  const [dropKey, setDropKey] = useState(0);
  const clearPDFs = useCallback(() => {
    setDropKey(dropKey + 1);
    handleChangeFiles([]);
  }, [dropKey, setDropKey]);

  const bankStatementConversion = data as IBankStatementConversion;
  const _ = useVersion();

  const bankStatementConversionText = (status) => {
    switch (status) {
      case "generating":
        return "Generating";
      case "success":
        return "Download";
    }
  };

  const checkShowSubmit = () => {
    if (
      !!bankStatementConversion &&
      bankStatementConversion?.conversionStatus === "failure"
    ) {
      return true;
    }
    if (!bankStatementConversion) {
      return true;
    }
    return false;
  };

  return (
    <div className={classes.container}>
      <Grid container={true} direction="column" spacing={2}>
        <Grid item={true}>
          <DropzoneArea
            key={dropKey}
            onChange={handleChangeFiles}
            dropzoneText="Drag and drop bank statement PDFs here or click"
            acceptedFiles={["application/pdf"]}
            filesLimit={12}
            showFileNames={true}
          />
        </Grid>
        <Grid item={true}>
          <FormControl className={classes.formControl}>
            <InputLabel>Bank Type</InputLabel>
            <Select
              value={bankType?.value ?? ""}
              onChange={handleChangeBankType}
            >
              {bankTypes.map(({ description, value }) => (
                <MenuItem key={value} value={value}>
                  {description}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>Required</FormHelperText>
          </FormControl>
        </Grid>
        {Object.keys(files).map((key) => {
          const { file, isScanned } = files[key];
          return (
            <BankStatementSwitchGroup
              key={fileId(file)}
              file={file}
              isScanned={isScanned}
              onChangeScanned={onChangeScanned}
            />
          );
        })}
        <Grid item={true}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={clearPDFs}
            className={classes.button}
          >
            Clear PDFs
          </Button>
        </Grid>
        <Grid item={true}>
          {checkShowSubmit() && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!bankType?.value}
              className={classes.button}
            >
              Submit
            </Button>
          )}
          {!!bankStatementConversion &&
            bankStatementConversion?.conversionStatus !== "failure" && (
              <Button
                variant="contained"
                color="secondary"
                href={bankStatementConversion?.fileUrl}
                disabled={
                  !(bankStatementConversion?.conversionStatus === "success")
                }
                className={classes.button}
              >
                {bankStatementConversionText(
                  bankStatementConversion?.conversionStatus
                )}
              </Button>
            )}
        </Grid>
        <Grid item={true}>
          {bankStatementConversion?.conversionStatus === "success" && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!bankType?.value}
              className={classes.button}
            >
              Submit New
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

interface IBankStatement {
  isScanned: boolean;
  file: File;
}

interface IBankStatementUploaderState {
  bankType?: IBankType;
  files: {
    [key: string]: IBankStatement;
  };
}

type IBankStatementUploaderAction =
  | { type: "SET_FILES"; files: File[] }
  | { type: "CHANGE_FILE_IS_SCANNED"; file: File; isScanned: boolean }
  | { type: "SET_BANK_TYPE"; bankType: IBankType };

const fileId = (file: File) => {
  return `${file.name}-${file.lastModified}`;
};

const reducer = (
  state: IBankStatementUploaderState,
  action: IBankStatementUploaderAction
): IBankStatementUploaderState => {
  switch (action.type) {
    case "SET_FILES":
      return action.files.reduce(
        (s, file) => ({
          ...s,
          files: {
            ...s.files,
            [fileId(file)]: {
              ...s.files[fileId(file)],
              file,
              isScanned: s.files[fileId(file)]?.isScanned ?? false,
            },
          },
        }),
        { ...state, files: pick(state.files, action.files.map(fileId)) }
      );
    case "CHANGE_FILE_IS_SCANNED":
      return {
        ...state,
        files: {
          ...state.files,
          [fileId(action.file)]: {
            ...state.files[fileId(action.file)],
            isScanned: action.isScanned,
          },
        },
      };
    case "SET_BANK_TYPE":
      return {
        ...state,
        bankType: action.bankType,
      };
  }
};

export const BankStatementCSVUploader = () => {
  const isRegistered = useSelector(
    (state: IEtsState) => !!state.admin.resources.bank_statement_conversions
  );
  const [{ bankType, files }, dispatch] = useReducer(reducer, { files: {} });
  const token = useSelector((state: IEtsState) => state.auth.token);
  const [create, { loading, error }] = useCreate(
    Constants.BANK_STATEMENT_CONVERSION_RESOURCE
  );
  const reduxDispatch = useDispatch();
  const [bankStatementConversionId, setBankStatementConversionId] = useState();

  const handleAttachment = (uploadedFiles) => {
    create(
      {
        payload: {
          data: {
            fileStatuses: Object.keys(files).map((id) => ({
              filename: files[id].file.name,
              size: files[id].file.size,
              lastModified: files[id].file.lastModified,
              isScanned: files[id].isScanned,
            })),
            bankType: bankType.value,
            bankStatements: uploadedFiles,
          },
        },
      },
      {
        onSuccess: (response) => {
          reduxDispatch(subscribeToBankStatementConversion(response.data));
          setBankStatementConversionId(response.data.id);
        },
      }
    );
  };

  const handleChangeBankType = useCallback(
    (newBankType) => {
      dispatch({ type: "SET_BANK_TYPE", bankType: newBankType });
    },
    [dispatch]
  );

  const handleChangeFiles = useCallback(
    (newFiles) => {
      dispatch({ type: "SET_FILES", files: newFiles });
    },
    [dispatch]
  );

  const handleChangeScanned = useCallback(
    (file: File, isScanned: boolean) => {
      dispatch({ type: "CHANGE_FILE_IS_SCANNED", file, isScanned });
    },
    [dispatch]
  );

  if (!isRegistered) {
    return null;
  }

  const render = (props: any) => {
    return (
      <BankStatementDropzone
        {...props}
        bankStatementConversionId={bankStatementConversionId}
        files={files}
        onUpload={props.handleUpload}
        bankType={bankType}
        onChangeBankType={handleChangeBankType}
        onChangeScanned={handleChangeScanned}
        onChangeFiles={handleChangeFiles}
      />
    );
  };

  return (
    <DirectUploadProvider
      headers={{ authorization: `Bearer ${token}` }}
      multiple={false}
      onSuccess={handleAttachment}
      render={render}
    />
  );
};
