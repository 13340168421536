import { Button, Icon } from "@blueprintjs/core";
import { TableCell, TableRow, Tooltip } from "@material-ui/core";
import * as React from "react";
import { Link } from "react-router-dom";
import { Constants } from "../../../constants";
import { ITask, ITimeEntry, ITimesheet } from "../../../model";
import * as Utils from "../../../utils/date";
import { countRowTotalDuration, countTaskDuration } from "./countDuration";
import { TimesheetTimeEntryRow } from "./TimesheetCreateTimeEntryRow";
import { TimesheetDurationBox } from "./TimesheetDurationBox";

export interface ITimesheetTaskRowProps {
  task: ITask;
  timesheet?: ITimesheet;
  entries: ITimeEntry[];
  onUpdateEntry?: (newEntry: ITimeEntry, previousEntry: ITimeEntry) => void;
}

export const TimesheetTaskRow: React.FC<ITimesheetTaskRowProps> = ({
  task,
  timesheet,
  entries,
  onUpdateEntry,
}) => {
  const [isExpanded, setExpanded] = React.useState(false);

  const handleRowClick: React.MouseEventHandler = (e) => {
    e.preventDefault();
    setExpanded(!isExpanded);
  };

  const timeEntriesSort = entries.sort((entry1, entry2) =>
    Utils.sortByDate(entry2.startDatetime, entry1.startDatetime)
  );
  const duration = countTaskDuration(entries);

  const renderWarning = () => {
    const title = "This task has no rate or assignee rate";
    return (
      <Tooltip title={title} aria-label="add">
        <Icon icon="warning-sign" iconSize={20} style={{ color: "#FFC400" }} />
      </Tooltip>
    );
  };
  return (
    <>
      <TableRow className="timesheet-create-table-task-row">
        <TableCell className="timesheet-create-task-first-cell">
          <Link to={`${Constants.TASK_PATH}/${task.id}`}>
            {task.description}
          </Link>
          <Button
            onClick={handleRowClick}
            className="bp3-minimal bp3-intent-primary bp3-small"
          >
            {isExpanded ? (
              <Icon icon="chevron-up" />
            ) : (
              <Icon icon="chevron-down" />
            )}
          </Button>
          {!task.rateId || (!!task.rateId && !task.assigneeRate)
            ? renderWarning()
            : ""}
        </TableCell>
        {Object.keys(duration).map((day) => (
          <TimesheetDurationBox
            duration={duration[day]}
            key={`${task.id}-${day}`}
          />
        ))}
        <TimesheetDurationBox
          duration={countRowTotalDuration(duration)}
          key={`${task.id}-total`}
        />
      </TableRow>
      {isExpanded &&
        timeEntriesSort.map((entry) => (
          <TimesheetTimeEntryRow
            timeEntry={entry}
            timesheet={timesheet}
            key={entry.id}
            onUpdateEntry={onUpdateEntry}
          />
        ))}
    </>
  );
};
