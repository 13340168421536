import { useDataProvider, useNotify } from "ra-core";
import React, { useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { useSelector } from "react-redux";
import { Constants } from "../../constants";
import { IAttachment, IEtsState } from "../../model";
import { canCreateAttachments } from "../../selectors";
import { GeneralUpload } from "./GeneralUpload";

interface ITimelineFormStateProps {
  enableAttachmentCreation: boolean;
  token: string;
}

interface ITimelineFormOwnProps {
  entryType: "Task" | "ClientGroup" | "ClientCompany";
  entryId: string;
  afterSubmit: () => void;
}

export const TimelineForm = ({
  entryId,
  entryType,
  afterSubmit,
}: ITimelineFormOwnProps) => {
  const { enableAttachmentCreation, token } = useSelector(
    (state: IEtsState) => {
      return {
        enableAttachmentCreation: canCreateAttachments(state),
        token: state.auth.token,
      };
    }
  );

  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const onSubmit = (attachment: string): Promise<Partial<IAttachment>> => {
    const attrs = { entryId, entryType, fileObject: attachment };
    return dataProvider.create(Constants.ATTACHMENT_RESOURCE, {
      data: attrs,
    });
  };

  const handleAttachment = async (attachments: string[]) => {
    try {
      const data = await Promise.all(attachments.map(onSubmit));
      notify("Attachment(s) successfully uploaded");
    } catch (error) {
      notify("Some uploads were unsuccessful", "warning");
    }
    afterSubmit();
    setLoading(false);
  };

  /*
    renderProps are from DirectUploadProvider, with handleUpload as
    function that takes in File object to be uploaded as a parameter,
    passed in to be called by Dropzone's onDrop function
  */
  const render = (renderProps: any) => {
    return (
      <GeneralUpload
        entryId={entryId}
        entryType={entryType}
        afterSubmit={afterSubmit}
        handleUpload={renderProps.handleUpload}
        loading={loading}
        setLoading={setLoading}
      />
    );
  };

  return (
    <DirectUploadProvider
      headers={{ authorization: `Bearer ${token}` }}
      multiple={true}
      onSuccess={handleAttachment}
      render={render}
    />
  );
};
