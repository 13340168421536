import { Box, TableCell } from "@material-ui/core";
import moment from "moment";
import "moment-duration-format";
import React from "react";
import { useSelector } from "react-redux";
import { IEtsState } from "../../../model";
import * as Utils from "../../../utils/date";

interface ITimesheetDurationBoxProps {
  duration: string;
}

export const TimesheetDailyDuration = ({
  duration,
}: ITimesheetDurationBoxProps) => {
  const durationObject = Utils.durationToString(duration);

  return (
    <Box display="flex">
      <span className="timesheet-duration-time-box-timer">
        {durationObject.hours}
      </span>
      <span className="timesheet-duration-time-box-timer-colon">:</span>
      <span className="timesheet-duration-time-box-timer">
        {durationObject.mins ?? "00"}
      </span>
    </Box>
  );
};

// eslint-disable-next-line react/display-name
export const TimesheetDurationBox = React.memo(
  ({ duration }: ITimesheetDurationBoxProps) => {
    const isDaily = useSelector(
      (state: IEtsState) => state.departmentSetting.timebillDaily
    );
    return (
      <TableCell className="timesheet-duration-box" colSpan={1}>
        <div className="timesheet-duration-time-box">
          {!isDaily && <TimesheetDailyDuration duration={duration} />}
          {isDaily && (
            <span>{moment.duration(duration).asDays().toFixed(2)}</span>
          )}
        </div>
      </TableCell>
    );
  }
);
