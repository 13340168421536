import { ListContextProvider, useReferenceManyFieldController } from "ra-core";
import React from "react";
import { ListView } from "react-admin";

export const ReferenceManyList: React.FC<any> = (props) => {
  const {
    basePath,
    children,
    filter,
    page = 1,
    perPage,
    record,
    reference,
    resource,
    sort,
    source,
    target,
  } = props;

  if (React.Children.count(children) !== 1) {
    throw new Error(
      "<ReferenceManyField> only accepts a single child (like <Datagrid>)"
    );
  }

  const controllerProps = useReferenceManyFieldController({
    basePath,
    filter,
    page,
    perPage,
    record,
    reference,
    resource,
    sort,
    source,
    target,
  });

  return (
    <ListContextProvider value={controllerProps}>
      <ListView {...props} {...controllerProps} />
    </ListContextProvider>
  );
};
