import { Grid } from "@material-ui/core";
import { useReference } from "ra-core";
import { SaveButton, Toolbar } from "ra-ui-materialui";
import React from "react";
import { useSelector } from "react-redux";
import { Constants } from "../../constants";
import { ApprovalAction } from "../../hooks";
import { IEtsState, ITask } from "../../model";
import { BackButton } from "./BackButton";
import { WorkFlowButton } from "./WorkFlowButton";

export const EditToolBar = (props) => {
  const { record, resource, onClick, showSave } = props;
  const { approvalStatus, createdById } = record;
  const user = useSelector((state: IEtsState) => state.auth.user);
  const { referenceRecord } = useReference({
    reference: Constants.TASK_RESOURCE,
    id: record.taskId,
  });
  const task = referenceRecord as ITask;

  if (!task) {
    return null;
  }

  const { reviewerId: eicId, picId } = task;
  const renderSaveButton = (componentProps) => {
    if ((user.id === createdById && approvalStatus === "draft") || showSave) {
      return (
        <SaveButton
          {...componentProps}
          label="Save"
          redirect="edit"
          submitOnEnter={false}
        />
      );
    }
  };

  const renderWorkFlowButton = () => {
    if (user.id === createdById && approvalStatus === "draft") {
      return (
        <WorkFlowButton
          resource={resource}
          record={record}
          workFlow={ApprovalAction.Submit}
          onClick={onClick}
        />
      );
    }
    if (approvalStatus === "need_eic_approval" && eicId === user.id) {
      return (
        <>
          <WorkFlowButton
            resource={resource}
            record={record}
            workFlow={ApprovalAction.EicAccept}
            onClick={onClick}
          />
          <WorkFlowButton
            resource={resource}
            record={record}
            workFlow={ApprovalAction.EicReject}
            onClick={onClick}
          />
        </>
      );
    }
    if (approvalStatus === "need_pic_approval" && picId === user.id) {
      return (
        <>
          <WorkFlowButton
            resource={resource}
            record={record}
            workFlow={ApprovalAction.PicAccept}
            onClick={onClick}
          />
          <WorkFlowButton
            resource={resource}
            record={record}
            workFlow={ApprovalAction.PicReject}
            onClick={onClick}
          />
        </>
      );
    }
  };

  return (
    <Toolbar {...props}>
      <Grid item={true} xs={6}>
        <BackButton type={resource} />
        {renderSaveButton(props)}
      </Grid>
      <Grid item={true} xs={6}>
        {renderWorkFlowButton()}
      </Grid>
    </Toolbar>
  );
};
