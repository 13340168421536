import { Icon } from "@blueprintjs/core";
import * as React from "react";
import { ReferenceField } from "react-admin";
import { Constants } from "../../constants";
import { ICommentSortable, IEtsUser } from "../../model";
import { formatDate, parseDate } from "../../utils/date";
import { displayValue, getStyleFromUser } from "../../utils/user";

interface ICommentProps {
  record: ICommentSortable;
}

const transform = ({ comment, metaData, content }: ICommentSortable) => {
  if (!metaData || !metaData.mentions.length) {
    return content;
  }
  const { mentions = [] } = metaData;
  return mentions.reduce((arr, m, i) => {
    const children = [
      ...arr,
      content.substring(
        i > 0
          ? mentions[i - 1].plainTextIndex + mentions[i - 1].display.length
          : 0,
        m.plainTextIndex
      ),
      <span key={m.plainTextIndex} className="mentions__mention">
        {m.display}
      </span>,
    ];
    if (i === mentions.length - 1) {
      children.push(content.substring(m.plainTextIndex + m.display.length));
    }
    return children;
  }, []);
};

interface ITimelineCreatedByProps {
  record?: IEtsUser;
}

const TimelineCreatedBy: React.FC<ITimelineCreatedByProps> = ({ record }) => {
  if (!record) {
    return null;
  }

  const createdByInitials = record.abbreviation;
  const createdByStyle = getStyleFromUser(record);

  return (
    <div className="timeline-comment-avatar" style={createdByStyle}>
      {createdByInitials}
    </div>
  );
};

interface ITimelineCommentedByProps {
  record?: IEtsUser;
  createdAt: string;
}

const TimelineCommentedBy: React.FC<ITimelineCommentedByProps> = ({
  record,
  createdAt,
}) => {
  const createdByName = displayValue(record, true);
  const date = formatDate(parseDate(createdAt));

  return (
    <div className="timeline-comment-header">
      {createdByName} commented on {date}
      <Icon className="icon-align-center" icon="comment" />
    </div>
  );
};

export const Comment: React.FC<ICommentProps> = ({ record }) => {
  return (
    <div className="timeline-comment-wrapper">
      <ReferenceField
        record={record}
        source="createdById"
        basePath={Constants.ATTACHMENT_RESOURCE}
        reference={Constants.USER_RESOURCE}
      >
        <TimelineCreatedBy />
      </ReferenceField>
      <div className="timeline-comment">
        <ReferenceField
          record={record}
          source="createdById"
          basePath={Constants.ATTACHMENT_RESOURCE}
          reference={Constants.USER_RESOURCE}
        >
          <TimelineCommentedBy createdAt={record.createdAt} />
        </ReferenceField>
        <div className="timeline-comment-content">{transform(record)}</div>
      </div>
    </div>
  );
};
