import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { IUser } from "../../app/types";
import { IAuthState } from "../model";

const typeCache: { [label: string]: boolean } = {};

function type<T>(label: T | ""): T {
  if (typeCache[label as string]) {
    throw new Error(`Action type "${label}" is not unique`);
  }

  typeCache[label as string] = true;

  return label as T;
}

export const ActionTypes = {
  START_LOGIN: type<"START_LOGIN">("START_LOGIN"),
  COMPLETE_LOGIN: type<"COMPLETE_LOGIN">("COMPLETE_LOGIN"),
  SUCCESSFUL_GET_USER_DETAILS: type<"SUCCESSFUL_GET_USER_DETAILS">(
    "SUCCESSFUL_GET_USER_DETAILS"
  ),
  FAIL_LOGIN: type<"FAIL_LOGIN">("FAIL_LOGIN"),
  START_LOGOUT: type<"START_LOGOUT">("START_LOGOUT"),
  COMPLETE_LOGOUT: type<"COMPLETE_LOGOUT">("COMPLETE_LOGOUT"),
  SET_REDIRECT: type<"SET_REDIRECT">("SET_REDIRECT"),
  CLEAR_REDIRECT: type<"CLEAR_REDIRECT">("CLEAR_REDIRECT"),
  SUCCESSFUL_GET_CLIENT_USER_DETAILS: type<
    "SUCCESSFUL_GET_CLIENT_USER_DETAILS"
  >("SUCCESSFUL_GET_CLIENT_USER_DETAILS"),
};

export interface IStartLogin extends Action<"START_LOGIN"> {}

export interface ICompleteLogin extends Action<"COMPLETE_LOGIN"> {
  authToken: IAuthToken;
}

export interface ISuccessfulGetUserDetails
  extends Action<"SUCCESSFUL_GET_USER_DETAILS"> {
  user: IUser;
  roles: string[];
  departmentId: string;
}

export interface ISuccessfulGetClientUserDetails
  extends Action<"SUCCESSFUL_GET_CLIENT_USER_DETAILS"> {
  user: IUser;
}

export interface IFailLogin extends Action<"FAIL_LOGIN"> {
  error: string;
}

export interface IStartLogout extends Action<"START_LOGOUT"> {}
export interface ICompleteLogout extends Action<"COMPLETE_LOGOUT"> {}

export interface ISetRedirect extends Action<"SET_REDIRECT"> {
  redirectPath: string;
}

export interface IClearRedirect extends Action<"CLEAR_REDIRECT"> {}

export declare type AuthAction =
  | Action
  | ICompleteLogin
  | ISuccessfulGetUserDetails
  | IFailLogin
  | ISetRedirect;
export declare type AuthDispatch = ThunkDispatch<IAuthState, void, AuthAction>;

export type IAuthActionType =
  | IStartLogin
  | ICompleteLogin
  | ISuccessfulGetUserDetails
  | IFailLogin
  | IStartLogout
  | ISetRedirect
  | IClearRedirect
  | ICompleteLogout
  | ISuccessfulGetClientUserDetails;

export interface IAuthToken {
  accessToken: string;
  client: string;
  expiry: string;
  tokenType: string;
  uid: string;
}

export const authTokenKey = {
  accessToken: "access-token",
  client: "client",
  expiry: "expiry",
  tokenType: "token-type",
  uid: "uid",
};
