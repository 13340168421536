import React from "react";
import { IResponseAnswer } from "../../model/IFormRespones";
import { getTypeformAnswer } from "../../utils/typeformConvert";

interface ITypeformAnswerProps {
  answer: IResponseAnswer;
}

export const TypeformAnswer: React.FC<ITypeformAnswerProps> = ({ answer }) => {
  const convertAnswer = getTypeformAnswer(answer);
  const renderFileUrl = () => (
    <a href={answer.fileUrl} download={true}>
      {convertAnswer}
    </a>
  );

  const renderUrl = () => (
    <a href={answer.url} target="_blank" rel="noreferrer">
      {convertAnswer}
    </a>
  );

  const renderChoices = () => (
    <ol>
      {convertAnswer.map((a) => (
        <li key={`${answer.field.id}-${a}`}>{a}</li>
      ))}
    </ol>
  );

  const render = () => getTypeformAnswer(answer);
  return (
    <>
      {answer.type === "url"
        ? renderUrl()
        : answer.type === "file_url"
        ? renderFileUrl()
        : answer.type === "choices"
        ? renderChoices()
        : render()}
    </>
  );
};
