import {
  Datagrid,
  ReferenceManyField,
  ShowButton,
  TextField,
} from "ra-ui-materialui";
import React from "react";
import { Pagination } from "react-admin";
import { CompanyShow } from "../../components/Company/CompanyShow";
import { Constants } from "../../constants";
import { IClientGroup } from "../../model";

interface IClientGroupCompaniesProps {
  clientGroup: IClientGroup;
}

export const ClientGroupCompanies: React.FC<IClientGroupCompaniesProps> = ({
  clientGroup,
}) => {
  return (
    <div className="client-group-company-list">
      <h3 className="client-group-entry-list-title">Companies</h3>
      <CompanyReferenceList
        resource={Constants.CLIENT_COMPANY_RESOURCE}
        basePath={Constants.COMPANY_DETAIL_PATH}
        hasEdit={false}
        hasCreate={false}
        hasList={true}
        hasShow={true}
        record={clientGroup}
      />
    </div>
  );
};

const CompanyReferenceList: React.FC<any> = (props) => {
  return (
    <ReferenceManyField
      {...props}
      reference={Constants.CLIENT_COMPANY_RESOURCE}
      target="client_group"
      perPage={5}
      pagination={<ListPagination />}
    >
      <Datagrid rowClick="expand" expand={<CompanyShow />}>
        <TextField source="name" label="Name" />
        <TextField source="clientId" label="Client ID" />
        <TextField source="chineseName" label="Chinese Name" />
        <TextField source="jurisdiction" label="Jurisdiction" />
        <TextField source="contactName" label="Contact Name" />
        <ShowButton />
      </Datagrid>
    </ReferenceManyField>
  );
};

const ListPagination: React.FC = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);
