import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AttachmentFileBrowser } from "../../AttachmentBrowser/AttachmentFileBrowser";

const useStyles = makeStyles({
  root: {
    borderRadius: 12,
    padding: 20,
  },
});

export const ClientPortalDataSafe: React.FC<RouteComponentProps<{
  id: string;
}>> = ({ match }) => {
  const id = match.params.id;
  const classes = useStyles({});

  return (
    <Card className={classes.root} raised={true}>
      <AttachmentFileBrowser
        canDirectUpload={false}
        canDownload={true}
        canModify={false}
        canSave={false}
        clientCompanyId={id}
      />
    </Card>
  );
};
