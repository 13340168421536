import moment from "moment";
import { IAmountToBill } from "../../../model/IAmountToBill";

export const dataCover = (data: IAmountToBill[]) => {
  const result = {};
  data.map((single: IAmountToBill) => {
    const key = `${single.type}-${single.typeId}`;
    const date = moment(`${single.year}-${single.month}`);
    if (!result[key]) {
      result[key] = {};
      if (single.type === "department" || single.type === "entity") {
        result[key].name = single.name;
        result[key].id = key;
      } else {
        result[key].firstName = single.firstName;
        result[key].lastName = single.lastName;
        result[key].id = key;
      }
    }
    result[key][date.format("YYYY-MM")] = Number(single.total);
  });
  return result;
};

export const getMonth = (data: IAmountToBill[]) => {
  const dateStart = moment(`${data[0].year}-${data[0].month}`);
  const dateEnd = moment(
    `${data[data.length - 1].year}-${data[data.length - 1].month}`
  );
  const timeValues = [];
  while (dateEnd > dateStart || dateStart.format("M") === dateEnd.format("M")) {
    timeValues.push(dateStart.format("YYYY-MM"));
    dateStart.add(1, "month");
  }
  return timeValues;
};
