export enum IEmailActionType {
  eicAccept = "eic_accept",
  eicReject = "eic_reject",
  picAccept = "pic_accept",
  picReject = "pic_reject",
}

export interface IEmailAction {
  action: IEmailActionType;
  resource?: string;
  id?: string;
}
