import { InputGroup } from "@blueprintjs/core";
import { push } from "connected-react-router";
import React from "react";
import { connect } from "react-redux";
import { Constants } from "../../constants";
import { Dispatch } from "../../types";

interface ISearchInputProps {
  onSearch: (query: string) => void;
}

interface ISearchInputState {
  query: string;
}

class SearchInputImpl extends React.Component<
  ISearchInputProps,
  ISearchInputState
> {
  public state: ISearchInputState = {
    query: "",
  };

  public render = () => {
    return (
      <InputGroup
        className="search-bar"
        leftIcon="search"
        placeholder="Search Jobs"
        type="search"
        onChange={this.handleChange}
        onKeyPress={this.handleKeyPress}
        value={this.state.query}
      />
    );
  };

  private handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const query = event.currentTarget.value;
    this.setState({ ...this.state, query });
  };

  private handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.props.onSearch(this.state.query);
    }
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onSearch: (query: string) => {
      dispatch(
        push(`${Constants.SEARCH_PATH}?query=${encodeURIComponent(query)}`)
      );
    },
  };
}

export const SearchInput = connect(null, mapDispatchToProps)(SearchInputImpl);
