import { Button, Card, Popover, Position } from "@blueprintjs/core";
import { Box } from "@material-ui/core";
import { useGetOne } from "ra-core";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Constants } from "../../constants";
import { IClientUser } from "../../model/IClientUserAuth";

interface ILoginLinkDialogBodyProps {
  clientUser: IClientUser;
}

export const LoginLinkDialogBody: React.FC<ILoginLinkDialogBodyProps> = ({
  clientUser,
}) => {
  const { id, firstName, lastName, email } = clientUser;
  const [url, setUrl] = useState(null);
  useGetOne(Constants.LOGIN_LINK_GENERATOR_RESOURCE, id, {
    onSuccess: ({ data }) => {
      setUrl(data.url);
    },
  });

  return (
    <Box className="login-link-dialog-body">
      {url ? (
        <Box>
          <Box className="login-link-dialog-info">
            Here is the invitation for <b>{email}</b>
          </Box>
          <Card className="login-link-email-content">
            <div>
              Dear {firstName},
              <br />
              <br />
              Here is your one-time login link to the client portal.
              <br />
              <br />
              <a className="login-link-url" href={url}>
                {url}
              </a>
              <br />
              <br />
              <br />
              Thanks,
              <br />
              EasyTrack.ai Team
            </div>
          </Card>
          <Box>
            <Popover position={Position.BOTTOM}>
              <CopyToClipboard text={url}>
                <Button icon="clipboard" alignText="right">
                  Copy Link
                </Button>
              </CopyToClipboard>
              {<div className="tooltip-message">Login Link Copied</div>}
            </Popover>
          </Box>
        </Box>
      ) : (
        <Box className="login-link-dialog-info">Generating login link ...</Box>
      )}
    </Box>
  );
};
