import { Button } from "@blueprintjs/core";
import { Menu, MenuItem } from "@material-ui/core";
import { push } from "connected-react-router";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { enabled } from "../../config";
import { Constants } from "../../constants";
import { IEtsState, ITask } from "../../model";

interface ICreateButtonProps {
  task: ITask;
}

export const CreateButton: React.FC<ICreateButtonProps> = ({ task }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const roles = useSelector((state: IEtsState) => state.auth.roles);

  const dispatch = useDispatch();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const query = `?source=${JSON.stringify({ taskId: task.id })}`;
  const handleInvoiceClick = () => {
    setAnchorEl(null);
    dispatch(push(`${Constants.INVOICE_PATH}/create${query}`));
  };
  const handleWriteOffClick = () => {
    setAnchorEl(null);
    dispatch(push(`${Constants.WRITE_OFF_PATH}/create${query}`));
  };

  const handleExpenseClick = () => {
    setAnchorEl(null);
    dispatch(push(`${Constants.EXPENSES_PATH}/create${query}`));
  };
  const handleAdjustmentClick = () => {
    setAnchorEl(null);
    dispatch(push(`${Constants.ADJUSTMENT_PATH}/create${query}`));
  };
  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Create
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {enabled("timebill.expense", roles) && (
          <MenuItem onClick={handleExpenseClick}>Expense</MenuItem>
        )}
        {enabled("timebill.invoice", roles) && (
          <MenuItem onClick={handleInvoiceClick} disabled={!task.picId}>
            Fee Note
          </MenuItem>
        )}
        {enabled("timebill.writeOff", roles) && (
          <MenuItem onClick={handleWriteOffClick} disabled={!task.picId}>
            Write-off
          </MenuItem>
        )}
        {enabled("timebill.adjustment", roles) && (
          <MenuItem onClick={handleAdjustmentClick}>Transfer</MenuItem>
        )}
      </Menu>
    </>
  );
};
