import { cond, eq, flatten, flow, identity, snakeCase } from "lodash/fp";
import * as pluralize from "pluralize";
import qs from "qs";
import { DataProvider, fetchUtils, Sort, UpdateResult } from "ra-core";
import { Constants } from "../constants";
import { authTokenKey, getClientToken, getToken, IAuthToken } from "../core";
import { IEtsUser } from "../model";

const fetchJson = (
  url: string,
  options: fetchUtils.Options = {},
  token = null,
  type: "app" | "clientPortal" = "app"
) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
    const t = token ?? type === "app" ? getToken() : getClientToken();
    if (t) {
      options.headers.set(authTokenKey.accessToken, t.accessToken);
      options.headers.set(authTokenKey.client, t.client);
      options.headers.set(authTokenKey.expiry, t.expiry);
      options.headers.set(authTokenKey.tokenType, t.tokenType);
      options.headers.set(authTokenKey.uid, t.uid);
    }
  }
  return fetchUtils.fetchJson(url, options);
};

const nestedRecources = {
  [Constants.ACCOUNT_BALANCE_RESOURCE]: "account_balance",
  [Constants.EXPENSE_BALANCE_RESOURCE]: "expense_balance",
  [Constants.EXPENSE_ENTRY_RESOURCE]: "expense_entries",
  [Constants.BALANCE_ENTRY_RESOURCE]: "balance_entries",
  [Constants.TIME_ENTRY_RESOURCE]: "time_entries",
  [Constants.ESTIMATED_TIME_ENTRY_RESOURCE]: "estimated_time_entries",
  [Constants.ACTUAL_RESOURCE]: "actual",
  [Constants.ESTIMATE_RESOURCE]: "estimates",
};

const makeApprovableAction = (action: string) => async (resource, params) => {
  const url = `/api/${resource}/${params.id}/${action}`;
  const { json } = await fetchJson(url, {
    method: "POST",
  });
  return { data: json };
};

export const toEntryResource = flow(
  cond([
    [eq("Transfer"), () => "Adjustment"],
    [() => true, identity],
  ]),
  pluralize.plural,
  snakeCase
);

const queryFormat: qs.IStringifyOptions = {
  arrayFormat: "brackets",
  strictNullHandling: true,
};

export const toFilter = (sort: Sort, filter: any) => {
  if (!!sort.field.length) {
    return {
      ...filter,
      sortedBy: `${snakeCase(sort.field)}_${sort.order.toLowerCase()}`,
    };
  }

  return filter;
};

export interface IEtsDataProvider extends DataProvider {
  submit: (resource: string, params: { id: string }) => Promise<UpdateResult>;
  eicAccept: (
    resource: string,
    params: { id: string }
  ) => Promise<UpdateResult>;
  eicReject: (
    resource: string,
    params: { id: string }
  ) => Promise<UpdateResult>;
  picAccept: (
    resource: string,
    params: { id: string }
  ) => Promise<UpdateResult>;
  picReject: (
    resource: string,
    params: { id: string }
  ) => Promise<UpdateResult>;
  validateToken: (params: IAuthToken) => Promise<IEtsUser>;
}

export const createDataProvider = (
  type: "app" | "clientPortal" = "app"
): IEtsDataProvider => {
  const api = type === "app" ? "api" : "client_api";
  return {
    getList: async (resource, params) => {
      const query = qs.stringify(
        {
          page: params.pagination.page,
          per_page: params.pagination.perPage,
          filter: toFilter(params.sort, params.filter),
        },
        queryFormat
      );
      const url = `/${api}/${resource}?${query}`;
      const { json, headers } = await fetchJson(url, {}, null, type);
      return { data: json, total: parseInt(headers.get("Total"), 10) };
    },
    getOne: async (resource: string, params) => {
      const url = `/${api}/${resource}/${params.id}`;
      const { json } = await fetchJson(url, {}, null, type);
      return { data: json };
    },
    getMany: async (resource, params) => {
      const query = qs.stringify(
        {
          filter: {
            id: params.ids,
          },
        },
        queryFormat
      );
      const url = `/${api}/${resource}?${query}`;
      const { json } = await fetchJson(url, {}, null, type);
      return { data: json };
    },
    getManyReference: async (resource, params) => {
      const query = qs.stringify(
        {
          page: params.pagination.page,
          per_page: params.pagination.perPage,
          filter: {
            ...toFilter(params.sort, params.filter),
            [`${pluralize.singular(params.target)}_id`]: params.id,
          },
        },
        queryFormat
      );
      let url = `/${api}/${resource}?${query}`;
      if (nestedRecources[resource]) {
        url = `/${api}/${params.target}/${params.id}/${nestedRecources[resource]}?${query}`;
      }
      const { json, headers } = await fetchJson(url, {}, null, type);
      const data = flatten([json]);
      return { data, total: parseInt(headers.get("Total"), 10) || data.length };
    },
    update: async (resource, params) => {
      const url = `/${api}/${resource}/${params.id}`;
      const { json } = await fetchJson(
        url,
        {
          method: "PUT",
          body: JSON.stringify(params.data),
        },
        null,
        type
      );
      return { data: json };
    },
    updateMany: async (resource, params) => {
      const ids = await Promise.all(
        params.ids.map(async (id) => {
          const url = `/${api}/${resource}/${id}`;
          await fetchJson(
            url,
            {
              method: "PUT",
              body: JSON.stringify(params.data),
            },
            null,
            type
          );
          return id;
        })
      );
      return { data: ids };
    },
    create: async (resource, params) => {
      const url = `/${api}/${resource}`;
      const { json } = await fetchJson(
        url,
        {
          method: "POST",
          body: JSON.stringify(params.data),
        },
        null,
        type
      );
      return { data: json };
    },
    delete: async (resource, params) => {
      const url = `/${api}/${resource}/${params.id}`;
      const { json } = await fetchJson(
        url,
        {
          method: "DELETE",
        },
        null,
        type
      );
      return { data: { id: params.id } };
    },
    deleteMany: async (resource, params) => {
      const ids = await Promise.all(
        params.ids.map(async (id) => {
          const url = `/${api}/${resource}/${id}`;
          await fetchJson(
            url,
            {
              method: "DELETE",
            },
            null,
            type
          );
          return id;
        })
      );
      return { data: ids };
    },
    submit: makeApprovableAction("submit"),
    eicAccept: makeApprovableAction("eic_accept"),
    eicReject: makeApprovableAction("eic_reject"),
    picAccept: makeApprovableAction("pic_accept"),
    picReject: makeApprovableAction("pic_reject"),
    validateToken: async (params: IAuthToken) => {
      const query = qs.stringify(params, queryFormat);
      const url = `/auth/validate_token?${query}`;

      const {
        json: { data },
      } = await fetchJson(url, {}, params, type);
      return data;
    },
    openAllNotifications: async () => {
      const url = `/${api}/notifications/open_all`;
      const { json: data } = await fetchJson(url, {
        method: "POST",
      });

      return { data };
    },
    openNotification: async (params) => {
      const url = `/${api}/notifications/${params.id}/open`;
      const { json: data } = await fetchJson(url, {
        method: "PUT",
      });

      return { data };
    },
    archiveNotifications: async (params) => {
      const url = `/${api}/notifications/archive_notifications`;
      const { json: data } = await fetchJson(url, {
        method: "PUT",
        body: JSON.stringify(params),
      });

      return { data };
    },
    archiveNotificationsByGroup: async (params) => {
      const url = `/${api}/notifications/archive_notifications_by_group_id`;
      const { json: data } = await fetchJson(url, {
        method: "PUT",
        body: JSON.stringify(params),
      });

      return { data };
    },
    createFollowerForFollowable: async (params) => {
      const url = `/${api}/follows/set_follower_status`;
      const { json: data } = await fetchJson(url, {
        method: "PUT",
        body: JSON.stringify(params),
      });

      return { data };
    },
    createStripeCustomer: async (params) => {
      const url = `/${api}/customer`;
      const { json: data } = await fetchJson(url, {
        method: "POST",
        body: JSON.stringify(params),
      });

      return { data };
    },
    createStripeSubscription: async (params) => {
      const url = `/${api}/subscription`;
      const { json: data } = await fetchJson(url, {
        method: "POST",
        body: JSON.stringify(params),
      });

      if (data.error) {
        throw { data };
      }

      return { data };
    },
    getTenantSubscriptionDetails: async () => {
      const url = `/${api}/subscriptions`;
      const { json: data } = await fetchJson(url, {
        method: "GET",
      });

      return { data };
    },
  };
};
