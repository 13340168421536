import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { useShowController } from "react-admin";

const useStyles = makeStyles({
  companyDetailShow: {
    marginBottom: 5,
    marginLeft: 40,
    marginTop: 5,
  },
});

export const CompanyShow: React.FC<any> = (props) => {
  const classes = useStyles({});
  const { record } = useShowController(props);
  const { status, companyType, registrationDate, address } = record;

  if (!record) {
    return null;
  }
  return (
    <Grid className={classes.companyDetailShow} container={true} spacing={2}>
      <Grid item={true} xs={2}>
        Status :
      </Grid>
      <Grid item={true} xs={10}>
        {status ? status : "-"}
      </Grid>
      <Grid item={true} xs={2}>
        Company Type :
      </Grid>
      <Grid item={true} xs={10}>
        {companyType ? companyType : "-"}
      </Grid>
      <Grid item={true} xs={2}>
        Registration Date :
      </Grid>
      <Grid item={true} xs={10}>
        {registrationDate ? registrationDate : "-"}
      </Grid>
      <Grid item={true} xs={2}>
        Address :
      </Grid>
      <Grid item={true} xs={10}>
        {address ? address : "-"}
      </Grid>
    </Grid>
  );
};
