import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { startCase } from "lodash";
import React from "react";

export const TimelineDateFilter = ({ datesList, onChange, filterState }) => {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (event, isExpanded) => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="filter-accordion"
    >
      <AccordionSummary
        aria-controls="panel1bh-content"
        id="filter-panel-header"
      >
        <span className="filter-accordion-header">Filter</span>
      </AccordionSummary>
      <AccordionDetails id="filter-options">
        {Object.keys(datesList).map((date) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterState[date]}
                  onChange={onChange}
                  name={date}
                  color="primary"
                />
              }
              label={startCase(date)}
              key={date}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
