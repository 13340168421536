import { Dialog, IDialogProps } from "@blueprintjs/core";
import * as React from "react";
import { IAttachmentEntry, IEmailAttachment } from "../../model";
import { AttachmentRename } from "../AttachmentBrowser/AttachmentRename";
import { AttachmentSave } from "../AttachmentBrowser/AttachmentSave";

interface IAttachmentSaveDialogProps extends IDialogProps {
  attachment: IAttachmentEntry | IEmailAttachment;
  isDuplicate: boolean;
  location: string;

  onSave: (fileName: string) => void;
  onOverride: (fileName: string) => void;
}

const stopPropagation = (event) => {
  event.stopPropagation();
};

export const AttachmentSaveDialog: React.FC<IAttachmentSaveDialogProps> = (
  props
) => {
  const { attachment, isDuplicate, location, onOverride, onSave } = props;

  return (
    <div className="save-dialog-wrapper" onClick={stopPropagation}>
      <Dialog {...props} icon="floppy-disk" title="Confirm">
        {isDuplicate ? (
          <AttachmentRename
            attachment={attachment}
            onSave={onSave}
            onOverride={onOverride}
          />
        ) : (
          <AttachmentSave
            attachment={attachment}
            location={location}
            onSave={onSave}
          />
        )}
      </Dialog>
    </div>
  );
};
