import { Button, Classes, Intent, Tag } from "@blueprintjs/core";
import React, { useState } from "react";
import { IAttachmentEntry, IEmailAttachment } from "../../model";

interface IAttachmentRenameProps {
  attachment: IAttachmentEntry | IEmailAttachment;

  onSave: (fileName: string) => void;
  onOverride: (fileName: string) => void;
}

export const AttachmentRename: React.FC<IAttachmentRenameProps> = ({
  attachment,
  onSave,
  onOverride,
}) => {
  const [newName, setNewName] = useState(attachment.fileName);
  const [isRenaming, setIsRenaming] = useState(false);

  const handleRename = () => {
    setIsRenaming(true);
  };

  const handleNewNameChange = (event) => {
    setNewName(event.target.value);
  };

  const handleCancelEdit = () => {
    setIsRenaming(false);
  };

  const handleSaveClick = () => {
    onSave(newName);
  };

  const handleOverrideClick = () => {
    onOverride(newName);
  };

  return (
    <>
      <div className={Classes.DIALOG_BODY}>
        <b>Target file with the same name exists !</b>
        <br />
        <p>
          Please rename file or confirming overriding file. Existing file will
          be deleted if overriden.
        </p>
        <br />
        {isRenaming ? (
          <div>
            <input
              type="text"
              value={newName}
              onChange={handleNewNameChange}
              onBlur={handleCancelEdit}
              autoFocus={true}
            />
          </div>
        ) : (
          <div>
            <Tag>{newName}</Tag>
          </div>
        )}
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        {newName === attachment.fileName ? (
          <Button
            intent={Intent.WARNING}
            text="Override"
            type="submit"
            className="attachment-share-dialog-footer"
            onClick={handleOverrideClick}
          />
        ) : (
          <Button
            intent={Intent.PRIMARY}
            text="Save"
            type="submit"
            className="attachment-share-dialog-footer"
            onClick={handleSaveClick}
          />
        )}
        {!isRenaming && (
          <Button
            intent={Intent.PRIMARY}
            text="Rename"
            type="submit"
            className="attachment-share-dialog-footer"
            onClick={handleRename}
          />
        )}
      </div>
    </>
  );
};
