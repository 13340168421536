import { makeStyles } from "@material-ui/core";
import moment from "moment";
import { useDataProvider } from "ra-core";
import React, { FC, useCallback, useState } from "react";
import {
  AutocompleteInput,
  CheckboxGroupInput,
  DateInput,
  ReferenceInput,
  SimpleForm,
} from "react-admin";
import { useSelector } from "react-redux";
import { reportAccess } from "../../config";
import { Constants } from "../../constants";
import { ReportRole } from "../../constants/reportRoles";
import { useReportParams } from "../../hooks/useReportParams";
import { IEtsState } from "../../model";
import { IFeeNoteLineItem } from "../../model/IFeeNoteLineItem";
import { endDateValidation, startDateValidation } from "../../utils/validation";
import { feeNoteLineIemExport } from "./exporter";

const useStyles = makeStyles({
  container: {
    margin: 30,
  },
});

const initialValues = {
  startDate: moment().startOf("M").toDate(),
  endDate: moment().endOf("D").toDate(),
  approvalStatus: ["approved"],
};

const choices = [
  { id: "approved", name: "Approved" },
  { id: "draft", name: "Draft" },
  { id: "need_eic_approval", name: "Need EIC Approval" },
  { id: "need_pic_approval", name: "Need PIC Approval" },
];

interface IFilter {
  startDate: Date;
  endDate: Date;
  approvalStatus: string[];
}
const filterClientCompany = (text) => ({ withName: text });

export const FeeNoteLineItemsForm: FC<any> = (props) => {
  const classes = useStyles(props);
  const [saving, setSaving] = useState(false);
  const dataProvider = useDataProvider();
  const roles = useSelector((state: IEtsState) => state.auth.roles);

  const handleSave = useCallback(async (value) => {
    const { data } = await dataProvider.getList(
      Constants.FEE_NOTE_LINE_ITEM_RESOURCE,
      {
        pagination: { page: 1, perPage: 10000 },
        sort: { field: "", order: "" },
        filter: value,
      }
    );

    feeNoteLineIemExport(data as IFeeNoteLineItem[]);

    setSaving(false);
  }, []);

  const filterParams = useReportParams(
    ReportRole.FEE_NOTES_LIST_DEPARTMENT,
    initialValues
  );

  return (
    <SimpleForm
      save={handleSave}
      saving={saving}
      resource={Constants.FEE_NOTE_LINE_ITEM_RESOURCE}
      className={classes.container}
      initialValues={filterParams}
    >
      <DateInput source="startDate" validate={[startDateValidation]} />
      <DateInput source="endDate" validate={[endDateValidation]} />
      <CheckboxGroupInput source="approvalStatus" choices={choices} />
      {reportAccess(roles, ReportRole.FEE_NOTES_LINE_ITEMS_TENANT) && (
        <ReferenceInput
          label="Department"
          source="departmentId"
          reference={Constants.DEPARTMENTS_RESOURCE}
          sort={{ field: "name", order: "ASC" }}
          filterToQuery={filterClientCompany}
          alwaysOn={true}
          allowEmpty={true}
          emptyText="Clear Input"
        >
          <AutocompleteInput source="name" />
        </ReferenceInput>
      )}
    </SimpleForm>
  );
};
