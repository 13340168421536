import React from "react";
import { ITaskAccountBalance } from "../../../model";
import { priceColor } from "../../../utils/color";
import { priceFormatter } from "../../../utils/money";

interface ITotalFieldProps {
  record?: ITaskAccountBalance;
  type: "wip" | "expense";
  label?: string;
  [k: string]: any;
}

export const TotalField: React.FC<ITotalFieldProps> = ({ record, type }) => {
  const total = record[`${type}Total`];
  return <span style={priceColor(total)}>{priceFormatter.format(total)}</span>;
};
