import { makeStyles } from "@material-ui/styles";
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  ReferenceField,
  TextField,
} from "ra-ui-materialui";
import React from "react";
import { useSelector } from "react-redux";
import { enabled } from "../../config/features";
import { Constants } from "../../constants";
import { IEtsState } from "../../model";
import { EmptyPage } from "../EmptyPage/EmptyPage";
import { AmountField } from "../fields";
import { FeeNoteNumberField } from "../Invoice/FeeNoteNumberField";
import { InvoiceFilter } from "../Invoice/InvoiceFilter";
import { ListActions } from "./PaymentAction";
import { paymentType } from "./paymentTypes";

interface IFullNameField {
  source: string;
  record?: { firstName: string; lastName: string };
  label?: string;
}

const useStyles = makeStyles({
  createdBy: { minWidth: 150 },
  amount: { minWidth: 100, textAlign: "right" },
});

const FullNameField: React.FC<IFullNameField> = ({ source, record, label }) => {
  return (
    <span>
      {record.firstName} {record.lastName}
    </span>
  );
};

const PaymentTypeField: React.FC<any> = ({ source, record }) => {
  const chosen = paymentType[record[source]];
  return <span>{chosen}</span>;
};

export const PaymentList: React.FC<any> = (props) => {
  const classes = useStyles({});
  const roles = useSelector((state: IEtsState) => state.auth.roles);

  const currentUserId = useSelector((state: IEtsState) => state.auth.user.id);

  const toAccountBalance = (record) => {
    const balanceEnabled =
      enabled("timebill.reports", roles) ||
      currentUserId === record.picId ||
      currentUserId === record.reviewerId;
    return (
      balanceEnabled && `${Constants.ACCOUNT_BALANCE_PATH}/${record.taskId}`
    );
  };

  const toInvoice = (record) => {
    const canAccess = enabled("timebill.invoice", roles);

    return canAccess && `${Constants.INVOICE_PATH}/${record.invoiceId}`;
  };

  return (
    <List
      {...props}
      style={{ margin: 10 }}
      bulkActionButtons={false}
      sort={{ field: "date", order: "DESC" }}
      empty={<EmptyPage {...props} />}
      filters={
        <InvoiceFilter includeDefaultFilter="invoiceId" feeNoteSearch={true} />
      }
      hasCreate={false}
      actions={<ListActions />}
    >
      <Datagrid>
        <DateField source="date" label="Date" />
        <ReferenceField
          label="Company"
          source="clientCompanyId"
          reference="client_companies"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Job"
          source="taskId"
          reference="tasks"
          link={toAccountBalance}
        >
          <TextField source="description" />
        </ReferenceField>
        <ReferenceField
          label="Created By"
          source="createdById"
          reference="users"
          link={false}
          cellClassName={classes.createdBy}
        >
          <FullNameField source="firstName" />
        </ReferenceField>
        <ReferenceField
          label="Fee Note #"
          source="invoiceId"
          reference="invoices"
          link={toInvoice}
        >
          <FeeNoteNumberField
            source="feeNoteNumber"
            cellClassName={classes.amount}
          />
        </ReferenceField>
        <PaymentTypeField label="Payment Type" source="paymentType" />
        <AmountField
          source="wipAmount"
          cellClassName={classes.amount}
          headerClassName={classes.amount}
        />
        <AmountField
          source="expenseAmount"
          cellClassName={classes.amount}
          headerClassName={classes.amount}
        />
        <EditButton label="Edit" />
      </Datagrid>
    </List>
  );
};
