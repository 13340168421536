import { TimePicker } from "@blueprintjs/datetime";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { IEtsState } from "../../model";
import { DurationTimeInput } from "./DurationTimeInput";

interface IDurationInputProps {
  duration: string;
  onChange: (duration: string) => void;
  disabled?: boolean;
  className?: string;
}

const DurationTimePicker = ({
  className,
  duration,
  onChange,
  disabled,
}: IDurationInputProps) => {
  const value = moment().startOf("d").add(moment.duration(duration)).toDate();
  const handleOnChange = (newTime: Date) => {
    const mT = moment(newTime);
    onChange(
      moment
        .duration({
          hours: mT.hours(),
          minutes: mT.minutes(),
          seconds: mT.seconds(),
        })
        .toISOString()
    );
  };

  return (
    <TimePicker
      className={className}
      value={value}
      onChange={handleOnChange}
      disabled={disabled}
    />
  );
};

export const DurationInput = (props: IDurationInputProps) => {
  const isDaily = useSelector(
    (state: IEtsState) => state.departmentSetting.timebillDaily
  );
  if (isDaily) {
    return <DurationTimeInput {...props} max={1} />;
  }

  return <DurationTimePicker {...props} />;
};
