import { Box, Divider, Grid } from "@material-ui/core";
import { ReferenceField } from "ra-ui-materialui";
import React from "react";
import { NavLink } from "react-router-dom";
import { EditableResourceField } from "../../components/cells/EditableResourceField";
import { Constants } from "../../constants";
import { IImportantPerson, INaturalPerson } from "../../model";
import * as DateUtils from "../../utils/date";

interface IClientCompanyImportantPersonProps {
  importantPerson: IImportantPerson;
}

interface IImportantPersonNameProps {
  record?: INaturalPerson;
}

const ImportantPersonName = ({ record }: IImportantPersonNameProps) => (
  <NavLink
    to={`${Constants.CLIENTGROUP_DETAIL_PATH}/${record.clientGroupId}/natural_person`}
  >
    {record.name}
  </NavLink>
);

export const ClientCompanyImportantPerson: React.FC<IClientCompanyImportantPersonProps> = ({
  importantPerson,
}) => {
  const { id, title, notes, updatedAt } = importantPerson;
  return (
    <>
      <Divider />
      <div className="important-person-fields">
        <ReferenceField
          resource={Constants.IMPORTANT_PERSON_RESOURCE}
          reference={Constants.NATURAL_PERSON_RESOURCE}
          source="naturalPersonId"
          basePath={Constants.COMPANY_DETAIL_PATH}
          record={importantPerson}
          link={false}
        >
          <ImportantPersonName />
        </ReferenceField>
        <Box
          component="div"
          display="flex"
          justifyContent="flex-start"
          className="important-person-field"
        >
          <Grid item={true} xs={2}>
            Title:
          </Grid>
          <Grid item={true} xs={10}>
            <EditableResourceField
              resourceId={id}
              resourceName={Constants.IMPORTANT_PERSON_RESOURCE}
              fieldValue={title}
              fieldName="title"
              multiline={false}
            />
          </Grid>
        </Box>
        <Box
          component="div"
          display="flex"
          justifyContent="flex-start"
          className="important-person-field"
        >
          <Grid item={true} xs={2}>
            Notes:
          </Grid>
          <Grid item={true} xs={10}>
            <EditableResourceField
              resourceId={id}
              resourceName={Constants.IMPORTANT_PERSON_RESOURCE}
              fieldValue={notes}
              fieldName="notes"
              multiline={true}
            />
          </Grid>
        </Box>
        <Box
          component="div"
          display="flex"
          justifyContent="flex-start"
          className="important-person-field"
        >
          <Grid item={true} xs={2}>
            Last updated:
          </Grid>
          <Grid item={true} xs={10}>
            {DateUtils.formatFriendlyDate(DateUtils.parseDate(updatedAt))}
          </Grid>
        </Box>
      </div>
    </>
  );
};
