import { useSelector } from "react-redux";
import { reportDefaultFilter } from "../config";
import { IEtsState } from "../model";

export const useReportParams = (role, initialValues) => {
  return useSelector((state: IEtsState) => {
    const departmentId = reportDefaultFilter(
      state.auth.roles,
      role,
      state.auth.departmentId
    );
    return { ...initialValues, ...departmentId };
  });
};
