import * as React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import { Auth } from "./core";
import { App } from "./layouts/App";
import { ClientPortalApp } from "./layouts/ClientPortal";

export interface IEtsProps {
  dataProvider?: any;
  clientPortalDataProvider?: any;
}

export class Ets extends React.Component<IEtsProps> {
  private static renderAuth(props: RouteComponentProps<any>): JSX.Element {
    return <Auth {...props} />;
  }

  constructor(props: IEtsProps) {
    super(props);
    this.renderApp = this.renderApp.bind(this);
    this.renderClientPortal = this.renderClientPortal.bind(this);
  }

  public render(): JSX.Element {
    return (
      <div>
        <Switch>
          <Redirect exact={true} from="/" to="/app" />
          <Route path="/app" render={this.renderApp} />
          <Route path="/auth" render={Ets.renderAuth} />
          <Route path="/client_app" render={this.renderClientPortal} />
        </Switch>
      </div>
    );
  }

  private renderApp(props: RouteComponentProps<any>): JSX.Element {
    return <App {...this.props} {...props} />;
  }

  private renderClientPortal(props: RouteComponentProps<any>): JSX.Element {
    return <ClientPortalApp {...this.props} {...props} />;
  }
}
