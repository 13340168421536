import { useUpdate } from "ra-core";
import { Edit, ReferenceField, SimpleForm } from "ra-ui-materialui";
import React from "react";
import { useFormState } from "react-final-form";
import { Constants } from "../../constants";
import { ICreditNote } from "../../model";
import { EditToolBar } from "../EditToolbar";
import { ClientCompanyField, TaskField } from "../fields";
import { ApprovalStatusEdit } from "../WriteOff/CustomField/ApprovalStatusEdit";
import { CreditNoteEditInput } from "./CreditNoteEditInput";
import { FeeNoteField } from "./CustomField/FeeNoteField";

export const CreditNoteEdit: React.FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<CreditNoteToolbar />}>
        <ReferenceField
          reference={Constants.TASK_RESOURCE}
          source="taskId"
          addLabel={false}
          link={false}
        >
          <TaskField />
        </ReferenceField>
        <ReferenceField
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          addLabel={false}
          link={false}
        >
          <ClientCompanyField />
        </ReferenceField>
        <ReferenceField
          source="invoiceId"
          reference={Constants.INVOICE_RESOURCE}
          addLabel={false}
          link={false}
        >
          <FeeNoteField />
        </ReferenceField>
        <ApprovalStatusEdit />
        <CreditNoteEditInput />
      </SimpleForm>
    </Edit>
  );
};

const CreditNoteToolbar = (props) => {
  const { record, basePath } = props;
  const formState = useFormState<Partial<ICreditNote>>();

  const [handleSubmit] = useUpdate(
    Constants.CREDIT_NOTE_RESOURCE,
    record.id,
    formState.values,
    record
  );

  return <EditToolBar {...props} onClick={handleSubmit} showSave={true} />;
};
