import { FormGroup as BPFormGroup, Intent } from "@blueprintjs/core";
import { FieldProps } from "formik";
import * as React from "react";
import { isNullOrUndefined } from "util";

export interface IFormGroupProps extends FieldProps<any> {
  label;
}

export class FormGroup extends React.Component<IFormGroupProps> {
  public render() {
    const { label, field, form } = this.props;
    const name = field.name;
    const error =
      form.submitCount > 0 ? this.getError(name, form.errors) : undefined;

    return (
      <BPFormGroup
        label={label}
        helperText={error}
        intent={isNullOrUndefined(error) ? null : Intent.DANGER}
      >
        {this.props.children}
      </BPFormGroup>
    );
  }

  private getError(name: string, errors) {
    const tokens = name.split(".");
    let result = errors;

    for (const token of tokens) {
      if (isNullOrUndefined(result[token])) {
        return;
      } else {
        result = result[token];
      }
    }

    return result;
  }
}
