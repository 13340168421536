import { Button, Classes, FormGroup, Intent } from "@blueprintjs/core";
import React, { useEffect, useReducer } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { useSelector } from "react-redux";
import { Api } from "../../actions";
import {
  IModelFormControllerComponentProps,
  ModelFormErrors,
  withModelFormController,
} from "../../core";
import { IComment, IEtsState } from "../../model";
import { canCreateComments } from "../../selectors";
import { MentionsSuggestion } from "./MentionsSuggestion";

interface IUserData {
  id: string;
  display: string;
}

interface ICommentFormOwnProps {
  entryType: "Task" | "ClientGroup" | "ClientCompany";
  entryId: string;
  afterSubmit: (comment: IComment) => void;
}

interface ICommentFormState {
  errors: ModelFormErrors<IComment>;
  showErrors: boolean;
  saveDisabled: boolean;
}

interface ICommentFormProps
  extends ICommentFormOwnProps,
    IModelFormControllerComponentProps<IComment> {}

type IAction =
  | { type: "VALIDATE_MODEL"; payload: ModelFormErrors<IComment> }
  | { type: "SUBMIT_ON_ERRORS"; payload: ModelFormErrors<IComment> }
  | { type: "SUBMIT_WITHOUT_ERRORS" }
  | { type: "ENABLE_SAVE" }
  | { type: "DISABLE_SAVE" };

const reducer = (
  state: ICommentFormState,
  action: IAction
): ICommentFormState => {
  switch (action.type) {
    case "VALIDATE_MODEL":
      const errors = action.payload;
      return {
        ...state,
        errors,
        saveDisabled: Object.keys(errors).length !== 0,
      };
    case "SUBMIT_ON_ERRORS":
      return {
        ...state,
        errors: action.payload,
        saveDisabled: true,
        showErrors: true,
      };
    case "SUBMIT_WITHOUT_ERRORS":
      return {
        ...state,
        errors: {},
        saveDisabled: false,
        showErrors: false,
      };
    case "ENABLE_SAVE":
      return { ...state, saveDisabled: false };
    case "DISABLE_SAVE":
      return { ...state, saveDisabled: true };
    default:
      return state;
  }
};

const stopPropagation = (event) => {
  event.preventDefault();
  event.stopPropagation();
};

const validate = (comment: Partial<IComment>) => {
  const errors: ModelFormErrors<IComment> = {};
  if (!comment.content) {
    errors.content = "Comment cannot be blank";
  }
  return errors;
};

const showErrorIntent = (showErrors, errors) => {
  const { content: error = "" } = errors;
  if (showErrors && error) {
    return Intent.DANGER;
  }

  return null;
};

const CommentFormImpl = ({
  entryId,
  entryType,
  model,
  onFormReset,
  onModelDetailsUpdate,
  afterSubmit,
  onSubmit,
}: ICommentFormProps) => {
  const { metaData: { markup = "" } = {} } = model;

  const [{ errors, showErrors, saveDisabled }, dispatch] = useReducer(reducer, {
    errors: validate(model),
    showErrors: false,
    saveDisabled: false,
  });

  useEffect(() => {
    onModelDetailsUpdate({ entryId, entryType });
  }, []);

  useEffect(() => {
    const modelErrors = validate(model);
    if (showErrors) {
      dispatch({ type: "VALIDATE_MODEL", payload: modelErrors });
    }
  }, [model]);

  const { enableCommentCreation, users } = useSelector(
    (storeState: IEtsState) => {
      return {
        enableCommentCreation: canCreateComments(storeState),
        users: Object.keys(storeState.usersById).map((key) => ({
          id: key,
          display: `${storeState.usersById[key].firstName} ${storeState.usersById[key].lastName}`,
        })),
      };
    }
  );

  const displayTransform = (id, display) => `@${display}`;

  const handleChange = (event, newValue, newPlainTextValue, mentions) => {
    const update = {
      entryId,
      entryType,
      content: newPlainTextValue,
      metaData: { mentions, markup: newValue },
    };
    onModelDetailsUpdate(update);
  };

  const onSubmitComment = (event) => {
    event.stopPropagation();
    const modelErrors = validate(model);
    if (Object.keys(modelErrors).length > 0) {
      dispatch({ type: "SUBMIT_ON_ERRORS", payload: modelErrors });
      return;
    }
    dispatch({ type: "DISABLE_SAVE" });
    return onSubmit().then(
      (comment) => {
        onFormReset();
        afterSubmit(comment);
        dispatch({ type: "SUBMIT_WITHOUT_ERRORS" });
        return;
      },
      () => {
        dispatch({ type: "ENABLE_SAVE" });
      }
    );
  };

  const showError = (error: string) => {
    if (showErrors && error) {
      return error;
    }

    return null;
  };

  return (
    <div>
      <div
        onClick={stopPropagation}
        onDragOverCapture={stopPropagation}
        onDrop={stopPropagation}
        onDragEnterCapture={stopPropagation}
      >
        <FormGroup
          helperText={showError(errors.content)}
          intent={showErrorIntent(showErrors, errors)}
        >
          <MentionsInput
            value={markup}
            onChange={handleChange}
            disabled={!enableCommentCreation}
            className="mentions"
          >
            <Mention
              trigger="@"
              data={users}
              renderSuggestion={MentionsSuggestion}
              displayTransform={displayTransform}
              className="mentions__mention"
            />
          </MentionsInput>
        </FormGroup>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button
            intent={Intent.PRIMARY}
            text="Save"
            onClick={onSubmitComment}
            disabled={saveDisabled || !enableCommentCreation}
          />
        </div>
      </div>
    </div>
  );
};

export const CommentForm = withModelFormController<
  ICommentFormProps,
  IComment,
  ICommentFormOwnProps
>(CommentFormImpl, Api.Comment);
