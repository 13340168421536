import { Tooltip } from "@material-ui/core";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { ITask } from "../../model";
import {
  calculateActualFee,
  calculateEstimatedTimeCost,
} from "../../selectors";
import { convertAmount } from "../../utils/money";
import { calculatePercentage } from "../../utils/numberFormat";

interface IEstimatedTimeEntryTotalProps {
  task: ITask;
}

export const EstimatedTimeEntryTotal: FC<IEstimatedTimeEntryTotalProps> = ({
  task,
}) => {
  const total = useSelector(calculateEstimatedTimeCost);
  const actualTotal = useSelector(calculateActualFee);
  const estimatedRecoverabilityRate = calculatePercentage(
    task.estimatedFee,
    total
  );
  const actualRecoverabilityRate = calculatePercentage(
    task.estimatedFee,
    actualTotal
  );

  return (
    <div className="estimated-time-entry-total">
      <div className="fill-component" />
      <div className="estimated-fee-total">
        <span>{convertAmount(total)}</span>
        <Tooltip title="Bud. Recoverability Rate">
          <span>{estimatedRecoverabilityRate}</span>
        </Tooltip>
      </div>
      <div className="estimated-fee-total-actual">
        <span> {convertAmount(actualTotal)}</span>
        <Tooltip title="Actual Recoverability Rate">
          <span> {actualRecoverabilityRate}</span>
        </Tooltip>
      </div>
      <div className="variance-total">
        <span>{convertAmount(actualTotal - total)}</span>
      </div>
      <div className="button-fill" />
    </div>
  );
};
