import { Card } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { FormattedMessage } from "react-intl";
import { IClientCompany } from "../../../model";
import { ClientPortalCompanySelect } from "./ClientPortalCompanySelect";

interface IClientPortalIndexProps {
  companies: IClientCompany[];
  selectedCompany: IClientCompany;
}

const useStyles = makeStyles({
  root: {
    borderRadius: 12,
    padding: 20,
  },
});

export const ClientPortalIndex: React.FC<IClientPortalIndexProps> = ({
  companies,
  selectedCompany,
}) => {
  const classes = useStyles({});

  return (
    <Card className={classes.root} raised={true}>
      <div>
        <FormattedMessage
          description="Choose company card header"
          defaultMessage="Choose Company"
        />
      </div>
      {!selectedCompany && (
        <ClientPortalCompanySelect
          companies={companies}
          selectedCompany={selectedCompany}
        />
      )}
    </Card>
  );
};
