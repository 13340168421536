import { Button, Icon } from "@blueprintjs/core";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useGetManyReference, useReference } from "ra-core";
import * as React from "react";
import { ReferenceField } from "react-admin";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Constants } from "../../constants";
import {
  IAccountBalance,
  IActual,
  IEstimates,
  IEtsState,
  IStatus,
  ITask,
} from "../../model";
import { priceColor } from "../../utils/color";
import { priceFormatter } from "../../utils/money";
import { formatPercentage } from "../../utils/numberFormat";
import { UserField } from "../fields";

const ClientCompanyField: React.FC<any> = ({ record }) => {
  return <Typography>{record.name}</Typography>;
};

const TaskTypeField: React.FC<any> = ({ record }) => {
  return <Typography>Job Type: {record.displayName}</Typography>;
};

const useStyle = makeStyles({
  description: {
    fontSize: "16px",
    fontFamily: "'Roboto', 'Helvetica','Arial','sans - serif'",
  },
  topBox: {
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
    borderTop: "none",
    fontSize: "13px",
  },
  bottomBox: {
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
    borderTop: "none",
    fontSize: "16px",
  },
  grid: {
    padding: "5px",
  },
});

export interface ITaskMainInfoProps {
  task: ITask;
  accountBalance: IAccountBalance;
}

const TaskMainInfo: React.FC<ITaskMainInfoProps> = ({
  task,
  accountBalance,
}) => {
  const currentUser = useSelector(
    (state: IEtsState) => state.usersById[state.auth.user.id]
  );
  const classes = useStyle({});

  const { data, loaded } = useGetManyReference(
    Constants.ACTUAL_RESOURCE,
    Constants.TASK_RESOURCE,
    task.id,
    { page: 1, perPage: 1 },
    { field: "", order: "" },
    {},
    Constants.TASK_RESOURCE
  );

  const { data: estimatesData, loaded: estimatesLoaded } = useGetManyReference(
    Constants.ESTIMATE_RESOURCE,
    Constants.TASK_RESOURCE,
    task.id,
    { page: 1, perPage: 1 },
    { field: "", order: "" },
    {},
    Constants.TASK_RESOURCE
  );

  const actual = data?.[task.id] as IActual;
  const estimates = estimatesData as IEstimates | null;
  return (
    <>
      <Grid item={true} xs={12} className={classes.grid}>
        <ReferenceField
          resource={Constants.TASK_RESOURCE}
          reference={Constants.CLIENT_COMPANY_RESOURCE}
          source="clientCompanyId"
          basePath="/tasks"
          record={task}
          link={false}
        >
          <ClientCompanyField />
        </ReferenceField>
      </Grid>
      <Grid item={true} xs={12} className={classes.grid}>
        {task.code && <Typography>Job Code: {task.code}</Typography>}
      </Grid>
      <Grid item={true} xs={12} className={classes.grid}>
        <ReferenceField
          resource={Constants.TASK_RESOURCE}
          reference={Constants.TASK_TYPE_RESOURCE}
          source="taskTypeId"
          basePath="/tasks"
          record={task}
          link={false}
        >
          <TaskTypeField />
        </ReferenceField>
      </Grid>
      <Grid item={true} xs={12} className={classes.grid}>
        {task.departmentId === currentUser.department.id ? (
          <Link
            to={`${Constants.TASK_PATH}/${task.id}`}
            className={classes.description}
          >
            {task.description}
          </Link>
        ) : (
          task.description
        )}
      </Grid>
      <Grid item={true} xs={10} className={classes.grid}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right" className={classes.topBox}>
                Budgeted Fee
              </TableCell>
              <TableCell align="right" className={classes.topBox}>
                Actual WIP
              </TableCell>
              <TableCell align="right" className={classes.topBox}>
                Actual Recoverability Rate
              </TableCell>
              <TableCell align="right" className={classes.topBox}>
                WIP Billed to Date
              </TableCell>
              <TableCell align="right" className={classes.topBox}>
                WIP Paid to Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="right" className={classes.bottomBox}>
                {priceFormatter.format(Number(accountBalance.estimatedFee))}
              </TableCell>
              <TableCell align="right" className={classes.bottomBox}>
                {actual
                  ? priceFormatter.format(Number(actual.actualWip))
                  : "N/A"}
              </TableCell>
              <TableCell align="right" className={classes.bottomBox}>
                {actual
                  ? formatPercentage(actual.actualRecoverabilityRate)
                  : "0%"}
              </TableCell>
              <TableCell align="right" className={classes.bottomBox}>
                {priceFormatter.format(Number(accountBalance.billedToDate))}
              </TableCell>
              <TableCell align="right" className={classes.bottomBox}>
                {priceFormatter.format(Number(accountBalance.paidToDate))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </>
  );
};

export interface IBalanceInfoProps {
  accountBalance: IAccountBalance;
}

const BalanceInfo: React.FC<IBalanceInfoProps> = ({ accountBalance }) => {
  const balance = accountBalance
    ? parseFloat(accountBalance.accountBalance)
    : 0;
  const expense = accountBalance
    ? parseFloat(accountBalance.expenseBalance)
    : 0;
  const provisional = accountBalance
    ? parseFloat(accountBalance.provisionalBalance)
    : 0;
  const expenseProvisional = accountBalance
    ? parseFloat(accountBalance.expenseProvisionalBalance)
    : 0;

  return (
    <Grid xs={12} item={true} container={true}>
      <Grid xs={12} item={true}>
        <Box display="flex" justifyContent="space-between">
          <Typography>Outstanding Balance:</Typography>
          <Typography>
            <span style={priceColor(balance)}>
              {priceFormatter.format(balance)}
            </span>
          </Typography>
        </Box>
      </Grid>
      <Grid xs={12} item={true}>
        <Box display="flex" justifyContent="space-between">
          <Typography>Provisional Balance:</Typography>
          <Typography>
            <span style={priceColor(provisional)}>
              {priceFormatter.format(provisional)}
            </span>
          </Typography>
        </Box>
      </Grid>
      <Grid xs={12} item={true}>
        <Box display="flex" justifyContent="space-between">
          <Typography>Expense Balance:</Typography>
          <Typography>
            <span style={priceColor(expense)}>
              {priceFormatter.format(expense)}
            </span>
          </Typography>
        </Box>
      </Grid>
      <Grid xs={12} item={true}>
        <Box display="flex" justifyContent="space-between">
          <Typography>Expense Provisional Balance:</Typography>
          <Typography>
            <span style={priceColor(expenseProvisional)}>
              {priceFormatter.format(expenseProvisional)}
            </span>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

interface ITaskSecondaryInfoProps {
  task: ITask;
}

const TaskSecondaryInfo: React.FC<ITaskSecondaryInfoProps> = ({ task }) => {
  const { loaded, referenceRecord: status } = useReference({
    reference: Constants.STATUS_RESOURCE,
    id: task.statusId,
  });

  if (!loaded) {
    return null;
  }
  const renderPic = () => (
    <Grid item={true}>
      <UserField userId={task.picId} />
    </Grid>
  );

  const renderNoPic = () => {
    const title = "PIC undefined";
    return (
      <Tooltip title={title}>
        <Icon icon="error" iconSize={40} />
      </Tooltip>
    );
  };

  return (
    <Grid item={true} xs={12}>
      <Grid container={true} justify="flex-end" alignItems="center" spacing={2}>
        <Grid item={true}>
          <UserField userId={task.reviewerId} />
        </Grid>
        {task.picId ? renderPic() : renderNoPic()}
        <Grid item={true}>
          <Button disabled={true}>{(status as IStatus).name}</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export interface IAccountBalanceHeaderProps {
  taskId: string;
}

export const AccountBalanceHeader: React.FC<IAccountBalanceHeaderProps> = ({
  taskId,
}) => {
  const { loaded: taskLoaded, referenceRecord } = useReference({
    reference: Constants.TASK_RESOURCE,
    id: taskId,
  });
  const task = referenceRecord as ITask;
  const { data, loaded: accountBalanceLoaded } = useGetManyReference(
    Constants.ACCOUNT_BALANCE_RESOURCE,
    Constants.TASK_RESOURCE,
    taskId,
    { page: 1, perPage: 1 },
    { field: "", order: "" },
    {},
    Constants.TASK_RESOURCE
  );

  if (!taskLoaded || !accountBalanceLoaded) {
    return null;
  }

  const accountBalance = data[taskId];

  return (
    <Grid container={true}>
      <Grid xs={8} container={true} item={true}>
        <TaskMainInfo task={task} accountBalance={accountBalance} />
      </Grid>
      <Grid xs={4} container={true} item={true}>
        <TaskSecondaryInfo task={task} />
        <BalanceInfo accountBalance={accountBalance} />
      </Grid>
    </Grid>
  );
};
