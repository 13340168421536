import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { ConnectedTaskTable } from "../../components";
import { IEtsState, ITask } from "../../model";
import * as Utils from "../../utils/sort";
import * as TaskUtil from "../../utils/task";

interface IWeekProps {
  tasks: ITask[];
}

export class WeekImpl extends React.Component<IWeekProps> {
  public render(): JSX.Element {
    return (
      <div className="week">
        <ConnectedTaskTable
          completedTasksFilter={TaskUtil.filterTasksThisMonthDueDate}
          tasks={this.props.tasks}
        />
      </div>
    );
  }
}

function mapStateToProps(state: IEtsState): IWeekProps {
  const tasks = state.tasks.filter(
    (task) =>
      task.assigneeId === state.auth.user.id &&
      moment(task.dueDate).toDate() <= moment().add(7, "days").toDate()
  );

  tasks.sort(Utils.sortByDueAtAsc);
  return {
    tasks,
  };
}

export const Week = connect(mapStateToProps)(WeekImpl);
