import { Box } from "@material-ui/core";
import moment from "moment";
import "moment-duration-format";
import React from "react";
import { useSelector } from "react-redux";
import { IEtsState, ITimeEntry } from "../../model";
import * as Utils from "../../utils/date";

interface ITimeEntryTotalProps {
  timeEntries: ITimeEntry[];
}

const getTotalDuration = (timeEntries: ITimeEntry[]) => {
  const totalDuration = timeEntries.reduce((total, entry) => {
    total = Utils.sumOfDuration(total, entry.duration);
    return total;
  }, "PT0H0M");
  const duration = moment.duration(totalDuration);
  const [hours, minutes] = moment.duration(duration).format("h:mm").split(":");
  return { hours, minutes, days: duration.asDays() };
};

export const TimeEntryTotal = ({ timeEntries }: ITimeEntryTotalProps) => {
  const isDaily = useSelector(
    (state: IEtsState) => state.departmentSetting.timebillDaily
  );
  const durationObject = getTotalDuration(timeEntries);
  return (
    <div className="time-entry-record">
      <div className="time-entry-record-start-date-time" />
      <div className="time-entry-record-created-by" />
      <div className="time-entry-record-subtask-description" />
      <div className="time-entry-record-description" />
      <div className="time-entry-record-chargeable" />
      <div className="time-entry-record-duration">
        <div className="timesheet-duration-time-box-total">
          {isDaily ? (
            <Box display="flex">
              <span className="timesheet-duration-time-box-timer">
                {durationObject.days}
              </span>
            </Box>
          ) : (
            <Box display="flex">
              <span className="timesheet-duration-time-box-timer">
                {durationObject.hours}
              </span>
              <span className="timesheet-duration-time-box-timer-colon">:</span>
              <span className="timesheet-duration-time-box-timer">
                {durationObject.minutes}
              </span>
            </Box>
          )}
        </div>
      </div>
      <div className="time-entry-record-timesheet-status" />
    </div>
  );
};
