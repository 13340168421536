import { FormLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { Link } from "react-router-dom";
import { Constants } from "../../../constants";
import { IInvoice } from "../../../model";
import { FeeNoteNumberField } from "../../Invoice/FeeNoteNumberField";

export interface IFeeNoteFieldProps {
  record?: IInvoice;
}
const useStyles = makeStyles(() => ({
  label: {
    marginTop: 16,
  },
}));

export const FeeNoteField: React.FC<IFeeNoteFieldProps> = ({ record }) => {
  const classes = useStyles({});
  return (
    <>
      <FormLabel component="legend" className={classes.label}>
        Fee Note Number
      </FormLabel>
      <Link to={`${Constants.INVOICE_PATH}/${record.id}`}>
        <FeeNoteNumberField record={record} source="feeNoteNumber" />
      </Link>
    </>
  );
};
