import { adminSaga, AuthProvider, DataProvider } from "ra-core";
import { all, fork } from "redux-saga/effects";
import { bankStatementConversionSaga } from "./bankStatementConversionSaga";
import { emailActionSaga } from "./emailActionSaga";
import { invoiceSaga } from "./invoiceSaga";

export const rootSaga = (dataProvider: DataProvider, auth: AuthProvider) => {
  function* saga() {
    yield all(
      [
        adminSaga(dataProvider, auth),
        invoiceSaga,
        bankStatementConversionSaga,
        emailActionSaga,
      ].map(fork)
    );
  }

  return saga;
};
