import moment from "moment";
import { IFormGroupResponse } from "../../../model/IFormGroupResponse";
import { IUser } from "../../app/types/index";
import { IAuthToken } from "../actions";
import { get } from "./get";

export const TOKEN_KEY = "token";
export const DEPARTMENT_ID_KEY = "department_id";
export const USER_KEY = "user";
export const REDIRECT_PATH_KEY = "redirect_path";
export const CLIENT_REDIRECT_PATH_KEY = "client_redirect_path";
export const ROLES_DEPARTMENT_KEY = "roles_and_department_id";
export const CLIENT_USER_KEY = "client_user";
export const CLIENT_USER_TOKEN = "client_user_token";
export const CLIENT_FORM_GROUP_RESPONSE = "client_form_group_response";

// TODO: Default value of 1 here, represents the 1 day value placed in the Django server code
// for auth token expiry. Would be great to not define this in both places...
export function setUser(user: IUser, expires: number = 1) {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
  set(USER_KEY, user, expires);
}

export function setClientUser(clientUser: IUser, expires: number = 1) {
  set(CLIENT_USER_KEY, clientUser, expires);
}

export function getUser(): IUser {
  return get(USER_KEY) as IUser;
}

export function getClientUser(): IUser {
  return get(CLIENT_USER_KEY) as IUser;
}

export function removeUser() {
  remove(USER_KEY);
}

export function removeClientUser() {
  remove(CLIENT_USER_KEY);
}

export function removeRolesAndDepartment() {
  remove(ROLES_DEPARTMENT_KEY);
}

export function setRolesAndDepartmentId(roles: string[], departmentId: string) {
  set(ROLES_DEPARTMENT_KEY, { roles, departmentId });
}

export function setToken(authToken: IAuthToken, expires: number = 1) {
  set(TOKEN_KEY, authToken, expires);
}

export function setClientUserToken(authToken: IAuthToken, expires: number = 1) {
  set(CLIENT_USER_TOKEN, authToken, expires);
}

export function getToken(): IAuthToken {
  return get(TOKEN_KEY);
}
export function getClientToken(): IAuthToken {
  return get(CLIENT_USER_TOKEN);
}

export function getRoles() {
  return get(ROLES_DEPARTMENT_KEY)?.roles;
}

export function getDepartmentId() {
  return get(ROLES_DEPARTMENT_KEY)?.departmentId;
}

export function removeToken() {
  remove(TOKEN_KEY);
}

export function removeClientUserToken() {
  remove(CLIENT_USER_TOKEN);
}

export function setRedirectPath(redirectPath: string) {
  set(REDIRECT_PATH_KEY, redirectPath);
}

export function getRedirectPath() {
  return get(REDIRECT_PATH_KEY);
}

export function removeRedirectPath() {
  remove(REDIRECT_PATH_KEY);
}

export function setClientRedirectPath(redirectPath: string) {
  set(CLIENT_REDIRECT_PATH_KEY, redirectPath);
}

export function getClientRedirectPath() {
  return get(CLIENT_REDIRECT_PATH_KEY);
}

export function removeClientRedirectPath() {
  remove(CLIENT_REDIRECT_PATH_KEY);
}

export function getFormGroupResponse(): IFormGroupResponse {
  return get(CLIENT_FORM_GROUP_RESPONSE);
}

export function setFormGroupResponse(data: IFormGroupResponse) {
  set(CLIENT_FORM_GROUP_RESPONSE, data);
}

export function removeFormGroupResponse() {
  remove(CLIENT_FORM_GROUP_RESPONSE);
}

export interface IStorageContainer {
  createdAt: string;
  expires: number;
  data: any;
}

function set(key: string, data: any, expires?: number) {
  const container = {
    createdAt: moment().format(),
    expires,
    data,
  };
  localStorage.setItem(key, JSON.stringify(container));
}

function remove(key: string) {
  localStorage.removeItem(key);
}
