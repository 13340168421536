import { Classes, Dialog, IDialogProps } from "@blueprintjs/core";
import { useDataProvider, useNotify, useRefresh } from "ra-core";
import React, { useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { useSelector } from "react-redux";
import { Constants } from "../../constants";
import { IAttachment, IEtsState } from "../../model";
import { AttachmentFileBrowser } from "../AttachmentBrowser/AttachmentFileBrowser";

interface IAttachmentManageDialogProps extends IDialogProps {
  clientCompanyId: string;
}

export const AttachmentManageDialog: React.FC<IAttachmentManageDialogProps> = (
  props
) => {
  const { clientCompanyId } = props;
  const token = useSelector((state: IEtsState) => state.auth.token);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  const [uploadLocation, setUploadLocation] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (attachment: string): Promise<Partial<IAttachment>> => {
    const attrs = {
      clientCompanyId,
      fileObject: attachment,
      location: uploadLocation,
    };
    return dataProvider.create(Constants.CLIENT_PORTAL_ATTACHMENT_RESOURCE, {
      data: attrs,
    });
  };

  const handleAttachment = async (attachments: string[]) => {
    try {
      await Promise.all(attachments.map(onSubmit));
      notify("Attachment(s) successfully uploaded");
    } catch (error) {
      notify("Some uploads were unsuccessful", "warning");
    }
    refresh();
    setIsLoading(false);
  };

  const render = (renderProps: any) => {
    return (
      <AttachmentFileBrowser
        clientCompanyId={clientCompanyId}
        canSave={false}
        canDownload={false}
        canModify={true}
        canDirectUpload={true}
        handleUpload={renderProps.handleUpload}
        setUploadLocation={setUploadLocation}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
    );
  };

  return (
    <Dialog {...props} icon="helper-management" title="View">
      <div className={Classes.DIALOG_BODY}>
        <DirectUploadProvider
          headers={{ authorization: `Bearer ${token}` }}
          multiple={false}
          onSuccess={handleAttachment}
          render={render}
        />
      </div>
    </Dialog>
  );
};
