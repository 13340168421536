import { Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { Constants } from "../../constants";

interface IEmptyMessageProps {
  resource: string;
  basePath: string;
}

export const EmptyMessage: React.FC<IEmptyMessageProps> = ({ resource }) => {
  const createPage = resource === "invoices" ? "task" : "invoice";
  const linkTo =
    resource === "invoices" ? Constants.INBOX_PATH : Constants.INVOICE_PATH;

  return (
    <Typography variant="body1">
      <Link to={linkTo}>{`Go to ${createPage} page to create`}</Link>
    </Typography>
  );
};
