import { Box, Divider, Grid } from "@material-ui/core";
import { ReferenceManyField } from "ra-ui-materialui";
import React from "react";
import { EditableResourceField } from "../../components/cells/EditableResourceField";
import { ImportantPersonList } from "../../components/ImportantPerson/ImportantPersonList";
import { Constants } from "../../constants";
import { INaturalPerson } from "../../model";
import * as DateUtils from "../../utils/date";

interface IClientGroupNaturalPersonProps {
  naturalPerson: INaturalPerson;
}

export const ClientGroupNaturalPerson: React.FC<IClientGroupNaturalPersonProps> = ({
  naturalPerson,
}) => {
  const { id, name, notes, updatedAt } = naturalPerson;
  return (
    <>
      <Divider />
      <div className="client-group-natural-person-field">
        <h5>{name}</h5>
        <Box
          component="div"
          display="flex"
          justifyContent="flex-start"
          className="natural-person-attributes"
        >
          <Grid item={true} xs={2}>
            Notes:
          </Grid>
          <Grid item={true} xs={10}>
            <EditableResourceField
              resourceId={id}
              resourceName={Constants.NATURAL_PERSON_RESOURCE}
              fieldValue={notes}
              fieldName="notes"
              multiline={true}
            />
          </Grid>
        </Box>
        <Box
          component="div"
          display="flex"
          justifyContent="flex-start"
          className="natural-person-attributes"
        >
          <Grid item={true} xs={2}>
            Roles:
          </Grid>
          <Grid item={true} xs={10}>
            <ReferenceManyField
              reference={Constants.IMPORTANT_PERSON_RESOURCE}
              resource={Constants.NATURAL_PERSON_RESOURCE}
              target={Constants.NATURAL_PERSON_RESOURCE}
              record={naturalPerson}
              filter={{ naturalPersonId: naturalPerson.id }}
              basePath={Constants.COMPANY_DETAIL_PATH}
              perPage={100000}
            >
              <ImportantPersonList />
            </ReferenceManyField>
          </Grid>
        </Box>
        <Box
          component="div"
          display="flex"
          justifyContent="flex-start"
          className="natural-person-attributes"
        >
          <Grid item={true} xs={2}>
            Last updated:
          </Grid>
          <Grid item={true} xs={10}>
            {DateUtils.formatFriendlyDate(DateUtils.parseDate(updatedAt))}
          </Grid>
        </Box>
      </div>
    </>
  );
};
