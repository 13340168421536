import { TextField } from "ra-ui-materialui";
import React from "react";
import * as Utils from "../../../utils/date";

export const DateField: React.FC<any> = ({ record, basePath }) => {
  const { dueDate, startDate } = record;
  const showString = `${Utils.shortFormDate(startDate)} - ${Utils.shortFormDate(
    dueDate
  )}`;
  return <TextField source="showString" record={{ ...record, showString }} />;
};
