import * as React from "react";

export const MentionsSuggestion = (
  suggestion: object,
  search: string,
  highlightedDisplay: object,
  index: number,
  focused: boolean
) => {
  return (
    <div className={`user ${focused ? "focused" : ""}`}>
      {highlightedDisplay}
    </div>
  );
};
