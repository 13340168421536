import { makeStyles } from "@material-ui/core";
import React from "react";
import FasterPaymentSystemQRCode from "react-fps-hk-qrcode";
import { FormattedMessage } from "react-intl";
import { IClientInvoice } from "../../../model";

const useStyle = makeStyles({
  show: {
    marginTop: 10,
    marginBottom: 10,
  },
  title: {
    fontWeight: "bold",
    marginTop: 10,
    fontSize: 15,
  },
  content: {
    marginTop: 10,
    marginBottom: 10,
  },
});

const convertTypeToString = (type: string) => {
  switch (type) {
    case "fps_id":
      return "02";
    case "mobile_number":
      return "03";
    case "email":
      return "04";
  }
};

interface IFpsQrCodeProps {
  record?: IClientInvoice;
}

export const FpsQrCode: React.FC<IFpsQrCodeProps> = ({ record }) => {
  const classes = useStyle({});

  const {
    fpsAccount,
    fpsAccountType,
    fpsBankCode,
    fpsMerchantCategoryCode,
    fpsPhoneNumber,
    fpsTransactionCurrency,
  } = record.fpsDetail;

  const accountTypeNumber = convertTypeToString(fpsAccountType);
  return (
    <div className={classes.show}>
      <div className={classes.title}>
        <FormattedMessage
          defaultMessage="Payment"
          description="invoice field payment"
        />
      </div>
      {fpsAccountType && (
        <>
          <div className={classes.content}>
            Method 1: Local Bank Transfer (HKD)
          </div>
          <div className={classes.content}>FPS QR Code</div>
          <FasterPaymentSystemQRCode
            account={accountTypeNumber}
            bank_code={fpsBankCode}
            fps_id={fpsAccount}
            email={fpsAccountType === "email" ? fpsAccount : ""}
            mobile={fpsPhoneNumber}
            mcc={fpsMerchantCategoryCode}
            currency={fpsTransactionCurrency}
            amount={record.amount}
            reference=""
          />
          <div className={classes.content}>
            Please use your mobile banking app to scan FPS code.
            <br />
            <br />
            For more info, please click{" "}
            <a href="https://www.notion.so/How-to-make-FPS-Payments-e5b6eacfdf6241b59758219ae2af2963">
              here
            </a>
          </div>
        </>
      )}
    </div>
  );
};
