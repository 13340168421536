import { Button, Popover, Position } from "@blueprintjs/core";
import { useDataProvider, useNotify, useRefresh } from "ra-core";
import React, { useCallback } from "react";

interface IFollowProps {
  followableId: string;
  type: "Task" | "Team" | "Department";
  userIsFollowing: boolean;
  onClick: (item) => void;
}

const useCreateFollower = (
  followableId,
  followableType,
  userIsFollowing,
  onClick
) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const notifyString = userIsFollowing
    ? `Unfollowed ${followableType}: ${followableId}`
    : `Followed ${followableType}: ${followableId}`;

  return useCallback(() => {
    const act = async () => {
      await dataProvider.createFollowerForFollowable({
        followable_id: followableId,
        followable_type: followableType,
      });
      refresh();
      onClick(!userIsFollowing);
    };
    act();
  }, [dataProvider]);
};

export const Follow: React.FC<IFollowProps> = ({
  followableId,
  type,
  userIsFollowing,
  onClick,
}) => {
  const followButtonText = userIsFollowing
    ? `Unfollow ${type}`
    : `Follow ${type}`;
  const handleFollowOnClick = useCreateFollower(
    followableId,
    type,
    userIsFollowing,
    onClick
  );

  return (
    <Popover position={Position.BOTTOM}>
      <Button icon="follower" alignText="right" onClick={handleFollowOnClick}>
        {followButtonText}
      </Button>
    </Popover>
  );
};
