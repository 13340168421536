import moment from "moment";
import { ITask } from "../model/ITask";

// retturns tasks completed less than 30 days ago, and with dueDate greater than
// start of this month
export function filterTasksThisMonthDueDate(task: ITask): boolean {
  return (
    moment(task.completedAt) >= moment().subtract(30, "days") ||
    moment(task.dueDate) >= moment().startOf("month")
  );
}
