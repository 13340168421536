import _, { camelCase } from "lodash/fp";
import { IResponseAnswer } from "../model/IFormRespones";

export function getTypeformAnswer(answer: IResponseAnswer) {
  const type = camelCase(answer.type);

  switch (type) {
    case "choice":
      if (answer.choice.hasOwnProperty("other")) {
        return answer.choice.other;
      }

      return answer.choice.label;
    case "fileUrl":
      return answer[type].split("/").pop();
    case "choices":
      if (answer.choices.hasOwnProperty("other")) {
        if (!_.isNil(answer.choices.labels)) {
          return [answer.choices.other].concat(answer.choices.labels);
        }
        return [answer.choices.other];
      }
      return answer.choices.labels;
    case "boolean":
      return answer.boolean ? "Yes" : "No";
    default:
      return answer[type];
  }
}

export function getTypeformFieldTitle(
  title: string,
  refWithAnswers: RefAndAnswer
) {
  const re = /\*/g;

  if (/{{.+}}/.test(title)) {
    const refIds = title.match(/{{.+?}}/g);
    return refIds.reduce((previousValue, currentValue) => {
      const refId = currentValue.replace(/{|}|(field:)/g, "");
      return previousValue.replace(currentValue, refWithAnswers[refId]);
    }, title);
  }
  return title.replace(re, "");
}

export interface RefAndAnswer {
  [refId: string]: string;
}

const isChoice = _.isObject;
const isChoices = _.has("labels");
const f = _.cond([
  [isChoices, _.flow(_.values, _.flatten, _.compact, _.join(", "))],
  [isChoice, _.flow(_.values, _.first)],
  [_.stubTrue, _.identity],
]);

export function getRefAndAnswer(answers: IResponseAnswer[]): RefAndAnswer {
  return answers
    .map((answer) => ({ [answer.field.ref]: f(answer[answer.type]) }))
    .reduce((obj, v) => ({ ...obj, ...v }), {});
}
