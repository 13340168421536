import {
  Callout,
  Classes,
  Dialog,
  IDialogProps,
  Intent,
  Toaster,
} from "@blueprintjs/core";
import { push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import { Api, closeCloneTasksDialog } from "../../../actions";
import { Constants } from "../../../constants";
import { IEtsState, IPartialTaskWithDSF } from "../../../model";
import { selectedTasksIdAsArray } from "../../../selectors";
import { Dispatch } from "../../../types";
import { TaskForm } from "../../forms";

interface ICloneTasksDialogStateProps {
  selectedTasks: string[];
  departmentId: string;
}

interface ICloneTasksDialogDispatchProps {
  onCloneTask: (tasks: string, overrides: IPartialTaskWithDSF) => Promise<any>;
  // Strange name here to avoid conflict with IDialogProps' onClose

  onGetTaskAdditionalDataRecord: (departmentId: string) => Promise<any>;
  redirectTaskDetail: (taskId) => void;
  onDispatchClose: () => void;
}

interface ICloneTasksDialogProps
  extends IDialogProps,
    ICloneTasksDialogStateProps,
    ICloneTasksDialogDispatchProps {}

const ToastFactory = Toaster.create();

class CloneTasksDialogImpl extends React.Component<ICloneTasksDialogProps> {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public render(): JSX.Element {
    return (
      <Dialog
        {...this.props}
        className="clone-tasks-dialog"
        icon="duplicate"
        title={"Clone Jobs"}
        onClose={this.props.onDispatchClose}
      >
        <div className={Classes.DIALOG_BODY}>
          <Callout>
            You are cloning <strong>{this.props.selectedTasks.length}</strong>{" "}
            tasks. Fields entered below will override existing values. If
            description is provided, it will replace descriptions for all
            clones. Otherwise, &quot;Clone of&quot; will be prepended to the
            original descriptions.
          </Callout>
          <TaskForm
            onCancel={this.props.onDispatchClose}
            onSubmit={this.handleSubmit}
            skipValidations={true}
            isClone={true}
            initialValues={{
              model: {
                cloneSubtasks: true,
                cloneInvoices: true,
                cloneBudgeted: true,
              },
            }}
          />
        </div>
      </Dialog>
    );
  }

  private async handleSubmit(values, actions) {
    const selectedTasks = this.props.selectedTasks;
    const numTasks = selectedTasks.length;

    const overrides = {
      task: values.model,
      additionalData: values.additionalData,
    };

    ToastFactory.show({
      message: `Cloning ${numTasks} tasks...`,
      intent: Intent.WARNING,
    });

    const result = await Promise.all(
      selectedTasks.map((selectedTask) =>
        this.props.onCloneTask(selectedTask, overrides)
      )
    );

    this.props.onGetTaskAdditionalDataRecord(this.props.departmentId);
    actions.resetForm();
    ToastFactory.clear();
    ToastFactory.show({
      message: `Successfully cloned ${numTasks} tasks`,
      intent: Intent.SUCCESS,
    });
    if (result.length === 1) {
      this.props.redirectTaskDetail(result[0]);
    }
    return this.props.onDispatchClose();
  }
}

function mapStateToProps(state: IEtsState) {
  return {
    selectedTasks: selectedTasksIdAsArray(state),
    departmentId: state.auth.departmentId,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onCloneTask: (task: string, overrides: IPartialTaskWithDSF) => {
      return dispatch(
        Api.Task.postCustomPath(`/api/tasks/${task}/clone`, overrides)
      );
    },
    redirectTaskDetail: (task) =>
      dispatch(push(`${Constants.TASK_PATH}/${task.id}`)),
    onDispatchClose: () => dispatch(closeCloneTasksDialog()),
    onGetTaskAdditionalDataRecord: (departmentId: string) => {
      return dispatch(
        Api.TaskAdditionalDataRecord.getAll({
          pagination: { page: 1, perPage: 10000 },
          filter: { departmentId },
          sort: { field: "", order: "ASC" },
        })
      );
    },
  };
}

export const CloneTasksDialog = connect<
  ICloneTasksDialogStateProps,
  ICloneTasksDialogDispatchProps,
  IDialogProps
>(
  mapStateToProps,
  mapDispatchToProps
)(CloneTasksDialogImpl);
