import { Classes, Dialog, IDialogProps } from "@blueprintjs/core";
import { useCreate, useNotify } from "ra-core";
import React, { useState } from "react";
import { Constants } from "../../constants";
import { IAttachmentEntry } from "../../model";
import { getAttachmentIdentifier } from "../../utils/fileManagement";
import { AttachmentFileBrowser } from "../AttachmentBrowser/AttachmentFileBrowser";

interface IAttachmentShareDialogProps extends IDialogProps {
  attachment: IAttachmentEntry;
  attachmentType: "timeline" | "email";
  clientCompanyId?: string;

  afterSharing: () => void;
}

export const AttachmentShareDialog: React.FC<IAttachmentShareDialogProps> = (
  props
) => {
  const { afterSharing, attachment, clientCompanyId, attachmentType } = props;
  const [create] = useCreate(Constants.CLIENT_PORTAL_ATTACHMENT_RESOURCE);
  const [isLoading, setIsLoading] = useState(false);
  const notify = useNotify();

  const identifier = getAttachmentIdentifier(attachmentType);

  const handleCopy = (newClientAttachmentKey: string) => {
    const attrs = {
      clientCompanyId,
      newClientAttachmentKey,
      [identifier]: attachment.id,
    };
    create(
      {
        payload: {
          data: attrs,
        },
      },
      {
        onSuccess: ({ data }) => {
          afterSharing();
          notify("File successfully shared", "info");
          setIsLoading(false);
        },
        onFailure: (error) => {
          notify("File sharing failed", "warning");
          setIsLoading(false);
        },
      }
    );
  };

  const handleReplace = (
    oldClientAttachmentId: string,
    newClientAttachmentKey: string
  ) => {
    const attrs = {
      clientCompanyId,
      oldClientAttachmentId,
      [identifier]: attachment.id,
      newClientAttachmentKey,
    };
    create(
      {
        payload: {
          data: attrs,
        },
      },
      {
        onSuccess: ({ data }) => {
          afterSharing();
          notify("File successfully overriden", "info");
          setIsLoading(false);
        },
        onFailure: (error) => {
          notify("File override failed", "warning");
          setIsLoading(false);
        },
      }
    );
  };

  return (
    <Dialog
      {...props}
      icon="share"
      title={
        attachment.isShared ? "Location in Data Safe" : "Place in Data Safe"
      }
    >
      <div className={Classes.DIALOG_BODY}>
        <AttachmentFileBrowser
          attachment={attachment}
          attachmentType={attachmentType}
          clientCompanyId={clientCompanyId}
          canSave={true}
          canDownload={false}
          canModify={false}
          canDirectUpload={false}
          handleCopy={handleCopy}
          handleReplace={handleReplace}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </div>
    </Dialog>
  );
};
