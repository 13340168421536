import { Fab } from "@material-ui/core";
import { crudGetOne, useGetOne, useMutation } from "ra-core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../../constants";
import { ApprovalAction, useApproval } from "../../hooks";
import { IEtsState, ITimeEntry, ITimesheet } from "../../model";
import { TaskGrid } from "./TimesheetCreateComponent/TaskGrid";
import { TimesheetInternalRemarks } from "./TimesheetCreateComponent/TimesheetInternalRemarks";

interface IAcceptRejectButtonGroupProps {
  onAccept?: () => void;
  onReject?: () => void;
  acceptText?: string;
  rejectText?: string;
}

const AcceptRejectButtonGroup: React.FC<IAcceptRejectButtonGroupProps> = ({
  onAccept,
  onReject,
  acceptText,
  rejectText,
}) => {
  return (
    <div className="timesheet-show-button">
      {onAccept && acceptText && (
        <Fab variant="extended" size="small" color="primary" onClick={onAccept}>
          {acceptText}
        </Fab>
      )}
      {onReject && rejectText && (
        <Fab variant="extended" size="small" color="primary" onClick={onReject}>
          {rejectText}
        </Fab>
      )}
    </div>
  );
};

const noop = () => {};

interface IActionButtonGroupProps {
  timesheet: ITimesheet;
}

const ActionButtonGroup: React.FC<IActionButtonGroupProps> = ({
  timesheet: { id, createdById, eicId, picId, approvalStatus },
}) => {
  const user = useSelector((state: IEtsState) => state.auth.user);
  const dispatch = useDispatch();
  const handleSubmit = useApproval(
    Constants.TIMESHEET_RESOURCE,
    ApprovalAction.Submit,
    id
  );
  const handleEicAccept = useApproval(
    Constants.TIMESHEET_RESOURCE,
    ApprovalAction.EicAccept,
    id
  );
  const handleEicReject = useApproval(
    Constants.TIMESHEET_RESOURCE,
    ApprovalAction.EicReject,
    id
  );
  const handlePicAccept = useApproval(
    Constants.TIMESHEET_RESOURCE,
    ApprovalAction.PicAccept,
    id
  );
  const handlePicReject = useApproval(
    Constants.TIMESHEET_RESOURCE,
    ApprovalAction.PicReject,
    id
  );

  const handleOnClick = async (handleApproval) => {
    handleApproval(
      {},
      {
        onSuccess: (result) => {
          dispatch(
            crudGetOne(Constants.TIMESHEET_RESOURCE, result.data.id, "")
          );
        },
      }
    );
  };

  const handleOnClickSubmit = () => handleOnClick(handleSubmit);
  const handleOnClickEicAccept = () => handleOnClick(handleEicAccept);
  const handleOnClickEicReject = () => handleOnClick(handleEicReject);
  const handleOnClickPicAccept = () => handleOnClick(handlePicAccept);
  const handleOnClickPicReject = () => handleOnClick(handlePicReject);
  return (
    <>
      {user.id === createdById && approvalStatus === "draft" && (
        <AcceptRejectButtonGroup
          acceptText="Submit"
          onAccept={handleOnClickSubmit}
        />
      )}
      {user.id === eicId && approvalStatus === "need_eic_approval" && (
        <AcceptRejectButtonGroup
          acceptText="EIC Approve"
          onAccept={handleOnClickEicAccept}
          rejectText="EIC Reject"
          onReject={handleOnClickEicReject}
        />
      )}
      {user.id === picId && approvalStatus === "need_pic_approval" && (
        <AcceptRejectButtonGroup
          acceptText="PIC Approve"
          onAccept={handleOnClickPicAccept}
          rejectText="PIC Reject"
          onReject={handleOnClickPicReject}
        />
      )}
    </>
  );
};

export const TimesheetEdit: React.FC<any> = ({ id }) => {
  const { loaded, data } = useGetOne(Constants.TIMESHEET_RESOURCE, id);
  const timeSheet = data as ITimesheet;
  const dispatch = useDispatch();
  const [mutate] = useMutation();

  if (!loaded || !timeSheet) {
    return null;
  }

  const { startDate } = timeSheet;

  const handleUpdateEntry = (
    newEntry: ITimeEntry,
    previousEntry: ITimeEntry
  ) => {
    mutate(
      {
        type: "update",
        resource: Constants.TIME_ENTRY_RESOURCE,
        payload: {
          id: newEntry.id,
          data: newEntry,
          previousData: previousEntry,
        },
      },
      {
        onSuccess: () => {
          dispatch(crudGetOne(Constants.TIMESHEET_RESOURCE, id, ""));
        },
      }
    );
  };

  return (
    <>
      <ActionButtonGroup timesheet={timeSheet} />
      <TaskGrid
        key={timeSheet.id}
        startDatetime={startDate}
        timesheet={timeSheet}
        onChangeDate={noop}
        onUpdateEntry={handleUpdateEntry}
      />
      <div className="timesheet-show-bottom">
        <TimesheetInternalRemarks timesheet={timeSheet} />
        <ActionButtonGroup timesheet={timeSheet} />
      </div>
    </>
  );
};
