import { IconName } from "@blueprintjs/core";
import { Schema } from "yup";

export enum DSFModel {
  Task = "task",
  Company = "company",
}

export interface IDepartmentSpecificFieldsConfig {
  [departmentInternalIdentifier: string]: IDepartmentSpecificFieldConfig[];
}

export interface IDepartmentSpecificFieldOptions {
  // When set to true, will attach a "onConfirm" handler to the editor
  // Otherwise, just handles "onChange"
  // This is important for editors like the EditableText component, which don't handle
  // updates atomically
  submitOnConfirm?: boolean;
  // When set to true, will attach a "onBlur" handler to the editor
  // Otherwise, just handles "onChange"
  // This is important for InputGroup components when there is no explicit submit button
  submitOnBlur?: boolean;
}

export interface IDepartmentSpecificFieldConfig {
  name: string;
  field: string;
  belongsTo?: string;
  // Optional. Validations in Yup schema format.
  validations?: Schema<any>;
  // Optional. Blueprint icon name to display in task detail.
  icon?: IconName;
  CreateComponent: React.ComponentType;
  // Optional. If provided, will be passed directly to the CreateComponent.
  createComponentProps?: any;
  createComponentOptions?: IDepartmentSpecificFieldOptions;
  EditComponent?: React.ComponentType;
  // Optional. If provided, will be passed directly to the EditComponent.
  editComponentProps?: any;
  editComponentOptions?: IDepartmentSpecificFieldOptions;
  // Must be a EditableCell component
  TableEditComponent?: React.ComponentType;
  width?: number;
}
