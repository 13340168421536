import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { IResponseAnswer, IResponseField } from "../../model/IFormRespones";
import {
  getTypeformFieldTitle,
  RefAndAnswer,
} from "../../utils/typeformConvert";
import { TypeformAnswer } from "./TypeformAnswer";

interface ITypeformExpandProps {
  field: IResponseField;
  answer: IResponseAnswer;
  index: number;
  allExpanded: boolean;
  refWithAnswers: RefAndAnswer;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: 15,
  },
  questionNumber: {
    color: "#6c757d",
    fontSize: 15,
  },
  question: {
    fontSize: 15,
    fontWeight: 700,
  },
}));

export const TypeformExpand: React.FC<ITypeformExpandProps> = ({
  field,
  answer,
  index,
  allExpanded,
  refWithAnswers,
}) => {
  const classes = useStyles({});

  const [expanded, setExpanded] = useState(allExpanded);

  useEffect(() => {
    setExpanded(allExpanded);
  }, [allExpanded]);

  const handleOnchange = (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion expanded={expanded} onChange={handleOnchange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.heading}>
          <span className={classes.questionNumber}>Q{index + 1}:</span>
          <span className={classes.question}>
            {getTypeformFieldTitle(field.title, refWithAnswers)}
          </span>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <TypeformAnswer answer={answer} />
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
