import { Icon } from "@blueprintjs/core";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { ReportList } from "./ReportList";

export const ReportSideBar: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const handleOnclickReport = (e) => {
    e.preventDefault();
    setOpen(!open);
  };
  const renderArrow = () => {
    if (open) {
      return <Icon icon="chevron-up" style={{ paddingLeft: 30 }} />;
    } else {
      return <Icon icon="chevron-down" style={{ paddingLeft: 30 }} />;
    }
  };

  return (
    <>
      <NavLink to="void" onClick={handleOnclickReport}>
        <Icon icon="numbered-list" />
        <span>Reports</span>
        {renderArrow()}
      </NavLink>
      {open ? <ReportList /> : ""}
    </>
  );
};
