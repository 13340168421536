import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { enabled } from "../../../config";
import { Constants } from "../../../constants";
import { IEtsState, ITaskAccountBalance } from "../../../model";

interface ITaskTextFieldProps {
  label?: string;
  record?: ITaskAccountBalance;
  source?: string;
}

const TaskTextField: React.FC<ITaskTextFieldProps> = ({ source, record }) => {
  const roles = useSelector((state: IEtsState) => state.auth.roles);
  const currentUserId = useSelector((state: IEtsState) => state.auth.user.id);

  const sameDepartment = useSelector(
    (state: IEtsState) => state.auth.departmentId === record.departmentId
  );

  const toAccountBalance = () => {
    const balanceEnabled =
      enabled("timebill.reports", roles) ||
      currentUserId === record.picId ||
      currentUserId === record.eicId;
    return balanceEnabled && `${Constants.ACCOUNT_BALANCE_PATH}/${record.id}`;
  };

  if (!sameDepartment) {
    return <span>{record[source]}</span>;
  }

  return (
    <Link to={toAccountBalance()}>
      <span>{record[source]}</span>
    </Link>
  );
};

export default TaskTextField;
