import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Box } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { enabled, reportRole } from "../../config/features";
import { Constants } from "../../constants";
import { IEtsState } from "../../model";
import { ReportSideBar } from "./Report/ReportSideBar";

interface ISidebarProps {
  roles: string[];
}

export class SidebarImpl extends React.Component<ISidebarProps> {
  public render(): JSX.Element {
    const { roles } = this.props;
    return (
      <Box displayPrint="none" component="div" className="sidebar">
        <ul>
          <li>
            <NavLink to={`${Constants.INBOX_PATH}?isComplete=false`}>
              <Icon icon="inbox" />
              <span>Inbox</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={Constants.ACTIVITY_FEED_PATH}>
              <Icon icon="feed" />
              <span>Feed</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={Constants.KANBAN_PATH}>
              <Icon icon="dashboard" />
              <span>Board</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={`${Constants.TODAY_PATH}?isComplete=false`}>
              <Icon icon="flame" />
              <span>Today</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={`${Constants.WEEK_PATH}?isComplete=false`}>
              <Icon icon="timeline-events" />
              <span>Week</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={`${Constants.TEAM_PATH}?isComplete=false`}>
              <Icon icon="people" />
              <span>Team</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={`${Constants.DEPARTMENT_PATH}?isComplete=false`}>
              <Icon icon="following" />
              <span>Department</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={Constants.COMPANY_DETAIL_PATH}>
              <Icon icon="office" />
              <span>Companies</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={Constants.TIMELINE_PATH}>
              <Icon icon="calendar" />
              <span>Calendar</span>
            </NavLink>
          </li>
          <li>
            <NavLink to={Constants.REVIEW_PATH}>
              <Icon icon="zoom-in" />
              <span>Review Jobs</span>
            </NavLink>
          </li>
          {enabled("bankStatmentParser", roles) && (
            <li>
              <NavLink to={Constants.BANK_STATEMENT_GENERATOR_PATH}>
                <Icon icon={IconNames.BANK_ACCOUNT} />
                <span>Bank Statements</span>
              </NavLink>
            </li>
          )}
          {enabled("timebill.timesheet", roles) && (
            <li>
              <NavLink to={Constants.TIMESHEET_PATH}>
                <Icon icon="timeline-events" />
                <span>Timesheets</span>
              </NavLink>
            </li>
          )}
          {enabled("timebill.expense", roles) && (
            <li>
              <NavLink to={Constants.EXPENSES_PATH}>
                <Icon icon="dollar" />
                <span>Expenses</span>
              </NavLink>
            </li>
          )}
          {enabled("timebill.invoice", roles) && (
            <li>
              <NavLink to={Constants.INVOICE_PATH}>
                <Icon icon="th" />
                <span>Fee Notes</span>
              </NavLink>
            </li>
          )}
          {enabled("timebill.payment", roles) && (
            <li>
              <NavLink to={Constants.PAYMENT_PATH}>
                <Icon icon="credit-card" />
                <span>Payments</span>
              </NavLink>
            </li>
          )}
          {enabled("timebill.creditNote", roles) && (
            <li>
              <NavLink to={Constants.CREDIT_NOTE_PATH}>
                <Icon icon="annotation" />
                <span>Credit Notes</span>
              </NavLink>
            </li>
          )}
          {enabled("timebill.writeOff", roles) && (
            <li>
              <NavLink to={Constants.WRITE_OFF_PATH}>
                <Icon icon="cut" />
                <span>Write-Offs</span>
              </NavLink>
            </li>
          )}
          {(enabled("timebill.reports", roles) || reportRole(roles)) && (
            <ReportSideBar />
          )}
          {enabled("timebill.customReport", roles) && (
            <li>
              <NavLink to={Constants.CUSTOM_REPORT_PATH}>
                <Icon icon="numbered-list" />
                <span>Custom Report</span>
              </NavLink>
            </li>
          )}
          <li>
            <NavLink to={Constants.SETTINGS_PATH}>
              <Icon icon="cog" />
              <span>Settings</span>
            </NavLink>
          </li>
        </ul>
      </Box>
    );
  }
}

function mapStateToProps(state: IEtsState): ISidebarProps {
  return {
    roles: state.auth.roles,
  };
}

export const Sidebar = connect(mapStateToProps)(SidebarImpl);
