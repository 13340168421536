import { Card } from "@blueprintjs/core";
import { Grid } from "@material-ui/core";
import { ReferenceField } from "ra-ui-materialui";
import React from "react";
import { Constants } from "../../constants";
import { UserUtil } from "../../core";
import { IClientCompany, IEtsUser } from "../../model";

interface IClientCompanyContactSectionProps {
  company: IClientCompany;
  team: string;
  referral: string;
}

interface IUserDisplayProps {
  record?: IEtsUser;
}

const UserDisplay = ({ record: user }: IUserDisplayProps) => (
  <>{UserUtil.displayValue(user as IEtsUser, true)}</>
);

export const ClientCompanyAssignedToSection = ({
  referral,
  team,
  company,
}: IClientCompanyContactSectionProps) => {
  return (
    <Card className="details">
      <div className="content">
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={3}>
            Client PIC:
          </Grid>
          <Grid item={true} xs={9}>
            <ReferenceField
              resource={Constants.CLIENT_COMPANY_RESOURCE}
              reference={Constants.USER_RESOURCE}
              source="assigneeId"
              basePath={Constants.COMPANY_DETAIL_PATH}
              record={company}
              link={false}
            >
              <UserDisplay />
            </ReferenceField>
          </Grid>
          <Grid item={true} xs={3}>
            Client EIC:
          </Grid>
          <Grid item={true} xs={9}>
            <ReferenceField
              resource={Constants.CLIENT_COMPANY_RESOURCE}
              reference={Constants.USER_RESOURCE}
              source="eicId"
              basePath={Constants.COMPANY_DETAIL_PATH}
              record={company}
              link={false}
            >
              <UserDisplay />
            </ReferenceField>
          </Grid>
          <Grid item={true} xs={3}>
            Team:
          </Grid>
          <Grid item={true} xs={9}>
            {team}
          </Grid>
          <Grid item={true} xs={3}>
            Referral:
          </Grid>
          <Grid item={true} xs={9}>
            {referral}
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};
