import {
  AppBar,
  Button,
  ButtonGroup,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import classNames from "classnames";
import { useLocale, useLogout, useSetLocale } from "ra-core";
import React, { useCallback } from "react";
import { Constants } from "../../../constants";
import { i18nIntlProvider } from "../../../i18n/i18Provider";

interface IClientPortalNavbarProps {
  user?: any;
  drawerOpen: boolean;
  handleDrawerOpen: (open) => void;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      padding: 5,
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hide: {
      display: "none",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    toolbar: {
      minHeight: 48,
    },
    exitButton: {
      padding: theme.spacing(1),
    },
  })
);

const TranslateButton = withStyles({
  root: {
    "color": "#FFFF",
    "&$disabled": {
      fontWeight: "bold",
      color: "#303030",
    },
  },
  disabled: {},
})(Button);

export const ClientPortalNavbar: React.FC<IClientPortalNavbarProps> = ({
  user,
  drawerOpen,
  handleDrawerOpen,
}) => {
  const classes = useStyles({});
  const logout = useLogout();
  const handleLogoutClick = () =>
    logout({}, Constants.CLIENT_APP_LOGIN_GENERATOR_PATH);

  const locale = useLocale();
  const setLocale = useSetLocale();
  const setLocaleCallback = useCallback(
    (locale: string) => {
      i18nIntlProvider.changeLocale(locale);
      setLocale(locale);
    },
    [setLocale]
  );

  return (
    <AppBar
      // position="fixed"
      className={classNames(classes.appBar, {
        [classes.appBarShift]: drawerOpen,
      })}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen.bind(this, true)}
          edge="start"
          className={classNames(classes.menuButton, drawerOpen && classes.hide)}
        >
          <MenuIcon />
        </IconButton>
        <ButtonGroup
          variant="text"
          color="default"
          aria-label="text primary button group"
          size="small"
        >
          <TranslateButton
            disabled={locale === "en"}
            onClick={() => setLocaleCallback("en")}
          >
            ENG
          </TranslateButton>
          <TranslateButton
            disabled={locale === "zh-HK"}
            onClick={() => setLocaleCallback("zh-HK")}
          >
            繁
          </TranslateButton>
          <TranslateButton
            disabled={locale === "zh-CN"}
            onClick={() => setLocaleCallback("zh-CN")}
          >
            简
          </TranslateButton>
        </ButtonGroup>
        <div className="client-portal-header-elements">
          {user && (
            <span className="client-user-header-name">
              {user.firstName + " " + user.lastName}
            </span>
          )}
          <IconButton
            color="inherit"
            onClick={handleLogoutClick}
            className={classNames(classes.exitButton)}
          >
            <ExitToAppIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};
