import { Button, Tag } from "@blueprintjs/core";
import * as React from "react";
import { connect } from "react-redux";
import { openCloneTasksDialog } from "../actions";
import { IEtsState, ITaskWithDSF } from "../model";
import { canCreateTasks, selectedTasksWithDsfAsArray } from "../selectors";
import { Dispatch } from "../types";

interface ICloneTaskButtonStateProps {
  enableTaskCloning: boolean;
  selectedTasks: ITaskWithDSF[];
}

interface ICloneTaskButtonDispatchProps {
  handleOnClick: () => void;
}

interface ICloneTaskButtonProps
  extends ICloneTaskButtonStateProps,
    ICloneTaskButtonDispatchProps {}

export class CloneTaskButtonImpl extends React.Component<
  ICloneTaskButtonProps
> {
  constructor(props) {
    super(props);
  }

  public render() {
    const count = this.props.selectedTasks.length;
    const disabled = !this.props.enableTaskCloning;
    const tag = count > 0 ? <Tag round={true}>{count}</Tag> : null;
    const text = count > 1 ? "Clone Jobs" : "Clone Job";

    return (
      <Button
        className="clone-task"
        icon="duplicate"
        text={text}
        disabled={disabled || count === 0}
        rightIcon={tag}
        onClick={this.props.handleOnClick}
      />
    );
  }
}

function mapStateToProps(state: IEtsState) {
  return {
    enableTaskCloning: canCreateTasks(state),
    selectedTasks: selectedTasksWithDsfAsArray(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    handleOnClick: () => dispatch(openCloneTasksDialog()),
  };
}

export const CloneTaskButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(CloneTaskButtonImpl);
