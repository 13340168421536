import { makeStyles } from "@material-ui/styles";
import { Datagrid, List, TextField } from "ra-ui-materialui";
import React, { useEffect, useState } from "react";
import { ReportRole } from "../../constants/reportRoles";
import { useReportParams } from "../../hooks/useReportParams";
import { EmptyPage } from "../EmptyPage/EmptyPage";
import { PriceField, UserField } from "../fields";
import { WipExpenseField } from "./CustomField";
import { ActualRecoverRateField } from "./CustomField/AcutalRecoverRateField";
import { DateField } from "./CustomField/DateField";
import TaskTextField from "./CustomField/TaskTextField";
import { TotalField } from "./CustomField/TotalField";
import { taskAccountBalanceExporter } from "./exporter";
import { TaskAccountBalanceFilter } from "./TaskAccountBalanceFilter";

const useStyle = makeStyles({
  container: { marginTop: "30px" },
  date: { minWidth: 160 },
  amount: { textAlign: "right", minWidth: 130 },
  description: { maxWidth: 350 },
  total: {
    textAlign: "right",
    minWidth: 130,
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
});

const defaultFilter = {
  approvalStatus: ["need_eic_approval", "need_pic_approval", "approved"],
};

const CustomDatagrid = ({ setIsShowAll, ...rest }) => {
  const classes = useStyle({});

  const {
    filterValues: { showAll = false },
  } = rest;
  useEffect(() => setIsShowAll(showAll), [showAll]);

  return (
    <Datagrid {...rest}>
      <TextField source="clientCompanyName" label="Client Company Name" />
      <DateField label="Date" cellClassName={classes.date} />
      <TextField source="taskCode" label="Job Code" />
      <TaskTextField source="taskDescription" label="Job" />
      <UserField source="eicId" label="EIC" />
      <UserField source="picId" label="PIC" />
      <UserField source="assigneeId" />
      <PriceField label="Estimated Fee" source="estimatedFee" />
      <WipExpenseField
        source="wipEntries"
        label="Entries (WIP)"
        cellClassName={classes.amount}
        headerClassName={classes.amount}
      />
      <ActualRecoverRateField label="Actual Recoverability Rate" />
      <WipExpenseField
        source="feeNoteAmount"
        label="Fee Notes Amount"
        cellClassName={classes.amount}
        headerClassName={classes.amount}
      />
      <WipExpenseField
        source="feeNoteBalance"
        label="Fee Notes Balance"
        cellClassName={classes.total}
        headerClassName={classes.total}
      />
      <WipExpenseField
        source="wipToBill"
        label="WIP To Bill"
        cellClassName={classes.amount}
        headerClassName={classes.amount}
      />
      {showAll && (
        <WipExpenseField
          source="wipSettled"
          label="WIP Settled"
          cellClassName={classes.amount}
          headerClassName={classes.amount}
        />
      )}
      {showAll && (
        <WipExpenseField
          source="wipCreditNotes"
          label="Credit Notes"
          cellClassName={classes.amount}
          headerClassName={classes.amount}
        />
      )}
      {showAll && (
        <WipExpenseField
          source="wipWriteOffs"
          label="Write-Offs"
          cellClassName={classes.amount}
          headerClassName={classes.amount}
        />
      )}
      {showAll && (
        <WipExpenseField
          source="wipAdjustments"
          label="Transfers"
          cellClassName={classes.amount}
          headerClassName={classes.amount}
        />
      )}
      {showAll && (
        <TotalField
          label="WIP Balance"
          type="wip"
          cellClassName={classes.total}
          headerClassName={classes.total}
        />
      )}
      {showAll && (
        <WipExpenseField
          source="expenseEntries"
          label="Entries (Expense)"
          cellClassName={classes.amount}
          headerClassName={classes.amount}
        />
      )}
      {showAll && (
        <WipExpenseField
          source="expenseToBill"
          label="Expense To Bill"
          cellClassName={classes.amount}
          headerClassName={classes.amount}
        />
      )}
      {showAll && (
        <WipExpenseField
          source="expenseSettled"
          label="Expense Settled"
          cellClassName={classes.amount}
          headerClassName={classes.amount}
        />
      )}
      {showAll && (
        <WipExpenseField
          source="expenseCreditNotes"
          label="Credit Notes"
          cellClassName={classes.amount}
          headerClassName={classes.amount}
        />
      )}
      {showAll && (
        <WipExpenseField
          source="expenseWriteOffs"
          label="Write-Offs"
          cellClassName={classes.amount}
          headerClassName={classes.amount}
        />
      )}
      {showAll && (
        <WipExpenseField
          source="expenseAdjustments"
          label="Transfers"
          cellClassName={classes.amount}
          headerClassName={classes.amount}
        />
      )}
      {showAll && (
        <TotalField
          label="Expense Balance"
          cellClassName={classes.total}
          headerClassName={classes.total}
          type="expense"
        />
      )}
    </Datagrid>
  );
};

export const TaskAccountBalanceList: React.FC<any> = (props) => {
  const classes = useStyle({});
  const filterParams = useReportParams(
    ReportRole.TASK_ACCOUNT_BALANCE_DEPARTMENT,
    defaultFilter
  );
  const [isShowAll, setIsShowAll] = useState(false);
  return (
    <List
      {...props}
      bulkActionButtons={false}
      style={{ marginLeft: 10, marginRight: 10 }}
      exporter={taskAccountBalanceExporter(isShowAll)}
      filters={<TaskAccountBalanceFilter />}
      empty={<EmptyPage {...props} />}
      filterDefaultValues={filterParams}
      className={classes.container}
    >
      <CustomDatagrid setIsShowAll={setIsShowAll} />
    </List>
  );
};
