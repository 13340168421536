import * as React from "react";
import { Key } from "ts-keycode-enum";
import { isNullOrUndefined } from "util";
import { Constants } from "../../constants";
import { ITask } from "../../model";
import * as Utils from "../../utils/date";
import { DateInput } from "../DateInput";
import { IEditableCellProps } from "./types";

interface IEditableDateCellState {
  editing: boolean;
}

export class EditableDateCell extends React.Component<
  IEditableCellProps<ITask, string>,
  IEditableDateCellState
> {
  private dateInput;

  constructor(props: IEditableCellProps<ITask, string>) {
    super(props);

    this.state = {
      editing: false,
    };

    this.dateInput = React.createRef();

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
  }

  public shouldComponentUpdate(nextProps, _nextState) {
    return (
      this.props.cellInfo.value !== nextProps.cellInfo.value ||
      this.props.loading !== nextProps.loading
    );
  }

  public render(): JSX.Element {
    const { cellInfo, disabled, inputProps, loading } = this.props;
    const value = Utils.parseDate(cellInfo.value);
    return (
      <DateInput
        ref={this.dateInput}
        value={value}
        formatDate={Utils.formatDate}
        inputProps={{ ...inputProps, onKeyDown: this.handleInputKeyDown }}
        parseDate={Utils.parseDateStrict}
        maxDate={Constants.MAX_DATE}
        onChange={this.handleDateChange}
        disabled={disabled || loading || cellInfo.original.isComplete}
      />
    );
  }

  private handleDateChange(selectedDate: Date, isUserChange: boolean) {
    if (isUserChange && !isNullOrUndefined(selectedDate)) {
      const id = this.props.cellInfo.original.id;
      const value = Utils.formatDate(selectedDate);
      this.props.onFinishEditing(id, value);
    }
    this.setState({ ...this.state, editing: false });
  }

  private handleInputKeyDown(e) {
    const ref = this.dateInput.current;
    if (e.which === Key.Tab && !e.shiftKey) {
      ref.setState({ ...ref.state, isOpen: false });
    }
  }
}
