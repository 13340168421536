import polyglotI18nProvider from "ra-i18n-polyglot";
import zhCN from "ra-language-chinese";
import zhHK from "ra-language-chinese-traditional";
import en from "ra-language-english";
import { createIntl, createIntlCache } from "react-intl";
import enIntl from "./app/en.json";
import zhCNIntl from "./app/zh-CN.json";
import zhHKIntl from "./app/zh-HK.json";
import initialMessage from "./initialMessage";

const messages: Record<string, Record<string, string>> = {
  "en": enIntl,
  "zh-HK": zhHKIntl,
  "zh-CN": zhCNIntl,
};

export const i18nProvider = polyglotI18nProvider((locale) => {
  switch (locale) {
    case "zh-CN":
      return zhCN;
    case "zh-HK":
      return zhHK;
    case "en":
      return en;
  }
}, "en");

const cache = createIntlCache();

export let intl = createIntl(
  {
    locale: "en",
    defaultLocale: "en",
    messages: enIntl,
  },
  cache
);

export const i18nIntlProvider = {
  translate: (key: string, options: any = {}) => {
    try {
      if (key.match(/^ra\./)) {
        return i18nProvider.translate(key, options);
      }
      return intl.formatMessage(initialMessage[key], options);
    } catch (error) {
      return key;
    }
  },
  changeLocale: (locale) => {
    intl = createIntl(
      {
        locale,
        messages: messages[locale],
      },
      cache
    );
    i18nProvider.changeLocale(locale);
  },
  getLocale: () => {
    return intl.locale;
  },
};
