import { IModelById, IProductInnerState, IProductState } from "@chiubaka/core";
import { ReduxState } from "ra-core";
import {
  getDepartmentId,
  getExistingAuthState,
  getRoles,
  IAuthState,
} from "../core";
import { IActualTime } from "./IActualTime";
import { IAmountToBill } from "./IAmountToBill";
import { IAttachment } from "./IAttachment";
import { IChatThread } from "./IChatThread";
import { IClientCompany } from "./IClientCompany";
import { IClientGroup } from "./IClientGroup";
import { IClientService } from "./IClientService";
import { IComment } from "./IComment";
import { ICompanyAdditionalData } from "./ICompanyAdditionalData";
import { ICreditNote } from "./ICreditNote";
import { ICreditNoteList } from "./ICreditNoteList";
import { IDepartmentAccountBalance } from "./IDepartmentAccountBalance";
import { IDepartmentSetting } from "./IDepartmentSetting";
import { IDepartmentSettings } from "./IDepartmentSettings";
import { IEmail } from "./IEmail";
import { IEstimatedTimeEntry } from "./IEstimatedTimeEntry";
import { IEtsUser } from "./IEtsUser";
import { IExpense } from "./IExpense";
import { IFeeNoteList } from "./IFeeNoteList";
import { IInterviewTemplate } from "./IInterviewTemplate";
import { IInvoiceTemplate } from "./IInvoiceTemplate";
import { INotification } from "./INotification";
import { IPayment } from "./IPayment";
import { IRate } from "./IRate";
import { IStatus } from "./IStatus";
import { ISubtaskType } from "./ISubtaskType";
import { ITask } from "./ITask";
import { ITaskAccountBalance } from "./ITaskAccountBalance";
import { ITaskAdditionalData } from "./ITaskAdditionalData";
import { ITaskType } from "./ITaskType";
import { ITeam } from "./ITeam";
import { ITenantAdditionalData } from "./ITenantAdditionalData";
import { ITimeEntry } from "./ITimeEntry";
import { ITimesheet } from "./ITimesheet";
import { IWriteOff } from "./IWriteOff";

export interface IUiState {
  cloneTasksDialogIsOpen: boolean;
}

export interface IEtsState extends IAuthState, IProductState, ReduxState {
  actualTime: IActualTime[];
  actualTimeById: IModelById<IActualTime>;
  amountToBills: IAmountToBill[];
  attachments: IAttachment[];
  chatThreads: IChatThread[];
  clientCompanies: IClientCompany[];
  clientCompaniesById: IModelById<IClientCompany>;
  clientGroups: IClientGroup[];
  clientGroupsById: IModelById<IClientGroup>;
  clientServices: IClientService[];
  clientServicesById: IModelById<IClientService>;
  comments: IComment[];
  companyAdditionalData: IModelById<ICompanyAdditionalData>;
  creditNotes: ICreditNote[];
  creditNotesById: IModelById<ICreditNote>;
  creditNotesLists: ICreditNoteList[];
  departmentsAccountBalances: IDepartmentAccountBalance[];
  departmentSetting: IDepartmentSetting;
  departmentSettings: IDepartmentSettings[];
  emails: IEmail[];
  emailsById: IModelById<IEmail>;
  estimatedTimeEntries: IEstimatedTimeEntry[];
  estimatedTimeEntriesById: IModelById<IEstimatedTimeEntry>;
  expenses: IExpense[];
  expensesById: IModelById<IExpense>;
  feeNotesLists: IFeeNoteList[];
  invoices: any[];
  invoicesById: IModelById<any>;
  invoiceTemplates: IInvoiceTemplate[];
  invoiceTemplatesById: IModelById<IInvoiceTemplate>;
  notifications: INotification[];
  payments: IPayment[];
  paymentsById: IModelById<IPayment>;
  rates: IRate[];
  ratesById: IModelById<IRate>;
  selectedTaskIds: Set<string>;
  statusesById: IModelById<IStatus>;
  subtaskTypesById: IModelById<ISubtaskType>;
  taskAdditionalData: IModelById<ITaskAdditionalData>;
  tasks: ITask[];
  tasksAccountBalances: ITaskAccountBalance[];
  tasksById: IModelById<ITask>;
  taskSearchResults: ITask[];
  taskTypes: ITaskType[];
  taskTypesById: IModelById<ITaskType>;
  teamsById: IModelById<ITeam>;
  tenantAdditionalData: IModelById<ITenantAdditionalData>;
  timeEntries: ITimeEntry[];
  timeEntriesById: IModelById<ITimeEntry>;
  timesheets: ITimesheet[];
  timesheetsById: IModelById<ITimesheet>;
  ui: IUiState;
  users: IEtsUser[];
  usersById: IModelById<IEtsUser>;
  writeOffs: IWriteOff[];
  writeOffsById: IModelById<IWriteOff>;
  router: any;
  interviewTemplates: IInterviewTemplate[];
  interviewTemplatesById: IModelById<IInterviewTemplate>;
}

export const PRODUCT_STATE: IProductInnerState = {
  productName: "EasyTrack.ai",
  testMarketingMode: false,
  logoPath:
    "https://easytrack.sgp1.cdn.digitaloceanspaces.com/easytrack-logo-100x100.png",
};

export const UI_INITIAL_STATE: IUiState = {
  cloneTasksDialogIsOpen: false,
};

export const ETS_INITIAL_STATE: IEtsState = {
  auth: {
    ...getExistingAuthState({
      enableNonSocialLogin: true,
      useEmailAsUsername: true,
      wrapParameters: true,
    }),
    roles: getRoles() || [],
    departmentId: getDepartmentId(),
  },
  attachments: [],
  comments: [],
  clientCompanies: [],
  clientCompaniesById: {},
  clientGroups: [],
  clientGroupsById: {},
  clientServices: [],
  clientServicesById: {},
  companyAdditionalData: {},
  emails: [],
  emailsById: {},
  product: PRODUCT_STATE,
  selectedTaskIds: new Set(),
  statusesById: {},
  subtaskTypesById: {},
  tasks: [],
  taskAdditionalData: {},
  tasksById: {},
  taskSearchResults: [],
  taskTypesById: {},
  taskTypes: [],
  teamsById: {},
  tenantAdditionalData: {},
  usersById: {},
  users: [],
  departmentSettings: [{ id: "", reviewStatusId: [] }],
  ui: UI_INITIAL_STATE,
  chatThreads: [],
  expenses: [],
  expensesById: {},
  timeEntries: [],
  timeEntriesById: {},
  timesheets: [],
  timesheetsById: {},
  creditNotes: [],
  creditNotesById: {},
  invoiceTemplates: [],
  invoiceTemplatesById: {},
  invoices: [],
  invoicesById: {},
  notifications: [],
  writeOffs: [],
  writeOffsById: {},
  tasksAccountBalances: [],
  departmentsAccountBalances: [],
  payments: [],
  paymentsById: {},
  amountToBills: [],
  feeNotesLists: [],
  creditNotesLists: [],
  rates: [],
  ratesById: {},
  estimatedTimeEntries: [],
  estimatedTimeEntriesById: {},
  actualTime: [],
  actualTimeById: {},
  admin: undefined,
  router: undefined,
  departmentSetting: {
    id: "",
    allowInvoiceDateOverride: false,
    timebillDaily: false,
    reviewStatusId: [],
  },
  interviewTemplates: [],
  interviewTemplatesById: {},
};

export function getExistingState(): IEtsState {
  return ETS_INITIAL_STATE;
}
