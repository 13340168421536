import { Middleware } from "redux";
import { IEtsState } from "../../model";
import {
  ActionTypes,
  IAuthActionType,
  removeRedirectPath,
  removeRolesAndDepartment,
  removeToken,
  removeUser,
  setRedirectPath,
  setRolesAndDepartmentId,
  setToken,
  setUser,
} from "../auth";

export const storageMiddleware: Middleware<{}, IEtsState> = (store) => (
  next
) => (action: IAuthActionType) => {
  switch (action.type) {
    case ActionTypes.COMPLETE_LOGIN:
      setToken(action.authToken);
      break;
    case ActionTypes.SUCCESSFUL_GET_USER_DETAILS:
      const { user, roles, departmentId } = action;
      setUser(user);
      setRolesAndDepartmentId(roles, departmentId);
      break;
    case ActionTypes.COMPLETE_LOGOUT:
      removeToken();
      removeUser();
      removeRolesAndDepartment();
      break;
    case ActionTypes.SET_REDIRECT:
      const redirectPath = action.redirectPath;
      setRedirectPath(redirectPath);
      break;
    case ActionTypes.CLEAR_REDIRECT:
      removeRedirectPath();
      break;
    default:
      break;
  }
  next(action);
};
