import { Grid } from "@material-ui/core";
import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
} from "ra-ui-materialui";
import React, { useCallback } from "react";
import { required } from "react-admin";
import { useForm } from "react-final-form";
import { Constants } from "../../../constants";

const filterClientCompany = (text) => ({ withName: text, hasTasks: true });

export const ExpenseCreateSelect: React.FC<any> = (props) => {
  const form = useForm();
  const [clientCompanyId, setClientCompanyId] = React.useState();
  const handleChangeClientCompany = useCallback(
    (value) => {
      setClientCompanyId(value);
      form.change("taskId", null);
    },
    [setClientCompanyId, form]
  );

  return (
    <Grid container={true}>
      <Grid item={true} xs={12}>
        <ReferenceInput
          label="Client company"
          source="clientCompanyId"
          reference={Constants.CLIENT_COMPANY_RESOURCE}
          resource={Constants.TASK_RESOURCE}
          filterToQuery={filterClientCompany}
          sort={{ field: "name", order: "ASC" }}
          onChange={handleChangeClientCompany}
        >
          <AutocompleteInput
            source="clientCompanyId"
            optionText="name"
            optionValue="id"
            validate={required()}
          />
        </ReferenceInput>
      </Grid>
      <Grid item={true} xs={12}>
        <ReferenceInput
          label="Task"
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          resource={Constants.EXPENSE_RESOURCE}
          filter={{ clientCompanyId }}
          sort={{ field: "description", order: "ASC" }}
        >
          <SelectInput
            source="taskId"
            optionText="description"
            optionValue="id"
            validate={required()}
          />
        </ReferenceInput>
      </Grid>
    </Grid>
  );
};
