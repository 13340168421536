import { required } from "ra-core";
import React, { FC } from "react";
import {
  DateInput,
  Edit,
  NumberInput,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { Constants } from "../../constants";
import { entryTypeString } from "../../utils/entryType";
import { TaskField } from "../fields";
import { ClientCompanyInput } from "../inputs";

const choices = [{ type: "wip" }, { type: "expense" }];

const redirect = (basePath, id, data) =>
  `${Constants.ACCOUNT_BALANCE_PATH}/${data.taskId}`;

export const AdjustmentEdit: FC<any> = (props) => {
  return (
    <Edit {...props} export={false} undoable={false}>
      <SimpleForm redirect={redirect}>
        <ReferenceField
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          addLabel={false}
          link={false}
        >
          <TaskField />
        </ReferenceField>
        <ClientCompanyInput />
        <DateInput source="date" defaultValue={new Date()} />
        <SelectInput
          source="entryType"
          choices={choices}
          optionText={(choice) => entryTypeString(choice.type)}
          optionValue="type"
          validate={required()}
        />
        <TextInput multiline={true} source="description" rows="4" />
        <NumberInput source="amount" />
      </SimpleForm>
    </Edit>
  );
};
