import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { IDepartmentAccountBalance } from "../../model";
import { priceColor } from "../../utils/color";
import { priceFormatter } from "../../utils/money";
import { HeaderName } from "./HeaderName";
import { departmentAccountBalanceStyle } from "./ReportLayoutLeft";

interface IDepartmentList {
  department: IDepartmentAccountBalance;
  type: string;
}

const useStyle = makeStyles({
  name: {
    textAlign: "center",
    boxSizing: "border-box",
    borderBottom: "solid 1px",
    marginTop: 10,
    marginBottom: 10,
    fontSize: 15,
    paddingBottom: 10,
    height: 45,
  },
  red: {
    color: "#ff0000",
  },
  green: {
    color: "#3cb371",
  },
});

export const DepartmentList: React.FC<IDepartmentList> = ({
  department,
  type,
}) => {
  const classes = departmentAccountBalanceStyle({});
  const rootClasses = useStyle({});
  return (
    <Grid
      container={true}
      item={true}
      xs={2}
      direction="column"
      className={classes.minWidth}
    >
      <Grid container={true} direction="column" className={classes.wip}>
        <Grid item={true} className={rootClasses.name}>
          <HeaderName record={department} type={type} />
        </Grid>
        <Grid item={true} className={classes.normal}>
          <BalanceField amount={department.wipBf} color={true} />
        </Grid>
        <Grid item={true} className={classes.normal}>
          <BalanceField amount={department.wipEntries} color={false} />
        </Grid>
        <Grid item={true} className={classes.normal}>
          <BalanceField
            amount={department.wipInvoices}
            color={false}
            negative={true}
          />
        </Grid>
        <Grid item={true} className={classes.normal}>
          <BalanceField amount={department.wipCreditNotes} color={false} />
        </Grid>
        <Grid item={true} className={classes.normal}>
          <BalanceField
            amount={department.wipWriteOffs}
            color={false}
            negative={true}
          />
        </Grid>
        <Grid item={true} className={classes.normal}>
          <BalanceField amount={department.wipAdjustments} color={false} />
        </Grid>
        <Grid item={true} className={classes.total}>
          <TotalField department={department} type="wip" />
        </Grid>
        <Grid item={true} className={classes.normal}>
          <BalanceField amount={department.wipCf} color={true} />
        </Grid>
        <Grid item={true} className={classes.expenses}>
          <BalanceField amount={department.expenseBf} color={true} />
        </Grid>
        <Grid item={true} className={classes.normal}>
          <BalanceField amount={department.expenseEntries} color={false} />
        </Grid>
        <Grid item={true} className={classes.normal}>
          <BalanceField
            amount={department.expenseInvoices}
            color={false}
            negative={true}
          />
        </Grid>
        <Grid item={true} className={classes.normal}>
          <BalanceField amount={department.expenseCreditNotes} color={false} />
        </Grid>
        <Grid item={true} className={classes.normal}>
          <BalanceField
            amount={department.expenseWriteOffs}
            color={false}
            negative={true}
          />
        </Grid>
        <Grid item={true} className={classes.normal}>
          <BalanceField amount={department.expenseAdjustments} color={false} />
        </Grid>
        <Grid item={true} className={classes.total}>
          <TotalField department={department} type="expense" />
        </Grid>
        <Grid item={true} className={classes.normal}>
          <BalanceField amount={department.expenseCf} color={true} />
        </Grid>
      </Grid>
    </Grid>
  );
};

interface ITotalFieldProps {
  department: IDepartmentAccountBalance;
  type: "wip" | "expense";
}

const TotalField: React.FC<ITotalFieldProps> = ({ department, type }) => {
  const total = department[`${type}Total`];
  return (
    <span style={priceColor(total)}>
      {priceFormatter.format(Math.abs(total))}
    </span>
  );
};
const BalanceField = ({ amount, color, negative = false }) => {
  const classes = useStyle({});
  const textColor = () => {
    if (!color) {
      return;
    }
    if (Number(amount) === 0) {
      return;
    } else if (amount < 0) {
      return classes.red;
    } else {
      return classes.green;
    }
  };
  return (
    <span className={textColor()}>
      {negative ? "-" : null}
      {priceFormatter.format(Math.abs(amount))}
    </span>
  );
};
