import { Card } from "@blueprintjs/core";
import { Grid } from "@material-ui/core";
import { findKey } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import {
  fieldConfigsForCurrentUser,
  tenantFieldConfigsForCurrentUser,
} from "../../components";
import { Constants } from "../../constants";
import {
  DSFModel,
  ICompanyAdditionalData,
  IDepartmentSpecificFieldConfig,
  IEtsState,
  ITenantAdditionalData,
  TSFModel,
} from "../../model";
import { stripKeySuffix } from "../../utils/fieldSpecific";

interface IClientCompanyGeneralProps {
  companyType: string;
  taxCode: string;
  status: string;
  registrationDate: string;
  clientCompanyId: string;
}

interface IAdditionalField {
  field: string;
  name: string;
  value: any;
}

const useFieldConfigs = () =>
  useSelector<IEtsState, IDepartmentSpecificFieldConfig[]>((state) => [
    ...fieldConfigsForCurrentUser(state, DSFModel.Company),
    ...tenantFieldConfigsForCurrentUser(state, TSFModel.TenantCompany),
  ]);

const useDepartmentSpecificData = (companyId: string) =>
  useSelector<IEtsState, ICompanyAdditionalData>(
    (state) => state.companyAdditionalData[companyId]
  );

const useTenantSpecificData = (companyId: string) =>
  useSelector<IEtsState, ITenantAdditionalData>((state) => {
    const key = findKey(
      state.tenantAdditionalData,
      (data) => data.clientCompanyId === companyId
    );
    return state.tenantAdditionalData[key];
  });

const useAdditionalFields = (companyId: string): IAdditionalField[] => {
  const configs = useFieldConfigs();
  const departmentSpecificData = useDepartmentSpecificData(companyId);
  const tenantSpecificData = useTenantSpecificData(companyId);

  return configs
    .filter(({ belongsTo, field }) => {
      const key = stripKeySuffix(field);
      if (belongsTo === Constants.TENANT_KEY) {
        return !!tenantSpecificData?.[key];
      }
      return !!departmentSpecificData?.[key];
    })
    .map(({ name, field, belongsTo }) => ({
      field,
      name,
      value: (belongsTo === Constants.TENANT_KEY
        ? tenantSpecificData
        : departmentSpecificData)?.[stripKeySuffix(field)],
    }));
};

export const ClientCompanyGeneralSection = ({
  companyType,
  taxCode,
  status,
  registrationDate,
  clientCompanyId,
}: IClientCompanyGeneralProps) => {
  const additionalFields = useAdditionalFields(clientCompanyId);
  return (
    <Card className="details">
      <div className="content">
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={3}>
            Company Type:
          </Grid>
          <Grid item={true} xs={9}>
            {companyType}
          </Grid>
          <Grid item={true} xs={3}>
            Tax Code:
          </Grid>
          <Grid item={true} xs={9}>
            {taxCode}
          </Grid>
          <Grid item={true} xs={3}>
            Status:
          </Grid>
          <Grid item={true} xs={9}>
            {status}
          </Grid>
          <Grid item={true} xs={3}>
            Registration Date:
          </Grid>
          <Grid item={true} xs={9}>
            {registrationDate}
          </Grid>
          {additionalFields.map(({ field, name, value }) => (
            <React.Fragment key={field}>
              <Grid item={true} xs={3}>
                {name}:
              </Grid>
              <Grid item={true} xs={9}>
                {value}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </div>
    </Card>
  );
};
