import classNames from "classnames";
import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface IHighlightWrapperProps extends RouteComponentProps<any> {
  id: string;
  className: string;
}

interface IHighlightWrapperState {
  bgc: string;
}

class HighlightWrapperImpl extends React.Component<
  IHighlightWrapperProps,
  IHighlightWrapperState
> {
  private updateTimer: any;
  constructor(props: IHighlightWrapperProps) {
    super(props);
    this.state = {
      bgc: "white",
    };
  }

  public componentDidUpdate(_prevProps: IHighlightWrapperProps) {
    const hasLocationChanged = this.props.location !== _prevProps.location;
    if (
      hasLocationChanged &&
      `#${_prevProps.id}` === this.props.location.hash
    ) {
      this.updateAndNotify();
    }
  }

  public componentWillUnmount() {
    if (this.updateTimer) {
      clearTimeout(this.updateTimer);
    }
  }

  public updateAndNotify = () => {
    if (this.updateTimer) {
      return;
    }
    this.setState({ bgc: "#89cff0" });
    this.updateTimer = setTimeout(() => {
      this.setState({ bgc: "white" });
      this.updateTimer = null;
    }, 5000);
  };

  public render(): JSX.Element {
    const { children, className } = this.props;
    return (
      <div
        id={this.props.id}
        className={classNames("highlight-wrapper", className)}
        style={{ borderColor: this.state.bgc }}
      >
        {children}
      </div>
    );
  }
}

const HighlightWrapper = withRouter<any, typeof HighlightWrapperImpl>(
  HighlightWrapperImpl
);
export default HighlightWrapper;
