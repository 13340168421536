import { Card, FormLabel, makeStyles } from "@material-ui/core";
import keyBy from "lodash/keyBy";
import { useGetList } from "ra-core";
import { Datagrid, DateField, Pagination, TextField } from "ra-ui-materialui";
import React, { FC, useReducer } from "react";
import { Constants } from "../../constants";
import { IInvoice } from "../../model";
import { PriceField, UserField } from "../fields";

interface IExpenseTableProps {
  record?: IInvoice;
}

interface IExpenseTableState {
  page: number;
  sort: string;
  order: string;
}

type IAction =
  | { type: "CHANGE_PAGE"; payload: number }
  | { type: "CHANGE_SORT"; payload: string };

function expenseTableReducer(state: IExpenseTableState, action: IAction) {
  switch (action.type) {
    case "CHANGE_PAGE":
      return { ...state, page: action.payload };
    case "CHANGE_SORT":
      if (state.sort !== action.payload) {
        return {
          ...state,
          sort: action.payload,
          order: "ASC",
        };
      } else {
        return {
          ...state,
          sort: action.payload,
          order: state.order === "ASC" ? "DESC" : "ASC",
        };
      }
    default:
      return state;
  }
}

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    main: {
      display: "flex",
    },
    content: {
      marginTop: 0,
      transition: theme.transitions.create("margin-top"),
      position: "relative",
      flex: "1 1 auto",
      [theme.breakpoints.down("xs")]: {
        boxShadow: "none",
      },
      overflow: "inherit",
    },
    bulkActionsDisplayed: {
      marginTop: -theme.spacing(8),
      transition: theme.transitions.create("margin-top"),
    },
    actions: {
      zIndex: 2,
      display: "flex",
      justifyContent: "flex-end",
      flexWrap: "wrap",
    },
    noResults: { padding: 20 },
  }),
  { name: "RaList" }
);

export const ExpenseTable: FC<IExpenseTableProps> = ({ record }) => {
  const [{ page, sort, order }, dispatch] = useReducer(expenseTableReducer, {
    page: 1,
    sort: "date",
    order: "ASC",
  });
  const perPage = 5;
  const { data, ids, total, loaded, loading } = useGetList(
    Constants.EXPENSE_RESOURCE,
    { page, perPage },
    { field: sort, order },
    { taskId: record.taskId }
  );
  const classes = useStyles({});

  const handleSort = (newSort: string) => {
    dispatch({ type: "CHANGE_SORT", payload: newSort });
  };

  const handlePage = (newPage: number) => {
    dispatch({ type: "CHANGE_PAGE", payload: newPage });
  };

  return (
    <div>
      <FormLabel component="legend">Expenses</FormLabel>
      <Card className={classes.content}>
        <Datagrid
          resource={Constants.EXPENSE_RESOURCE}
          loaded={loaded}
          loading={loading}
          data={keyBy(data, "id")}
          ids={ids}
          currentSort={{ field: sort, order }}
          setSort={handleSort}
          selectedIds={[]}
        >
          <DateField label="Date" source="date" />
          <TextField source="description" />
          <UserField label="Created by" source="createdById" />
          <PriceField source="amount" />
        </Datagrid>
        <Pagination
          page={page}
          perPage={perPage}
          setPage={handlePage}
          total={total}
        />
      </Card>
    </div>
  );
};
