import { Dialog, IDialogProps } from "@blueprintjs/core";
import * as React from "react";
import { IClientUser } from "../../model/IClientUserAuth";
import { LoginLinkDialogBody } from "../ClientUser/LoginLinkDialogBody";

interface IClientUserLoginLinkDialogProps extends IDialogProps {
  clientUser: IClientUser;
}

export const ClientUserLoginLinkDialog: React.FC<IClientUserLoginLinkDialogProps> = (
  props
) => {
  const { clientUser } = props;

  return (
    <div className="save-dialog-wrapper">
      <Dialog {...props} icon="link" title="Login Link">
        <LoginLinkDialogBody clientUser={clientUser} />
      </Dialog>
    </div>
  );
};
