import { Button, Popover, Position } from "@blueprintjs/core";
import _ from "lodash";
import { useGetList } from "ra-core";
import React from "react";
import { useSelector } from "react-redux";
import { enabled } from "../../config/features";
import { Constants } from "../../constants";
import { IAuthInnerState } from "../../core";
import { IEtsState, IEtsUser } from "../../model";
import { getFullName } from "../../utils/user";
import { UserList } from "./UserList";

interface ISwitchUserProps {
  authState: IAuthInnerState;
}

export const SwitchUser: React.FC<ISwitchUserProps> = ({ authState }) => {
  const authUserDetail = useSelector(
    (state: IEtsState) => state.usersById[authState.user.id]
  );
  const { data, loaded, total } = useGetList(
    Constants.PARENT_LIST_RESOURCE,
    { page: 1, perPage: 10 },
    { field: "", order: "" },
    {}
  );

  const hasInternalRole = enabled("switchUser.internal", authState.roles);

  if (!loaded) {
    return null;
  }

  return (
    <>
      {total > 0 || hasInternalRole ? (
        <Popover
          boundary="viewport"
          content={
            <UserList
              users={_.values(data) as IEtsUser[]}
              internal={hasInternalRole}
            />
          }
          position={Position.BOTTOM_RIGHT}
        >
          <Button text={getFullName(authUserDetail)} />
        </Popover>
      ) : (
        <span className="navbar-username">{getFullName(authUserDetail)}</span>
      )}
    </>
  );
};
