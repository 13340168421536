import { Icon } from "@blueprintjs/core";
import { IconName, IconNames } from "@blueprintjs/icons";
import * as React from "react";
import { useSelector } from "react-redux";
import {
  IEtsState,
  INotification,
  INotificationProps,
  NotificationMode,
} from "../../model";
import { getDaysfromNow } from "../../utils/date";
import {
  ApprovalFullNotification,
  ApprovalShortNotification,
} from "./NotificationRender";

const NOTIFICATION_RENDER_MODE = {
  Full: ApprovalFullNotification,
  Short: ApprovalShortNotification,
};

export const updateApprovalNotificationLine = (
  notification: INotification
): INotification => {
  const userId = useSelector((state: IEtsState) => state.auth.user.id);
  const {
    reviewerId,
    picId,
    followerNotificationLine,
  } = notification.parameters;
  if (![reviewerId, picId].includes(userId) && followerNotificationLine) {
    notification.parameters.notificationLine = followerNotificationLine;
  }
  return notification;
};

export const ApprovalNotificationComponent: React.FC<INotificationProps> = ({
  mode = NotificationMode.Full,
  notification,
}) => {
  const notificationModeString = NotificationMode[mode];
  const Handler = NOTIFICATION_RENDER_MODE[notificationModeString];
  const approvalNotification = updateApprovalNotificationLine(notification);

  const useIcon = (): IconName => {
    switch (notification.parameters?.entryType) {
      case "Task":
        return IconNames.EDIT;
      default:
        return IconNames.EDIT;
    }
  };

  return (
    <div className="notify-container-right">
      <div className="notify-container-three">
        <Handler notification={approvalNotification} mode={mode} />
      </div>
      <small className="icon-container">
        <Icon icon={useIcon()} iconSize={12} />
        <span className="bp3-button-text">
          {getDaysfromNow(notification.createdAt)}
        </span>
      </small>
    </div>
  );
};
