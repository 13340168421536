import { Field } from "formik";
import * as React from "react";
import * as Yup from "yup";
import { Errors } from "../../../constants";
import { DSFModel } from "../../../model";
import { withDSFCreateForm } from "../../hoc";
import {
  CompanySelect,
  StatusSelect,
  TaskTypeSelect,
  UserSelect,
} from "../../select";
import { DSFForm, IFormProps } from "../DSFForm/DSFForm";

const TaskDSFForm = withDSFCreateForm(DSFForm, DSFModel.Task) as any;

const TaskSchema = Yup.object().shape({
  description: Yup.string().required(Errors.REQUIRED),
  clientCompanyId: Yup.string().required(Errors.REQUIRED),
  assigneeId: Yup.string().required(Errors.REQUIRED),
  reviewerId: Yup.string().required(Errors.REQUIRED),
  taskTypeId: Yup.string().required(Errors.REQUIRED),
  statusId: Yup.string().required(Errors.REQUIRED),
  startDate: Yup.string().required(Errors.REQUIRED),
  dueDate: Yup.string().required(Errors.REQUIRED),
});

export class TaskForm extends React.Component<IFormProps> {
  public render() {
    return (
      <TaskDSFForm
        {...this.props}
        validationSchema={TaskSchema}
        className="task-form"
      >
        <Field
          name="model.description"
          render={DSFForm.renderInputGroup.bind(this, "Description")}
        />
        <Field
          name="model.code"
          render={DSFForm.renderInputGroup.bind(this, "Code")}
        />
        <Field
          name="model.clientCompanyId"
          render={DSFForm.renderModelSelect.bind(
            this,
            "Client Company",
            CompanySelect
          )}
        />
        <div className="d-flex flex-row">
          <Field
            name="model.assigneeId"
            render={DSFForm.renderModelSelect.bind(
              this,
              "Assignee",
              UserSelect
            )}
          />
          <Field
            name="model.reviewerId"
            render={DSFForm.renderModelSelect.bind(
              this,
              "Reviewer",
              UserSelect
            )}
          />
        </div>
        <div className="d-flex flex-row">
          <Field
            name="model.taskTypeId"
            render={DSFForm.renderModelSelect.bind(
              this,
              "Job Type",
              TaskTypeSelect
            )}
          />
          <Field
            name="model.statusId"
            render={DSFForm.renderModelSelect.bind(
              this,
              "Status",
              StatusSelect
            )}
          />
        </div>
        <div className="d-flex flex-row">
          <Field
            name="model.startDate"
            render={DSFForm.renderDateInput.bind(this, "Start Date")}
          />
          <Field
            name="model.dueDate"
            render={DSFForm.renderDateInput.bind(this, "Due Date")}
          />
        </div>
        <div className="d-flex flex-row">
          <Field
            name="model.reminderDate"
            render={DSFForm.renderDateInput.bind(this, "Reminder Date")}
          />
        </div>
        {this.props.isClone && this.renderCloneOption()}
      </TaskDSFForm>
    );
  }

  private renderCloneOption = () => (
    <>
      <div className="d-flex flex-row">
        <span>Clone</span>
      </div>
      <div className="d-flex flex-row">
        <Field
          name="model.cloneSubtasks"
          render={DSFForm.renderCheckBox.bind(this, "Subtasks")}
        />
        <Field
          name="model.cloneSubtasks"
          render={DSFForm.renderSubtaskWarning}
        />
        <Field
          name="model.cloneInvoices"
          render={DSFForm.renderCheckBox.bind(this, "Invoices")}
        />
        <Field
          name="model.cloneBudgeted"
          render={DSFForm.renderCheckBox.bind(this, "Budgeted")}
        />
      </div>
    </>
  );
}
