import { ThunkDispatch } from "redux-thunk";
import { Api, ILoginUserResponse } from "../../core";
import {
  AuthAction,
  AuthApi,
  completeLogin,
  completeLogoutAndRedirect,
  getAuthToken,
  IAuthState,
  IAuthToken,
  successfulGetUserDetails,
} from "../../core/auth";

export const switchUser = (userId: string, token: IAuthToken) => {
  return async (dispatch: ThunkDispatch<IAuthState, void, AuthAction>) => {
    const request = await fetch(`/api/switch_user?user_id=${userId}`, {
      method: "GET",
      headers: Api.apiHeaders(token),
    });
    const response = (await request.json()) as ILoginUserResponse;
    await dispatch(completeLogoutAndRedirect());
    const authorizationHeader = getAuthToken(await request);
    if (authorizationHeader !== null) {
      const user = AuthApi.userFromJwtUserResponse(response);
      const { roles, department } = response.data;
      await dispatch(successfulGetUserDetails(user, roles, department.id));
      dispatch(completeLogin(authorizationHeader));
    }
  };
};
