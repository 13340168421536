import { makeStyles } from "@material-ui/styles";
import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
} from "ra-ui-materialui";
import * as React from "react";
import { EmptyPage } from "../EmptyPage/EmptyPage";
import { UserField } from "../fields";
import { ApprovalStatusSelectArrayInput } from "../inputs";
import { ApprovalStatusList } from "../WriteOff/CustomField/ApprovalStatusList";
import { TimesheetCreateButton } from "./TimesheetCreateComponent/TimesheetCreateButton";

const PostActions: React.FC<any> = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  ...rest
}) => {
  return (
    <div className="timesheet-list-bar">
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <TimesheetCreateButton basePath={basePath} empty={false} />
    </div>
  );
};

const TimesheetFilter: React.FC<any> = (props) => {
  return (
    <Filter {...props}>
      <ApprovalStatusSelectArrayInput
        label="Approval statuses"
        source="approvalStatus"
      />
    </Filter>
  );
};

const useStyles = makeStyles({
  container: {
    margin: "50px 10px",
  },
});

export const TimesheetList: React.FC<any> = (props) => {
  const classes = useStyles(props);

  const Formatter = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  return (
    <List
      {...props}
      bulkActionButtons={false}
      actions={<PostActions />}
      filters={<TimesheetFilter />}
      sort={{ field: "start_date", order: "DESC" }}
      empty={
        <EmptyPage
          {...props}
          timesheetCreateButton={
            <TimesheetCreateButton basePath={props.basePath} />
          }
        />
      }
      className={classes.container}
    >
      <Datagrid optimized={true}>
        <DateField source="startDate" options={Formatter} />
        <DateField source="endDate" options={Formatter} />
        <UserField label="Created By" source="createdById" />
        <ApprovalStatusList source="approvalStatus" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
