import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { push } from "connected-react-router";
import { flatten, mapValues, startCase } from "lodash";
import moment from "moment";
import { Sort, useGetList } from "ra-core";
import React, { useState } from "react";
import Timeline, {
  CustomMarker,
  DateHeader,
  TimelineHeaders,
  TimelineMarkers,
} from "react-calendar-timeline";
import { useDispatch } from "react-redux";
import { Constants } from "../../constants";
import { TimelineDateFilter } from "../DateTimeline/TimelineDateFilter";
import { TimelineDialog } from "../DateTimeline/TimelineDialog";
import { TimelineTableHeader } from "../DateTimeline/TimelineTableHeader";

interface IGroupType {
  id: string;
  title: string;
}

interface IItemType {
  id: string;
  group: string;
  title: string;
  start_time: moment.Moment;
  end_time: moment.Moment;
  colorHex: string;
  companyName: string;
}

interface TimelineProps {
  groups: IGroupType[];
  items?: IItemType[];
  defaultTimeStart?: moment.Moment;
  defaultTimeEnd?: moment.Moment;
}

const renderLabelFormat = (
  [startTime, endTime],
  unit,
  labelWidth,
  formatOptions
) => {
  switch (unit) {
    case "day":
      if (labelWidth <= 50) {
        return startTime.format("D");
      } else if (labelWidth > 50 && labelWidth <= 100) {
        return startTime.format("dd D");
      } else {
        return startTime.format("dd M/D");
      }
    default:
      if (labelWidth <= 50) {
        return startTime.format("M");
      } else if (labelWidth > 50 && labelWidth <= 100) {
        return startTime.format("MM");
      } else {
        return startTime.format("MMMM");
      }
  }
};

const datesList = {
  nextAnnualGeneralMeetingDate: "#ea907a",
  nextAnnualReturnDate: "#beebe9",
  nextAuditDate: "#ffb6b9",
  nextBusinessRegistrationRenewalDate: "#a8e6cf",
  nextFinancialYearEnd: "#a6b1e1",
  nextTaxFilingDate: "#8ac6d1",
};

export const TimelineItem = (props) => {
  const {
    item,
    timelineContext,
    itemContext,
    getItemProps,
    getResizeProps,
    setSelectedItem,
    setOpen,
  } = props;

  return (
    <div
      {...getItemProps({
        style: {
          backgroundColor: item.colorHex,
          color: "#000000",
          borderStyle: "none",
        },
        onMouseDown: () => {
          setSelectedItem(item);
          setOpen(true);
        },
      })}
    >
      <div
        style={{
          height: props.itemContext.dimensions.height,
          overflow: "hidden",
          paddingLeft: 3,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {itemContext.title}
      </div>
    </div>
  );
};

export const DateTimeline: React.FC<TimelineProps> = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const pageSize = 10;

  const [selectedItem, setSelectedItem] = useState({
    title: "TITLE",
    companyName: "COMPANY",
    start_time: moment(),
  });

  const [filterState, setFilterState] = useState(
    mapValues(datesList, () => true)
  );

  const [sort, setSort] = useState<Sort>({ field: "date", order: "asc" });

  const [open, setOpen] = useState(false);

  const { data, ids, total } = useGetList(
    Constants.CLIENT_COMPANY_RESOURCE,
    { page, perPage: pageSize },
    { field: "timeline", order: "asc" },
    { hasImportantDate: true }
  );

  const clientCompanies = ids.map((d) => data[d]);

  const groups = clientCompanies.map((company) => {
    return {
      id: company.id,
      title: company.name,
    };
  });

  const goToCompanyDetails = (id) => {
    dispatch(push(`/app/companies/${id}?isComplete=false`));
  };

  const groupRenderer = ({ group }) => {
    return (
      <div className="custom-group">
        <a className="group-title" onClick={() => goToCompanyDetails(group.id)}>
          {group.title}
        </a>
      </div>
    );
  };

  const itemRenderer = (props) => {
    return (
      <TimelineItem
        {...props}
        setSelectedItem={setSelectedItem}
        setOpen={setOpen}
      />
    );
  };

  const items = clientCompanies.reduce((acc, company) => {
    Object.keys(datesList).forEach((date) => {
      if (company[date] && filterState[date]) {
        acc.push({
          id: `${company.id}-${date}`,
          group: company.id,
          title: startCase(date),
          start_time: moment(company[date]),
          end_time: moment(company[date]).add(1, "day"),
          colorHex: datesList[date],
          companyName: company.name,
        });
      }
    });
    return acc;
  }, []);

  const sortedItems = items.sort((a, b) => {
    const orderAdjust = sort.order === "asc" ? 1 : -1;
    switch (sort.field) {
      case "type":
        return orderAdjust * a.title.localeCompare(b.title);
      case "date":
        return orderAdjust * (a.start_time - b.start_time);
      case "company":
        return orderAdjust * a.companyName.localeCompare(b.companyName);
    }
  });

  const handleNavChange = (event, value) => {
    setPage(value);
  };

  const updateFilter = (event) => {
    setFilterState({
      ...filterState,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div className="date-timeline">
      <TimelineDialog
        selectedItem={selectedItem}
        isOpen={open}
        onOpen={setOpen}
      />

      <Timeline
        groups={groups}
        items={flatten(items)}
        showCursorLine
        // sidebarWidth={300}
        canMove={false}
        canResize={false}
        defaultTimeStart={moment().add(-3, "day")}
        defaultTimeEnd={moment().add(4, "day")}
        minZoom={24 * 60 * 60 * 1000}
        maxZoom={5 * 365 * 24 * 60 * 60 * 1000}
        timeSteps={{
          day: 1,
          month: 1,
          year: 1,
        }}
        traditionalZoom={true}
        itemRenderer={itemRenderer}
        groupRenderer={groupRenderer}
      >
        <TimelineMarkers>
          <CustomMarker date={moment().endOf("day")} />
        </TimelineMarkers>

        <TimelineHeaders className="sticky">
          <DateHeader unit="primaryHeader" />
          <DateHeader labelFormat={renderLabelFormat} />
        </TimelineHeaders>
      </Timeline>

      <TimelineDateFilter
        datesList={datesList}
        onChange={updateFilter}
        filterState={filterState}
      />

      <TableContainer className="table-container" component={Paper}>
        <Table className="table" aria-label="simple table" stickyHeader>
          <TimelineTableHeader sort={sort} setSort={setSort} />
          <TableBody>
            {sortedItems.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  <a
                    className="group-title"
                    onClick={() => goToCompanyDetails(row.group)}
                  >
                    {row.companyName}
                  </a>
                </TableCell>
                <TableCell>{row.title}</TableCell>
                <TableCell align="right">
                  {row.start_time.format("DD MMMM YYYY")}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="page-nav">
        <Pagination
          count={Math.ceil(total / pageSize)}
          page={page}
          onChange={handleNavChange}
        />
      </div>
    </div>
  );
};
