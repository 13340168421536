import { Alert, Checkbox, TextArea } from "@blueprintjs/core";
import { Box } from "@material-ui/core";
import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Api } from "../../actions";
import { enabled } from "../../config/features";
import { Constants } from "../../constants";
import { IModelById } from "../../core";
import { IEtsState, IEtsUser, ITimeEntry } from "../../model";
import * as Utils from "../../utils/date";
import { getStyleFromUser } from "../../utils/user";
import { DurationInput } from "../inputs";

interface ITimeEntryRecordState {
  isEditDescription: boolean;
  isOpenConfirm: boolean;
  description?: string;
  duration?: string;
}

interface ITimeEntryRecordProps {
  timeEntry: ITimeEntry;
  users: IModelById<IEtsUser>;
}

type IAction =
  | { type: "TOGGLE_EDIT_DESCRIPTION" }
  | { type: "EDIT_DESCRIPTION"; payload: string }
  | { type: "EDIT_DURATION"; payload: string }
  | { type: "OPEN_ALERT" }
  | { type: "CLOSE_ALERT" }
  | { type: "CANCEL_ALERT"; payload: ITimeEntry };

const reducer = (state: ITimeEntryRecordState, action: IAction) => {
  switch (action.type) {
    case "TOGGLE_EDIT_DESCRIPTION":
      return {
        ...state,
        isEditDescription: !state.isEditDescription,
      };
    case "EDIT_DESCRIPTION":
      return {
        ...state,
        description: action.payload,
      };
    case "CLOSE_ALERT":
      return {
        ...state,
        isOpenConfirm: false,
      };
    case "OPEN_ALERT":
      return {
        ...state,
        isOpenConfirm: true,
      };
    case "EDIT_DURATION":
      return {
        ...state,
        duration: action.payload,
      };
    case "CANCEL_ALERT":
      const { payload: timeEntry } = action;
      return {
        ...state,
        isChargeable: timeEntry.isChargeable,
        description: timeEntry.description,
        duration: timeEntry.duration,
      };
    default:
      return state;
  }
};

const DurationField = ({ duration }) => {
  const durationString = Utils.durationToString(duration);
  const isDaily = useSelector(
    (state: IEtsState) => state.departmentSetting.timebillDaily
  );
  return (
    <div className="timesheet-duration-time-box">
      <Box display="flex">
        {!isDaily && (
          <>
            <span className="timesheet-duration-time-box-timer">
              {durationString.hours}
            </span>
            <span className="timesheet-duration-time-box-timer-colon">:</span>
            <span className="timesheet-duration-time-box-timer">
              {durationString.mins}
            </span>
          </>
        )}
        {isDaily && (
          <span className="timesheet-duration-time-box-timer">
            {moment.duration(duration).asDays()}
          </span>
        )}
      </Box>
    </div>
  );
};

interface IDescriptionFieldProps {
  timeEntry: ITimeEntry;
  onClick?: React.MouseEventHandler;
}

const DescriptionField = ({ timeEntry, onClick }: IDescriptionFieldProps) => {
  if (!!timeEntry.timesheetId) {
    return <span>{timeEntry.description}</span>;
  } else {
    return (
      <input
        className={"bp3-input"}
        readOnly={true}
        value={timeEntry.description}
        onClick={onClick}
      />
    );
  }
};

const TimesheetStatus = ({ timeEntry }) => {
  return (
    <div>
      {!!timeEntry.timesheetId ? (
        <Link to={`${Constants.TIMESHEET_PATH}/${timeEntry.timesheetId}`}>
          Link
        </Link>
      ) : (
        <span className="bp3-tag">Not Submitted</span>
      )}
    </div>
  );
};

export const TimeEntryRecord = ({ timeEntry }: ITimeEntryRecordProps) => {
  const isAdmin = useSelector((state: IEtsState) =>
    enabled("timebill.timeEntry", state.auth.roles)
  );
  const reduxDispatch = useDispatch();
  const [
    { isEditDescription, isOpenConfirm, description, duration },
    dispatch,
  ] = useReducer(reducer, {
    isEditDescription: false,
    isOpenConfirm: false,
    description: timeEntry.description,
    duration: timeEntry.duration,
  });

  const handleUpdateTimeEntry = useCallback(
    (original: ITimeEntry, update: ITimeEntry) => {
      reduxDispatch(Api.TimeEntry.update(original, update));
      dispatch({ type: "EDIT_DURATION", payload: update.duration });
    },
    [reduxDispatch]
  );

  const user = useSelector(
    (state: IEtsState) => state.usersById[timeEntry.createdById]
  );

  const toggleEdit = () => dispatch({ type: "TOGGLE_EDIT_DESCRIPTION" });
  const handleClose = () => dispatch({ type: "CLOSE_ALERT" });
  const handleConfirm = () => {
    handleUpdateTimeEntry(timeEntry, {
      ...timeEntry,
      duration,
      endDatetime: moment(timeEntry.startDatetime)
        .add(moment.duration(duration).asMilliseconds())
        .toString(),
    });
  };

  const handleEditDurationDebounce = debounce((newDuration: string) => {
    if (isAdmin && timeEntry.timesheetId) {
      dispatch({ type: "EDIT_DURATION", payload: newDuration });
      dispatch({ type: "OPEN_ALERT" });
    } else {
      handleUpdateTimeEntry(timeEntry, {
        ...timeEntry,
        duration: newDuration,
        endDatetime: moment(timeEntry.startDatetime)
          .add(moment.duration(newDuration).asMilliseconds())
          .toString(),
      });
    }
  }, 1000);

  const handleDescriptionKeyPress: React.KeyboardEventHandler = useCallback(
    (event) => {
      if (event.key !== "Enter") {
        return;
      }

      finishEditDescription();
    },
    [description, timeEntry.description, toggleEdit, handleUpdateTimeEntry]
  );
  const finishEditDescription = useCallback(() => {
    if (timeEntry.description !== description) {
      handleUpdateTimeEntry(timeEntry, { ...timeEntry, description });
    }

    toggleEdit();
  }, [toggleEdit, handleUpdateTimeEntry, timeEntry.description, description]);
  const handleEditDescription = useCallback(
    (
      event:
        | React.FocusEvent<HTMLTextAreaElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      const { value } = event.currentTarget;
      dispatch({ type: "EDIT_DESCRIPTION", payload: value });
    },
    [timeEntry.description, description, handleUpdateTimeEntry]
  );

  const handleCancel = () =>
    dispatch({ type: "CANCEL_ALERT", payload: timeEntry });

  return (
    <div className="time-entry-record">
      <div className="time-entry-record-start-date-time">
        <span>{Utils.formatMonthDay(timeEntry.startDatetime)}</span>
      </div>
      <div className="time-entry-record-created-by">
        <div className="time-entry-created-by" style={getStyleFromUser(user)}>
          <span>{user.abbreviation}</span>
        </div>
      </div>
      <div className="time-entry-record-subtask-description">
        <div>
          <span>{timeEntry.subtaskDescription}</span>
        </div>
      </div>
      <div className="time-entry-record-description">
        {isEditDescription || isAdmin ? (
          <TextArea
            fill={true}
            onBlur={finishEditDescription}
            value={description}
            onKeyPress={handleDescriptionKeyPress}
            onChange={handleEditDescription}
          />
        ) : (
          <DescriptionField timeEntry={timeEntry} onClick={toggleEdit} />
        )}
      </div>
      <div className="time-entry-record-chargeable">
        <Checkbox
          alignIndicator="center"
          checked={timeEntry.isChargeable}
          inline={true}
          disabled={true}
        />
      </div>
      <div className="time-entry-record-duration">
        {!timeEntry.timesheetId || isAdmin ? (
          <DurationInput
            duration={duration}
            onChange={handleEditDurationDebounce}
          />
        ) : (
          <DurationField duration={timeEntry.duration} />
        )}
      </div>
      <div className="time-entry-record-timesheet-status">
        <TimesheetStatus timeEntry={timeEntry} />
      </div>
      <Alert
        cancelButtonText="Cancel"
        canOutsideClickCancel={true}
        isOpen={isOpenConfirm}
        onClose={handleClose}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      >
        <p>This will affect the WipEntry calculation as well</p>
      </Alert>
    </div>
  );
};
