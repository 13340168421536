import { ApprovalAction } from "../hooks";

export const statusString = (approvalStatus: string) => {
  switch (approvalStatus) {
    case "draft":
      return "Draft";
    case "need_eic_approval":
      return "Needs EIC Approval";
    case "need_pic_approval":
      return "Needs PIC Approval";
    case "approved":
      return "Approved";
    default:
      return approvalStatus;
  }
};

export const workFlowLabel = (workFlow: ApprovalAction) => {
  switch (workFlow) {
    case ApprovalAction.EicAccept:
      return "EIC APPROVAL";
    case ApprovalAction.EicReject:
      return "EIC REJECT";
    case ApprovalAction.PicAccept:
      return "PIC APPROVAL";
    case ApprovalAction.PicReject:
      return "PIC REJECT";
    case ApprovalAction.Submit:
      return "SUBMIT";
    default:
      return;
  }
};
