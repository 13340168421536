import { makeStyles } from "@material-ui/core";
import { CRUD_GET_ONE, useGetOne } from "ra-core";
import React, { useEffect } from "react";
import {
  ArrayInput,
  Edit,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { useDispatch } from "react-redux";
import { subscribeToInvoice } from "../../actions";
import { Constants } from "../../constants";
import { IInvoice } from "../../model";
import { FeeNoteField } from "../fields";
import { TextAreaInput } from "../inputs";
import { BalanceInput } from "./BalanceInput";
import { ExpenseTable } from "./ExpenseTable";
import { InvoiceBudgetedField } from "./InvoiceBudgetedField";
import { InvoiceDateField } from "./InvoiceDateField";
import { InvoiceEditToolbar } from "./InvoiceEditToolbar";
import { InvoiceHeader } from "./InvoiceHeader";
import { InvoiceProForma } from "./InvoiceProForma";
import { LineItemIterator } from "./lineItem/LineItemIterator";

const useStyles = makeStyles(() => ({
  container: {
    margin: 30,
  },
  largeText: {
    fontSize: 40,
  },
}));

export const InvoiceEdit: React.FC<any> = (props) => {
  const classes = useStyles(props);
  const { loaded: invoiceLoaded, data } = useGetOne(
    Constants.INVOICE_RESOURCE,
    props.id,
    {
      action: CRUD_GET_ONE,
    }
  );
  const invoice = data as IInvoice;

  const dispatch = useDispatch();
  useEffect(() => {
    if (invoiceLoaded) {
      dispatch(subscribeToInvoice(invoice));
    }
  }, [invoiceLoaded]);

  if (!invoiceLoaded || !invoice) {
    return null;
  }

  const disabled = invoice.approvalStatus !== "draft";

  return (
    <Edit {...props} location={{}} redirect={false} undoable={false}>
      <SimpleForm
        className={classes.container}
        toolbar={<InvoiceEditToolbar />}
      >
        <InvoiceHeader />
        <TextInput
          source="contact"
          resettable={true}
          disabled={disabled}
          allowEmpty={true}
        />
        <ReferenceInput
          label="Template to use"
          source="templateId"
          reference={Constants.INVOICE_TEMPLATE_RESOURCE}
          validate={[required()]}
          disabled={disabled}
        >
          <SelectInput optionText="displayName" />
        </ReferenceInput>
        <FeeNoteField />
        <br />
        <InvoiceProForma isCreate={false} />
        <TextInput
          source="reference"
          validate={[required()]}
          disabled={disabled}
        />
        <TextInput source="title" disabled={disabled} />
        <InvoiceDateField source="date" disabled={disabled} />
        <InvoiceBudgetedField />
        <ExpenseTable />
        <ArrayInput source="lineItems">
          <LineItemIterator disabled={disabled} />
        </ArrayInput>
        <BalanceInput type="wip" label="WIP" disabled={disabled} />
        <BalanceInput type="expense" label="Expense" disabled={disabled} />
        <TextAreaInput source="internalRemarks" />
        <TextAreaInput source="customField" disabled={disabled} />
      </SimpleForm>
    </Edit>
  );
};
