import { useReference } from "ra-core";
import { useEffect } from "react";
import { useForm } from "react-final-form";
import { Constants } from "../../../constants";
import { ILineItemTemplate } from "../../../model";

export const LineItemCategoryInput = ({ templateId, ...props }) => {
  const { referenceRecord } = useReference({
    reference: Constants.LINE_ITEM_TEMPLATE_RESOURCE,
    id: templateId,
  });

  const template = referenceRecord as ILineItemTemplate;
  const form = useForm();

  useEffect(() => {
    if (!!template) {
      form.change(props.source, template.category);
    }
  }, [template]);

  return null;
};
