import * as React from "react";
import { connect } from "react-redux";
import { Api } from "../../actions";
import { SettingsForm } from "../../components";
import { IEtsState, IEtsUser } from "../../model";
import { Dispatch } from "../../types";

interface ISettingsStateProps {
  currentEtsUser: IEtsUser;
}

interface ISettingsDispatchProps {
  onGetUser: (id: string) => Promise<IEtsUser>;
}

interface ISettingsProps extends ISettingsStateProps, ISettingsDispatchProps {}

export class SettingsImpl extends React.Component<ISettingsProps> {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  public render(): JSX.Element {
    return (
      <div className="settings">
        <SettingsForm
          afterSubmit={this.submit}
          model={this.props.currentEtsUser}
        />
      </div>
    );
  }

  private submit() {
    this.props.onGetUser(this.props.currentEtsUser.id);
  }
}

function mapStateToProps(state: IEtsState) {
  return {
    currentEtsUser: state.usersById[state.auth.user.id],
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onGetUser: (id: string) => {
      return dispatch(Api.User.get(id));
    },
  };
}

export const Settings = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsImpl);
