import * as React from "react";

export const CCEmails = ({ ccIds }) => {
  if (ccIds == null) {
    return null;
  }
  const ccEmails = ccIds
    .split(",")
    .filter((e) => !e.includes("easytrack.ai"))
    .map((email, index) => (
      <span className="timeline-email-addr" key={index}>
        {email}
      </span>
    ));
  return (
    <div className="timeline-email-to">
      <div className="">cc: </div>
      <div className="timeline-email-addrs">{ccEmails}</div>
    </div>
  );
};
