import { Icon, IconName } from "@blueprintjs/core";
import classNames from "classnames";
import { Sort } from "ra-core";
import React, { useCallback } from "react";

interface ISortableHeaderItemProps {
  field: string;
  label: string;
  onClick?: (sort: Sort) => void;
  sort?: Sort;
  sortable?: boolean;
  className?: string;
}

interface IOrderArrowProps {
  sort: Sort;
}

const generateSort = (previousSort: Sort, field: string): Sort => ({
  field,
  order:
    previousSort.field === field
      ? ["ASC", "DESC"].find((order) => order !== previousSort.order)
      : "ASC",
});

const OrderArrow: React.FC<IOrderArrowProps> = ({ sort }) => (
  <Icon icon={`chevron-${sort.order === "ASC" ? "up" : "down"}` as IconName} />
);

export const SortableHeaderItem: React.FC<ISortableHeaderItemProps> = ({
  onClick,
  sort,
  label,
  field,
  className,
  sortable = true,
}) => {
  const handleClick = useCallback(() => {
    onClick(generateSort(sort, field));
  }, [sort, field]);

  return (
    <div
      className={classNames({ "sortable-header-item": sortable }, className)}
      onClick={sortable && handleClick}
    >
      {label} {sortable && sort.field === field && <OrderArrow sort={sort} />}
    </div>
  );
};
