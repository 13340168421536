import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { useNotify, useUpdate } from "ra-core";
import React, { useReducer } from "react";
import { Constants } from "../../constants";
import { IClientUser } from "../../model/IClientUserAuth";

interface IClientUserNameEditProps {
  clientUser: IClientUser;
}

interface IClientUserInputState {
  currFirstName: string;
  currLastName: string;
  isRenaming: boolean;
}

type IAction =
  | {
      type: "REVERT_EDIT";
      payload: { currFirstName: string; currLastName: string };
    }
  | { type: "CHANGE_FIRST_NAME"; payload: string }
  | { type: "CHANGE_LAST_NAME"; payload: string }
  | { type: "START_EDIT" }
  | { type: "CANCEL_EDIT" };

const reducer = (
  state: IClientUserInputState,
  action: IAction
): IClientUserInputState => {
  switch (action.type) {
    case "REVERT_EDIT":
      return {
        currFirstName: action.payload.currFirstName,
        currLastName: action.payload.currLastName,
        isRenaming: false,
      };
    case "START_EDIT":
      return {
        ...state,
        isRenaming: true,
      };
    case "CANCEL_EDIT":
      return {
        ...state,
        isRenaming: false,
      };
    case "CHANGE_FIRST_NAME":
      return {
        ...state,
        currFirstName: action.payload,
      };
    case "CHANGE_LAST_NAME":
      return {
        ...state,
        currLastName: action.payload,
      };
    default:
      return state;
  }
};

export const ClientUserNameEdit: React.FC<IClientUserNameEditProps> = ({
  clientUser,
}) => {
  const { id, firstName, lastName } = clientUser;
  const [{ currFirstName, currLastName, isRenaming }, dispatch] = useReducer(
    reducer,
    {
      currFirstName: firstName,
      currLastName: lastName,
      isRenaming: false,
    }
  );

  const notify = useNotify();

  const updatedClientUser = {
    ...clientUser,
    firstName: currFirstName,
    lastName: currLastName,
  };
  const [updateName] = useUpdate(
    Constants.CLIENT_USER_RESOURCE,
    id,
    updatedClientUser,
    clientUser,
    {
      onSuccess: () => {
        notify("Name successfully updated", "info");
      },
      onFailure: () => {
        notify("Name update failed", "warning");
      },
    }
  );

  const handleFirstNameChange = (event) => {
    dispatch({ type: "CHANGE_FIRST_NAME", payload: event.target.value });
  };

  const handleLastNameChange = (event) => {
    dispatch({ type: "CHANGE_LAST_NAME", payload: event.target.value });
  };

  const handleCancelEdit = () => {
    dispatch({
      type: "REVERT_EDIT",
      payload: {
        currFirstName: firstName,
        currLastName: lastName,
      },
    });
  };

  const handleStartEdit = () => {
    dispatch({ type: "START_EDIT" });
  };

  const handleConfirmChange = () => {
    dispatch({ type: "CANCEL_EDIT" });
    if (currFirstName !== firstName || currLastName !== lastName) {
      updateName();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key !== "Enter") {
      return;
    }
    handleConfirmChange();
  };

  const currFullName = currFirstName + " " + currLastName;

  return (
    <>
      {isRenaming ? (
        <div>
          <input
            type="text"
            value={currFirstName}
            onChange={handleFirstNameChange}
            onKeyDown={handleKeyDown}
            autoFocus={true}
          />
          <input
            type="text"
            value={currLastName}
            onChange={handleLastNameChange}
            onKeyDown={handleKeyDown}
            autoFocus={true}
            className="client-user-name-input"
          />
          <SaveIcon
            onClick={handleConfirmChange}
            className="client-user-rename-icon"
          />
          <CloseIcon
            onClick={handleCancelEdit}
            className="client-user-rename-icon"
          />
        </div>
      ) : (
        <div>
          <span>{currFullName}</span>
          <EditIcon
            onClick={handleStartEdit}
            fontSize="default"
            className="client-user-rename-icon"
          />
        </div>
      )}
    </>
  );
};
