import { Button, Checkbox, Intent, Spinner } from "@blueprintjs/core";
import { IModelById, UserUtil } from "@chiubaka/core";
import { push } from "connected-react-router";
import { CRUD_UPDATE, startOptimisticMode, stopOptimisticMode } from "ra-core";
import * as React from "react";
import { connect } from "react-redux";
import ReactTable, { Column } from "react-table";
import { isNullOrUndefined } from "util";
import { Api, selectTasks, unselectTasks } from "../../actions";
import { Constants } from "../../constants";
import {
  DSFModel,
  IClientCompany,
  IClientGroup,
  IEtsState,
  IEtsUser,
  IPermissions,
  IStatus,
  ITask,
  ITaskAdditionalData,
  ITaskType,
} from "../../model";
import { currentPermissions } from "../../selectors";
import { Dispatch } from "../../types";
import * as DateUtils from "../../utils/date";
import * as HighlightUtils from "../../utils/highlight";
import { cannot } from "../../utils/permissions";
import * as TableUtils from "../../utils/table";
import * as FilterUtils from "../../utils/tableFilter";
import {
  DateAwareEditableDateCell,
  EditableAssigneeCell,
  EditableCompanyCell,
  EditableDateCell,
  EditablePicCell,
  EditableReviewerCell,
  EditableStatusCell,
  EditableTaskTypeCell,
  EditableTextCell,
} from "../cells";
import {
  FoldableTableHoc,
  IDSFTableControllerComponentProps,
  withDSFTableController,
  withUrlQueryableTable,
} from "../hoc";
import { TaskTableRow } from "./TaskTableRow";

const FoldableTable = FoldableTableHoc(withUrlQueryableTable(ReactTable));

interface ITaskTableOwnProps {
  hideCompanyColumns?: boolean;
  completedTasksLimit?: number;
  completedTasksFilter?: (task: ITask) => boolean;
  tasks: ITask[];
  query?: string;
  showPageSizeOptions?: boolean;
  defaultPageSize?: number;
}

interface ITaskTableStateProps {
  completedTasks: ITask[];
  incompleteTasks: ITask[];
  clientCompaniesById: IModelById<IClientCompany>;
  clientGroupsById: IModelById<IClientGroup>;
  globalPermissions: IPermissions;
  selectedTaskIds: Set<string>;
  statusesById: IModelById<IStatus>;
  tasksById: IModelById<ITask>;
  taskTypesById: IModelById<ITaskType>;
  usersById: IModelById<IEtsUser>;
}

interface ITaskTableDispatchProps {
  onTaskCompletionToggle: (id: string) => void;
  onCreateTaskAdditionalData: (
    data: ITaskAdditionalData
  ) => Promise<ITaskAdditionalData>;
  onUpdateTask: (originalTask: ITask, updatedTask: ITask) => Promise<ITask>;
  onUpdateTaskAdditionalData: (
    originalData: ITaskAdditionalData,
    updatedData: ITaskAdditionalData
  ) => Promise<ITaskAdditionalData>;
  onViewCompany: (id: string) => void;
  onViewTask: (id: string) => Promise<ITask>;
  onSelectTasks: (taskIds: string[]) => void;
  onUnselectTasks: (taskIds: string[]) => void;
}

interface ITaskTableProps
  extends ITaskTableOwnProps,
    ITaskTableStateProps,
    ITaskTableDispatchProps,
    IDSFTableControllerComponentProps {}

enum ExpansionGroups {
  Both,
  Neither,
  RecentlyCompletedOnly,
  IncompleteOnly,
}

interface ITaskTableState {
  creating: boolean;
  reloadingTasks: Set<string>;
  expandRecentlyCompleted: boolean;
  expandIncomplete: boolean;
  expansionGroups: ExpansionGroups;
}

export class TaskTable extends React.Component<
  ITaskTableProps,
  ITaskTableState
> {
  private tableRef;

  constructor(props) {
    super(props);

    this.state = {
      creating: false,
      reloadingTasks: new Set(),
      expandRecentlyCompleted: false,
      expandIncomplete: true,
      expansionGroups: this.expansionGroupsFromTasks(),
    };

    this.tableRef = React.createRef();

    this.filterUser = this.filterUser.bind(this);
    this.handleFilteredChanged = this.handleFilteredChanged.bind(this);
    this.getRowExpansionState = this.getRowExpansionState.bind(this);
    this.getTrProps = this.getTrProps.bind(this);
    this.renderTableRow = this.renderTableRow.bind(this);
    this.renderTextCell = this.renderTextCell.bind(this);
    this.renderEditableTaskCell = this.renderEditableTaskCell.bind(this);
    this.renderEditableCell = this.renderEditableCell.bind(this);

    this.companyPropertyAccessor = this.companyPropertyAccessor.bind(this);
    this.taskTypePropertyAccessor = this.taskTypePropertyAccessor.bind(this);

    this.createTask = this.createTask.bind(this);
    this.finishEditingTask = this.finishEditingTask.bind(this);

    this.handleTaskCompletionToggle = this.handleTaskCompletionToggle.bind(
      this
    );

    this.handleExpanderClick = this.handleExpanderClick.bind(this);
    this.handleTaskSelectAllToggle = this.handleTaskSelectAllToggle.bind(this);

    this.startReloadingTask = this.startReloadingTask.bind(this);
    this.finishReloadingTask = this.finishReloadingTask.bind(this);
  }

  public render(): JSX.Element {
    const columns = this.getColumnConfigurations();

    return (
      <div className="task-table">
        <FoldableTable
          ref={this.tableRef}
          columns={columns}
          data={[...this.props.completedTasks, ...this.props.incompleteTasks]}
          defaultPageSize={this.props.defaultPageSize ?? 30}
          expanded={this.getRowExpansionState()}
          filterable={true}
          defaultFilterMethod={FilterUtils.filterCaseInsensitive}
          onFilteredChange={this.handleFilteredChanged}
          minRows={5}
          pageSizeOptions={[10, 30, 50]}
          getTrProps={this.getTrProps}
          TrComponent={this.renderTableRow}
          showPageSizeOptions={this.props.showPageSizeOptions ?? true}
        />
      </div>
    );
  }

  private getColumnConfigurations(): Column[] {
    return [
      {
        Header: "ID",
        accessor: "id",
        show: false,
      },
      {
        Header: "Job Type ID",
        accessor: "taskTypeId",
        show: false,
      },
      {
        Cell: (cellInfo) => {
          if (cellInfo.aggregated) {
            return null;
          }

          return (
            <Checkbox
              checked={this.props.selectedTaskIds.has(cellInfo.original.id)}
              onChange={this.handleTaskSelectionToggle.bind(
                this,
                cellInfo.original
              )}
            />
          );
        },
        className: "vertical-center",
        width: 26,
        sortable: false,
        filterable: false,
        resizable: false,
      },
      {
        Cell: (cellInfo) => {
          return (
            <Button
              onClick={this.props.onViewTask.bind(this, cellInfo.value)}
              icon="link"
              intent={Intent.PRIMARY}
              minimal={true}
              className="link-task-icon"
            />
          );
        },
        accessor: "id",
        className: "vertical-center",
        sortable: false,
        filterable: false,
        resizable: false,
        width: 40,
      },
      {
        Cell: (cellInfo) => this.renderCompletion(cellInfo),
        accessor: "isComplete",
        className: "vertical-center horizontal-center",
        sortable: false,
        resizable: false,
        filterMethod: this.filterIsComplete,
        Filter: this.renderIsCompleteFilter,
        width: 60,
      },
      ...this.getPreCompanyDetailColumnConfigurations(),
      {
        Cell: this.renderEditableTaskCell.bind(
          this,
          EditableCompanyCell,
          "clientCompanyId"
        ),
        Header: "Company",
        accessor: this.companyPropertyAccessor.bind(this, "name"),
        id: "company",
        width: 235,
      },
      ...this.getCompanyDetailColumnConfigurations(),
      {
        Cell: this.renderEditableTaskCell.bind(
          this,
          EditableTaskTypeCell,
          "taskTypeId"
        ),
        Header: "Job Type",
        accessor: (task: ITask) => {
          const group = this.taskTypePropertyAccessor("group", task);
          const name = this.taskTypePropertyAccessor("name", task);

          return name ? `${group}/${name}` : group;
        },
        id: "task",
        width: 120,
      },
      {
        Cell: this.renderEditableTaskCell.bind(
          this,
          EditableAssigneeCell,
          "assigneeId"
        ),
        Header: "Assignee",
        accessor: (task: ITask) => {
          const assignee = this.props.usersById[task.assigneeId];
          return UserUtil.displayValue(assignee, true);
        },
        filterMethod: this.filterAssignee,
        id: "assignee",
        width: 55,
      },
      {
        Cell: this.renderEditableTaskCell.bind(
          this,
          EditableReviewerCell,
          "reviewerId"
        ),
        Header: "Reviewer",
        accessor: (task: ITask) => {
          const reviewer = this.props.usersById[task.reviewerId];
          return UserUtil.displayValue(reviewer, true);
        },
        filterMethod: this.filterReviewer,
        id: "reviewer",
        width: 55,
      },
      {
        Cell: this.renderEditableTaskCell.bind(this, EditablePicCell, "picId"),
        Header: "PIC",
        accessor: (task: ITask) => {
          const pic = this.props.usersById[task.picId];
          return UserUtil.displayValue(pic, true);
        },
        filterMethod: this.filterPic,
        id: "pic",
        width: 55,
      },
      {
        Cell: this.renderEditableTaskCell.bind(
          this,
          EditableStatusCell,
          "statusId"
        ),
        Header: "Status",
        accessor: this.statusPropertyAccessor.bind(this, "name"),
        id: "status",
        width: 125,
      },
      {
        Cell: this.renderEditableTaskCell.bind(
          this,
          EditableDateCell,
          "startDate"
        ),
        Header: "Start Date",
        accessor: "startDate",
        className: "vertical-center",
        width: 109,
      },
      {
        Cell: this.renderEditableTaskCell.bind(
          this,
          DateAwareEditableDateCell,
          "dueDate"
        ),
        Header: "Due Date",
        accessor: "dueDate",
        className: "vertical-center",
        width: 109,
      },
      {
        Cell: this.renderEditableTaskCell.bind(
          this,
          EditableDateCell,
          "reminderDate"
        ),
        Header: "Reminder Date",
        accessor: "reminderDate",
        className: "vertical-center",
        width: 109,
      },
      {
        Cell: this.renderEditableTaskCell.bind(
          this,
          EditableTextCell,
          "description"
        ),
        Header: "Description",
        accessor: "description",
        className: "vertical-center",
        width: 255,
      },
      ...this.props.dsfColumnConfigs,
    ];
  }
  private getPreCompanyDetailColumnConfigurations(): Column[] {
    if (this.props.hideCompanyColumns) {
      return [];
    }
    return [
      {
        Cell: this.renderTextCell,
        Header: "Client Group",
        accessor: this.clientGroupPropertyAccessor.bind(this, "name"),
        id: "clientGroup",
        className: "vertical-center",
        width: 50,
      },
    ];
  }

  private getCompanyDetailColumnConfigurations(): Column[] {
    if (this.props.hideCompanyColumns) {
      return [];
    }

    return [
      {
        Cell: (cellInfo) => {
          return (
            <Button
              onClick={this.props.onViewCompany.bind(this, cellInfo.value)}
              icon="zoom-in"
              minimal={true}
            />
          );
        },
        accessor: "clientCompanyId",
        className: "vertical-center",
        sortable: false,
        filterable: false,
        resizable: false,
        width: 30,
      },
      {
        Cell: this.renderTextCell,
        Header: "Jurisdiction",
        accessor: this.companyPropertyAccessor.bind(this, "jurisdiction"),
        id: "jurisdiction",
        className: "vertical-center",
        width: 40,
      },
    ];
  }

  private getTrProps(state, rowInfo, column) {
    return {
      rowInfo,
    };
  }

  private renderTableRow(props) {
    return (
      <TaskTableRow
        {...props}
        handleExpanderClick={this.handleExpanderClick}
        handleTaskSelectAllToggle={this.handleTaskSelectAllToggle}
        selectedTaskIds={this.props.selectedTaskIds}
      />
    );
  }

  private renderTextCell(cellInfo) {
    if (cellInfo.groupedByPivot) {
      return null;
    }

    const query = this.props.query;

    const loading = this.isReloadingTask(cellInfo.original.id);
    const value = cellInfo.value;
    return (
      <span className={loading ? "loading" : null}>
        {query ? HighlightUtils.highlightText(value, query) : value}
      </span>
    );
  }

  private renderEditableTaskCell(ComponentClass, propertyName, cellInfo) {
    const objectPermissions =
      cellInfo && cellInfo.original ? cellInfo.original.permissions : null;

    return this.renderEditableCell(
      ComponentClass,
      cellInfo,
      this.finishEditingTask.bind(this, propertyName),
      cannot({
        action: "update",
        modelName: "task",
        fieldName: propertyName,
        permissions: this.props.globalPermissions,
        objectPermissions,
      })
    );
  }

  // Factory method that instantiates react component
  private renderEditableCell(
    ComponentClass,
    cellInfo,
    onFinishEditing,
    disabled
  ) {
    return TableUtils.renderEditableCell({
      ComponentClass,
      cellInfo,
      onFinishEditing,
      loading:
        cellInfo &&
        cellInfo.original &&
        this.isReloadingTask(cellInfo.original.id),
      disabled,
    });
  }

  private renderCompletion(cellInfo) {
    if (cellInfo.groupedByPivot) {
      return null;
    }
    const id = cellInfo.original.id;

    if (this.isReloadingTask(id)) {
      return <Spinner intent={Intent.PRIMARY} size={16} />;
    }

    const isComplete = cellInfo.value;

    const objectPermissions =
      cellInfo && cellInfo.original ? cellInfo.original.permissions : null;
    const disabled = cannot({
      action: "update",
      modelName: "task",
      fieldName: "isComplete",
      permissions: this.props.globalPermissions,
      objectPermissions,
    });

    return (
      <Button
        className="is-complete"
        disabled={disabled}
        icon={isComplete ? "tick-circle" : "circle"}
        intent={isComplete ? Intent.SUCCESS : null}
        minimal={true}
        onClick={this.handleTaskCompletionToggle.bind(this, cellInfo.original)}
      />
    );
  }

  /**
   * returns check and cross filter header
   */
  private renderIsCompleteFilter({ filter, onChange }) {
    const handleChange = (event: any) => onChange(event.target.value);
    return (
      <select
        onChange={handleChange}
        style={{ width: "100%" }}
        value={filter ? filter.value : "all"}
      >
        <option value="all">All</option>
        <option value="true">{String.fromCharCode(10003)}</option>
        <option value="false">{String.fromCharCode(10007)}</option>
      </select>
    );
  }

  // Passes a function to know which attribute to get from the row to filter on
  private filterReviewer = (filter, row): boolean => {
    return this.filterUser(filter, row, (e) => e.reviewerId);
  };

  private filterPic = (filter, row): boolean => {
    return this.filterUser(filter, row, (e) => e.picId);
  };

  private filterAssignee = (filter, row): boolean => {
    return this.filterUser(filter, row, (e) => e.assigneeId);
  };

  private filterIsComplete(filter, row): boolean {
    switch (filter.value) {
      case "all":
        return true;
      case "true":
        return row.isComplete;
      case "false":
        return !row.isComplete;
      default:
        return false;
    }
  }

  /**
   * Method that returns boolean based on whether the User object has name or initials
   * that matches the passed in filter param
   * @param  {string} filter           Search string to filter on
   * @param  {object} row              Row object containing User object
   * @param  {function} userIdAccessor Function that returns correct attr from User object
   * @return {boolean}                 Whether or not filter is found in specific attr of row
   */
  private filterUser(filter, row, userIdAccessor): boolean {
    if (row._aggregated) {
      return true;
    }

    // Create array of candidates for matching
    const containsComma = filter.value.split(",").length > 1;
    const filterValues = containsComma
      ? filter.value.toLowerCase().split(",")
      : [filter.value.toLowerCase()];

    const userId = userIdAccessor(row._original);
    const userObj = this.props.usersById[userId]; // Returns user object
    const name = row[filter.id]; //
    const initials = userObj.abbreviation;

    let found = false;
    // Break early if candidate matches
    filterValues.forEach((filterValue) => {
      if (
        name.toLowerCase().startsWith(filterValue) ||
        initials.toLowerCase().startsWith(filterValue)
      ) {
        return (found = true);
      }
    });
    return found; // else match not found
  }

  private handleFilteredChanged() {
    const {
      sortedData: groups,
    } = this.tableRef.current.getWrappedInstance().state;
    if (isNullOrUndefined(groups) || groups.length === 0) {
      this.setState({
        ...this.state,
        expansionGroups: ExpansionGroups.Neither,
      });
    } else if (groups.length === 2) {
      this.setState({ ...this.state, expansionGroups: ExpansionGroups.Both });
    } else if (groups.length === 1) {
      if (groups[0].isCompletePivot === "true") {
        this.setState({
          ...this.state,
          expansionGroups: ExpansionGroups.RecentlyCompletedOnly,
        });
      } else {
        this.setState({
          ...this.state,
          expansionGroups: ExpansionGroups.IncompleteOnly,
        });
      }
    }
  }

  // Handles restructing the expansion state based on whether or not we have
  // completed tasks in the table. Otherwise, the indices for the expansion
  // state aren't dynamic so the first group is always mapped to recently completed
  // which is not correct when there are no recently completed tasks.
  private getRowExpansionState() {
    const expansionGroups = this.state.expansionGroups;

    switch (expansionGroups) {
      case ExpansionGroups.Both:
        return {
          0: this.state.expandRecentlyCompleted,
          1: this.state.expandIncomplete,
        };
      case ExpansionGroups.RecentlyCompletedOnly:
        return {
          0: this.state.expandRecentlyCompleted,
        };
      case ExpansionGroups.IncompleteOnly:
        return {
          0: this.state.expandIncomplete,
        };
      default:
        return {};
    }
  }

  private expansionGroupsFromTasks() {
    const { completedTasks, incompleteTasks } = this.props;
    const tasks = [...completedTasks, incompleteTasks];
    if (!tasks || tasks.length === 0) {
      return null;
    }

    return completedTasks.length > 0
      ? ExpansionGroups.Both
      : ExpansionGroups.IncompleteOnly;
  }

  private companyPropertyAccessor(key: string, task: ITask) {
    const companyId = task.clientCompanyId;
    const company = this.props.clientCompaniesById[companyId];
    return company[key];
  }

  private statusColor(task: ITask) {
    const statusId = task.statusId;
    const status = this.props.statusesById[statusId];
    return "";
  }

  private statusPropertyAccessor(key: string, task: ITask) {
    const statusId = task.statusId;
    const status = this.props.statusesById[statusId];
    return status[key];
  }

  private taskTypePropertyAccessor(key: string, task: ITask) {
    const taskTypeId = task.taskTypeId;
    const taskType = this.props.taskTypesById[taskTypeId];
    return taskType[key];
  }

  private clientGroupPropertyAccessor(key: string, task: ITask) {
    const company = this.props.clientCompaniesById[task.clientCompanyId];
    const clientGroup = this.props.clientGroupsById[company.clientGroupId];
    return clientGroup[key];
  }

  private createTask() {
    this.setState({ ...this.state, creating: true });
  }

  private finishEditingTask(
    key: string,
    taskId: string,
    newValue: string
  ): Promise<any> {
    const originalTask = this.props.tasksById[taskId];
    const originalValue = originalTask[key];

    if (originalValue !== newValue) {
      const updatedTask = { ...originalTask };
      updatedTask[key] = newValue;

      this.startReloadingTask(originalTask);
      return this.props.onUpdateTask(originalTask, updatedTask).finally(() => {
        return this.finishReloadingTask(originalTask);
      });
    }

    return Promise.resolve();
  }

  private handleExpanderClick(rowInfo) {
    if (rowInfo.row.isCompletePivot === "true") {
      this.setState({
        ...this.state,
        expandRecentlyCompleted: !this.state.expandRecentlyCompleted,
      });
    } else {
      this.setState({
        ...this.state,
        expandIncomplete: !this.state.expandIncomplete,
      });
    }
  }

  private async handleTaskCompletionToggle(task: ITask) {
    const updatedTask = { ...task, isComplete: !task.isComplete };
    this.startReloadingTask(task);
    await this.props.onUpdateTask(task, updatedTask);
    this.finishReloadingTask(task);
  }

  private handleTaskSelectionToggle(task: ITask) {
    const selectedTaskIds = this.props.selectedTaskIds;

    if (selectedTaskIds.has(task.id)) {
      this.props.onUnselectTasks([task.id]);
    } else {
      this.props.onSelectTasks([task.id]);
    }
  }

  private handleTaskSelectAllToggle(selectedAll: boolean, taskIds: string[]) {
    if (selectedAll) {
      this.props.onUnselectTasks(taskIds);
    } else {
      this.props.onSelectTasks(taskIds);
    }
  }

  private startReloadingTask(task: ITask) {
    const reloadingTasks = new Set(this.state.reloadingTasks);
    reloadingTasks.add(task.id);
    this.setState({ ...this.state, reloadingTasks });
    this.props.onStartReloadingRecord(task.id);
  }

  private finishReloadingTask(task: ITask) {
    const reloadingTasks = new Set(this.state.reloadingTasks);
    reloadingTasks.delete(task.id);
    this.setState({ ...this.state, reloadingTasks });
    this.props.onFinishReloadingRecord(task.id);
  }

  private isReloadingTask(taskId: string) {
    return (
      this.state.reloadingTasks.has(taskId) ||
      this.props.reloadingRecords.has(taskId)
    );
  }
}

function mapStateToProps(
  state: IEtsState,
  ownProps: ITaskTableOwnProps
): ITaskTableStateProps {
  const clientCompaniesById: IModelById<IClientCompany> = {};
  const statusesById = {};
  const tasksById = {};
  const taskTypesById = {};
  const usersById = {};
  const completedTasks = [];
  const incompleteTasks = [];
  const clientGroupsById: IModelById<IClientGroup> = {};

  // NOTE Whenever a foreign key is added to Task, please add here
  ownProps.tasks.forEach((task) => {
    const company = state.clientCompaniesById[task.clientCompanyId];
    clientCompaniesById[task.clientCompanyId] = company;
    clientGroupsById[company.clientGroupId] =
      state.clientGroupsById[company.clientGroupId];
    statusesById[task.statusId] = state.statusesById[task.statusId];
    tasksById[task.id] = task;
    taskTypesById[task.taskTypeId] = state.taskTypesById[task.taskTypeId];
    usersById[task.assigneeId] = state.usersById[task.assigneeId];
    usersById[task.reviewerId] = state.usersById[task.reviewerId];

    task.isComplete ? completedTasks.push(task) : incompleteTasks.push(task);
  });

  completedTasks.sort((a, b) => {
    return DateUtils.sortByDate(a.completedAt, b.completedAt);
  });

  const completedTasksLimit = ownProps.completedTasksLimit;
  const completedTasksFilter = ownProps.completedTasksFilter;
  let filteredCompletedTasks = completedTasks;
  if (completedTasksLimit) {
    filteredCompletedTasks = completedTasks.splice(0, completedTasksLimit);
  } else if (completedTasksFilter) {
    filteredCompletedTasks = completedTasks.filter(completedTasksFilter);
  }

  return {
    completedTasks: filteredCompletedTasks,
    incompleteTasks,
    clientCompaniesById,
    clientGroupsById,
    globalPermissions: currentPermissions(state),
    selectedTaskIds: state.selectedTaskIds,
    statusesById,
    tasksById,
    taskTypesById,
    usersById,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onUpdateTask: async (originalTask: ITask, updatedTask: ITask) => {
      dispatch(startOptimisticMode());
      dispatch(Api.Task.update(originalTask, updatedTask));
      dispatch({
        type: `${CRUD_UPDATE}_OPTIMISTIC`,
        payload: {
          id: originalTask.id,
          data: updatedTask,
          previousData: originalTask,
        },
        meta: {
          fetch: "UPDATE",
          resource: Constants.TASK_RESOURCE,
          optimistic: true,
        },
      });
      dispatch(stopOptimisticMode());
    },
    onViewCompany: (id: string) => {
      return dispatch(push(`${Constants.COMPANY_DETAIL_PATH}/${id}`));
    },
    onViewTask: (id: string) => {
      return dispatch(push(`${Constants.TASK_PATH}/${id}`));
    },
    onSelectTasks: (taskIds: string[]) => {
      return dispatch(selectTasks(taskIds));
    },
    onUnselectTasks: (taskIds: string[]) => {
      return dispatch(unselectTasks(taskIds));
    },
  };
}

export const ConnectedTaskTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(withDSFTableController(TaskTable, DSFModel.Task) as any);
