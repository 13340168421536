import { FormGroup, NumericInput } from "@blueprintjs/core";
import moment from "moment";
import React, { FC, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { IEtsState } from "../../model";

interface IDurationTimeInputProps {
  duration: string;
  onChange: (duration: string) => void;
  disabled?: boolean;
  max?: number;
}

export const DurationTimeInput: FC<IDurationTimeInputProps> = ({
  duration,
  disabled,
  onChange,
  max,
}) => {
  const isDaily = useSelector(
    (state: IEtsState) => state.departmentSetting.timebillDaily
  );
  const [intent, setIntent] = useState<
    "none" | "primary" | "success" | "warning" | "danger"
  >("none");
  const [helperText, setHelperText] = useState<string>(null);
  const handleValueChange = useCallback(
    (n: number) => {
      const newDuration = moment.duration(n, isDaily ? "d" : "h").toISOString();
      if (isDaily) {
        if (n % 0.25 !== 0) {
          setHelperText(
            "Invalid value, number should be product of 0.25 e.g. (0.25, 0.5, 1)"
          );
          setIntent("warning");
          return;
        }
        if (n > max) {
          setHelperText(`Cannot be greater than ${max}`);
          setIntent("warning");
          onChange(moment.duration(max, "d").toISOString());
          return;
        }
        setIntent("none");
        setHelperText(null);
      }
      onChange(newDuration);
    },
    [onChange, isDaily, setIntent, setHelperText]
  );

  return (
    <FormGroup intent={intent} helperText={helperText} style={{ margin: 0 }}>
      <NumericInput
        clampValueOnBlur={true}
        style={{ textAlign: "right", width: "100%" }}
        disabled={disabled}
        fill={true}
        max={max}
        min={0}
        minorStepSize={isDaily ? 0.25 : 0.1}
        onValueChange={handleValueChange}
        stepSize={isDaily ? 0.25 : 1}
        value={
          !!duration
            ? isDaily
              ? moment.duration(duration).asDays()
              : moment.duration(duration).asHours()
            : 0
        }
      />
    </FormGroup>
  );
};
