import { makeStyles } from "@material-ui/styles";
import { required } from "ra-core";
import { Create, SimpleForm, TextInput } from "ra-ui-materialui";
import * as React from "react";
import { PostCreateToolbar } from "../CreateToolBar";

const useStyles = makeStyles({
  firstName: { marginLeft: 10, display: "block" },
  lastName: { marginLeft: 10, display: "block" },
  email: { marginLeft: 10, display: "block" },
  card: { backgroundColor: "inherit", boxShadow: "0px 0px 0px 0px" },
});

export const ClientUserCreate: React.FC<any> = (props) => {
  const classes = useStyles({});
  const { clientGroupId, handleSuccess } = props;
  return (
    <Create
      {...props}
      classes={classes}
      location={{}}
      onSuccess={handleSuccess}
    >
      <SimpleForm
        toolbar={<PostCreateToolbar />}
        initialValues={{ clientGroupId }}
      >
        <>
          <TextInput
            label="First Name"
            source="firstName"
            validate={[required()]}
            className={classes.firstName}
          />
          <TextInput
            label="Last Name"
            source="lastName"
            validate={[required()]}
            className={classes.lastName}
          />
          <TextInput
            label="Email Address"
            source="email"
            validate={[required()]}
            className={classes.email}
          />
        </>
      </SimpleForm>
    </Create>
  );
};
