import { uniq } from "lodash";
import { useGetMany } from "ra-core";
import * as React from "react";
import { Constants } from "../../../constants";
import { ITask, ITimeEntry, ITimesheet } from "../../../model";
import { TimesheetTaskRow } from "./TimesheetTaskRow";

export interface ITimesheetTasksRowProps {
  timesheet?: ITimesheet;
  entries?: ITimeEntry[];
  onUpdateEntry?: (newEntry: ITimeEntry, previousEntry: ITimeEntry) => void;
}

export const TimesheetTasksRow: React.FC<ITimesheetTasksRowProps> = ({
  timesheet,
  entries,
  onUpdateEntry,
}) => {
  const { data, loaded } = useGetMany(
    Constants.TASK_RESOURCE,
    uniq(entries.map((entry) => entry.taskId))
  );

  if (!data || !loaded) {
    return null;
  }

  const tasks = data as ITask[];

  const renderTaskRow = (task) => (
    <TimesheetTaskRow
      key={task.id}
      task={task}
      timesheet={timesheet}
      entries={entries.filter((entry) => entry.taskId === task.id)}
      onUpdateEntry={onUpdateEntry}
    />
  );

  return <>{tasks.map((task) => task && renderTaskRow(task))}</>;
};
