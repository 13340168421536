import {
  LOCATION_CHANGE,
  onLocationChanged,
  push,
} from "connected-react-router";
import * as qs from "qs";
import { call, put, takeEvery } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";
import { Constants } from "../constants";
import {
  completeLogin,
  completeLogout,
  IAuthToken,
  successfulGetUserDetails,
} from "../core";
import { IEmailAction, IEmailActionType } from "../model";
import { createDataProvider } from "../utils/dataProvider";

const dataProvider = createDataProvider();

function* processApprovalAction({ action, resource, id }: IEmailAction) {
  switch (action) {
    case IEmailActionType.eicAccept:
      yield call(dataProvider.eicAccept, resource, { id });
      break;
    case IEmailActionType.eicReject:
      yield call(dataProvider.eicReject, resource, { id });
      break;
    case IEmailActionType.picAccept:
      yield call(dataProvider.picAccept, resource, { id });
      break;
    case IEmailActionType.picReject:
      yield call(dataProvider.picReject, resource, { id });
      break;
    default:
      break;
  }

  yield put(push(`${resource}/${id}`));
}

function* login(token: IAuthToken) {
  yield put(completeLogout());

  const user = yield call(dataProvider.validateToken, token);
  const { roles, department } = user;
  const userDetailAction = successfulGetUserDetails(user, roles, department.id);
  yield put(userDetailAction);

  yield put(completeLogin(token));
}

function* processEmailAction({
  payload: { location, action },
}: ActionType<typeof onLocationChanged>) {
  if (location.pathname !== "/email_action") {
    return;
  }

  try {
    const {
      token: accessToken,
      uid,
      client,
      expiry,
      type: tokenType,
      resource,
      id,
      action: emailAction,
    } = qs.parse(location.search?.slice(1) || "", { comma: true });
    const token = {
      accessToken: decodeURIComponent(accessToken as string),
      uid: decodeURIComponent(uid as string),
      client: client as string,
      expiry: expiry as string,
      tokenType: tokenType as string,
    };

    yield call(login, token);

    switch (emailAction) {
      case IEmailActionType.eicAccept:
      case IEmailActionType.eicReject:
      case IEmailActionType.picAccept:
      case IEmailActionType.picReject:
        yield processApprovalAction({
          action: emailAction,
          resource: resource as string,
          id: id as string,
        });
      default:
        break;
    }

    if (!!resource && !!id) {
      yield put(push(`/app/${resource}/${id}`));
    } else {
      yield put(push(Constants.APP_PATH));
    }
  } catch (error) {
    yield put(push(Constants.APP_PATH));
  }
}

export function* emailActionSaga() {
  yield takeEvery(LOCATION_CHANGE, processEmailAction);
}
