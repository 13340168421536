import { Card, Icon, Tab, Tabs } from "@blueprintjs/core";
import { push } from "connected-react-router";
import { useEditController } from "ra-core";
import { ReferenceManyField } from "ra-ui-materialui";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { RAHistoryTimeline } from "../../components/HistoryTimeline/RAHistoryTimeline";
import { Constants } from "../../constants";
import { IClientGroup } from "../../model";
import { copyEmailAddress } from "../../utils/email";
import { CopyEmail } from "../CompanyDetail/CopyEmail";
import { ClientGroupClientUserList } from "./ClientGroupClientUserList";
import { ClientGroupCompanies } from "./ClientGroupCompanies";
import { ClientGroupFormTab } from "./ClientGroupFormTab";
import { ClientGroupNaturalPersons } from "./ClientGroupNaturalPersons";

interface IClientGroupDetailProps {
  id: string;
  basePath: string;
  resource: string;
  match?: any;
}

export const ClientGroupDetail: React.FC<IClientGroupDetailProps> = (props) => {
  const {
    basePath,
    loaded,
    loading,
    record,
    resource,
    version,
    save,
    saving,
  } = useEditController({
    ...props,
    undoable: false,
  });

  const clientGroup = record as IClientGroup;

  const [tab, setTab] = useState(props?.match?.params?.tab ?? "companies");
  const dispatch = useDispatch();

  const handleTabChange = (selectedTab: string) => {
    dispatch(
      push(`${Constants.CLIENTGROUP_DETAIL_PATH}/${props.id}/${selectedTab}`)
    );
    setTab(selectedTab);
  };

  if (!clientGroup) {
    return null;
  }
  return (
    <div className="client-groups-detail top-padding">
      <div className="client-groups-header">
        <h2>{clientGroup.name} </h2>
        <CopyEmail
          emailAddress={copyEmailAddress(
            "client-group-id",
            clientGroup.emailAlias
          )}
          type="Client Group"
        />
      </div>
      <Tabs
        animate={true}
        onChange={handleTabChange}
        id="TabsExample"
        selectedTabId={tab}
      >
        <Tab
          id="companies"
          title={
            <>
              <Icon icon="office" /> Companies
            </>
          }
          panel={<ClientGroupCompanies clientGroup={clientGroup} />}
        />
        <Tab
          id="client_user"
          title={
            <>
              <Icon icon="person" /> Client Users
            </>
          }
          panel={<ClientGroupClientUserList clientGroupId={props.id} />}
        />
        <Tab
          id="form"
          title={
            <>
              <Icon icon="form" /> Forms
            </>
          }
          panel={<ClientGroupFormTab clientGroup={clientGroup} />}
        />
        <Tab
          id="natural_person"
          title={
            <>
              <Icon icon="user" /> Natural Persons
            </>
          }
          panel={
            <ReferenceManyField
              reference={Constants.NATURAL_PERSON_RESOURCE}
              resource={Constants.CLIENT_GROUP_RESOURCE}
              target={Constants.CLIENT_GROUP_RESOURCE}
              record={clientGroup}
              filter={{ clientGroupId: clientGroup.id }}
              basePath={Constants.CLIENTGROUP_DETAIL_PATH}
              perPage={100000}
            >
              <ClientGroupNaturalPersons clientGroupId={props.id} />
            </ReferenceManyField>
          }
        />
        <Tabs.Expander />
      </Tabs>
      {tab === "companies" && (
        <Card>
          <RAHistoryTimeline
            entryType="ClientGroup"
            entryId={props.id}
            basePath={basePath}
          />
        </Card>
      )}
    </div>
  );
};
