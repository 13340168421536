import { Button } from "@blueprintjs/core";
import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
} from "@material-ui/core";
import { push } from "connected-react-router";
import moment from "moment";
import { useGetOne } from "ra-core";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Constants } from "../../constants";
import { ITask } from "../../model";
import { AccountBalanceHeader } from "./AccountBalanceHeader";
import { BalanceEntryFilter } from "./BalanceEntryFilter";
import { BalanceEntryTable } from "./BalanceEntryTable";
import { CreateButton } from "./CreateButton";
import { ExpenseTable } from "./ExpenseTable";
import { TimeEntriesDownload } from "./TimeEntriesDownload";

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: theme.spacing(2, 0),
  },
  tableTitle: {
    marginTop: "30px",
  },
}));

export const AccountBalanceShow: React.FC<any> = (props) => {
  const { id } = props;
  const [range, setRange] = React.useState<[Date, Date]>([
    moment().startOf("M").toDate(),
    moment().endOf("D").toDate(),
  ]);
  const [showProvisional, setShowProvisional] = React.useState<boolean>(false);
  const { data, loaded } = useGetOne(Constants.TASK_RESOURCE, id);

  const dispatch = useDispatch();

  const classes = useStyles(props);

  if (!loaded || !data) {
    return null;
  }

  const task = data as ITask;
  const handleCheckProvisional = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setShowProvisional(checked);
  };

  const query = encodeURIComponent(JSON.stringify({ taskId: id }));
  const handleInvoiceClick = () => {
    dispatch(push(`${Constants.INVOICE_PATH}/?filter=${query}`));
  };
  const handleWriteOffClick = () => {
    dispatch(push(`${Constants.WRITE_OFF_PATH}/?filter=${query}`));
  };

  const handleExpenseClick = () => {
    dispatch(push(`${Constants.EXPENSES_PATH}/?filter=${query}`));
  };

  return (
    <Box mt={1}>
      <Container>
        <AccountBalanceHeader taskId={id} />
        <Divider className={classes.divider} />
        <Grid container={true}>
          <Grid
            container={true}
            item={true}
            justify="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item={true}>
              <BalanceEntryFilter onChange={setRange} range={range} />
            </Grid>
            <Grid item={true}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showProvisional}
                    onChange={handleCheckProvisional}
                    value="checkedProvisional"
                  />
                }
                label="Show provisional entries"
              />
            </Grid>
            <Grid
              item={true}
              container={true}
              justify="flex-end"
              spacing={2}
              sm={true}
            >
              <Grid item={true}>
                <TimeEntriesDownload taskId={id} />
              </Grid>
              <Grid item={true}>
                <Button onClick={handleInvoiceClick}>Fee Notes</Button>
              </Grid>
              <Grid item={true}>
                <Button onClick={handleWriteOffClick}>Write-offs</Button>
              </Grid>
              <Grid item={true}>
                <Button onClick={handleExpenseClick}>Expenses</Button>
              </Grid>
              <Grid item={true}>
                <CreateButton task={task} />
              </Grid>
            </Grid>
          </Grid>
          <h3 className={classes.tableTitle}>WIP Entries</h3>
          <BalanceEntryTable
            range={range}
            showProvisional={showProvisional}
            taskId={id}
          />
          <h3 className={classes.tableTitle}>Expense Entries</h3>
          <ExpenseTable
            range={range}
            showProvisional={showProvisional}
            taskId={id}
          />
        </Grid>
      </Container>
    </Box>
  );
};
