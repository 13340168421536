import { Icon } from "@blueprintjs/core";
import Downshift, { DownshiftProps } from "downshift";
import Fuse from "fuse.js";
import React from "react";
import { ISubtask } from "../../model";
import { fuseJsOptionConfig } from "../../utils/fuseJsSearch";

interface IDownshiftProps extends DownshiftProps<ISubtask> {
  items: ISubtask[];
}

export const SubtaskDownshift: React.FC<IDownshiftProps> = ({
  itemToString,
  items,
  ...rest
}) => {
  const options = fuseJsOptionConfig(["code", "category", "description"]);

  const fuse = new Fuse(items, options);

  const handleClear = (clearSelection) => clearSelection;

  const selectItems = (inputValue): ISubtask[] => {
    if (inputValue) {
      return fuse.search(inputValue);
    }
    return items;
  };

  return (
    <Downshift itemToString={itemToString} {...rest}>
      {({
        getInputProps,
        getItemProps,
        isOpen,
        inputValue,
        clearSelection,
        highlightedIndex,
      }) => (
        <div className="subtask-downshift">
          <div style={{ position: "relative" }}>
            <input
              {...getInputProps({
                placeholder: "select subtask",
                className: "subtask-downshift-input",
              })}
            />
            <button
              className="subtask-downshift-clear-button"
              onClick={handleClear(clearSelection)}
            >
              <Icon icon="cross" />
            </button>
          </div>
          <div
            className="subtask-downshift-list"
            style={{ display: isOpen ? "" : "none" }}
          >
            {isOpen &&
              selectItems(inputValue).map((item: ISubtask, index) => (
                <div
                  style={
                    highlightedIndex === index
                      ? { backgroundColor: "rgba(0,0,0,.03)" }
                      : {}
                  }
                  key={`${item.id}${index}`}
                  {...getItemProps({ item, index })}
                >
                  <ListItem subtask={item} />
                </div>
              ))}
          </div>
        </div>
      )}
    </Downshift>
  );
};

const ListItem: React.FC<{ subtask: ISubtask }> = ({ subtask }) => {
  const code = subtask.code && `${subtask.code}`;
  const category = subtask.category && `${subtask.category},`;

  const renderCodeAndCategory = () => {
    if (subtask.code || subtask.category) {
      return (
        <span className="subtask-downshift-list-item-category">
          {category} &nbsp; {code}
        </span>
      );
    }
  };

  return (
    <div className="subtask-downshift-list-item">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>{subtask.description}</span>
        {renderCodeAndCategory()}
      </div>
    </div>
  );
};
