import { Button, Intent } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { Constants } from "../../constants";
import * as DateUtils from "../../utils/date";
import { ClientCompanyDateSpan } from "./ClientCompanyDateSpan";

interface ICascadingKeys {
  lastDateKey: string;
  nextDateKey: string;
}
interface IClientCompanyDateFieldsProps {
  dateName: string;
  dateKey: string;
  firstDate: string;
  lastDate: string;
  nextDate: string;

  updateDate: (
    key: string,
    cascadingKeys?: ICascadingKeys
  ) => (selectedDate: Date, isUserChange: boolean) => void;
  completeNextYear: (
    lastDatekey: string,
    nextDateKey: string,
    dateString: string
  ) => () => void;
}

export const ClientCompanyDateFields: React.FC<IClientCompanyDateFieldsProps> = ({
  dateName,
  dateKey,
  firstDate,
  lastDate,
  nextDate,
  updateDate,
  completeNextYear,
}) => {
  const firstDateKey =
    "first" + dateKey.charAt(0).toUpperCase() + dateKey.slice(1);
  const lastDateKey =
    "last" + dateKey.charAt(0).toUpperCase() + dateKey.slice(1);
  const nextDateKey =
    "next" + dateKey.charAt(0).toUpperCase() + dateKey.slice(1);

  const isNextDateCompletable = (dateString: string) => {
    const nextYear = moment(dateString).year();
    const currYear = moment().year();
    if (nextYear > currYear) {
      return false;
    }
    return true;
  };

  const deriveNextDateString = () => {
    if (nextDate) {
      return nextDate;
    } else if (lastDate) {
      return DateUtils.formatFriendlyDate(
        DateUtils.parseDatePlusOneYear(lastDate)
      );
    } else if (firstDate) {
      return firstDate;
    }
    return null;
  };

  const derivedNextDateString = deriveNextDateString();

  const getNextDateBottomLimit = () => {
    if (lastDate) {
      return moment(lastDate).add(1, "year").startOf("year").toDate();
    } else if (firstDate) {
      return DateUtils.parseDate(firstDate);
    } else {
      return Constants.MIN_DATE;
    }
  };

  const getFirstDateUpperLimit = () => {
    if (lastDate) {
      return DateUtils.parseDate(lastDate);
    } else if (nextDate) {
      return DateUtils.parseDate(nextDate);
    } else {
      return Constants.MAX_DATE;
    }
  };

  return (
    <>
      <Grid item={true} xs={3}>
        First {dateName}:
      </Grid>
      <Grid item={true} xs={9}>
        <DateInput
          canClearSelection={false}
          value={DateUtils.parseDate(firstDate)}
          formatDate={DateUtils.formatFriendlyDate}
          parseDate={DateUtils.parseDateStrict}
          onChange={updateDate(firstDateKey, { lastDateKey, nextDateKey })}
          minDate={Constants.MIN_DATE}
          maxDate={getFirstDateUpperLimit()}
        />
      </Grid>
      <Grid item={true} xs={3} />
      <Grid
        item={true}
        xs={9}
        justify="flex-start"
        className="company-detail-date-input"
      >
        <ClientCompanyDateSpan firstDate={firstDate} lastDate={lastDate} />
      </Grid>
      <Grid item={true} xs={3}>
        Next {dateName}:
      </Grid>
      <Grid
        item={true}
        xs={9}
        justify="flex-start"
        className={`company-detail-date-input bottom-margin ${
          !firstDate && "input-disabled"
        }`}
      >
        <DateInput
          canClearSelection={false}
          minDate={getNextDateBottomLimit()}
          maxDate={Constants.MAX_DATE}
          value={DateUtils.parseDate(derivedNextDateString)}
          formatDate={DateUtils.formatFriendlyDate}
          parseDate={DateUtils.parseDateStrict}
          onChange={updateDate(nextDateKey)}
        />
        {derivedNextDateString &&
          isNextDateCompletable(derivedNextDateString) && (
            <Button
              intent={Intent.PRIMARY}
              icon="confirm"
              text="Complete"
              type="submit"
              className="client-company-date-complete-button"
              onClick={completeNextYear(
                lastDateKey,
                nextDateKey,
                derivedNextDateString
              )}
            />
          )}
      </Grid>
    </>
  );
};
