import { FormLabel, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useGetMatching, useInput } from "ra-core";
import React, { useEffect } from "react";
import {
  FormDataConsumer,
  minValue,
  number,
  NumberField,
  NumberInput,
  required,
  TextInput,
} from "react-admin";
import { Constants } from "../../constants";
import { IInvoice, IWriteOff } from "../../model";
import { AmountFormField } from "./AmountFormField";

const useStyles = makeStyles(() => ({
  settle: {
    marginTop: 0,
  },
}));

const WriteOffField: React.FC<any> = ({ formData, amount, source, label }) => {
  return (
    <Grid item={true}>
      <FormLabel component="legend">{`${label} to write off`}</FormLabel>
      <NumberField
        record={{ amount: formData[source] - amount }}
        source="amount"
        options={{ style: "currency", currency: "HKD" }}
      />
    </Grid>
  );
};

const WriteOffInput: React.FC<any> = ({
  record,
  type,
  label,
  disabled,
  source,
  ...rest
}) => {
  const { data, loaded, loading } = useGetMatching(
    Constants.WRITE_OFF_RESOURCE,
    { page: 1, perPage: 1 },
    { field: "", order: "" },
    { invoiceId: record.id, entryType: type },
    source,
    Constants.INVOICE_RESOURCE
  );
  const {
    input: { onChange, value },
    meta: { touched },
  } = useInput({
    record,
    type,
    label,
    disabled,
    source,
    ...rest,
  });
  const writeOffs = data as IWriteOff[];

  useEffect(() => {
    if (!touched && !value && writeOffs && loaded && !loading) {
      const event = { target: { value: writeOffs?.[0]?.description } };
      onChange(event);
    }
  }, [writeOffs, touched]);

  if (!writeOffs) {
    return null;
  }
  return (
    <TextInput
      source={source}
      label={label}
      validate={[required()]}
      disabled={disabled}
      fullWidth={true}
    />
  );
};

const BalanceInputImpl: React.FC<any> = ({
  record,
  type,
  label,
  disabled,
  formData,
}) => {
  const classes = useStyles({});
  const amount = (formData.lineItems || [])
    .filter((item) => item?.entryType === type)
    .reduce((sum, item) => sum + parseFloat(item.amount) || 0, 0);
  const source = type === "wip" ? "amtToSettle" : "expenseToSettle";
  const hasWriteOff = formData[source] > amount;

  if (!record) {
    return null;
  }

  return (
    <>
      <Grid container={true} spacing={3}>
        <Grid item={true}>
          <NumberInput
            source={source}
            label={`${label} to settle`}
            validate={[required(), number(), minValue(0)]}
            disabled={disabled}
            record={record}
            className={classes.settle}
          />
        </Grid>
        <Grid item={true} xs={3}>
          <AmountFormField
            type={type}
            record={record}
            label={`${label} to bill`}
            amount={amount}
          />
        </Grid>
        {hasWriteOff && (
          <WriteOffField
            amount={amount}
            record={record}
            source={source}
            label={label}
            formData={formData}
          />
        )}
      </Grid>
      {hasWriteOff && record.id && (
        <WriteOffInput
          record={record}
          type={type}
          label={`${label} write off description`}
          disabled={disabled}
          source={`${type}WriteOff.description`}
        />
      )}
      {hasWriteOff && !record.id && (
        <TextInput
          source={`${type}WriteOff.description`}
          label={`${label} write off description`}
          validate={[required()]}
          disabled={disabled}
          fullWidth={true}
        />
      )}
    </>
  );
};

interface IBalanceInputProps {
  type?: "wip" | "expense";
  label?: string;
  disabled?: boolean;
  record?: Partial<IInvoice>;
}

export const BalanceInput: React.FC<IBalanceInputProps> = (props) => (
  <FormDataConsumer>
    {({ formData, ...rest }) => (
      <BalanceInputImpl {...props} formData={formData} />
    )}
  </FormDataConsumer>
);
