import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import React from "react";
import { ReferenceField, TextField } from "react-admin";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Constants } from "../../constants";
import { IEtsState, IHourByUser, IHourRecord } from "../../model";
import { durationToStringFormat } from "../../utils/date";
import { priceFormatter } from "../../utils/money";

const hourShowStyle = makeStyles({
  leftBorderBox: {
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  rightBorderBox: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderBottom: "none",
  },
  noBorderBottom: {
    borderBottom: "none",
  },
  headerLeftBorderBox: {
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
  headerRightBorderBox: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
  headerDescription: {
    maxWidth: "280px",
  },
  headerClientCompany: {
    maxWidth: "150px",
  },
  headerStartDate: {
    minWidth: "100",
  },
  description: {
    maxWidth: "280px",
    borderBottom: "none",
  },
  clientCompany: {
    maxWidth: "150px",
    borderBottom: "none",
  },
  startDate: {
    minWidth: "100",
    borderBottom: "none",
  },
});

export const HourDetailShow: React.FC<any> = (props) => {
  const { records } = props.record as IHourByUser;
  const type = props.type;
  const isDaily = useSelector(
    (state: IEtsState) => state.departmentSetting.timebillDaily
  );

  const classes = hourShowStyle({});
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">
            {type === "user" ? "Job" : "Staff"}
          </TableCell>
          {type === "user" && (
            <TableCell align="right" className={classes.headerClientCompany}>
              Client Company
            </TableCell>
          )}
          {type === "user" && (
            <TableCell align="right" className={classes.headerStartDate}>
              Start Date
            </TableCell>
          )}
          <TableCell align="right" className={classes.headerLeftBorderBox}>
            {`Budgeted ${isDaily ? "Days" : "Hours"}`}
          </TableCell>
          <TableCell align="right" className={classes.headerRightBorderBox}>
            {`Actual ${isDaily ? "Days" : "Hours"}`}
          </TableCell>
          <TableCell align="right">Rate</TableCell>
          <TableCell align="right" className={classes.headerLeftBorderBox}>
            Budgeted WIP
          </TableCell>
          <TableCell align="right" className={classes.headerRightBorderBox}>
            Actual WIP
          </TableCell>
          <TableCell align="right">Variance</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {records.map((record) => (
          <TableRow key={record.taskId}>
            <TableCell align="left" className={classes.description}>
              <JobDescriptionCell record={record} type={type} />
            </TableCell>
            {type === "user" && (
              <TableCell align="right" className={classes.clientCompany}>
                <Link
                  to={`${Constants.COMPANY_DETAIL_PATH}/${record.clientCompanyId}`}
                >
                  {record.clientCompanyName}
                </Link>
              </TableCell>
            )}
            {type === "user" && (
              <TableCell align="right" className={classes.startDate}>
                {record.taskStartDate}
              </TableCell>
            )}
            <TableCell align="right" className={classes.leftBorderBox}>
              {durationToStringFormat(record.estTime, isDaily)}
            </TableCell>
            <TableCell align="right" className={classes.rightBorderBox}>
              {durationToStringFormat(record.actualTime, isDaily)}
            </TableCell>
            <TableCell align="right" className={classes.noBorderBottom}>
              {priceFormatter.format(Number(record.rate))}
            </TableCell>
            <TableCell align="right" className={classes.leftBorderBox}>
              {priceFormatter.format(Number(record.estAmount))}
            </TableCell>
            <TableCell align="right" className={classes.rightBorderBox}>
              <ActualAmountField record={record} />
            </TableCell>
            <TableCell align="right" className={classes.noBorderBottom}>
              {priceFormatter.format(
                Number(record.actualAmount) - Number(record.estAmount)
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

interface IActualAmountFieldProps {
  record: IHourRecord;
}

const ActualAmountField: React.FC<IActualAmountFieldProps> = ({ record }) => {
  const { rate, actualTime, actualAmount } = record;
  const amount = Number(rate) * moment.duration(actualTime).asHours();

  const actualAmountString = priceFormatter.format(Number(actualAmount));

  if (!actualTime || amount.toFixed(2) === actualAmount) {
    return <>{actualAmountString}</>;
  }

  return (
    <Tooltip title="The rate may be changed">
      <span>{actualAmountString}*</span>
    </Tooltip>
  );
};

interface IJobDescriptionCellProps {
  record: IHourRecord;
  type: string;
}

const JobDescriptionCell: React.FC<IJobDescriptionCellProps> = ({
  record,
  type,
}) => {
  const auth = useSelector((state: IEtsState) => state.auth);
  const canLink = record.departmentId === auth.departmentId;
  return (
    <>
      {type === "user" ? (
        <ReferenceField
          resource={Constants.HOURS_BY_JOB_RESOURCE}
          reference={Constants.TASK_RESOURCE}
          basePath={Constants.HOUR_BY_JOB_PATH}
          link={canLink}
          addLabel={false}
          record={record}
          label="Job"
          source="taskId"
        >
          <TextField source="description" />
        </ReferenceField>
      ) : (
        record.userName
      )}
    </>
  );
};
