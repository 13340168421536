import React, { useEffect, useState } from "react";
import Truncate from "react-truncate";

interface IReadMoreDefaultProps {
  lines: number;
  more: string;
  less: string;
}

interface IReadMoreStateProps {
  expanded: boolean;
  truncated: boolean;
}

interface IReadMoreProps extends IReadMoreDefaultProps, IReadMoreStateProps {}

export const ReadMore = (props) => {
  const { children, more, less, lines, expanded, truncated } = props;

  const [expandedState, setExpandedState] = useState(expanded);
  const [truncatedState, setTruncatedState] = useState(truncated);

  useEffect(() => {
    setExpandedState(props.expanded);
  }, [props.expanded]);

  const linething = (children as string).split("\n").map((sentence, i, arr) => {
    const line = <span key={i}>{sentence}</span>;

    if (i === arr.length - 1) {
      return line;
    }
    return [line, <br key={i + "br"} />];
  });

  const handleTruncate = (t) => {
    if (truncatedState !== t) {
      setTruncatedState(t);
    }
  };

  const toggleLines = (event) => {
    event.preventDefault();
    props.updateExpanded(!expandedState);
  };

  return (
    <div>
      <Truncate
        lines={!expandedState && lines}
        ellipsis={
          <span>
            ...{" "}
            <a href="#" onClick={toggleLines}>
              {more}
            </a>
          </span>
        }
        onTruncate={handleTruncate}
      >
        {linething}
      </Truncate>
      {!truncatedState && expandedState && (
        <span>
          <a href="#" onClick={toggleLines}>
            {less}
          </a>
        </span>
      )}
    </div>
  );
};
