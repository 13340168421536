export const formatPercentage = (value: string | number) => {
  return `${(Number(value) * 100).toFixed(1)}%`;
};

export const calculatePercentage = (value1, value2) => {
  const v1 = Number(value1);
  const v2 = Number(value2);

  if (v2 === 0 || (v1 === 0 && v2 === 0)) {
    return "0.0%";
  }

  return formatPercentage(v1 / v2);
};
