import { Button } from "@blueprintjs/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IEtsState } from "../../model";
import { IEtsUser } from "../../model/IEtsUser";
import { switchUser } from "./switch";
import { UserDetail } from "./UserDetail";

interface IUserListProps {
  users: IEtsUser[];
  internal: boolean;
}

export const UserList: React.FC<IUserListProps> = ({ users, internal }) => {
  return (
    <ul className="bp3-menu .modifier bp3-elevation-1">
      {internal ? (
        <UserListInput />
      ) : (
        users.map((user) => <UserDetail user={user} key={user.id} />)
      )}
    </ul>
  );
};

const UserListInput: React.FC = () => {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const token = useSelector((state: IEtsState) => state.auth.token);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserId(event.currentTarget.value);
  };

  const handleOnclick = () => {
    if (userId) {
      dispatch(switchUser(userId, token));
    }
  };

  return (
    <>
      <input
        className="bp3-input"
        placeholder="Enter user id."
        onChange={handleOnChange}
      />
      &nbsp;
      <Button text="Go" onClick={handleOnclick} />
    </>
  );
};
