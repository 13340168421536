import { Api } from "../../actions";
import { TaskForm } from "../forms";
import { buildModelDialog } from "./ModelDialog";

export const TaskDialog = buildModelDialog({
  defaultDialogProps: {
    className: "task-dialog",
    icon: "tick-circle",
    title: "Create a job",
  },
  Form: TaskForm,
  modelApi: Api.Task,
  dataApi: Api.TaskAdditionalDataRecord,
  foreignKeyProperty: "taskId",
});
