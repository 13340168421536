import { Icon } from "@blueprintjs/core";
import { IModelById, UserUtil } from "@chiubaka/core";
import React from "react";
import { connect } from "react-redux";
import { isNullOrUndefined } from "util";
import { IChatThread, IEtsState, IEtsUser } from "../../model";
import * as DateUtils from "../../utils/date";
import * as UserUtils from "../../utils/user";
import { ChatMessageImpl } from "./ChatMessage/ChatMessage";

interface IChatThreadStateProps {
  entry: IChatThread;
}
interface IChatThreadUserProps {
  usersById: IModelById<IEtsUser>;
}
interface IChatThreadProps
  extends IChatThreadStateProps,
    IChatThreadUserProps {}

class ChatThreadImpl extends React.Component<IChatThreadProps> {
  public render() {
    return this.renderChatThread(this.props.entry);
  }

  private renderChatMessage = (entry: IChatThread) => {
    return entry.messages.map((data) => {
      return <ChatMessageImpl chatmessage={data} key={data.id} />;
    });
  };

  private renderHeader = (
    isGroupChat: boolean,
    createdTime: string,
    createdByName: string
  ) => {
    return isGroupChat
      ? `Added to group chat on ${createdTime}`
      : `${createdByName} fowarded on ${createdTime}`;
  };

  private renderChatThread = (entry: IChatThread) => {
    let createdByInitials = "AN";
    let createdByName = "Unknown";
    let createdByStyle = { backgroundColor: "$baby-blue" };
    let isGroupChat = false;
    const { createdById, threadType, createdAt } = entry;

    if (
      !isNullOrUndefined(this.props.usersById) &&
      !isNullOrUndefined(createdById)
    ) {
      const createdBy = this.props.usersById[createdById];
      if (threadType === "group_chat") {
        createdByInitials = "GC";
        createdByName = "GroupChat";
        isGroupChat = true;
      }
      if (threadType === "manual") {
        createdByInitials = createdBy.abbreviation;
        createdByName = UserUtil.displayValue(createdBy, true);
        createdByStyle = UserUtils.getStyleFromUser(createdBy);
        isGroupChat = false;
      }
    }
    const createdTime = DateUtils.formatDate(
      DateUtils.parseDate(this.props.entry.createdAt)
    );
    return (
      <div className="chat-thread-wrapper">
        <div className="chat-thread-avatar" style={createdByStyle}>
          {createdByInitials}
        </div>
        <div className="chat-thread-border">
          <div className="chat-thread-header">
            <span>
              {this.renderHeader(isGroupChat, createdTime, createdByName)}
              <Icon className="icon-align-center" icon="chat" />
            </span>
          </div>
          <div className="chat-message">{this.renderChatMessage(entry)}</div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state: IEtsState): IChatThreadUserProps => {
  const usersById = state.usersById;
  return { usersById };
};

export const ChatThread = connect(mapStateToProps)(ChatThreadImpl);
