import has from "lodash/has";
import { ReduxState } from "ra-core";
import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import {
  AccountBalanceShow,
  ActivityFeedShow,
  AdjustmentCreate,
  AdjustmentEdit,
  AmountToBillList,
  BankStatementCSVUploader,
  CreditNoteCreate,
  CreditNoteEdit,
  CreditNoteList,
  CreditNotesReportList,
  CustomReport,
  DateTimeline,
  DepartmentsAccountBalancesList,
  ExpenseCreate,
  ExpenseEdit,
  ExpenseList,
  FeeNoteLineItemsForm,
  FeeNotesList,
  HourByJobList,
  HourByUserList,
  InvoiceCreate,
  InvoiceEdit,
  InvoiceList,
  PaymentCreate,
  PaymentEdit,
  PaymentList,
  PicAccountBalancesList,
  TaskAccountBalanceList,
  TimesheetCreate,
  TimesheetEdit,
  TimesheetList,
  WriteOffCreate,
  WriteOffEdit,
  WriteOffList,
} from "../components";
import { Constants } from "../constants";
import {
  ClientGroupDetail,
  CompanyDetail,
  CompanyIndex,
  Department,
  Inbox,
  Notifications,
  Review,
  Search,
  Settings,
  TaskKanban,
  TaskPage,
  Team,
  Today,
  Week,
} from "../pages";

export const AppRouter = () => {
  const isRegistered = useSelector(({ admin: { resources } }: ReduxState) =>
    Constants.RESOURCES.reduce(
      (result, resource) => result && has(resources, resource),
      true
    )
  );

  if (!isRegistered) {
    return null;
  }

  return (
    <Switch>
      <Redirect
        exact={true}
        from={Constants.APP_PATH}
        to={`${Constants.INBOX_PATH}?isComplete=false`}
      />
      <Route path={Constants.INBOX_PATH} component={Inbox} />
      <Route path={Constants.TIMELINE_PATH} component={DateTimeline} />
      <Route path={Constants.KANBAN_PATH} component={TaskKanban} />
      <Route path={Constants.TODAY_PATH} component={Today} />
      <Route path={Constants.WEEK_PATH} component={Week} />
      <Route path={Constants.TEAM_PATH} component={Team} />
      <Route path={Constants.DEPARTMENT_PATH} component={Department} />
      <Route path={`${Constants.TASK_PATH}/:id`} component={TaskPage} />
      <Route
        path={Constants.COMPANY_DETAIL_PATH}
        component={CompanyIndex}
        exact={true}
      />
      <Route
        exact={true}
        path={`${Constants.ACCOUNT_BALANCE_PATH}/:id`}
        render={(props) => (
          <AccountBalanceShow
            resource={Constants.ACCOUNT_BALANCE_RESOURCE}
            {...props}
            id={props.match.params.id}
            basePath={Constants.ACCOUNT_BALANCE_PATH}
          />
        )}
      />
      <Route path={Constants.SEARCH_PATH} component={Search} />
      <Route path={Constants.REVIEW_PATH} component={Review} />
      <Route path={Constants.NOTIFICATIONS_PATH} component={Notifications} />
      <Route path={Constants.SETTINGS_PATH} component={Settings} />
      <Route
        path={Constants.EXPENSES_PATH}
        component={(props) => (
          <ExpenseList
            resource={Constants.EXPENSE_RESOURCE}
            {...props}
            hasCreate={true}
            hasList={true}
            hasShow={false}
            hasEdit={true}
            basePath="/app/expenses"
          />
        )}
        exact={true}
      />
      <Route
        path={`${Constants.EXPENSES_PATH}/create`}
        component={(props) => (
          <ExpenseCreate
            {...props}
            resource={Constants.EXPENSE_RESOURCE}
            basePath="/app/expenses"
          />
        )}
        exact={true}
      />
      <Route
        exact={true}
        path={`${Constants.EXPENSES_PATH}/:id`}
        component={(props) => (
          <ExpenseEdit
            {...props}
            resource={Constants.EXPENSE_RESOURCE}
            basePath="/app/expenses"
            id={props.match.params.id}
          />
        )}
      />
      <Route
        exact={true}
        path={Constants.TIMESHEET_PATH}
        render={(props) => (
          <TimesheetList
            resource={Constants.TIMESHEET_RESOURCE}
            {...props}
            hasCreate={true}
            hasEdit={true}
            hasShow={false}
            hasList={true}
            basePath={Constants.TIMESHEET_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={`${Constants.TIMESHEET_PATH}/create`}
        render={(props) => (
          <TimesheetCreate
            {...props}
            resource={Constants.TIMESHEET_RESOURCE}
            basePath={Constants.TIMESHEET_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={`${Constants.TIMESHEET_PATH}/:id`}
        render={(props) => (
          <TimesheetEdit
            {...props}
            resource="timesheets"
            id={props.match.params.id}
            basePath={`${Constants.TIMESHEET_PATH}?`}
          />
        )}
      />
      <Route
        exact={true}
        path={Constants.CREDIT_NOTE_PATH}
        render={(props) => (
          <CreditNoteList
            resource={Constants.CREDIT_NOTE_RESOURCE}
            {...props}
            hasCreate={true}
            hasEdit={true}
            hasShow={false}
            hasList={true}
            basePath={Constants.CREDIT_NOTE_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={`${Constants.CREDIT_NOTE_PATH}/create`}
        render={(props) => (
          <CreditNoteCreate
            resource={Constants.CREDIT_NOTE_RESOURCE}
            {...props}
            basePath={Constants.CREDIT_NOTE_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={`${Constants.CREDIT_NOTE_PATH}/:id`}
        render={(props) => (
          <CreditNoteEdit
            resource="credit_notes"
            {...props}
            id={props.match.params.id}
            basePath={Constants.CREDIT_NOTE_PATH}
          />
        )}
      />
      <Route
        exact={false}
        path={`${Constants.CLIENTGROUP_DETAIL_PATH}/:id/:tab?`}
        render={(props) => (
          <ClientGroupDetail
            resource={Constants.CLIENT_GROUP_RESOURCE}
            {...props}
            id={props.match.params.id}
            basePath={Constants.CLIENTGROUP_DETAIL_PATH}
          />
        )}
      />
      <Route
        path={`${Constants.COMPANY_DETAIL_PATH}/:id/:tab?`}
        render={(props) => (
          <CompanyDetail
            resource={Constants.CLIENT_COMPANY_RESOURCE}
            {...props}
            id={props.match.params.id}
            basePath={Constants.COMPANY_DETAIL_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={Constants.PAYMENT_PATH}
        render={(props) => (
          <PaymentList
            resource={Constants.PAYMENT_RESOURCE}
            {...props}
            hasCreate={true}
            hasEdit={true}
            hasShow={false}
            hasList={true}
            basePath={Constants.PAYMENT_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={`${Constants.PAYMENT_PATH}/create`}
        render={(props) => (
          <PaymentCreate
            resource={Constants.PAYMENT_RESOURCE}
            {...props}
            basePath={Constants.PAYMENT_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={`${Constants.PAYMENT_PATH}/:id`}
        render={(props) => (
          <PaymentEdit
            resource={Constants.PAYMENT_RESOURCE}
            {...props}
            id={props.match.params.id}
            basePath={Constants.PAYMENT_PATH}
          />
        )}
      />
      <Route
        path={`${Constants.INVOICE_PATH}/create`}
        render={(props) => (
          <InvoiceCreate
            {...props}
            resource={Constants.INVOICE_RESOURCE}
            basePath={Constants.INVOICE_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={`${Constants.INVOICE_PATH}/:id`}
        render={(props) => (
          <InvoiceEdit
            {...props}
            resource={Constants.INVOICE_RESOURCE}
            id={props.match.params.id}
            basePath={Constants.INVOICE_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={Constants.BANK_STATEMENT_GENERATOR_PATH}
        component={BankStatementCSVUploader}
      />
      <Route
        exact={true}
        path={Constants.INVOICE_PATH}
        render={(props) => (
          <InvoiceList
            resource={Constants.INVOICE_RESOURCE}
            {...props}
            hasCreate={true}
            hasEdit={false}
            hasShow={false}
            hasList={true}
            basePath={Constants.INVOICE_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={`${Constants.WRITE_OFF_PATH}/`}
        render={(props) => (
          <WriteOffList
            resource={Constants.WRITE_OFF_RESOURCE}
            {...props}
            hasCreate={true}
            hasList={true}
            hasEdit={true}
            hasShow={false}
            basePath={Constants.WRITE_OFF_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={`${Constants.WRITE_OFF_PATH}/create`}
        render={(props) => (
          <WriteOffCreate
            resource={Constants.WRITE_OFF_RESOURCE}
            {...props}
            basePath={Constants.WRITE_OFF_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={`${Constants.WRITE_OFF_PATH}/:id`}
        render={(props) => (
          <WriteOffEdit
            {...props}
            resource={Constants.WRITE_OFF_RESOURCE}
            id={props.match.params.id}
            basePath={Constants.WRITE_OFF_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={`${Constants.ADJUSTMENT_PATH}/create`}
        render={(props) => (
          <AdjustmentCreate
            {...props}
            resource={Constants.ADJUSTMENT_RESOURCE}
            basePath={Constants.ADJUSTMENT_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={`${Constants.ADJUSTMENT_PATH}/:id`}
        render={(props) => (
          <AdjustmentEdit
            {...props}
            resource={Constants.ADJUSTMENT_RESOURCE}
            id={props.match.params.id}
            basePath={Constants.ADJUSTMENT_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={Constants.TASKS_ACCOUNT_BALANCE_PATH}
        render={(props) => (
          <TaskAccountBalanceList
            {...props}
            resource={Constants.TASKS_ACCOUNT_BALANCE_RESOURCE}
            hasCreate={false}
            hasEdit={false}
            hasShow={false}
            hasList={true}
            basePath={Constants.TASKS_ACCOUNT_BALANCE_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={Constants.DEPARTMENTS_ACCOUNT_BALANCE_PATH}
        render={(props) => (
          <DepartmentsAccountBalancesList
            {...props}
            resource={Constants.DEPARTMENTS_ACCOUNT_BALANCE_RESOURCE}
            hasCreate={false}
            hasEdit={false}
            hasShow={false}
            hasList={true}
            basePath={Constants.DEPARTMENTS_ACCOUNT_BALANCE_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={Constants.PIC_ACCOUNT_BALANCE_PATH}
        render={(props) => (
          <PicAccountBalancesList
            {...props}
            resource={Constants.PIC_ACCOUNT_BALANCE_RESOURCE}
            hasCreate={false}
            hasEdit={false}
            hasShow={false}
            hasList={true}
            basePath={Constants.PIC_ACCOUNT_BALANCE_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={Constants.AMOUNT_TO_BILL_PATH}
        render={(props) => (
          <AmountToBillList
            {...props}
            hasCreate={false}
            hasEdit={false}
            hasShow={false}
            hasList={true}
            resource={Constants.AMOUNT_TO_BILL_RESOURCE}
            basePath={Constants.AMOUNT_TO_BILL_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={Constants.FEE_NOTES_LIST_PATH}
        render={(props) => (
          <FeeNotesList
            {...props}
            resource={Constants.FEE_NOTES_LIST_RESOURCE}
            hasCreate={false}
            hasEdit={false}
            hasShow={false}
            hasList={true}
            basePath={Constants.FEE_NOTES_LIST_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={Constants.CREDIT_NOTES_LIST_PATH}
        render={(props) => (
          <CreditNotesReportList
            {...props}
            resource={Constants.CREDIT_NOTE_LIST_RESOURCE}
            hasCreate={false}
            hasEdit={false}
            hasShow={false}
            hasList={true}
            basePath={Constants.CREDIT_NOTES_LIST_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={Constants.FEE_NOTE_LINE_ITEMS_PATH}
        render={(props) => <FeeNoteLineItemsForm {...props} />}
      />
      <Route
        exact={true}
        path={Constants.HOUR_BY_JOB_PATH}
        render={(props) => (
          <HourByJobList
            {...props}
            resource={Constants.HOURS_BY_JOB_RESOURCE}
            hasCreate={false}
            hasEdit={false}
            hasShow={false}
            hasList={true}
            basePath={Constants.HOUR_BY_JOB_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={Constants.HOUR_BY_USER_PATH}
        render={(props) => (
          <HourByUserList
            {...props}
            resource={Constants.HOURS_BY_USER_RESOURCE}
            hasCreate={false}
            hasEdit={false}
            hasShow={false}
            hasList={true}
            basePath={Constants.HOUR_BY_USER_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={Constants.CUSTOM_REPORT_PATH}
        render={(props) => (
          <CustomReport
            {...props}
            resource={Constants.CUSTOM_REPORT_RESOURCE}
            hasCreate={false}
            hasEdit={false}
            hasShow={false}
            hasList={true}
            basePath={Constants.CUSTOM_REPORT_PATH}
          />
        )}
      />
      <Route
        exact={true}
        path={Constants.ACTIVITY_FEED_PATH}
        render={(props) => (
          <ActivityFeedShow
            {...props}
            resource={Constants.ACTIVITY_FEED_RESOURCE}
            basePath={Constants.ACTIVITY_FEED_PATH}
          />
        )}
      />
    </Switch>
  );
};
