import { IconName } from "@blueprintjs/core";
import { Schema } from "yup";

export enum TSFModel {
  TenantCompany = "tenant",
  NA = "not applicable",
}

export interface ITenantSpecificFieldsConfig {
  [tenantName: string]: ITenantSpecificFieldConfig[];
}

export interface ITenantSpecificFieldOptions {
  // When set to true, will attach a "onConfirm" handler to the editor
  // Otherwise, just handles "onChange"
  // This is important for editors like the EditableText component, which don't handle
  // updates atomically
  submitOnConfirm?: boolean;
  // When set to true, will attach a "onBlur" handler to the editor
  // Otherwise, just handles "onChange"
  // This is important for InputGroup components when there is no explicit submit button
  submitOnBlur?: boolean;
}

export interface ITenantSpecificFieldConfig {
  name: string;
  field: string;
  belongsTo?: string;
  // Optional. Validations in Yup schema format.
  validations?: Schema<any>;
  // Optional. Blueprint icon name to display in task detail.
  icon?: IconName;
  CreateComponent: React.ComponentClass;
  // Optional. If provided, will be passed directly to the CreateComponent.
  createComponentProps?: any;
  createComponentOptions?: ITenantSpecificFieldOptions;
  EditComponent?: React.ComponentClass;
  // Optional. If provided, will be passed directly to the EditComponent.
  editComponentProps?: any;
  editComponentOptions?: ITenantSpecificFieldOptions;
  // Must be a EditableCell component
  TableEditComponent?: React.ComponentClass;
  width?: number;
}
