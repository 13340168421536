import { red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/styles";
import { SaveButton, Toolbar } from "ra-ui-materialui";
import React from "react";
import { useCreateInvoice } from "./hooks";

const InvoiceSaveButton: React.FC<any> = ({
  handleSubmitWithRedirect,
  redirect,
  ...props
}) => {
  const handleClick = useCreateInvoice(props);

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  wrapper: {
    margin: 16,
    position: "relative",
  },
  buttonSuccess: {
    "backgroundColor": red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
  buttonProgress: {
    color: red[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const InvoiceCreateToolbar = (props) => {
  const canSave =
    !props.record.approvalStatus || props.record.approvalStatus === "draft";
  return (
    <Toolbar {...props} classes={useStyles(props)}>
      {canSave && <InvoiceSaveButton label="Save" />}
    </Toolbar>
  );
};
