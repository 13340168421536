import { Button, Checkbox, Icon, Tag } from "@blueprintjs/core";
import { TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { ITimeEntry, ITimesheet } from "../../../model";
import { DurationInput } from "../../inputs";
import { TimesheetDurationBox } from "./TimesheetDurationBox";

interface ITimesheetTimeEntryRow {
  timeEntry: ITimeEntry;
  timesheet?: ITimesheet;
  onUpdateEntry?: (newEntry: ITimeEntry, previousEntry: ITimeEntry) => void;
}

const columns = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const TimesheetTimeEntryRow: React.FC<ITimesheetTimeEntryRow> = ({
  timeEntry,
  timesheet,
  onUpdateEntry,
}) => {
  const dayOfWeek = moment(timeEntry.startDatetime).format("ddd");
  const [editDuration, setEditDuration] = useState(timeEntry.duration);
  const [editDescription, setEditDescription] = useState(timeEntry.description);
  const [editChargeable, setEditChargeable] = useState(timeEntry.isChargeable);
  const [isEdit, setIsEdit] = React.useState(false);
  const handleEdit = () => setIsEdit(!isEdit);

  const renderDuration = () => {
    if (isEdit) {
      return (
        <TableCell
          className="timesheet-duration-box"
          key={`${timeEntry.id} edit`}
        >
          <DurationInput
            className="bp3-intent-danger"
            duration={editDuration}
            onChange={setEditDuration}
          />
        </TableCell>
      );
    }
    return (
      <TimesheetDurationBox duration={timeEntry.duration} key={timeEntry.id} />
    );
  };

  const handleOnchangeDescription = (event) =>
    setEditDescription(event.currentTarget.value);

  const renderDescription = () => {
    if (isEdit) {
      return (
        <input
          className={"bp3-input"}
          value={editDescription}
          onChange={handleOnchangeDescription}
        />
      );
    }
    return (
      <span className="timesheet-create-time-entry-head-description">
        {timeEntry.description}
      </span>
    );
  };

  const handleUpdate = useCallback(() => {
    const description = editDescription;
    const isChargeable = editChargeable;
    const endDatetime = moment(timeEntry.startDatetime)
      .add(moment.duration(editDuration).asMilliseconds())
      .toISOString();
    setIsEdit(!isEdit);
    onUpdateEntry(
      {
        ...timeEntry,
        endDatetime,
        description,
        isChargeable,
      },
      timeEntry
    );
  }, [editDuration, timesheet, editDescription, editChargeable, isEdit]);

  const renderButton = () => {
    if (isEdit) {
      return (
        <Button onClick={handleUpdate}>
          <Icon icon={"floppy-disk"} />
        </Button>
      );
    } else {
      return (
        <Button onClick={handleEdit}>
          <Icon icon={"edit"} />
        </Button>
      );
    }
  };
  const renderChargeable = () => {
    return (
      <Checkbox
        className={"timesheet-create-time-entry-chargeable"}
        checked={editChargeable}
        label="Chargeable?"
        onClick={handleOnClickChargeable}
        inline={true}
        alignIndicator="right"
        disabled={!(isEdit && !timeEntry.subtaskId)}
      />
    );
  };

  const handleOnClickChargeable = () => setEditChargeable(!editChargeable);

  return (
    <>
      <TableRow
        className="timesheet-create-time-entry-head"
        key={`${timeEntry.id} head`}
      >
        <TableCell
          colSpan={8}
          className="timesheet-create-time-entry-description"
        >
          {timeEntry.subtaskDescription && (
            <Tag
              round={true}
              className="timesheet-create-time-entry-head-subtask"
              style={{ width: "fit-content", marginBottom: 3 }}
            >
              {timeEntry.subtaskDescription}
            </Tag>
          )}
          {renderDescription()}
        </TableCell>
        <TableCell colSpan={1}>{renderChargeable()}</TableCell>
      </TableRow>
      <TableRow
        className="timesheet-create-table-time-entry-row"
        key={`${timeEntry.id} duration`}
      >
        <TableCell />
        {columns.map((day) =>
          day === dayOfWeek ? (
            renderDuration()
          ) : (
            <TableCell key={`${timeEntry.id} ${day}`} />
          )
        )}
        <TableCell className="timesheet-duration-box">
          {timesheet && timesheet.approvalStatus !== "draft"
            ? ""
            : renderButton()}
        </TableCell>
      </TableRow>
    </>
  );
};
