import { Api } from "../../actions";
import { CompanyForm } from "../forms";
import { buildModelDialog } from "./ModelDialog";

export const CompanyDialog = buildModelDialog({
  defaultDialogProps: {
    className: "company-dialog",
    icon: "office",
    title: "Create a company",
  },
  Form: CompanyForm,
  modelApi: Api.ClientCompany,
  dataApi: Api.CompanyAdditionalDataRecord,
  additionalDataApi: Api.TenantAdditionalDataRecord,
  foreignKeyProperty: "clientCompanyId",
});
