import { Button, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { push } from "connected-react-router";
import React, { useCallback } from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  ReferenceField,
  TextField,
} from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import { enabled } from "../../config/features";
import { Constants } from "../../constants";
import { IEtsState, ITaskType } from "../../model";
import { EmptyPage } from "../EmptyPage/EmptyPage";
import { PriceField, UserField } from "../fields";
import { Pagination } from "../Pagination";
import { ApprovalStatusList } from "../WriteOff/CustomField/ApprovalStatusList";
import { BalanceField } from "./BalanceField";
import { FeeNoteNumberField } from "./FeeNoteNumberField";
import { InvoiceCreateMenu } from "./InvoiceCreateMenu";
import { InvoiceFilter } from "./InvoiceFilter";
import { ProForma } from "./ProForma";

const CreditNoteButton: React.FC<any> = ({ source, record, ...props }) => {
  const dispatch = useDispatch();
  const query = encodeURIComponent(JSON.stringify({ invoiceId: record.id }));
  const handleClick = useCallback(() => {
    dispatch(push(`${Constants.CREDIT_NOTE_PATH}?filter=${query}`));
  }, []);

  return <Button onClick={handleClick}>Credit Note</Button>;
};

const renderTaskTypeNameField = (record: ITaskType) => (
  <Chip label={record.displayName} />
);

const PaymentButton: React.FC<any> = ({ source, record, ...props }) => {
  const dispatch = useDispatch();
  const query = encodeURIComponent(JSON.stringify({ invoiceId: record.id }));
  const handleClick = useCallback(() => {
    dispatch(push(`${Constants.PAYMENT_PATH}?filter=${query}`));
  }, []);

  return <Button onClick={handleClick}>Payment</Button>;
};

const TaskTypeField: React.FC<any> = ({ record }) => {
  return (
    <ReferenceField
      resource={Constants.TASK_RESOURCE}
      reference={Constants.TASK_TYPE_RESOURCE}
      source="taskTypeId"
      basePath="/tasks"
      record={record}
      link={false}
    >
      <FunctionField source="group" render={renderTaskTypeNameField} />
    </ReferenceField>
  );
};

const AssigneeField: React.FC<any> = (props) => (
  <ReferenceField
    {...props}
    resource={Constants.TASK_RESOURCE}
    reference={Constants.USER_RESOURCE}
    source="assigneeId"
    basePath="/tasks"
    link={false}
  >
    <UserField userId={props.record.assigneeId} />
  </ReferenceField>
);

const useStyles = makeStyles({
  container: {
    margin: "50px 10px",
  },
});

export const InvoiceList: React.FC<any> = (props) => {
  const classes = useStyles(props);

  const roles = useSelector((state: IEtsState) => state.auth.roles);
  const currentUserId = useSelector((state: IEtsState) => state.auth.user.id);

  const toAccountBalance = (record) => {
    const balanceEnabled =
      enabled("timebill.reports", roles) ||
      currentUserId === record.picId ||
      currentUserId === record.reviewerId;
    return (
      balanceEnabled && `${Constants.ACCOUNT_BALANCE_PATH}/${record.taskId}`
    );
  };

  return (
    <List
      {...props}
      bulkActionButtons={false}
      sort={{ field: "date", order: "DESC" }}
      pagination={<Pagination />}
      empty={<EmptyPage {...props} />}
      filters={
        <InvoiceFilter includeDefaultFilter="taskId" feeNoteSearch={true} />
      }
      hasCreate={false}
      exporter={false}
      className={classes.container}
    >
      <Datagrid optimized={true}>
        <EditButton />
        <ApprovalStatusList source="approvalStatus" label="Approval Status" />
        <DateField source="date" />
        <ReferenceField
          label="Company"
          source="clientCompanyId"
          reference={Constants.CLIENT_COMPANY_RESOURCE}
          link={false}
          sortBy="clientCompanyName"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Job Type"
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          link={false}
          sortBy="taskType"
        >
          <TaskTypeField />
        </ReferenceField>
        <ReferenceField
          label="Job"
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          link={toAccountBalance}
          sortBy="taskDescription"
        >
          <TextField source="description" />
        </ReferenceField>
        <ProForma label="Pro Forma" />
        <FeeNoteNumberField
          source="feeNoteNumber"
          label="Fee Note #"
          sortable={false}
        />
        <UserField label="Created By" source="createdById" />
        <ReferenceField
          label="Assignee"
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          link={false}
          sortBy="assigneeId"
        >
          <AssigneeField />
        </ReferenceField>
        <PriceField label="WIP" source="amtToBill" />
        <PriceField label="Expense" source="expenseToBill" />
        <BalanceField label="WIP Balance" source="wipBalance" />
        <BalanceField label="Expense Balance" source="expenseBalance" />
        <DateField label="Created On" source="createdAt" />
        {enabled("timebill.creditNote", roles) && <CreditNoteButton />}
        {enabled("timebill.payment", roles) && <PaymentButton />}
        {(enabled("tiembill.creditNote", roles) ||
          enabled("timebill.payment", roles)) && <InvoiceCreateMenu />}
      </Datagrid>
    </List>
  );
};
