import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { required, useReference } from "ra-core";
import { BooleanInput, DateInput, TextInput } from "ra-ui-materialui";
import React, { useEffect } from "react";
import { Constants } from "../../constants";
import { IInvoice } from "../../model";
import { WipAndExpenseField } from "./CustomField/WipAndExpenseField";

const useStyles = makeStyles({
  description: { width: 350 },
});

export const CreditNoteEditInput = (props) => {
  const { record } = props;
  const { referenceRecord } = useReference({
    reference: Constants.INVOICE_RESOURCE,
    id: record.invoiceId,
  });
  const classes = useStyles({});
  const [disableEdit, setDisableEdit] = React.useState(false);
  useEffect(() => {
    if (record.approvalStatus && record.approvalStatus !== "draft") {
      setDisableEdit(true);
    }
  }, [props, record]);
  const invoice = referenceRecord as IInvoice;

  if (!invoice) {
    return null;
  }

  return (
    <>
      <Grid container={true} direction="column">
        <Grid item={true}>
          <DateInput
            resource={Constants.CREDIT_NOTE_RESOURCE}
            source="date"
            validate={required()}
            disabled={disableEdit}
          />
        </Grid>
        <Grid item={true}>
          <TextInput
            resource={Constants.CREDIT_NOTE_RESOURCE}
            source="description"
            multiline={true}
            rows="4"
            className={classes.description}
            disabled={disableEdit}
          />
        </Grid>
      </Grid>
      <WipAndExpenseField invoice={invoice} disableEdit={disableEdit} />
      <TextInput
        label="Internal Remarks"
        source="internalRemarks"
        multiline={true}
        rows="4"
        className={classes.description}
      />
      <BooleanInput label="Write-Off?" source="isWriteOff" />
    </>
  );
};
