import { Edit, ReferenceField, SimpleForm } from "ra-ui-materialui";
import React from "react";
import { Constants } from "../../constants";
import { ClientCompanyField, FeeNoteField, TaskField } from "../fields";
import { ApprovalStatusEdit } from "./CustomField/ApprovalStatusEdit";
import { WriteOffEditToolBar } from "./CustomField/WriteOffEditToolBar";
import { WriteOffSelectInput } from "./CustomField/WriteOffSelectInput";

export const WriteOffEdit: React.FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<WriteOffEditToolBar />}>
        <ReferenceField
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          addLabel={false}
          link={false}
        >
          <TaskField />
        </ReferenceField>
        <ReferenceField
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          addLabel={false}
          link={false}
        >
          <ClientCompanyField />
        </ReferenceField>
        <ReferenceField
          source="invoiceId"
          reference={Constants.INVOICE_RESOURCE}
          addLabel={false}
          link={false}
        >
          <FeeNoteField />
        </ReferenceField>
        <ApprovalStatusEdit />
        <WriteOffSelectInput />
      </SimpleForm>
    </Edit>
  );
};
