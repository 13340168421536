import { Checkbox, Icon, InputGroup } from "@blueprintjs/core";
import { useRefresh } from "ra-core";
import React, { useState } from "react";
import { ReferenceField } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../../actions";
import { Constants } from "../../constants";
import {
  IAttachmentEntry,
  IChatThread,
  ICommentEntry,
  IEmail,
  IEtsState,
  ISortable,
  IVersionEntry,
} from "../../model";
import * as SortUtils from "../../utils/sort";
import { AttachmentShareDialog } from "../dialog/AttachmentShareDialog";
import { TimelineForm } from "../FileUpload/TimelineForm";
import { CreatedAtEntry } from "./CreatedAtEntry";
import { HistoryEntry } from "./HistoryEntry";

interface IHistoryTimelineProps {
  attachments?: object[];
  comments?: object[];
  departmentSpecificFieldsVersions?: object[];
  versions?: object[];
  emails?: IEmail[];
  chatThreads?: IChatThread[];
  clientCompanyId?: string;

  entryType: "Task" | "ClientGroup" | "ClientCompany";
  entryId: string;
  createdAt?: string;
  createdById?: string;
  basePath: string;
  resource: string;
}

const addAttachmentTag = (entry: IAttachmentEntry): ISortable => {
  const key = "entry";
  const searchContent = "searchContent";
  entry[key] = "attachment";
  entry[searchContent] = `${entry.fileName}`.toLowerCase();
  return entry as ISortable;
};

const addVersionTag = (entry: IVersionEntry): ISortable => {
  const key = "entry";
  const searchContent = "searchContent";
  entry[key] = "version";
  entry[searchContent] = `${entry.fieldName}`.toLowerCase();
  return entry as ISortable;
};

const addCommentTag = (entry: ICommentEntry): ISortable => {
  const key = "entry";
  const searchContent = "searchContent";
  entry[key] = "comment";
  entry[searchContent] = `${entry.content}`.toLowerCase();
  return entry as ISortable;
};

const addDepartmentSpecificFieldsVersionsTag = (entry: object): ISortable => {
  const key = "entry";
  const searchContent = "searchContent";
  entry[key] = "departmentSpecificFieldsVersion";
  entry[searchContent] = ``.toLowerCase();
  return entry as ISortable;
};

const addEmailTag = (entry: IEmail): ISortable => {
  const key = "entry";
  const searchContent = "searchContent";
  entry[searchContent] = `${entry.emailBody} ${entry.ccIds} ${
    entry.emailSubject
  } ${entry.recipientId}
  ${entry.attachments.map((e) => e.fileName)}`.toLowerCase();
  entry[key] = "email";
  return entry as ISortable;
};

const addChatTag = (entry: IChatThread): ISortable => {
  const key = "entry";
  const searchContent = "searchContent";
  entry[searchContent] = `${entry.messages.map(
    (message) => `${message.userName} ${message.content}`
  )}`.toLowerCase();
  entry[key] = "chat";
  return entry as ISortable;
};

const getAuditTrailEntries = (
  historyType,
  query,
  attachments,
  chatThreads,
  comments,
  emails,
  departmentSpecificFieldsVersions,
  versions
) => {
  const filterByQuery = (entry: ISortable): boolean => {
    if (
      query === "" ||
      entry.searchContent.match(query.toLowerCase()) !== null
    ) {
      return true;
    }
  };

  const filterByType = (entry: ISortable): boolean => {
    const type = entry.entry;
    switch (type) {
      case "attachment":
        return historyType.attachment;
      case "comment":
        return historyType.comment;
      case "email":
        return historyType.email;
      case "chat":
        return historyType.chat;
      case "version":
        return historyType.version;
      case "departmentSpecificFieldsVersion":
        return historyType.departmentSpecificFieldsVersion;
    }
  };

  let versionsCpy = [];
  let commentsCpy = [];
  let attachmentsCpy = [];
  let departmentSpecificFieldsVersionsCpy = [];
  let emailCpy = [];
  let chatThreadsCpy = [];
  if (versions) {
    versionsCpy = versions.map(addVersionTag);
  }
  if (comments) {
    commentsCpy = comments.map(addCommentTag);
  }
  if (attachments) {
    attachmentsCpy = attachments.map(addAttachmentTag);
  }
  if (departmentSpecificFieldsVersions) {
    departmentSpecificFieldsVersionsCpy = departmentSpecificFieldsVersions.map(
      addDepartmentSpecificFieldsVersionsTag
    );
  }
  if (emails) {
    emailCpy = emails.map(addEmailTag);
  }
  if (chatThreads) {
    chatThreadsCpy = chatThreads.map(addChatTag);
  }
  return [
    ...versionsCpy,
    ...commentsCpy,
    ...attachmentsCpy,
    ...departmentSpecificFieldsVersionsCpy,
    ...emailCpy,
    ...chatThreadsCpy,
  ]
    .filter(filterByQuery)
    .filter(filterByType)
    .sort(SortUtils.sortByCreatedAtAscGeneric);
};

export const HistoryTimeline: React.FC<IHistoryTimelineProps> = ({
  attachments,
  comments,
  departmentSpecificFieldsVersions,
  versions,
  emails,
  chatThreads,
  createdAt,
  createdById,
  entryType,
  entryId,
  clientCompanyId,
  basePath,
  resource,
}) => {
  const [
    {
      historyType,
      query,
      isDisplayComment,
      sharingAttachment,
      currentAttachment,
      attachmentType,
    },
    setState,
  ] = useState({
    historyType: {
      chat: true,
      email: true,
      comment: true,
      attachment: true,
      version: true,
      departmentSpecificFieldsVersion: true,
    },
    query: "",
    isDisplayComment: true,
    sharingAttachment: false,
    currentAttachment: null,
    attachmentType: null,
  });

  const dispatch = useDispatch();
  const refresh = useRefresh();

  // const usersById = useSelector((state: IEtsState) => state.usersById);
  const currentUser = useSelector(
    (state: IEtsState) => state.usersById[state.auth.user.id]
  );

  const onGetTask = (id: string) => {
    return dispatch(Api.Task.get(id));
  };

  const onGetChatThreads = (id: string, type: string) => {
    return dispatch(
      Api.ChatThread.getAllCustomPath(`/api/${type}/${id}/chat_threads`)
    );
  };

  const onGetEmails = (id: string) => {
    return dispatch(Api.Email.getAllCustomSuffix(id));
  };

  const onGetClientGroupComments = (id: string) => {
    return dispatch(
      Api.Comment.getAllCustomPath(`/api/client_groups/${id}/comments`)
    );
  };

  const onGetClientGroupAttachments = (id: string) => {
    return dispatch(
      Api.Attachment.getAllCustomPath(`/api/client_groups/${id}/attachments`)
    );
  };

  const onGetClientGroupEmails = (id: string) => {
    return dispatch(
      Api.Email.getAllCustomPath(`/api/client_groups/${id}/emails`)
    );
  };

  const onGetCompanyComments = (id: string) => {
    return dispatch(
      Api.Comment.getAllCustomPath(`/api/client_companies/${id}/comments`)
    );
  };

  const onGetCompanyAttachments = (id: string) => {
    return dispatch(
      Api.Attachment.getAllCustomPath(`/api/client_companies/${id}/attachments`)
    );
  };

  const onGetCompanyEmails = (id: string) => {
    return dispatch(
      Api.Email.getAllCustomPath(`/api/client_companies/${id}/emails`)
    );
  };

  const afterSubmit = () => {
    refresh();
    if (entryType === "Task") {
      onGetTask(entryId);
    } else if (entryType === "ClientCompany") {
      onGetCompanyAttachments(entryId);
    } else if (entryType === "ClientGroup") {
      onGetClientGroupAttachments(entryId);
    }
  };

  const onChangeSearch = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.currentTarget;
    setState((prevState) => ({
      ...prevState,
      query: target.value,
    }));
  };

  const onClickRefresh = () => {
    refresh();
    switch (entryType) {
      case "Task":
        onGetChatThreads(entryId, "tasks");
        onGetEmails(entryId);
        return onGetTask(entryId);
      case "ClientGroup":
        onGetChatThreads(entryId, "client_groups");
        onGetClientGroupAttachments(entryId);
        onGetClientGroupEmails(entryId);
        return onGetClientGroupComments(entryId);
      case "ClientCompany":
        onGetCompanyComments(entryId);
        onGetCompanyEmails(entryId);
        return onGetCompanyAttachments(entryId);
    }
  };

  const onChangeType = (event: React.FormEvent<HTMLInputElement>) => {
    const { name, checked } = event.currentTarget;
    const selectType = historyType;
    selectType[name] = checked;
    setState((prevState) => ({
      ...prevState,
      historyType: selectType,
    }));
  };

  const startSharingAttachment = (
    selectedAttachment: IAttachmentEntry,
    type: string
  ) => {
    setState((prevState) => ({
      ...prevState,
      sharingAttachment: true,
      currentAttachment: selectedAttachment,
      attachmentType: type,
    }));
  };

  const finishSharingAttachment = () => {
    setState((prevState) => ({
      ...prevState,
      sharingAttachment: false,
      currentAttachment: null,
      attachmentType: null,
    }));
    afterSubmit();
  };

  const { attachment, chat, comment, email } = historyType;
  const auditTrailEntries = getAuditTrailEntries(
    historyType,
    query,
    attachments,
    chatThreads,
    comments,
    emails,
    departmentSpecificFieldsVersions,
    versions
  );
  const currentUserInitials = currentUser.abbreviation;

  return (
    <div className="history-wrapper">
      <div className="timeline-header">
        <div className="timeline-search-refresh">
          <div className="timeline-refresh-title">
            <div>
              <h6>History</h6>
            </div>
            <div className="timeline-refresh">
              <button onClick={onClickRefresh}>
                <Icon iconSize={15} icon="refresh" color="#5c7080" />
              </button>
            </div>
          </div>
          <div className="timeline-search-content">
            <InputGroup
              className="timeline-search-input"
              leftIcon="search"
              placeholder="Search"
              onChange={onChangeSearch}
              value={query}
            />
          </div>
        </div>
        <div className="timeline-filter">
          {chatThreads && (
            <Checkbox
              name="chat"
              checked={chat}
              onChange={onChangeType}
              label={"Chat Threads"}
            />
          )}
          {comments && (
            <Checkbox
              name="comment"
              checked={comment}
              onChange={onChangeType}
              label={"Comments"}
            />
          )}
          {emails && (
            <Checkbox
              name="email"
              checked={email}
              onChange={onChangeType}
              label={"Emails"}
            />
          )}
          {attachments && (
            <Checkbox
              name="attachment"
              checked={attachment}
              onChange={onChangeType}
              label={"Attachments"}
            />
          )}
        </div>
      </div>
      <div className="timeline-wrapper">
        {createdAt && (
          <ReferenceField
            source="createdById"
            record={{ createdById }}
            reference={Constants.USER_RESOURCE}
            basePath={basePath}
            resource={resource}
          >
            <CreatedAtEntry createdAt={createdAt} type={entryType} />
          </ReferenceField>
        )}
        {auditTrailEntries.map((entry, index) => {
          const isSharable =
            entry.entry === "attachment" || entry.entry === "email";
          return (
            <HistoryEntry
              key={index}
              entry={entry}
              parentEntryType={entryType}
              afterUpdate={
                entry.entry === "email" && onGetEmails.bind(this, entryId)
              }
              startSharingAttachment={isSharable && startSharingAttachment}
            />
          );
        })}
      </div>
      {currentAttachment && (
        <AttachmentShareDialog
          isOpen={sharingAttachment}
          canOutsideClickClose={true}
          onClose={finishSharingAttachment}
          attachment={currentAttachment}
          attachmentType={attachmentType}
          afterSharing={finishSharingAttachment}
          clientCompanyId={
            (entryType === "Task" || entryType === "ClientCompany") &&
            clientCompanyId
          }
        />
      )}
      <div
        className="timeline-form-wrapper"
        style={isDisplayComment ? {} : { display: "none" }}
      >
        <div className="timeline-form-avatar">{currentUserInitials}</div>
        <TimelineForm
          entryId={entryId}
          entryType={entryType}
          afterSubmit={afterSubmit}
        />
      </div>
    </div>
  );
};
