import { Chip } from "@material-ui/core";
import React from "react";
import { entryTypeString } from "../../../utils/entryType";

interface IEntryTypeFieldProps {
  source: string;
  label?: string;
  record?: { entryType: string };
}

export const EntryTypeField: React.FC<IEntryTypeFieldProps> = ({
  source,
  record,
  label = "Type",
}) => {
  return <Chip label={entryTypeString(record.entryType)} />;
};
