import { FormLabel, Grid, makeStyles, TextField } from "@material-ui/core";
import { required, useGetManyReference } from "ra-core";
import React, { useEffect } from "react";
import {
  DateInput,
  FormDataConsumer,
  NumberInput,
  SelectInput,
  TextInput,
} from "react-admin";
import { Constants } from "../../../constants";
import { IAccountBalance } from "../../../model";
import { entryTypeString } from "../../../utils/entryType";
import { AmountInput } from "./AmountInput";

const useStyle = makeStyles({
  grid: { marginTop: 16 },
});

export const WriteOffSelectInputImpl: React.FC<any> = (props) => {
  const { record, formData } = props;
  const classes = useStyle({});
  const [disableEdit, setDisableEdit] = React.useState(false);
  useEffect(() => {
    if (
      (record.approvalStatus && record.approvalStatus !== "draft") ||
      record.invoiceId
    ) {
      setDisableEdit(true);
    }
  }, [record]);

  const { data: accountBalances } = useGetManyReference(
    Constants.ACCOUNT_BALANCE_RESOURCE,
    Constants.TASK_RESOURCE,
    record.taskId,
    { page: 1, perPage: 1 },
    { field: "", order: "" },
    {},
    Constants.TASK_RESOURCE
  );

  if (!accountBalances) {
    return null;
  }

  const accountBalance = accountBalances[record.taskId] as IAccountBalance;

  const choices = [{ type: "wip" }, { type: "expense" }];
  const selectType = formData.entryType;
  const renderAccountBalance = () => {
    if (selectType === "wip") {
      return (
        <>
          <FormLabel component="legend" className={classes.grid}>
            Current WIP balance
          </FormLabel>
          <TextField value={accountBalance.accountBalance} />
        </>
      );
    }
    if (selectType === "expense") {
      return (
        <>
          <FormLabel component="legend" className={classes.grid}>
            Expense Balance
          </FormLabel>
          <TextField value={accountBalance.expenseBalance} />
        </>
      );
    }
  };
  const renderRemainingBalance = () => {
    if (record.approvalStatus !== "approved") {
      return (
        <AmountInput
          amount={formData.amount}
          type={selectType}
          accountBalance={accountBalance}
        />
      );
    }
  };

  return (
    <>
      <Grid container={true} direction="column">
        <Grid item={true} className={classes.grid}>
          <DateInput
            resource={Constants.WRITE_OFF_RESOURCE}
            source="date"
            validate={required()}
            disabled={disableEdit}
          />
        </Grid>
        <Grid item={true} className={classes.grid}>
          <TextInput
            resource={Constants.WRITE_OFF_RESOURCE}
            source="description"
            validate={required()}
            disabled={disableEdit}
            multiline={true}
            rows="4"
          />
        </Grid>
        <Grid item={true} className={classes.grid}>
          <SelectInput
            resource={Constants.WRITE_OFF_RESOURCE}
            source="entryType"
            choices={choices}
            optionText={(choice) => entryTypeString(choice.type)}
            optionValue="type"
            validate={required()}
            disabled={disableEdit}
          />
        </Grid>
        <Grid item={true} className={classes.grid}>
          {renderAccountBalance()}
        </Grid>
        <Grid item={true} className={classes.grid}>
          <NumberInput
            resource={Constants.WRITE_OFF_RESOURCE}
            source="amount"
            validate={required()}
            disabled={disableEdit}
          />
        </Grid>
        <Grid item={true} className={classes.grid}>
          {renderRemainingBalance()}
        </Grid>
      </Grid>
    </>
  );
};

export const WriteOffSelectInput = (props) => (
  <FormDataConsumer>
    {({ formData, ...rest }) => (
      <WriteOffSelectInputImpl formData={formData} {...props} />
    )}
  </FormDataConsumer>
);
