import { makeStyles } from "@material-ui/styles";
import { ReactAdminComponentProps } from "ra-core";
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  ReferenceField,
  TextField,
} from "ra-ui-materialui";
import React from "react";
import { Constants } from "../../constants";
import { PriceField, UserField } from "../fields";
import { InvoiceFilter } from "../Invoice/InvoiceFilter";

const useStyle = makeStyles({
  container: { paddingTop: 10 },
});

export const ExpenseList: React.FC<ReactAdminComponentProps> = (props) => {
  const classes = useStyle(props);

  return (
    <List
      {...props}
      filters={<InvoiceFilter includeDefaultFilter="taskId" />}
      exporter={false}
      sort={{ field: "date", order: "DESC" }}
      className={classes.container}
    >
      <Datagrid optimized={true}>
        <DateField label="Date" source="date" />
        <ReferenceField
          label="Company"
          source="clientCompanyId"
          reference={Constants.CLIENT_COMPANY_RESOURCE}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Job"
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          link={false}
        >
          <TextField source="description" />
        </ReferenceField>
        <TextField source="description" />
        <UserField label="Created By" source="createdById" />
        <PriceField source="amount" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
