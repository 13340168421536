import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { push } from "connected-react-router";
import _ from "lodash";
import moment from "moment";
import { useCreate, useReference } from "ra-core";
import { SaveButton } from "ra-ui-materialui";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  countClientCompanyDuration,
  TimesheetDateFilter,
  TimesheetTableHeaderRow,
  TimesheetTasksRow,
} from ".";
import { Constants } from "../../../constants";
import { IClientCompany, ITimeEntry, ITimesheet } from "../../../model";
import * as Utils from "../../../utils/date";
import { TimesheetTimeEntryCreate } from "./TimesheetTimeEntryCreate/TimesheetTimeEntryCreate";
import { TimesheetTotalRow } from "./TimesheetTotalRow";

interface IClientCompanyRowProps {
  clientCompanyId: string;
  timesheet?: ITimesheet;
  entries?: ITimeEntry[];
  onUpdateEntry?: (newEntry: ITimeEntry, previousEntry: ITimeEntry) => void;
}

const ClientCompanyRow: React.FC<IClientCompanyRowProps> = ({
  clientCompanyId,
  timesheet,
  entries,
  onUpdateEntry,
}) => {
  const { referenceRecord } = useReference({
    reference: Constants.CLIENT_COMPANY_RESOURCE,
    id: clientCompanyId,
  });
  if (!referenceRecord) {
    return null;
  }

  const clientCompany = referenceRecord as IClientCompany;

  return (
    <>
      <TableRow>
        <TableCell colSpan={9}>{clientCompany.name}</TableCell>
      </TableRow>
      <TimesheetTasksRow
        timesheet={timesheet}
        entries={entries}
        onUpdateEntry={onUpdateEntry}
      />
    </>
  );
};

interface ITaskGridProps {
  startDatetime: string;
  onChangeDate: (date: Date) => void;
  ids?: string[];
  timesheet?: ITimesheet;
  entries?: ITimeEntry[];
  onUpdateEntry?: (newEntry: ITimeEntry, previousEntry: ITimeEntry) => void;
  timeEntriesCreate?: boolean;
}

export const TaskGrid: React.FC<ITaskGridProps> = ({
  startDatetime,
  onChangeDate,
  timesheet,
  ids,
  entries,
  onUpdateEntry,
  timeEntriesCreate = false,
}) => {
  const startDayOfWeek = moment(startDatetime)
    .startOf("week")
    .add(1, "d")
    .toDate();
  const endDayOfWeek = moment(startDatetime).endOf("week").add(1, "d").toDate();
  const timeEntries = timesheet?.timeEntries || entries || [];
  const startDate = Utils.datTimeWithZone(startDayOfWeek);
  const endDate = Utils.datTimeWithZone(endDayOfWeek);

  const clientCompaniesDuration = countClientCompanyDuration(timeEntries);
  const clientCompaniesKeys = Object.keys(clientCompaniesDuration);
  const [create] = useCreate(Constants.TIMESHEET_RESOURCE);

  const handleClickLeft = () =>
    onChangeDate(moment(startDatetime).add(-1, "w").toDate());
  const handleClickRight = () =>
    onChangeDate(moment(startDatetime).add(1, "w").toDate());
  const dispatch = useDispatch();

  const handleCreateTimesheet = useCallback(() => {
    create(
      {
        payload: {
          data: {
            startDate,
            endDate,
            timeEntryIds: _.map(timeEntries, "id"),
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          dispatch(push(`${Constants.TIMESHEET_PATH}/${data.id}`));
        },
      }
    );
  }, [create, timeEntries]);

  const renderTopButton = () => {
    if (timesheet) {
      return "";
    }
    return <SaveButton handleSubmitWithRedirect={handleCreateTimesheet} />;
  };
  return (
    <>
      <Grid className="timesheet-create-table">
        <Grid container={true} className="time-sheet-create-top">
          <Grid item={true} className="time-sheet-create-date-filter">
            <TimesheetDateFilter
              startDayOfWeek={startDayOfWeek}
              endDayOfWeek={endDayOfWeek}
              onClickLeft={handleClickLeft}
              onClickRight={handleClickRight}
              timesheet={timesheet}
            />
          </Grid>
          <Grid item={true}>{renderTopButton()}</Grid>
        </Grid>
        <div>
          <Table stickyHeader={true}>
            <TableHead className="timesheet-create-table-head">
              <TimesheetTableHeaderRow
                date={startDatetime}
                key={`${startDayOfWeek} - ${endDayOfWeek}`}
              />
            </TableHead>
            <TableBody className="timesheet-create-table-body">
              {clientCompaniesKeys.map((id) => (
                <ClientCompanyRow
                  key={id}
                  clientCompanyId={id}
                  timesheet={timesheet}
                  entries={timeEntries.filter(
                    (entry) => entry.clientCompanyId === id
                  )}
                  onUpdateEntry={onUpdateEntry}
                />
              ))}
              <TimesheetTotalRow timeEntries={timeEntries} />
            </TableBody>
          </Table>
        </div>
      </Grid>
      {timeEntriesCreate && <TimesheetTimeEntryCreate date={startDatetime} />}
    </>
  );
};
