import { pickBy } from "lodash";
import { IEtsState, ITaskType } from "../../model";
import { selectForModelById } from "./ModelSelect/ModelSelect";
import { alphabeticalSort } from "./ModelSelect/Sort";

export const TaskTypeSelect = selectForModelById(
  "task-type",
  (taskType: ITaskType) => {
    return {};
  },
  (taskType: ITaskType) => taskType.displayName,
  (taskType: ITaskType) => null,
  (state: IEtsState) =>
    pickBy(state.taskTypesById, (type) => {
      const user = state.usersById[state.auth.user.id];
      return type.departmentId === user.department.id;
    }),
  undefined,
  undefined,
  alphabeticalSort
);
