import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { TextInput } from "react-admin";

const useStyles = makeStyles(() => ({
  description: {
    width: 350,
  },
}));

export const TextAreaInput: React.FC<any> = (props) => {
  const classes = useStyles(props);
  return (
    <TextInput
      {...props}
      className={classes.description}
      multiline={true}
      rows="4"
    />
  );
};
