import { FormLabel, makeStyles, Typography } from "@material-ui/core";
import { get } from "lodash";
import React from "react";
import { IWriteOff } from "../../../model";
import { statusString } from "../../../utils/approvalStatus";

const useStyles = makeStyles({
  approval: {
    marginTop: 16,
  },
});

const TextField: React.FC<any> = ({
  className,
  source,
  record = {},
  ...rest
}) => {
  const value = get(record, source);
  const status = statusString(value);
  return (
    <Typography component="span" variant="body2" className={className}>
      {status}
    </Typography>
  );
};

interface IApprovalStatusEditProps {
  record?: IWriteOff;
}

export const ApprovalStatusEdit: React.FC<IApprovalStatusEditProps> = (
  props
) => {
  const { record } = props;
  const classes = useStyles({});
  return (
    <>
      <FormLabel component="legend" className={classes.approval}>
        Approval Status
      </FormLabel>
      <TextField record={record} source="approvalStatus" />
    </>
  );
};
