import moment, { Moment } from "moment";
import "moment-duration-format";
import { Sort } from "ra-core";
import { isNullOrUndefined } from "util";
import { Constants } from "../constants";
import { IEtsUser, ITimeEntry } from "../model";
import { getFullName } from "./user";

export type Language = "en" | "zh-HK" | "zh-CN";

export function formatDate(date: Date) {
  return moment(date).format(Constants.DATE_FORMAT);
}

export function formatDateMinusOneYear(date: Date) {
  return moment(date).subtract(1, "year").format(Constants.DATE_FORMAT);
}

export function formatSlashDate(date: Date) {
  return moment(date).format(Constants.DATE_SLASH_FORMAT);
}

export function formatFriendlyDate(date: Date) {
  return moment(date).format(Constants.HUMAN_DATE_FORMAT);
}

export function formatShortDate(date: Date) {
  return moment(date).format(Constants.SHORT_DATE_FORMAT);
}

export function formatFriendlyDateNoYear(date: Date) {
  return moment(date).format(Constants.HUMAN_DATE_FORMAT_NO_YEAR);
}

export function getDaysfromNow(date: string) {
  const parsedDate = moment(date);
  if (moment(new Date()).diff(parsedDate, "minutes") === 0) {
    return "now";
  } else if (moment(new Date()).diff(parsedDate, "minutes") < 60) {
    return moment(new Date()).diff(parsedDate, "minutes") + "m";
  } else if (moment(new Date()).diff(parsedDate, "hours") < 24) {
    return moment(new Date()).diff(parsedDate, "hours") + "h";
  } else if (moment(new Date()).diff(parsedDate, "days") < 7) {
    return moment(new Date()).diff(parsedDate, "days") + "d";
  } else {
    return moment(new Date()).diff(parsedDate, "weeks") + "w";
  }
}

export function parseFormatFriendlyDateNoYear(dateString: string) {
  return formatFriendlyDateNoYear(parseDate(dateString));
}

export function parseDateStrict(dateString: string) {
  if (isNullOrUndefined(dateString) || dateString === "") {
    return null;
  }
  return moment(dateString, "YYYY-MM-DD", true).toDate();
}

export function parseDate(dateString: string) {
  if (isNullOrUndefined(dateString) || dateString === "") {
    return null;
  }
  return moment(dateString).toDate();
}

export function parseDateMonthDayTime(dateString: string) {
  if (isNullOrUndefined(dateString) || dateString === "") {
    return null;
  }
  return moment(dateString).format("M/D LT");
}

export function parseDatePlusOneYear(dateString: string) {
  if (isNullOrUndefined(dateString) || dateString === "") {
    return null;
  }
  return moment(dateString).add(1, "year").toDate();
}

export function sortByDate(dateString1: string, dateString2: string) {
  const date1 = moment(dateString1);
  const date2 = moment(dateString2);

  if (date1.isBefore(date2)) {
    return 1;
  } else if (date2.isBefore(date1)) {
    return -1;
  } else {
    return 0;
  }
}

export function durationToDate(duration: string) {
  const initialTime = moment().startOf("day");
  const time = moment.duration(duration);
  const minutes = time.get("minutes");
  const hours = time.get("hours");
  return initialTime.add(hours, "hours").add(minutes, "minutes").toDate();
}

export function durationToString(duration: string) {
  const durationObject = moment.duration(duration);
  const timeString = moment.duration(durationObject).format("H:mm");
  if (timeString.includes(":")) {
    return { hours: timeString.split(":")[0], mins: timeString.split(":")[1] };
  }
  return { hours: "0", mins: timeString.split(":")[0] };
}

export function durationToStringFormat(duration: string, isDaily: boolean) {
  return isDaily
    ? moment.duration(duration).asDays().toFixed(2)
    : moment.duration(duration).format("h:mm");
}

export function datTimeWithZone(date: Date | Moment) {
  return moment(date).format("YYYY-MM-DD HH:mm:ss.SSS Z");
}

export function formatHoursMinsTimezone(date: Date) {
  if (moment(date).second() >= 1) {
    return moment(date).add(1, "minute").format("HH:mmZ");
  }
  return moment(date).format("HH:mmZ");
}

export function formatMonthDay(dateString: string | Date) {
  return moment(dateString).format("M/D ");
}

export function formatDateToDuration(date: Date | string) {
  const convert = moment(date).format("HH:mm");
  return moment.duration(convert).toJSON();
}

export function shortFormDate(date: string) {
  return moment(date).format("D/M/YY");
}

export function sumOfDuration(
  durationString1: string,
  durationString2: string
) {
  const duration1 = moment.duration(durationString1);
  const duration2 = moment.duration(durationString2);
  duration1.add(duration2);
  return moment.duration(duration1).toISOString();
}

interface ITimeEntryWithCreatedBy extends ITimeEntry {
  createdBy: IEtsUser;
}

export const sortTimeEntries = (sort: Sort) => (
  entry1: ITimeEntryWithCreatedBy,
  entry2: ITimeEntryWithCreatedBy
) => {
  let order = 0;

  switch (sort.field) {
    case "date":
      const entry1CreatedAt = moment(entry1.createdAt);
      const entry1StartDate = moment(entry1.startDatetime);
      const entry2CreatedAt = moment(entry2.createdAt);
      const entry2StartDate = moment(entry2.startDatetime);
      if (entry1StartDate.isBefore(entry2StartDate, "day")) {
        order = -1;
      } else if (entry2StartDate.isBefore(entry1StartDate, "day")) {
        order = 1;
      } else {
        if (entry1CreatedAt.isBefore(entry2CreatedAt)) {
          order = -1;
        } else if (entry2CreatedAt.isBefore(entry1CreatedAt)) {
          order = 1;
        } else {
          order = 0;
        }
      }
      break;
    case "subtask":
      order = entry1.subtaskDescription < entry2.subtaskDescription ? -1 : 1;
      break;
    case "createdBy":
      order =
        getFullName(entry1.createdBy) < getFullName(entry2.createdBy) ? -1 : 1;
      break;
    case "duration":
      const duration1 = moment.duration(entry1.duration).asMilliseconds();
      const duration2 = moment.duration(entry2.duration).asMilliseconds();
      order = duration1 < duration2 ? -1 : 1;
      break;
    default:
      break;
  }

  return sort.order === "ASC" ? order : -1 * order;
};

export const getYearStart = (dateString: string, yearsToAdd: number = 0) => {
  if (isNullOrUndefined(dateString) || dateString === "") {
    return null;
  }
  return moment(dateString).add(yearsToAdd, "year").startOf("year").toDate();
};

export const getYearEnd = (dateString: string, yearsToAdd: number = 0) => {
  if (isNullOrUndefined(dateString) || dateString === "") {
    return null;
  }
  return moment(dateString).add(yearsToAdd, "year").endOf("year").toDate();
};

export const getCurrYearEnd = () => {
  return moment().endOf("year").toDate();
};
