import { NonIdealState, Spinner } from "@blueprintjs/core";
import * as qs from "qs";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Api } from "../../actions";
import { ConnectedTaskTable } from "../../components";
import { IEtsState, ITask } from "../../model";
import { Dispatch } from "../../types";

interface ISearchStateProps {
  tasks: ITask[];
}

interface ISearchDispatchProps {
  onSubmitQuery: (query: string) => Promise<ITask[]>;
}

interface ISearchProps
  extends ISearchStateProps,
    ISearchDispatchProps,
    RouteComponentProps<any> {}

interface ISearchState {
  loading: boolean;
}

export class SearchImpl extends React.Component<ISearchProps, ISearchState> {
  constructor(props: ISearchProps) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  public componentDidMount() {
    this.submitQuery(null, this.props);
  }

  public componentDidUpdate(prevProps: ISearchProps) {
    this.submitQuery(prevProps, this.props);
  }

  public render(): JSX.Element {
    return <div className="search">{this.renderContent()}</div>;
  }

  private renderContent() {
    if (this.state.loading) {
      return <Spinner size={Spinner.SIZE_LARGE} />;
    }

    const tasks = this.props.tasks;
    const query = this.queryFromProps(this.props);
    if (tasks.length === 0) {
      return (
        <NonIdealState
          icon="search"
          title="No search results"
          description={
            <p>
              Your search didn&apos;t match any tasks.
              <br />
              Try searching for something else.
            </p>
          }
        />
      );
    }

    return <ConnectedTaskTable tasks={tasks} query={query} />;
  }

  private async submitQuery(
    prevProps: RouteComponentProps<any>,
    props: RouteComponentProps<any>
  ) {
    const query = this.queryFromProps(props);
    const prevQuery = this.queryFromProps(prevProps);
    if (prevQuery !== query) {
      this.setState({ ...this.setState, loading: true });
      await this.props.onSubmitQuery(query);
      this.setState({ ...this.state, loading: false });
    }
  }

  private queryFromProps(props: RouteComponentProps<any>): string {
    if (!props || !props.location || !props.location.search) {
      return null;
    }

    return qs.parse(props.location.search.slice(1)).query as string;
  }
}

function mapStateToProps(state: IEtsState): ISearchStateProps {
  return {
    tasks: state.taskSearchResults,
  };
}

function mapDispatchToProps(dispatch: Dispatch): ISearchDispatchProps {
  return {
    onSubmitQuery: (query: string) => {
      return dispatch(Api.Task.search(query));
    },
  };
}

export const Search = connect(mapStateToProps, mapDispatchToProps)(SearchImpl);
