import { Grid, makeStyles, TextField } from "@material-ui/core";
import { NumberInput } from "ra-ui-materialui";
import React from "react";
import { IInvoice } from "../../model";

interface IWipAndExpenseField {
  invoice: IInvoice;
  disableEdit: boolean;
}

const useStyles = makeStyles({
  amount: { width: 256 },
  credit: { width: "auto", display: "flex" },
  creditGrid: { marginTop: 5, width: "auto" },
});

export const WipAndExpenseField: React.FC<IWipAndExpenseField> = ({
  invoice,
  disableEdit,
}) => {
  const classes = useStyles({});
  const renderWipBilled = () => (
    <TextField
      variant="filled"
      label="WIP Billed"
      disabled={true}
      defaultValue={invoice.amtToBill}
      style={{ width: 256 }}
    />
  );
  const renderExpensesBilled = () => (
    <TextField
      variant="filled"
      label="Expenses Billed"
      disabled={true}
      defaultValue={invoice.expenseToBill}
      style={{ width: 256 }}
    />
  );
  return (
    <>
      <Grid container={true} className={classes.creditGrid}>
        <Grid
          item={true}
          xs={12}
          sm={6}
          direction="column"
          className={classes.credit}
        >
          {renderWipBilled()}
          <NumberInput
            source="wipToCredit"
            className={classes.amount}
            disabled={disableEdit}
          />
        </Grid>
        <Grid
          item={true}
          xs={12}
          sm={6}
          direction="column"
          className={classes.credit}
        >
          {renderExpensesBilled()}
          <NumberInput
            source="expenseToCredit"
            className={classes.amount}
            disabled={disableEdit}
          />
        </Grid>
      </Grid>
    </>
  );
};
