import { Dialog, IDialogProps } from "@blueprintjs/core";
import * as React from "react";
import { Constants } from "../../constants";
import { ImportantPersonCreate } from "../ImportantPerson/ImportantPersonCreate";

interface IImportantPersonCreateDialogProps extends IDialogProps {
  clientGroupId: string;
  clientCompanyId: string;
  onSuccess: () => void;
}

export const ImportantPersonCreateDialog: React.FC<IImportantPersonCreateDialogProps> = (
  props
) => {
  const { clientCompanyId, clientGroupId, onSuccess } = props;

  return (
    <div className="save-dialog-wrapper">
      <Dialog {...props} icon="person" title="Create Important Person">
        <ImportantPersonCreate
          resource={Constants.IMPORTANT_PERSON_RESOURCE}
          basePath="client_companies"
          clientGroupId={clientGroupId}
          clientCompanyId={clientCompanyId}
          onSuccess={onSuccess}
        />
      </Dialog>
    </div>
  );
};
