import { Icon } from "@blueprintjs/core";
import React from "react";

export const Warning = ({ text }) => {
  return (
    <div className="warning">
      <Icon className="icon" icon="warning-sign" iconSize={20} />
      <span className="text">{text}</span>
    </div>
  );
};
