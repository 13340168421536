import * as jsonExport from "jsonexport/dist";
import { downloadCSV } from "ra-core";
import { IFeeNoteLineItem } from "../../model/IFeeNoteLineItem";

export const feeNoteLineIemExport = (records: IFeeNoteLineItem[]) => {
  const data = records.map((record) => {
    return {
      "Client Company Name": record.clientCompanyName,
      "Client Id": record.clientId,
      "Billing Entity": record.billingEntity,
      "FeeNote Number": record.feeNoteNumber,
      "Custom Field": record.customField,
      "Date": record.date,
      "Approval Status": record.approvalStatus,
      "Code": record.code,
      "Description": record.description,
      "Amount": record.amount,
      "Department": record.department,
      "Assignee FirstName": record.assigneeFirstName,
      "Assignee LastName": record.assigneeLastName,
      "Reviewer FirstName": record.reviewerFirstName,
      "Reviewer LastName": record.reviewerLastName,
    };
  });
  jsonExport(data, {}, (err, csv) => {
    downloadCSV(csv, "line_items");
  });
};
