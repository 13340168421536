import * as React from "react";
import { connect } from "react-redux";
import { ConnectedTaskTable } from "../../components";
import { IEtsState, ITask } from "../../model";
import * as Utils from "../../utils/sort";
import * as TaskUtil from "../../utils/task";

interface IInboxProps {
  tasks: ITask[];
}

export class InboxImpl extends React.Component<IInboxProps> {
  public render(): JSX.Element {
    return (
      <div className="inbox">
        <ConnectedTaskTable
          completedTasksFilter={TaskUtil.filterTasksThisMonthDueDate}
          tasks={this.props.tasks}
        />
      </div>
    );
  }
}

function mapStateToProps(state: IEtsState): IInboxProps {
  const tasks = state.tasks.filter(
    (task) => task.assigneeId === state.auth.user.id
  );
  tasks.sort(Utils.sortByCreatedAtDesc);
  return {
    tasks,
  };
}

export const Inbox = connect(mapStateToProps)(InboxImpl);
