import { makeStyles } from "@material-ui/core";
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  Filter,
  ReferenceInput,
} from "ra-ui-materialui";
import * as React from "react";
import { useSelector } from "react-redux";
import { reportAccess } from "../../config";
import { Constants } from "../../constants";
import { ReportRole } from "../../constants/reportRoles";
import { IEtsState } from "../../model";
import { endDateValidation, startDateValidation } from "../../utils/validation";
import { ApprovalStatusSelectArrayInput } from "../inputs";

const filterClientCompany = (text) => ({ withName: text });

const useStyle = makeStyles({
  search: { marginLeft: 10, width: 300 },
});

export const TaskAccountBalanceFilter = (props) => {
  const classes = useStyle({ ...props });
  const roles = useSelector((state: IEtsState) => state.auth.roles);

  return (
    <Filter {...props}>
      <ReferenceInput
        label="Company Name"
        source="clientCompanyId"
        reference={Constants.CLIENT_COMPANY_RESOURCE}
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={filterClientCompany}
        alwaysOn={true}
        allowEmpty={true}
        emptyText="Clear Input"
        className={classes.search}
      >
        <AutocompleteInput source="name" />
      </ReferenceInput>
      <DateInput
        label="Start date is after"
        source="startDate"
        alwaysOn={true}
        validate={startDateValidation}
      />
      <DateInput
        label="Start date is before"
        source="endDate"
        alwaysOn={true}
        validate={endDateValidation}
      />
      <ApprovalStatusSelectArrayInput
        label="Approval statuses"
        source="approvalStatus"
      />
      {reportAccess(roles, ReportRole.TASK_ACCOUNT_BALANCE_TENANT) && (
        <ReferenceInput
          label="Department"
          source="departmentId"
          reference={Constants.DEPARTMENTS_RESOURCE}
          sort={{ field: "name", order: "ASC" }}
          filterToQuery={filterClientCompany}
          alwaysOn={true}
          allowEmpty={true}
          emptyText="Clear Input"
          className={classes.search}
        >
          <AutocompleteInput source="name" alwaysOn={true} />
        </ReferenceInput>
      )}
      <BooleanInput source="showAll" />
    </Filter>
  );
};
