import {
  Datagrid,
  DateField,
  ReferenceField,
  TextField,
} from "ra-ui-materialui";
import * as React from "react";
import { Pagination } from "react-admin";
import {
  FormGroupResponseLink,
  FormGroupResponseStatusField,
} from "../../components";
import { Constants } from "../../constants";
import { ReferenceManyList } from "../../ReferenceManyList";
import { FormGroupResponseDownloadButton } from "./FormGroupResponseDownloadButton";
import { FormGroupResponseExpandButton } from "./FormGroupResponseExpandButton";

export const FormGroupResponseReferenceList: React.FC<any> = (props) => {
  const clientGroupId = props.record.id;
  return (
    <ReferenceManyList
      {...props}
      reference={Constants.FORM_GROUP_RESPONSE_RESOURCE}
      target="client_group"
      perPage={5}
      pagination={<ListPagination />}
      sort={{ field: "created_at", order: "desc" }}
      bulkActionButtons={false}
      actions={false}
      exporter={false}
      filter={{ clientGroupId, status: "unfinished" }}
    >
      <Datagrid>
        <DateField source="createdAt" label="Date" />
        <ReferenceField
          label="Client User"
          source="clientUserId"
          reference={Constants.CLIENT_USER_RESOURCE}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Form"
          source="formGroupId"
          reference={Constants.FORM_GROUPS_RESOURCE}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <FormGroupResponseStatusField label="Status" />
        <FormGroupResponseLink label="Link" />
        <FormGroupResponseDownloadButton />
        <FormGroupResponseExpandButton scrollItoView={props.scrollItoView} />
      </Datagrid>
    </ReferenceManyList>
  );
};

const ListPagination: React.FC = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);
