// in src/comments/ApproveButton.js
import { Button } from "@blueprintjs/core";
import * as jsonexport from "jsonexport";
import { downloadCSV } from "ra-core";
import React, { useCallback, useState } from "react";
import { fetchEnd, fetchStart } from "react-admin";
import { useDispatch, useSelector } from "react-redux";
import { Constants } from "../../constants";
import { Api } from "../../core/api/actions";
import { IEtsState } from "../../model";
import { convertWipEntries } from "../../utils/csv";

interface ITimeEntriesDownloadProps {
  taskId: string;
}

const exportCsv = (data, isDaily) => {
  const coverData = convertWipEntries(data, isDaily);
  return jsonexport(coverData, {}, (err, csv) => {
    downloadCSV(csv, "wip entries with rate");
  });
};

export const TimeEntriesDownload: React.FC<ITimeEntriesDownloadProps> = ({
  taskId,
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state: IEtsState) => state.auth.token);
  const [loading, setLoading] = useState(false);
  const isDaily = useSelector(
    (state: IEtsState) => state.departmentSetting.timebillDaily
  );
  const handleClick = useCallback(async () => {
    setLoading(true);
    dispatch(fetchStart());
    const result = await fetch(
      `${Constants.WIP_ENTRIES_REPORT_PATH}?taskId=${taskId}`,
      {
        method: "GET",
        headers: Api.apiHeaders(token),
      }
    );
    const data = await result.json();
    exportCsv(data, isDaily);
    setLoading(false);
    dispatch(fetchEnd());
  }, [taskId, token]);
  return (
    <Button onClick={handleClick} disabled={loading}>
      Time Entries Download
    </Button>
  );
};
