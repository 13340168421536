import { Menu, MenuItem } from "@material-ui/core";
import ContentAdd from "@material-ui/icons/Add";
import { push } from "connected-react-router";
import { Button } from "ra-ui-materialui";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enabled } from "../../config/features";
import { Constants } from "../../constants";
import { IEtsState, IInvoice } from "../../model";

interface IInvoiceCrateMenuProps {
  source?: string;
  record?: IInvoice;
}

export const InvoiceCreateMenu: React.FC<IInvoiceCrateMenuProps> = ({
  source,
  record,
}) => {
  const roles = useSelector((state: IEtsState) => state.auth.roles);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickCreditNote = () => {
    setAnchorEl(null);
    dispatch(
      push(`${Constants.CREDIT_NOTE_PATH}/create?invoiceId=${record.id}`)
    );
  };

  const handleClickPayment = () => {
    setAnchorEl(null);
    dispatch(push(`${Constants.PAYMENT_PATH}/create?invoiceId=${record.id}`));
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        label="Create"
      >
        <ContentAdd />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {enabled("timebill.creditNote", roles) && (
          <MenuItem onClick={handleClickCreditNote} disabled={record.proForma}>
            Create Credit Note
          </MenuItem>
        )}
        {enabled("timebill.payment", roles) && (
          <MenuItem onClick={handleClickPayment} disabled={record.proForma}>
            Create Payment
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
