import { Icon } from "@blueprintjs/core";
import { Box, Divider, Grid } from "@material-ui/core";
import * as React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import { IClientCompany } from "../../../model";

interface ICompanyInfoFieldsProps {
  company: IClientCompany;
}

export const CompanyInfoFields: React.FC<ICompanyInfoFieldsProps> = ({
  company,
}) => {
  const {
    name,
    status,
    jurisdiction,
    registrationNumber,
    businessRegistrationCertificateNumber,
    registrationDate,
    companyType,
    address,
    description,
  } = company;

  return (
    <div className="client-portal-company-fields">
      <Box
        className="client-portal-company-field"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item={true} xs={12} md={6}>
          <Icon className="client-portal-company-field-icon" icon="tag" />
          <FormattedMessage
            description="manage page company name"
            defaultMessage="Company Name"
          />{" "}
          :
        </Grid>
        <Grid item={true} xs={12} md={6}>
          {name}
        </Grid>
      </Box>
      <Divider />
      <Box
        className="client-portal-company-field"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item={true} xs={12} md={6}>
          <Icon className="client-portal-company-field-icon" icon="confirm" />
          <FormattedMessage
            description="manage page Status"
            defaultMessage="Status"
          />{" "}
          :
        </Grid>
        <Grid item={true} xs={12} md={6}>
          {status ?? "-"}
        </Grid>
      </Box>
      <Divider />
      <Box
        className="client-portal-company-field"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item={true} xs={12} md={6}>
          <Icon className="client-portal-company-field-icon" icon="bookmark" />
          <FormattedMessage
            description="manage page Jurisdiction"
            defaultMessage="Jurisdiction"
          />{" "}
          :
        </Grid>
        <Grid item={true} xs={12} md={6}>
          {jurisdiction ?? "-"}
        </Grid>
      </Box>
      <Divider />
      <Box
        className="client-portal-company-field"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item={true} xs={12} md={6}>
          <Icon className="client-portal-company-field-icon" icon="label" />
          <FormattedMessage
            description="manage page company registration number"
            defaultMessage="Company Registration Number"
          />{" "}
          :
        </Grid>
        <Grid item={true} xs={12} md={6}>
          {registrationNumber ?? "-"}
        </Grid>
      </Box>
      <Divider />
      <Box
        className="client-portal-company-field"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item={true} xs={12} md={6}>
          <Icon className="client-portal-company-field-icon" icon="label" />
          <FormattedMessage
            description="manage page business registration certificate number"
            defaultMessage="Business Registration Certificate Number"
          />{" "}
          :
        </Grid>
        <Grid item={true} xs={12} md={6}>
          {businessRegistrationCertificateNumber ?? "-"}
        </Grid>
      </Box>
      <Divider />
      <Box
        className="client-portal-company-field"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item={true} xs={12} md={6}>
          <Icon className="client-portal-company-field-icon" icon="calendar" />
          <FormattedMessage
            description="manage page business incorporation date"
            defaultMessage="Incorporation Date"
          />{" "}
          :
        </Grid>
        <Grid item={true} xs={12} md={6}>
          {registrationDate ? (
            <FormattedDate
              value={registrationDate}
              year="numeric"
              month="long"
              day="2-digit"
            />
          ) : (
            "-"
          )}
        </Grid>
      </Box>
      <Divider />
      <Box
        className="client-portal-company-field"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item={true} xs={12} md={6}>
          <Icon className="client-portal-company-field-icon" icon="briefcase" />
          <FormattedMessage
            description="manage page business company type"
            defaultMessage="Company Type"
          />{" "}
          :
        </Grid>
        <Grid item={true} xs={12} md={6}>
          {companyType ?? "-"}
        </Grid>
      </Box>
      <Divider />
      <Box
        className="client-portal-company-field"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item={true} xs={12} md={6}>
          <Icon
            className="client-portal-company-field-icon"
            icon="map-marker"
          />
          <FormattedMessage
            description="manage page business registered address"
            defaultMessage="Registered Address"
          />{" "}
          :
        </Grid>
        <Grid item={true} xs={12} md={6}>
          {address ?? "-"}
        </Grid>
      </Box>
      <Divider />
      <Box
        className="client-portal-company-field"
        display="flex"
        justifyContent="space-between"
      >
        <Grid item={true} xs={12} md={6}>
          <Icon className="client-portal-company-field-icon" icon="clipboard" />
          <FormattedMessage
            description="manage page business company description"
            defaultMessage="Company Description"
          />{" "}
          :
        </Grid>
        <Grid item={true} xs={12} md={6}>
          {description ?? "-"}
        </Grid>
      </Box>
    </div>
  );
};
