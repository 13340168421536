import { Button, Intent } from "@blueprintjs/core";
import { IModelById, UserUtil } from "@chiubaka/core";
import { push } from "connected-react-router";
import * as React from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import { Constants } from "../constants";
import {
  DSFModel,
  IClientCompany,
  IClientGroup,
  IEtsState,
  IEtsUser,
  TSFModel,
} from "../model";
import { Dispatch } from "../types";
import * as Utils from "../utils/tableFilter";
import {
  FoldableTableHoc,
  IDSFTableControllerComponentProps,
  withDSFTableController,
  withUrlQueryableTable,
} from "./hoc";

const FoldableTable = FoldableTableHoc(withUrlQueryableTable(ReactTable));

interface ICompanyTableStateProps {
  companies: IClientCompany[];
  clientGroupsById: IModelById<IClientGroup>;
  usersById: IModelById<IEtsUser>;
}

interface ICompanyTableDispatchProps {
  onViewCompany: (id: string) => void;
  onViewClientGroup: (id: string) => void;
}

interface ICompanyTableProps
  extends ICompanyTableStateProps,
    ICompanyTableDispatchProps,
    IDSFTableControllerComponentProps {}

class CompanyTableImpl extends React.Component<ICompanyTableProps> {
  public render(): JSX.Element {
    const columns = this.getColumns();
    return (
      <FoldableTable
        columns={columns}
        data={this.props.companies}
        defaultFilterMethod={Utils.filterCaseInsensitive}
        filterable={true}
        sortable={true}
      />
    );
  }

  private getColumns() {
    return [
      {
        Cell: (cellInfo) => {
          return (
            <Button
              onClick={this.props.onViewCompany.bind(this, cellInfo.value)}
              icon="eye-open"
              intent={Intent.PRIMARY}
              minimal={true}
            />
          );
        },
        accessor: "id",
        sortable: false,
        filterable: false,
        resizable: false,
        width: 40,
      },
      {
        Header: "Client ID",
        accessor: "clientId",
        className: "vertical-center",
      },
      {
        Header: "Client Group",
        accessor: this.displayClientGroup.bind(this),
        id: "clientGroup",
        className: "vertical-center",
      },
      {
        Cell: (cellInfo) => {
          return (
            <Button
              onClick={this.props.onViewClientGroup.bind(this, cellInfo.value)}
              icon="zoom-in"
              minimal={true}
            />
          );
        },
        accessor: "clientGroupId",
        className: "vertical-center",
        sortable: false,
        filterable: false,
        resizable: false,
        width: 40,
      },
      {
        Header: "Name",
        accessor: "name",
        className: "vertical-center company-name",
      },
      {
        Header: "Chinese Name",
        accessor: "chineseName",
        className: "vertical-center",
      },
      {
        Header: "Jurisdiction",
        accessor: "jurisdiction",
        className: "vertical-center",
      },
      {
        Header: "Contact Name",
        accessor: "contactName",
        className: "vertical-center",
      },
      {
        Header: "Client PIC",
        accessor: this.displayUserName.bind(this, "assigneeId"),
        id: "assignee",
        className: "vertical-center",
      },
      {
        Header: "Client EIC",
        accessor: this.displayUserName.bind(this, "eicId"),
        id: "eic",
        className: "vertical-center",
      },
      ...this.props.dsfColumnConfigs,
    ];
  }

  private displayUserName(property: string, company: IClientCompany) {
    const user = this.props.usersById[company[property]];
    return UserUtil.displayValue(user, true);
  }

  private displayClientGroup(company: IClientCompany) {
    return this.props.clientGroupsById[company.clientGroupId].name;
  }
}

function mapStateToProps(state: IEtsState) {
  const companies = state.clientCompanies.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  });

  return {
    companies,
    clientGroupsById: state.clientGroupsById,
    usersById: state.usersById,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    onViewCompany: (id: string) => {
      dispatch(push(`${Constants.COMPANY_DETAIL_PATH}/${id}?isComplete=false`));
    },
    onViewClientGroup: (id: string) => {
      dispatch(
        push(`${Constants.CLIENTGROUP_DETAIL_PATH}/${id}?isComplete=false`)
      );
    },
  };
}

export const CompanyTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withDSFTableController(
    CompanyTableImpl,
    DSFModel.Company,
    TSFModel.TenantCompany
  ) as any
);
