import { Checkbox } from "@blueprintjs/core";
import React from "react";

export const CustomCheckbox: React.FC = (props) => {
  return (
    <div className="custom-checkbox">
      <Checkbox {...props} />
    </div>
  );
};
