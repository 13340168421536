import {
  getAuthToken,
  getClientUserDetail,
  setClientUser,
  setClientUserToken,
} from "..";
import { Api } from "../api/actions";
import {
  getClientToken,
  removeClientUser,
  removeClientUserToken,
} from "../auth";

const clientPortalAuthProvider = {
  login: async (token) => {
    const request = await fetch("/client_user_auth/validate_token", {
      method: "GET",
      headers: Api.apiHeaders(token),
    });
    const response = await request.json();
    const status = await request.status;
    if (status < 200 || status >= 300) {
      throw new Error(response.statusText);
    }
    setClientUserToken(getAuthToken(await request));
    setClientUser(getClientUserDetail(response));
  },
  checkAuth: async () => {
    const clientUserToken = localStorage.getItem("client_user_token");

    if (clientUserToken) {
      const request = await fetch("/client_user_auth/validate_token", {
        method: "GET",
        headers: Api.apiHeaders(JSON.parse(clientUserToken).data),
      });
      const status = await request.status;
      if (status < 200 || status >= 300) {
        removeClientUser();
        return removeClientUserToken();
      }
      return Promise.resolve();
    }

    return Promise.reject({ redirectTo: "/client_app/login_generator" });
  },
  logout: async () => {
    const token = getClientToken();
    const request = await fetch("/client_user_auth/sign_out", {
      method: "DELETE",
      headers: Api.apiHeaders(token),
    });
    const response = await request.json();
    const status = await request.status;
    if (status < 200 || status >= 300) {
      throw new Error(response.statusText);
    }
    removeClientUser();
    removeClientUserToken();
  },
  checkError: (error) => Promise.resolve(),
  getPermissions: (params) => Promise.resolve(),
};

export default clientPortalAuthProvider;
