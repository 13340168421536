import React from "react";
import { priceFormatter } from "../../utils/money";

interface IPriceFieldProps {
  textAlign?: "right" | "left";
  source: string;
  label?: string;
  record?: { amount: string };
  sortable?: boolean;
}

export const PriceField: React.FC<IPriceFieldProps> = ({
  source,
  record,
  label = "Amount",
  sortable = false,
}) => {
  return (
    <span className="amount-price">
      {priceFormatter.format(Number(record[source]))}
    </span>
  );
};
