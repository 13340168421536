import { defineMessages } from "react-intl";

export default defineMessages({
  "resources.invoices.fields.date": {
    id: "resources.invoices.fields.date",
    defaultMessage: "Date",
  },
  "resources.invoices.fields.title": {
    id: "resources.invoices.fields.title",
    defaultMessage: "Title",
  },
  "resources.invoices.fields.amount": {
    id: "resources.invoices.fields.amount",
    defaultMessage: "Amount",
  },
  "resources.invoices.fields.status": {
    id: "resources.invoices.fields.status",
    defaultMessage: "Status",
  },
  "resources.invoices.fields.feeNoteNumber": {
    id: "resources.invoices.fields.feeNoteNumber",
    defaultMessage: "Fee Note #",
  },
  "resources.invoices.fields.clientCompanyId": {
    id: "resources.invoices.fields.clientCompanyId",
    defaultMessage: "Company",
  },
  "resources.invoices.fields.payment": {
    id: "resources.invoices.fields.payment",
    defaultMessage: "Payment",
  },
  "resources.invoices.fields.pdf": {
    id: "resources.invoices.fields.pdf",
    defaultMessage: "PDF",
  },
  "invoices.status.not_paid": {
    id: "invoices.status.not_paid",
    defaultMessage: "Not Paid",
  },
  "invoices.status.pay_partial": {
    id: "invoices.status.pay_partial",
    defaultMessage: "Partially Paid",
  },
  "invoices.status.paid": {
    id: "invoices.status.paid",
    defaultMessage: "paid",
  },
  "resources.timesheets.fields.startDate": {
    id: "resources.timesheets.fields.startDate",
    defaultMessage: "Start Date",
  },
  "resources.timesheets.fields.endDate": {
    id: "resources.timesheets.fields.endDate",
    defaultMessage: "End Date",
  },
  "resources.timesheets.fields.approvalStatus": {
    id: "resources.timesheets.fields.approvalStatus",
    defaultMessage: "Approval Status",
  },
  "resources.invoices.fields.contact": {
    id: "resources.invoices.fields.contact",
    defaultMessage: "Contact",
  },
  "resources.invoices.fields.reference": {
    id: "resources.invoices.fields.reference",
    defaultMessage: "Reference",
  },
  "resources.invoices.fields.lineItems": {
    id: "resources.invoices.fields.lineItems",
    defaultMessage: "Line items",
  },
  "resources.invoices.fields.internalRemarks": {
    id: "resources.invoices.fields.internalRemarks",
    defaultMessage: "Internal Remarks",
  },
  "resources.invoices.fields.customField": {
    id: "resources.invoices.fields.customField",
    defaultMessage: "Custom Field",
  },
  "resources.credit_notes.fields.Status": {
    id: "resources.credit_notes.fields.Status",
    defaultMessage: "Status",
  },
  "resources.credit_notes.fields.date": {
    defaultMessage: "Date",
    id: "resources.credit_notes.fields.date",
  },
  "resources.credit_notes.fields.description": {
    defaultMessage: "Description",
    id: "resources.credit_notes.fields.description",
  },
  "resources.write_offs.fields.amount": {
    id: "resources.write_offs.fields.amount",
    defaultMessage: "Amount",
  },
  "resources.write_offs.fields.approvalStatus": {
    defaultMessage: "Approval Status",
    id: "resources.write_offs.fields.approvalStatus",
  },
  "resources.write_offs.fields.date": {
    defaultMessage: "Date",
    id: "resources.write_offs.fields.date",
  },
  "resources.write_offs.fields.description": {
    defaultMessage: "Description",
    id: "resources.write_offs.fields.description",
  },
  "resources.write_offs.fields.entryType": {
    defaultMessage: "Entry Type",
    id: "resources.write_offs.fields.entryType",
  },
  "resources.expenses.fields.description": {
    defaultMessage: "Description",
    id: "resources.write_offs.fields.description",
  },
  "resources.expenses.fields.amount": {
    defaultMessage: "Amount",
    id: "resources.expenses.fields.amount",
  },
  "resources.form_group_responses.fields.status": {
    id: "resources.form_group_responses.fields.status",
    defaultMessage: "Status",
  },
  "resources.tasks_account_balances.fields.assigneeId": {
    id: "resources.tasks_account_balances.fields.assigneeId",
    defaultMessage: "Assignee",
  },
  "resources.fee_note_line_items.fields.startDate": {
    id: "resources.fee_note_line_items.fields.startDate",
    defaultMessage: "Start Date",
  },
  "resources.fee_note_line_items.fields.endDate": {
    id: "resources.fee_note_line_items.fields.endDate",
    defaultMessage: "End Date",
  },
  "resources.fee_note_line_items.fields.approvalStatus": {
    id: "resources.fee_note_line_items.fields.approvalStatus",
    defaultMessage: "Approval Status",
  },
  "resources.credit_notes_lists.fields.clientCompanyName": {
    id: "resources.credit_notes_lists.fields.clientCompanyName",
    defaultMessage: "Client Company Name",
  },
  "resources.expenses.fields.date": {
    id: "resources.expenses.fields.date",
    defaultMessage: "Date",
  },
  "resources.adjustments.fields.date": {
    id: "resources.adjustments.fields.date",
    defaultMessage: "Date",
  },
  "resources.adjustments.fields.entryType": {
    id: "resources.adjustments.fields.entryType",
    defaultMessage: "Entry Type",
  },
  "resources.adjustments.fields.description": {
    id: "resources.adjustments.fields.description",
    defaultMessage: "Description",
  },
  "resources.adjustments.fields.amount": {
    id: "resources.adjustments.fields.amount",
    defaultMessage: "Amount",
  },
  "resources.payments.fields.date": {
    id: "resources.payments.fields.date",
    defaultMessage: "Date",
  },
  "resources.tasks_account_balances.fields.showAll": {
    id: "resources.tasks_account_balances.fields.showAll",
    defaultMessage: "Show all",
  },
  "resources.payments.fields.wipAmount": {
    id: "resources.payments.fields.wipAmount",
    defaultMessage: "Wip Amount",
  },
  "resources.payments.fields.expenseAmount": {
    id: "resources.payments.fields.expenseAmount",
    defaultMessage: "Expense Amount",
  },
  "resources.invoices.fields.taskId": {
    id: "resources.invoices.fields.taskId",
    defaultMessage: "Task",
  },
  "resources.write_offs.fields.taskId": {
    id: "resources.write_offs.fields.taskId",
    defaultMessage: "Task",
  },
  "resources.expenses.fields.taskId": {
    id: "resources.expenses.fields.taskId",
    defaultMessage: "Task",
  },
  "resources.important_people.fields.natural_person_id": {
    id: "resources.important_people.fields.natural_person_id",
    defaultMessage: "Nature Person",
  },
  "resources.important_people.fields.title": {
    id: "resources.important_people.fields.title",
    defaultMessage: "Title",
  },
  "resources.important_people.fields.notes": {
    id: "resources.important_people.fields.notes",
    defaultMessage: "Notes",
  },
});
