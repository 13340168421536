import { Button, Checkbox, Intent, TextArea } from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import Downshift, { StateChangeOptions } from "downshift";
import { History } from "history";
import moment from "moment";
import React, { useCallback, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../../actions";
import { IEtsState, ISubtask } from "../../model";
import * as Utils from "../../utils/date";
import {
  AddTimeEntryDispatchContext,
  AddTimeEntryStateContext,
} from "./context";
import { initialState, reducer } from "./reducer";
import { SubtaskDownshift } from "./SubtaskDownshift";
import { TimeEntryTabs } from "./Tabs";

export interface IAddTimeEntryProps {
  subtask: ISubtask;
  taskId: string;
  history: History;
  onTimeSubtask: (subtask: ISubtask) => void;
}

const itemToString = (subtask: ISubtask) => subtask?.description ?? "";

export const AddTimeEntry = ({
  subtask,
  taskId,
  onTimeSubtask,
}: IAddTimeEntryProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    description,
    duration,
    endTime,
    isChargeable,
    isOpen,
    startDate,
    startTime,
    subtask: sub,
  } = state;
  const subtasks = useSelector((s: IEtsState) => s.tasksById[taskId].subtasks);
  const setSubtask = (s: ISubtask) =>
    dispatch({ type: "SET_SUBTASK", payload: s });
  useEffect(() => {
    if (subtask?.id) {
      setSubtask(subtask);
    }
  }, [subtask?.id]);
  const reduxDispatch = useDispatch();

  const handleChangeSubtask = (selectedItem?: ISubtask) => {
    setSubtask(selectedItem);
    onTimeSubtask(selectedItem);
  };

  const handleChangeState = (changes: StateChangeOptions<ISubtask>) => {
    if (changes.isOpen !== undefined) {
      dispatch({
        type: "SET_IS_OPEN",
        payload:
          changes.type === Downshift.stateChangeTypes.mouseUp
            ? changes.isOpen
            : true,
      });
    }
  };

  const handleChangeDate = (date: Date) =>
    dispatch({ type: "SET_START_DATE", payload: date });
  const handleChangeDescription: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) =>
    dispatch({ type: "SET_DESCRIPTION", payload: event.currentTarget.value });
  const handleChangeChargeable = () =>
    dispatch({ type: "SET_IS_CHARGEABLE", payload: !isChargeable });

  const handleCreate = useCallback(() => {
    if (moment(startTime).isAfter(endTime)) {
      return window.alert("Start time should be before end time");
    }
    reduxDispatch(
      Api.TimeEntry.create({
        description,
        duration,
        isChargeable,
        taskId,
        endDatetime: moment(endTime).toISOString(),
        startDatetime: moment(startTime).toISOString(),
        subtaskId: sub?.id,
      })
    );
    dispatch({ type: "RESET" });
  }, [sub?.id, startTime, endTime, isChargeable, description, duration]);

  return (
    <AddTimeEntryStateContext.Provider value={{ state }}>
      <AddTimeEntryDispatchContext.Provider value={{ dispatch }}>
        <div className="add-time-entry">
          <div className="add-time-entry-content">
            <div className="add-time-entry-date-input">
              <DateInput
                onChange={handleChangeDate}
                value={startDate}
                formatDate={Utils.formatSlashDate}
                parseDate={Utils.parseDate}
              />
            </div>
            <div className="add-time-entry-subtask-description">
              <SubtaskDownshift
                onChange={handleChangeSubtask}
                itemToString={itemToString}
                onStateChange={handleChangeState}
                isOpen={isOpen}
                items={subtasks}
                selectedItem={sub}
              />
            </div>
            <div className="add-time-entry-description">
              <TextArea
                growVertically={true}
                placeholder="Description"
                value={description}
                fill={true}
                onChange={handleChangeDescription}
              />
            </div>
            <div className="add-time-entry-chargeable">
              <Checkbox
                checked={isChargeable}
                onChange={handleChangeChargeable}
                label="Chargeable?"
                inline={true}
                alignIndicator="right"
                disabled={!!subtask}
              />
            </div>

            <div className="add-time-entry-time-input">
              <TimeEntryTabs />
            </div>
          </div>
          <div className="time-entry-button">
            <Button
              intent={Intent.PRIMARY}
              text="Save"
              className="time-entry-submit-button"
              onClick={handleCreate}
            />
          </div>
        </div>
      </AddTimeEntryDispatchContext.Provider>
    </AddTimeEntryStateContext.Provider>
  );
};
