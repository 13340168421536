import { makeStyles } from "@material-ui/styles";
import {
  AutocompleteInput,
  DateInput,
  Filter,
  ReferenceInput,
} from "ra-ui-materialui";
import React from "react";
import { useSelector } from "react-redux";
import { reportAccess } from "../../config/features";
import { Constants } from "../../constants";
import { IEtsState } from "../../model";
import { endDateValidation, startDateValidation } from "../../utils/validation";

const filterDepartment = (text) => ({ withName: text });

const useStyle = makeStyles({
  search: { marginLeft: 10, width: 300 },
});

export const DateFilter = (props) => {
  const {
    approvalStatusSelectArrayInput,
    reportType,
    balanceFilterInput,
  } = props;
  const roles = useSelector((state: IEtsState) => state.auth.roles);
  const classes = useStyle({});
  return (
    <Filter {...props}>
      <DateInput
        label="Start date"
        source="startDate"
        alwaysOn={true}
        validate={startDateValidation}
      />
      <DateInput
        label="End date"
        source="endDate"
        alwaysOn={true}
        validate={endDateValidation}
      />
      {reportAccess(roles, reportType) && (
        <ReferenceInput
          label="Department"
          source="departmentId"
          reference={Constants.DEPARTMENTS_RESOURCE}
          sort={{ field: "name", order: "ASC" }}
          filterToQuery={filterDepartment}
          alwaysOn={true}
          allowEmpty={true}
          emptyText="Clear Input"
          className={classes.search}
        >
          <AutocompleteInput source="name" />
        </ReferenceInput>
      )}
      {approvalStatusSelectArrayInput && approvalStatusSelectArrayInput}
      {balanceFilterInput && balanceFilterInput}
    </Filter>
  );
};
