import { IModel } from "@chiubaka/core";
import * as qs from "qs";
import { GetListParams } from "ra-core";
import { ThunkDispatch } from "redux-thunk";
import { ApiAction, SearchableModelApi } from "../../core";
import { toFilter } from "../../utils/dataProvider";

export declare type Dispatch = ThunkDispatch<any, void, any>;

export class EnhancedModelApi<
  BackendType extends IModel,
  FrontendType extends IModel = BackendType
> extends SearchableModelApi<BackendType, FrontendType> {
  constructor(modelName: string) {
    super(modelName);
  }

  public getAll(params?: GetListParams): ApiAction<FrontendType[]> {
    if (!params) {
      return super.getAll();
    }

    return this.actionCreator(
      `${this.getListEndpoint().slice(0, -1)}?${qs.stringify(
        {
          page: params.pagination.page,
          per_page: params.pagination.perPage,
          filter: toFilter(params.sort, params.filter),
        },
        {
          arrayFormat: "brackets",
        }
      )}`,
      null,
      "GET",
      (disptach, response: FrontendType[]) => {
        disptach(this.successfulGetAllAction(response));
      },
      null,
      this.bulkTransformForFrontend
    );
  }

  public getAllCustomSuffix(subPath: string): ApiAction<FrontendType[]> {
    return this.actionCreator(
      this.getListEndpoint() + subPath,
      null,
      "GET",
      (dispatch, response: FrontendType[]) => {
        dispatch(this.successfulGetAllAction(response));
      },
      null,
      this.bulkTransformForFrontend
    );
  }

  public getAllCustomPathAndParams(
    path: string,
    params?: GetListParams
  ): ApiAction<FrontendType[]> {
    if (!params) {
      return this.getAllCustomPath(path);
    }
    return this.actionCreator(
      `${path}?${qs.stringify(params.filter)}`,
      null,
      "GET",
      (dispatch, response: FrontendType[]) => {
        dispatch(this.successfulGetAllAction(response));
      },
      null,
      this.bulkTransformForFrontend
    );
  }

  public getAllCustomPath(path: string): ApiAction<FrontendType[]> {
    return this.actionCreator(
      path,
      null,
      "GET",
      (dispatch, response: FrontendType[]) => {
        dispatch(this.successfulGetAllAction(response));
      },
      null,
      this.bulkTransformForFrontend
    );
  }

  public postCustomPath(path: string, payload: any): ApiAction<FrontendType> {
    return this.actionCreator(
      path,
      payload,
      "POST",
      (dispatch, response: FrontendType) => {
        dispatch(this.successfulUpdateAction(payload, response));
      },
      null,
      this.transformForFrontend
    );
  }
  public getCustomPath(path: string): ApiAction<FrontendType> {
    return this.actionCreator(
      path,
      null,
      "GET",
      (dispatch, response: FrontendType) => {
        dispatch(this.successfulGetAction(response));
      },
      null,
      this.transformForFrontend
    );
  }
}
