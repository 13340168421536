import { makeStyles } from "@material-ui/styles";
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  ReferenceField,
  TextField,
} from "ra-ui-materialui";
import React from "react";
import { Constants } from "../../constants";
import { EmptyPage } from "../EmptyPage/EmptyPage";
import { PriceField, UserField } from "../fields";
import { FeeNoteNumberField } from "../Invoice/FeeNoteNumberField";
import { InvoiceFilter } from "../Invoice/InvoiceFilter";
import { ApprovalStatusList } from "./CustomField/ApprovalStatusList";
import { EntryTypeField } from "./CustomField/EntryTypeField";

const useStyles = makeStyles({
  createdBy: { minWidth: 150 },
  container: {
    margin: "50px 10px",
  },
});

export const WriteOffList: React.FC<any> = (props) => {
  const classes = useStyles(props);
  const toAccountBalance = (record) => {
    return `${Constants.ACCOUNT_BALANCE_PATH}/${record.taskId}`;
  };

  const toInvoice = (record) => {
    return `${Constants.INVOICE_PATH}/${record.invoiceId}`;
  };

  return (
    <List
      {...props}
      exporter={false}
      sort={{ field: "date", order: "DESC" }}
      empty={<EmptyPage {...props} />}
      filters={<InvoiceFilter includeDefaultFilter="taskId" />}
      bulkActionButtons={false}
      hasCreate={false}
      className={classes.container}
    >
      <Datagrid>
        <DateField source="date" label="Date" />
        <ReferenceField
          label="Company"
          source="clientCompanyId"
          reference="client_companies"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Fee Note #"
          source="invoiceId"
          reference={Constants.INVOICE_RESOURCE}
          link={toInvoice}
        >
          <FeeNoteNumberField source="feeNoteNumber" />
        </ReferenceField>
        <EntryTypeField label="Type" source="entryType" />
        <ReferenceField
          label="Job"
          source="taskId"
          reference="tasks"
          link={toAccountBalance}
        >
          <TextField source="description" />
        </ReferenceField>
        <UserField label="Created By" source="createdById" />
        <PriceField source="amount" />
        <ApprovalStatusList source="approvalStatus" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
