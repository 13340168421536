import {
  SortDirection,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  withStyles,
} from "@material-ui/core";
import React from "react";

const headCells = [
  { id: "company", numeric: false, disablePadding: true, label: "Company" },
  { id: "type", numeric: false, disablePadding: false, label: "Type" },
  { id: "date", numeric: true, disablePadding: false, label: "Date" },
];

const StyledTableCell = withStyles(() => ({
  root: {
    color: "#ffffff",
  },
  head: {
    "&:hover": {
      color: "#ffffff",
    },
    "&$active": {
      " color": "#ffffff",
      "&& $icon": {
        opacity: 1,
        color: "#ffffff",
      },
    },
    "backgroundColor": "#106ba3",
    "color": "#ffffff",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableSortLabel = withStyles(
  {
    root: {
      "&:focus": {
        color: "#ffff",
      },
      "&:hover": {
        "color": "#ffff",
        "& $icon": {
          opacity: 0.5,
        },
      },
      "&$active": {
        "color": "#ffff",
        "&& $icon": {
          opacity: 1,
          color: "#ffff",
        },
      },
    },
    active: {},
    icon: {
      userSelect: "none",
    },
    iconDirectionDesc: {},
    iconDirectionAsc: {},
  },
  { name: "MuiTableSortLabel" }
)(TableSortLabel);

export const TimelineTableHeader = ({ sort, setSort }) => {
  const createSortHandler = (property) => (event) => {
    const isAsc = sort.field === property && sort.order === "asc";
    setSort({
      field: property,
      order: isAsc ? "desc" : "asc",
    });
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={
              sort.field === headCell.id ? (sort.order as SortDirection) : false
            }
          >
            <StyledTableSortLabel
              active={sort.field === headCell.id}
              direction={
                sort.field === headCell.id
                  ? sort.order === "asc"
                    ? "asc"
                    : "desc"
                  : "asc"
              }
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </StyledTableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
