import { getRefAndAnswer } from "@app/utils/typeformConvert";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { IFormResponse } from "../../model/IFormRespones";
import { TypeformExpand } from "./TypeformExpand";

interface ITypeformShowProps {
  record?: IFormResponse;
}

const useStyles = makeStyles(() => ({
  expand: {
    padding: 25,
  },
}));

export const TypeformShow: React.FC<ITypeformShowProps> = ({ record }) => {
  const { definition, answers } = record.responseData.formResponse;
  const { fields } = definition;
  const refWithAnswers = getRefAndAnswer(answers);
  const [allExpand, setAllExpand] = useState<boolean>(false);

  const handleOnchange = () => setAllExpand(!allExpand);
  const classes = useStyles({});

  return (
    <div className={classes.expand}>
      <div>
        <a onClick={handleOnchange} href="javascript:void(0)">
          {allExpand ? "Collapse All" : "Expand All"}
        </a>
      </div>
      <br />
      {fields.map((field, index) => {
        return answers.map((answer) => {
          if (field.id === answer.field.id) {
            return (
              <TypeformExpand
                index={index}
                answer={answer}
                field={field}
                allExpanded={allExpand}
                refWithAnswers={refWithAnswers}
              />
            );
          }
        });
      })}
    </div>
  );
};
