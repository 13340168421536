import {
  Button,
  Checkbox,
  Icon,
  InputGroup,
  Intent,
  Popover,
  Position,
  Spinner,
} from "@blueprintjs/core";
import { DateInput } from "@blueprintjs/datetime";
import { IModelById } from "@chiubaka/core";
import classNames from "classnames";
import Fuse from "fuse.js";
import moment from "moment";
import { Sort } from "ra-core";
import * as React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { enabled } from "../../config/features";
import { Constants } from "../../constants";
import { IEtsState, IEtsUser, ISubtask } from "../../model";
import {
  canCreateSubtasks,
  canDestroySubtasks,
  canUpdateSubtasks,
} from "../../selectors";
import * as DateUtils from "../../utils/date";
import { returnEmailEnvPrefix } from "../../utils/environment";
import { getFullName } from "../../utils/user";
import HighlightWrapper from "../HighlightWrapper/HighlightWrapper";
import { UserCompactSelect } from "../select/UserSelect";
import { SortableHeader, SortableHeaderItem } from "../SortableHeader";

const DEFAULT_SUBTASK_COUNT = 5;

interface ISubtaskListStateProps {
  enableCreating: boolean;
  enableEditing: boolean;
  enableDeleting: boolean;
  usersById: IModelById<IEtsUser>;
  roles: string[];
}

interface ISubtaskListOwnProps {
  subtasks: ISubtask[];
  onTimeSubtask: (subtask: ISubtask) => void;
  onUpdateSubtask: (original: ISubtask, updated: ISubtask) => Promise<ISubtask>;
  onDeleteSubtask: (subtask: ISubtask) => Promise<void>;
}

interface ISubtaskListProps
  extends ISubtaskListStateProps,
    ISubtaskListOwnProps,
    RouteComponentProps<any> {}

interface ISubtaskListState {
  reloadingSubtasks: Set<string>;
  editedSubtaskDescriptions: { [id: string]: string };
  isAdmin: boolean;
  sort: Sort;
  filter: string;
  isExpanded: boolean;
}

interface ISubtaskWithAssignee extends ISubtask {
  assignee?: IEtsUser;
}

const sortSubtasks = (sort: Sort) => (
  subtask1: ISubtaskWithAssignee,
  subtask2: ISubtaskWithAssignee
) => {
  let order = 0;
  switch (sort.field) {
    case "description":
      order = subtask1.description < subtask2.description ? -1 : 1;
      break;
    case "assignee":
      if (subtask1.assignee && subtask2.assignee) {
        order =
          getFullName(subtask1.assignee) < getFullName(subtask2.assignee)
            ? -1
            : 1;
      } else if (subtask1.assignee) {
        order = -1;
      } else if (subtask2.assignee) {
        order = 1;
      }
      break;
    case "isChargeable":
      if (subtask1.isChargeable !== subtask2.isChargeable) {
        order = subtask1.isChargeable ? -1 : 1;
      }
      break;
    case "isComplete":
      if (subtask1.isComplete !== subtask2.isComplete) {
        order = subtask1.isComplete ? -1 : 1;
      }
      break;
    case "isStarred":
      if (subtask1.isStarred !== subtask2.isStarred) {
        order = subtask1.isStarred ? -1 : 1;
      }
      break;
    case "dueDate":
      order = moment(subtask1.dueDate || 0).isBefore(subtask2.dueDate || 0)
        ? -1
        : 1;
      break;
    default:
      break;
  }

  return sort.order === "ASC" ? order : -1 * order;
};

const filterSubtasks = (subtasks: ISubtaskWithAssignee[], filter: string) => {
  if (!filter.length) {
    return subtasks;
  }
  return new Fuse(subtasks, {
    keys: ["assignee.firstName", "assignee.lastName", "description"],
  }).search(filter);
};

class SubtaskListImpl extends React.Component<
  ISubtaskListProps,
  ISubtaskListState
> {
  private subtaskDescriptionEditor;

  constructor(props: ISubtaskListProps) {
    super(props);

    this.state = {
      isExpanded: false,
      editedSubtaskDescriptions: {},
      reloadingSubtasks: new Set(),
      isAdmin: enabled("timebill.editSubtask", this.props.roles),
      sort: { field: "", order: "ASC" },
      filter: "",
    };

    this.subtaskDescriptionEditor = React.createRef();

    this.renderSubtask = this.renderSubtask.bind(this);

    this.handleSubtaskToggle = this.handleSubtaskToggle.bind(this);
    this.handleSubtaskDescriptionChange = this.handleSubtaskDescriptionChange.bind(
      this
    );
    this.handleSubtaskDescriptionKeyPress = this.handleSubtaskDescriptionKeyPress.bind(
      this
    );
    this.finishEditingSubtask = this.finishEditingSubtask.bind(this);
    this.deleteSubtask = this.deleteSubtask.bind(this);

    this.startReloadingSubtask = this.startReloadingSubtask.bind(this);
    this.finishReloadingSubtask = this.finishReloadingSubtask.bind(this);
    this.startEditingSubtaskDescription = this.startEditingSubtaskDescription.bind(
      this
    );
    this.finishEditingSubtaskDescription = this.finishEditingSubtaskDescription.bind(
      this
    );
  }

  public componentDidUpdate(_prevProps: ISubtaskListProps) {
    const editor = this.subtaskDescriptionEditor.current;
    if (editor) {
      editor.focus();
    }
  }

  public render() {
    const { subtasks, usersById } = this.props;
    const { sort, filter, isExpanded } = this.state;

    const subtasksWithAssignee = subtasks.map<ISubtaskWithAssignee>(
      (subtask) => ({ ...subtask, assignee: usersById[subtask.assigneeId] })
    );

    const subtaskElements = filterSubtasks(subtasksWithAssignee, filter)
      .sort(sortSubtasks(sort))
      .slice(0, isExpanded ? Infinity : DEFAULT_SUBTASK_COUNT)
      .map(this.renderSubtask);

    return (
      <>
        <div className="subtasks-title">
          <div className="subtasks-title-group">
            <h6>
              <Icon icon="properties" />
              Subtasks
            </h6>
            <Button
              text={isExpanded ? "Hide" : "Show"}
              intent={Intent.PRIMARY}
              onClick={this.handleExpand}
            />
          </div>
          <InputGroup
            className="search-input"
            leftIcon="search"
            placeholder="Search"
            onChange={this.handleFilter}
            value={filter}
          />
        </div>
        <div className="subtasks">
          <SortableHeader onClick={this.handleClickSort} sort={this.state.sort}>
            <SortableHeaderItem
              label="⭑"
              field="isStarred"
              className={classNames(
                "subtask-list-is-starred",
                "subtask-list-center"
              )}
            />
            <SortableHeaderItem
              label="✓"
              field="isComplete"
              className={classNames(
                "subtask-list-is-complete",
                "subtask-list-center"
              )}
            />
            <SortableHeaderItem
              label="Description"
              field="description"
              className="subtask-list-description"
            />
            <SortableHeaderItem
              label="$"
              field="isChargeable"
              className="subtask-list-is-chargeable"
            />
            <SortableHeaderItem
              label="Due Date"
              field="dueDate"
              className="subtask-list-due-date"
            />
            <SortableHeaderItem
              label="Assignee"
              field="assignee"
              className="subtask-list-assignee"
            />
          </SortableHeader>
          {subtaskElements}
        </div>
      </>
    );
  }

  private handleExpand = () => {
    this.setState((prevState) => ({
      ...prevState,
      isExpanded: !prevState.isExpanded,
    }));
  };

  private handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filter: event.target.value,
    });
  };

  private handleClickSort = (sort: Sort) => {
    this.setState({ sort });
  };

  private renderSubtask(subtask: ISubtask) {
    const id = subtask.id;
    const editingDescription = this.state.editedSubtaskDescriptions.hasOwnProperty(
      id
    );
    const descriptionElement = editingDescription
      ? this.renderSubtaskDescriptionEditor(subtask)
      : this.renderSubtaskDescription(subtask);

    const starElement = this.renderStar(subtask);

    const completionElement = this.state.reloadingSubtasks.has(id)
      ? this.renderSpinner()
      : this.renderCheckbox(subtask);

    const copyEmailElement = editingDescription
      ? null
      : this.renderCopyButton(id);

    const timeEntry = this.renderClockIcon(subtask);

    const isChargeable = this.renderIsChargeable(subtask);

    // doesn't render dueDate when editing description
    const dueDateElement = editingDescription
      ? null
      : this.renderDueDateInput(subtask);

    // doesn't render assignee when editing description
    const assigneeElement = editingDescription
      ? null
      : this.renderAssignee(subtask);

    const deletionElement = editingDescription
      ? null
      : this.renderSubtaskDeletion(subtask);

    return (
      <HighlightWrapper id={id} key={id} className="subtask-highlight-wrapper">
        <div className="subtask-entry-container">
          {starElement}
          {completionElement}
          <div className="subclass-description-clipboard">
            <div className="subtask-entry-left-align">
              {descriptionElement}
              {copyEmailElement}
            </div>
          </div>
          <div className="subtask-entry-right-align">
            {isChargeable}
            {timeEntry}
            {dueDateElement}
            {assigneeElement}
            {deletionElement}
          </div>
        </div>
      </HighlightWrapper>
    );
  }

  private renderCopyButton(id) {
    const copyEmailAddress =
      "sub-id-" +
      id +
      "@noreply." +
      returnEmailEnvPrefix() +
      "mail.easytrack.ai";
    return (
      <Popover position={Position.BOTTOM}>
        <div className="subtask-copy-email">
          <CopyToClipboard text={copyEmailAddress}>
            <Button
              className={classNames("copy-clipboard-icon", "icon-align-center")}
              minimal={true}
            >
              <Icon icon="clipboard" iconSize={14} />
            </Button>
          </CopyToClipboard>
        </div>
        {<div className="tooltip-message">Email Address Copied</div>}
      </Popover>
    );
  }

  private renderSpinner() {
    return <Spinner intent={Intent.PRIMARY} size={16} />;
  }

  private renderStar(subtask: ISubtask) {
    const isStarred = subtask.isStarred;

    return (
      <div
        className={classNames("subtask-list-is-starred", "subtask-list-center")}
      >
        <Button
          className="is-starred"
          icon={isStarred ? "symbol-diamond" : null}
          intent={isStarred ? Intent.SUCCESS : null}
          minimal={true}
          onClick={this.handleSubtaskToggleIsStarred.bind(this, subtask)}
        />
      </div>
    );
  }

  private renderCheckbox(subtask: ISubtask) {
    const isComplete = subtask.isComplete;

    return (
      <div
        className={classNames(
          "subtask-list-is-complete",
          "subtask-list-center"
        )}
      >
        <Checkbox
          checked={isComplete}
          inline={true}
          onChange={this.handleSubtaskToggle.bind(this, subtask)}
          disabled={!this.props.enableEditing}
        />
      </div>
    );
  }

  private renderSubtaskDescriptionEditor(subtask: ISubtask) {
    return (
      <InputGroup
        disabled={this.state.reloadingSubtasks.has(subtask.id)}
        inputRef={this.subtaskDescriptionEditor}
        onBlur={this.finishEditingSubtask.bind(this, subtask)}
        onKeyPress={this.handleSubtaskDescriptionKeyPress.bind(this, subtask)}
        onChange={this.handleSubtaskDescriptionChange.bind(this, subtask)}
        value={this.state.editedSubtaskDescriptions[subtask.id]}
      />
    );
  }

  private renderSubtaskDescription(subtask: ISubtask) {
    const { enableEditing } = this.props;
    const onClick = enableEditing
      ? this.startEditingSubtaskDescription.bind(this, subtask)
      : null;
    const description = subtask.description;
    const completedElem = this.renderSubtaskCompletionString(subtask);

    return (
      <>
        <span className="description" onClick={onClick}>
          {description}
        </span>
        {completedElem}
      </>
    );
  }

  private renderSubtaskCompletionString(subtask: ISubtask) {
    const { usersById } = this.props;
    if (!subtask.isComplete) {
      return "";
    }
    const completedBy = usersById[subtask.completedById].abbreviation;
    const completedAt = DateUtils.parseFormatFriendlyDateNoYear(
      subtask.completedAt
    );
    const completedString = `by ${completedBy} on ${completedAt}`;
    return <span className="completed-string-text">{completedString}</span>;
  }

  private renderDueDate(subtask: ISubtask) {
    let formattedDate = "";
    if (subtask.dueDate) {
      formattedDate = DateUtils.formatFriendlyDate(
        DateUtils.parseDate(subtask.dueDate)
      );
    }
    return (
      <div className="subtask-entry-due-date">
        <span>{formattedDate}</span>
      </div>
    );
  }

  private renderDueDateInput(subtask: ISubtask) {
    return (
      <div className="subtask-entry-due-date">
        <DateInput
          className="subtask-entry-due-date-input"
          value={DateUtils.parseDate(subtask.dueDate)}
          formatDate={DateUtils.formatFriendlyDateNoYear}
          parseDate={DateUtils.parseDate}
          placeholder=""
          maxDate={Constants.MAX_DATE}
          onChange={this.handleDueDateChange.bind(this, subtask)}
          disabled={false}
        />
      </div>
    );
  }

  private renderIsChargeable = (subtask: ISubtask) => {
    const { isAdmin } = this.state;
    return (
      <div className="subtask-ischargeable">
        <Checkbox
          onChange={this.handleOnChangeIsChargeable.bind(this, subtask)}
          checked={subtask.isChargeable}
          inline={true}
          alignIndicator="left"
          disabled={!isAdmin}
        />
      </div>
    );
  };

  private renderClockIcon = (subtask: ISubtask) => {
    return (
      <div className="subtask-time-entry">
        <Button onClick={this.props.onTimeSubtask.bind(this, subtask)}>
          <Icon icon="time" iconSize={20} color="#5c7080" />
        </Button>
      </div>
    );
  };

  private renderAssignee(subtask: ISubtask) {
    return (
      <div className="subtask-entry-assignee">
        <UserCompactSelect
          selectedId={subtask.assigneeId}
          onSelect={this.handleAssigneeSelect.bind(this, subtask)}
          disabled={false}
          defaultButtonLabel=""
        />
      </div>
    );
  }

  private renderSubtaskDeletion(subtask: ISubtask) {
    if (!this.props.enableDeleting) {
      return null;
    }

    return (
      <Icon
        icon="cross"
        iconSize={18}
        onClick={this.deleteSubtask.bind(this, subtask)}
      />
    );
  }

  private async handleAssigneeSelect(subtask: ISubtask, assignee: IEtsUser) {
    this.startReloadingSubtask(subtask);
    await this.props.onUpdateSubtask(subtask, {
      ...subtask,
      assigneeId: assignee.id,
    });

    this.finishReloadingSubtask(subtask);
  }

  private async handleDueDateChange(subtask: ISubtask, selectedDate: Date) {
    this.startReloadingSubtask(subtask);
    await this.props.onUpdateSubtask(subtask, {
      ...subtask,
      dueDate: DateUtils.formatDate(selectedDate),
    });

    this.finishReloadingSubtask(subtask);
  }

  private async handleSubtaskToggle(subtask: ISubtask) {
    this.startReloadingSubtask(subtask);
    await this.props.onUpdateSubtask(subtask, {
      ...subtask,
      isComplete: !subtask.isComplete,
    });
    this.finishReloadingSubtask(subtask);
  }

  private handleSubtaskToggleIsStarred(subtask: ISubtask) {
    this.startReloadingSubtask(subtask);
    this.props.onUpdateSubtask(subtask, {
      ...subtask,
      isStarred: !subtask.isStarred,
    });
    this.finishReloadingSubtask(subtask);
  }

  private handleSubtaskDescriptionChange(
    subtask: ISubtask,
    event: React.FormEvent<any>
  ) {
    const editedSubtaskDescriptions = {
      ...this.state.editedSubtaskDescriptions,
    };
    editedSubtaskDescriptions[subtask.id] = event.currentTarget.value;
    this.setState({ ...this.state, editedSubtaskDescriptions });
  }

  private handleSubtaskDescriptionKeyPress(
    subtask: ISubtask,
    event: React.KeyboardEvent<HTMLInputElement>
  ) {
    if (event.key === "Enter") {
      this.finishEditingSubtask(subtask);
    }
  }

  private async finishEditingSubtask(subtask: ISubtask) {
    const newDescription = this.state.editedSubtaskDescriptions[subtask.id];
    if (subtask.description !== newDescription && newDescription.length > 0) {
      this.startReloadingSubtask(subtask);
      await this.props.onUpdateSubtask(subtask, {
        ...subtask,
        description: newDescription,
      });
      this.finishReloadingSubtask(subtask);
      this.finishEditingSubtaskDescription(subtask);
    } else {
      this.finishEditingSubtaskDescription(subtask);
    }
  }

  private deleteSubtask(subtask: ISubtask) {
    this.startReloadingSubtask(subtask);
    this.props.onDeleteSubtask(subtask);
  }

  private startReloadingSubtask(subtask: ISubtask) {
    const reloadingSubtasks = new Set(this.state.reloadingSubtasks);
    reloadingSubtasks.add(subtask.id);
    this.setState({ ...this.state, reloadingSubtasks });
  }

  private finishReloadingSubtask(subtask: ISubtask) {
    const reloadingSubtasks = new Set(this.state.reloadingSubtasks);
    reloadingSubtasks.delete(subtask.id);
    this.setState({ ...this.state, reloadingSubtasks });
  }

  private startEditingSubtaskDescription(subtask: ISubtask) {
    const editedSubtaskDescriptions = {
      ...this.state.editedSubtaskDescriptions,
    };
    editedSubtaskDescriptions[subtask.id] = subtask.description;
    this.setState({ ...this.state, editedSubtaskDescriptions });
  }

  private finishEditingSubtaskDescription(subtask: ISubtask) {
    const editedSubtaskDescriptions = {
      ...this.state.editedSubtaskDescriptions,
    };
    delete editedSubtaskDescriptions[subtask.id];
    this.setState({ ...this.state, editedSubtaskDescriptions });
  }

  private handleOnChangeIsChargeable = async (
    subtask: ISubtask,
    event: React.FormEvent<HTMLInputElement>
  ) => {
    this.startReloadingSubtask(subtask);
    await this.props.onUpdateSubtask(subtask, {
      ...subtask,
      isChargeable: event.currentTarget.checked,
    });

    this.finishReloadingSubtask(subtask);
  };
}

function mapStateToProps(state: IEtsState): ISubtaskListStateProps {
  const usersById = state.usersById;

  return {
    enableCreating: canCreateSubtasks(state),
    enableEditing: canUpdateSubtasks(state),
    enableDeleting: canDestroySubtasks(state),
    roles: state.auth.roles,
    usersById,
  };
}

const SubtaskListNoRouter = connect(mapStateToProps)(SubtaskListImpl);
export const SubtaskList = withRouter(SubtaskListNoRouter);
