import * as React from "react";
import { connect } from "react-redux";
import { Api } from "../../actions/api";
import { ConnectedTaskTable, TableKanbanSwitcher } from "../../components";
import { Follow } from "../../components/Follow/Follow";
import {
  IEtsState,
  ITeamDispatchProps,
  ITeamProps,
  ITeamStateProps,
} from "../../model";
import { taskKanbanTeam } from "../../selectors";
import { Dispatch } from "../../types";
import * as Utils from "../../utils/sort";
import * as TaskUtil from "../../utils/task";
import { Kanban } from "../Kanban/Kanban";

export class TeamImpl extends React.Component<ITeamProps> {
  public render(): JSX.Element {
    const { columnList, model, teamId, followingOwnTeam } = this.props;
    const table = (
      <div>
        {teamId && (
          <Follow
            followableId={teamId}
            type="Team"
            userIsFollowing={followingOwnTeam}
            onClick={this.handleUpdateFollowingStatus}
          />
        )}
        <ConnectedTaskTable
          completedTasksFilter={TaskUtil.filterTasksThisMonthDueDate}
          tasks={this.props.tasks}
        />
      </div>
    );

    const board = <Kanban columnList={columnList} model={model} />;

    return <TableKanbanSwitcher table={table} board={board} />;
  }

  private handleUpdateFollowingStatus = (followingOwnTeam) => {
    this.setState({
      ...this.state,
      followingOwnTeam,
    });
    return this.props.onGetUser(this.props.userId);
  };
}

function mapStateToProps() {
  const taskKanbanTeamList = taskKanbanTeam();

  return (state: IEtsState): ITeamStateProps => {
    const tasks = state.tasks.filter(
      (task) =>
        task.assigneeId === state.auth.user.id ||
        state.usersById[task.assigneeId].teamId ===
          state.usersById[state.auth.user.id].teamId
    );
    const user = state.usersById[state.auth.user.id];
    tasks.sort(Utils.sortByCreatedAtDesc);
    return {
      teamId: user.teamId,
      userId: user.id,
      followingOwnTeam: user.followingOwnTeam,
      tasks,
      columnList: taskKanbanTeamList(state),
      model: Api.Task,
    };
  };
}

function mapDispatchToProps(dispatch: Dispatch): ITeamDispatchProps {
  return {
    onGetUser: (id: string) => {
      return dispatch(Api.User.get(id));
    },
  };
}

export const Team = connect(mapStateToProps, mapDispatchToProps)(TeamImpl);
