import * as jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import { IHourByUser } from "../../model/IHourByUser";
import { durationToStringFormat } from "../../utils/date";
import { priceFormatter } from "../../utils/money";

export const hourByUserExporter = (isDaily: boolean) => (
  records: IHourByUser[]
) => {
  const budgetedKey = `Budgeted ${isDaily ? "Days" : "Hours"}`;
  const actualKey = `Actual ${isDaily ? "Days" : "Hours"}`;
  const data = records.map((record) => {
    const newDetail = record.records.map((detail) => ({
      "Description": detail.taskDescription,
      "Start Date": detail.taskStartDate,
      "Client Company": detail.clientCompanyName,
      [budgetedKey]: durationToStringFormat(detail.estTime, isDaily),
      [actualKey]: durationToStringFormat(detail.actualTime, isDaily),
      "Rate": priceFormatter.format(Number(detail.rate)),
      "Budgeted WIP": priceFormatter.format(Number(detail.estAmount)),
      "Actual WIP": priceFormatter.format(Number(detail.actualAmount)),
      "Variance": priceFormatter.format(Number(detail.variance)),
    }));

    return {
      "User Name": record.userName,
      "Job": newDetail,
    };
  });

  jsonExport(data, {}, (err, csv) => {
    downloadCSV(csv, "actual_vs_budget_by_user");
  });
};
