import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { Resource } from "ra-core";
import { Notification } from "ra-ui-materialui";
import React from "react";
import { AdminContext } from "react-admin";
import {
  ClientInvoiceList,
  ClientInvoiceShow,
} from "../components/ClientPortal/ClientInvoice";
import { Constants } from "../constants";
import clientPortalAuthProvider from "../core/clientUserAuth/clientAuthProvider";
import { i18nIntlProvider } from "../i18n/i18Provider";
import { history } from "../store";
import { ClientAppRouter } from "./ClientAppRouter";
import { Intl } from "./Intl";

export const ClientPortalApp: React.FC<any> = (props) => {
  const { clientPortalDataProvider } = props;

  return (
    <ThemeProvider theme={createMuiTheme()}>
      <AdminContext
        authProvider={clientPortalAuthProvider}
        dataProvider={clientPortalDataProvider}
        i18nProvider={i18nIntlProvider}
        history={history}
      >
        <Intl>
          <Resource name={Constants.USER_RESOURCE} intent="registration" />
          <Resource
            name={Constants.CLIENT_USER_RESOURCE}
            intent="registration"
          />
          <Resource
            name={Constants.CLIENT_COMPANY_RESOURCE}
            intent="registration"
          />
          <Resource
            name={Constants.CLIENT_PORTAL_ATTACHMENT_RESOURCE}
            intent="registration"
          />
          <Resource
            name={Constants.FORMS_RESPONSE_RESOURCE}
            intent="registration"
          />
          <Resource
            name={Constants.FORM_GROUP_RESPONSE_RESOURCE}
            intent="registration"
          />
          <Resource
            name={Constants.INVOICE_RESOURCE}
            intent="registration"
            list={ClientInvoiceList}
            show={ClientInvoiceShow}
          />
          <ClientAppRouter />
          <Notification />
        </Intl>
      </AdminContext>
    </ThemeProvider>
  );
};
