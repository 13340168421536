import { cond, filter, flow, identity } from "lodash/fp";
import moment from "moment";
import { Constants } from "../constants";
import { IBalanceEntry } from "../model";
import { durationToStringFormat, sortByDate } from "./date";

export const sourceEntryLink = (entry: IBalanceEntry) => {
  switch (entry.entryType) {
    case "Invoice":
      return `${Constants.INVOICE_PATH}/${entry.entry.id}`;
    case "WriteOff":
      return `${Constants.WRITE_OFF_PATH}/${entry.entry.id}`;
    case "CreditNote":
      return `${Constants.CREDIT_NOTE_PATH}/${entry.entry.id}`;
    case "WipEntry":
      return `${Constants.TIMESHEET_PATH}/${entry.entry.timesheetId}`;
    case "Expense":
      return `${Constants.EXPENSES_PATH}/${entry.entry.id}`;
    case "Adjustment":
      return `${Constants.ADJUSTMENT_PATH}/${entry.entry.id}`;
  }
};

export const balanceEntriesWithTotal = (
  entries: IBalanceEntry[]
): Array<{ entry: IBalanceEntry; total: number }> => {
  return entries.reduce(
    (arr, entry, index) => [
      ...arr,
      {
        entry,
        total:
          index === 0
            ? parseFloat(entry.entryAmount)
            : arr[index - 1].total + parseFloat(entry.entryAmount),
      },
    ],
    []
  );
};

export const filterBalanceEntriesWithTotal = (
  showProvisional,
  range: [Date, Date]
) =>
  flow(
    cond([
      [() => showProvisional, identity],
      [
        () => !showProvisional,
        filter<IBalanceEntry>((entry) => entry.approvalStatus === "approved"),
      ],
    ]),
    filter(
      (entry) =>
        moment(entry.createdAt).isAfter(range[0]) &&
        moment(entry.createdAt).isBefore(range[1])
    ),
    (es) =>
      es.sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? -1 : 1)),
    balanceEntriesWithTotal
  );

export const sumOfBalanceEntries = (entries: Array<{ entry: IBalanceEntry }>) =>
  [...entries].reduce(
    (amount, entry) => amount + parseFloat(entry.entry.entryAmount),
    0
  );

export const mapEntryType = (type: string) => {
  switch (type) {
    case "WipEntry":
      return "Timesheet";
    case "Adjustment":
      return "Transfer";
    case "Invoice":
      return "Fee Note";
    case "WriteOff":
      return "Write-off";
    case "CreditNote":
      return "Credit Note";
    default:
      return type;
  }
};

export const mapDescription = (
  entry: IBalanceEntry,
  isDaily: boolean = false
) => {
  if (entry.entryType === "WipEntry") {
    const seconds = moment.duration(entry.entry.duration).asSeconds();
    const rate =
      (Number(entry.entry.amount) / seconds) * (isDaily ? 24 * 3600 : 3600);
    const durationString = durationToStringFormat(
      entry.entry.duration,
      isDaily
    );
    return `${durationString} x $${rate.toFixed(0)}/${isDaily ? "d" : "h"}`;
  }
  switch (entry.entryType) {
    case "CreditNote":
      return entry.entry.internalRemarks;
    case "WriteOff":
      return entry.entry.description;
    case "Invoice":
      if (entry.entry.proForma) {
        return `(Pro Forma) ${entry.entry.internalRemarks ?? ""}`;
      } else {
        return entry.entry.internalRemarks;
      }
    case "Expense":
      return entry.entry.description;
    case "Adjustment":
      return entry.entry.description;
  }
};

export const getStartDateAndEndDate = (
  expenseEntries: IBalanceEntry[],
  wipEntries: IBalanceEntry[]
): [Date, Date] => {
  const total = expenseEntries.concat(wipEntries);
  total.sort((a, b) => {
    return sortByDate(b.createdAt, a.createdAt);
  });
  const startDate = moment(total[0].createdAt).startOf("day").toDate();
  const endDate = moment(total[total.length - 1].createdAt)
    .endOf("day")
    .toDate();
  return [startDate, endDate];
};
