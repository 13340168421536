import { Api } from "../actions";
import { IDepartmentSetting } from "../model";

const initialState: IDepartmentSetting = {
  id: "",
  reviewStatusId: [],
  allowInvoiceDateOverride: false,
  timebillDaily: false,
  timesheetCreateTimeEntry: false,
};

export function departmentSettingsReducer(
  state = initialState,
  action: ReturnType<typeof Api.DepartmentSetting.successfulGetAllAction>
): IDepartmentSetting {
  switch (action.type) {
    case "SUCCESSFUL_GET_ALL_DEPARTMENT_SETTINGS":
      return action.payload.reduce(
        (s, setting) => ({
          ...s,
          ...setting,
          allowInvoiceDateOverride:
            setting.allowInvoiceDateOverride?.includes("true") ?? false,
          timebillDaily: setting.timebillDaily?.includes("true") ?? false,
          timesheetCreateTimeEntry:
            setting.timesheetCreateTimeEntry?.includes("true") ?? false,
        }),
        state
      );
    // Until it's hooked up to API, use the hardcoded value
    default:
      return state;
  }
}
