import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
// import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import classnames from "classnames";
import { compact } from "lodash";
import moment from "moment";
import { useGetManyReference } from "ra-core";
import React, { useState } from "react";
import { Constants } from "../../constants";
import { IModel } from "../../core";
import {
  balanceEntriesWithTotal,
  sumOfBalanceEntries,
} from "../../utils/balanceEntry";
import { priceColor } from "../../utils/color";
import { priceFormatter } from "../../utils/money";

const useStyles = makeStyles({
  boldCell: { fontWeight: "bold" },
  container: { minWidth: "100%", marginTop: "20px" },
  rightAlignedCell: { textAlign: "right" },
});

interface IAccountBalanceTableProps {
  taskId: string;
  resource: string;
  range: [Date, Date];
  showProvisional: boolean;
  rowRenderer: (entry: IModel, total: number) => React.ReactNode;
}

export const AccountBalanceTable: React.FC<IAccountBalanceTableProps> = (
  props
) => {
  const [page] = useState(0);
  const [rowsPerPage] = useState(10000);

  const classes = useStyles(props);
  const { taskId, resource, range, showProvisional, rowRenderer } = props;
  const startDate = range[0] && moment(range[0]).format("YYYY-MM-DD");
  const endDate = range[1] && moment(range[1]).format("YYYY-MM-DD");

  const { ids = [], data = {} } = useGetManyReference(
    resource,
    Constants.TASK_RESOURCE,
    taskId,
    { page, perPage: rowsPerPage },
    { field: "date", order: "ASC" },
    {
      startDate,
      endDate,
      approvalStatus: showProvisional
        ? ["need_eic_approval", "need_pic_approval", "approved"]
        : ["approved"],
    },
    Constants.TASK_RESOURCE
  );

  const entries = React.useMemo(
    () => balanceEntriesWithTotal(compact(ids.map((id) => data[id]))),
    [ids, data]
  );

  const totalAmount = sumOfBalanceEntries(entries);

  // TODO: Enable pagination
  // const handleChangeRowsPerPage: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> =
  //   (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  //   };

  // const handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void =
  //   (_, newPage) => {
  //     setPage(newPage);
  //   };

  return (
    <Paper className={classes.container}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell>Status</TableCell>
            <TableCell className={classnames(classes.rightAlignedCell)}>
              Amount
            </TableCell>
            <TableCell className={classnames(classes.rightAlignedCell)}>
              Running total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {entries.map(({ entry, total: entryTotal }) =>
            rowRenderer(entry, entryTotal)
          )}
          <TableRow>
            <TableCell colSpan={4} />
            <TableCell
              className={classnames(classes.boldCell, classes.rightAlignedCell)}
            >
              Total
            </TableCell>
            <TableCell
              style={priceColor(totalAmount)}
              className={classnames(classes.rightAlignedCell)}
            >
              {priceFormatter.format(totalAmount)}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
};
