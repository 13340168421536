import { Button } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Datagrid, ReferenceField, TextField } from "ra-ui-materialui";
import React from "react";
import { Link } from "react-router-dom";
import { Constants } from "../../constants";
import { IImportantPerson } from "../../model";

const stopPropagation = (event) => {
  event.stopPropagation();
};

interface IImportantPersonShowButtonProps {
  record?: IImportantPerson;
}

interface IImportantPersonNoteProps {
  record?: IImportantPerson;
}

const ImportantPersonShowButton: React.FC<IImportantPersonShowButtonProps> = ({
  record,
}) => {
  return (
    <Button
      component={Link}
      to={`${Constants.COMPANY_DETAIL_PATH}/${record.clientCompanyId}/important_people`}
      onClick={stopPropagation}
      color="primary"
    >
      <VisibilityIcon className="important-person-show-icon" /> SHOW
    </Button>
  );
};

const ImportantPersonNote: React.FC<IImportantPersonNoteProps> = (props) => {
  return <span>{props.record.notes}</span>;
};

export const ImportantPersonList: React.FC<any> = (props) => {
  return (
    <Datagrid {...props} rowClick="expand" expand={<ImportantPersonNote />}>
      <ReferenceField
        source="clientCompanyId"
        reference={Constants.CLIENT_COMPANY_RESOURCE}
        label="Company Name"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="title" label="Title" />
      <ImportantPersonShowButton />
    </Datagrid>
  );
};
