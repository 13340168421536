import { IModel } from "@chiubaka/core";
import * as React from "react";
import {
  IAttachmentSortable,
  IChatThreadSortable,
  ICommentSortable,
  IEmailSortable,
  ISortable,
  IVersionSortable,
} from "../../model";
import { ChatThread } from "../ChatThread/ChatThread";
import { Comment } from "../Comment/Comment";
import { EmailThread } from "../EmailThread/EmailThread";
import { Attachment } from "./Attachment";
import { ChangeEntry } from "./ChangeEntry";

interface IHistoryEntryProps {
  entry: ISortable;
  parentEntryType: string;
  afterUpdate?: (id: string) => Promise<IModel[]>;
  startSharingAttachment?: (selectedAttachment, type) => void;
}

export const HistoryEntry = ({
  entry,
  parentEntryType,
  afterUpdate,
  startSharingAttachment,
}: IHistoryEntryProps) => {
  const type = entry.entry;
  switch (type) {
    case "attachment":
      return (
        <Attachment
          record={entry as IAttachmentSortable}
          startSharingAttachment={startSharingAttachment.bind(
            this,
            entry,
            "timeline"
          )}
          parentEntryType={parentEntryType}
        />
      );
    case "comment":
      const commentEntry = entry as ICommentSortable;
      return <Comment record={commentEntry} />;
    case "version":
    case "departmentSpecificFieldsVersion":
      const changeEntry = entry as IVersionSortable;
      return <ChangeEntry record={changeEntry} />;
    case "email":
      const emailEntry = entry as IEmailSortable;
      return (
        <EmailThread
          key={emailEntry.id}
          entry={emailEntry}
          startSharingAttachment={startSharingAttachment}
          parentEntryType={parentEntryType}
        />
      );
    case "chat":
      const chatEntry = entry as IChatThreadSortable;
      return <ChatThread key={chatEntry.createdAt} entry={chatEntry} />;
  }
};
