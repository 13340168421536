import moment from "moment";
import { ISubtask } from "../../model";

export interface IAddTimeEntryState {
  description: string;
  duration: string;
  endTime: Date;
  isChargeable: boolean;
  isOpen: boolean;
  startDate: Date;
  startTime: Date;
  subtask?: ISubtask;
  tabs: "duration" | "time";
}

const initialTime = moment().startOf("day").toDate();

export const initialState: IAddTimeEntryState = {
  description: "",
  duration: "PT0H",
  endTime: initialTime,
  isChargeable: false,
  isOpen: false,
  startDate: initialTime,
  startTime: initialTime,
  tabs: "duration",
  subtask: null,
};

export type IAddTimeEntryAction =
  | { type: "SET_SUBTASK"; payload: ISubtask }
  | { type: "SET_IS_OPEN"; payload: boolean }
  | { type: "RESET" }
  | { type: "SET_START_DATE"; payload: Date }
  | { type: "SET_START_TIME"; payload: Date }
  | { type: "SET_END_TIME"; payload: Date }
  | { type: "SET_DESCRIPTION"; payload: string }
  | { type: "SET_DURATION"; payload: string }
  | { type: "SET_IS_CHARGEABLE"; payload: boolean };

export const reducer = (
  state: IAddTimeEntryState,
  action: IAddTimeEntryAction
) => {
  switch (action.type) {
    case "SET_SUBTASK":
      return {
        ...state,
        isChargeable: action.payload?.isChargeable,
        subtask: action.payload,
      };
    case "SET_IS_OPEN":
      return {
        ...state,
        isOpen: action.payload,
      };
    case "SET_START_DATE":
      return {
        ...state,
        startDate: action.payload,
      };
    case "SET_START_TIME":
      const startTime = moment(action.payload);
      startTime.set({
        year: moment(state.startDate).year(),
        month: moment(state.startDate).month(),
        date: moment(state.startDate).date(),
      });
      return {
        ...state,
        startTime: startTime.toDate(),
      };
    case "SET_END_TIME":
      const endTime = moment(action.payload);
      endTime.set({
        year: moment(state.startDate).year(),
        month: moment(state.startDate).month(),
        date: moment(state.startDate).date(),
      });
      return {
        ...state,
        endTime: endTime.toDate(),
      };
    case "SET_IS_CHARGEABLE":
      return {
        ...state,
        isChargeable: action.payload,
      };
    case "SET_DURATION":
      return {
        ...state,
        duration: action.payload,
        startTime: moment(state.startDate).startOf("D").toDate(),
        endTime: moment(state.startDate)
          .startOf("D")
          .add(
            Math.ceil(moment.duration(action.payload).asMinutes()),
            "minutes"
          )
          .subtract()
          .toDate(),
      };
    case "SET_DESCRIPTION":
      return {
        ...state,
        description: action.payload,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};
