import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { priceFormatter } from "../../utils/money";

interface IInvoiceBalancesProps {
  type: string;
  amountBilled: string;
  remainingBalance: string;
  showRemaining: boolean;
}

const useStyles = makeStyles({
  fields: {
    color: "gray",
    display: "inline-block",
    lineHeight: "1.5em",
    marginLeft: 10,
    marginTop: 10,
    width: 250,
  },
});

export const InvoiceBalancesDisplay: React.FC<IInvoiceBalancesProps> = ({
  type,
  amountBilled,
  remainingBalance,
  showRemaining,
}) => {
  const classes = useStyles({});
  const balanceType = type.charAt(0).toUpperCase() + type.slice(1);

  return (
    <Box component="div" className={classes.fields}>
      <Box display="flex" justifyContent="space-between">
        <span>Total {balanceType} Billed :</span>
        <span>{priceFormatter.format(parseFloat(amountBilled))}</span>
      </Box>
      {showRemaining && (
        <Box display="flex" justifyContent="space-between">
          <span>{balanceType} Balance :</span>
          <span>{priceFormatter.format(parseFloat(remainingBalance))}</span>
        </Box>
      )}
    </Box>
  );
};
