import { formatFriendlyDate, parseDateStrict } from "@app/utils/date";
import { HTMLSelect, InputGroup } from "@blueprintjs/core";
// These component imports must be direct, or we will create a circular dependency
import { DateInput } from "../components/DateInput";
import { Constants } from "../constants/index";
import { IDepartmentSpecificFieldsConfig } from "../model";
import {
  DATE_INPUT_DEFAULT_PROPS,
  DATE_INPUT_EDIT_CELL_DEFAULT_PROPS,
  TEXT_INPUT_EDIT_CELL_DEFAULT_PROPS,
} from "./defaultProps";
import {
  jckAuditCompanyFields,
  jckAuditTaskFields,
} from "./jckAuditDepartmentSpecificFields";

export const DEPARTMENT_SPECIFIC_TASK_FIELDS: IDepartmentSpecificFieldsConfig = {
  Accounting: [
    {
      name: "Fee Note Num",
      field: `feeNoteNumber${Constants.DEPARTMENT_KEY}`,
      // not required as per client request
      belongsTo: Constants.DEPARTMENT_KEY,
      createComponentProps: {
        placeholder: "Fee Note Num",
      },
      ...TEXT_INPUT_EDIT_CELL_DEFAULT_PROPS,
    },
    {
      name: "FS Date",
      field: `fsDate${Constants.DEPARTMENT_KEY}`,
      belongsTo: Constants.DEPARTMENT_KEY,
      ...DATE_INPUT_EDIT_CELL_DEFAULT_PROPS,
    },
    {
      name: "Trust Name",
      field: `trustName${Constants.DEPARTMENT_KEY}`,
      belongsTo: Constants.DEPARTMENT_KEY,
      ...TEXT_INPUT_EDIT_CELL_DEFAULT_PROPS,
    },
  ],
  JckAudit: jckAuditTaskFields,
  LinkpathAudit: [
    {
      name: "Period Ended",
      field: `periodEndDate${Constants.DEPARTMENT_KEY}`,
      belongsTo: Constants.DEPARTMENT_KEY,
      ...DATE_INPUT_EDIT_CELL_DEFAULT_PROPS,
      createComponentProps: {
        maxDate: Constants.MAX_DATE,
        parseDate: parseDateStrict,
        formatDate: formatFriendlyDate,
      },
      editComponentProps: {
        maxDate: Constants.MAX_DATE,
        parseDate: parseDateStrict,
        formatDate: formatFriendlyDate,
      },
    },
    // {
    //   name: "Second reviewer",
    //   field: `secondReviewerId${Constants.DEPARTMENT_KEY}`,
    //   belongsTo: Constants.DEPARTMENT_KEY,
    //   TableEditComponent: EditableLinkpathReviewerCell,
    // },
  ],
};

export const DATE_INPUT_READ_CELL_DEFAULT_PROPS = {
  CreateComponent: DateInput,
  createComponentProps: DATE_INPUT_DEFAULT_PROPS,
};

export const DEPARTMENT_SPECIFIC_COMPANY_FIELDS: IDepartmentSpecificFieldsConfig = {
  Accounting: [
    {
      name: "Acct Job Freq",
      field: "jobFrequency",
      CreateComponent: HTMLSelect,
      belongsTo: Constants.DEPARTMENT_KEY,
      createComponentProps: {
        options: ["Monthly", "Quarterly", "Half Yearly", "Yearly"],
      },
    },
    {
      name: "Auditor Name",
      field: `auditorName${Constants.DEPARTMENT_KEY}`,
      belongsTo: Constants.DEPARTMENT_KEY,
      CreateComponent: InputGroup,
    },
    {
      name: "Client Tax Deadline",
      field: `clientTaxDeadline${Constants.DEPARTMENT_KEY}`,
      belongsTo: Constants.DEPARTMENT_KEY,
      ...DATE_INPUT_READ_CELL_DEFAULT_PROPS,
    },
    {
      name: "Latest FS Date",
      field: `latestFsDate${Constants.DEPARTMENT_KEY}`,
      belongsTo: Constants.DEPARTMENT_KEY,
      ...DATE_INPUT_READ_CELL_DEFAULT_PROPS,
    },
    {
      name: "Tax Code",
      field: `taxCode${Constants.DEPARTMENT_KEY}`,
      belongsTo: Constants.DEPARTMENT_KEY,
      ...TEXT_INPUT_EDIT_CELL_DEFAULT_PROPS,
    },
    {
      name: "Trust Name",
      field: `trustName${Constants.DEPARTMENT_KEY}`,
      belongsTo: Constants.DEPARTMENT_KEY,
      ...TEXT_INPUT_EDIT_CELL_DEFAULT_PROPS,
    },
    {
      name: "Profits Tax Date",
      field: `profitsTaxDate${Constants.DEPARTMENT_KEY}`,
      belongsTo: Constants.DEPARTMENT_KEY,
      ...DATE_INPUT_EDIT_CELL_DEFAULT_PROPS,
    },
    {
      name: "Profits Tax Received Date",
      field: `profitsTaxReceivedDate${Constants.DEPARTMENT_KEY}`,
      belongsTo: Constants.DEPARTMENT_KEY,
      ...DATE_INPUT_EDIT_CELL_DEFAULT_PROPS,
    },
    {
      name: "Interoffice",
      field: `interoffice${Constants.DEPARTMENT_KEY}`,
      belongsTo: Constants.DEPARTMENT_KEY,
      ...TEXT_INPUT_EDIT_CELL_DEFAULT_PROPS,
    },
    {
      name: "Business Nature",
      field: `businessNature${Constants.DEPARTMENT_KEY}`,
      belongsTo: Constants.DEPARTMENT_KEY,
      ...TEXT_INPUT_EDIT_CELL_DEFAULT_PROPS,
    },
  ],
  JckAudit: jckAuditCompanyFields,
};
