import { IClientCompany, ISubtask, ITask } from "../../../../model";

interface ITimesheetCreateState {
  currentDate?: string;
  disabled: boolean;
  clientCompany?: IClientCompany;
  task: ITask;
  tasksOptions?: ITask[];
  subtask: ISubtask;
  subtasksOptions?: ISubtask[];
  durations: { [date: string]: string };
  description?: string;
  isChargeable: boolean;
}

type IAction =
  | { type: "CHANGE_CURRENT_DATE"; payload: string }
  | { type: "CHANGE_DISABLED"; payload: boolean }
  | { type: "CHANGE_CLIENT_COMPANY"; payload: IClientCompany }
  | { type: "CHANGE_TASK"; payload: ITask }
  | { type: "CHANGE_TASK_OPTIONS"; payload: ITask[] }
  | { type: "CHANGE_SUBTASK"; payload: ISubtask }
  | { type: "CHANGE_SUBTASK_OPTIONS"; payload: ISubtask[] }
  | { type: "CHANGE_DURATION"; payload: { date: Date; duration: string } }
  | { type: "CHANGE_DESCRIPTION"; payload: string }
  | { type: "RESET_DURATION" }
  | { type: "CHANGE_ISCHARGEABLE"; payload: boolean }
  | { type: "RESET_ALL" };

export function timesheetCreateReducer(
  state: ITimesheetCreateState,
  action: IAction
): ITimesheetCreateState {
  switch (action.type) {
    case "CHANGE_CURRENT_DATE":
      return { ...state, currentDate: action.payload };
    case "CHANGE_DISABLED":
      return { ...state, disabled: action.payload };
    case "CHANGE_CLIENT_COMPANY":
      return {
        ...state,
        clientCompany: action.payload,
        task: null,
        subtask: null,
        subtasksOptions: null,
      };
    case "CHANGE_TASK":
      return {
        ...state,
        task: action.payload,
        subtask: null,
        subtasksOptions: null,
      };
    case "CHANGE_TASK_OPTIONS":
      return { ...state, tasksOptions: action.payload };
    case "CHANGE_SUBTASK":
      return {
        ...state,
        subtask: action.payload,
        isChargeable: action.payload.isChargeable,
      };
    case "CHANGE_SUBTASK_OPTIONS":
      return { ...state, subtasksOptions: action.payload };
    case "CHANGE_DURATION":
      return {
        ...state,
        durations: {
          ...state.durations,
          [action.payload.date.toISOString()]: action.payload.duration,
        },
      };
    case "CHANGE_DESCRIPTION":
      return { ...state, description: action.payload };
    case "CHANGE_ISCHARGEABLE":
      return { ...state, isChargeable: action.payload };
    case "RESET_ALL":
      return {
        ...state,
        clientCompany: null,
        tasksOptions: null,
        task: null,
        subtasksOptions: null,
        subtask: null,
        disabled: true,
        durations: null,
        description: "",
      };
    case "RESET_DURATION":
      return { ...state, durations: {} };
  }
}

export const initialState = (date) => {
  return {
    currentDate: date,
    disabled: true,
    clientCompany: null,
    task: null,
    tasksOptions: null,
    subtask: null,
    subtasksOptions: null,
    durations: {},
    description: "",
    isChargeable: false,
  };
};
