import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import classnames from "classnames";
import { cond, flow } from "lodash/fp";
import { useReference } from "ra-core";
import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IBalanceEntry, IEtsState } from "../../model";
import { statusString } from "../../utils/approvalStatus";
import {
  mapDescription,
  mapEntryType,
  sourceEntryLink,
} from "../../utils/balanceEntry";
import { toEntryResource } from "../../utils/dataProvider";
import * as DateUtils from "../../utils/date";
import * as MoneyUtils from "../../utils/money";
import { UserField } from "../fields";

const useStyles = makeStyles({
  rightAlignedCell: { textAlign: "right" },
});

export interface IBalanceEntryRowProps {
  entry: IBalanceEntry;
  total: number;
}

export const BalanceEntryRow: React.FC<IBalanceEntryRowProps> = ({
  entry,
  total,
}) => {
  const classes = useStyles();

  const sourceEntryId = flow(
    cond<IBalanceEntry, string>([
      [(e) => e.entryType === "WipEntry", (e) => e.entry.timesheetId],
      [() => true, (e) => e.entry.id],
    ])
  )(entry);

  const { loaded, referenceRecord: sourceEntry } = useReference({
    reference: toEntryResource(mapEntryType(entry.entryType)),
    id: sourceEntryId,
  });

  const isDaily = useSelector(
    (state: IEtsState) => state.departmentSetting.timebillDaily
  );

  if (!loaded) {
    return null;
  }

  const renderDescription = () => {
    const content = mapDescription(entry, isDaily);
    return <span>{content}</span>;
  };

  return (
    <TableRow key={entry.id}>
      <TableCell>
        {DateUtils.formatDate(DateUtils.parseDate(entry.date))}
      </TableCell>
      <TableCell>
        <Link to={sourceEntryLink(entry)}>{mapEntryType(entry.entryType)}</Link>
      </TableCell>
      <TableCell>{renderDescription()}</TableCell>
      <TableCell>
        <UserField userId={entry.entry.createdById} />
      </TableCell>
      <TableCell>{statusString(entry.approvalStatus)}</TableCell>
      <TableCell className={classnames(classes.rightAlignedCell)}>
        {MoneyUtils.priceFormatter.format(parseFloat(entry.entryAmount))}
      </TableCell>
      <TableCell className={classnames(classes.rightAlignedCell)}>
        {MoneyUtils.priceFormatter.format(total)}
      </TableCell>
    </TableRow>
  );
};
