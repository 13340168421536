import moment from "moment";
import { useListController, useMutation, useNotify } from "ra-core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getList } from "../../actions";
import { Constants } from "../../constants";
import { IEtsState, ITimeEntry } from "../../model";
import { history } from "../../store";
import { TaskGrid } from "./TimesheetCreateComponent/TaskGrid";

const pagination = { page: 1, perPage: 100000 };
const sort = { field: "", order: "" };

const useUpdateEntry = (filter: any) => {
  const dispatch = useDispatch();
  const [mutate] = useMutation();

  return (newEntry: ITimeEntry, previousEntry: ITimeEntry) => {
    mutate(
      {
        type: "update",
        resource: Constants.TIME_ENTRY_RESOURCE,
        payload: {
          id: newEntry.id,
          data: newEntry,
          previousData: previousEntry,
        },
      },
      {
        onSuccess: () => {
          dispatch(
            getList(Constants.TIME_ENTRY_RESOURCE, pagination, sort, filter)
          );
        },
      }
    );
  };
};

export const TimesheetCreate: React.FC<any> = (props) => {
  const userId = useSelector((state: IEtsState) => state.auth.user?.id);
  const [nowDate, setNowDate] = React.useState(new Date());
  const timesheetCreateTimeEntry = useSelector(
    (state: IEtsState) => state.departmentSetting.timesheetCreateTimeEntry
  );
  const notify = useNotify();
  const queryString = history.location.search;
  const query = new URLSearchParams(queryString);
  const filter = {
    startDatetime: query.get("startDatetime"),
    endDatetime: query.get("endDatetime"),
    createdById: userId,
    hasTimesheet: "0",
  };
  const handleUpdateEntry = useUpdateEntry(filter);
  const onChangeDate = (date) => {
    setNowDate(date);
    const endCodeStartDate = encodeURIComponent(
      moment(date).startOf("week").add(1, "d").format("YYYY-MM-DD HH:mm:ss Z")
    );
    const endCodeEndDate = encodeURIComponent(
      moment(date).endOf("week").add(1, "d").format("YYYY-MM-DD HH:mm:ss Z")
    );
    history.push({
      search: `startDatetime=${endCodeStartDate}&endDatetime=${endCodeEndDate}`,
    });
  };
  const controllerProps = useListController({
    basePath: props.basePath,
    resource: Constants.TIME_ENTRY_RESOURCE,
    hasCreate: false,
    perPage: 100000,
    filter,
    sort,
    query: {
      sort: "",
      order: "",
      page: 1,
      perPage: 10000,
      filter,
      displayedFilters: false,
    },
  });

  const { data, ids } = controllerProps;
  if (!data || !query.get("startDatetime")) {
    return null;
  }

  return (
    <TaskGrid
      {...props}
      ids={ids as string[]}
      entries={ids.map((id) => data[id]) as ITimeEntry[]}
      startDatetime={decodeURIComponent(query.get("startDatetime"))}
      onChangeDate={onChangeDate}
      onUpdateEntry={handleUpdateEntry}
      timeEntriesCreate={timesheetCreateTimeEntry}
    />
  );
};
