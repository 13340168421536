import { makeStyles } from "@material-ui/core";
import {
  AutocompleteInput,
  DateInput,
  Filter,
  ReferenceInput,
} from "ra-ui-materialui";
import * as React from "react";
import { useSelector } from "react-redux";
import { reportAccess } from "../../../config/features";
import { Constants } from "../../../constants";
import { IEtsState } from "../../../model";

const filterDepartment = (text) => ({ withName: text });

const useStyle = makeStyles({
  search: { marginLeft: 10, width: 300 },
});

export const HourReportFilter = (props) => {
  const roles = useSelector((state: IEtsState) => state.auth.roles);
  const classes = useStyle({});
  const { reportType } = props;

  return (
    <Filter {...props}>
      <DateInput
        label="Start date is after"
        source="startDate"
        alwaysOn={true}
      />
      <DateInput
        label="Start date is before"
        source="endDate"
        alwaysOn={true}
      />
      {reportAccess(roles, reportType) && (
        <ReferenceInput
          label="Department"
          source="departmentId"
          reference={Constants.DEPARTMENTS_RESOURCE}
          sort={{ field: "name", order: "ASC" }}
          filterToQuery={filterDepartment}
          alwaysOn={true}
          allowEmpty={true}
          emptyText="Clear Input"
          className={classes.search}
        >
          <AutocompleteInput source="name" />
        </ReferenceInput>
      )}
    </Filter>
  );
};
