import { FormLabel, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { FormDataConsumer } from "react-admin";

const useStyle = makeStyles({
  grid: { marginTop: 16 },
});
export const AmountInput: React.FC<any> = (props) => {
  const { accountBalance, amount, type } = props;
  const classes = useStyle({});
  const record = { amount: 0 };
  if (type === "wip") {
    record.amount = accountBalance.accountBalance - (amount ? amount : 0);
  }
  if (type === "expense") {
    record.amount = accountBalance.expenseBalance - (amount ? amount : 0);
  }
  const renderRemain = () => (
    <>
      <FormLabel component="legend" className={classes.grid}>
        Remaining
      </FormLabel>
      <TextField value={record.amount.toFixed(2)} />
    </>
  );

  return <FormDataConsumer>{renderRemain}</FormDataConsumer>;
};
