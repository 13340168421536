import React from "react";
import { IEtsUser } from "../../model";
import { getStyleFromUser, initials } from "../../utils/user";

interface ICreatedByProps {
  record?: IEtsUser;
}

export const CreatedBy: React.FC<ICreatedByProps> = ({ record }) => {
  if (!record) {
    return null;
  }

  const createdByInitials = initials(record);
  const createdByStyle = getStyleFromUser(record);

  return (
    <div className="timeline-changelog-avatar" style={createdByStyle}>
      {createdByInitials}
    </div>
  );
};
