import initialMessage from "@app/i18n/initialMessage";
import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const useStyle = makeStyles({
  title: {
    fontWeight: "bold",
    fontSize: 15,
    color: "#000000DE",
  },
  content: {
    paddingTop: 8,
    fontSize: 14,
  },
});

export const InvoiceStatusField: React.FC<any> = (props) => {
  const { record } = props;
  const isShow = props.basePath.includes("show");
  const classes = useStyle({});
  const intl = useIntl();

  return (
    <>
      {isShow ? (
        <div className="MuiFormControl-root MuiFormControl-marginDense">
          <label className={classes.title}>
            <FormattedMessage
              defaultMessage="Status"
              description="invoice field status"
            />
          </label>
          <Typography
            component="span"
            variant="body2"
            className={classes.content}
          >
            {intl.formatMessage(
              initialMessage[`invoices.status.${record.status}`]
            )}
          </Typography>
        </div>
      ) : (
        <span>
          {intl.formatMessage(
            initialMessage[`invoices.status.${record.status}`]
          )}
        </span>
      )}
    </>
  );
};
