import React from "react";
import { List } from "react-admin";

export const CustomReport: React.FC<any> = (props) => {
  return (
    <List pagination={false} exporter={false} {...props}>
      <MetabasesIframe />
    </List>
  );
};

const MetabasesIframe: React.FC = (props: any) => {
  const { data, ids } = props;
  const customReport = data[ids[0]];

  if (!customReport) {
    return null;
  }
  return (
    <iframe
      src={customReport.url}
      height={customReport.iframeHeight}
      width={customReport.iframeWidth}
      allowTransparency={true}
    />
  );
};
