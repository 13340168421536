import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IEtsState, IEtsUser } from "../../model";
import { getFullName } from "../../utils/user";
import { switchUser } from "./switch";

interface IUserDetailProps {
  user?: IEtsUser;
}

export const UserDetail: React.FC<IUserDetailProps> = ({ user }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state: IEtsState) => state.auth);

  const handleOnClick = () => {
    return dispatch(switchUser(user.id, auth.token));
  };

  return (
    <li>
      <a className="bp3-menu-item">
        <div className="switch-detail" onClick={handleOnClick}>
          <div
            className="notify-initials"
            style={{ backgroundColor: user.colorHex }}
          >
            {user.abbreviation}
          </div>
          <span>{getFullName(user)}</span>
        </div>
      </a>
    </li>
  );
};
