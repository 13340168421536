import { makeStyles } from "@material-ui/styles";
import { useGetList } from "ra-core";
import {
  Datagrid,
  DateField,
  EditButton,
  List,
  ReferenceField,
  TextField,
} from "ra-ui-materialui";
import React from "react";
import { Constants } from "../../constants";
import { PriceField, UserField } from "../fields";
import { FeeNoteNumberField } from "../Invoice/FeeNoteNumberField";
import { InvoiceFilter } from "../Invoice/InvoiceFilter";
import { ApprovalStatusList } from "../WriteOff/CustomField/ApprovalStatusList";

const useStyles = makeStyles({
  container: {
    margin: "50px 10px",
  },
  createdBy: { minWidth: 150 },
  amount: { minWidth: 100, textAlign: "right" },
});

export const CreditNoteList: React.FC<any> = (props) => {
  useGetList(
    "invoices",
    { page: 1, perPage: 10 },
    { field: "", order: "" },
    {}
  );
  const classes = useStyles({});
  const toAccountBalance = (record, reference) => {
    return `${Constants.ACCOUNT_BALANCE_PATH}/${record.taskId}`;
  };
  const toInvoice = (record, reference) => {
    return `${Constants.INVOICE_PATH}/${record.invoiceId}`;
  };

  return (
    <List
      {...props}
      exporter={false}
      style={{ margin: 10 }}
      bulkActionButtons={false}
      sort={{ field: "date", order: "DESC" }}
      hasCreate={false}
      className={classes.container}
      filters={
        <InvoiceFilter includeDefaultFilter="invoiceId" feeNoteSearch={true} />
      }
    >
      <Datagrid>
        <DateField source="date" label="Date" />
        <ReferenceField
          label="Company"
          source="clientCompanyId"
          reference={Constants.CLIENT_COMPANY_RESOURCE}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Job"
          source="taskId"
          reference={Constants.TASK_RESOURCE}
          link={toAccountBalance}
        >
          <TextField source="description" />
        </ReferenceField>
        <UserField label="Created By" source="createdById" />
        <ReferenceField
          label="Fee Note #"
          source="invoiceId"
          reference={Constants.INVOICE_RESOURCE}
          link={toInvoice}
        >
          <FeeNoteNumberField
            source="feeNoteNumber"
            cellClassName={classes.amount}
          />
        </ReferenceField>
        <PriceField source="wipToCredit" label="WIP" />
        <PriceField source="expenseToCredit" label="Expense" />
        <ApprovalStatusList source="Status" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
