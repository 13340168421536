import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { reportAccess } from "../../../config/features";
import { Constants } from "../../../constants";
import { IEtsState } from "../../../model";

export const ReportList: React.FC = () => {
  const roles = useSelector((state: IEtsState) => state.auth.roles);
  return (
    <>
      <ul>
        {reportAccess(roles, "tasks_account_balance") && (
          <li style={{ paddingLeft: 10, maxWidth: 159 }}>
            <NavLink to={Constants.TASKS_ACCOUNT_BALANCE_PATH}>
              <span>By Job</span>
            </NavLink>
          </li>
        )}
        {reportAccess(roles, "departments_account_balance") && (
          <li style={{ paddingLeft: 10, maxWidth: 159 }}>
            <NavLink to={Constants.DEPARTMENTS_ACCOUNT_BALANCE_PATH}>
              <span>By Department</span>
            </NavLink>
          </li>
        )}
        {reportAccess(roles, "pic_account_balance") && (
          <li style={{ paddingLeft: 10, maxWidth: 159 }}>
            <NavLink to={Constants.PIC_ACCOUNT_BALANCE_PATH}>
              <span>By PIC</span>
            </NavLink>
          </li>
        )}
        {reportAccess(roles, "amount_to_bill") && (
          <li style={{ paddingLeft: 10, maxWidth: 159 }}>
            <NavLink to={Constants.AMOUNT_TO_BILL_PATH}>
              <span>Billings</span>
            </NavLink>
          </li>
        )}
        {reportAccess(roles, "fee_notes_list") && (
          <li style={{ paddingLeft: 10, maxWidth: 159 }}>
            <NavLink to={Constants.FEE_NOTES_LIST_PATH}>
              <span>Fee Note List</span>
            </NavLink>
          </li>
        )}
        {reportAccess(roles, "credit_notes_lists") && (
          <li style={{ paddingLeft: 10, maxWidth: 159 }}>
            <NavLink to={Constants.CREDIT_NOTES_LIST_PATH}>
              <span>Credit Note List</span>
            </NavLink>
          </li>
        )}
        {reportAccess(roles, "hours_by_job") && (
          <li style={{ paddingLeft: 10, maxWidth: 159 }}>
            <NavLink to={Constants.HOUR_BY_JOB_PATH}>
              <span>Hours by Job</span>
            </NavLink>
          </li>
        )}
        {reportAccess(roles, "hours_by_user") && (
          <li style={{ paddingLeft: 10, maxWidth: 159 }}>
            <NavLink to={Constants.HOUR_BY_USER_PATH}>
              <span>Hours by Staff</span>
            </NavLink>
          </li>
        )}
        {reportAccess(roles, "fee_notes_line_items") && (
          <li style={{ paddingLeft: 10, maxWidth: 159 }}>
            <NavLink to={Constants.FEE_NOTE_LINE_ITEMS_PATH}>
              <span>Fee Note Line Items</span>
            </NavLink>
          </li>
        )}
      </ul>
    </>
  );
};
