import { Popover, Position } from "@blueprintjs/core";
import { IconButton } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { IFormGroupResponse } from "../../model";

interface IStatusFieldProps {
  record?: IFormGroupResponse;
  label?: string;
}

export const FormGroupResponseLink: React.FC<IStatusFieldProps> = ({
  record,
}) => {
  return (
    <>
      {record?.url && (
        <Popover position={Position.BOTTOM}>
          <CopyToClipboard text={record.url}>
            <IconButton onClick={stopPropagation}>
              <LinkIcon />
            </IconButton>
          </CopyToClipboard>
          <div className="tooltip-message">Form Link Copied</div>
        </Popover>
      )}
    </>
  );
};

const stopPropagation = (e) => e.stopPropagation();
