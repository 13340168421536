import { IconButton, TableCell, TableRow } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { IClientUser } from "../../model/IClientUserAuth";
import * as DateUtils from "../../utils/date";
import { ClientUserLoginLinkDialog } from "../dialog/ClientUserLoginLinkDialog";
import { ClientUserNameEdit } from "./ClientUserNameEdit";
import { ClientUserStatusSelect } from "./ClientUserStatusSelect";

interface IClientUserListItemProps {
  clientUser: IClientUser;
}

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  card: {
    borderBottom: "10px",
  },
  selectList: {
    "& > *": {
      paddingLeft: "10px",
    },
    "margin": "10px",
  },
});

export const ClientUserListItem: React.FC<IClientUserListItemProps> = ({
  clientUser,
}) => {
  const { id, email, createdAt, status } = clientUser;
  const classes = useRowStyles();
  const [isViewingLoginLink, setIsViewingLoginLink] = useState(false);

  const startViewingLoginLink = () => {
    setIsViewingLoginLink(true);
  };

  const finishViewingLoginLink = () => {
    setIsViewingLoginLink(false);
  };

  return (
    <>
      <TableRow key={id} className={classes.root}>
        <TableCell component="th" scope="row">
          <ClientUserNameEdit clientUser={clientUser} />
        </TableCell>
        <TableCell align="left">{clientUser.email}</TableCell>
        <TableCell align="left">
          <ClientUserStatusSelect clientUser={clientUser} />
        </TableCell>
        <TableCell align="left">
          {createdAt
            ? DateUtils.formatDate(DateUtils.parseDate(createdAt))
            : "-"}
        </TableCell>
        <TableCell align="right">
          <IconButton disabled={status === "active" ? false : true}>
            <LinkIcon onClick={startViewingLoginLink} />
          </IconButton>
        </TableCell>
      </TableRow>
      <ClientUserLoginLinkDialog
        isOpen={isViewingLoginLink}
        canOutsideClickClose={true}
        onClose={finishViewingLoginLink}
        clientUser={clientUser}
      />
    </>
  );
};
