import { Constants } from "../constants";

export function stripSuffixFromDataKeys(data, fieldKey: string) {
  const returnObj = {};
  data =
    data[fieldKey] === undefined &&
    (data[Constants.TENANT_KEY] || data[Constants.DEPARTMENT_KEY])
      ? { clientCompanyId: data[Constants.CLIENT_COMPANY_ID_KEY] }
      : data[fieldKey] || data;
  Object.keys(data).map((key) => {
    const newKey = key
      .replace(Constants.TENANT_KEY, "")
      .replace(Constants.DEPARTMENT_KEY, "");
    returnObj[newKey] = data[key];
  });
  return returnObj;
}

export function stripKeySuffix(key: string) {
  return key
    .replace(Constants.TENANT_KEY, "")
    .replace(Constants.DEPARTMENT_KEY, "");
}
