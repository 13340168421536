import { DateRangeInput } from "@blueprintjs/datetime";
import { Box } from "@material-ui/core";
import * as React from "react";
import * as Utils from "../../utils/date";

export interface IBalanceEntryFilterProps {
  onChange: (range: [Date, Date]) => void;
  range: [Date, Date];
}

export const BalanceEntryFilter: React.FC<IBalanceEntryFilterProps> = ({
  onChange,
  range,
}) => {
  return (
    <Box>
      <DateRangeInput
        formatDate={Utils.formatDate}
        onChange={onChange}
        parseDate={Utils.parseDateStrict}
        value={range}
        allowSingleDayRange={true}
      />
    </Box>
  );
};
