import { Button, Intent, NonIdealState } from "@blueprintjs/core";
import * as Sentry from "@sentry/browser";
import * as React from "react";

interface IErrorBoundaryState {
  error: any;
}

export class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {
  constructor(props) {
    super(props);

    this.state = { error: null };

    this.renderReportButton = this.renderReportButton.bind(this);
  }

  public componentDidCatch(error, errorInfo) {
    this.setState({ ...this.state, error });
    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
    });
    Sentry.captureException(error);
  }

  public render(): React.ReactNode {
    if (this.state.error) {
      return (
        <NonIdealState
          icon="error"
          title="An unexpected error occurred."
          description="Please tell us about what happened so we can better resolve this issue."
          action={this.renderReportButton()}
        />
      );
    }

    return this.props.children;
  }

  private renderReportButton() {
    return (
      <Button
        icon="error"
        intent={Intent.DANGER}
        onClick={Sentry.showReportDialog}
        text="Report Problem"
      />
    );
  }
}
