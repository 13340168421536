import { FormControl, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useDataProvider, useNotify } from "ra-core";
import React from "react";
import { Constants } from "../../constants";
import { IClientUser } from "../../model/IClientUserAuth";

interface IClientUserStatusSelectProps {
  clientUser: IClientUser;
}

const useStyles = makeStyles({
  root: {
    fontSize: "inherit",
  },
});

export const ClientUserStatusSelect = ({ clientUser }) => {
  const { id, status } = clientUser;
  const [currStatus, setCurrStatus] = React.useState<string>(status);
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const classes = useStyles({});

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (event.target.value !== status) {
      dataProvider.update(
        Constants.CLIENT_USER_RESOURCE,
        {
          id,
          data: {
            ...clientUser,
            status: event.target.value,
          },
          previousData: clientUser,
        },
        {
          onSuccess: ({ data }) => {
            notify("Status successfully updated", "info");
            setCurrStatus(data.status);
          },
          onFailure: () => {
            notify("Status update failed", "warning");
          },
        }
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <FormControl className="client-user-status-form">
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={currStatus}
          onChange={handleChange}
        >
          <MenuItem classes={classes} value={"active"}>
            Active
          </MenuItem>
          <MenuItem classes={classes} value={"disabled"}>
            Disabled
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};
