import { makeStyles } from "@material-ui/core";
import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextInput,
} from "ra-ui-materialui";
import React from "react";
import { useSelector } from "react-redux";
import { Constants } from "../../constants";
import { IEtsState } from "../../model";
import { ApprovalStatusSelectArrayInput } from "../inputs";

const filterClientCompany = (text) => ({ withName: text });

const useStyle = makeStyles({
  search: { marginLeft: 10, width: 300 },
  filter: { paddingLeft: 10 },
});

const approvableResources = [
  Constants.TIMESHEET_RESOURCE,
  Constants.INVOICE_RESOURCE,
  Constants.CREDIT_NOTE_RESOURCE,
  Constants.WRITE_OFF_RESOURCE,
];

export const InvoiceFilter = (props) => {
  const { includeDefaultFilter } = props;

  const classes = useStyle(props);
  const hasParams = location.search.includes(includeDefaultFilter);
  const displayId = `${props.filterValues?.[includeDefaultFilter]?.slice(
    0,
    7
  )}...`;
  const currentUserId = useSelector((state: IEtsState) => state.auth.user.id);

  return (
    <Filter {...props} className={classes.filter}>
      {props.feeNoteSearch && (
        <SearchInput
          source="feeNoteNumber"
          placeholder="Fee Note Number"
          alwaysOn={true}
        />
      )}
      <ReferenceInput
        label="Company Name"
        source="clientCompanyId"
        reference={Constants.CLIENT_COMPANY_RESOURCE}
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={filterClientCompany}
        alwaysOn={!props.feeNoteSearch}
        allowEmpty={true}
        emptyText="Clear Input"
        className={classes.search}
      >
        <AutocompleteInput source="name" />
      </ReferenceInput>
      <SelectInput
        label="Fee Note Group"
        source="userType"
        emptyText="See all"
        choices={[
          { id: `created_by:${currentUserId}`, name: "Created by me" },
          { id: `assignee:${currentUserId}`, name: "My jobs" },
          { id: `managed:${currentUserId}`, name: "Reviewed by me" },
          { id: `none:${currentUserId}`, name: "Managed by me" },
        ]}
        alwaysOn={true}
      />
      {approvableResources.includes(props.resource) && (
        <ApprovalStatusSelectArrayInput
          label="Approval statuses"
          source="approvalStatus"
        />
      )}
      {hasParams && (
        <TextInput
          source={includeDefaultFilter}
          disabled={true}
          value={displayId}
        />
      )}
    </Filter>
  );
};
