import { FormLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { ReferenceField, TextField } from "react-admin";
import { Constants } from "../../constants";
import { ITask } from "../../model";

export interface ICompanyFieldProps {
  record?: ITask;
}

const useStyles = makeStyles(() => ({
  address: {
    whiteSpace: "pre-wrap",
  },
  label: {
    marginTop: 16,
  },
}));

export const ClientCompanyField: React.FC<ICompanyFieldProps> = ({
  record,
}) => {
  const classes = useStyles({});

  if (!record) {
    return null;
  }

  return (
    <>
      <FormLabel component="legend" className={classes.label}>
        Client company
      </FormLabel>
      <ReferenceField
        resource={Constants.TASK_RESOURCE}
        reference={Constants.CLIENT_COMPANY_RESOURCE}
        source="clientCompanyId"
        basePath="/tasks"
        record={record}
        link={false}
        label="Client company"
      >
        <TextField source="name" />
      </ReferenceField>
    </>
  );
};
