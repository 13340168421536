import { Popover, Position } from "@blueprintjs/core";
import {
  Button,
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { GetListResult, useDataProvider, useRefresh } from "ra-core";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Constants } from "../../constants";
import { IClientGroup } from "../../model";
import { IClientUser } from "../../model/IClientUserAuth";
import { IFormGroup } from "../../model/IFormGroup";

interface IClientGroupFromProps {
  clientGroup?: IClientGroup;
  clientUsers: IClientUser[];
  formGroup: IFormGroup[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    formControl: {
      marginRight: 16,
    },
    formSelect: {
      minWidth: 160,
      height: 48,
    },
    clientUserSelect: {
      minWidth: 218.4,
      height: 48,
    },
    generateButton: {
      height: 36,
      display: "inline-block",
      alignSelf: "flex-end",
      marginBottom: 2,
    },
    copyButton: {
      display: "inline-block",
      alignSelf: "flex-end",
      marginBottom: 8,
      height: 30,
    },
  })
);

export const ClientGroupForm: React.FC<IClientGroupFromProps> = ({
  clientGroup,
  clientUsers,
  formGroup,
}) => {
  const [currentFormGroup, setCurrentFormGroup] = useState();
  const [currentClientUser, setCurrentClientUser] = useState();
  const [url, setUrl] = useState<string>();
  const dataProvider = useDataProvider();
  const classes = useStyles({});
  const refresh = useRefresh();
  const handleOnFormGroupSelect = (event: any) => {
    setCurrentFormGroup(event.target.value);
  };

  const handleOnClientUserSelect = (event: any) => {
    setCurrentClientUser(event.target.value);
  };

  useEffect(() => {
    setUrl(null);
  }, [currentClientUser, currentFormGroup]);

  const handleOnclickButton = async () => {
    const data: GetListResult = await dataProvider.getList(
      Constants.FORM_LINK_GENERATOR_RESOURCE,
      {
        pagination: { page: 0, perPage: 0 },
        sort: { field: "", order: "" },
        filter: {
          form_group_id: currentFormGroup,
          client_user_email: currentClientUser,
        },
      }
    );
    setUrl(data.data[0].url);
    refresh();
  };

  return (
    <div className={classes.root}>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel id="form-label">Forms</InputLabel>
        <Select
          labelId="form-label"
          value={currentFormGroup}
          onChange={handleOnFormGroupSelect}
          className={classes.formSelect}
        >
          {formGroup.map((form: IFormGroup) => (
            <MenuItem key={form.id} value={form.id}>
              {form.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="filled" className={classes.formControl}>
        <InputLabel id="client-user-label">Client User</InputLabel>
        <Select
          labelId="client-user-label"
          value={currentClientUser}
          onChange={handleOnClientUserSelect}
          className={classes.clientUserSelect}
        >
          {clientUsers.map((cu: IClientUser) => (
            <MenuItem key={cu.id} value={cu.email}>
              {cu.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {url ? (
        <>
          <Popover position={Position.BOTTOM} className={classes.copyButton}>
            <CopyToClipboard text={url}>
              <Button variant="contained">Copy Form Link</Button>
            </CopyToClipboard>
            <div className="tooltip-message">Form Link Copied</div>
          </Popover>
        </>
      ) : (
        <Button
          variant="contained"
          onClick={handleOnclickButton}
          disabled={!currentFormGroup || !currentClientUser}
          className={classes.generateButton}
        >
          Generate Link
        </Button>
      )}
    </div>
  );
};
