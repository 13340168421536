import { useReference } from "ra-core";
import * as React from "react";
import { Constants } from "../../constants";
import { IEtsUser } from "../../model";
import { UserAvatar } from "../UserAvatar";

export const UserField: React.FC<any> = ({
  record,
  source,
  userId,
  sortable = true,
}) => {
  const { loaded, referenceRecord: user } = useReference({
    reference: Constants.USER_RESOURCE,
    id: userId ? userId : record[source],
  });

  if (!loaded) {
    return null;
  }

  return <UserAvatar user={user as IEtsUser} />;
};
