import { AnchorButton, Icon } from "@blueprintjs/core";
import classnames from "classnames";
import * as React from "react";
import { INotificationProps, NotificationMode } from "../../model";
import * as UserUtils from "../../utils/user";
import { ApprovalNotificationComponent } from "./ApprovalNotification";
import { AttachmentNotificationComponent } from "./AttachmentNotification";
import { CommentNotificationComponent } from "./CommentNotification";
import { FormGroupResponseNotificationComponent } from "./FormGroupResponseNotification";
import { TaskNotificationComponent } from "./TaskNotification";

const NOTIFICATIONS = {
  attachment: AttachmentNotificationComponent,
  creditnote: ApprovalNotificationComponent,
  comment: CommentNotificationComponent,
  invoice: ApprovalNotificationComponent,
  payment: ApprovalNotificationComponent,
  subtask: TaskNotificationComponent,
  task: TaskNotificationComponent,
  timesheet: ApprovalNotificationComponent,
  writeoff: ApprovalNotificationComponent,
  formgroupresponse: FormGroupResponseNotificationComponent,
};

export const Notification: React.FC<INotificationProps> = ({
  mode = NotificationMode.Full,
  displayArchiveButton = false,
  notification,
  notificationOnClick,
  archiveNotificationOnClick,
}) => {
  const readColor = notification.openedAt ? "read" : "unread";
  const notificationColor =
    notification.parameters?.userColor ??
    UserUtils.getStyleFromUser().backgroundColor;
  const userInitials =
    notification.parameters?.userInitials ?? UserUtils.getAbbreviation();
  const handleNotificationOnClick = () => {
    notificationOnClick(notification);
  };
  const handleArchiveNotificationOnClick = () => {
    archiveNotificationOnClick([notification.id]);
  };

  const Handler = NOTIFICATIONS[notification.notifiableType.toLowerCase()];

  const renderArchiveNotificationButton = () => {
    return (
      <AnchorButton
        key={`${notification.id}-archive`}
        className="archive-notification-button"
        onClick={handleArchiveNotificationOnClick}
        minimal={true}
      >
        <Icon icon="cross" />
      </AnchorButton>
    );
  };

  const renderNotificationComponent = () => {
    return (
      <Handler
        notification={notification}
        mode={mode}
        onClick={notificationOnClick}
        key={notification.id}
      />
    );
  };

  return (
    <div>
      <AnchorButton
        key={notification.id}
        className={displayArchiveButton ? "archive-notification-link" : "link"}
        onClick={handleNotificationOnClick}
        minimal={true}
      >
        <div className={classnames("notify-container-two", readColor)}>
          <div
            className="notify-initials"
            style={{ backgroundColor: notificationColor }}
          >
            {userInitials}
          </div>
          {renderNotificationComponent()}
        </div>
      </AnchorButton>
      {displayArchiveButton && renderArchiveNotificationButton()}
    </div>
  );
};
