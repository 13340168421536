import { Button, Card, Intent } from "@blueprintjs/core";
import { Box, makeStyles, Tab, Tabs } from "@material-ui/core";
import { push } from "connected-react-router";
import { useEditController, useNotify } from "ra-core";
import { ReferenceField, ReferenceManyField } from "ra-ui-materialui";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, RouteComponentProps } from "react-router-dom";
import {
  AttachmentManageDialog,
  ConnectedTaskTable,
  RAHistoryTimeline,
} from "../../components";
import { Constants } from "../../constants";
import { IClientCompany, IClientGroup, IEtsState } from "../../model";
import { copyEmailAddress } from "../../utils/email";
import { ClientCompanyAssignedToSection } from "./ClientCompanyAssignedToSection";
import { ClientCompanyContactSection } from "./ClientCompanyContactSection";
import { ClientCompanyDatesSection } from "./ClientCompanyDatesSection";
import { ClientCompanyGeneralSection } from "./ClientCompanyGeneralSection";
import { ClientCompanyImportantPeople } from "./ClientCompanyImportantPeople";
import { CopyEmail } from "./CopyEmail";

const useStyles = makeStyles(() => ({
  indicator: {
    "outline": "none",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      outline: "none",
    },
  },
  sectionHeader: {
    "margin-bottom": "15px",
  },
}));

const TabPanel = (props) => {
  const classes = useStyles({});
  const { children, tabSelect, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={tabSelect !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {tabSelect === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    "id": `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const RATaskTable = ({ data = {}, ids = [], page = 0, perPage = 0 }) => {
  return (
    <ConnectedTaskTable
      hideCompanyColumns={true}
      tasks={ids.map((id) => data[id])}
      defaultPageSize={5}
      showPageSizeOptions={false}
    />
  );
};

interface IGroupLinkProps {
  record?: IClientGroup;
  clientId: string;
}
const GroupLink = ({ record, clientId }: IGroupLinkProps) => (
  <span className="secondary-info metadata">
    <NavLink to={`${Constants.CLIENTGROUP_DETAIL_PATH}/${record.id}`}>
      {record.name}
    </NavLink>
    , {clientId}
  </span>
);

const tabNameToIndex = {
  general: 0,
  contact: 1,
  assigned_to: 2,
  important_dates: 3,
  important_people: 4,
};

const indexToTabName = {
  0: "general",
  1: "contact",
  2: "assigned_to",
  3: "important_dates",
  4: "important_people",
};

interface ICompanyDetailProps extends RouteComponentProps<{ tab?: string }> {
  id: string;
  basePath: string;
  resource: string;
}

export const CompanyDetail: React.FC<ICompanyDetailProps> = (props) => {
  const classes = useStyles({});
  const departmentId = useSelector(
    (state: IEtsState) => state.auth.departmentId
  );

  const [tab, setTab] = useState(
    tabNameToIndex[props.match.params.tab || "general"] || 0
  );
  const [isManagingAttachments, setIsManagingAttachments] = useState(false);
  const notify = useNotify();
  const dispatch = useDispatch();

  const {
    basePath,
    loaded,
    loading,
    record,
    resource,
    version,
    save,
    saving,
  } = useEditController({
    ...props,
    undoable: false,
  });
  const company = record as IClientCompany;

  if (!company) {
    return null;
  }

  const {
    name,
    chineseName,
    clientId,
    clientGroupId,
    contactName,
    jurisdiction,
    address,
    billingAddressLine1,
    billingAddressLine2,
    billingAddressLine3,
    contactEmail,
    contactNumber,
    referral,
    team,
    companyType,
    taxCode,
    status,
    registrationDate,
    lastAnnualReturnDate,
    nextAnnualReturnDueDate,
    lastAuditDate,
    nextAuditDueDate,
    id,
    emailAlias,
    versions,
  } = company;

  const startManagingAttachments = () => {
    setIsManagingAttachments(true);
  };

  const finishManagingAttachments = () => {
    setIsManagingAttachments(false);
  };

  const handleChange = (event, newValue) => {
    dispatch(
      push(
        `${Constants.COMPANY_DETAIL_PATH}/${props.id}/${indexToTabName[newValue]}`
      )
    );
    setTab(newValue);
  };

  const handleDateChange = (dates) => {
    save(dates, null, {
      onSuccess: ({ data }) => {
        notify("Date successfully updated");
      },
    });
  };

  return (
    <div className="company-detail">
      <div className="company-info">
        <div className="details">
          <ReferenceField
            resource={Constants.CLIENT_COMPANY_RESOURCE}
            reference={Constants.CLIENT_GROUP_RESOURCE}
            source="clientGroupId"
            basePath={Constants.COMPANY_DETAIL_PATH}
            record={company}
            link={false}
          >
            <GroupLink clientId={clientId} />
          </ReferenceField>
          <span className="name">{`${name} (${chineseName})`}</span>
          <span className="secondary-info organization">{jurisdiction}</span>
          <div>
            <CopyEmail
              emailAddress={copyEmailAddress("company-id", emailAlias)}
              type="Company"
            />
            <Button
              intent={Intent.PRIMARY}
              text="Data Safe"
              onClick={startManagingAttachments}
              className="client-portal-button"
            />
          </div>
        </div>
        <div className="tabs-wrapper">
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="simple tabs example"
            variant="scrollable"
            classes={{ indicator: classes.indicator }}
          >
            <Tab
              label="General"
              {...a11yProps(0)}
              classes={{ selected: classes.indicator }}
            />
            <Tab
              label="Contact"
              {...a11yProps(1)}
              classes={{ selected: classes.indicator }}
            />
            <Tab
              label="Assigned To"
              {...a11yProps(2)}
              classes={{ selected: classes.indicator }}
            />
            <Tab
              label="Important Dates"
              {...a11yProps(3)}
              classes={{ selected: classes.indicator }}
            />
            <Tab
              label="Important People"
              {...a11yProps(4)}
              classes={{ selected: classes.indicator }}
            />
          </Tabs>
          <TabPanel tabSelect={tab} index={0}>
            <ClientCompanyGeneralSection
              companyType={companyType}
              taxCode={taxCode}
              status={status}
              registrationDate={registrationDate}
              clientCompanyId={id}
            />
          </TabPanel>
          <TabPanel tabSelect={tab} index={1}>
            <ClientCompanyContactSection
              contactName={contactName}
              contactEmail={contactEmail}
              contactNumber={contactNumber}
              address={address}
              billingAddress={`${billingAddressLine1} ${billingAddressLine2} ${billingAddressLine3}`}
              referral={referral}
            />
          </TabPanel>
          <TabPanel tabSelect={tab} index={2}>
            <ClientCompanyAssignedToSection
              referral={referral}
              team={team}
              company={company}
            />
          </TabPanel>
          <TabPanel tabSelect={tab} index={3}>
            <ClientCompanyDatesSection
              clientCompany={company}
              onChange={handleDateChange}
            />
          </TabPanel>
          <TabPanel tabSelect={tab} index={4}>
            <ReferenceManyField
              reference={Constants.IMPORTANT_PERSON_RESOURCE}
              resource={Constants.CLIENT_COMPANY_RESOURCE}
              target={Constants.CLIENT_COMPANY_RESOURCE}
              record={company}
              filter={{ clientCompanyId: company.id }}
              basePath={basePath}
              perPage={100000}
            >
              <ClientCompanyImportantPeople company={company} />
            </ReferenceManyField>
          </TabPanel>
        </div>
      </div>
      <div className="task-table-wrapper">
        <Card>
          <div className={classes.sectionHeader}>
            <h6>Jobs</h6>
          </div>
          <ReferenceManyField
            reference={Constants.TASK_RESOURCE}
            resource={Constants.CLIENT_COMPANY_RESOURCE}
            target={Constants.CLIENT_COMPANY_RESOURCE}
            record={company}
            filter={{ departmentId }}
            basePath={basePath}
            perPage={100000}
          >
            <RATaskTable />
          </ReferenceManyField>
        </Card>
      </div>
      <div className="history-timeline-wrapper">
        <Card>
          <RAHistoryTimeline
            basePath={basePath}
            entryId={id}
            entryType="ClientCompany"
            versions={versions}
            clientCompanyId={id}
          />
        </Card>
      </div>
      <AttachmentManageDialog
        isOpen={isManagingAttachments}
        canOutsideClickClose={true}
        onClose={finishManagingAttachments}
        clientCompanyId={id}
      />
    </div>
  );
};
