import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { required } from "ra-core";
import { Create, SimpleForm, TextInput } from "ra-ui-materialui";
import * as React from "react";
import { PostCreateToolbar } from "../CreateToolBar";

const useStyles = makeStyles({
  name: { marginLeft: 10, display: "block" },
  notes: { marginLeft: 10, display: "block" },
  card: { backgroundColor: "inherit", boxShadow: "0px 0px 0px 0px" },
});

export const NaturalPersonCreate: React.FC<any> = (props) => {
  const classes = useStyles(props);
  const { clientGroupId, onSuccess } = props;
  return (
    <Create {...props} classes={classes} location={{}} onSuccess={onSuccess}>
      <SimpleForm
        toolbar={<PostCreateToolbar />}
        initialValues={{ clientGroupId }}
      >
        <>
          <TextInput
            label="Name"
            source="name"
            validate={[required()]}
            className={classes.name}
          />
          <TextInput
            label="Notes"
            source="notes"
            className={classNames(classes.notes, "person-notes-create")}
            multiline={true}
          />
        </>
      </SimpleForm>
    </Create>
  );
};
