import { Chip } from "@material-ui/core";
import React from "react";
import { statusString } from "../../../utils/approvalStatus";

interface IPriceField {
  source: string;
  label?: string;
  record?: { approvalStatus: string };
}

export const ApprovalStatusList: React.FC<IPriceField> = ({
  source,
  record,
  label = "Amount",
}) => {
  return <Chip label={statusString(record.approvalStatus)} />;
};
