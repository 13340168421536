import { Icon } from "@blueprintjs/core";
import moment from "moment";
import React, { FC, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Api } from "../../actions";
import { IEstimatedTimeEntry, IEtsState, IEtsUser } from "../../model";
import { convertAmount } from "../../utils/money";
import { DurationTimeInput } from "../inputs";
import { Warning } from "../Warning";
import { EstimatedUserSelect } from "./EstimatedUserSelect";

interface IEstimatedTimeEntryRecordProps {
  entry: IEstimatedTimeEntry;
  editable: boolean;
}

export const EstimatedTimeEntryRecord: FC<IEstimatedTimeEntryRecordProps> = ({
  entry,
  editable,
}) => {
  const dispatch = useDispatch();
  const actualTime = useSelector(
    (state: IEtsState) => state.actualTimeById[entry.userId]
  );

  const updateEstimatedTimeEntry = useCallback(
    (newEntry) => {
      dispatch(Api.EstimatedTimeEntry.update(entry, newEntry));
    },
    [dispatch]
  );
  const deleteEstimatedTimeEntry = useCallback(() => {
    dispatch(Api.EstimatedTimeEntry.delete(entry));
  }, [dispatch]);
  const isDaily = useSelector(
    (state: IEtsState) => state.departmentSetting.timebillDaily
  );

  const handleSelectUser = async (user: IEtsUser) => {
    updateEstimatedTimeEntry({
      ...entry,
      userId: user.id,
    });
  };

  const [duration, setDuration] = useState(entry.duration);
  const handleChangeDuration = useCallback(
    (newDuration: string) => {
      setDuration(newDuration);
      updateEstimatedTimeEntry({
        ...entry,
        duration: newDuration,
      });
    },
    [setDuration, updateEstimatedTimeEntry]
  );

  const handleDeleteDuration = useCallback(() => {
    deleteEstimatedTimeEntry();
  }, [deleteEstimatedTimeEntry]);

  const aroundTime = isDaily
    ? moment.duration(actualTime?.duration ?? "P0D").asDays()
    : moment
        .duration(actualTime?.duration ?? "P0D")
        .asHours()
        .toFixed(1);

  return (
    <div className="estimated-time-entry-container">
      <div className="estimated-time-entry-user">
        <EstimatedUserSelect
          selectedId={entry.userId}
          onSelect={handleSelectUser}
          disabled={true}
          defaultButtonLabel=""
        />
      </div>
      <div className="estimated-time-entry-rate">
        {entry.rateAmount ? (
          convertAmount(parseFloat(entry.rateAmount))
        ) : (
          <Warning text="This user has no user rate" />
        )}
      </div>
      <div className="estimated-time-entry-duration">
        <DurationTimeInput
          duration={duration}
          onChange={handleChangeDuration}
          disabled={!editable}
        />
      </div>
      <div className="estimated-time-entry-duration-actual">
        ({`${aroundTime}`})
      </div>
      <div className="estimated-time-entry-amount">
        <span>{convertAmount(parseFloat(entry.amount))}</span>
      </div>
      <div className="estimated-time-entry-amount-actual">
        <span>{actualTime ? convertAmount(actualTime.amount) : "(0)"}</span>
      </div>
      <div className="estimated-time-entry-variance">
        {convertAmount((actualTime?.amount ?? 0) - parseFloat(entry.amount))}
      </div>
      <div className="estimated-time-entry-delete">
        {editable && (
          <Icon icon="cross" iconSize={18} onClick={handleDeleteDuration} />
        )}
      </div>
    </div>
  );
};
