import { Icon } from "@blueprintjs/core";
import { Avatar, makeStyles, Theme, Tooltip } from "@material-ui/core";
import * as React from "react";
import { IEtsUser } from "../model";
import * as UserUtils from "../utils/user";

export interface IUserAvatarProps {
  user: IEtsUser;
}
const useStylesBootstrap = makeStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: "0.9em",
  },
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap({});

  return <Tooltip classes={classes} {...props} />;
}

export const UserAvatar: React.FC<IUserAvatarProps> = ({ user }) => {
  const useStyles = makeStyles({
    avatar: {
      backgroundColor: !!user ? user.colorHex : "gray",
    },
  });

  const renderNoUser = () => {
    return <Icon icon="error" iconSize={40} />;
  };

  const renderUser = () => {
    return <Avatar className={classes.avatar}>{user.abbreviation}</Avatar>;
  };

  const classes = useStyles({});
  const title = user ? UserUtils.getFullName(user) : "undefined";
  return (
    <BootstrapTooltip title={title}>
      {user ? renderUser() : renderNoUser()}
    </BootstrapTooltip>
  );
};
