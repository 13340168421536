import { Checkbox } from "@material-ui/core";
import React from "react";
import { IInvoice } from "../../model";

interface IProFormaProps {
  record?: IInvoice;
  label: string;
}

export const ProForma: React.FC<IProFormaProps> = ({ record, label }) => {
  return <>{record.proForma && <Checkbox checked={true} color="primary" />}</>;
};
