import { Constants } from "../constants/index";
import { ITenantSpecificFieldsConfig } from "../model";
import { TEXT_INPUT_EDIT_CELL_DEFAULT_PROPS } from "./defaultProps";

export const TENANT_SPECIFIC_COMPANY_FIELDS: ITenantSpecificFieldsConfig = {
  Jck: [
    {
      name: "Tax Code",
      field: `taxCode${Constants.TENANT_KEY}`,
      belongsTo: Constants.TENANT_KEY,
      ...TEXT_INPUT_EDIT_CELL_DEFAULT_PROPS,
    },
    {
      name: "Auditor Name",
      field: `auditorName${Constants.TENANT_KEY}`,
      belongsTo: Constants.TENANT_KEY,
      ...TEXT_INPUT_EDIT_CELL_DEFAULT_PROPS,
    },
  ],
};
