import Fuse from "fuse.js";
import { fuseJsOptionConfig } from "./fuseJsSearch";

export function filterCaseInsensitive(filter, row) {
  if (row._aggregated) {
    return true;
  }

  const filterValue = filter.value.toString();

  const key = [filter.id];
  const options = fuseJsOptionConfig(key);
  const fuse = new Fuse([row], options);
  const result = fuse.search(filterValue);
  return result?.length > 0;
}
