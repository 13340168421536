import { IconButton } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import React from "react";
import { IFormGroupResponse } from "../../model";

interface IFormGroupResponseDownloadButton {
  record?: IFormGroupResponse;
  label?: string;
}

export const FormGroupResponseAutofillDownloadButton: React.FC<IFormGroupResponseDownloadButton> = ({
  record,
}) => {
  if (!record) {
    return null;
  }

  return (
    <IconButton
      href={record?.autofillPdf}
      target="_blank"
      disabled={!record.autofillPdf}
      onClick={stopPropagation}
    >
      <GetAppIcon />
    </IconButton>
  );
};

const stopPropagation = (e) => e.stopPropagation();
