import { Button } from "@blueprintjs/core";
import { required } from "ra-core";
import * as React from "react";
import { NumberInput } from "react-admin";
import { useForm } from "react-final-form";
import { InvoiceBalancesDisplay } from "../Invoice/InvoiceBalancesDisplay";

interface IPaymentBalanceProps {
  wipBalance: string;
  expenseBalance: string;
  originalWip: string;
  originalExpense: string;
  withAutofillOption: boolean;
}

export const PaymentBalance: React.FC<IPaymentBalanceProps> = ({
  wipBalance,
  expenseBalance,
  originalWip,
  originalExpense,
  withAutofillOption,
}) => {
  const initialPayment =
    wipBalance === originalWip && expenseBalance === originalExpense;
  const form = useForm();
  const handleAutofillClick = () => {
    form.change("wipAmount", wipBalance);
    form.change("expenseAmount", expenseBalance);
  };
  const onChangeWip = (event) => {
    const value = event.target.value;
    form.change("wipAmount", value);
  };
  const onChangeExpense = (event) => {
    const value = event.target.value;
    form.change("expenseAmount", value);
  };
  return (
    <>
      {withAutofillOption && (
        <div className="payment-autofill-button">
          <Button onClick={handleAutofillClick}>Pay in Full</Button>
        </div>
      )}
      <NumberInput
        source="wipAmount"
        validate={[required()]}
        label="WIP amount"
        onChange={onChangeWip}
      />
      <InvoiceBalancesDisplay
        type="WIP"
        amountBilled={originalWip}
        remainingBalance={wipBalance}
        showRemaining={!initialPayment}
      />
      <br />
      <NumberInput
        source="expenseAmount"
        validate={[required()]}
        label="Expense amount"
        onChange={onChangeExpense}
      />
      <InvoiceBalancesDisplay
        type="expense"
        amountBilled={originalExpense}
        remainingBalance={expenseBalance}
        showRemaining={!initialPayment}
      />
    </>
  );
};
