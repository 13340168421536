import { Button, Classes, ControlGroup, InputGroup } from "@blueprintjs/core";
import { useNotify } from "ra-core";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { IEtsState } from "../../../model";

export const LoginLinkGenerator: React.FC = () => {
  const logoPath = useSelector((state: IEtsState) => state.product.logoPath);

  const productName = useSelector(
    (state: IEtsState) => state.product.productName
  );

  const [email, setEmail] = useState("");

  const notify = useNotify();
  const handleEmailOnchange = (event) => {
    setEmail(event.currentTarget.value);
  };

  const handleClick = useCallback(() => {
    if (email === "") {
      return notify("No Email Input", "warning");
    }
    // eslint-disable-next-line promise/catch-or-return
    fetch("/client_api/login_generator", {
      method: "POST",
      body: JSON.stringify({ email }),
      headers: {
        "content-type": "application/json",
      },
    }).then((response) => {
      if (response.status === 404) {
        return notify("Error Email", "warning");
      } else if (response.status === 403) {
        return notify("Email not authorized", "warning");
      }
      return notify(
        "Login Email has been sent. Please check your inbox.",
        "info"
      );
    });
  }, [email]);

  const renderLogo = () => {
    if (logoPath) {
      return (
        <img height="100" className="logo mx-auto d-block" src={logoPath} />
      );
    }
    return null;
  };

  const emailInput = () => {
    return (
      <ControlGroup className="login-form" vertical={true}>
        <InputGroup
          onChange={handleEmailOnchange}
          className={Classes.LARGE}
          leftIcon={"envelope"}
          placeholder={"Input Client User Email"}
          value={email}
          type="email"
        />
        <Button onClick={handleClick} text="Get Login Link" />
      </ControlGroup>
    );
  };

  return (
    <div className="login-page container d-table">
      <div className="content d-table-cell align-middle">
        {renderLogo()}
        <span className="product-name">{productName}</span>
        {emailInput()}
      </div>
    </div>
  );
};
