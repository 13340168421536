import { InputGroup } from "@blueprintjs/core";
import { FieldProps, FormikProps } from "formik";
import { lowerFirst, upperFirst } from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Column } from "react-table";
import { Api } from "../../../actions";
import {
  DEPARTMENT_SPECIFIC_COMPANY_FIELDS,
  DEPARTMENT_SPECIFIC_TASK_FIELDS,
  TENANT_SPECIFIC_COMPANY_FIELDS,
} from "../../../config";
import { Constants } from "../../../constants/index";
import { IModelById, ModelApi } from "../../../core/api";
import {
  DSFModel,
  IDepartment,
  IDepartmentSpecificFieldConfig,
  IDepartmentSpecificFieldOptions,
  IEtsState,
  IPermissions,
  ITenant,
  TSFModel,
} from "../../../model";
import {
  currentDepartment,
  currentPermissions,
  currentTenant,
} from "../../../selectors";
import { Dispatch } from "../../../types";
import { formatDate } from "../../../utils/date";
import {
  stripKeySuffix,
  stripSuffixFromDataKeys,
} from "../../../utils/fieldSpecific";
import { cannot } from "../../../utils/permissions";
import * as TableUtils from "../../../utils/table";

export interface IRenderEditorParams {
  fieldConfig: IDepartmentSpecificFieldConfig;
  additionalProps?: any;
}

export interface IRenderFormEditorParams extends IRenderEditorParams {
  fieldProps: FieldProps<any>;
}

interface IRenderEditorPrivateParams<TAdditionalData>
  extends IRenderEditorParams {
  data: Partial<TAdditionalData>;
  onSubmitCallback: (field: string, value: any, isCheckbox: boolean) => void;
  onChangeCallback: (field: string, value: any, isCheckbox: boolean) => void;
}

interface IDSFControllerStateProps {
  department: IDepartment;
  tenant?: ITenant;
  fieldConfigs: IDepartmentSpecificFieldConfig[];
  dsfPermissions: IPermissions;
}

interface IDSFControllerComponentProps<TAdditionalData> {
  departmentSpecificFieldConfigs: IDepartmentSpecificFieldConfig[];
  renderDSFEditor: (params: IRenderEditorParams) => JSX.Element;
}

export function withDSFCreateForm<TAdditionalData>(
  WrappedComponent: React.ComponentType<
    IDSFControllerComponentProps<TAdditionalData>
  >,
  dsfModel: DSFModel,
  tsfModel?: TSFModel
) {
  class DSFCreateForm extends React.Component<IDSFControllerStateProps> {
    constructor(props) {
      super(props);

      this.renderEditor = this.renderEditor.bind(this);
    }

    public render() {
      return (
        <WrappedComponent
          {...this.props}
          departmentSpecificFieldConfigs={this.props.fieldConfigs}
          renderDSFEditor={this.renderEditor}
        />
      );
    }

    private renderEditor(params: IRenderFormEditorParams) {
      const { department, tenant, dsfPermissions } = this.props;
      const fieldConfig = params.fieldConfig;
      const {
        field,
        belongsTo,
        CreateComponent,
        createComponentProps,
        createComponentOptions,
      } = fieldConfig;

      const { fieldProps, ...otherParams } = params;
      const data = fieldProps.form.values.additionalData;
      const callback = this.handleChange.bind(this, fieldProps.form);

      let fieldSpecificModelname = "";
      if (belongsTo !== undefined) {
        switch (belongsTo) {
          case Constants.TENANT_KEY:
            fieldSpecificModelname = tenantModelName(tenant, tsfModel);
            break;
          default:
            fieldSpecificModelname = modelName(department, dsfModel);
            break;
        }
      }

      const disabled = cannot({
        action: "create",
        modelName: fieldSpecificModelname,
        fieldName: field,
        permissions: dsfPermissions,
      });

      return renderEditor(
        CreateComponent,
        {
          ...createComponentProps,
          disabled,
        },
        createComponentOptions,
        {
          ...otherParams,
          data,
          onChangeCallback: callback,
          onSubmitCallback: callback,
        }
      );
    }

    private handleChange(
      form: FormikProps<any>,
      field: string,
      isCheckbox: boolean,
      value: any
    ) {
      form.setFieldValue(`additionalData.${field}`, value);
    }
  }

  function mapStateToProps(state: IEtsState) {
    return {
      department: currentDepartment(state),
      tenant: currentTenant(state),
      fieldConfigs: [
        ...fieldConfigsForCurrentUser(state, dsfModel),
        ...tenantFieldConfigsForCurrentUser(state, tsfModel),
      ],
      dsfPermissions: currentPermissions(state),
    };
  }

  return connect(mapStateToProps)(DSFCreateForm);
}

interface IDSFEditControllerStateProps<TAdditionalData>
  extends IDSFControllerStateProps {
  data: TAdditionalData;
}

interface IDSFEditControllerDispatchProps<TAdditionalData> {
  onCreateOrUpdateDepartmentSpecificData: (
    original: Partial<TAdditionalData>,
    updated: Partial<TAdditionalData>
  ) => Promise<TAdditionalData>;
  onCreateOrUpdateTenantSpecificData: (
    original: Partial<TAdditionalData>,
    updated: Partial<TAdditionalData>
  ) => Promise<TAdditionalData>;
}

// NOTE: This is done because currently all cases where we use the EditController
// the model ID is determined through the route (e.g. /tasks/{id}). Some slight
// modification will be necessary to handle other cases e.g. where this id might
// be supplied when rendering the component.
interface IDSFEditControllerOwnProps extends RouteComponentProps<any> {}

interface IDSFEditControllerProps<TAdditionalData>
  extends IDSFEditControllerStateProps<TAdditionalData>,
    IDSFEditControllerDispatchProps<TAdditionalData>,
    IDSFEditControllerOwnProps {}

export interface IDSFEditControllerComponentProps<TAdditionalData>
  extends IDSFControllerComponentProps<TAdditionalData> {
  departmentSpecificData: TAdditionalData;
}

interface IDSFEditControllerState<TAdditionalData> {
  data: TAdditionalData;
}

export function withDSFEditController<TAdditionalData>(
  WrappedComponent: React.ComponentType<
    IDSFEditControllerComponentProps<TAdditionalData>
  >,
  dsfModel: DSFModel
) {
  class DSFEditController extends React.Component<
    IDSFEditControllerProps<TAdditionalData>,
    IDSFEditControllerState<TAdditionalData>
  > {
    constructor(props) {
      super(props);
      this.state = {
        data: props.data,
      };

      this.renderEditor = this.renderEditor.bind(this);

      this.handleChange = this.handleChange.bind(this);
      this.handleConfirm = this.handleConfirm.bind(this);
    }

    public componentDidUpdate(prevProps) {
      if (this.props.data !== prevProps.data) {
        this.setState({ ...this.state, data: this.props.data });
      }
    }

    public render(): JSX.Element {
      return (
        <WrappedComponent
          {...this.props}
          departmentSpecificData={this.state.data}
          departmentSpecificFieldConfigs={this.props.fieldConfigs}
          renderDSFEditor={this.renderEditor}
        />
      );
    }

    private renderEditor(params: IRenderEditorParams) {
      const { department, dsfPermissions } = this.props;
      const fieldConfig = params.fieldConfig;
      const {
        field,
        EditComponent,
        editComponentProps,
        editComponentOptions,
      } = fieldConfig;

      const cannotCreate = cannot({
        action: "create",
        modelName: modelName(department, dsfModel),
        fieldName: field,
        permissions: dsfPermissions,
      });

      const cannotUpdate = cannot({
        action: "update",
        modelName: modelName(department, dsfModel),
        fieldName: field,
        permissions: dsfPermissions,
      });

      return renderEditor(
        EditComponent,
        {
          ...editComponentProps,
          disabled: cannotCreate || cannotUpdate,
        },
        editComponentOptions,
        {
          ...params,
          data: this.state.data,
          onChangeCallback: this.handleChange,
          onSubmitCallback: this.handleConfirm,
        }
      );
    }

    private async handleChange(field: string, isCheckbox: boolean, value: any) {
      if (isCheckbox) {
        this.handleConfirm(field, isCheckbox, value);
      } else {
        const updated: TAdditionalData = { ...this.state.data };
        updated[field] = value;
        await this.setState({ ...this.state, data: updated });
      }
    }

    private handleConfirm(field: string, isCheckbox: boolean, value: any) {
      const original = this.state.data;
      const updated: TAdditionalData = { ...(original as any) };
      updated[field] = value;
      this.props.onCreateOrUpdateDepartmentSpecificData(original, updated);
    }
  }

  function mapStateToProps(state: IEtsState, ownProps) {
    const dataKey = dataKeyForModel(dsfModel);
    const modelId = ownProps.match.params.id;
    return {
      data: state[dataKey][modelId],
      department: currentDepartment(state),
      fieldConfigs: fieldConfigsForCurrentUser(state, dsfModel),
      dsfPermissions: currentPermissions(state),
    };
  }

  function mapDispatchToProps(dispatch: Dispatch, ownProps: any) {
    const modelId = ownProps.match.params.id;
    const modelIdKey = modelIdKeyForModel(dsfModel);
    return {
      onCreateOrUpdateDepartmentSpecificData: (
        original: TAdditionalData,
        updated: TAdditionalData
      ) => {
        if (original) {
          original[modelIdKey] = modelId;
        }
        updated[modelIdKey] = modelId;
        return dispatch(
          apiForModel(dsfModel).createOrUpdate(original, updated)
        );
      },
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(DSFEditController as any);
}

interface IDSFTableControllerStateProps<TAdditionalData>
  extends IDSFControllerStateProps {
  data: IModelById<TAdditionalData>;
}

interface IDSFTableControllerProps<TAdditionalData>
  extends IDSFTableControllerStateProps<TAdditionalData>,
    IDSFEditControllerDispatchProps<TAdditionalData> {}

interface IDSFTableControllerState {
  reloadingRecords: Set<string>;
}

export interface IDSFTableControllerComponentProps {
  dsfColumnConfigs: Column[];
  reloadingRecords: Set<string>;
  onStartReloadingRecord: (id: string) => void;
  onFinishReloadingRecord: (id: string) => void;
}

export function withDSFTableController<TProps, TAdditionalData>(
  WrappedComponent: React.ComponentType<IDSFTableControllerComponentProps>,
  dsfModel: DSFModel,
  tsfModel?: TSFModel
) {
  class DSFTableController extends React.Component<
    IDSFTableControllerProps<TAdditionalData> & TProps,
    IDSFTableControllerState
  > {
    constructor(props) {
      super(props);

      this.state = {
        reloadingRecords: new Set(),
      };

      this.startReloadingRecord = this.startReloadingRecord.bind(this);
      this.finishReloadingRecord = this.finishReloadingRecord.bind(this);
    }

    public render() {
      return (
        <WrappedComponent
          {...this.props}
          dsfColumnConfigs={this.getColumnConfigs()}
          reloadingRecords={this.state.reloadingRecords}
          onStartReloadingRecord={this.startReloadingRecord}
          onFinishReloadingRecord={this.finishReloadingRecord}
        />
      );
    }

    private getColumnConfigs(): Column[] {
      const { data, fieldConfigs } = this.props;
      return fieldConfigs.map((fieldConfig) => {
        const {
          name,
          field,
          belongsTo,
          TableEditComponent,
          width,
        } = fieldConfig;

        const cell = this.renderEditableCell.bind(
          this,
          TableEditComponent,
          field,
          belongsTo
        );
        const columnFieldStripped = stripKeySuffix(field);

        const columnConfig = {
          Cell: cell,
          Header: name,
          className: "vertical-center",
          foldable: true,
          id: field,
          width,
          accessor: (row) => {
            if (data[row.id]) {
              if (
                data[row.id].hasOwnProperty(belongsTo) &&
                data[row.id][belongsTo].hasOwnProperty(columnFieldStripped)
              ) {
                return data[row.id][belongsTo][columnFieldStripped];
              }
            }
          },
        };

        return columnConfig;
      });
    }

    private renderEditableCell(
      ComponentClass,
      propertyName,
      cellBelongsTo,
      cellInfo
    ) {
      // Returning undefined will result in an uneditable text cell.
      if (!ComponentClass) {
        return <span>{cellInfo.value}</span>;
      }

      const { department, dsfPermissions } = this.props;

      const cannotCreate = cannot({
        action: "create",
        modelName: modelName(department, dsfModel),
        fieldName: propertyName,
        permissions: dsfPermissions,
      });

      const cannotUpdate = cannot({
        action: "update",
        modelName: modelName(department, dsfModel),
        fieldName: propertyName,
        permissions: dsfPermissions,
      });

      return TableUtils.renderEditableCell({
        ComponentClass,
        cellInfo,
        onFinishEditing: this.finishEditingData.bind(
          this,
          propertyName,
          cellBelongsTo
        ),
        loading:
          cellInfo &&
          cellInfo.original &&
          this.state.reloadingRecords.has(cellInfo.original.id),
        disabled: cannotCreate || cannotUpdate,
      });
    }

    private finishEditingData(
      key: string,
      cellBelongsTo: string | null,
      modelId: string,
      newValue: string
    ): Promise<any> {
      const originalData: Partial<TAdditionalData> =
        this.props.data[modelId] || {};
      originalData[modelIdKeyForModel(dsfModel)] = modelId;
      const updatedData = { ...(originalData as any) };
      key = stripKeySuffix(key);

      let promise: Promise<any> = Promise.resolve();
      switch (cellBelongsTo) {
        case Constants.TENANT_KEY:
          promise = this.returnTenantDepartmentPromise(
            originalData,
            updatedData,
            newValue,
            key,
            Constants.TENANT_KEY
          );
          break;
        case Constants.DEPARTMENT_KEY:
          promise = this.returnTenantDepartmentPromise(
            originalData,
            updatedData,
            newValue,
            key,
            Constants.DEPARTMENT_KEY
          );
          break;
        default:
          break;
      }
      this.startReloadingRecord(modelId);
      return promise.finally(() => {
        return this.finishReloadingRecord(modelId);
      });
    }

    private returnTenantDepartmentPromise(
      originalData: Partial<TAdditionalData>,
      updatedData: Partial<TAdditionalData>,
      newValue: any,
      newValueKey: string,
      fieldBelongsToKey: string
    ) {
      originalData = stripSuffixFromDataKeys(originalData, fieldBelongsToKey);
      updatedData = stripSuffixFromDataKeys(updatedData, fieldBelongsToKey);
      updatedData[newValueKey] = newValue;
      if (!(originalData[newValueKey] == null && newValue === "")) {
        switch (fieldBelongsToKey) {
          case Constants.TENANT_KEY:
            return this.props.onCreateOrUpdateTenantSpecificData(
              originalData,
              updatedData
            );
          case Constants.DEPARTMENT_KEY:
            return this.props.onCreateOrUpdateDepartmentSpecificData(
              originalData,
              updatedData
            );
          default:
            break;
        }
      }
      return Promise.resolve();
    }

    private startReloadingRecord(modelId: string) {
      const reloadingRecords = new Set(this.state.reloadingRecords);
      reloadingRecords.add(modelId);
      this.setState({ ...this.state, reloadingRecords });
    }

    private finishReloadingRecord(modelId: string) {
      const reloadingRecords = new Set(this.state.reloadingRecords);
      reloadingRecords.delete(modelId);
      this.setState({ ...this.state, reloadingRecords });
    }
  }

  function mapStateToProps(
    state: IEtsState
  ): IDSFTableControllerStateProps<TAdditionalData> {
    const dataKey = dataKeyForModel(dsfModel);
    const tenantDataKey = dataKeyForModel(tsfModel);
    // Reduce all DSF data to a object under the department key
    let data = Object.keys(state[dataKey]).reduce((acc, key) => {
      const clientCompanyIdKey = state[dataKey][key].clientCompanyId;
      if (clientCompanyIdKey === undefined) {
        acc[state[dataKey][key].taskId] = {
          Department: { ...state[dataKey][key] },
        };
      } else {
        acc[state[dataKey][key].clientCompanyId] = {
          Department: { ...state[dataKey][key] },
        };
      }
      return acc;
    }, {});

    // Reduce all TSF data to a object under the tenant key
    data =
      state[tenantDataKey] !== undefined
        ? Object.keys(state[tenantDataKey]).reduce((acc, key) => {
            if (acc.hasOwnProperty(state[tenantDataKey][key].clientCompanyId)) {
              acc[state[tenantDataKey][key].clientCompanyId] = {
                ...acc[state[tenantDataKey][key].clientCompanyId],
                Tenant: { ...state[tenantDataKey][key] },
              };
            } else {
              acc[state[tenantDataKey][key].clientCompanyId] = {
                Tenant: { ...state[tenantDataKey][key] },
              };
            }
            return acc;
          }, data)
        : data;

    return {
      data,
      department: currentDepartment(state),
      fieldConfigs: [
        ...fieldConfigsForCurrentUser(state, dsfModel),
        ...tenantFieldConfigsForCurrentUser(state, tsfModel),
      ],
      dsfPermissions: currentPermissions(state),
    };
  }

  function mapDispatchToProps(dispatch: Dispatch) {
    return {
      onCreateOrUpdateDepartmentSpecificData: (
        original: TAdditionalData,
        updated: TAdditionalData
      ) => {
        return dispatch(
          apiForModel(dsfModel).createOrUpdate(original, updated)
        );
      },
      onCreateOrUpdateTenantSpecificData: (
        original: TAdditionalData,
        updated: TAdditionalData
      ) => {
        return dispatch(
          apiForModel(tsfModel).createOrUpdate(original, updated)
        );
      },
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(DSFTableController as any);
}

function isCB(fieldConfig) {
  if (
    fieldConfig.createComponentProps &&
    fieldConfig.createComponentProps.componentType
  ) {
    return fieldConfig.createComponentProps.componentType === "Checkbox";
  }
}

function renderEditor<TAdditionalData>(
  Editor: React.ComponentType,
  editorProps: any,
  options: IDepartmentSpecificFieldOptions,
  params: IRenderEditorPrivateParams<TAdditionalData>
) {
  const {
    fieldConfig,
    data,
    onSubmitCallback,
    onChangeCallback,
    additionalProps,
  } = params;
  const { field } = fieldConfig;
  const submitOnConfirm = options && options.submitOnConfirm;
  const submitOnBlur = options && options.submitOnBlur;
  const props = {
    value: getFieldValueFromData(fieldConfig, Editor, editorProps, data),
    onChange:
      submitOnConfirm || submitOnBlur
        ? changeHandler.bind(this, field, isCB(fieldConfig), onChangeCallback)
        : changeHandler.bind(this, field, isCB(fieldConfig), onSubmitCallback),
    ...editorProps,
    ...additionalProps,
  };

  if (isCB(fieldConfig)) {
    props.checked = getFieldValueFromData(
      fieldConfig,
      Editor,
      editorProps,
      data
    );
  } else {
    props.value = getFieldValueFromData(fieldConfig, Editor, editorProps, data);
  }

  if (submitOnConfirm) {
    props.onConfirm = changeHandler.bind(
      this,
      isCB(fieldConfig),
      field,
      onSubmitCallback
    );
  }

  if (submitOnBlur) {
    props.onBlur = changeHandler.bind(
      this,
      isCB(fieldConfig),
      field,
      onSubmitCallback
    );
  }

  return <Editor {...props} />;
}

function getFieldValueFromData<TAdditionalData>(
  fieldConfig: IDepartmentSpecificFieldConfig,
  Editor,
  props: any,
  data: Partial<TAdditionalData>
) {
  if (!data) {
    if (Editor === InputGroup) {
      return "";
    } else if (isCB(fieldConfig)) {
      return false;
    }
    return;
  }

  const field = fieldConfig.field;
  let value = data[field] || "";
  if (props && props.hasOwnProperty("parseDate")) {
    value = props.parseDate(value);
  } else if (isCB(fieldConfig) && value === "") {
    value = false;
  }
  return value;
}

function changeHandler(
  field: string,
  isCheckbox: boolean,
  callback: (field: string, isCheckbox: boolean, value: any) => void,
  raw: any
) {
  let value = raw;
  // In this case, we're dealing with an event
  if (raw != null && raw.hasOwnProperty("target")) {
    value = raw.target.value;
    // if (!value) value = false;
  } else if (value instanceof Date) {
    value = formatDate(raw);
  }
  callback(field, isCheckbox, isCheckbox ? !(value === "true") : value);
}

export function fieldConfigsForCurrentUser(state: IEtsState, model: DSFModel) {
  const department = currentDepartment(state);
  const dsfConfig = fieldConfigsForModel(model);
  const fields = dsfConfig[department.internalIdentifier];

  if (model === DSFModel.Task && fields !== undefined) {
    fields.forEach(
      (fieldObj) => (fieldObj.field = stripKeySuffix(fieldObj.field))
    );
  }
  return fields ? fields : [];
}

export function tenantFieldConfigsForCurrentUser(
  state: IEtsState,
  tenantModel: TSFModel
) {
  const tenant = currentTenant(state);
  const tsfConfig = fieldConfigsForModel(tenantModel);
  const fields = tsfConfig[tenant.internalIdentifier];

  return fields ? fields : [];
}

function fieldConfigsForModel(model) {
  switch (model) {
    case DSFModel.Task:
      return DEPARTMENT_SPECIFIC_TASK_FIELDS;
    case DSFModel.Company:
      return DEPARTMENT_SPECIFIC_COMPANY_FIELDS;
    case TSFModel.TenantCompany:
      return TENANT_SPECIFIC_COMPANY_FIELDS;
    default:
      return {};
  }
}

function modelName(department: IDepartment, model: DSFModel) {
  return `${lowerFirst(department.internalIdentifier)}${upperFirst(
    model
  )}AdditionalDataRecord`;
}

function tenantModelName(tenant: ITenant, model: TSFModel) {
  return `${lowerFirst(tenant.internalIdentifier)}${upperFirst(
    model
  )}AdditionalDataRecord`;
}

function dataKeyForModel(model) {
  return `${model}AdditionalData`;
}

function modelIdKeyForModel(model: DSFModel) {
  switch (model) {
    case DSFModel.Company:
      return "clientCompanyId";
    default:
      return `${model}Id`;
  }
}

function apiForModel(model): ModelApi<any> {
  switch (model) {
    case DSFModel.Task:
      return Api.TaskAdditionalDataRecord;
    case DSFModel.Company:
      return Api.CompanyAdditionalDataRecord;
    case TSFModel.TenantCompany:
      return Api.TenantAdditionalDataRecord;
    default:
      console.error(`No API defined for DSF Model ${model}`);
      return null;
  }
}
