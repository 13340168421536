import { Button, Grid } from "@material-ui/core";
import moment from "moment";
import { FormDataConsumer } from "ra-core";
import React, { useCallback } from "react";
import { BooleanInput, ReferenceField } from "react-admin";
import { useForm } from "react-final-form";
import { Constants } from "../../constants";
import { IInvoice, ITask } from "../../model";

interface IInvoiceProFormProps {
  record?: IInvoice;
  isCreate: boolean;
}

export const InvoiceProForma: React.FC<IInvoiceProFormProps> = (props) => {
  return (
    <FormDataConsumer>
      {({ formData }) => (
        <ProForm
          record={props.record}
          isCreate={props.isCreate}
          formData={formData}
        />
      )}
    </FormDataConsumer>
  );
};

interface IProFormProps {
  record?: IInvoice;
  isCreate: boolean;
  formData: IInvoice;
}

const ProForm: React.FC<IProFormProps> = ({ record, isCreate, formData }) => {
  return (
    <Grid item={true} xs={12}>
      <ReferenceField
        resource={Constants.INVOICE_RESOURCE}
        reference={Constants.TASK_RESOURCE}
        source="taskId"
        basePath={Constants.TASK_PATH}
        record={record}
        link={false}
        addLabel={false}
      >
        <ProFormInput isCreate={isCreate} formData={formData} />
      </ReferenceField>
    </Grid>
  );
};

interface IProFormInputProps {
  record?: ITask;
  isCreate: boolean;
  formData: IInvoice;
}

const ProFormInput: React.FC<IProFormInputProps> = ({ isCreate, formData }) => {
  const form = useForm();
  const handleOnclick = useCallback(() => {
    const confirm = window.confirm(
      "Please confirm that this will become a fee note"
    );
    if (confirm) {
      form.change("proForma", false);
      form.change("date", moment().format("YYYY-MM-DD"));
    }
  }, []);

  return (
    <Grid
      container={true}
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item={true}>
        <BooleanInput
          label="Pro Forma"
          source="proForma"
          disabled={isCreate ? false : true}
        />
      </Grid>
      {!isCreate && formData.proForma && (
        <Grid item={true}>
          <Button
            onClick={handleOnclick}
            size="small"
            color="primary"
            variant="contained"
          >
            Convert
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
