import { isNullOrUndefined } from "util";
import { IEtsUser } from "../model";

const DEFAULT_USER_COLOR = "#f5f8fa";
const DEFAULT_ABBREVIATION = "A";

export const displayValue = (user: IEtsUser, includeLastName = false) => {
  if (!user) {
    return null;
  }

  let displayValue = user.email;

  if (includeLastName && user.firstName && user.lastName) {
    displayValue = `${user.firstName} ${user.lastName}`;
  } else if (user.firstName) {
    displayValue = user.firstName;
  } else if (user.username) {
    displayValue = user.username;
  }

  return displayValue;
};

export const initials = (user: IEtsUser) => {
  if (!user) {
    return null;
  }

  if (user.firstName && user.lastName) {
    return `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
  } else if (user.firstName) {
    return user.firstName.charAt(0);
  } else if (user.lastName) {
    return user.lastName.charAt(0);
  }

  return null;
};

export function getFullName(user: IEtsUser) {
  return `${user.firstName} ${user.lastName}`;
}

export function getStyleFromUser(user?: IEtsUser) {
  if (isNullOrUndefined(user?.colorHex) || user?.colorHex === "") {
    return { backgroundColor: DEFAULT_USER_COLOR };
  }
  return { backgroundColor: user.colorHex };
}

export function getAbbreviation(user?: IEtsUser) {
  if (isNullOrUndefined(user?.abbreviation) || user?.abbreviation === "") {
    return DEFAULT_ABBREVIATION;
  }
  return user.abbreviation;
}
