import { TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import React from "react";
import * as Utils from "../../../utils/date";

export const TimesheetTableHeaderRow: React.FC<any> = ({ date }) => {
  const dateOfWeek = getCurrentDateOfWeek(date);
  const renderDate = (d: Date) => (
    <TableCell
      className="timesheet-duration-box"
      key={`head${d} ${moment(d).format("(ddd)")}`}
    >
      {Utils.formatMonthDay(d)}
      {moment(d).format("(ddd)")}
    </TableCell>
  );

  return (
    <TableRow>
      <TableCell className="timesheet-create-table-head-left" />
      {dateOfWeek.map(renderDate)}
      <TableCell
        className="timesheet-duration-box"
        key={`head${date} total}`}
      />
    </TableRow>
  );
};

export const getCurrentDateOfWeek = (date: string) => {
  const startDayOfWeek = moment(date).startOf("week").add(1, "d");
  const dateOfWeek: Date[] = [];
  let currentDay = startDayOfWeek;
  let i = 0;
  while (i < 7) {
    dateOfWeek.push(currentDay.toDate());
    currentDay = currentDay.clone().add(1, "d");
    i++;
  }
  return dateOfWeek;
};
