import { ActionTypes } from "@chiubaka/core";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { Action, AnyAction, applyMiddleware, createStore, Store } from "redux";
import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";
import thunk, { ThunkDispatch } from "redux-thunk";
import { storageMiddleware } from "./core/middlewares";
import { getExistingState, IEtsState } from "./model";
import { etsReducer } from "./reducers";
import { rootSaga } from "./sagas";

export const history = createBrowserHistory();
export const authProvider = {
  login: (params) => Promise.resolve(),
  logout: (params) => Promise.resolve(),
  checkAuth: (params) => Promise.resolve(),
  checkError: (error) => Promise.resolve(),
  getPermissions: (params) => Promise.resolve(),
};

export interface IStore<S = any, A extends Action = AnyAction> extends Store {
  dispatch: ThunkDispatch<S, any, A>;
}

export interface IEtsStore extends IStore<IEtsState, any> {}

export const createAdminStore = ({
  dataProviderFactory,
  authProvider: authP,
  history: hist,
}) => {
  const reducer = etsReducer(hist);
  const resettableAppReducer = (state, action) => {
    if (action.type !== ActionTypes.COMPLETE_LOGOUT) {
      return reducer(state, action);
    }

    return reducer(getExistingState() as any, action);
  };

  const sagaMiddleware = createSagaMiddleware();

  const middleware = [
    sagaMiddleware,
    routerMiddleware(hist),
    thunk,
    storageMiddleware,
  ];

  // Only log actions to console in dev mode
  if (!process.env.NODE_ENV || process.env.NODE_ENV !== "production") {
    middleware.push(logger);
  }

  const store = createStore(
    resettableAppReducer,
    getExistingState(),
    applyMiddleware(...middleware)
  );

  const dataProvider = dataProviderFactory("app", store);

  const clientPortalDataProvider = dataProviderFactory("clientPortal", store);

  sagaMiddleware.run(rootSaga(dataProvider, authP));

  return { store, dataProvider, clientPortalDataProvider };
};
