import { Button, Icon } from "@blueprintjs/core";
import { TimePicker } from "@blueprintjs/datetime";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Prompt } from "react-router";
import { useStopwatch } from "react-timer-hook";
import { IEtsState } from "../../../model";
import { DurationInput } from "../../inputs";
import {
  AddTimeEntryDispatchContext,
  AddTimeEntryStateContext,
} from "../context";

export interface ITimeEntryDurationTabProps {
  selected: boolean;
}

const initialDate = moment().startOf("D").toDate();

interface ITimerProps {
  selected: boolean;
  duration: string;
  onChange: (duration: string) => void;
}

const Timer = ({ selected, duration, onChange }: ITimerProps) => {
  const [baseDate, setBaseDate] = useState<Date>(initialDate);
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch();

  const date = useMemo(
    () =>
      moment(baseDate)
        .add(days, "d")
        .add(hours, "h")
        .add(minutes, "m")
        .add(seconds, "s")
        .toDate(),
    [seconds, minutes, hours, days, baseDate]
  );

  const durationString = useMemo(
    () =>
      moment
        .duration({
          seconds: moment(date).seconds(),
          minutes: moment(date).minutes(),
          hours: moment(date).hours(),
        })
        .toISOString(),
    [date]
  );

  useEffect(() => {
    if (durationString !== duration && duration === "PT0H") {
      setBaseDate(initialDate);
      reset();
    }
  }, [duration, reset]);

  const handleChange = (newTime: Date) => {
    setBaseDate(newTime);
    reset();
  };

  useEffect(() => {
    reset();
    setBaseDate(initialDate);
  }, [selected]);

  useEffect(() => {
    onChange(durationString);
  }, [durationString]);

  const handlePause = () => {
    pause();
  };

  const handleReset = () => {
    reset();
  };

  return (
    <div className="add-time-entry-input-duration">
      <Prompt
        when={isRunning ?? false}
        message={"Stop Watch is running. Are you sure you leave?"}
      />
      <TimePicker
        onChange={handleChange}
        value={date}
        precision="second"
        disabled={isRunning}
      />
      {!isRunning && (
        <Button onClick={start} style={{ borderRadius: "100%" }}>
          <Icon
            icon="record"
            style={{ color: "red", display: "flex", alignItems: "center" }}
          />
        </Button>
      )}
      {isRunning && (
        <>
          <Button onClick={handlePause} style={{ borderRadius: "100%" }}>
            <Icon
              icon="pause"
              style={{ color: "black", display: "flex", alignItems: "center" }}
            />
          </Button>
          <Button
            onClick={handleReset}
            style={{ borderRadius: "100%", marginLeft: "5px" }}
          >
            <Icon
              icon="stop"
              style={{ color: "black", display: "flex", alignItems: "center" }}
            />
          </Button>
        </>
      )}
    </div>
  );
};

export const TimeEntryDurationTab = ({
  selected,
}: ITimeEntryDurationTabProps) => {
  const {
    state: { duration },
  } = useContext(AddTimeEntryStateContext);
  const { dispatch } = useContext(AddTimeEntryDispatchContext);
  const isDaily = useSelector(
    (state: IEtsState) => state.departmentSetting.timebillDaily
  );
  const onChange = (payload: string) =>
    dispatch({ type: "SET_DURATION", payload });
  if (isDaily) {
    return <DurationInput duration={duration} onChange={onChange} />;
  }

  return <Timer selected={selected} duration={duration} onChange={onChange} />;
};
