import React from "react";
import { IFormGroupResponse } from "../../model";

interface IStatusFieldProps {
  record?: IFormGroupResponse;
  label?: string;
}

export const FormGroupResponseStatusField: React.FC<IStatusFieldProps> = ({
  record,
}) => {
  return <span>{record && convertFormGroupResponseState(record.status)}</span>;
};

const convertFormGroupResponseState = (status: "finished" | "unfinished") => {
  switch (status) {
    case "finished":
      return "Finished";
    case "unfinished":
      return "In Process";
  }
};
