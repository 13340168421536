import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

interface TimelineDialogProps {
  selectedItem: any;
  isOpen: boolean;
  onOpen: (boolean) => void;
}

export const TimelineDialog: React.FC<TimelineDialogProps> = (props) => {
  const handleCloseModal = () => {
    onOpen(false);
  };

  const { selectedItem, isOpen, onOpen } = props;

  return (
    <Dialog
      onClose={handleCloseModal}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
    >
      <DialogTitle id="simple-dialog-title">
        {selectedItem.companyName}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {selectedItem.title} on{" "}
          {selectedItem.start_time.format("DD MMMM YYYY")}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
