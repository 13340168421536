import * as React from "react";

export interface IRenderEditableCellParams {
  ComponentClass: any;
  cellInfo: any;
  disabled?: boolean;
  loading?: boolean;
  onFinishEditing: (id: string, newValue: string) => Promise<any>;
  query?: string;
}

// Factory method that instantiates react component
export function renderEditableCell(params: IRenderEditableCellParams) {
  const {
    ComponentClass,
    cellInfo,
    disabled,
    loading,
    onFinishEditing,
    query,
  } = params;

  if (cellInfo.groupedByPivot) {
    return null;
  }

  return (
    <ComponentClass
      cellInfo={cellInfo}
      disabled={disabled}
      loading={loading}
      onFinishEditing={onFinishEditing}
      query={query}
    />
  );
}
