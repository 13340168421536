import { Action } from "redux";
import { createAction } from "typesafe-actions";
import { type } from "../core";
import {
  IBankStatementConversion,
  IEmailAction,
  IInvoice,
  IPartialTaskWithDSF,
  ITaskWithDSF,
} from "../model";
import { Api } from "./api";

export * from "./admin";
export * from "./api";

export const ActionTypes = {
  SELECT_TASKS: type<"SELECT_TASKS">("SELECT_TASKS"),
  UNSELECT_TASKS: type<"UNSELECT_TASKS">("UNSELECT_TASKS"),
  CLEAR_TASK_SELECTION: type<"CLEAR_TASK_SELECTION">("CLEAR_TASK_SELECTION"),
  OPEN_CLONE_TASKS_DIALOG: type<"OPEN_CLONE_TASKS_DIALOG">(
    "OPEN_CLONE_TASKS_DIALOG"
  ),
  CLOSE_CLONE_TASKS_DIALOG: type<"CLOSE_CLONE_TASKS_DIALOG">(
    "CLOSE_CLONE_TASKS_DIALOG"
  ),
};

export interface ISelectTasks extends Action {
  taskIds: string[];
}

export function selectTasks(taskIds: string[]): ISelectTasks {
  return {
    type: ActionTypes.SELECT_TASKS,
    taskIds,
  };
}

export function unselectTasks(taskIds: string[]): ISelectTasks {
  return {
    type: ActionTypes.UNSELECT_TASKS,
    taskIds,
  };
}

export function clearTaskSelection(): Action {
  return {
    type: ActionTypes.CLEAR_TASK_SELECTION,
  };
}

export const subscribeToInvoice = createAction("invoices/SUBSCRIBE")<
  IInvoice
>();

export const updateInvoice = createAction("invoices/UPDATE")<IInvoice>();

export const subscribeToBankStatementConversion = createAction(
  "bankStatementConversions/SUBSCRIBE"
)<IBankStatementConversion>();

export const updateBankStatementConversion = createAction(
  "bankStatementConversions/UPDATE"
)<IBankStatementConversion>();

export function cloneTask(
  taskWithDsf: ITaskWithDSF,
  overrides: IPartialTaskWithDSF = {}
) {
  return (dispatch) => {
    const newTask = {
      ...taskWithDsf.task,
      description: `Copy of ${taskWithDsf.task.description}`,
      id: undefined,
      createdAt: undefined,
      createdById: undefined,
      isComplete: false,
      completedAt: undefined,
      completedById: undefined,
      ...overrides.task,
    };

    return dispatch(Api.Task.create(newTask)).then((task) => {
      const newAdditionalData = {
        ...taskWithDsf.additionalData,
        id: undefined,
        taskId: task.id,
        ...overrides.additionalData,
      };

      return dispatch(
        Api.TaskAdditionalDataRecord.create(newAdditionalData)
      ).then((additionalData) => {
        return {
          task,
          additionalData,
        };
      });
    });
  };
}

export function cloneTasks(
  tasks: ITaskWithDSF[],
  overrides: IPartialTaskWithDSF = {}
) {
  return (dispatch) => {
    const promises = [];

    tasks.forEach((task) => {
      promises.push(dispatch(cloneTask(task, overrides)));
    });

    return Promise.all(promises);
  };
}

export function openCloneTasksDialog(): Action {
  return {
    type: ActionTypes.OPEN_CLONE_TASKS_DIALOG,
  };
}

export function closeCloneTasksDialog(): Action {
  return {
    type: ActionTypes.CLOSE_CLONE_TASKS_DIALOG,
  };
}

export const receiveEmailAction = createAction("emails/RECEIVE_EMAIL_ACTION")<
  IEmailAction
>();
