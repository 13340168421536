import moment from "moment";
import { isNullOrUndefined } from "util";
import { IStorageContainer } from "./storage";
export function get(key: string) {
  const item = localStorage.getItem(key);
  if (isNullOrUndefined(item)) {
    return null;
  }
  const container: IStorageContainer = JSON.parse(item);
  // If expired, return null
  const { createdAt, expires, data } = container;
  if (!expires || moment().isBefore(moment(createdAt).add(expires, "days"))) {
    return data;
  }
  localStorage.removeItem(key);
  return null;
}
