export const convertAmount = (amount: number) =>
  amount.toLocaleString(undefined, {
    style: "currency",
    currency: "HKD",
  });

export const priceFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});
