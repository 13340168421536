import { Chip } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { IDepartmentAccountBalance, IEtsState } from "../../model";
import { getFullName } from "../../utils/user";

interface IHeaderNameProps {
  type: string;
  record: IDepartmentAccountBalance;
}

export const HeaderName: React.FC<IHeaderNameProps> = ({ record, type }) => {
  const user = useSelector((state: IEtsState) =>
    type === "Users" ? state.usersById[record.id] : null
  );
  return (
    <Chip label={user ? getFullName(user) : record.name} color="primary" />
  );
};
