import { Typography } from "@material-ui/core";
import { Inbox } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { useTranslate } from "ra-core";
import React from "react";
import { EmptyMessage } from "./EmptyMessage";

const useStyles = makeStyles(
  (theme: any) => ({
    message: {
      textAlign: "center",
      opacity: theme.palette.type === "light" ? 0.5 : 0.8,
      margin: "0 1em",
      color:
        theme.palette.type === "light" ? "inherit" : theme.palette.text.primary,
    },
    icon: {
      width: "9em",
      height: "9em",
    },
    toolbar: {
      textAlign: "center",
      marginTop: "2em",
    },
  }),
  { name: "RaEmpty" }
);

export const EmptyPage: React.FC<any> = ({
  timesheetCreateButton = null,
  ...props
}) => {
  const { basePath } = props;
  const resource: string = props.resource;
  const classes = useStyles(props);
  const translate = useTranslate();

  const resourceName = resource.replace("_", " ");
  const emptyMessage = translate("ra.page.empty", { name: resourceName });
  const inviteMessage = translate("ra.page.invite");

  return (
    <>
      <div className={classes.message}>
        <Inbox className={classes.icon} />
        <Typography variant="h4" paragraph={true}>
          {translate(`resources.${resource}.empty`, {
            _: emptyMessage,
          })}
        </Typography>
        <Typography variant="body1" paragraph={true}>
          {translate(`resources.${resource}.invite`, {
            _: inviteMessage,
          })}
        </Typography>
        {resource !== "timesheets" && (
          <EmptyMessage basePath={basePath} resource={resource} />
        )}
      </div>
      <div className={classes.toolbar}>{timesheetCreateButton}</div>
    </>
  );
};
