import { Icon } from "@blueprintjs/core";
import { UserUtil } from "@chiubaka/core";
import { useUpdate } from "ra-core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashLink as NavLink } from "react-router-hash-link";
import { isNullOrUndefined } from "util";
import { Api } from "../../actions";
import { Constants } from "../../constants";
import { IEmailSortable, IEtsState } from "../../model";
import * as DateUtils from "../../utils/date";
import * as UserUtils from "../../utils/user";
import { ReadMoreHtml } from "../ReadMore/ReadMoreHtml";
import { CCEmails } from "./CCEmails";
import { EmailAttachments } from "./EmailAttachments";
import { EmailBody } from "./EmailBody";

interface IEmailThreadProps {
  entry: IEmailSortable;
  parentEntryType: string;
  startSharingAttachment?: (emailAttachment, type) => void;
}

export const EmailThread = ({
  entry,
  parentEntryType,
  startSharingAttachment,
}: IEmailThreadProps) => {
  const [isBodyHidden, setIsBodyHidden] = useState(entry.isHidden);
  const [isExpanded, setIsExpanded] = useState(false);
  const dispatch = useDispatch();
  const usersById = useSelector((state: IEtsState) => state.usersById);

  const updated = { ...entry, isHidden: !isBodyHidden };
  const [requestToggleHidden] = useUpdate(
    Constants.EMAIL_RESOURCE,
    entry.id,
    updated,
    entry,
    {
      onSuccess: () => {
        dispatch(Api.Email.successfulUpdateAction(entry, updated));
      },
    }
  );

  const handleToggleHidden = () => {
    setIsBodyHidden(!isBodyHidden);
    requestToggleHidden();
  };

  const handleToggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const updateExpanded = (newExpandedState) => {
    setIsExpanded(newExpandedState);
  };

  const updateTruncated = (newTruncatedState) => {
    setIsExpanded(!newTruncatedState);
  };

  const {
    emailBody,
    emailBodyHtml,
    senderId,
    emailSubject,
    recipientId,
    createdAt,
    attachments,
    ccIds,
    emailType,
    taskDescription,
    taskId,
    isHidden,
    entryDescription,
    entryId,
    id,
  } = entry;

  let createdByInitials = "AN";
  let createdByName = "Unknown";
  let createdByStyle = {};

  const hash = `#${entryId}`;
  if (
    !isNullOrUndefined(usersById) &&
    !isNullOrUndefined(senderId) &&
    usersById[senderId]
  ) {
    const createdBy = usersById[senderId];
    createdByInitials = createdBy.abbreviation;
    createdByName = UserUtil.displayValue(createdBy, true);
    createdByStyle = UserUtils.getStyleFromUser(createdBy);
  }

  const toEmails = recipientId
    .split(",")
    .filter((e) => !e.includes("easytrack.ai"))
    .map((email, index) => (
      <span className="timeline-email-addr" key={index}>
        {email}
      </span>
    ));
  const date = DateUtils.formatDate(DateUtils.parseDate(createdAt));
  const emailBodyToRender = emailBodyHtml || emailBody;

  return (
    <div className="timeline-email-wrapper" key={createdAt}>
      <div className="timeline-email-avatar" style={createdByStyle}>
        {createdByInitials}
      </div>
      <div className="timeline-email-border">
        <div className="timeline-email-header">
          <span>
            {createdByName} sent on {date}
            {emailType === "Subtask" && (
              <NavLink to={hash} smooth={true}>
                <span className="timeline-email-subtask-link">
                  {"   "}for: {entryDescription}
                </span>
              </NavLink>
            )}
            {"  "}
            <Icon className="icon-align-center" icon="envelope" />
          </span>
          <span className="timeline-email-float-right">
            {!isNullOrUndefined(attachments) && attachments.length !== 0 && (
              <Icon
                className="icon-float-right"
                icon="paperclip"
                iconSize={16}
              />
            )}
            <Icon
              className="icon-float-right email-eye-button"
              icon={isExpanded ? "minus" : "plus"}
              onClick={handleToggleExpanded}
            />
            <Icon
              className="icon-float-right email-eye-button"
              icon={isBodyHidden ? "eye-open" : "eye-off"}
              onClick={handleToggleHidden}
            />
          </span>
        </div>
        {!isBodyHidden && (
          <div>
            <div className="timeline-email">
              <div className="timeline-email-to">
                <div className="">to: </div>
                <div className="timeline-email-addrs">{toEmails}</div>
              </div>
              <CCEmails ccIds={ccIds} />
              <div className="timeline-email-subject">{emailSubject}</div>
              <div className="timeline-email-content">
                {!isNullOrUndefined(emailBodyHtml) && emailBodyHtml !== "" ? (
                  <ReadMoreHtml
                    body={emailBodyToRender}
                    identifier={id}
                    heightThreshold={100}
                    truncated={!isExpanded}
                    updateTruncated={updateTruncated}
                  />
                ) : (
                  <EmailBody
                    emailBody={emailBodyToRender}
                    expanded={isExpanded}
                    updateExpanded={updateExpanded}
                  />
                )}
              </div>
            </div>
            <div className="timeline-email">
              {attachments?.length > 0 && (
                <div className="timeline-email-attachments-container">
                  {attachments.map((attachment, index) => (
                    <EmailAttachments
                      key={index}
                      {...attachment}
                      startSharingAttachment={startSharingAttachment.bind(
                        this,
                        attachment,
                        "email"
                      )}
                      parentEntryType={parentEntryType}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
