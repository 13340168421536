import React from "react";
import { IInvoice } from "../../model";
import { priceFormatter } from "../../utils/money";

interface IBalanceProps {
  record?: IInvoice;
  label?: string;
  sortable?: boolean;
  source?: string;
}

export const BalanceField: React.FC<IBalanceProps> = ({ record, source }) => {
  return (
    <span className="amount-price">
      {priceFormatter.format(record[source])}
    </span>
  );
};
