import moment from "moment";
import { ISortable, IStatus, ITask } from "../model";

// function that sorts ITask objects by createdAt property, most recent in front
export function sortByCreatedAtDesc(a: ITask, b: ITask) {
  return sortTimeDesc(moment(a.createdAt), moment(b.createdAt));
}

export function sortByCreatedAtAsc(a: ITask, b: ITask) {
  return sortTimeAsc(moment(a.createdAt), moment(b.createdAt));
}

export function sortByCreatedAtAscGeneric<T extends ISortable>(a: T, b: T) {
  return sortTimeAsc(moment(a.createdAt), moment(b.createdAt));
}

export function sortByDueAtAsc(a: ITask, b: ITask) {
  const momentA = moment(a.dueDate);
  const momentB = moment(b.dueDate);

  return sortTimeAsc(momentA, momentB);
}

export function sortByOrderAsc(a: IStatus, b: IStatus) {
  if (a.order < b.order) {
    return -1;
  } else if (a.order > b.order) {
    return 1;
  }
  return 0;
}

function sortTimeAsc(momentA: moment.Moment, momentB: moment.Moment) {
  if (momentA.isBefore(momentB)) {
    return -1;
  } else if (momentA.isAfter(momentB)) {
    return 1;
  } else {
    return 0;
  }
}

function sortTimeDesc(momentA: moment.Moment, momentB: moment.Moment) {
  if (momentA.isBefore(momentB)) {
    return 1;
  } else if (momentA.isAfter(momentB)) {
    return -1;
  } else {
    return 0;
  }
}
