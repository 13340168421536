import { Grid, makeStyles } from "@material-ui/core";
import { NumberInput } from "ra-ui-materialui";
import React from "react";
import { IInvoice } from "../../../model";
import { InvoiceBalancesDisplay } from "../../Invoice/InvoiceBalancesDisplay";

interface IWipAndExpenseField {
  invoice: IInvoice;
  disableEdit: boolean;
}

const useStyles = makeStyles({
  amount: { width: 256 },
  credit: { width: "auto" },
  creditGrid: { marginTop: 5, width: "auto" },
});

export const WipAndExpenseField: React.FC<IWipAndExpenseField> = ({
  invoice,
  disableEdit,
}) => {
  const classes = useStyles({});
  const { amtToBill, expenseToBill, wipBalance, expenseBalance } = invoice;
  const balanceChanged =
    amtToBill !== wipBalance || expenseToBill !== expenseBalance;

  return (
    <>
      <Grid container={true} className={classes.creditGrid}>
        <Grid item={true} xs={12} direction="column" className={classes.credit}>
          <NumberInput
            source="wipToCredit"
            label="WIP to Credit"
            className={classes.amount}
            disabled={disableEdit}
          />
          <InvoiceBalancesDisplay
            type="WIP"
            amountBilled={amtToBill}
            remainingBalance={wipBalance}
            showRemaining={balanceChanged}
          />
        </Grid>
        <Grid item={true} xs={12} direction="column" className={classes.credit}>
          <NumberInput
            source="expenseToCredit"
            label="Expense to Credit"
            className={classes.amount}
            disabled={disableEdit}
          />
          <InvoiceBalancesDisplay
            type="expense"
            amountBilled={expenseToBill}
            remainingBalance={expenseBalance}
            showRemaining={balanceChanged}
          />
        </Grid>
      </Grid>
    </>
  );
};
