import { CRUD_UPDATE } from "ra-core";
import { showNotification } from "react-admin";
import { eventChannel } from "redux-saga";
import { call, put, take, takeEvery } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import {
  subscribeToBankStatementConversion,
  updateBankStatementConversion,
} from "../actions";
import { Constants } from "../constants";
import { IBankStatementConversion } from "../model";
import { getCable } from "./actionCable";

const createBankStatementConversionChannel = (
  bankStatementConversionId: string
) =>
  eventChannel<ActionType<typeof updateBankStatementConversion>>((emit) => {
    const chan = getCable().subscriptions.create(
      {
        channel: "BankStatementConversionChannel",
        id: bankStatementConversionId,
      },
      {
        received: (bankStatementConversion: IBankStatementConversion) => {
          emit(updateBankStatementConversion(bankStatementConversion));
        },
      }
    );

    return chan.unsubscribe;
  });

export function* subscribeToBankStatementConversionChannel(
  action: ActionType<typeof subscribeToBankStatementConversion>
) {
  const previousBankStatementConversion = action.payload;
  const chan: ReturnType<typeof createBankStatementConversionChannel> = yield call(
    createBankStatementConversionChannel,
    previousBankStatementConversion.id
  );

  while (true) {
    try {
      const {
        payload: newBankStatementConversion,
      }: ActionType<typeof updateBankStatementConversion> = yield take(chan);
      const requestPayload = {
        id: previousBankStatementConversion.id,
        data: newBankStatementConversion,
        previousData: previousBankStatementConversion,
      };
      yield put({
        type: `${CRUD_UPDATE}_LOADING`,
        payload: requestPayload,
        meta: {
          resource: Constants.BANK_STATEMENT_CONVERSION_RESOURCE,
        },
      });

      yield put({
        type: `${CRUD_UPDATE}_SUCCESS`,
        payload: {
          data: newBankStatementConversion,
        },
        requestPayload: {
          id: previousBankStatementConversion.id,
          data: newBankStatementConversion,
          previousData: previousBankStatementConversion,
        },
        meta: {
          fetchResponse: "UPDATE",
          fetchStatus: "RA/FETCH_END",
          resource: Constants.BANK_STATEMENT_CONVERSION_RESOURCE,
          refresh: true,
        },
      });
      if (newBankStatementConversion.conversionStatus === "failure") {
        yield put(
          showNotification(
            "Failed to generate bank statement conversion.",
            "warning"
          )
        );
      }
    } catch (error) {
      console.error("bank statement conversion channel error", error);
      chan.close();
    }
  }
}

export function* bankStatementConversionSaga() {
  yield takeEvery(
    getType(subscribeToBankStatementConversion),
    subscribeToBankStatementConversionChannel
  );
}
