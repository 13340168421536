import { IEtsState, ISubtaskType } from "../../../model";
import { selectForModelById } from "../ModelSelect/ModelSelect";

export const SubtaskTypeSelect = selectForModelById(
  "subtask-type",
  (subtaskType: ISubtaskType) => {
    return {};
  },
  (subtaskType: ISubtaskType) => subtaskType.description,
  (_subtaskType: ISubtaskType) => null,
  (state: IEtsState) => state.subtaskTypesById
);

export const CurrentUserSubtaskTypeSelect = selectForModelById(
  "current-user-subtask-type",
  (subtaskType: ISubtaskType) => {
    return {};
  },
  (subtaskType: ISubtaskType) => subtaskType.description,
  (_subtaskType: ISubtaskType) => null,
  (state: IEtsState) => state.subtaskTypesById,
  () => null,
  (state: IEtsState, subtaskType: ISubtaskType) => {
    return subtaskType.createdById === state.auth.user.id;
  }
);
