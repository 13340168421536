import { Alignment, Checkbox } from "@blueprintjs/core";
import * as React from "react";
import { Key } from "ts-keycode-enum";
import { ITask } from "../../model";
import { IEditableCellProps } from "./types";

export class EditableBooleanCell extends React.Component<
  IEditableCellProps<ITask, boolean>
> {
  private dateInput;

  constructor(props: IEditableCellProps<ITask, boolean>) {
    super(props);

    this.dateInput = React.createRef();

    this.handleClick = this.handleClick.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
  }

  public shouldComponentUpdate(nextProps, _nextState) {
    return (
      this.props.cellInfo.value !== nextProps.cellInfo.value ||
      this.props.loading !== nextProps.loading
    );
  }

  public render(): JSX.Element {
    const { cellInfo } = this.props;
    return (
      <div className="horizontal-center">
        <Checkbox
          alignIndicator={Alignment.CENTER}
          checked={cellInfo.value}
          onChange={this.handleClick}
        />
      </div>
    );
  }

  private handleClick(event: React.FormEvent<HTMLInputElement>) {
    const id = this.props.cellInfo.original.id;
    const value = event.currentTarget.checked;
    this.props.onFinishEditing(id, value);
  }

  private handleInputKeyDown(e) {
    const ref = this.dateInput.current;
    if (e.which === Key.Tab && !e.shiftKey) {
      ref.setState({ ...ref.state, isOpen: false });
    }
  }
}
