import moment from "moment";
import { IEtsUser, ITimeEntry, ITimeEntryWithRate } from "../model";
import { durationToStringFormat } from "./date";
import { priceFormatter } from "./money";
import { getFullName } from "./user";

export const convertTimeEntries = (
  timeEntries: ITimeEntry[],
  users: { [id: string]: IEtsUser },
  isDaily: boolean,
  headers: string[]
) => {
  return timeEntries.map((timeEntry) => {
    const durationString = durationToStringFormat(timeEntry.duration, isDaily);

    return {
      "Date": moment(timeEntry.startDatetime).format("YYYY/MM/DD"),
      "Staff": getFullName(users[timeEntry.createdById]),
      "Code": timeEntry.code,
      "Category": timeEntry.category,
      "Subtask Description": timeEntry.subtaskDescription,
      "Description": timeEntry.description,
      "Is Chargeable": timeEntry.isChargeable,
      "Duration": durationString,
      "Timesheet": timeEntry.timesheetId == null ? "No" : "Yes",
    };
  });
};

export const convertWipEntries = (
  data: ITimeEntryWithRate[],
  isDaily: boolean
) => {
  return data.map((timeEntry) => {
    const durationString = durationToStringFormat(timeEntry.duration, isDaily);
    return {
      "Date": moment(timeEntry.startDatetime).format("YYYY/MM/DD"),
      "Created By": timeEntry.createdBy,
      "Category": timeEntry.category,
      "Duration": durationString,
      "Rate": priceFormatter.format(Number(timeEntry.rate)),
      "Amount": priceFormatter.format(Number(timeEntry.amount)),
      "Subtask Description": timeEntry.subtaskDescription,
      "Description": timeEntry.description,
      "Is Chargeable": timeEntry.isChargeable,
    };
  });
};
