import { createContext } from "react";
import { IAddTimeEntryAction, IAddTimeEntryState } from "./reducer";

export const AddTimeEntryStateContext = createContext<{
  state?: IAddTimeEntryState;
}>({});

export const AddTimeEntryDispatchContext = createContext<{
  dispatch?: React.Dispatch<IAddTimeEntryAction>;
}>({});
