import { IClientPortalAttachment } from "../model";

export const getFileDataByKey = (key: string) => {
  const result = { folder: "", fileName: key };
  const index = key.indexOf("/");
  if (index !== -1) {
    result.folder = key.substring(0, index + 1);
    result.fileName = key.split("/")[1];
  }

  return result;
};

export const getTransformedFiles = (data, dir) => {
  const files = [];
  if (data) {
    const folders = new Set();
    for (const [id, value] of Object.entries(data)) {
      const entry = value as IClientPortalAttachment;
      const key = entry.key;
      if (!key) {
        continue;
      }
      const { folder } = getFileDataByKey(key);
      if (folder && folder !== "" && !folders.has(folder)) {
        files.push({
          key: folder,
        });
        folders.add(folder);
      }
      files.push({
        id,
        key,
        size: entry.size,
        url: entry.fileUrl,
        attachmentId: entry.attachmentId,
        emailAttachmentId: entry.emailAttachmentId,
      });
    }

    files.push(...dir);
    return files;
  }
};

export const checkDuplicate = (path: string, transformedFiles) => {
  let duplicate = false;
  transformedFiles.forEach((file) => {
    if (path === file.key) {
      duplicate = true;
    }
  });
  return duplicate;
};

export const getFolders = (transformedFiles) => {
  const folders = new Set();
  transformedFiles.forEach((file) => {
    const { folder } = getFileDataByKey(file.key);
    if (folder !== "") {
      folders.add(folder);
    }
  });
  return folders;
};

export const getFileByKey = (oldKey: string, rawFiles) => {
  for (const [id, value] of Object.entries(rawFiles)) {
    const entry = value as IClientPortalAttachment;
    if (oldKey === entry.key) {
      return entry;
    }
  }
  return null;
};

export const getFilesByKeys = (oldKeys: string[], rawFiles) => {
  return oldKeys.map((key) => {
    return getFileByKey(key, rawFiles);
  });
};

export const isInsideFolder = (key: string) => {
  const { folder, fileName } = getFileDataByKey(key);
  if (folder !== "" && fileName) {
    return true;
  } else {
    return false;
  }
};

export const getFilesByFolder = (folder: string, rawFiles) => {
  const files = [];
  for (const [id, value] of Object.entries(rawFiles)) {
    const entry = value as IClientPortalAttachment;
    if (isInsideFolder(entry.key)) {
      const folderKey = entry.key.split("/")[0] + "/";
      if (folderKey === folder) {
        files.push(entry);
      }
    }
  }
  return files;
};

export const findFileByType = (type: string, id: string, transformedFiles) => {
  if (!transformedFiles) {
    return null;
  }

  switch (type) {
    case "timeline":
      return transformedFiles.find((file) => {
        return file.attachmentId === id;
      });
    case "email":
      return transformedFiles.find((file) => {
        return file.emailAttachmentId === id;
      });
  }
};

export const getAttachmentIdentifier = (type: string) => {
  switch (type) {
    case "timeline":
      return "attachmentId";
    case "email":
      return "emailAttachmentId";
  }
};
