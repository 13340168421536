import { Icon, Tag } from "@blueprintjs/core";
import * as React from "react";
import { ReferenceField } from "react-admin";
import { Constants } from "../../constants";
import { IAttachmentSortable } from "../../model";
import { formatDate, parseDate } from "../../utils/date";
import { CreatedBy } from "./CreatedBy";

interface ITimelineAttachmentProps {
  record: IAttachmentSortable;
  parentEntryType: string;
  startSharingAttachment: () => void;
}

export const Attachment = ({
  record,
  parentEntryType,
  startSharingAttachment,
}: ITimelineAttachmentProps) => {
  const { createdAt, fileName, fileUrl, id, isShared } = record;
  const date = formatDate(parseDate(createdAt));

  return (
    <div className="timeline-changelog-wrapper" key={id}>
      <div className="timeline-commit-icon">
        <Icon icon="git-commit" />
      </div>
      <ReferenceField
        record={record}
        source="createdById"
        basePath={Constants.ATTACHMENT_RESOURCE}
        reference={Constants.USER_RESOURCE}
      >
        <CreatedBy />
      </ReferenceField>
      <div className="timeline-changelog-content">
        {`attached `}
        <Tag>
          {fileUrl !== "" ? (
            <a href={fileUrl} className="timeline-attachment-url">
              <Icon iconSize={12} icon="cloud-download" /> {fileName}
            </a>
          ) : (
            <span>
              <Icon iconSize={12} icon="cross" intent="danger" /> {fileName}
            </span>
          )}
        </Tag>{" "}
        on {date}
        {parentEntryType !== "ClientGroup" && (
          <Icon
            className="attachment-button"
            icon={isShared ? "tick-circle" : "share"}
            iconSize={12}
            onClick={startSharingAttachment}
          />
        )}
      </div>
    </div>
  );
};
