import { useDataProvider, useRefresh } from "ra-core";
import { useCallback } from "react";

export const useArchiveNotificationsOnClick = () => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  return useCallback(
    (notification_ids: string[]) => {
      const act = async () => {
        await dataProvider.archiveNotifications({ notification_ids });
        refresh();
      };
      act();
    },
    [dataProvider]
  );
};

export const useArchiveNotificationsByGroupOnClick = () => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  return useCallback(
    (group_id: string) => {
      const act = async () => {
        await dataProvider.archiveNotificationsByGroup({ group_id });
        refresh();
      };
      act();
    },
    [dataProvider]
  );
};
