import { GET_LIST, Pagination, Sort } from "ra-core";

export const CUSTOM_QUERY = "CUSTOM_QUERY";

interface IRequestPayload {
  pagination: Pagination;
  sort: Sort;
  filter: object;
}

interface IGetListAction {
  readonly type: typeof CUSTOM_QUERY;
  readonly payload: IRequestPayload;
  readonly meta: {
    resource: string;
    fetch: typeof GET_LIST;
  };
}

export const getList = (
  resource: string,
  pagination: Pagination,
  sort: Sort,
  filter: object
): IGetListAction => ({
  type: "CUSTOM_QUERY",
  payload: {
    pagination,
    sort,
    filter,
  },
  meta: { resource, fetch: GET_LIST },
});
