import * as qs from "qs";
import { required, useGetOne } from "ra-core";
import {
  Create,
  DateInput,
  ReferenceField,
  SelectInput,
  SimpleForm,
  TextInput,
} from "ra-ui-materialui";
import * as React from "react";
import { Constants } from "../../constants";
import { IInvoice } from "../../model";
import { PostCreateToolbar } from "../CreateToolBar";
import { ClientCompanyField, FeeNoteField, TaskField } from "../fields";
import { PaymentBalance } from "./PaymentBalance";
import { paymentTypes } from "./paymentTypes";

const initialValues = { date: new Date() };

export const PaymentCreate: React.FC<any> = (props) => {
  const { invoiceId } = qs.parse(props.location.search.slice(1));
  const { data } = useGetOne(Constants.INVOICE_RESOURCE, invoiceId as string);
  const invoice = data as IInvoice;
  if (!invoice) {
    return null;
  }
  const referenceRecord = { invoiceId, taskId: invoice.taskId };
  return (
    <Create {...props} location={{}}>
      <SimpleForm
        initialValues={{ ...initialValues, invoiceId, taskId: invoice.taskId }}
        toolbar={<PostCreateToolbar />}
      >
        <>
          <ReferenceField
            source="taskId"
            reference={Constants.TASK_RESOURCE}
            addLabel={false}
            link={false}
            record={referenceRecord}
            basePath={Constants.PAYMENT_PATH}
            resource={Constants.PAYMENT_RESOURCE}
          >
            <TaskField />
          </ReferenceField>
          <ReferenceField
            source="taskId"
            reference={Constants.TASK_RESOURCE}
            addLabel={false}
            link={false}
            record={referenceRecord}
            basePath={Constants.PAYMENT_PATH}
            resource={Constants.PAYMENT_RESOURCE}
          >
            <ClientCompanyField />
          </ReferenceField>
          <ReferenceField
            source="invoiceId"
            reference={Constants.INVOICE_RESOURCE}
            addLabel={false}
            record={referenceRecord}
            basePath={Constants.PAYMENT_PATH}
            resource={Constants.PAYMENT_RESOURCE}
          >
            <FeeNoteField />
          </ReferenceField>
        </>
        <DateInput source="date" />
        <PaymentBalance
          wipBalance={invoice.wipBalance}
          expenseBalance={invoice.expenseBalance}
          originalWip={invoice.amtToBill}
          originalExpense={invoice.expenseToBill}
          withAutofillOption={true}
        />
        <SelectInput
          source="paymentType"
          label="Payment type"
          choices={paymentTypes}
          validate={[required()]}
        />
        <TextInput
          label="Internal Remarks"
          source="internalRemarks"
          multiline={true}
          rows="4"
        />
      </SimpleForm>
    </Create>
  );
};
