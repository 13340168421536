import { Button, Card } from "@blueprintjs/core";
import _ from "lodash";
import { useNotify, useRefresh } from "ra-core";
import React, { useState } from "react";
import { NaturalPersonCreateDialog } from "../../components/dialog/NaturalPersonCreateDialog";
import { ClientGroupNaturalPerson } from "./ClientGroupNaturalPerson";

interface IClientGroupNaturalPersonsProps {
  clientGroupId: string;
  data?: any;
}

export const ClientGroupNaturalPersons: React.FC<IClientGroupNaturalPersonsProps> = ({
  clientGroupId,
  data: naturalPersons = {},
}) => {
  const [isCreatingNaturalPerson, setIsCreatingNaturalPerson] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const startCreatingNaturalPerson = () => {
    setIsCreatingNaturalPerson(true);
  };

  const finishCreatingNaturalPerson = () => {
    setIsCreatingNaturalPerson(false);
  };

  const handleCreateNaturalPersonSuccess = () => {
    notify("ra.notification.created", "info", { smart_count: 1 });
    refresh();
    finishCreatingNaturalPerson();
  };

  return (
    <>
      <Card className="natural-persons-card">
        <div>
          <Button
            icon="add"
            text="Add Natural Person"
            onClick={startCreatingNaturalPerson}
            className="create-client-user-button"
          />
          {_.values(naturalPersons).map((person, index) => (
            <ClientGroupNaturalPerson key={index} naturalPerson={person} />
          ))}
        </div>
      </Card>
      <NaturalPersonCreateDialog
        isOpen={isCreatingNaturalPerson}
        onClose={finishCreatingNaturalPerson}
        canOutsideClickClose={true}
        clientGroupId={clientGroupId}
        onSuccess={handleCreateNaturalPersonSuccess}
      />
    </>
  );
};
