import * as React from "react";
import { connect } from "react-redux";
import { Api } from "../../actions";
import { IEtsState, IKanbanProps, IKanbanState } from "../../model";
import { taskKanbanBoard } from "../../selectors";
import { Kanban } from "./Kanban";

export class TaskKanbanImpl extends React.Component<
  IKanbanProps,
  IKanbanState
> {
  constructor(props) {
    super(props);
  }

  public render(): JSX.Element {
    const { columnList, model } = this.props;
    return (
      <div>
        <Kanban columnList={columnList} model={model} />
      </div>
    );
  }
}

function mapStateToProps() {
  const taskKanbanBoardList = taskKanbanBoard();

  return (state: IEtsState): IKanbanProps => {
    return {
      columnList: taskKanbanBoardList(state),
      model: Api.Task,
    };
  };
}

export const TaskKanban = connect(mapStateToProps)(TaskKanbanImpl);
