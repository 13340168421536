import { TableCell } from "@material-ui/core";
import React from "react";
import { DurationInput } from "../../../inputs";

interface ITimesheetTimeEntryTimeInputProps {
  date: Date;
  duration: string;
  disabled: boolean;
  onChange: (date: Date, duration: string) => void;
}

export const TimesheetTimeEntryTimeInput: React.FC<ITimesheetTimeEntryTimeInputProps> = ({
  date,
  duration,
  disabled,
  onChange,
}) => {
  const handleOnChange = (newDuration: string) => {
    onChange(date, newDuration);
  };

  return (
    <TableCell className="timesheet-duration-box" colSpan={1}>
      <div className="timesheet-duration-time-box">
        <DurationInput
          disabled={disabled}
          duration={duration}
          onChange={handleOnChange}
        />
      </div>
    </TableCell>
  );
};
