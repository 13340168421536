export const fuseJsOptionConfig = (keys: string[]) => {
  return {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys,
  };
};
