import {
  Datagrid,
  DateField,
  ReferenceField,
  TextField,
} from "ra-ui-materialui";
import * as React from "react";
import { Pagination } from "react-admin";
import {
  FormGroupResponseLink,
  FormGroupResponseStatusField,
  FormResponseExpand,
} from "../../components";
import { Constants } from "../../constants";
import { IFormGroupResponse } from "../../model";
import { ReferenceManyList } from "../../ReferenceManyList";
import { FormGroupResponseAutofillDownloadButton } from "./FormGroupResponseAutofillDownloadButton";
import { FormGroupResponseDownloadButton } from "./FormGroupResponseDownloadButton";
import { FormResponseFilter } from "./FormResponseFilter";

export const FormResponseReferenceList: React.FC<any> = (props) => {
  const clientGroupId = props.record.id;
  return (
    <ReferenceManyList
      {...props}
      reference={Constants.FORM_GROUP_RESPONSE_RESOURCE}
      target="client_group"
      perPage={5}
      pagination={<ListPagination />}
      sort={{ field: "created_at", order: "desc" }}
      filters={<FormResponseFilter />}
      bulkActionButtons={false}
      actions={false}
      exporter={false}
      filter={{ clientGroupId }}
    >
      <Datagrid rowClick="expand" expand={<FormResponseExpand />}>
        <DateField source="createdAt" label="Date" />
        <ReferenceField
          label="Client User"
          source="clientUserId"
          reference={Constants.CLIENT_USER_RESOURCE}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Form"
          source="formGroupId"
          reference={Constants.FORM_GROUPS_RESOURCE}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <CurrentFormField label="Current Form" />
        <FormGroupResponseStatusField label="Status" />
        <FormGroupResponseDownloadButton />
        <FormGroupResponseAutofillDownloadButton label="AutoFill PDF" />
        <FormGroupResponseLink label="Link" />
      </Datagrid>
    </ReferenceManyList>
  );
};

const ListPagination: React.FC = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

interface ICurrentFormFieldProps {
  record?: IFormGroupResponse;
  label?: string;
}
const CurrentFormField: React.FC<ICurrentFormFieldProps> = ({ record }) => {
  if (!record) {
    return null;
  }
  return <span>{record.currentForm.name}</span>;
};
