import { makeStyles } from "@material-ui/core";
import * as jsonExport from "jsonexport/dist";
import moment from "moment";
import React from "react";
import {
  Datagrid,
  DateField,
  downloadCSV,
  List,
  ReferenceField,
  TextField,
} from "react-admin";
import { ReportRole } from "../../constants/reportRoles";
import { useReportParams } from "../../hooks/useReportParams";
import { priceFormatter } from "../../utils/money";
import { DateFilter } from "../DepartmentAccountBalance/DateFilter";
import { PriceField, UserField } from "../fields";
import { ApprovalStatusSelectArrayInput } from "../inputs";
import { BalanceFilterInput } from "../inputs/BalanceFilterInput";
import { BalanceField } from "../Invoice/BalanceField";
import { TotalField } from "./TotalField";

const filterDefaultValues = {
  startDate: moment().startOf("M").toDate(),
  endDate: moment().endOf("D").toDate(),
  approvalStatus: [
    "draft",
    "need_eic_approval",
    "need_pic_approval",
    "approved",
  ],
};

const useStyle = makeStyles({
  container: { marginTop: "30px" },
});

const exporter = (records) => {
  const data = records.map((record) => {
    const {
      wipCreditNote,
      wipPayment,
      expenseCreditNote,
      expensePayment,
      wipBalance,
      expenseBalance,
    } = record;
    return {
      "Issue Date": record.date,
      "Fee Note": record.feeNoteNumber,
      "Department Name": record.departmentName,
      "Billing Entity": record.templateName,
      "Client Company Name": record.clientCompanyName,
      "Eic Name": record.eic,
      "Pic Name": record.pic,
      "Total On Fee Note": priceFormatter.format(record.amountOnFeeNote),
      "Wip Billed": priceFormatter.format(record.amtToBill),
      "Credit Note_wip": priceFormatter.format(wipCreditNote),
      "Payment Wip": priceFormatter.format(wipPayment),
      "Balance Wip": priceFormatter.format(wipBalance),
      "Wip Settled": priceFormatter.format(record.amtToSettle),
      "Expense Billed": priceFormatter.format(record.expenseToBill),
      "Expense Settled": priceFormatter.format(record.expenseToSettle),
      "Credit Note Expense": priceFormatter.format(expenseCreditNote),
      "Payment Expense": priceFormatter.format(expensePayment),
      "Balance Expense": priceFormatter.format(expenseBalance),
    };
  });

  jsonExport(data, (err, csv) => {
    downloadCSV(csv, "fee_notes_lists");
  });
};

export const FeeNotesList: React.FC<any> = (props) => {
  const classes = useStyle();

  const filterParams = useReportParams(
    ReportRole.FEE_NOTES_LIST_DEPARTMENT,
    filterDefaultValues
  );

  return (
    <List
      {...props}
      filters={
        <DateFilter
          reportType={ReportRole.FEE_NOTES_LIST_TENANT}
          approvalStatusSelectArrayInput={
            <ApprovalStatusSelectArrayInput
              label="Approval statuses"
              source="approvalStatus"
            />
          }
          balanceFilterInput={
            <BalanceFilterInput
              label="Total Balance More Than"
              source="balanceAmount"
            />
          }
        />
      }
      bulkActionButtons={false}
      exporter={exporter}
      filterDefaultValues={filterParams}
      className={classes.container}
    >
      <Datagrid>
        <DateField source="date" label="Issue Date" sortable={false} />
        <TextField source="feeNoteNumber" label="Fee Note #" sortable={false} />
        <TextField
          source="templateName"
          label="Billing Entity"
          sortable={false}
        />
        <TextField
          source="departmentName"
          label="Department"
          sortable={false}
        />
        <ReferenceField
          label="Client Company"
          source="clientCompanyId"
          reference="client_companies"
          link={false}
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <UserField label="PIC" source="picId" />
        <UserField label="EIC" source="eicId" />
        <PriceField
          label="Fee Note Total"
          source="amountOnFeeNote"
          sortable={false}
        />
        <PriceField label="WIP Billed" source="amtToBill" sortable={false} />
        <PriceField label="WIP Settled" source="amtToSettle" sortable={false} />
        <TotalField label="WIP (Credit Note)" source="wipCreditNote" />
        <TotalField label="WIP (Payment)" source="wipPayment" />
        <BalanceField label="WIP Balance" source="wipBalance" />
        <PriceField
          label="Expense Billed"
          source="expenseToBill"
          sortable={false}
        />
        <PriceField
          label="Expense Settled"
          source="expenseToSettle"
          sortable={false}
        />
        <TotalField label="Expense (Credit Note)" source="expenseCreditNote" />
        <TotalField label="Expense (Payment)" source="expensePayment" />
        <BalanceField label="Expense Balance" source="expenseBalance" />
      </Datagrid>
    </List>
  );
};
