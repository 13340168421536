import React, { FC } from "react";
import { ReferenceField, TextInput } from "react-admin";
import { Constants } from "../../constants";
import { IClientCompany, IInvoice } from "../../model";

interface IContactInputProps {
  record?: IInvoice;
}

interface IContactTextInputProps {
  record?: IClientCompany;
  invoice: IInvoice;
}

const ContactTextInput: FC<IContactTextInputProps> = ({ record, invoice }) => {
  return (
    <TextInput
      resource={Constants.INVOICE_RESOURCE}
      source="contact"
      record={invoice}
      allowEmpty={true}
      initialValue={record.contactName}
    />
  );
};

export const ContactInput: FC<IContactInputProps> = ({ record }) => {
  return (
    <ReferenceField
      source="taskId"
      reference={Constants.TASK_RESOURCE}
      addLabel={false}
      link={false}
      record={record}
      resource={Constants.INVOICE_RESOURCE}
      basePath="app/invoices"
    >
      <ReferenceField
        resource={Constants.TASK_RESOURCE}
        reference={Constants.CLIENT_COMPANY_RESOURCE}
        source="clientCompanyId"
        basePath="app/tasks"
        link={false}
      >
        <ContactTextInput invoice={record} />
      </ReferenceField>
    </ReferenceField>
  );
};
