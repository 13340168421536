import { INotification } from "./INotification";

export enum NotificationMode {
  Full,
  Short,
}

export interface INotificationProps {
  notification: INotification;
  displayArchiveButton?: boolean;
  notificationOnClick?: (notification: INotification) => void;
  archiveNotificationOnClick?: (notifications: string[]) => void;
  mode?: NotificationMode;
}
