import moment from "moment";
import { CreateButton } from "ra-ui-materialui";
import React from "react";

const startDate = encodeURIComponent(
  moment().startOf("week").add(1, "d").toISOString()
);
const endDate = encodeURIComponent(
  moment().endOf("week").add(1, "d").toISOString()
);

interface ITimesheetCreateButtonProps {
  basePath: string;
  empty?: boolean;
}

export const TimesheetCreateButton: React.FC<ITimesheetCreateButtonProps> = ({
  basePath,
  empty = true,
}) => {
  return (
    <CreateButton
      variant={empty ? "contained" : "text"}
      to={`${basePath}/create?startDatetime=${startDate}&endDatetime=${endDate}`}
    />
  );
};
