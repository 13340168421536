import { Constants } from "../constants";

export const typeToResource = (type: string): string => {
  switch (type) {
    case "Task":
      return Constants.TASK_RESOURCE;
    case "ClientCompany":
      return Constants.CLIENT_COMPANY_RESOURCE;
    case "ClientGroup":
      return Constants.CLIENT_GROUP_RESOURCE;
  }
};
