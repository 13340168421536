import { InputGroup } from "@blueprintjs/core";
import { Constants } from "../constants/index";
import { IDepartmentSpecificFieldConfig } from "../model";
import {
  BOOLEAN_EDIT_CELL_DEFAULT_PROPS,
  DATE_INPUT_EDIT_CELL_DEFAULT_PROPS,
  TEXT_INPUT_EDIT_CELL_DEFAULT_PROPS,
} from "./defaultProps";

export const jckAuditTaskFields: IDepartmentSpecificFieldConfig[] = [
  {
    name: "Tax Deadline",
    field: "taxDeadline",
    belongsTo: Constants.DEPARTMENT_KEY,
    ...DATE_INPUT_EDIT_CELL_DEFAULT_PROPS,
  },
  {
    name: "Tax Typed On",
    field: "taxTypedOn",
    belongsTo: Constants.DEPARTMENT_KEY,
    ...DATE_INPUT_EDIT_CELL_DEFAULT_PROPS,
  },
  {
    name: "Tax Filed On",
    field: "taxFiledOn",
    belongsTo: Constants.DEPARTMENT_KEY,
    ...DATE_INPUT_EDIT_CELL_DEFAULT_PROPS,
  },
  {
    name: "Tax Bill Received On",
    field: "taxBillReceivedOn",
    belongsTo: Constants.DEPARTMENT_KEY,
    ...DATE_INPUT_EDIT_CELL_DEFAULT_PROPS,
  },
  {
    name: "Tax Bill at JCK?",
    field: "isTaxBillAtJck",
    width: 145,
    belongsTo: Constants.DEPARTMENT_KEY,
    ...BOOLEAN_EDIT_CELL_DEFAULT_PROPS,
  },
  {
    name: "Agree with Return?",
    field: "isAgreeWithReturn",
    width: 170,
    belongsTo: Constants.DEPARTMENT_KEY,
    ...BOOLEAN_EDIT_CELL_DEFAULT_PROPS,
  },
  {
    name: "First Payment Date",
    field: "firstPaymentDate",
    belongsTo: Constants.DEPARTMENT_KEY,
    ...DATE_INPUT_EDIT_CELL_DEFAULT_PROPS,
  },
  {
    name: "First Payment Amount",
    field: "firstPaymentAmount",
    width: 150,
    belongsTo: Constants.DEPARTMENT_KEY,
    ...TEXT_INPUT_EDIT_CELL_DEFAULT_PROPS,
  },
  {
    name: "Second Payment Date",
    field: "secondPaymentDate",
    belongsTo: Constants.DEPARTMENT_KEY,
    ...DATE_INPUT_EDIT_CELL_DEFAULT_PROPS,
  },
  {
    name: "Second Payment Amount",
    field: "secondPaymentAmount",
    width: 150,
    belongsTo: Constants.DEPARTMENT_KEY,
    ...TEXT_INPUT_EDIT_CELL_DEFAULT_PROPS,
  },
  {
    name: "Holdover Apply?",
    field: "isHoldoverApply",
    width: 150,
    belongsTo: Constants.DEPARTMENT_KEY,
    ...BOOLEAN_EDIT_CELL_DEFAULT_PROPS,
  },
  {
    name: "Letter to IRD?",
    field: "isLetterToIrd",
    width: 150,
    belongsTo: Constants.DEPARTMENT_KEY,
    ...BOOLEAN_EDIT_CELL_DEFAULT_PROPS,
  },
  {
    name: "Agreed By IRD?",
    field: "isAgreedByIrd",
    width: 150,
    belongsTo: Constants.DEPARTMENT_KEY,
    ...BOOLEAN_EDIT_CELL_DEFAULT_PROPS,
  },
  {
    name: "Year End Date",
    field: "yearEndDate",
    width: 150,
    belongsTo: Constants.DEPARTMENT_KEY,
    ...DATE_INPUT_EDIT_CELL_DEFAULT_PROPS,
  },
  {
    name: "Source Of Accounts",
    field: "sourceOfAccounts",
    width: 150,
    belongsTo: Constants.DEPARTMENT_KEY,
    ...TEXT_INPUT_EDIT_CELL_DEFAULT_PROPS,
  },
];

export const jckAuditCompanyFields: IDepartmentSpecificFieldConfig[] = [
  {
    name: "Accountant Name",
    field: `acountantName${Constants.DEPARTMENT_KEY}`,
    CreateComponent: InputGroup,
    belongsTo: Constants.DEPARTMENT_KEY,
  },
  {
    name: "Tax Code",
    field: `taxCode${Constants.DEPARTMENT_KEY}`,
    CreateComponent: InputGroup,
    belongsTo: Constants.DEPARTMENT_KEY,
  },
];
