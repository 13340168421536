import { SubscriptionCheckoutDialog } from "@app/components/SubscriptionCheckout/SubscriptionCheckoutDialog";
import {
  Alignment,
  Button,
  Classes,
  Intent,
  Menu,
  Navbar,
  Popover,
  Position,
  ProgressBar,
} from "@blueprintjs/core";
import { AuthenticatedContainer } from "@chiubaka/core";
import { Box } from "@material-ui/core";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import classNames from "classnames";
import { push } from "connected-react-router";
import moment from "moment";
import { Notification } from "ra-ui-materialui";
import * as React from "react";
import { AdminContext, Resource } from "react-admin";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Api } from "../actions";
import {
  AmountToBillList,
  ClientUserCreate,
  CloneTaskButton,
  CloneTasksDialog,
  CompanyDialog,
  CompanyList,
  CompanyShow,
  CreditNoteCreate,
  CreditNoteEdit,
  CreditNoteList,
  CustomReport,
  DepartmentsAccountBalancesList,
  ExpenseCreate,
  ExpenseEdit,
  ExpenseList,
  FeeNotesList,
  HourByJobList,
  HourByUserList,
  InvoiceCreate,
  InvoiceEdit,
  InvoiceList,
  NotificationTaskList,
  PaymentCreate,
  PaymentEdit,
  PaymentList,
  PicAccountBalancesList,
  SearchInput,
  Sidebar,
  SwitchUser,
  TaskAccountBalanceList,
  TaskDialog,
  TimesheetCreate,
  TimesheetEdit,
  TimesheetList,
  WriteOffCreate,
  WriteOffEdit,
  WriteOffList,
} from "../components";
import { NotificationButton } from "../components/NotificationButton/NotificationButton";
import { Constants } from "../constants";
import { ApiAction, IAuthInnerState } from "../core";
import { i18nIntlProvider } from "../i18n/i18Provider";
import { IClientCompany, IEtsState, ITask } from "../model";
import { ClientGroupDetail, CompanyDetail } from "../pages";
import { canCreateCompanies, canCreateTasks } from "../selectors";
import { authProvider, history } from "../store";
import { Dispatch } from "../types";
import { renderLogo } from "../utils/logo";
import { AppRouter } from "./AppRouter";
import { Intl } from "./Intl";

interface IAppDispatchProps {
  loadAppState: (departmentId: string) => Promise<any>;
  onTaskCreate: (task: ITask) => void;
  onCompanyCreate: (company: IClientCompany) => void;
}

interface IAppStateProps {
  auth: IAuthInnerState;
  loggedIn: boolean;
  productName: string;
  logoPath: string;
  cloneTasksDialogIsOpen: boolean;
  enableCompanyCreation: boolean;
  enableTaskCreation: boolean;
  departmentId?: string;
}

interface IAppOwnProps {
  dataProvider: any;
  authProvider: any;
}

interface IAppProps
  extends IAppOwnProps,
    IAppStateProps,
    IAppDispatchProps,
    RouteComponentProps<any> {}

interface IAppState {
  loading: boolean;
  creatingTask: boolean;
  creatingCompany: boolean;
  showNotifications: boolean;
  showSidebar: boolean;
}

class AppImpl extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);

    this.state = {
      loading: true,
      creatingTask: false,
      creatingCompany: false,
      showSidebar: true,
      showNotifications: false,
    };

    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.startCreatingTask = this.startCreatingTask.bind(this);
    this.finishCreatingTask = this.finishCreatingTask.bind(this);
    this.startCreatingCompany = this.startCreatingCompany.bind(this);
    this.finishCreatingCompany = this.finishCreatingCompany.bind(this);
  }

  public async componentDidMount() {
    if (this.props.loggedIn) {
      await this.props.loadAppState(this.props.departmentId);
      this.setState({ ...this.state, loading: false });
    }
  }

  public setLoading = (isLoading: boolean) => {
    this.setState({ ...this.state, loading: isLoading });
  };

  public render(): JSX.Element {
    if (this.state.loading) {
      return (
        <AuthenticatedContainer id="app" {...this.props}>
          <ProgressBar />
          <div className="loading-container">
            <div className="loading-icon-container">
              <img
                className="loading-icon-img"
                height="160"
                width="180"
                src={
                  "https://easytrack.sgp1.cdn.digitaloceanspaces.com/loading-image.gif"
                }
              />
              <span className="loading-text">
                {" "}
                Preparing the EasyTrack experience...
              </span>
            </div>
          </div>
        </AuthenticatedContainer>
      );
    }

    const {
      enableCompanyCreation,
      enableTaskCreation,
      dataProvider,
    } = this.props;

    const additionalNavbarItems = (
      <Menu>
        <Button
          className="create-company"
          disabled={!enableCompanyCreation}
          icon="office"
          text="Add Company"
          minimal={true}
          onClick={this.startCreatingCompany}
        />
      </Menu>
    );

    return (
      <ThemeProvider theme={createMuiTheme()}>
        <AuthenticatedContainer id="app">
          <AdminContext
            authProvider={authProvider}
            dataProvider={dataProvider}
            i18nProvider={i18nIntlProvider}
            history={history}
          >
            <Intl>
              {this.props.auth.user.userTrialOver && (
                <SubscriptionCheckoutDialog />
              )}
              <Box displayPrint="none" component="div">
                <Navbar
                  className={classNames("navbar", "bp3-dark", "d-print-none")}
                >
                  <Navbar.Group align={Alignment.LEFT}>
                    <Button
                      className="menu-button"
                      icon="menu"
                      minimal={true}
                      tabIndex={-1}
                      onClick={this.toggleSidebar}
                    />
                    {renderLogo(this.props.logoPath)}
                    <span className="navbar-product-name">
                      {this.props.productName}
                    </span>
                  </Navbar.Group>
                  <Navbar.Group align={Alignment.RIGHT}>
                    <Button
                      className="create-task"
                      disabled={!enableTaskCreation}
                      icon="add"
                      intent={Intent.PRIMARY}
                      text="Job"
                      onClick={this.startCreatingTask}
                    />
                    <CloneTaskButton />
                    <Popover
                      className="additional-navbar-items"
                      content={additionalNavbarItems}
                      position={Position.BOTTOM_RIGHT}
                      boundary="viewport"
                    >
                      <Button text="..." />
                    </Popover>
                    <SearchInput />
                    <NotificationButton />
                    <SwitchUser authState={this.props.auth} />
                    <Link to="/auth/logout">
                      <Button
                        className={Classes.MINIMAL}
                        icon="log-out"
                        tabIndex={-1}
                      />
                    </Link>
                  </Navbar.Group>
                </Navbar>
              </Box>
              <main>
                {this.state.showSidebar ? <Sidebar /> : null}
                <div className="content">
                  <TaskDialog
                    isOpen={this.state.creatingTask}
                    onClose={this.finishCreatingTask}
                    afterSubmit={this.props.onTaskCreate}
                  />
                  <CompanyDialog
                    isOpen={this.state.creatingCompany}
                    onClose={this.finishCreatingCompany}
                    afterSubmit={this.props.onCompanyCreate}
                  />
                  <CloneTasksDialog
                    isOpen={this.props.cloneTasksDialogIsOpen}
                  />
                  <Resource name="users" intent="registration" />
                  <Resource name="client_groups" intent="registration" />
                  <Resource name="invoice_templates" intent="registration" />
                  <Resource name="interview_templates" intent="registration" />
                  <Resource
                    name={Constants.TASK_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.TASK_TYPE_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.ATTACHMENT_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.EMAIL_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.ESTIMATE_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.EXPENSE_BALANCE_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.RATE_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.USER_RATE_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.BALANCE_ENTRY_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.NOTIFICATIONS_RESOURCE}
                    intent="registration"
                    list={NotificationTaskList}
                  />
                  <Resource
                    name={Constants.ACTIVITY_FEED_NOTIFICATIONS_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={
                      Constants.ACTIVITY_FEED_NOTIFICATIONS_BY_GROUP_RESOURCE
                    }
                    intent="registration"
                  />
                  <Resource
                    name={Constants.CLIENT_COMPANY_RESOURCE}
                    intent="registration"
                    list={CompanyList}
                    show={CompanyShow}
                    edit={CompanyDetail}
                  />
                  <Resource
                    name={Constants.EXPENSE_ENTRY_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.ACCOUNT_BALANCE_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.TIMESHEET_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.STATUS_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.VERSION_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.CLIENT_GROUP_RESOURCE}
                    intent="registration"
                    edit={ClientGroupDetail}
                  />
                  <Resource
                    name={Constants.DEPARTMENTS_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.PARENT_LIST_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.CLIENT_PORTAL_ATTACHMENT_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.TASKS_ACCOUNT_BALANCE_RESOURCE}
                    intent="registration"
                    list={TaskAccountBalanceList}
                  />
                  <Resource
                    name={Constants.CHAT_THREAD_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.EXPENSE_RESOURCE}
                    intent="registration"
                    list={ExpenseList}
                    create={ExpenseCreate}
                    edit={ExpenseEdit}
                  />
                  <Resource
                    name={Constants.CUSTOM_REPORT_RESOURCE}
                    intent="registration"
                    list={CustomReport}
                  />
                  <Resource
                    name={Constants.TIME_ENTRY_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.ESTIMATED_TIME_ENTRY_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.CLIENT_USER_RESOURCE}
                    create={ClientUserCreate}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.LOGIN_LINK_GENERATOR_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.TIMESHEET_RESOURCE}
                    intent="registration"
                    list={TimesheetList}
                    create={TimesheetCreate}
                    edit={TimesheetEdit}
                  />
                  <Resource
                    name="credit_notes"
                    intent="registration"
                    list={CreditNoteList}
                    create={CreditNoteCreate}
                    edit={CreditNoteEdit}
                  />
                  <Resource
                    name={Constants.PAYMENT_RESOURCE}
                    intent="registration"
                    list={PaymentList}
                    create={PaymentCreate}
                    edit={PaymentEdit}
                  />
                  <Resource
                    name={Constants.INVOICE_RESOURCE}
                    intent="registration"
                    create={InvoiceCreate}
                    list={InvoiceList}
                    edit={InvoiceEdit}
                  />
                  <Resource
                    name={Constants.AMOUNT_TO_BILL_RESOURCE}
                    intent="registration"
                    list={AmountToBillList}
                  />
                  <Resource
                    name={Constants.WRITE_OFF_RESOURCE}
                    intent="registration"
                    list={WriteOffList}
                    create={WriteOffCreate}
                    edit={WriteOffEdit}
                  />
                  <Resource
                    name={Constants.DEPARTMENTS_ACCOUNT_BALANCE_RESOURCE}
                    intent="registration"
                    list={DepartmentsAccountBalancesList}
                  />
                  <Resource
                    name={Constants.PIC_ACCOUNT_BALANCE_RESOURCE}
                    intent="registration"
                    list={PicAccountBalancesList}
                  />
                  <Resource
                    name={Constants.FEE_NOTES_LIST_RESOURCE}
                    intent="registration"
                    list={FeeNotesList}
                  />
                  <Resource
                    name={Constants.CREDIT_NOTE_LIST_RESOURCE}
                    intent="registration"
                    list={FeeNotesList}
                  />
                  <Resource
                    name={Constants.ADJUSTMENT_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.LINE_ITEM_TEMPLATE_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.LINE_ITEM_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.FEE_NOTE_LINE_ITEM_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.DEPARTMENT_SETTING_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.BANK_STATEMENT_CONVERSION_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.COMMENT_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.HOURS_BY_JOB_RESOURCE}
                    intent="registration"
                    list={HourByJobList}
                  />
                  <Resource
                    name={Constants.HOURS_BY_USER_RESOURCE}
                    intent="registration"
                    list={HourByUserList}
                  />
                  <Resource
                    name={Constants.ACTUAL_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.SWITCH_USER_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.FORMS_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.FORMS_RESPONSE_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.INTERVIEWS_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.COMPLETED_INTERVIEWS_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.FORM_GROUPS_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.FORM_GROUP_RESPONSE_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.IMPORTANT_PERSON_RESOURCE}
                    intent="registration"
                  />
                  <Resource
                    name={Constants.NATURAL_PERSON_RESOURCE}
                    intent="registration"
                  />
                  <AppRouter />
                </div>
              </main>
            </Intl>
            <Notification />
          </AdminContext>
        </AuthenticatedContainer>
      </ThemeProvider>
    );
  }

  private toggleSidebar() {
    this.setState({ ...this.state, showSidebar: !this.state.showSidebar });
  }

  private startCreatingTask() {
    this.setState({ ...this.state, creatingTask: true });
  }

  private finishCreatingTask() {
    this.setState({ ...this.state, creatingTask: false });
  }

  private startCreatingCompany() {
    this.setState({ ...this.state, creatingCompany: true });
  }

  private finishCreatingCompany() {
    this.setState({ ...this.state, creatingCompany: false });
  }
}

function mapStateToProps(state: IEtsState): IAppStateProps {
  const auth = state.auth;
  const loggedIn = auth.token != null;
  return {
    auth,
    loggedIn,
    departmentId: auth.departmentId,
    logoPath: state.product.logoPath,
    productName: state.product.productName,
    cloneTasksDialogIsOpen: state.ui.cloneTasksDialogIsOpen,
    enableCompanyCreation: canCreateCompanies(state),
    enableTaskCreation: canCreateTasks(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch): IAppDispatchProps {
  return {
    loadAppState: (departmentId: string) => {
      const actions: Array<ApiAction<any>> = [
        Api.DepartmentSetting.getAll(),
        Api.ClientGroup.getAll(),
        Api.Rate.getAll(),
        Api.ClientCompany.getAll(),
        Api.ClientService.getAll(),
        Api.CompanyAdditionalDataRecord.getAll(),
        Api.Status.getAll(),
        Api.SubtaskType.getAll(),
        Api.Task.getAll({
          pagination: { page: 1, perPage: 10000 },
          filter: {
            departmentId,
            nonCompletedOrCompletedAfter: moment()
              .subtract(2, "M")
              .toISOString(),
          },
          sort: { field: "", order: "ASC" },
        }),
        Api.TaskAdditionalDataRecord.getAll({
          pagination: { page: 1, perPage: 10000 },
          filter: { departmentId },
          sort: { field: "", order: "ASC" },
        }),
        Api.TaskType.getAll({
          pagination: { page: 1, perPage: 10000 },
          filter: { departmentId },
          sort: { field: "", order: "ASC" },
        }),
        Api.Team.getAll(),
        Api.TenantAdditionalDataRecord.getAll(),
        Api.User.getAll(),
      ];

      return Promise.all(actions.map((action) => dispatch(action)));
    },
    onTaskCreate: (task: ITask) => {
      dispatch(push(`${Constants.TASK_PATH}/${task.id}`));
    },
    onCompanyCreate: (company: IClientCompany) => {
      dispatch(push(`${Constants.COMPANY_DETAIL_PATH}/${company.id}`));
    },
  };
}

export const App = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppImpl) as any);
