import { Card, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useGetOne } from "ra-core";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router-dom";
import { Constants } from "../../../constants";
import { IClientCompany } from "../../../model";
import { CompanyDatesFields } from "./CompanyDatesFields";
import { CompanyInfoFields } from "./CompanyInfoFields";

const useStyles = makeStyles({
  root: {
    borderRadius: 12,
    padding: 20,
  },
  indicator: {
    "outline": "none",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      outline: "none",
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  tabSelect: any;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  tabSelect,
  index,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={tabSelect !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {tabSelect === index && <>{children}</>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    "id": `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

export const ClientPortalManage: React.FC<RouteComponentProps<{
  id: string;
}>> = ({ match }) => {
  const classes = useStyles({});
  const [tab, setTab] = useState(0);
  const id = match.params.id;
  const { data: company } = useGetOne(Constants.CLIENT_COMPANY_RESOURCE, id);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <Tabs
        value={tab}
        onChange={handleTabChange}
        aria-label="simple tabs example"
        classes={{ indicator: classes.indicator }}
      >
        <Tab
          label={
            <FormattedMessage
              description="Manage Page Tab1 header"
              defaultMessage="INFO"
            />
          }
          {...a11yProps(0)}
          classes={{ selected: classes.indicator }}
        />
        <Tab
          label={
            <FormattedMessage
              description="Manage Page Tab2 header"
              defaultMessage="IMPORTANT DATES"
            />
          }
          {...a11yProps(1)}
          classes={{ selected: classes.indicator }}
        />
      </Tabs>
      <TabPanel tabSelect={tab} index={0}>
        <Card className={classes.root} raised={true}>
          {company ? (
            <CompanyInfoFields company={company as IClientCompany} />
          ) : (
            <FormattedMessage
              description="loading company string"
              defaultMessage="Loading Company Info"
            />
          )}
        </Card>
      </TabPanel>
      <TabPanel tabSelect={tab} index={1}>
        <Card className={classes.root} raised={true}>
          {company ? (
            <CompanyDatesFields company={company as IClientCompany} />
          ) : (
            <FormattedMessage
              description="loading company string"
              defaultMessage="Loading Company Dates"
            />
          )}
        </Card>
      </TabPanel>
    </>
  );
};
