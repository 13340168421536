import { Tooltip } from "@material-ui/core";
import React from "react";
import { IHourByJob } from "../../../model";
import { priceFormatter } from "../../../utils/money";

interface IRecoverRateFieldProps {
  textAlign?: "left" | "right";
  record?: IHourByJob;
  label?: any;
  source?: string;
  type: "actual" | "estimated";
}

export const RecoverRateField: React.FC<IRecoverRateFieldProps> = ({
  record,
  label,
  source,
  type,
}) => {
  const estimatedFee = Number(record.estimatedFee);
  const estimatedWip = record.records.reduce((acc, current) => {
    return Number(current.estAmount) + acc;
  }, 0);
  const actualWip = record.records.reduce((acc, current) => {
    return Number(current.actualAmount) + acc;
  }, 0);

  const title = `${priceFormatter.format(estimatedFee)} / ${
    type === "actual"
      ? priceFormatter.format(actualWip)
      : priceFormatter.format(estimatedWip)
  }`;
  return (
    <Tooltip title={title} placement="top-start">
      <span>
        {type === "actual"
          ? calculatePercentage(estimatedFee, actualWip)
          : calculatePercentage(estimatedFee, estimatedWip)}
      </span>
    </Tooltip>
  );
};

export const calculatePercentage = (value1: number, value2: number) => {
  if (value2 === 0) {
    return "N/A";
  }
  return `${((value1 / value2) * 100).toFixed(1)}%`;
};
