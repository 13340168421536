import { connectRouter } from "connected-react-router";
import { adminReducer } from "react-admin";
import { Action, combineReducers } from "redux";
import { ActionTypes, Api, ISelectTasks } from "../actions";
import {
  auth,
  modelApiAsArray,
  modelApiById,
  modelApiByUniqueProperty,
  product,
  searchableModelApiAsArray,
} from "../core";
import { IUiState, PRODUCT_STATE, UI_INITIAL_STATE } from "../model";
import { departmentSettingsReducer } from "./departmentSettingsReducer";

export const etsReducer = (history) =>
  combineReducers({
    auth,
    departmentSetting: departmentSettingsReducer,
    comments: modelApiAsArray({
      Api: Api.Comment,
    }),
    attachments: modelApiAsArray({
      Api: Api.Attachment,
    }),
    clientCompanies: modelApiAsArray({
      Api: Api.ClientCompany,
    }),
    clientCompaniesById: modelApiById({
      Api: Api.ClientCompany,
    }),
    clientGroups: modelApiAsArray({
      Api: Api.ClientGroup,
    }),
    clientGroupsById: modelApiById({
      Api: Api.ClientGroup,
    }),
    clientServices: modelApiAsArray({
      Api: Api.ClientService,
    }),
    clientServicesById: modelApiById({
      Api: Api.ClientService,
    }),
    companyAdditionalData: modelApiByUniqueProperty({
      Api: Api.CompanyAdditionalDataRecord,
      propertyName: "clientCompanyId",
    }),
    product: product(PRODUCT_STATE),
    selectedTaskIds: selectedTasksReducer as any,
    statusesById: modelApiById({
      Api: Api.Status,
    }),
    subtaskTypesById: modelApiById({
      Api: Api.SubtaskType,
    }),
    tasks: modelApiAsArray({
      Api: Api.Task,
    }),
    taskAdditionalData: modelApiByUniqueProperty({
      Api: Api.TaskAdditionalDataRecord,
      propertyName: "taskId",
    }),
    tasksById: modelApiById({
      Api: Api.Task,
    }),
    taskSearchResults: searchableModelApiAsArray({
      Api: Api.Task,
    }),
    taskTypes: modelApiAsArray({
      Api: Api.TaskType,
    }),
    taskTypesById: modelApiById({
      Api: Api.TaskType,
    }),
    teamsById: modelApiById({
      Api: Api.Team,
    }),
    tenantAdditionalData: modelApiById({
      Api: Api.TenantAdditionalDataRecord,
    }),
    usersById: modelApiById({
      Api: Api.User,
    }),
    users: modelApiAsArray({
      Api: Api.User,
    }),
    departmentSettings: modelApiAsArray({
      Api: Api.DepartmentSetting,
    }),
    emails: modelApiAsArray({
      Api: Api.Email,
    }),
    emailsById: modelApiById({
      Api: Api.Email,
    }),
    chatThreads: modelApiAsArray({
      Api: Api.ChatThread,
    }),
    expenses: modelApiAsArray({
      Api: Api.Expense,
    }),
    expensesById: modelApiById({
      Api: Api.Expense,
    }),
    timeEntries: modelApiAsArray({
      Api: Api.TimeEntry,
    }),
    timeEntriesById: modelApiById({
      Api: Api.TimeEntry,
    }),
    timesheets: modelApiAsArray({
      Api: Api.Timesheet,
    }),
    timesheetsById: modelApiById({
      Api: Api.Timesheet,
    }),
    invoices: modelApiAsArray({
      Api: Api.Invoice,
    }),
    invoicesById: modelApiById({
      Api: Api.Invoice,
    }),
    invoiceTemplates: modelApiAsArray({
      Api: Api.InvoiceTemplate,
    }),
    invoiceTemplatesById: modelApiById({
      Api: Api.InvoiceTemplate,
    }),
    creditNotes: modelApiAsArray({
      Api: Api.CreditNote,
    }),
    creditNotesById: modelApiById({
      Api: Api.CreditNote,
    }),
    payments: modelApiAsArray({
      Api: Api.Payment,
    }),
    paymentsById: modelApiById({
      Api: Api.Payment,
    }),
    writeOffs: modelApiAsArray({
      Api: Api.WriteOff,
    }),
    writeOffsById: modelApiById({
      Api: Api.WriteOff,
    }),
    tasksAccountBalances: modelApiAsArray({
      Api: Api.TasksAccountBalance,
    }),
    departmentsAccountBalances: modelApiAsArray({
      Api: Api.DepartmentsAccountBalance,
    }),
    amountToBills: modelApiAsArray({
      Api: Api.AmountToBill,
    }),
    feeNotesLists: modelApiAsArray({
      Api: Api.FeeNotesList,
    }),
    creditNotesLists: modelApiAsArray({
      Api: Api.CreditNotesList,
    }),
    rates: modelApiAsArray({
      Api: Api.Rate,
    }),
    ratesById: modelApiById({
      Api: Api.Rate,
    }),
    estimatedTimeEntries: modelApiAsArray({
      Api: Api.EstimatedTimeEntry,
    }),
    estimatedTimeEntriesById: modelApiById({
      Api: Api.EstimatedTimeEntry,
    }),
    actualTime: modelApiAsArray({
      Api: Api.ActualTime,
    }),
    actualTimeById: modelApiById({
      Api: Api.ActualTime,
    }),
    router: connectRouter(history),
    ui: uiReducer,
    admin: adminReducer,
  });

function selectedTasksReducer(state: Set<string> = new Set(), action: Action) {
  switch (action.type) {
    case ActionTypes.SELECT_TASKS: {
      const taskIds = (action as ISelectTasks).taskIds;
      const newState = new Set(state);
      taskIds.forEach((id) => {
        newState.add(id);
      });
      return newState;
    }
    case ActionTypes.UNSELECT_TASKS: {
      const taskIds = (action as ISelectTasks).taskIds;
      const newState = new Set(state);
      taskIds.forEach((id) => {
        newState.delete(id);
      });
      return newState;
    }
    case ActionTypes.CLEAR_TASK_SELECTION:
    case "@@router/LOCATION_CHANGE":
      return new Set();
    default:
      return state;
  }
}

function uiReducer(state: IUiState = UI_INITIAL_STATE, action: Action) {
  switch (action.type) {
    case ActionTypes.OPEN_CLONE_TASKS_DIALOG: {
      return {
        ...state,
        cloneTasksDialogIsOpen: true,
      };
    }
    case ActionTypes.CLOSE_CLONE_TASKS_DIALOG: {
      return {
        ...state,
        cloneTasksDialogIsOpen: false,
      };
    }
    default:
      return state;
  }
}
