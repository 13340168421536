// Run this example by adding <%= javascript_pack_tag 'application' %> to the head of your layout file,
// like app/views/layouts/application.html.erb.

import * as Sentry from "@sentry/browser";
import { ConnectedRouter } from "connected-react-router";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ErrorBoundary } from "../src/components";
import { Ets } from "../src/Ets";
import { authProvider, createAdminStore, history } from "../src/store";
import { createDataProvider } from "../src/utils/dataProvider";
import { returnSentryEnvironment } from "../src/utils/environment";
import "react-phone-number-input/style.css";
import "react-table/react-table.css";
import "../stylesheets/application.scss";
if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://afde750735574d9faf9454752cd01e49@sentry.io/1292633",
    environment: returnSentryEnvironment(),
  });
}

document.addEventListener("DOMContentLoaded", () => {
  const { store, dataProvider, clientPortalDataProvider } = createAdminStore({
    dataProviderFactory: createDataProvider,
    authProvider,
    history,
  });
  ReactDOM.render(
    <ErrorBoundary>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Ets dataProvider={dataProvider} clientPortalDataProvider={clientPortalDataProvider} />
        </ConnectedRouter>
      </Provider>
    </ErrorBoundary>,
    document.getElementById("ets"),
  );
});
