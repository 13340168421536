import React, { cloneElement } from "react";
import { sanitizeListRestProps, TopToolbar } from "react-admin";
import { PaymentExportButton } from "./paymentExport";

export const ListActions = (props) => {
  const {
    className,
    resource,
    filters,
    displayedFilters,
    filterValues,
    permanentFilter,
    showFilter,
    total,
    ...rest
  } = props;
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}

      <PaymentExportButton
        filterValues={{ ...filterValues, ...permanentFilter }}
        disabled={total === 0}
      />
    </TopToolbar>
  );
};
