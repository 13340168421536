import React from "react";
import { IChatThreadMessage } from "../../../model";
import * as DateUtils from "../../../utils/date";
interface IChatMessageProps {
  chatmessage: IChatThreadMessage;
}

export class ChatMessageImpl extends React.Component<IChatMessageProps> {
  public render() {
    const { userName, content, sentAt } = this.props.chatmessage;
    const sentdate = DateUtils.parseDateMonthDayTime(sentAt);
    return (
      <div className="speech-wrapper">
        <div className="bubble">
          <div className="txt">
            <p className="name">
              {userName} <span className="timestamp">{sentdate}</span>
            </p>
            <p className="message">{content}</p>
          </div>
          <div className="bubble-arrow" />
        </div>
      </div>
    );
  }
}
