import moment from "moment";

export * from "./errors";

export class Constants {
  public static readonly APP_PATH = "/app";
  public static readonly COMPANY_DETAIL_PATH = `${Constants.APP_PATH}/companies`;
  public static readonly INBOX_PATH = `${Constants.APP_PATH}/inbox`;
  public static readonly TIMELINE_PATH = `${Constants.APP_PATH}/timeline`;
  public static readonly KANBAN_PATH = `${Constants.APP_PATH}/board`;
  public static readonly TODAY_PATH = `${Constants.APP_PATH}/today`;
  public static readonly WEEK_PATH = `${Constants.APP_PATH}/week`;
  public static readonly TEAM_PATH = `${Constants.APP_PATH}/team`;
  public static readonly DEPARTMENT_PATH = `${Constants.APP_PATH}/department`;
  public static readonly TASK_PATH = `${Constants.APP_PATH}/tasks`;
  public static readonly ACCOUNT_BALANCE_PATH = `${Constants.APP_PATH}/account_balance`;
  public static readonly REVIEW_PATH = `${Constants.APP_PATH}/review`;
  public static readonly CLIENTGROUP_DETAIL_PATH = `${Constants.APP_PATH}/client_groups`;
  public static readonly SEARCH_PATH = `${Constants.APP_PATH}/search`;
  public static readonly NOTIFICATIONS_PATH = `${Constants.APP_PATH}/notifications`;
  public static readonly SETTINGS_PATH = `${Constants.APP_PATH}/settings`;
  public static readonly EXPENSES_PATH = `${Constants.APP_PATH}/expenses`;
  public static readonly TIMESHEET_PATH = `${Constants.APP_PATH}/timesheets`;
  public static readonly INVOICE_PATH = `${Constants.APP_PATH}/invoices`;
  public static readonly CREDIT_NOTE_PATH = `${Constants.APP_PATH}/credit_notes`;
  public static readonly PAYMENT_PATH = `${Constants.APP_PATH}/payments`;
  public static readonly WRITE_OFF_PATH = `${Constants.APP_PATH}/write_offs`;
  public static readonly TASKS_ACCOUNT_BALANCE_PATH = `${Constants.APP_PATH}/tasks_account_balances`;
  public static readonly DEPARTMENTS_ACCOUNT_BALANCE_PATH = `${Constants.APP_PATH}/departments_account_balances`;
  public static readonly PIC_ACCOUNT_BALANCE_PATH = `${Constants.APP_PATH}/pic_account_balances`;
  public static readonly AMOUNT_TO_BILL_PATH = `${Constants.APP_PATH}/amount_to_bill`;
  public static readonly FEE_NOTES_LIST_PATH = `${Constants.APP_PATH}/fee_notes_lists`;
  public static readonly CREDIT_NOTES_LIST_PATH = `${Constants.APP_PATH}/credit_notes_lists`;
  public static readonly ADJUSTMENT_PATH = `${Constants.APP_PATH}/adjustments`;
  public static readonly LINE_ITEM_TEMPLATE_PATH = `${Constants.APP_PATH}/line_item_templates`;
  public static readonly FEE_NOTE_LINE_ITEMS_PATH = `${Constants.APP_PATH}/fee_note_line_items`;
  public static readonly BANK_STATEMENT_GENERATOR_PATH = `${Constants.APP_PATH}/bank_statement_csv_generator`;
  public static readonly HOUR_BY_JOB_PATH = `${Constants.APP_PATH}/hours_by_job`;
  public static readonly HOUR_BY_USER_PATH = `${Constants.APP_PATH}/hours_by_staff`;
  public static readonly CUSTOM_REPORT_PATH = `${Constants.APP_PATH}/custom_report`;
  public static readonly CLIENT_USER_PATH = `${Constants.APP_PATH}/client_users`;
  public static readonly FORM_RESPONSES_PATH = `${Constants.APP_PATH}/form_responses`;
  public static readonly ACTIVITY_FEED_PATH = `${Constants.APP_PATH}/activity_feed`;

  // Client Portal Path
  public static readonly CLIENT_APP_PATH = "/client_app";
  public static readonly CLIENT_APP_COMPANY_PATH = `${Constants.CLIENT_APP_PATH}/companies`;
  public static readonly CLIENT_APP_LOGIN_GENERATOR_PATH = `${Constants.CLIENT_APP_PATH}/login_generator`;
  public static readonly CLIENT_APP_FORM_PATH = `${Constants.CLIENT_APP_PATH}/forms`;

  public static readonly DATE_FORMAT = "YYYY-MM-DD";
  public static readonly DATE_SLASH_FORMAT = "YYYY/MM/DD";
  public static readonly HUMAN_DATE_FORMAT = "MMM Do YYYY";
  public static readonly SHORT_DATE_FORMAT = "MMM D";
  public static readonly HUMAN_DATE_FORMAT_NO_YEAR = "MMM D";
  public static readonly MAX_DATE = moment().add(5, "years").toDate();
  public static readonly MIN_DATE = moment().subtract(10, "years").toDate();

  public static readonly FORM_LINK_GENERATOR_RESOURCE = "form_link_generator";
  public static readonly ACCOUNT_BALANCE_RESOURCE = "account_balances";
  public static readonly ACTUAL_RESOURCE = "actual";
  public static readonly ADJUSTMENT_RESOURCE = "adjustments";
  public static readonly ACTIVITY_FEED_RESOURCE = "activity_feed";
  public static readonly AMOUNT_TO_BILL_RESOURCE = "amount_to_bills";
  public static readonly ATTACHMENT_RESOURCE = "attachments";
  public static readonly CLIENT_PORTAL_ATTACHMENT_RESOURCE =
    "client_portal_attachments";
  public static readonly BALANCE_ENTRY_RESOURCE = "balance_entries";
  public static readonly CHAT_THREAD_RESOURCE = "chat_threads";
  public static readonly CLIENT_COMPANY_RESOURCE = "client_companies";
  public static readonly CLIENT_GROUP_RESOURCE = "client_groups";
  public static readonly COMMENT_RESOURCE = "comments";
  public static readonly CREDIT_NOTE_LIST_RESOURCE = "credit_notes_lists";
  public static readonly CREDIT_NOTE_RESOURCE = "credit_notes";
  public static readonly CUSTOM_REPORT_RESOURCE = "custom_report";
  public static readonly COMPLETED_INTERVIEWS_RESOURCE = "completed_interviews";
  public static readonly DEPARTMENTS_ACCOUNT_BALANCE_RESOURCE =
    "departments_account_balances";
  public static readonly BANK_STATEMENT_CONVERSION_RESOURCE =
    "bank_statement_conversions";
  public static readonly DEPARTMENTS_RESOURCE = "departments";
  public static readonly EMAIL_RESOURCE = "emails";
  public static readonly ESTIMATED_TIME_ENTRY_RESOURCE =
    "estimated_time_entries";
  public static readonly ESTIMATE_RESOURCE = "estimates";
  public static readonly EXPENSE_BALANCE_RESOURCE = "expense_balances";
  public static readonly EXPENSE_ENTRY_RESOURCE = "expense_entries";
  public static readonly EXPENSE_RESOURCE = "expenses";
  public static readonly FEE_NOTE_LINE_ITEM_RESOURCE = "fee_note_line_items";
  public static readonly FEE_NOTES_LIST_RESOURCE = "fee_notes_lists";
  public static readonly HOURS_BY_JOB_RESOURCE = "hours_by_job";
  public static readonly HOURS_BY_USER_RESOURCE = "hours_by_user";
  public static readonly INVOICE_RESOURCE = "invoices";
  public static readonly INVOICE_TEMPLATE_RESOURCE = "invoice_templates";
  public static readonly INTERVIEWS_RESOURCE = "interviews";
  public static readonly LINE_ITEM_RESOURCE = "line_items";
  public static readonly LINE_ITEM_TEMPLATE_RESOURCE = "line_item_templates";
  public static readonly LOGIN_LINK_GENERATOR_RESOURCE = "login_link_generator";
  public static readonly PARENT_LIST_RESOURCE = "parent_list";
  public static readonly PAYMENT_RESOURCE = "payments";
  public static readonly PIC_ACCOUNT_BALANCE_RESOURCE = "pic_account_balances";
  public static readonly RATE_RESOURCE = "rates";
  public static readonly STATUS_RESOURCE = "statuses";
  public static readonly SWITCH_USER_RESOURCE = "switch";
  public static readonly TASK_RESOURCE = "tasks";
  public static readonly TASK_TYPE_RESOURCE = "task_types";
  public static readonly TASKS_ACCOUNT_BALANCE_RESOURCE =
    "tasks_account_balances";
  public static readonly TIME_ENTRY_RESOURCE = "time_entries";
  public static readonly TIMESHEET_RESOURCE = "timesheets";
  public static readonly USER_RATE_RESOURCE = "user_rates";
  public static readonly USER_RESOURCE = "users";
  public static readonly WRITE_OFF_RESOURCE = "write_offs";
  public static readonly FORMS_RESOURCE = "forms";
  public static readonly FORMS_RESPONSE_RESOURCE = "form_responses";
  public static readonly FORM_GROUPS_RESOURCE = "form_groups";
  public static readonly IMPORTANT_PERSON_RESOURCE = "important_people";
  public static readonly NATURAL_PERSON_RESOURCE = "natural_persons";
  public static readonly VERSION_RESOURCE = "versions";
  public static readonly INTERVIEW_TEMPLATE_RESOURCE = "interview_templates";

  public static readonly CLIENT_COMPANY_ID_KEY = "clientCompanyId";
  public static readonly DATA_NAME_KEY = "name";
  public static readonly DEPARTMENT_KEY = "Department";
  public static readonly TENANT_KEY = "Tenant";

  // Client Portal Resource
  public static readonly CLIENT_USER_RESOURCE = "client_users";
  public static readonly FORM_GROUP_RESPONSE_RESOURCE = "form_group_responses";

  public static readonly Api = "/api";
  public static readonly WIP_ENTRIES_REPORT_PATH = `${Constants.Api}/wip_export_reports`;
  public static readonly PAYMENTS_API_PATH = `${Constants.Api}/${Constants.PAYMENT_RESOURCE}`;
  public static readonly DEPARTMENT_SETTING_RESOURCE = "department_settings";
  public static readonly NOTIFICATIONS_RESOURCE = "notifications";
  public static readonly NOTIFICATIONS_OPEN_RESOURCE = `${Constants.NOTIFICATIONS_RESOURCE}/open_all`;
  public static readonly ACTIVITY_FEED_NOTIFICATIONS_RESOURCE = `${Constants.NOTIFICATIONS_RESOURCE}/index_by_group`;
  public static readonly ACTIVITY_FEED_NOTIFICATIONS_BY_GROUP_RESOURCE = `${Constants.NOTIFICATIONS_RESOURCE}/notifications_by_group`;

  public static readonly GET_ACTION_CABLE_CONSUMER =
    "GET_ACTION_CABLE_CONSUMER";

  public static readonly Logout = "/auth/logout";

  public static readonly RESOURCES = [
    Constants.ACCOUNT_BALANCE_RESOURCE,
    Constants.ACTUAL_RESOURCE,
    Constants.ADJUSTMENT_RESOURCE,
    Constants.AMOUNT_TO_BILL_RESOURCE,
    Constants.ATTACHMENT_RESOURCE,
    Constants.BALANCE_ENTRY_RESOURCE,
    Constants.CLIENT_COMPANY_RESOURCE,
    Constants.CLIENT_GROUP_RESOURCE,
    Constants.CREDIT_NOTE_LIST_RESOURCE,
    Constants.CREDIT_NOTE_RESOURCE,
    Constants.CUSTOM_REPORT_RESOURCE,
    Constants.DEPARTMENTS_ACCOUNT_BALANCE_RESOURCE,
    Constants.DEPARTMENTS_RESOURCE,
    Constants.EMAIL_RESOURCE,
    Constants.ESTIMATED_TIME_ENTRY_RESOURCE,
    Constants.ESTIMATE_RESOURCE,
    Constants.EXPENSE_BALANCE_RESOURCE,
    Constants.EXPENSE_ENTRY_RESOURCE,
    Constants.EXPENSE_RESOURCE,
    Constants.FEE_NOTE_LINE_ITEM_RESOURCE,
    Constants.FEE_NOTES_LIST_RESOURCE,
    Constants.HOURS_BY_JOB_RESOURCE,
    Constants.HOURS_BY_USER_RESOURCE,
    Constants.INVOICE_RESOURCE,
    Constants.INVOICE_TEMPLATE_RESOURCE,
    Constants.LINE_ITEM_RESOURCE,
    Constants.LINE_ITEM_TEMPLATE_RESOURCE,
    Constants.PARENT_LIST_RESOURCE,
    Constants.PAYMENT_RESOURCE,
    Constants.PIC_ACCOUNT_BALANCE_RESOURCE,
    Constants.RATE_RESOURCE,
    Constants.STATUS_RESOURCE,
    Constants.SWITCH_USER_RESOURCE,
    Constants.TASK_RESOURCE,
    Constants.TASK_TYPE_RESOURCE,
    Constants.TASKS_ACCOUNT_BALANCE_RESOURCE,
    Constants.TIME_ENTRY_RESOURCE,
    Constants.TIMESHEET_RESOURCE,
    Constants.USER_RATE_RESOURCE,
    Constants.USER_RESOURCE,
    Constants.WRITE_OFF_RESOURCE,
    Constants.FORMS_RESOURCE,
    Constants.FORMS_RESPONSE_RESOURCE,
    Constants.CLIENT_USER_RESOURCE,
    Constants.FORM_GROUPS_RESOURCE,
    Constants.FORM_GROUP_RESPONSE_RESOURCE,
    Constants.VERSION_RESOURCE,
    Constants.CHAT_THREAD_RESOURCE,
    Constants.INTERVIEW_TEMPLATE_RESOURCE,
  ];

  public static readonly CLIENT_RESOURCES = [
    Constants.CLIENT_USER_RESOURCE,
    Constants.CLIENT_COMPANY_RESOURCE,
    Constants.CLIENT_USER_RESOURCE,
    Constants.USER_RESOURCE,
    Constants.INVOICE_RESOURCE,
    Constants.FORM_GROUP_RESPONSE_RESOURCE,
  ];
}
