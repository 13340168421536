import { TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { ICoverAmountToBill } from "../../../model/IAmountToBill";
import { priceFormatter } from "../../../utils/money";

interface IAmountToBillContent {
  amountToBill: ICoverAmountToBill;
  months: string[];
}

export const AmountToBillContent: React.FC<IAmountToBillContent> = ({
  amountToBill,
  months,
}) => {
  const total = months.reduce((acc, month) => {
    if (amountToBill[month]) {
      acc += Number(amountToBill[month]);
    }
    return acc;
  }, 0);

  const getName = () => {
    if (amountToBill.firstName && amountToBill.lastName) {
      return `${amountToBill.firstName}, ${amountToBill.lastName}`;
    } else {
      return amountToBill.name;
    }
  };
  const name = getName();
  return (
    <TableRow>
      <TableCell align="left">{name}</TableCell>
      {months.map((month) => {
        return (
          <TableCell align="right" key={`${amountToBill.id}-${month}`}>
            {priceFormatter.format(amountToBill[month] || 0)}
          </TableCell>
        );
      })}
      <TableCell align="right">{priceFormatter.format(total)}</TableCell>
    </TableRow>
  );
};
