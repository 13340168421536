import { Card } from "@blueprintjs/core";
import { Grid } from "@material-ui/core";
import React from "react";

interface IClientCompanyContactSectionProps {
  contactName: string;
  address: string;
  billingAddress: string;
  contactEmail: string;
  contactNumber: string;
  referral: string;
}

export const ClientCompanyContactSection = ({
  contactName,
  address,
  billingAddress,
  contactEmail,
  contactNumber,
  referral,
}: IClientCompanyContactSectionProps) => {
  return (
    <Card className="details">
      <div className="content">
        <Grid container={true} spacing={1}>
          <Grid item={true} xs={3}>
            Contact Person:
          </Grid>
          <Grid item={true} xs={9}>
            {contactName}
          </Grid>
          <Grid item={true} xs={3}>
            Contact Email:
          </Grid>
          <Grid item={true} xs={9}>
            {contactEmail}
          </Grid>
          <Grid item={true} xs={3}>
            Contact Phone:
          </Grid>
          <Grid item={true} xs={9}>
            {contactNumber}
          </Grid>
          <Grid item={true} xs={3}>
            Address:
          </Grid>
          <Grid item={true} xs={9}>
            {address}
          </Grid>
          <Grid item={true} xs={3}>
            Billing Address:
          </Grid>
          <Grid item={true} xs={9}>
            {billingAddress}
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};
