import { Tab, Tabs } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Api, selectTasks } from "../../actions";
import { enabled } from "../../config";
import { IEtsState } from "../../model";
import { EstimatedFeePage } from "../EstimatedFeePage/EstimatedFeePage";
import { TaskDetail } from "../TaskDetail/TaskDetail";

interface ITaskPageProps extends RouteComponentProps<{ id: string }> {}

const estimatedFeeEnabled = (task, auth) => {
  const { assigneeId, picId, reviewerId } = task;
  return (
    enabled("timebill.estimatedFee", auth.roles) ||
    assigneeId === auth.user.id ||
    picId === auth.user.id ||
    reviewerId === auth.user.id
  );
};

export const TaskPage: React.FC<ITaskPageProps> = ({ match }) => {
  const [tab, setTab] = useState("detail");
  const taskId = match.params.id;
  const task = useSelector((state: IEtsState) => state.tasksById[taskId]);
  const auth = useSelector((state: IEtsState) => state.auth);

  const renderTask = () => <TaskDetail task={task} />;
  const renderEstimatedFee = () => <EstimatedFeePage task={task} />;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Api.Task.get(taskId));
    dispatch(selectTasks([taskId]));
  }, [taskId]);

  const handleTabChange = (newTabId: string) => setTab(newTabId);

  if (!task) {
    return null;
  }

  return (
    <Tabs
      className="task-tabs"
      onChange={handleTabChange}
      selectedTabId={tab}
      large={true}
    >
      <Tab id="detail" title="Details" panel={renderTask()} />
      <Tab
        id="estimated_fee"
        title="Estimates"
        disabled={!estimatedFeeEnabled(task, auth)}
        panel={renderEstimatedFee()}
      />
    </Tabs>
  );
};
