import { Button } from "@blueprintjs/core";
import { Box } from "@material-ui/core";
import { useNotify, useRefresh } from "ra-core";
import React, { useState } from "react";
import { ClientUserList } from "../../components/ClientUser/ClientUserList";
import { ClientUserCreateDialog } from "../../components/dialog/ClientUserCreateDialog";

interface IClientGroupClientUserListProps {
  clientGroupId: string;
}

export const ClientGroupClientUserList: React.FC<IClientGroupClientUserListProps> = ({
  clientGroupId,
}) => {
  const [isCreatingClientUser, setIsCreatingClientUser] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const finishCreatingClientUser = () => {
    setIsCreatingClientUser(false);
  };

  const startCreatingClientUser = () => {
    setIsCreatingClientUser(true);
  };

  const handleCreateClientUserSuccess = () => {
    notify("ra.notification.created", "info", { smart_count: 1 });
    refresh();
    finishCreatingClientUser();
  };

  return (
    <>
      <div className="client-group-company-list">
        <Box display="flex" justifyContent="space-between">
          <h3 className="client-group-entry-list-title">Client Users</h3>
          <Button
            icon="add"
            text="Create Client User"
            onClick={startCreatingClientUser}
            className="create-client-user-button"
          />
        </Box>
        <ClientUserList clientGroupId={clientGroupId} />
        <ClientUserCreateDialog
          isOpen={isCreatingClientUser}
          canOutsideClickClose={true}
          onClose={finishCreatingClientUser}
          clientGroupId={clientGroupId}
          handleSuccess={handleCreateClientUserSuccess}
        />
      </div>
    </>
  );
};
