import { IModelById, UserUtil } from "@chiubaka/core";
import { createSelector } from "reselect";
import {
  IClientCompany,
  IClientGroup,
  IEtsState,
  IEtsUser,
  IKanbanColumn,
  IPermissions,
  IStatus,
  ITask,
  ITaskAdditionalData,
  ITaskType,
  ITaskWithDSF,
} from "../model";
import { formatShortDate, parseDate } from "../utils/date";
import { can } from "../utils/permissions";
import * as Utils from "../utils/sort";

interface ITaskWithDSFById {
  [id: string]: ITaskWithDSF;
}

export const tasksWithDsfById = createSelector(
  [
    (state: IEtsState) => state.tasksById,
    (state: IEtsState) => state.taskAdditionalData,
  ],
  (
    tasksById: IModelById<ITask>,
    taskAdditionalData: IModelById<ITaskAdditionalData>
  ): ITaskWithDSFById => {
    const tasksWithDsf: ITaskWithDSFById = {};
    for (const id in tasksById) {
      if (!tasksById.hasOwnProperty(id)) {
        continue;
      }

      const task = tasksById[id];
      const additionalData = taskAdditionalData[id];
      tasksWithDsf[id] = {
        task,
        additionalData,
      };
    }

    return tasksWithDsf;
  }
);

export const selectedTasksWithDsfAsArray = createSelector(
  [(state: IEtsState) => state.selectedTaskIds, tasksWithDsfById],
  (selectedTaskIds: Set<string>, tasks: ITaskWithDSFById): ITaskWithDSF[] => {
    const selectedTasks = [];
    selectedTaskIds.forEach((id: string) => {
      selectedTasks.push(tasks[id]);
    });

    return selectedTasks;
  }
);

export const selectedTasksIdAsArray = (state: IEtsState): string[] =>
  Array.from(state.selectedTaskIds);

export const currentEtsUser = createSelector(
  [
    (state: IEtsState) => state.auth.user && state.auth.user.id,
    (state: IEtsState) => state.usersById,
  ],
  (userId: string, usersById: IModelById<IEtsUser>) => {
    if (userId == null) {
      return null;
    }

    return usersById[userId];
  }
);

export const currentDepartment = createSelector(
  currentEtsUser,
  (etsUser: IEtsUser) => {
    if (etsUser == null) {
      return null;
    }

    return etsUser.department;
  }
);

export const currentTenant = createSelector(
  currentEtsUser,
  (etsUser: IEtsUser) => {
    if (etsUser == null) {
      return null;
    }
    return etsUser.tenant;
  }
);

export const currentPermissions = createSelector(
  currentEtsUser,
  (etsUser: IEtsUser) => {
    if (etsUser == null) {
      return null;
    }

    return etsUser.permissions;
  }
);

const canCreate = (modelName) => {
  return createSelector(currentPermissions, (permissions: IPermissions) => {
    return can({
      action: "create",
      modelName,
      permissions,
    });
  });
};

export const canCreateTasks = canCreate("task");
export const canCreateCompanies = canCreate("clientCompany");

export const permissionsForTask = (task) => {
  return createSelector(currentPermissions, (permissions: IPermissions) => {
    const taskPermissions = {};
    for (const property in task) {
      if (!task.hasOwnProperty(property)) {
        continue;
      }

      taskPermissions[property] = can({
        action: "update",
        modelName: "task",
        fieldName: property,
        permissions,
        objectPermissions: task.permissions,
      });
    }

    return taskPermissions;
  });
};

const subtaskPermissions = (
  action: "create" | "read" | "update" | "destroy"
) => {
  return createSelector(currentPermissions, (permissions: IPermissions) => {
    return can({
      action,
      modelName: "subtask",
      permissions,
    });
  });
};

export const canCreateSubtasks = createSelector(
  [currentPermissions, subtaskPermissions("create")],
  (permissions: IPermissions, subtaskCreationEnabled: boolean) => {
    return (
      subtaskCreationEnabled &&
      can({
        action: "create",
        modelName: "subtaskType",
        permissions,
      })
    );
  }
);

const toKanban = (isDragDisabled: boolean) => (
  statusesById: IModelById<IStatus>,
  userId: string,
  clientCompaniesById: IModelById<IClientCompany>,
  clientGroupsById: IModelById<IClientGroup>,
  taskTypesById: IModelById<ITaskType>,
  usersById: IModelById<IEtsUser>,
  tasks: ITask[]
): IKanbanColumn[] => {
  return calculateKanbanColumn(
    tasks,
    statusesById,
    userId,
    clientCompaniesById,
    clientGroupsById,
    taskTypesById,
    usersById,
    isDragDisabled
  );
};

const taskKanBanTasksSelector = (state: IEtsState) =>
  state.tasks.filter((task) => {
    const assignee = state.usersById[task.assigneeId];
    const currentUser = state.usersById[state.auth.user.id];
    return (
      assignee.id === currentUser.id || assignee.teamId === currentUser.teamId
    );
  });

const kanBanStateSelectors = [
  (state: IEtsState) => state.statusesById,
  (state: IEtsState) => state.auth.user.id,
  (state: IEtsState) => state.clientCompaniesById,
  (state: IEtsState) => state.clientGroupsById,
  (state: IEtsState) => state.taskTypesById,
  (state: IEtsState) => state.usersById,
];

export const taskKanbanTeam = () =>
  createSelector(
    [...kanBanStateSelectors, taskKanBanTasksSelector],
    // Disable drag for team
    toKanban(true)
  );

export const taskKanbanDepartment = () =>
  createSelector(
    [
      ...kanBanStateSelectors,
      // (state: IEtsState) => [...state.tasks],
      (state: IEtsState) => state.tasks,
    ],
    // Disable drag for department
    toKanban(true)
  );

export const taskKanbanBoard = () =>
  createSelector(
    [
      ...kanBanStateSelectors,
      (state: IEtsState) =>
        state.tasks.filter((task) => task.assigneeId === state.auth.user.id),
    ],
    toKanban(false)
  );

export const calculateKanbanColumn = (
  tasks: ITask[],
  statusesById: IModelById<IStatus>,
  currentUserId: string,
  clientCompaniesById: IModelById<IClientCompany>,
  clientGroupsById: IModelById<IClientGroup>,
  taskTypesById: IModelById<ITaskType>,
  usersById: IModelById<IEtsUser>,
  isDragDisabled: boolean
): IKanbanColumn[] => {
  const currentUser = usersById[currentUserId];
  const columnList = [];
  // Index Column List by Statuses
  for (const key of Object.keys(statusesById)) {
    const status = statusesById[key];
    if (status.departmentId === currentUser.department.id) {
      columnList.push(status);
    }
  }

  columnList.sort((a, b) => {
    return Utils.sortByOrderAsc(a, b);
  });

  columnList.map((column) => {
    const completedTasks = tasks.filter((task) => !task.isComplete);
    const tasksGroupedByStatus = completedTasks.filter(
      (task) => task.statusId === column.id
    );
    column.taskCardList = [];
    for (const taskByStatus of tasksGroupedByStatus) {
      const clientCompany = clientCompaniesById[taskByStatus.clientCompanyId];
      const clientGroup = clientGroupsById[clientCompany.clientGroupId];
      const user = usersById[taskByStatus.reviewerId];
      const taskCardUserColor =
        typeof user === "undefined" ? "turquoise" : user.colorHex;
      const taskType = taskTypesById[taskByStatus.taskTypeId];
      const taskTitle = typeof taskType === "undefined" ? "" : taskType.name;
      const taskCard = {
        id: taskByStatus.id,
        draggableId: taskByStatus.id,
        isDragDisabled,
        taskCardStartDate: formatShortDate(parseDate(taskByStatus.startDate)),
        taskCardDueDate: formatShortDate(parseDate(taskByStatus.dueDate)),
        taskCardLabelColor: clientCompany.colorHex,
        taskCardLabelContent: `${clientGroup.name}:${clientCompany.name}`,
        taskCardUserSpanContent: UserUtil.initials(
          usersById[taskByStatus.reviewerId]
        ),
        taskCardUserSpanColor: taskCardUserColor,
        taskCardLink: `/app/tasks/${taskByStatus.id}`,
        taskCardTitle: taskTitle,
      };
      column.taskCardList.push(taskCard);
    }
    return column;
  });
  return columnList;
};

export const canUpdateSubtasks = subtaskPermissions("update");
export const canDestroySubtasks = subtaskPermissions("destroy");

export const canCreateComments = canCreate("comment");
export const canCreateAttachments = canCreate("attachment");

export const calculateEstimatedTimeCost = createSelector(
  (state: IEtsState) => state.estimatedTimeEntries,
  (entries) => entries.reduce((sum, entry) => sum + parseFloat(entry.amount), 0)
);

export const calculateActualFee = createSelector(
  (state: IEtsState) => state.actualTime,
  (actualTime) =>
    actualTime.reduce((sum, record) => sum + Number(record.amount), 0)
);
