import actionCable from "actioncable";
import { getToken } from "../core";
export const getCable = () => {
  const { accessToken, client, expiry, uid, tokenType } = getToken();
  const params = {
    accessToken,
    client,
    expiry,
    uid,
    tokenType,
  };

  return actionCable.createConsumer(
    `${
      window.env.ACTION_CABLE_URL ?? "ws://localhost:3000/cable"
    }?token=${encodeURIComponent(JSON.stringify(params))}`
  );
};
