import { FormLabel, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { DateField, DateInput } from "react-admin";
import { useSelector } from "react-redux";
import { IEtsState } from "../../model";

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: "10px",
  },
}));

export const InvoiceDateField: FC<any> = (props) => {
  const classes = useStyles(props);

  const isOverride = useSelector(
    (state: IEtsState) => state.departmentSetting.allowInvoiceDateOverride
  );

  return isOverride ? (
    <DateInput {...props} />
  ) : (
    <div className={classes.container}>
      <FormLabel component="legend">Date</FormLabel>
      <DateField {...props} />
    </div>
  );
};
