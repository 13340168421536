import { Icon } from "@blueprintjs/core";
import * as React from "react";
import { Draggable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { ITaskCard } from "../../../model";

export class TaskCardImpl extends React.Component<ITaskCard> {
  public render(): JSX.Element {
    const {
      isDragDisabled,
      draggableId,
      draggableIndex,
      taskCardStartDate,
      taskCardDueDate,
      taskCardLabelColor,
      taskCardLabelContent,
      taskCardUserSpanContent,
      taskCardUserSpanColor,
      taskCardLink,
      taskCardTitle,
    } = this.props;

    const container = (provided, snapshot) => (
      <div
        className="taskcard-container"
        ref={provided.innerRef}
        {...provided.dragHandleProps}
        {...provided.draggableProps}
      >
        <div
          className="taskcard-label"
          style={{ backgroundColor: taskCardLabelColor }}
        >
          {taskCardLabelContent}
        </div>
        <div style={{ marginBottom: "5px" }} className="taskcard-title">
          {taskCardTitle}
        </div>

        <div className="taskcard-footer">
          {taskCardLink.length > 0 && (
            <Link to={taskCardLink}>
              <Icon icon="list" />
            </Link>
          )}
          <div>
            {taskCardStartDate} - {taskCardDueDate}
            <div
              className="taskcard-user-span"
              style={{ backgroundColor: taskCardUserSpanColor }}
            >
              {taskCardUserSpanContent}
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <Draggable
        draggableId={draggableId}
        isDragDisabled={isDragDisabled}
        index={draggableIndex}
      >
        {container}
      </Draggable>
    );
  }
}

export const TaskCard = TaskCardImpl;
