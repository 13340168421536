import * as React from "react";
import { ReadMore } from "../ReadMore/ReadMore";

export const EmailBody = ({ emailBody, expanded, updateExpanded }) => {
  return (
    <ReadMore
      lines={3}
      more={"Read more"}
      less={"Read less"}
      expanded={expanded}
      truncated={false}
      updateExpanded={updateExpanded}
    >
      {emailBody}
    </ReadMore>
  );
};
