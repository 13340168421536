import { makeStyles, TableCell, TableRow } from "@material-ui/core";
import classnames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import { IBalanceEntry } from "../../model";
import { statusString } from "../../utils/approvalStatus";
import {
  mapDescription,
  mapEntryType,
  sourceEntryLink,
} from "../../utils/balanceEntry";
import * as DateUtils from "../../utils/date";
import * as MoneyUtils from "../../utils/money";
import { UserField } from "../fields";

const useStyles = makeStyles({
  rightAlignedCell: { textAlign: "right" },
});

export interface IExpenseRowProps {
  entry: IBalanceEntry;
  total: number;
}

export const ExpenseRow: React.FC<IExpenseRowProps> = ({ entry, total }) => {
  const classes = useStyles();

  const description = mapDescription(entry);

  return (
    <TableRow key={entry.id}>
      <TableCell>
        {DateUtils.formatDate(DateUtils.parseDate(entry.date))}
      </TableCell>
      <TableCell>
        <Link to={sourceEntryLink(entry)}>{mapEntryType(entry.entryType)}</Link>
      </TableCell>
      <TableCell>
        <span>{description}</span>
      </TableCell>
      <TableCell>
        <UserField userId={entry.entry.createdById} />
      </TableCell>
      <TableCell>{statusString(entry.approvalStatus)}</TableCell>
      <TableCell className={classnames(classes.rightAlignedCell)}>
        {MoneyUtils.priceFormatter.format(parseFloat(entry.entryAmount))}
      </TableCell>
      <TableCell className={classnames(classes.rightAlignedCell)}>
        {MoneyUtils.priceFormatter.format(total)}
      </TableCell>
    </TableRow>
  );
};
