import React from "react";
import { Constants } from "../../constants";
import { AccountBalanceTable } from "./AccountBalanceTable";
import { BalanceEntryRow } from "./BalanceEntryRow";

export interface IBalanceEntryTableProps {
  range: [Date, Date];
  taskId: string;
  showProvisional: boolean;
}

const renderRow = (entry, total) => (
  <BalanceEntryRow key={entry.id} entry={entry} total={total} />
);

export const BalanceEntryTable: React.FC<IBalanceEntryTableProps> = (props) => (
  <AccountBalanceTable
    {...props}
    resource={Constants.BALANCE_ENTRY_RESOURCE}
    rowRenderer={renderRow}
  />
);
